<template>
  <div class="appFrame">
    <Navbar
      :current-module-path="'creative-production-live-polls'"
      :current-module-name="'Live Polls'"
    />

    <main
      class="moduleFrame moduleFrame--tagManagerRepository moduleFrame--tagManagerRepositoryPlacements livePolleRepo"
    >
      <PencilBar />

      <div class="heading">Live Polls</div>

      <ModuleHeader>
        <ul slot="tabs">
          <li>
            <router-link
              :to="{
                path: '/tag-manager/placements/campaign/' + campaign.id,
                params: { id: campaign.id, data: campaign }
              }"
              >Placements</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                path: '/creative-production/creatives/campaign/' + campaign.id
              }"
              >Creatives</router-link
            >
          </li>
          <li selected>Live Polls</li>
        </ul>

        <div slot="title">Live Polls</div>

        <div slot="actions">
          <button
            class="moduleHeader__createButton mr1"
            @click.prevent="openNewLivePoll"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            />
            New Live Poll
          </button>
        </div>
      </ModuleHeader>

      <ModuleFilter>
        <div slot="searchField">
          <FormField
            label="Search Live Polls"
            for-id="filter-search"
            type="search"
            class="moduleFilter__filter"
          >
            <input
              id="filter-search"
              v-model="filterModuleData.search"
              type="text"
              required
            />
          </FormField>
        </div>
      </ModuleFilter>

      <section class="moduleContent">
        <div class="contentTableFrame">
          <ContentTableTools>
            <FormField
              slot="left"
              for-id="tools-orderby"
              type="select"
              tag-left="Order By:"
            >
              <select
                id="tools-orderby"
                v-model="tableDataOptions.orderBy"
              >
                <option
                  v-for="(orderByOption, index) in tableDataOptions.options"
                  :key="index"
                  :value="orderByOption.value"
                >
                  {{ orderByOption.label }}
                </option>
              </select>
            </FormField>

            <FormField
              slot="left"
              for-id="tools-order"
              type="select"
              tag-left="Order:"
            >
              <select
                id="tools-order"
                v-model="tableDataOptions.order"
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </FormField>

            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Live Polls: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>

          <div
            class="contentTableWrapper"
            :class="{ contextualMenuExpanded: contextualMenuExpanded }"
          >
            <table class="contentTable">
              <thead>
                <tr>
                  <th class="contentTable__dimension">
                    <input
                      type="checkbox"
                      @change="selectAllToggle($event)"
                    />
                    Live Poll Name
                  </th>
                  <th>Last Updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in paginatedItems"
                  :key="index"
                >
                  <td class="contentTable__dimension">
                    <input
                      v-model="pollSelected[item.id]"
                      type="checkbox"
                      :name="item.name"
                    /><a
                      :title="item.name"
                      href="#"
                      @click.prevent="openLivePollSettings(item.id)"
                      >{{ item.name }}</a
                    >
                  </td>
                  <td column-name="Last Updated">
                    <span
                      @click.prevent="
                        filterModuleData.filterValues.updated_at =
                          item.updated_at
                      "
                      >{{ getFormattedDate(item.updated_at) }}</span
                    >
                  </td>
                  <td class="contentTable__actions">
                    <button
                      title="Edit Feed"
                      @click.prevent="openLivePollSettings(item.id)"
                    >
                      <font-awesome-icon
                        icon="cog"
                        class="i"
                      />
                    </button>
                    <button
                      title="Open Endpoint"
                      @click.prevent="openStatusEndpoint(item.id)"
                    >
                      <font-awesome-icon
                        icon="external-link-alt"
                        class="i"
                      />
                    </button>
                    <button
                      title="Delete Live Poll"
                      @click.prevent="deleteLivePoll(item.id)"
                    >
                      <font-awesome-icon
                        icon="trash-alt"
                        class="i"
                      />
                    </button>
                  </td>
                </tr>

                <tr v-if="paginatedItems.length < 1 && campaign.creatives">
                  <td colspan="9">No Live Polls matches your criteria</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ContentTableTools>
            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Live Polls: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>
        </div>
      </section>

      <ModuleFooter>
        <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
        <WebLinks slot="right" />
      </ModuleFooter>
    </main>

    <!-- Modal Component -->
    <b-modal
      ref="moduleModal"
      :static="staticBModal"
      class="modal990Width"
      title=""
      hide-footer
      hide-header
      no-enforce-focus
      @hidden="modalData.currentComponent = null"
    >
      <component
        :is="modalData.currentComponent"
        :item-id="modalData.itemId"
        @done="hideModal()"
        @pollCreated="fetchLatestPolls()"
      />
    </b-modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import ProfileModal from '@/views/creative-production/modals/RepoProductionLivePollProfile.vue'
import WebLinks from '@/components/WebLinks.vue'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'

export default {
  name: 'CreativeProductionRepositoryLivePolls',
  components: {
    Navbar,
    FormField,
    ModuleHeader,
    ModuleFilter,
    PencilBar,
    ModuleFooter,
    ContentTableTools,
    WebLinks
  },
  data() {
    return {
      staticBModal: true,
      filterModuleData: {
        search: '',
        filterValues: {},
        filterDefaults: {},
        filterOptions: {}
      },
      tableDataOptions: {
        orderBy: 'updated_at',
        order: 'desc',
        options: [
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Live Poll Id',
            value: 'id'
          },
          {
            label: 'Last Updated',
            value: 'updated_at'
          }
        ]
      },
      mobileFiltersExpanded: false,
      breadcrumsData: [
        {
          text: 'Tag Manager',
          href: '/tag-manager'
        },
        {
          text: 'Live Polls',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null
      },
      data: [],
      pollSelected: {},
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      currentSection: 'details',
      contextualMenuExpanded: false,
      selectAll: false,
      campaign: { campaign_source: { id: null } }
      // if we select an assigned creative name from the placements list on Tag Manager placements, it comes in the url query ?creative=id
    }
  },
  computed: {
    ...mapGetters('livePolls', {
      list: 'list'
    }),
    currentUser: function () {
      const self = this
      const currentUser = JSON.parse(localStorage._sUser)

      return currentUser
    },
    filteredItems: function () {
      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        this.list,
        this.filterModuleData.search
      )
      // this.$log.info(searchedItems)
      // Pick properties to filter by: the ones that are not using the default values
      const propsToFilterBy = Object.keys(this.filterModuleData.filterValues)
        .filter((key) => {
          const filterOption
            = this.filterModuleData.filterOptions
            && this.filterModuleData.filterOptions[key]
              ? this.filterModuleData.filterOptions[key][0]
              : false
          return (
            filterOption
            && this.filterModuleData.filterValues[key] !== filterOption
          )
        })
        .reduce((obj, key) => {
          obj[key] = this.filterModuleData.filterValues[key]
          return obj
        }, {})
      // filter data
      const filteredItems = Utils.getFilteredItems(
        searchedItems,
        propsToFilterBy
      )

      // order data asc or desc
      filteredItems.sort((a, b) => {
        const field = this.tableDataOptions.orderBy
        if (a[field] < b[field]) {
          return this.tableDataOptions.order === 'asc' ? -1 : 1
        }
        if (a[field] > b[field]) {
          return this.tableDataOptions.order === 'asc' ? 1 : -1
        }
        return 0
      })
      return filteredItems
    },
    paginatedItems: function () {
      // return paginated data
      return Utils.getPaginatedItems(
        this.filteredItems,
        this.pagination.currentPage,
        this.pagination.perPage
      )
    }
  },
  watch: {
    // Watch for changes in livePolls List
    list: function () {
      this.populateFilters()
    }
  },
  created() {
    this.getCampaign(this.$route.params.id).then((response) => {
      this.campaign = response
    })

    this.fetch()
  },
  methods: {
    ...mapActions(['registerModalOpened', 'registerModalClosed']),
    ...mapActions('livePolls', {
      fetch: 'fetch',
      createPoll: 'create',
      delete: 'delete'
    }),
    ...mapActions('campaigns', {
      getCampaign: 'fetchByIdNoPlacements'
    }),
    openStatusEndpoint(id) {
      window.open(
        process.env.VUE_APP_ENDPOINT_LIVE_POLL_STATUS_BY_ID + id,
        '_blank'
      )
    },
    openNewLivePoll: function () {
      // set the modal item id to null to create new user
      this.setModalProps(null)

      // register that we have just opened a modal
      this.registerModalOpened()

      // set the content for the modal to the user profile component
      this.modalData.currentComponent = ProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      this.$refs.moduleModal.show()
    },
    openLivePollSettings: function (itemId) {
      // set the modal item id to the userId we want to update
      this.setModalProps(itemId)

      // register that we have just opened a modal
      this.registerModalOpened()

      // set the content for the modal to the user profile component
      this.modalData.currentComponent = ProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      this.$refs.moduleModal.show()
    },
    deleteLivePoll: function (pollId) {
      const feed = this.list.find((item) => item.id === pollId)

      this.$swal({
        position: 'top',
        title: 'Are you sure you want to delete this Live Poll',
        html: '',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
        icon: 'warning',
        toast: false,
        timer: 7000
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(pollId)
            .then(() => {
              this.showAlerts(
                'Succes!',
                'Live Poll deleted successfully',
                'success'
              )
            })
            .catch((error) => {
              this.$log.error(error)
              this.showAlerts(
                'Error!',
                'There was an issue deleting this live poll. Please try again.',
                'error'
              )
            })
        }
      })
    },
    navigateToPoll: function (itemData) {
      this.$router.push({
        name: 'Edit Feed Builder',
        params: {
          id: itemData.id
        }
      })
    },
    setModalProps: function (itemId) {
      // set the modal item id to the userId we want to update
      this.modalData.itemId = itemId
    },
    fetchLatestPolls: function () {
      this.fetch()
    },
    hideModal: function () {
      // register that we have just closed a modal
      this.registerModalClosed()
      // show modal
      this.$refs.moduleModal.hide()
    },
    getFormattedDate: function (date) {
      const formattedDate = this.$moment(date).utc().format('MMM DD, YYYY')

      return formattedDate !== 'Invalid date' ? formattedDate : ''
    },
    populateFilters() {
      // Get keys for the filter
      const paramsToFilterBy = Object.keys(this.filterModuleData.filterValues)

      // populate filterOptions based on livepolls
      this.list.map((item) => {
        return item
      })

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(this.list, paramsToFilterBy)

      // Add filterModuleData.filterValues's default values as the first option for each filter
      paramsToFilterBy.forEach((param) => {
        filterOptions[param].unshift(
          this.filterModuleData.filterDefaults[param]
        )
      })

      // Store filter options
      this.filterModuleData.filterOptions = filterOptions
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll === true) {
        this.paginatedItems.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.pollSelected, String(item.id), true)
          return item
        })
      }
      // deselect all
      if (this.selectAll === false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.pollSelected, String(item.id), false)
          return item
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/common.scss';

.icon-name {
  margin: 0 5px;
}

.defaultCreative {
  // background: darkgrey !important;
  pointer-events: none;
  opacity: 0.5;
}

.highlight-row,
.highlight-row td,
.highlight-row:hover {
  background: #ededed !important;
}

.highlight-row td:first-child {
  border-left: 3px solid #3bbca1;
}

.livePolleRepo div#title-section {
  display: none;
}
</style>
