<template>
  <div class="appFrame">
    <Navbar
      :current-module-path="'tag-manager'"
      :current-module-name="'Tag Manager'"
    />

    <main
      class="moduleFrame moduleFrame--tagManagerRepository moduleFrame--tagManagerRepositoryPlacements"
    >
      <PencilBar />

      <div
        v-if="campaign != null"
        class="heading"
      >
        {{ campaign.name }}
        <button
          class="button"
          @click="openCampaignSettings(campaign.id)"
        >
          <font-awesome-icon
            icon="cog"
            class="i"
          />
        </button>
      </div>

      <ModuleHeader>
        <ul slot="tabs">
          <li selected>Placements</li>
          <li>
            <router-link
              :to="{
                path: '/creative-production/creatives/campaign/' + campaign.id
              }"
              >Creatives</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ path: '/tag-manager/live-polls/campaign/' + campaign.id }"
              >Live Polls</router-link
            >
          </li>
          <!-- <li v-if="currentUser.role_name == 'Admin' || environment !== 'production'"><router-link :to="{path:'/analytics/performance/campaign/' + campaign.id}">Monitoring</router-link></li> -->
          <!-- <li v-if="currentUser.role_name == 'Admin' || environment !== 'production'"><router-link :to="{path:'/analytics/live/campaign/' + campaign.id}">Live</router-link></li> -->
        </ul>

        <!-- <div slot="title">Placements</div> -->

        <div slot="actions">
          <!-- <button v-if="isCeltraSync" class="moduleHeader__createButton moduleHeader__createButton__first" @click.prevent="syncPlacements"><font-awesome-icon :icon="(noPlacements) ? 'plus-circle' : 'sync-alt'" class="i" /> Sync Celtra Placements</button>
              <button class="moduleHeader__createButton mr1" @click.prevent="openNewPlacement"><font-awesome-icon icon="plus-circle" class="i" /> New Placement</button> -->
          <button
            v-if="isCeltraSync"
            class="moduleHeader__createButton moduleHeader__createButton__first"
            @click.prevent="syncPlacements"
          >
            <font-awesome-icon
              :icon="noPlacements ? 'plus-circle' : 'sync-alt'"
              class="i"
            />
            Sync
          </button>
          <button
            class="moduleHeader__createButton mr1"
            :disabled="campaign.status_source == 'Updating'"
            @click.prevent="openNewPlacement"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            />
            New Placement
          </button>
          <!-- <button class="moduleHeader__createButton" :class="{ mr1 : !isAirtorySync }" @click.prevent="openNewPlacement"><font-awesome-icon icon="plus-circle" class="i" /> New</button> -->
          <!-- <button v-if="!isAirtorySync" class='button noborder' @click.prevent="openTrafficPlan"><font-awesome-icon icon="table" class="i" /> TRAFFIC PLAN</button> -->
          <button
            v-if="isAirtorySync || isPadSquadSync"
            class="button noborder"
            @click.prevent="openTrafficPlan"
          >
            <font-awesome-icon
              icon="table"
              class="i"
            />
            TRAFFIC PLAN
          </button>
          <!-- <button class='moduleHeader__createButton' @click.prevent="openGenerateCSV"><font-awesome-icon icon="table" class="i" />Generate CSV</button> -->
        </div>
      </ModuleHeader>

      <ModuleFilter>
        <div slot="searchField">
          <FormField
            label="Search Placements"
            for-id="filter-search"
            type="search"
            class="moduleFilter__filter"
          >
            <input
              id="filter-search"
              v-model="filterModuleData.search"
              type="text"
              required
            />
          </FormField>
        </div>

        <div slot="collapsableFilters">
          <FormField
            label="Format"
            for-id="filter-format"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-format"
              v-model="filterModuleData.filterValues.formatType"
            >
              <option
                v-for="item in filterModuleData.filterOptions.formatType"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
            <div
              v-if="
                filterModuleData.filterValues.formatType !==
                filterModuleData.filterDefaults.formatType
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="
                filterModuleData.filterValues.formatType =
                  filterModuleData.filterDefaults.formatType
              "
            >
              [X] CLEAR
            </div>
          </FormField>

          <FormField
            label="Tag Source"
            for-id="filter-tagSource"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-tagSource"
              v-model="filterModuleData.filterValues.tagSource"
            >
              <option
                v-for="tagSource in filterModuleData.filterOptions.tagSource"
                :key="tagSource"
              >
                {{ tagSource }}
              </option>
            </select>
            <div
              v-if="
                filterModuleData.filterValues.tagSource !==
                filterModuleData.filterDefaults.tagSource
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="
                filterModuleData.filterValues.tagSource =
                  filterModuleData.filterDefaults.tagSource
              "
            >
              [X] CLEAR
            </div>
          </FormField>
        </div>
      </ModuleFilter>

      <section class="moduleContent">
        <div
          v-if="campaign != null && fallbackImages.length == 0"
          class="alert-heading"
        >
          <font-awesome-icon
            icon="exclamation-circle"
            class="i"
          />
          This campaign does not have any fallback image.
          <button
            class="anchor-button"
            @click="openCampaignSettings(campaign.id, 'fallbacks')"
          >
            Upload here.
          </button>
        </div>
        <div
          v-if="campaign != null && campaign.status_source == 'Updating'"
          class="alert-heading"
        >
          <font-awesome-icon
            icon="exclamation-circle"
            class="i"
          />This campaign is updating placements by traffic plan, Unable to
          update any placement until the process have been completed.
        </div>
        <div class="contentTableFrame">
          <ContentTableTools>
            <FormField
              slot="left"
              for-id="tools-orderby"
              type="select"
              tag-left="Order By:"
            >
              <select
                id="tools-orderby"
                v-model="tableDataOptions.orderBy"
              >
                <option
                  v-for="(orderByOption, index) in tableDataOptions.options"
                  :key="index"
                  :value="orderByOption.value"
                >
                  {{ orderByOption.label }}
                </option>
              </select>
            </FormField>

            <FormField
              slot="left"
              for-id="tools-order"
              type="select"
              tag-left="Order:"
            >
              <select
                id="tools-order"
                v-model="tableDataOptions.order"
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </FormField>

            <FormField
              slot="left"
              for-id="tools-actions"
              type="select"
              tag-left="Actions:"
            >
              <select
                id="tools-actions"
                @change="actionsHandler"
              >
                <option value="none">Select an Action</option>
                <option
                  value="Open Tags"
                  :disabled="!validItemsSelected.length"
                >
                  Open Tags
                </option>
              </select>
            </FormField>

            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Placements: {{ filteredItems.length }}
            </div>
            <b-pagination
              v-if="!noPlacements"
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>

          <div
            class="contentTableWrapper"
            :class="{ contextualMenuExpanded: contextualMenuExpanded }"
          >
            <table
              v-if="!askForPendingPlacementsSync"
              class="contentTable"
            >
              <thead>
                <tr>
                  <th class="contentTable__dimension">
                    <input
                      type="checkbox"
                      @change="selectAllToggle($event)"
                    />Placement Name
                  </th>
                  <th>Placement ID</th>
                  <th>Last Update</th>
                  <th>Format</th>
                  <th v-if="isAirtorySync || isPadSquadSync">
                    Assigned Creative
                  </th>
                  <th v-if="!isAirtorySync && !isPadSquadSync">3P ID</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in paginatedItems"
                  :key="index"
                  :class="{
                    backgroundDarker:
                      item.creative_tag == '' || item.format_id == 0
                  }"
                >
                  <td class="contentTable__dimension">
                    <input
                      v-model="itemsSelected[item.id]"
                      type="checkbox"
                      :name="'placement_' + item.name"
                      :disabled="
                        item.creative_tag == '' || item.format_id == 0
                          ? true
                          : false
                      "
                      :title="getTitle(item)"
                    /><a
                      class="overflow-ellipsis-placements"
                      :title="item.name"
                      href="#"
                      @click.prevent="openPlacementSettings(item.id)"
                      >{{ item.name }}</a
                    >
                  </td>
                  <td column-name="Placement ID">
                    <a
                      href="#"
                      @click.prevent="
                        filterModuleData.filterValues.id = item.id
                      "
                      >{{ item.id }}</a
                    >
                  </td>
                  <td column-name="Last Update">
                    <a
                      href="#"
                      @click.prevent="
                        filterModuleData.filterValues.touched_on =
                          item.touched_on
                      "
                      >{{ getFormattedDate(item.touched_on) }}</a
                    >
                  </td>
                  <td column-name="Format">
                    <a
                      href="#"
                      @click.prevent="
                        filterModuleData.filterValues.type = item.type
                      "
                      >{{ getFormatName(item.format_id) }}</a
                    >
                  </td>
                  <td
                    v-if="isAirtorySync || isPadSquadSync"
                    column-name="Assigned Creative"
                  >
                    <a
                      class="overflow-ellipsis-column"
                      :title="getCreativeName(item.creative_id)"
                      href="#"
                      @click.prevent="gotoCreative(item.creative_id)"
                      >{{ getCreativeName(item.creative_id) }}</a
                    >
                  </td>

                  <td
                    v-if="!isAirtorySync && !isPadSquadSync"
                    column-name="3P ID"
                  >
                    <a
                      href="#"
                      @click.prevent="
                        filterModuleData.filterValues.third_party_id
                      "
                      >{{ item.third_party_id }}</a
                    >
                  </td>
                  <td
                    class="contentTable__actions"
                    disabled="true"
                  >
                    <button
                      :disabled="
                        item.creative_tag == '' ||
                        item.format_id == 0 ||
                        item.format_id == ''
                          ? true
                          : false
                      "
                      :title="getTitle(item)"
                      @click="openTags([{ id: item.id }])"
                    >
                      <font-awesome-icon
                        icon="code"
                        class="i"
                      />
                    </button>
                    <button @click="openPlacementSettings(item.id)">
                      <font-awesome-icon
                        icon="cog"
                        class="i"
                      />
                    </button>
                    <div>
                      <ActionsDropdown
                        :dropup="
                          paginatedItems.length > 10 &&
                          !(index < paginatedItems.length - 2)
                        "
                        :item-id="item.id"
                        :actions="tableActions"
                        @show="contextualMenuExpanded = true"
                        @hide="contextualMenuExpanded = false"
                      />
                    </div>
                  </td>
                </tr>

                <tr v-if="paginatedItems.length < 1 && !noPlacements">
                  <td colspan="9">No placements matches your criteria</td>
                </tr>
              </tbody>
            </table>

            <table
              v-show="noPlacements"
              class="contentTable"
            >
              <tbody>
                <tr>
                  <td colspan="9">
                    There are no placements on this campaign. You can
                    <span v-if="isCeltraSync"
                      >either
                      <a
                        href="#"
                        @click="syncPlacements"
                        >Import Placements from Celtra</a
                      >
                      or </span
                    ><a
                      href="#"
                      @click="openNewPlacement"
                      >Create Placement Manually</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <ContentTableTools v-if="!noPlacements">
            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Placements: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>
        </div>
      </section>

      <ModuleFooter>
        <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
        <WebLinks slot="right" />
      </ModuleFooter>
    </main>

    <!-- Modal Component -->
    <b-modal
      ref="moduleModal"
      :static="staticBModal"
      :class="{ modalFullWidth: isModalFullWidth }"
      title=""
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hidden="modalData.currentComponent = null"
    >
      <component
        :is="modalData.currentComponent"
        :item-id="modalData.itemId"
        :campaign="modalData.campaign"
        :campaign-id="modalData.campaignId"
        :campaign-name="modalData.campaignName"
        :advertiser-id="modalData.advertiserId"
        :advertiser-name="modalData.advertiserName"
        :campaign-prop="modalData.campaign"
        :current-section-prop="modalData.currentSectionProp"
        :campaign-source-prop="modalData.campaignSourceProp"
        @done="hideModal()"
        @fallbackChanged="onFallbackChanged"
      />
    </b-modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import ProfileModalCampaign from '@/views/modals/RepoCampaignProfile.vue'
import ProfileModal from '@/views/modals/RepoPlacementProfile.vue'
import ProfileModalCeltraSync from '@/views/modals/RepoCeltraSyncProfile.vue'
import ProfileModalTrafficPlan from '@/views/modals/RepoTrafficPlanProfile.vue'
import ProfileModalTrafficPlanCreativeProduction from '@/views/creative-production/modals/RepoCreativeProductionTrafficPlanProfile.vue'
import WebLinks from '@/components/WebLinks.vue'
import GenerateCSV from '@/views/modals/GenerateCSV.vue'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'

export default {
  name: 'TagManagerRepositoryPlacements',
  components: {
    Navbar,
    FormField,
    ModuleHeader,
    ModuleFilter,
    PencilBar,
    ModuleFooter,
    ActionsDropdown,
    ContentTableTools,
    WebLinks,
    ProfileModal,
    ProfileModalCampaign
  },
  data() {
    return {
      staticBModal: true,
      environment: process.env.VUE_APP_ENV,
      mobileFiltersExpanded: false,
      preview: '',
      filterModuleData: {
        search: '',
        filterValues: {
          formatType: 'All Formats',
          touched_on: 'All dates',
          tagSource: 'All Tag Sources',
          thirdPartyId: 'All Third Parties'
        },
        filterDefaults: {
          formatType: 'All Formats',
          touched_on: 'All dates',
          tagSource: 'All Tag Sources',
          thirdPartyId: 'All Third Parties'
        },
        filterOptions: {
          formatType: [],
          touched_on: [],
          tagSource: [],
          thirdPartyId: []
        }
      },
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Format Type',
            value: 'formatType'
          },
          {
            label: 'Created Date',
            value: 'placementCreatedDate'
          }
          // {
          //   label: "Modified Date",
          //   value: "placementModifiedDate"
          // }
        ]
      },
      breadcrumsData: [
        {
          text: 'Tag Manager',
          href: '/tag-manager'
        },
        {
          text: 'Campaigns',
          href: '/tag-manager/campaigns'
        },
        {
          text: 'Campaign',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        campaign: null,
        campaignProp: null,
        campaignId: null,
        campaignName: null,
        advertiserId: null,
        advertiserName: null,
        currentSectionProp: null,
        campaignSourceProp: null
      },
      itemsSelected: {},
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'Placement Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openPlacementSettings
        },
        {
          ico: 'clone',
          text: 'Duplicate Placement',
          clickAction: this.duplicatePlacement
        },
        {
          ico: 'trash-alt',
          text: 'Delete Placement',
          clickAction: this.deletePlacement
        },
        {
          ico: 'eye',
          text: 'Test Page - Friendly Iframe',
          clickAction: this.openPreviewTestPageFriendly
        },
        {
          ico: 'eye',
          text: 'Test Page - Safe Frame',
          clickAction: this.openPreview
        }
      ],
      contextualMenuExpanded: false,
      campaign: { campaign_source: { id: null } }, // fallbackImages: [] },
      fallbackImages: [],
      prompted: false,
      isModalFullWidth: false,
      selectAll: false
    }
  },
  computed: {
    ...mapGetters('placements', ['list', 'selectedCampaign']),
    ...mapGetters('campaigns', {
      listCampaign: 'list',
      campaignById: 'itemById'
    }),
    ...mapGetters(['appStatus', 'creativeFormats']),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById'
    }),
    ...mapGetters('tags', ['placements']),
    currentUser: function () {
      const self = this
      const currentUser = JSON.parse(localStorage._sUser)

      return currentUser
    },
    isCeltraSync: function () {
      return this.campaign.campaign_source === 'Celtra Sync'
    },
    isAirtorySync: function () {
      return this.campaign.campaign_source === 'Airtory Sync'
    },
    isPadSquadSync: function () {
      return this.campaign.campaign_source === 'PadSquad'
    },
    askForPendingPlacementsSync: function () {
      return this.isCeltraSync && this.list.length < 1
    },
    noPlacements: function () {
      return this.list.length < 1
    },
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validItemsSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    validItemsSelectedToObjects: function () {
      const data = []
      this.validItemsSelected.map((item) => {
        data.push({ id: parseInt(item) })
      })

      return data
    },
    filteredItems: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        self.list,
        self.filterModuleData.search
      )

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(
        self.filterModuleData.filterValues,
        (filter, key) => {
          // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
          const filterOption = _.get(
            self.filterModuleData.filterOptions,
            '[' + key + '][0]',
            false
          )

          // if filterOption is not false and the filter option is not the first option
          return filterOption && filter != filterOption
        }
      )

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(
        filteredItems,
        [self.tableDataOptions.orderBy],
        [self.tableDataOptions.order]
      )

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(
        self.filteredItems,
        self.pagination.currentPage,
        self.pagination.perPage
      )
    }
  },
  watch: {
    list: function () {
      const self = this

      self.populateFilters()
    },
    listCampaign: function () {
      this.campaign = this.campaignById(this.$route.params.id)
      // if(!this.prompted) this.promptToSyncCeltraPlacements();
    },
    appStatus: function () {
      // if(this.appStatus == 'ready' && !this.prompted){
      //   this.promptToSyncCeltraPlacements();
      // }
    },
    placements: function () {
      const self = this
      self.populatePlacements()
    }
  },
  mounted() {
    // if (!this.$store.state.campaigns.list) {
    // this.fetchAllTaxonomies();
    // }
    // this.fetchCreativeFormats();
  },
  async created() {
    const self = this

    self.getCampaign(this.$route.params.id).then((response) => {
      self.fallbackImages = [...response.fallbackImages]
      if (self.campaign.status_source == 'Updating') {
        this.$swal({
          position: 'top',
          title: 'Info!',
          html: '<h3>This campaign is updating placements by traffic plan, Unable to update any placement until the process have been completed.</h3>',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          allowOutsideClick: false,
          icon: 'info',
          timer: 10000,
          toast: false
        })
      }

      self.fetchWithOutTrackers(response.id).then(async (placements) => {
        response.placements = [...placements]
        self.campaign = response
        self.setPlacements(placements)

        if (
          this.isCeltraSync
          && !this.prompted
          && response.placements.length < 1
        ) {
          this.promptToSyncCeltraPlacements()
        }
        this.checkAirtoryAnalytics()
      })
    })

    // self.$log.info(self.campaign);
    //  console.log('idlccheck',response.placements.length)
  },
  methods: {
    ...mapActions('placements', [
      'fetch',
      'fetchCeltra',
      'sync',
      'create',
      'read',
      'update',
      'delete',
      'setPlacements',
      'duplicate',
      'fetchWithOutTrackers'
    ]),
    ...mapActions('campaigns', {
      replaceCampaign: 'replace',
      fetchCampaignById: 'fetchById',
      addPlacementCampaign: 'addPlacementCampaign',
      getCampaign: 'fetchByIdNoPlacements'
    }),
    ...mapActions([
      'fetchCreativeFormats',
      'registerModalOpened',
      'registerModalClosed'
    ]),
    ...mapActions('tags', ['fetch', 'setTagsByAdServer', 'setTagVersion']),
    ...mapActions('quickview', ['setWebsite']),
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':
          break
        case 'Open Tags':
          this.openTags(this.validItemsSelectedToObjects)
          break

        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    openTags: function (itemsId) {
      // console.log('open tags', itemsId);

      const placementsId = itemsId
        .map((item) => {
          return item.id
        })
        .join('-')

      const routeData = this.$router.resolve({
        name: 'Tag Generator',
        params: { campaignId: this.campaign.id, placements: placementsId }
      }) // path:`/tg/${itemId}`, query: { data: selectedPlacements }});
      window.open(routeData.href, '_blank')
    },
    openTrafficPlan: function () {
      const self = this

      // self.modalData.itemId = self.campaign.celtra_campaign_id;
      self.modalData.itemId = !this.isPadSquadSync
        ? self.campaign.external_campaign_id
        : self.campaign.id
      self.modalData.campaign = self.campaign

      // self.$log.info(self.campaign);

      self.registerModalOpened()

      self.modalData.currentComponent = this.isAirtorySync || this.isPadSquadSync
        ? ProfileModalTrafficPlanCreativeProduction
        : ProfileModalTrafficPlan
      this.isModalFullWidth = true

      self.$refs.moduleModal.show()
    },
    syncPlacements() {
      const self = this

      // self.modalData.itemId = self.campaign.celtra_campaign_id;
      self.modalData.itemId = self.campaign.external_campaign_id
      self.modalData.campaign = self.campaign

      self.registerModalOpened()

      self.modalData.currentComponent = ProfileModalCeltraSync
      this.isModalFullWidth = true

      self.$refs.moduleModal.show()
    },
    openCampaignSettings: function (itemId, currentSection) {
      const self = this
      // set the modal item id to the userId we want to update
      self.modalData.itemId = itemId

      // for opening fallbacks directly
      self.modalData.currentSectionProp = currentSection

      // self.$log.info('Campaign Id', itemId, this.listCampaign);

      // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCampaign
      const _advertiser = this.advertiserById(this.campaign.advertiser_id)

      self.modalData.advertiserId = _advertiser.id
      self.modalData.advertiserName = _advertiser.name

      self.modalData.campaignSourceProp = this.campaign.campaign_source

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = ProfileModalCampaign
      this.isModalFullWidth = false

      // show modal
      self.$refs.moduleModal.show()
    },
    setPlacementModalProps: function (itemId) {
      // set the modal item id to the userId we want to update
      this.modalData.itemId = itemId
      this.modalData.campaignId = this.campaign.id
      this.modalData.campaign = this.campaign
    },
    openAnalytics(id) {
      const p = this.campaign.placements.find((item) => item.id === id)
      if (p)
        window.open(
          `https://studio.airtory.com/#/analytics/placement/${p.external_placement_id}`,
          '_blank'
        )
    },
    openPlacementSettings: function (itemId) {
      const self = this

      // self.$log.info(itemId);
      // self.$log.info(self.campaign);

      // set the modal item id to the userId we want to update
      self.setPlacementModalProps(itemId)

      this.isModalFullWidth = false

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = ProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },
    duplicatePlacement: function (itemId) {
      var self = this
      if (self.campaign.status_source != 'Updating') {
        var self = this

        this.duplicate(itemId)
          .then((data) => {
            // self.$log.info('Duplicated', data);
            self.addPlacementCampaign({
              campaign_id: data.campaign_id,
              placement: data
            })
          })
          .catch((error) =>
            this.notificationAlert(
              'There was an error duplicating the placement.',
              '',
              'error'
            )
          )
      } else {
        self.$swal({
          position: 'top',
          title: 'Info!',
          html: '<h3>This campaign is updating placements by traffic plan, Unable to update any placement until the process have been completed.</h3>',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          allowOutsideClick: false,
          icon: 'info',
          timer: 5000,
          toast: false
        })
      }
    },
    deletePlacement: function (itemId) {
      const self = this
      if (self.campaign.status_source != 'Updating') {
        // fet user from users.
        const placement = _.find(self.list, { id: itemId })

        // if the user we are trying to delete is not the logged in user...
        this.$swal({
          position: 'top',
          title: `Are you sure you want to delete this placement: ${placement.name}?`,
          html: '',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          allowOutsideClick: false,
          icon: 'warning',
          toast: false
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            self.delete(itemId).catch((error) => {
              self.$log.error(error)
              // alert(`There was an issue deleting this placement. Please try again. Error Code: ${error}`);
              this.notificationAlert(
                'There was an issue deleting this placement. Please try again.',
                `Error Code: ${error}`,
                'error'
              )
            })
          }
        })
        // if(confirm(`Are you sure you want to delete this placement: ${placement.name}?`)) {
        //   // delete creative

        // }
      } else {
        self.$swal({
          position: 'top',
          title: 'Info!',
          html: '<h3>This campaign is updating placements by traffic plan, Unable to update any placement until the process have been completed.</h3>',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          allowOutsideClick: false,
          icon: 'info',
          timer: 5000,
          toast: false
        })
      }
    },
    openNewPlacement: function () {
      const self = this

      // set the modal item id to null to create new user
      self.setPlacementModalProps(null)

      this.isModalFullWidth = false

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = ProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    openGenerateCSV: function () {
      const self = this

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.campaignId = this.$route.params.id
      self.modalData.campaignName = this.campaign.name
      self.modalData.currentComponent = GenerateCSV
      this.isModalFullWidth = false

      // show modal
      self.$refs.moduleModal.show()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()
    },
    onFallbackChanged: function (fallbacks) {
      this.fallbackImages = fallbacks.filter((fallback) => {
        return fallback.image_url != null
      })

      // this.$log.info(this.fallbackImages.length)
      // this.$log.info(fallbacks)
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('M/DD/YY h:mma')

      return formattedDate != 'Invalid date' ? formattedDate : ''
    },
    populateFilters: function () {
      const self = this

      // add format type {id, name}
      self.list.map((item) => {
        item.formatType = _.find(this.creativeFormats, { id: item.format_id })
        if (item.formatType == undefined) return item
        item.formatType = item.formatType.name
        return item
      })

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.list, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, (param) => {
        filterOptions[param].unshift(
          self.filterModuleData.filterDefaults[param]
        )
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    },
    promptToSyncCeltraPlacements: function () {
      // if the campaign is Celtra Sync but has not placement imported
      if (this.askForPendingPlacementsSync) {
        this.prompted = true
        setTimeout(() => {
          if (
            confirm(
              'This campaign was for Celtra Sync, but placements have not yet been imported. Would you like to sync your Celtra placements now?'
            )
          ) {
            // open the Celtra modal
            this.syncPlacements()
          }
        }, 800)
      }
    },
    getFormatName: function (formatId) {
      const foundCreativeFormat = _.find(this.creativeFormats, { id: formatId })
      const name = _.get(foundCreativeFormat, 'name', '')

      return name
    },
    getCreativeName: function (creativeId) {
      const creative = this.campaign.creatives.find((c) => c.id === creativeId)
      return creative ? creative.name : ''
    },
    gotoCreative: function (creativeId) {
      // open creative on creatives list section
      this.$router.push({
        path: `/creative-production/creatives/campaign/${this.campaign.id}`,
        query: { creative: creativeId }
      })
    },
    getTitle: function (item) {
      if (item.creative_tag == '' && item.format_id == 0) {
        return 'Creative tag and Format are missing for this placement'
      } else if (item.creative_tag == '') {
        return 'Creative tag missing for this placement'
      } else if (item.format_id == 0) {
        return 'Format is not specified for this placement'
      } else {
        return ''
      }
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.paginatedItems.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.itemsSelected, String(item.id), true)

          // if item does not have a creative tag
          if (item.creative_tag == '' || item.format_id == 0) {
            this.$set(this.itemsSelected, String(item.id), false)
          }
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.itemsSelected, String(item.id), false)
        })
      }
    },
    openPreview: function (itemId) {
      this.preview = 'Test Page Safe Iframe'
      this.setPlacementPreview(itemId)
    },
    openPreviewTestPageFriendly: function (itemId) {
      this.preview = 'Test Page Friendly'
      this.setPlacementPreview(itemId)
    },
    setPlacementPreview(itemId) {
      const selft = this
      const placement = _.find(selft.paginatedItems, { id: itemId })
      const data = {
        campaign_id: placement.campaign_id,
        placements: placement.id
      }
      selft.fetch(data).then((data) => {
        if (this.placements.length > 0) {
          const tagVersion
            = this.campaign.campaign_source === 'PadSquad' ? 3 : 2
          this.setTagVersion(tagVersion)
          this.setTagsByAdServer(6)
          this.setWebsite('creativepreview.html')

          const formatName = this.getFormatName(this.placements[0].format_id)

          let size = '&width=300&height=250&fi=false'
          if (
            formatName === 'Hover'
            || formatName === 'Headliner'
            || formatName === 'Brandscape'
            || formatName === 'Swell'
            || formatName === 'Standard 320x50'
            || formatName === 'Reactive Hover'
            || formatName === 'Superscape'
            || formatName === 'FT Adhesion'
          ) {
            size = '&width=320&height=50&fi=false'
          }

          size = this.preview == 'Test Page Friendly' ? '' : size

          const datetime = new Date().toISOString()

          const creativeTag = btoa(
            unescape(encodeURIComponent(this.placements[0].tag))
          )
          const tagUrl = `https://tools.padsquad.com/quick-view/creativepreview.html?preview=on&creativeTag=${creativeTag}${size}&date=${datetime}`

          window.open(tagUrl, '_blank')
        }
      })
    },
    checkAirtoryAnalytics: function () {
      if (this.isAirtorySync) {
        // console.log('Airotry', this.isAirtorySync, 'Placement id', response.placements.id)
        this.tableActions = [
          ...this.tableActions,
          {
            ico: 'chart-line',
            text: 'Airtory Analytics',
            roles: ['Admin', 'Marketing'],
            clickAction: this.openAnalytics
          }
        ]
      }
    },
    populatePlacements: function () {}
  }
}
</script>

<style lang="scss">
@import '@/scss/common.scss';

.heading {
  font-size: 32px;
  font-size: 3.5rem !important;
  font-weight: 600 !important;
  text-align: left;
  color: white;
  color: $green3 !important;
  color: $primary1 !important;
  // padding: $spacing-unit*2.5 $spacing-unit*4 !important;
  padding: 4.35rem $spacing-unit * 4 !important;
  background-color: $green8;
  clear: both;
  display: flex;
  align-items: center;

  & button {
    width: 42px;
    height: 26px;
    border-radius: 4px;
    border: 0px;
    font-size: 1.2rem;
    background: $green3;
    background: $primary1;
    color: white;
    margin-left: $spacing-unit;
    cursor: pointer;

    padding: 0 0 0 10px;

    &:hover {
      background: $green3 !important;
    }
  }
}

.alert-heading {
  font-size: 16px;
  color: $secondary !important;
  border: 1px solid $secondary !important;
  padding: $spacing-unit;
  margin: 0 0 $spacing-unit;

  & .i {
    margin-right: $spacing-unit;
    color: $red1 !important;
  }

  & .anchor-button {
    text-decoration: underline;
    cursor: pointer;
    border: none !important;
    color: $secondary !important;
    font-weight: 600;
    background: transparent;
  }
}

.backgroundDarker {
  background: $gray2 !important;
}
</style>
