<template>
    <div class="appFrame">
        <main class="moduleFrame moduleFrame--CreativeRepositoryPreview">
            <div class="loader" :class="{'done': (!loading)}">
                <font-awesome-icon icon="spinner" class="spinner"/>
            </div>

           <div class="stickyNav">
                <ul>
                    <li :selected="currentSection=='overview'">
                        <a @click="currentSection='overview'">Overview</a>
                    </li>
                    <li v-if="creative.designImages.length>0" :selected="currentSection=='design'">
                        <a @click="currentSection='design'">
                            <span v-if="!isCTV">Designs</span>
                            <span v-else>{{ creative.format }}</span>
                        </a>
                    </li>
                     <li  v-if="creative.demoTag" :selected="currentSection=='demo'">
                        <a @click="currentSection='demo'">Demo</a>
                    </li>
                </ul>

                <div  class="stickyNav__overview--desktop">
                    <a v-if="!notDesktop" @click="toggleOverviewDesktop">{{creative.name}}
                        <font-awesome-icon icon="info" class="i" />
                    </a>
                </div>

                <a href="http://padsquad.com/" target="_blank" class="stickyNav__brand stickyNav__brand--mobile">
                    <img src="@/assets/simpli-icon.svg"/>
                </a>

                <a href="http://padsquad.com/" target="_blank" class="stickyNav__brand stickyNav__brand--desktop">
                    <img src="@/assets/simpli-logo-white.svg"/>
                </a>
            </div>

            <div class="creativePreview" :class="{'creativePreview__demo--height' : currentSection == 'demo' && notDesktop && !isCTV}">

                <div v-if="overviewDesktopShow && !notDesktop" class="creativePreview__overlay" @click="toggleOverviewDesktop()"></div>

                <div v-if="currentSection=='overview' || overviewDesktopShow && !notDesktop" class="creativePreview__panel creativePreview__panel--overview">
                    <div class="overview">

                        <div class="overview__close" v-if="device=='desktop'">
                            <a @click="toggleOverviewDesktop()">
                                <font-awesome-icon icon="times" class="i" />
                            </a>
                        </div>

                        <div class="overview__logo" v-if="device=='desktop'">
                            <img class="box__brand" src="@/assets/simpli-logo.svg" />
                        </div>

                        <div class="overview__infoBox">
                            <header class="overview__name">{{creative.name}}</header>

                            <div class="overview__infoBox">
                                <div v-if="creative.description" class="overview__infoBox__row overview__description" v-html="creative.description"></div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div v-if="currentSection=='demo'" class="creativePreview__panel creativePreview__panel--demo">
                    <div class="creativePreview__panel__controls" v-show = "creative.format !== 'Advanced CTV' || creative.format !== 'VidStream+'">
                        <ul>
                            <li v-if="creative.supports_mobile == 1 && $store.getters.authenticated" :selected="demo.controls.previewSelectExpanded">
                                <a @click="demo.controls.previewSelectExpanded=!demo.controls.previewSelectExpanded;
                                           demo.controls.deviceSelectExpanded=false">
                                    <font-awesome-icon icon="eye" class="i" />
                                </a>
                                <ul>
                                    <li class="preview-site">
                                       <div class="mt1 mb0">
                                        <FormField id="form-wrapper-courier" class="mb1" type="select">
                                                <select v-model="websiteOption.selected" id="tools-website" @change="onChangeWebsite($event)">
                                                    <option disabled>Select a website:</option>
                                                    <optgroup label="Simpli Mock Sites">
                                                            <option value="creativepreview.html">Creative Preview</option>
                                                    </optgroup>
                                                    <optgroup label="Publisher sites (cached)">
                                                            <option v-for="(website, index) in websites" :key="index" :value="website.value">{{website.name}}</option>
                                                    </optgroup>
                                                </select>
                                            </FormField>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li v-if="creative.supports_mobile == 1" :selected="demo.controls.deviceSelectExpanded">
                                <a @click="demo.controls.deviceSelectExpanded=!demo.controls.deviceSelectExpanded;
                                           demo.controls.previewSelectExpanded=false">
                                    <font-awesome-icon icon="mobile-alt" class="i" />
                                </a>
                                <ul>
                                    <li :selected="demo.currentDevice=='sm'">
                                        <a @click="demo.currentDevice==='desktop' && demoRefresh(); demo.currentDeviceType ='mobile'; demo.currentDevice='sm'; demo.controls.deviceSelectExpanded=false;">S</a>
                                    </li>
                                    <li :selected="demo.currentDevice=='md'">
                                        <a @click="demo.currentDevice==='desktop' && demoRefresh(); demo.currentDeviceType ='mobile'; demo.currentDevice='md'; demo.controls.deviceSelectExpanded=false;">M</a>
                                    </li>
                                    <li :selected="demo.currentDevice=='lg'">
                                        <a @click="demo.currentDevice==='desktop' && demoRefresh(); demo.currentDeviceType ='mobile'; demo.currentDevice='lg'; demo.controls.deviceSelectExpanded=false;">L</a>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="creative.supports_desktop == 1">
                                <a @click="demo.currentDeviceType ='desktop'; demo.currentDevice='desktop'; demoRefresh();
                                          demo.controls.previewSelectExpanded=false; demo.controls.deviceSelectExpanded=false">
                                    <font-awesome-icon icon="desktop" class="i" />
                                </a>
                            </li>
                            <li v-if="!isCTV">
                                <a @click="demoRefresh">
                                    <font-awesome-icon icon="sync-alt" class="i" />
                                </a>
                            </li>
                            <li v-if="creative.format === 'VidStream+'">
                                <a @click="demoExpandCollapse">
                                    <font-awesome-icon :icon="computedIcon" class="i" />
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="demoPresentation" :class="'demoPresentation---'+demo.currentDeviceType" v-if="creative.format!=='Advanced CTV' && creative.format!=='VidStream+'">
                        <div class="demoPresentation__inner">
                            <div class="demoPresentation__viewport" :class="'demoPresentation__viewport--'+demo.currentDevice">
                                <iframe v-if="device=='desktop'" :src="'/assets/mocksites/'+ this.websiteOption.selected +'?tag='+tagToRender"></iframe>
                                <div v-if="device=='mobile'"  v-html="mockSiteMarkUp" class="mockSite"></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="restrictOnMobile" class="d-flex align-items-center justify-content-center p-4" style="height: 100vh">
                            This creative is a desktop-only creative experience. Please preview on Desktop.
                    </div>
                    <!-- VidStream fullscreen and Iframe  -->
                    <div id="iframe-container" class="iframe-container" v-show="creative.format === 'VidStream+' && isIframe && !fullScreenToggle && !restrictOnMobile" :class="{'hide': device ==='mobile'}">
                        <iframe v-if="creative.format === 'VidStream+' && isIframe && !fullScreenToggle" id="iframe-studio" class="iframe-studio" :src="'/assets/mocksites/creativepreview.html'+'?tag='+tagToRender"></iframe>
                    </div>
                    <div id="vidStreamContainer" class="iframe-container" v-show="creative.format === 'VidStream+' && !isIframe && !fullScreenToggle && !restrictOnMobile">
                        <div v-if="creative.format === 'VidStream+' && !isIframe && !fullScreenToggle" id="vidStreamStudio" class="iframe-studio" ></div>
                    </div>
                    <!-- Default Advance CTV -->
                    <div class="designPresentation__inner_dark" v-show="creative.format === 'Advanced CTV' && !restrictOnMobile" style="width: 670px;">
                        <div id="container">
                            <div id="player"></div>
                        </div>
                    </div>
                    

                    <!-- VidStream with CreativePreview -->
                    <div class="demoPresentation" :class="'demoPresentation---'+demo.currentDeviceType" v-show="creative.format === 'VidStream+' && fullScreenToggle && !restrictOnMobile">
                        <div class="demoPresentation__inner">
                            <div class="demoPresentation__viewport" :class="'demoPresentation__viewport--desktop'">
                                <iframe v-if="device=='desktop' && isIframe && fullScreenToggle" :src="'/assets/mocksites/creativepreview.html'+'?tag='+tagToRender+'&format=vidstream'"></iframe>
                                <iframe v-if="!isIframe && fullScreenToggle" :src="'/assets/mocksites/creativepreview_vast.html'+'?tag='+vastToRender"></iframe>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="mt-20" v-if="this.device === 'mobile' && this.creative.format === 'VidStream+'">
                        <h2>Please preview from a desktop device.</h2>
                    </div> -->
                    <div class="creativePreview__panel__footer">&copy; {{ new Date() | moment('YYYY') }}. This is a legal, probably you will not be that nice, but we want you to be nice so we don’t sue you. Best, us.</div>
                </div>

                <div v-show="currentSection=='design'"  :class="{'hide': (currentSection !='design')}"  class="creativePreview__panel creativePreview__panel--design">
                    <div class="creativePreview__panel__tabs">
                        <ul>
                            <li selected>
                                <a @click="currentSection='design'">Design</a>
                            </li>
                            <li v-if="creative.demoTag != ''">
                                <a @click="currentSection='demo'">Demo</a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="designPresentation">
                        <div v-else class="designPresentation__inner">
                            <div id="designPresentation__viewport"  class="designPresentation__viewport designPresentation__viewport--md" :style="'--design-viewport-height:'+design.viewportHeight+'px'">
                                <div  class="designPresentation__slides">
                                    <ul>
                                        <li v-for="(designImage, index) in creative.designImages" :onLoad="resize(index)"  :id="'current_' + index"  :key="index" :class="{'current': (index==design.currentSlide)}" :style="{'background-image': 'url('+designImage.path+')'}"></li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    <div class="designPresentation">
                        <div class="designPresentation__inner_dark" v-if="creative.format === 'Advanced CTV' || creative.format === 'VidStream+'">
                            <div id="designPresentation__viewport"  class="custom-size designPresentation__viewport designPresentation__viewport--md ">
                                <div  class="designPresentation__slides">
                                    <ul>
                                        <li v-for="(designImage, index) in creative.designImages"  :id="'current_' + index"  :key="index" :class="{'current': (index==design.currentSlide)}" :style="{'background-image': 'url('+designImage.path+')'}"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="designPresentation__inner" v-else>
                            <div id="designPresentation__viewport"  class="designPresentation__viewport designPresentation__viewport--md" :style="'--design-viewport-height:'+design.viewportHeight+'px'">
                                <div  class="designPresentation__slides">
                                    <ul>
                                        <li v-for="(designImage, index) in creative.designImages" :onLoad="resize(index)"  :id="'current_' + index"  :key="index" :class="{'current': (index==design.currentSlide)}" :style="{'background-image': 'url('+designImage.path+')'}"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="designPresentation__controls" v-show="creative.format !== 'Advanced CTV' || creative.format !== 'VidStream+'" id="hide">
                            <div class="designPresentation__controls__breadcrumbs">
                                <ul>
                                    <li v-for="(designImage, index) in creative.designImages" :key="index" :selected="index==design.currentSlide"></li>
                                </ul>
                            </div>
                            <a class="designPresentation__controls__control designPresentation__controls__control--prev" @click="designPrev()">
                                <font-awesome-icon icon="angle-left" class="i" />
                            </a>
                            <a class="designPresentation__controls__control designPresentation__controls__control--next" @click="designNext()">
                                <font-awesome-icon icon="angle-right" class="i" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <div v-html="linkedinInsight"></div>

    </div>
</template>

<script>
import FormField from '@/components/FormField.vue'
import { mapGetters, mapActions } from 'vuex'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'
import ApiUser from '@/helpers/ApiUser.js'
import Amplify from 'aws-amplify'
import pako from 'pako'

export default {
  name: 'CreativeRepositoryCreative',
  components: {
    FormField
  },
  data () {
    return {
      loading: true,
      creative: {
        name: '',
        format: '',
        creative_type: '',
        designImages: [],
        performanceMetrics: [],
        description: '',
        type: '',
        supports_mobile: false,
        supports_desktop: false,
        demoTag: ''
      },
      currentSection: 'demo',
      overviewDesktopShow: false,
      demo: {
        currentDeviceType: 'mobile',
        currentDevice: this.deviceThatFits(),
        controls: {
          deviceSelectExpanded: false,
          previewSelectExpanded: false
        }
      },
      design: {
        viewportHeight: 0,
        currentSlide: 0
      },
      windowWidth: window.innerWidth,
      is320x50: [],
      websiteOption: {
        selected: this.getQueryVariable('site') || 'creativepreview.html'
      },
      isCTV: false,
      isIframe: false,
      fullScreenToggle: true,
      vastDemo: null
    }
  },
  created () {
    const self = this
    const promises = []

    ApiUser.gethealthcheck() // pass true for dummy data
      .then(data => {
        if (data.region == 'us-west-2') {
          localStorage.current_region = data.region
          this.setRegionAuth(data.region)
        } else if (data.region == 'us-east-1') {
          this.setRegionAuth(data.region)
          localStorage.current_region = data.region
        }
      })
      .catch(error => {
        console.log('Some error', error)
      })

    // get data
    Api.getCreativePreviewById(self.$route.params.id, true)
      .then(creativeData => {
        const self = this

        if (creativeData.campaignStartDate) creativeData.campaignStartDate = self.$moment(creativeData.campaignStartDate).utc()
        if (creativeData.campaignEndDate) creativeData.campaignEndDate = self.$moment(creativeData.campaignEndDate).utc()

        // sort performance metrics
        creativeData.performanceMetrics = _.sortBy(creativeData.performanceMetrics, 'order')

        self.$log.info('creative:', creativeData)
        // FT Adhesion name
        const pickAttributesOnlyName = _.pick(creativeData, ['name'])
        self.is320x50 = Utils.searchItemsByText([pickAttributesOnlyName], '320x50')

        self.creative = creativeData
        self.creative.designImages = creativeData.assets

        if (self.creative.format === 'Iframe') {
          self.demo.currentDeviceType = 'desktop'; self.demo.currentDevice = 'desktop'
        //   self.fullScreenToggle = false
        }
        self.creative.format === 'Advanced CTV' || self.creative.format === 'VidStream+' ? self.isCTV = true : self.isCTV = false
        self.isIframe = !!self.creative.iframe_vidstream

        if (this.device === 'mobile' && !this.isIframe && this.fullScreenToggle) {
            // this.fullScreenToggle = !this.fullScreenToggle;
        }

        if (self.creative.format === 'VidStream+') this.handleVideoControlBarForVidstream()

        // set default section (design|demo)
        // if a demo tag came through
        if (!_.isEmpty(self.creative.demoTag)) {
          // add this script for an Airtory fix on PadSquad preview
          if (self.isCTV) {
            self.currentSection = 'demo'
            self.vastDemo = self.creative.demoTag
            self.setDemoVideo()
          } else {
            self.creative.demoTag = '<scr' + 'ipt>(screen.width>640||window.matchMedia&&window.matchMedia("only screen and (min-width: 640px)").matches)&&(window.name="native");</scr' + 'ipt>' + self.creative.demoTag
            self.currentSection = 'demo'
          }
        } else {
          self.currentSection = 'design'
        }

        // turn off loading spinner
        self.loading = false

        self.$log.info('format', self.creative.format)
      })
      .catch(error => {
        self.$log.error(error)
        // take the user to simpli's home page if the creative does not exist.
        window.location.replace('https://padsquad.com/simpli')
      })
  },
  mounted () {
    const self = this

    // add an event listener for window.resize. This is used for a couple of things.
    window.addEventListener('resize', () => {
      // 1. this is to hide certain panels depending if mobile or not
      self.windowWidth = window.innerWidth

      // if this is the design section
      if (self.currentSection == 'design') {
        // 2. In mobile this is used to calculate the aspect ratio for vertical resize of the design slides container.
        self.design.viewportHeight = document.querySelector('.designPresentation__viewport').offsetHeight
      }

      self.resize(0)
    })

    window.addEventListener('DOMContentLoaded', () => {
      // self.resize();
    })
  },
  computed: {
    quarters: function () {
      const self = this

      if (self.creative.campaignStartDate && self.creative.campaignEndDate) {
        // date on creatives is MM/DD/YYYY. Watch out for any future changes
        const startQuarter = Utils.getQuarterFromMonth(self.creative.campaignStartDate.month())
        const endQuarter = Utils.getQuarterFromMonth(self.creative.campaignEndDate.month())
        // how many quarters the flight spreads acros
        const numberOfQuarters = Math.abs(endQuarter - startQuarter) + 1

        const quartersList = _.times(numberOfQuarters, key => {
          let q = startQuarter + key

          if (q > 4) q = q - 4

          return `Q${q}`
        })

        // compose an array with the quarters the campaign ran on.
        return quartersList
      } else {
        return []
      }
    },
    getBrandscapeFTAdhesionClassName: function () {
      // return (this.creative.format == 'Brandscape' || this.creative.format == 'FT Adhesion' || this.creative.format == 'Reactive Tile') ? 'BrandscapeFTAdhesion' : '';
      return (this.creative.expandable === 1) ? 'BrandscapeFTAdhesion' : ''
    },
    getBrandscapeFTAdhesion320x50: function () {
      return (this.is320x50.length > 0 && creative.format === 'FT Adhesion') ? 'FTAdhesion320x50' : ''
    },
    engagemenRate: function () {
      const self = this

      return Math.round((self.creative.engagements / self.creative.deliveredImpressions) * 100)
    },
    videoCompletionRate: function () {
      const self = this

      return Math.round((self.creative.videoCompletions / self.creative.deliveredImpressions) * 100)
    },
    viewabilityRate: function () {
      const self = this

      return Math.round((self.creative.viewableImpressions / self.creative.deliveredImpressions) * 100)
    },
    notDesktop: function () {
      const self = this

      return self.windowWidth < 768
    },
    device: function () {
      const self = this

      return (self.notDesktop) ? 'mobile' : 'desktop'
    },
    tagToRender: function () {
      const self = this

      // let renderType = _.find(self.formats, format => format.id == self.creative.format_id).demoRenderStyle || false;
      let renderType = false

      // if we selected Brandscape or FT (Adhesion) format we'll replace web.js for preview.js in the TAG
      // let id = self.creative.format_id;
      const formatName = self.creative.format

      // console.log(formatName)
      // Brandscape id:2
      // FT (Adhesion) id:36
      // if(parseInt(id) == 2 || parseInt(id) == 36 || ) {
      // if(formatName === 'Brandscape' || formatName === 'FT Adhesion' ||  formatName === 'Reactive Tile'  || self.creative.expandable === 1) {
      if (self.creative.expandable === 1) {
        const tag = self.creative.demoTag
        self.creative.demoTag = tag.replace('web.js', 'preview.js')
        renderType = 'straight'
      }

      switch (renderType) {
        case 'straight':
          return _.trim(btoa(self.creative.demoTag))
          break

        default:
          return btoa(`<iframe srcdoc="&lt;script&gt;var inDapIF = true; &lt;/script&gt; ${_.trim(_.escape(self.creative.demoTag))}"></iframe>`)
          break
      }
    },
    restrictOnMobile () {
        const isVideo = this.creative.format === 'Advanced CTV' || this.creative.format === 'VidStream+'
        return this.device == 'mobile' && !this.creative.crossScreen && isVideo
    },
    vastToRender: function () {
      const self = this
      // Pako is used to compress the VAST tag,then is encoded to base64
      const compressedData = pako.gzip(self.creative.demoTag)
      const base64Data = btoa(String.fromCharCode.apply(null, compressedData))
      return base64Data
    },
    computedIcon () {
      return this.fullScreenToggle ? 'expand-alt' : 'compress-alt'
    },
    ...mapGetters('quickview', [
      'websites'
    ]),
    mockSiteIframe: function () {
      const self = this

      return `
                <!doctype html>
                <html lang='en'>
                <head>
                    <meta charset='UTF-8'>
                    <meta name='viewport' content='width=device-width, initial-scale=1.0'>
                    <meta http-equiv='X-UA-Compatible' content='ie=edge'>
                    <link rel='stylesheet' type='text/css' href='https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css'>
                    <link rel='stylesheet' type='text/css' href='https://use.typekit.net/adn0bei.css'>

                    <style>

                        html {
                            height: 100%;
                            font-size: 62.5%; /* Now 10px = 1rem! */
                            font-family: 'montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                            overflow-x:hidden;
                        }

                        html::-webkit-scrollbar {
                            display: none;
                        }

                        body {
                            font-size: 1.2rem;
                            line-height: 1.5em;
                            color: #707070;
                        }

                        #page-container{
                            font-size: 1.2rem;
                            line-height: 1.5em;
                            text-align: left;
                            padding: 0 30px 0;
                        }

                    <script>

                    window.addEventListener('load', function() {
                        var toast = document.getElementById('toast');
                        toast.classList.add('show');
                    })
                    window.addEventListener("scroll", Scroll);
                    function Scroll(){
                        var toast = document.getElementById('toast');
                        var browserbar = document.querySelector(".browser-toolbar");
                        var ypos = window.pageYOffset;
                        if (ypos > 2) {
                            browserbar.classList.add("hide-browser-toolbar");
                        } else {
                            browserbar.classList.remove("hide-browser-toolbar");
                        }
                        if (ypos > 150) {
                            toast.classList.remove("show");
                            toast.classList.add('hide');
                        } else {
                            toast.classList.remove("hide");
                            toast.classList.add('show');

                        }

                    }
                    <\/script>
                </head>
                <body>
                    ${self.mockSiteMarkUp}
                </body>
                </html>`
    },
    mockSiteMarkUp: function () {
      const self = this

      return `
                <style>
                    #page-container{
                        font-size: 1.2rem;
                        line-height: 1.5em;
                        text-align: left;
                        padding: 0 30px 0;
                    }

                    h1{ font-size: 1.4em }

                    header{
                        min-height: 50px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        -webkit-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.098);
                        -moz-box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.098);
                        box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.098);
                        padding: 0 30px 0;
                        border-top: 1px solid rgba(227, 227, 227, 0.803);
                    }

                    header h1{
                        font-weight: 600;
                        color: #707070;
                        margin-top: 4px;
                    }

                    .title, .title_deco{
                        display: flex;
                        align-items: center;
                    }

                    .title_deco{
                        margin-left: 0.5em;
                    }

                    .title_deco .deco{
                        width: 10px;
                        height: 10px;
                        background-color: #CCCCCC;
                        opacity: 0.5;
                        margin-right: -2px;
                        transform: rotate(45deg);
                    }

                    .menu_burger .divider{
                            width: 24px;
                            height: 4px;
                            background-color: #EDEDED;
                            margin-bottom: .4em;
                            border-radius: 10px;


                    }

                    article {
                        padding: 4px 0;
                        color: #646464;
                    }

                    .block_main article p:nth-child(n+5){ display: none }
                    .block_main article p{ line-height: 2rem }


                    .adBreak {
                        width: 100vw;
                        margin-left: -30px;
                        min-height: 50px;
                        margin-bottom: 15px;
                    }

                    .adBreak * {
                        max-height: 100vh !important;
                    }

                    .adBreak .celtra-ad-inline-host{
                        min-height: 100vh !important;
                        max-height: 100% !important;
                    }

                    .adBreak .celtra-ad-inline-host *, .adBreak .celtra-ad-inline-host .notranslate, .adBreak .celtra-ad-inline-host .notranslate * {
                        max-width: 100% !important;
                    }

                    .BrandscapeFTAdhesion .celtra-ad-inline-host{
                        min-height: auto !important;
                    }

                    .FTAdhesion320x50 .ps-tag {
                        position: fixed;
                        margin: auto;
                        top: auto;
                        bottom: 0px;
                        left: 0px;
                        width: 100vw;
                        height: auto;
                        z-index: 1000000;
                    }

                    .postImage {
                        width: 100%;
                        background-color:#e6e5e5;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 1em 0;
                        font-size: 1.2em;
                    }

                    .postImage_small{ height: 120px }
                    .postImage_medium{ height: 250px }
                    .postImage_large{ height: 270px }

                    .main_title{
                        font-size: 2rem;
                        margin-top: 1em;
                    }

                    h3 {
                        font-size: 1.4rem;
                        margin-bottom: 15px;
                        font-weight: 500;
                        line-height: 1.3em;
                    }

                    p {
                        font-size: 1.2rem;
                        font-weight: 100;
                    }

                    footer {
                        font-size: 1rem;
                        padding: 14px;
                        background: #FAFAFA;
                        text-align: center;
                        margin-top: 3em;
                    }

                    footer h3 { margin-bottom: 2px;}

                    footer p { margin: 0;}

                    .user_info{
                        display: flex;
                        align-items: center;
                        padding-left: 2em;
                    }

                    .icon_user{
                        width: 24px;
                        height: 24px;
                        background-color: #D5D5D5;
                        border-radius: 50px;
                    }

                    .user_name, .user_date{
                        padding: 0 1em;
                        font-size: .8em;
                    }

                    .block_filled{
                        background-color: #f5f5f5;
                        text-align: center;
                        padding: 2em 3em;
                    }

                    .block_filled p, .block_filled h3{
                        padding: 0;
                        margin: 0;
                    }

                    .block_desktop{ display: none }

                    .user_name{ font-weight: 500}

                    .user_date{ color: #D5D5D5}

                </style>

                <header>
                    <div class="title">
                        <h1> CREATIVE PREVIEW </h1>
                        <div class="title_deco">
                            <div class="deco"></div>
                            <div class="deco"></div>
                        </div>
                    </div>
                    <div class="menu_burger">
                        <div class="divider"></div>
                        <div class="divider"></div>
                    </div>
                </header>

                <div id="page-container">
                    <div class="block block_main">
                        <article>
                            <h3 class="main_title">Stop the Scroll with Memorable Creative by PadSquad</h3>
                            <div class="user_info">
                                <div class="icon_user"></div>
                                <p class="user_name">By Jonathan Jameson</p>
                                <p>—</p>
                                <p class="user_date">Jan 8, 2017</p>
                            </div>
                            <p class="postImage postImage_large">SCROLL TO SEE THE AD</p>
                        </article>

                        <article>
                            <p>Amet consectetur adipiscing elit ut. Fringilla urna porttitor rhoncus dolor. Lectus nulla at volutpat diam ut venenatis tellus. Odio tempor orci dapibus ultrices. Suspendisse potenti nullam ac tortor</p>

                            <p>Id venenatis a condimentum vitae sapien pellentesque. Nunc faucibus a pellentesque sit amet porttitor. Donec massa sapien faucibus et molestie ac feugiat sed. Amet volutpat consequat mauris nunc congue. Aliquam ultrices sagittis orci a scelerisque purus semper eget. Eget arcu dictum varius.</p>

                            <p>Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Scelerisque varius morbi enim nunc faucibus a pellentesque sit. Eget magna fermentum iaculis eu. Faucibus purus in massa tempor nec feugiat. Ornare aenean euismod elementum nisi quis eleifend quam.</p>

                            <p>Vel facilisis volutpat est velit. Iaculis eu non diam phasellus vestibulum lorem sed. Magna sit amet purus gravida quis blandit turpis cursus. Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Sed blandit libero volutpat sed cras ornare arcu dui vivamus. Habitant morbi tristique senectus et netus et malesuada fames ac. Tristique risus nec feugiat in fermentum posuere urna. Habitant morbi tristique.</p>

                            <p> duis at consectetur lorem. Ultricies lacus sed turpis tincidunt id aliquet risus. Vivamus arcu felis bibendum ut tristique et egestas quis ipsum. Augue interdum velit euismod in pellentesque. Vehicula ipsum a arcu cursus vitae. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant.</p>

                            <p>senectus et. Diam in arcu cursus euismod quis viverra nibh cras. Congue nisi vitae suscipit tellus mauris a. Diam maecenas sed enim ut sem viverra aliquet. Et malesuada fames ac turpis egestas.</p>

                            <p> ac auctor augue mauris augue neque. Dui id ornare arcu odio ut. Ornare arcu dui vivamus arcu. Nisi vitae suscipit tellus mauris a diam. Metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Metus aliquam eleifend mi in nulla posuere.</p>

                            <p>Pulvinar elementum integer enim neque volutpat ac tincidunt vitae semper. Velit egestas dui id ornare arcu odio ut. Fringilla ut morbi tincidunt augue. Scelerisque varius morbi enim nunc. Id interdum velit laoreet id donec ultrices tincidunt. Sapien et ligula ullamcorper malesuada proin.</p>

                            <p>Volutpat commodo sed egestas egestas fringilla phasellus. Vulputate dignissim suspendisse in est ante in nibh mauris cursus. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et netus. Volutpat odio facilisis mauris sit amet. Scelerisque fermentum dui faucibus in ornare quam viverra. Mauris nunc congue nisi vitae suscipit tellus mauris a. Feugiat in ante metus dictum at tempor.</p>

                            <p>vitae turpis massa. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Amet nulla facilisi morbi tempus. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula. Consectetur adipiscing elit ut aliquam purus sit amet luctus. Eu non diam phasellus vestibulum.</p>

                            <p>Iaculis urna id volutpat lacus laoreet. Amet justo donec enim diam. Amet facilisis magna etiam tempor orci eu lobortis elementum nibh. Augue ut lectus arcu bibendum at varius vel pharetra. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Viverra ipsum nunc aliquet bibendum enim facilisis. Vulputate mi sit amet mauris. Feugiat in ante metus dictum at tempor.</p>

                            <p>Volutpat ac tincidunt vitae semper. Tortor at risus viverra adipiscing at in tellus. Quam pellentesque nec nam aliquam sem et tortor. Pharetra sit amet aliquam id. Neque gravida in fermentum et sollicitudin ac orci phasellus egestas. Volutpat ac tincidunt vitae semper.</p>

                        </article>

                        <article>
                            <div class="adBreak ${self.getBrandscapeFTAdhesionClassName} ${self.getBrandscapeFTAdhesion320x50}">
                                <iframe srcdoc="&lt;script&gt;var inDapIF = true; &lt;/script&gt; ${this.replaceDemoTagPreview()}"></iframe>
                            </div>
                        </article>

                        <div class="block block_filled">
                            <article>
                                <div class='postImage postImage_small'></div>
                                <p>vitae turpis massa. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Amet nulla facilisi morbi tempus. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula.</p>
                                <br/>
                                <p>Iaculis urna id volutpat lacus laoreet. Amet justo donec enim diam. Amet facilisis magna etiam tempor orci eu lobortis elementum nibh. Augue ut lectus arcu bibendum at varius vel pharetra. Malesuada bibendum arcu vitae elementum curabitur vitae nunc.</p>
                                <br/>
                                <p>Volutpat ac tincidunt vitae semper. Tortor at risus viverra adipiscing at in tellus. Quam pellentesque nec nam aliquam sem et tortor. Pharetra sit amet aliquam id. Neque gravida in fermentum et sollicitudin ac orci phasellus egestas.Volutpat ac tincidunt vitae semper aliquam sem et.</p>
                            </article>

                            <article>
                                <div class='postImage postImage_medium'></div>
                            </article>

                            <article>
                                <div class='postImage postImage_small'></div>
                                <p>vitae turpis massa. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Amet nulla facilisi morbi tempus. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula.</p>
                                <br/>
                                <p>Iaculis urna id volutpat lacus laoreet. Amet justo donec enim diam. Amet facilisis magna etiam tempor orci eu lobortis elementum nibh. Augue ut lectus arcu bibendum at varius vel pharetra. Malesuada bibendum arcu vitae elementum curabitur vitae nunc.</p>
                                <br/>
                                <p>Volutpat ac tincidunt vitae semper. Tortor at risus viverra adipiscing at in tellus. Quam pellentesque nec nam aliquam sem et tortor. Pharetra sit amet aliquam id. Neque gravida in fermentum et sollicitudin ac orci phasellus egestas.Volutpat ac tincidunt vitae semper aliquam sem et.</p>
                            </article>
                        </div>
                    </div>
                </div>

                <footer>
                    <h3>Thanks for watching.</h3>
                    <p>&copy; 2019. PadSquad LLC.</p>
                </footer>`
    },
    linkedinInsight: function () {
      const self = this

      return `
                <!-- LinkedIn Insights tag -->
                <script type="text/javascript"> _linkedin_partner_id = "1539297"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); <\/script><script type="text/javascript"> (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(); <\/script> <noscript> <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1539297&fmt=gif" /> </noscript>
            `
    },
    fullScreenToggleMobileVast() {
      return this.device === 'mobile' && !this.isIframe && this.fullScreenToggle;
    }
  },
  methods: {
    handleVideoControlBarForVidstream () {
        const vids = document.querySelectorAll('#vidStreamContainer')
        for (const vid of vids) {
            vid.addEventListener('mouseover', () => {
                const controlBars = document.querySelectorAll('.vjs-control-bar')
                for (const el of controlBars) {
                    el.style.visibility = 'visible'
                    el.style.opacity = 1
                }
            })
            vid.addEventListener('mouseleave', () => {
                const controlBars = document.querySelectorAll('.vjs-control-bar')
                for (const el of controlBars) {
                    el.style.visibility = null
                    el.style.opacity = null
                }
            })
        }
    },
    setDemoVideo: function () {
      const self = this
      const vastFormat = self.creative.format === 'Advanced CTV' ? '#player' : '#vidStreamContainer'
      const videoContainer = document.querySelector(vastFormat)
      self.createVideoEl(videoContainer, function (videoEl) {
        const player = videojs(videoEl)
        player.vastClient({
          // Media tag URL
          adTag: '',
          adTagXML: self.requestVASTXML,
          playAdAlways: true,
          // Note: As requested we set the preroll timeout at the same place than the adsCancelTimeout
          adCancelTimeout: 6000,
          adsEnabled: true
        })

        // reloading the page when ads end to restart the another video
        player.on('vast.adEnd', function () {
          window.location.reload()
        })
      })
    },
    replaceDemoTagPreview: function () {
      if (this.creative.demoTag.indexOf('preview.js') !== -1) {
        return _.trim(_.escape(this.creative.demoTag.replace('preview.js', 'web.js')))
      } else {
        return _.trim(_.escape(this.creative.demoTag))
      }
    },
    demoRefresh: function () {
      const self = this

      const demoTag = _.cloneDeep(self.creative.demoTag)

      // premise: we will perform by updating the creative.demoTag,
      // which will trigger the mockSiteMarkUp computed value to update. Hence refreshing it.

      // change the demo tag to something else.
      self.creative.demoTag = '_'

      // and put it back in.
      setTimeout(() => {
        self.creative.demoTag = demoTag
      }, 100)
    },
    demoExpandCollapse: function () {
      const self = this
      const demoTag = _.cloneDeep(self.creative.demoTag)

      self.fullScreenToggle = !self.fullScreenToggle
      // change the demo tag to something else.
      self.creative.demoTag = '_'

      // and put it back in.
      setTimeout(() => {
        self.creative.demoTag = demoTag
        self.vastDemo = demoTag
        self.setDemoVideo()
      }, 100)
    },
    designNext: function () {
      const self = this

      if (self.design.currentSlide < self.creative.designImages.length - 1) {
        self.design.currentSlide++
      } else {
        self.design.currentSlide = 0
      }
    },
    designPrev: function () {
      const self = this

      if (self.design.currentSlide > 0) {
        self.design.currentSlide--
      } else {
        self.design.currentSlide = self.creative.designImages.length - 1
      }
    },
    deviceThatFits: function () {
      if (window.innerHeight < 800) {
        return 'sm'
      } else {
        return 'md'
      }
    },
    toggleOverviewDesktop: function () {
      this.overviewDesktopShow = !this.overviewDesktopShow
    },
    ...mapActions('quickview', [
      'setWebsite'
    ]),
    onChangeWebsite (event) {
      // set website
      this.setWebsite(this.websiteOption.selected)

      // hide drop down
      this.demo.controls.previewSelectExpanded = false

      // Adding the website to the actual url as a parameter
      window.history.pushState({}, '', '?site=' + this.websiteOption.selected)
    },
    getQueryVariable (variable) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')

      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] == variable) { return pair[1] }
      }
      return (false)
    },
    resize (index) {
      if (index != 0) { return }
      // THIS SHOULD BE APPLIED ONLY FOR DESIGN PREVIEW

      // get the image wrapper user for design presentation
      const designViewport = document.getElementById('designPresentation__viewport')
      // get the current wrapper height
      const frameHeight = designViewport.clientHeight
      // get the image url from the image attached
      var _image = document.getElementById('current_0')
      var _image = designViewport.querySelector('.current')

      if (_image != null) {
        const _style = _image.currentStyle || window.getComputedStyle(_image, false)
        const _source = _style.backgroundImage.slice(4, -1).replace(/"/g, '')
        // create a new image
        const newImage = new Image()
        newImage.src = _source

        // change the image wrapper width after image has loaded
        function imageLoaded () {
          const newWidth = designViewport.clientHeight * newImage.width / newImage.height + 'px'
          designViewport.style.width = newWidth
        }

        if (newImage.complete) {
          imageLoaded()
        } else {
          newImage.addEventListener('load', imageLoaded)
          newImage.addEventListener('error', function () {
            // console.log('error');
          })
        }
      }
    },
    requestVASTXML: function (callback) {
      const self = this
      setTimeout(function () {
        callback(null, self.creative.demoTag)
      }, 0)
    },
    createVideoEl: function (container, cb) {
      const self = this
      const videoTag = self.creative.format === 'Advanced CTV' ? '<video id="my-video" class="video-js vjs-styles-dimensions vjs-vpaid-ad vjs-vpaid-html5-ad vjs-ad-playing vjs-default-skin vjs-big-play-centered vjs-paused  vjs-user-active" controls preload="auto" style="width: 640px; height: 360px;"></video>' : '<video id="my-video" class="video-js vjs-styles-dimensions vjs-vpaid-ad vjs-vpaid-html5-ad vjs-ad-playing vjs-default-skin vjs-big-play-centered vjs-paused vjs-user-active" controls preload="auto" playsinline ></video>'

      container.innerHTML = videoTag

      // We do this asynchronously to give time for the dom to be updated
      setTimeout(function () {
        const videoEl = container.querySelector('.video-js')
        cb(videoEl)
        // Initialize the video player with Video.js
        const player = videojs('my-video')
      }, 0)
    },
    setRegionAuth (region) {
      switch (region) {
        case 'us-east-1':
          console.log('EAST')
          Amplify.configure({
            Auth: {
              // REQUIRED - Amazon Cognito Identity Pool ID,
              identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID,
              // REQUIRED - Amazon Cognito Region
              region: process.env.VUE_APP_AUTH_REGION,
              // OPTIONAL - Amazon Cognito Federated Identity Pool Region
              // Required only if it's different from Amazon Cognito Region
              identityPoolRegion: process.env.VUE_APP_AUTH_IDENTITY_POOL_REGION,
              // OPTIONAL - Amazon Cognito User Pool ID
              userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,
              // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
              userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID

            }
          })
          break
        case 'us-west-2':
          // console.log('WEST')
          Amplify.configure({
            Auth: {
              // REQUIRED - Amazon Cognito Identity Pool ID,
              identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID_WEST,
              // REQUIRED - Amazon Cognito Region
              region: process.env.VUE_APP_AUTH_REGION_WEST,
              // OPTIONAL - Amazon Cognito Federated Identity Pool Region
              // Required only if it's different from Amazon Cognito Region
              identityPoolRegion: process.env.VUE_APP_AUTH_IDENTITY_POOL_REGION_WEST,
              // OPTIONAL - Amazon Cognito User Pool ID
              userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID_WEST,
              // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
              userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID_WEST

            }
          })
          break
        default:
          break
      }
      //
      if (localStorage.reload_dr == 'yes' || localStorage.reload_dr == undefined) {
        localStorage.reload_dr = 'no'
        window.location.reload(true)
      }
    }
  },
  watch: {
    notDesktop: function () {
      const self = this

      // if it just switched to desktop and it's in the overview sections...
      if (!self.notDesktop) {
        // set default section (design|demo)
        if (!_.isEmpty(self.creative.demoTag)) {
          self.currentSection = 'demo'
        } else {
          self.currentSection = 'design'
        }
      }
    },
    currentSection: function () {
      const self = this
      // if this is the design section
      console.log('currentSection', self.currentSection)
      if (self.currentSection == 'design') {
        setTimeout(() => {
          // In mobile this is used to calculate the aspect ratio for vertical resize of the design slides container.
          self.design.viewportHeight = document.querySelector('.designPresentation__viewport').offsetHeight
        }, 100)
      } else if (self.currentSection == 'demo' && self.isCTV) {
        setTimeout(() => {
          self.setDemoVideo()
        }, 100)
      }
    },
    mockSiteIframe: function () {
      const self = this
    },
    fullScreenToggleMobileVast() {
      if (this.device === 'mobile' && !this.isIframe && this.fullScreenToggle) {
        // this.fullScreenToggle = !this.fullScreenToggle;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/common.scss";

html,
body,
.appFrame,
#app {
    height: 100%;
}

.celtra-expanded-ad{
    height: 300px !important;
}

// .moduleFrame.moduleFrame--CreativeRepositoryPreview {
//     top: 0;
//     height: 100vh;
//     background: #11433c;

//     @include media-breakpoint-up(md) {
//         display: block;
//         width: 100%;
//         left: 0px;

//         .creativePreview {
//             display: flex;
//         }
//     }
// }
.moduleFrame.moduleFrame--CreativeRepositoryPreview {
    top: 0;
    height: 100vh;

    @include media-breakpoint-up(md) {
        display: block;
        width: 100%;
        left: 0px;

        .creativePreview {
            display: flex;
        }
    }
}
.hide {
    visibility: hidden !important;
    display: none;
}
.mt-20 {
    margin-top: 20px;
}
.creativePreview {
    height: 100%;

    /* fixed scrolles demo bugs */
    &__demo--height{
        height: auto;
    }

    &__overlay{
        background-color: #00000085;
        width: 100%;
        position: absolute;
        height: 100%;
        z-index: 2;
    }

    .preview-site{
        background: #fafafa;
        width: 240px;
        height: auto;
        padding: 1em;

        .mt1{
            margin-top: 0.4em;
        }

        .mb1{
            margin-bottom: 0.4em;
        }

        select{
            line-height: 1.6em;
        }

        button{
            width: 100%;
        }
    }

    &__panel {
        &__tabs,
        &__controls,
        &__footer {
            display: none
        }

        &--overview {
            @extend .container-fluid;

            overflow-x: hidden;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &--demo {
            padding: 0px;
            width: 100%;
            height: 100%;

            overflow-x: hidden;
        }

        &--design {
            background: $gray2;
            width: 100%;
            height: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;

        &__panel {
            flex-grow: 1;
            overflow: scroll;
            position: relative;
            min-width: 540px;

            &__tabs {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;

                ul {
                    display: block;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    white-space: nowrap;

                    li {
                        display: inline-block;
                        color: white;

                        a {
                            display: block;
                            height: 6rem;
                            line-height: 6rem;
                            font-size: 2.4rem;
                            font-weight: 600;
                            color: white;
                            text-decoration: none;
                            width: 100%;
                            padding-left: $spacing-unit*2;
                            padding-right: $spacing-unit*2;
                            cursor: pointer;

                            &:hover {
                                background: $pink5;
                            }
                        }

                        &[selected] {
                            a {
                                color: $pink6;
                                background: white;

                                &:hover {
                                    color: $pink5;
                                }
                            }
                        }
                    }
                }
            }

            &__footer {
                position: absolute;
                top: calc(100% + #{$spacing-unit*8});
                left: 0;
                display: none !important;
                padding: $spacing-unit*2;
                color: white;
                font-size: 1rem;
            }

            &__controls {
                position: absolute;
                top: 50%;
                margin-top: -60px;
                right: $spacing-unit*2;
                width: 50px !important;
                z-index: 2;

                ul {
                    list-style: none;
                    margin: 0;
                    padding:0;

                    li {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        background: white;
                        margin-bottom: $spacing-unit;

                        &[selected] {

                            > a {
                                color: white;
                                background: $green4;
                            }

                            > ul {
                                display: block;
                                position: absolute;
                                top: 0;
                                right: calc(100% + #{$spacing-unit});

                                li {

                                    a {
                                        border: $pink5;
                                    }
                                }
                            }
                        }

                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            font-size: 1.5rem;
                            cursor: pointer;
                            color: $gray6;

                            &:hover {
                                background: $green4;
                                color: white !important;
                            }
                        }

                        & > ul {
                            display: none;
                        }
                    }
                }
            }

            &--overview {
                @include vertical-align();

                flex-grow: 1;
                width: 54%;
                max-width: 720px;
                min-width: 600px;
                overflow-x: hidden;

                position: absolute;
                z-index: 2;
                background: white;
                padding: 4rem;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &--demo,
            &--design {
                flex-grow: 1;
                width: 70%;

                @include vertical-align();
                padding: $spacing-unit*6 0 $spacing-unit*2;
                background: $green7;
            }

            &--demo {
                overflow-x:hidden;
            }
        }
    }
}

.overview {
    padding-top: $spacing-unit*4;

    &__close{
        position: absolute;
        right: 0;
        top: 0;
        background-color: $green9;

        a{
            padding: .5em;
            color: white !important;
            font-size: 2.4rem !important;
            cursor: pointer;
        }
    }

    &__logo{
        .box__brand{
            height: 3.5rem;
        }
    }

    &__type {
        font-size: 1.2rem;
        font-weight: 600;
        color: $green11;
        margin-bottom: $spacing-unit;
    }

    &__name {
        font-size: 1.6rem;
        font-weight: 600;
        color: $green7;
    }

    &__infoBox {

        margin-top: 2em;

        &:empty {
            border: none;
        }

        &__row {
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__flight {

        & > * {
            display: inline-block;
        }

        &__startDate,
        &__endDate {
            color: $green7;

        }

        &__yearSplit {
            margin-left: $spacing-unit/2;
            margin-right: $spacing-unit/2;
            position: relative;
            min-width: 25px;
            height: 25px;
            line-height: 25px;

            .curve {
                display: none;
            }

            :before {
                content: '';
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: $green4;
                top: 50%;
                left: 0;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    opacity: 0;
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    vertical-align: middle;
                    background: $green1;
                    border-radius: 100%;
                    color: white;
                    font-weight: bold;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;

                    &:not(:first-child) {
                        display: none;
                    }
                }
            }
        }
    }

    &__description {
        padding: $spacing-unit*2 0;

        h3 {
           color: $green11;
        }

        p {
            color: $gray6;
            line-height: 2rem;

            & + h3 {
                margin-top: 2rem;
            }
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    &__performance {
        &.overview__infoBox__row {
            padding-bottom: 0;
        }

        &__metric {
            margin-bottom: $spacing-unit*2;
            display: inline-block;
            width: 100%;

            &__value {
                font-size: 2.2rem;
                font-weight: 600;
                color: $green11;
            }

            &__label {
                color: $green7;
            }
        }
    }

    @include media-breakpoint-up(sm) {

        padding-top: 0;

        &__flight {
            :before {
                display: none;
            }

            &__yearSplit {
                margin-left: $spacing-unit;
                margin-right: $spacing-unit;

                ul {
                    position: relative;
                    li {
                        opacity: 1;

                        &:not(:first-child) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {

        text-align: left;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 3em;

        &__infoBox {

            margin-top: initial;
        }

        &__name {
            font-size: 2.4rem;
        }

        &__flight {
            &__yearSplit {
                margin-left: $spacing-unit;
                margin-right: $spacing-unit;

                .curve {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    /* display: block; */

                    img {
                        position: absolute;
                        width: calc(100% + #{1rem * 2});
                        left: -1rem;
                        bottom: 50%;
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: -1rem;
                        margin-top: -5px;
                        width: 5px;
                        height: 5px;
                        background: white;
                        border: 1px solid #DB2C91;
                        border-radius: 100%;
                        z-index: 1;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -1rem;
                        margin-top: -5px;
                        width: 5px;
                        height: 5px;
                        background: white;
                        border: 1px solid #DB2C91;
                        border-radius: 100%;
                        z-index: 1;
                    }

                }
            }
        }

        &__performance {

            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            &__metric {

                text-align: center;
                display: initial;
                width: 150px;

                &__value {
                    font-size: 2rem;
                }
            }
        }
    }
}

.demoPresentation {
    width: auto !important;
    height: 100%;
    margin-top: $spacing-unit*4;

    @media only screen and (max-width: 768px) {
        margin-top: 0;
    }

    @media only screen and (max-width: 1400px) {
        &---desktop{
            left: 3rem;
            position: absolute;
        }
    }

    &__inner {
        height: 100%;
    }

    &__viewport {
        height: 100%;

        iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        height: auto;

        &---mobile {
            position: relative;

              &:before{
                content: '';
                position: absolute;
                top: 19px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 14%;
                height: 5px;
                border-radius: 10px;
                background-color: $gray7;
                pointer-events: none;
            }

        }

        &__inner {
            display: inline-block;
            background: white;
            padding: 4rem 2.5rem;
            border-radius: 2rem;
        }

        &__inner_dark {
            display: inline-block;
            background: #181818;
            padding: 1.5rem 1.5rem;
        }

        &__viewport {
            border: 1px solid $gray7;
            position: relative;
            border-radius: .4rem;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 2px solid black;
                opacity: 0.05;
                pointer-events: none;
            }

            &--sm {
                width: 322px;
                height: 570px;
            }

            &--md {
                width: 375px;
                height: 667px;
            }

            &--lg {
                width: 414px;
                height: 736px;
            }

            &--desktop {
                max-width: 1350px;
                width: 82vw;
                height: 80vh;
            }
        }
    }
}

.designPresentation {
    position: relative;
    width: auto !important;
    height: 100%;

    &__inner {
        height: calc(100% - 51px);
        padding: $spacing-unit*5 0 $spacing-unit;
        background: $gray2;
    }

    &__viewport {
        display: inline-block;
        width: calc((var(--design-viewport-height) * 0.56 ));
        height: 100%;
        position: relative;
    }

    &__controls {

        display: flex;
        background: white;
        border-top: 1px solid $borders-color;

        &__breadcrumbs {
            flex-grow: 1;
            height: 50px;
            border-right: 1px solid $borders-color;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                height: 100%;
                line-height: 50px;
                padding: 0 30px;
                text-align: left;

                li {
                    display: inline-block;
                    line-height: 50px;
                    width: 12px;
                    height: 12px;
                    background: $gray3;
                    border-radius: 100%;
                    margin-right: 10px;

                    &[selected] {
                        background: $green7;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &__control {
            display: block;
            width: 50px;
            height: 50px;
            background: white;
            font-size: 2rem;
            line-height: 50px;
            color: $gray6;
            cursor: pointer;
            border-right: 1px solid $borders-color;

            &:last-child {
                border-right: none;
            }
        }
    }

    &__slides {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            width: 100%;
            height: 100%;

            li {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

                &.current {
                    display: block
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        height: auto;

        &__inner {
            display: inline-block;
            background: white;
            padding: 4rem 2.5rem;
            border-radius: 4rem;
        }

        &__inner_dark {
            display: inline-block;
            background: rgb(24, 24, 24);
            padding: 1.5rem 1.5rem;
        }

        &__viewport {
            border: 1px solid $gray2;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 2px solid black;
                opacity: 0.05;
                pointer-events: none;
            }

            &--sm {
                width: 322px;
                height: 570px;
            }

            &--md {
                width: 384px;
                height: 615px;
            }

            &--lg {
                width: 414px;
                height: 736px;
            }
        }

        &__controls {
            display: block;
            border-top: none;

            &__breadcrumbs {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 40px;
                border-right: none;

                ul {
                    line-height: 40px;
                    text-align: center;
                }
            }

            &__control {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                z-index: 1;
                border-right: none;

                &--prev {
                    left: -50px;
                }

                &--next {
                    right: -50px;
                }
            }
        }
    }
}

.stickyNav {
    @extend .no-select;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: $green10;
    z-index: 1;
    display: flex;
    display: none;

    ul {
        flex-grow: 1;
        list-style: none;
        margin: 0 0 0 10px;
        padding: 0;
        text-align: left;

        li {
            position: relative;
            height: 100%;
            line-height: 60px;
            display: inline-block;
            color: white;

            &[selected] {
                a {
                    color: $green7;
                    background: white;

                    &:hover {
                        color: $green7;
                        background: white;
                    }
                }
            }

            a {
                display: inline-block;
                cursor: pointer;
                padding: 0 $spacing-unit;

                &:hover {
                    background: white;
                }
            }

            &:hover{
                color: $green7;
            }
        }
    }

    &__overview--desktop{
        a{
            position: relative;
            height: 100%;
            line-height: 60px;
            display: inline-block;
            color: white !important;
            cursor: pointer;

            svg{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                line-height: 20px;
                vertical-align: middle;
                padding: 4px;
                border: 1px solid white;
                margin-left: 0.4em;
            }
        }
    }

    &__brand {
        width: 60px;
        height: 100%;
        line-height: 60px;
        color: white;
        background: $green10;

        img {
            height: 50%;
        }

        &--desktop {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
        display: flex;

        a{
            font-size: 1.6rem;
        }

        ul {
            text-align: right;

            li:first-child{
                display: none;
            }
        }

        &__brand {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: block;
                width: auto;
                padding: 0 $spacing-unit*2;
            }
        }

    }
}
.video-vjs, #player{
    width: 640px !important;
    height: 360px !important;
}
::v-deep .vjs-big-play-button:before {
    content: "▶" !important;
    font-family: "Arial", sans-serif;
    line-height: 2.6em;
    text-shadow: 0.05em 0.05em 0.1em #000;
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
}
div.video-js {
    width: 100% !important;
    height: 100% !important;
}

#vidStreamContainer ::v-deep div#my-video {
    width: 100% !important;
    height: 100% !important;

    .vjs-fullscreen-control {
        display: none;
    }

    .vjs-volume-control { 
        margin-right: 1rem;
    }
}

::v-deep video#my-video_html5_api {
    width: 100% !important;
    height: 100% !important;
}

::v-deep button.plyr__controls__item.plyr__control{
    display:none !important
}
.custom-size{
    @media only screen and (min-width: 768px) {
        width: 640px !important;
        height: 360px !important;
    }
    @media only screen and (min-width: 1400px) {
        width: 1280px !important;
        height: 720px !important;
    }
}

$aspect-ratio-width: 16;
$aspect-ratio-height: 9;
$iframe-container-width-percent: 75;

.iframe-container {
  position: relative;
  width: calc(#{$iframe-container-width-percent} * 1vw);
  height: calc(#{$iframe-container-width-percent} * 1vw * #{($aspect-ratio-height / $aspect-ratio-width)});
  max-height: 80vh;
  max-width: calc(80vh * #{($aspect-ratio-width / $aspect-ratio-height)});
  margin: 0 auto;
  overflow: hidden;
  background-color: #ffff;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
  // border: 15px solid rgb(24, 24, 24);
  box-sizing: content-box;

  .iframe-studio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    overflow: hidden;
  }

  @media (max-width: 768px), (max-height: 768px) {
    margin-top: 50%;
    border-width: 10px;
    border-radius: 0 !important;
    width: 100vw;
    height: calc(100vw * (9/16));
  }
}

@media (max-height: 568px) {
    .iframe-container {
        max-height: 62vh;
        max-width: calc(62vh * #{($aspect-ratio-width / $aspect-ratio-height)});
        border-width: 10px;
    }
}
</style>
