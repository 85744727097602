/** VUEX module for Quick View in Tag Generator section **/
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    websites: [
      // { name:'Creative Preview', value:"creativepreview.html" },
      { name: 'Clean Preview', value: 'clean-preview.html' },
      { name: 'Daily Parent', value: 'dailyparent.html' },
      { name: 'Fine Gardening - Shade Garden', value: 'Fine Gardening - Shade Garden.html' },
      { name: 'All Recipes - Vegetable Fajitas', value: 'allrecipes.html' },
      { name: 'All Recipes - Dirt Cake III', value: 'allrecipes2.html' },
      { name: 'All Recipes - Cookies', value: 'All Recipes - Cookies.html' },
      { name: "Men's Fitness", value: 'mensfitness.html' },
      { name: 'Fine Gardening - Brick Path', value: 'finegardening2.html' },
      { name: 'Muscle & Fitness', value: 'muscleandfitness.html' },
      { name: 'Fitness Magazine', value: 'fitnessmagazine.html' },
      { name: 'Oprah', value: 'oprah.html' },
      { name: 'Southern Living', value: 'Southern Living.html' },
      { name: 'Marie Claire', value: 'marieclaire.html' },
      { name: 'Heavy', value: 'heavy.html' },
      { name: 'Delish', value: 'delish.html' },
      { name: 'Elle', value: 'elle.html' },
      { name: 'Esquire', value: 'esquire.html' },
      { name: 'Timeout', value: 'timeout.html' },
      { name: 'Rolling Stone', value: 'rollingstone.html' },
      { name: 'People', value: 'people.html' }
    ],
    sizes: [
      { name: '320x50', value: '320x50' },
      { name: '300x250', value: '300x250' },
      { name: '728x90', value: '728x90' },
      { name: '970x90', value: '970x90' }
    ],
    url: 'https://tools.padsquad.com/quick-view/',
    website_selected: null,
    size_selected: null
  },
  getters: {
    websites: (state, getters, rootState, rootGetters) => {
      return state.websites
    },
    url: (state) => {
      return state.url
    },
    sizes: (state) => {
      return state.sizes
    },
    website_selected: (state) => {
      return state.website_selected
    },
    size_selected: (state) => {
      return state.size_selected
    }
  },
  mutations: {
    SET: (state, payload) => {

    },
    CREATE: (state, payload) => {

    },
    UPDATE: (state, payload) => {

    },
    DELETE: (state, payload) => {

    },
    SET_WEBSITE: (state, payload) => {
      state.website_selected = payload
    },
    SET_SIZE: (state, payload) => {
      state.size_selected = payload
    }
  },
  actions: {
    fetch: (context) => {

    },
    setWebsite: ({ commit, state }, website) => {
      commit('SET_WEBSITE', website)
    },
    setSize: ({ commit, state }, size) => {
      commit('SET_SIZE', size)
    }
  }
}
