<template>
    <div class="formField" :class="(type) ? 'formField--' + type : ''">
        <div class="formField__fieldWrapper">
            <label v-if="(labelGap || label)" :for="forId">{{label}}</label>
            <label v-if="description" class="description">{{description}}</label>
            <div class="formField__field" v-bind:class="{'formField__field--withTag': (tagLeft || tagRight)}">
                <div v-if="tagLeft" class="formField__field__tag formField__field__tag--left">{{tagLeft}}</div>
                <slot :id="forId"></slot>
                <div v-if="tagRight" class="formField__field__tag formField__field__tag--right">{{tagRight}}</div>
            </div>
            <font-awesome-icon v-if="type == 'select'" icon="caret-down" class="formField__ico" />
            <font-awesome-icon v-if="type == 'search'" icon="search" class="formField__ico" />
            <font-awesome-icon v-if="type == 'date'" icon="calendar-alt" class="formField__ico" />
            <span v-if="isCeltra" class="celtra-logo" :class="{ moreMargin : (type == 'select') }"></span>
            <span v-if="isAirtory" class="airtory-logo" :class="{ moreMargin : (type == 'select') }"></span>
        </div>
        <slot name="custom-content"></slot>
        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    label: String,
    description: String,
    forId: String,
    type: String,
    labelGap: Boolean,
    tagLeft: String,
    tagRight: String,
    isCeltra: {
      type: Boolean,
      default: false
    },
    isAirtory: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
@import "../scss/common.scss";
.celtra-logo{
    width: 12px;
    height: 16px;
    position: absolute;
    right: 10px;
    pointer-events: none;
    z-index: 100;
    top:auto;
    bottom:10px;
    background: url(../assets/celtra-logo.svg) center no-repeat;
}

.airtory-logo{
    width: 19px;
    height: 19px;
    position: absolute;
    right: 10px;
    pointer-events: none;
    z-index: 100;
    top:auto;
    bottom:10px;
    background: url(../assets/airtory-logo.png) center no-repeat;
    background-size: contain;
}

.moreMargin{
    right:35px;
}

.formField {
    @extend .form-group;

    margin-bottom: $spacing-unit;
    position: relative;

    label {
        text-transform: uppercase;
        display: block;
        font-size: 1rem;
        margin-bottom: 1rem;
        min-height: 1.5rem;

        & + label {
            text-transform: initial;
            margin-top: -0.7rem;
        }
    }

    &__fieldWrapper {
        position: relative;

        & + small {
            margin-top: 1rem;

            & input[type="checkbox"],
            .form-check-input {
                position: relative;
                margin-right: 0.5rem;
                margin-left: 0;
            }
        }
    }

    &__field {
        &--withTag {
            display: flex;

            > input[type="text"],
            > input[type="email"],
            > input[type="password"],
            > input[type="date"],
            > select,
            button,
            textarea {
                flex-grow: 1;
            }
            textarea {
                resize: none !important;
                overflow: hidden !important;
                min-height: 37px;
                padding-top: .8rem !important;
                padding-bottom: .8rem !important;
            }
        }

        &__tag {
            display: block;
            height: auto;
            background-color: $gray2;
            background-clip: padding-box;
            padding: 1rem 1.5rem;
            font-size: 1.2rem;
            line-height: 1.5rem;
            color: black;
            border: 1px solid $borders-color;
            border-radius: 0px;
            flex-grow: 0;
            white-space: nowrap;

            &--right {
                border-left: none;
            }

            &--left {
                border-right: none;
            }
        }

        > input[type="text"],
        > input[type="email"],
        > input[type="password"],
        > input[type="date"],
        > select,
        button,
        textarea,
        .ProseMirror {
            -webkit-appearance: none;
            display: block;
            height: auto;
            width: 100%;
            background-color: #fff;
            background-clip: padding-box;
            padding: 1rem 1.5rem;
            font-size: 1.2rem;
            line-height: 1.5rem;
            color: black;
            border: 1px solid $borders-color;
            border: 1px solid $grayTable;
            border-radius: 0px;

            & + small {
                @extend .form-group;
                margin-top: 1rem;

                &.formField__small--checkbox {
                    @extend .form-check;
                }
            }

            &:disabled {
                background: $gray2;
            }

            &:hover {
                border-color: lighten($gray6, 20%);
            }

        }

        .ProseMirror {
            min-height: 15rem;
        }

        > input[type="checkbox"] {
            @extend .form-check-input;

            + label {
                @extend .form-check-label;
            }
        }

        > select {}
    }

    &__ico {
        position: absolute;
        pointer-events: none;
    }

    &--select {
        position: relative;

        .formField__ico {
            width: 10px;
            height: 14px;
            right: 1.5rem;
            bottom: calc(1rem + 0.85em);
            margin-bottom: -8px;
            color: $gray6;
        }

        select {
            padding-right: 3.5rem;
        }
    }

    &--date {
        position: relative;

        .formField__ico {
            width: 10px;
            height: 14px;
            right: 1.5rem;
            bottom: calc(1rem + 0.85em);
            margin-bottom: -8px;
            color: $gray6;
        }

        input[type="date"] {
            padding-right: 3.5rem;
        }
    }

    &--search {
        position: relative;

        .formField__ico {
            width: 14px;
            height: 14px;
            left: 1.5rem;
            bottom: calc(1rem + 0.86em);
            margin-bottom: -8px;
            color: $gray6;
        }

        input[type="text"] {
            padding-left: 3.5rem;
        }
    }

    button {
        @extend .button;
    }

    small {
        display: block;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: $spacing-unit*2;
    }
}

#bodyTrackers {

    .formField {

        &__field{

            &__tag{

                &--left{
                    width: 118px !important;
                    min-width: 118px !important;
                    padding: 1rem 0 !important;
                    text-align: center;
                    max-height: 37px;
                }

            }
        }

    }

    .listBoxOneField{
        grid-template-columns: 1fr 60px;
    }

    .listBoxOneField_labelArrowUp{
            display: grid !important;
            grid-template-columns: 1fr;
            grid-template-columns: 1fr 60px;
            grid-column: 1;
            margin-top: 5px;
            position: relative;

            button {
                // width: 44px !important;
                // height: 37px !important;
                padding: 1rem 1.5rem;
            }

            .formField__field--withTag{
                position: relative;
            }

            .formField {

                &__field{

                    &__tag{

                        &--left{
                            overflow: hidden !important;
                            text-indent: -500px !important;
                            background-color: transparent !important;
                            border: none !important;
                        }

                    }
                }

            }

    }
}

.formFieldGroup {
    @include media-breakpoint-up(md) {

        & > label {
            display: block;
            text-transform: uppercase;
            display: block;
            font-size: 1rem;
            margin-bottom: 1rem;
            min-height: 1.5rem;

            & + label {
                text-transform: initial;
                margin-top: -0.7rem;
            }
        }

        &__fields {
            display: flex;

            .formField {
                flex-grow: 1;

                padding-left: $spacing-unit;
                padding-right: $spacing-unit;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;

                    .formField__ico {
                        right: 1.5rem;
                    }
                }
            }
        }
        &__fields__bottom_align {
            display: flex;
            align-items: flex-end;

            .formField {
                flex-grow: 1;

                padding-left: $spacing-unit/2;
                padding-right: $spacing-unit/2;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;

                    .formField__ico {
                        right: 1.5rem;
                    }
                }
            }
        }

        &__footer__text{
            .formField{
                margin-bottom: 1rem;

            }
        }

        small{
            display: block;
            margin-bottom: 3rem;
        }
    }
}
</style>
