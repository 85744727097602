<template>
  <section class="sModal">
    <div class="loader" :class="{'done': (!loading)}">
      <font-awesome-icon icon="spinner" class="spinner"/>
    </div>

    <header v-if="itemId" class="sModal__header">{{list.name}}</header>
    <header v-else class="sModal__header">New List</header>

    <form @submit.prevent="formSubmit">
      <nav class="sModal__tabs">
        <ul>
          <!--
            Workaround:
            Each tab requires validations. I wanted to use the native validation. So...
            I am using buttons for the tabs so it triggers the form submittion. This also changes the toSection var.
            The idea is that when the formSubmit method is called it checks against the toSection var, if it has value then
            it will change currentSection to toSection value, so the tab changes. If the form is submitted with
            no toSection value (e.g. when the save creative button is hit), then it will just call saveData.
          -->
          <li :selected="(currentSection=='details')">
            <button @click="gotoSection('details')">Details</button>
          </li>
          <li :selected="(currentSection=='creatives')">
            <button :disabled="!itemId" @click="gotoSection('creatives')">Creatives</button>
          </li>
        </ul>
      </nav>

      <div class="sModal__body" v-if="currentSection=='details'">
          <FormField label="List Name *" forId="list-name">
            <input v-model="list.name" id="list-name" type="text" maxlength="50" required />
          </FormField>

          <FormField label="Visibility *" forId="list-visibility" type="select">
            <select v-model="list.visibility" id="list-visibility" required>
              <option value="" selected disabled>Select a Visibility</option>
              <option v-for="(visibility, index) in visibilityOptions" :key="index">{{visibility}}</option>
            </select>
          </FormField>

          <FormField v-if="list.visibility=='Passkey'" label="Password" forId="list-password">
            <input v-model="list.password" id="list-password" type="text" :required="list.visibility=='passkey'" />
          </FormField>

          <FormField label="Access *" forId="list-private" type="select">
            <select v-model="list.private" id="list-private" :disabled="!(currentUserIsAdmin || isCurrentUserOwner || !list.id)" required>
              <option value="1">Private: Only you can see and edit this list</option>
              <option value="0">Shared: Everyone can see and edit this list</option>
            </select>
          </FormField>

          <FormField label="Keywords / Tags *" forId="list-keywords">
            <input v-model="list.keywords" id="list-keywords" type="text" required />
          </FormField>
      </div>

      <div class="sModal__body" v-if="currentSection=='creatives'">
        <FormField label="Creatives on this list">
          <div class="listBox">
            <ul>
              <li v-for="(creative, index) in list.creatives" :key="index" >
                <div class="listBox__label">
                  <div class="listBox__label__tag listBox__label__tag--removed" v-if="creative.status=='removed'">removed</div>
                  {{creative.name}}
                </div>
                <div>
                  <button
                    @click.prevent="viewCreative(creative)"><font-awesome-icon icon="eye" class="i" /></button>
                  <button
                    v-if="creative.status != 'removed'"
                    @click.prevent="removeCreative(creative)"><font-awesome-icon icon="trash-alt" class="i" /></button>
                  <button
                    v-if="creative.status == 'removed'"
                    @click.prevent="undoRemoveCreative(creative)"><font-awesome-icon icon="undo-alt" class="i" /></button>
                </div>
              </li>
              <li v-if="list.creatives.length==0" class="empty">
                <div>There are still no creatives attached to this list.</div>
              </li>
            </ul>
          </div>
          <small  v-if="list.creatives.length!=0">* You must save the list in order for updates to be saved.</small>
        </FormField>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" /> Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Save Creative' if this not a new creative -->
          <button v-bind:disabled="!isCreativeModified" v-if="itemId" class="saveButton">
            <font-awesome-icon icon="check" class="i" />Save List
          </button>

          <!-- show 'Save New Creative' if this is new creative and we are on the last screen-->
          <button v-bind:disabled="!isCreativeModified" v-if="!itemId" class="saveButton">
            <font-awesome-icon icon="check" class="i" />Save New List
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'
import { mapGetters } from 'vuex'

export default {
  name: 'CreativeProfile',
  components: {
    FormField
  },
  props: {
    /* if this prop comes empty, the template considers this as a new user */
    itemId: null
  },
  data () {
    return {
      loading: true,
      sections: [
        'details',
        'creatives'
      ],
      visibilityOptions: [],
      currentSection: 'details',
      list: {},
      listDefaults: {
        id: null,
        name: '',
        visibility: '',
        password: '',
        keywords: '',
        user_id: false,
        creatives: [],
        private: '1'
      },
      undoBucket: []
    }
  },
  methods: {
    gotoSection: function (section) {
      const self = this

      // otherwise let's keep the validation and
      // change toSection, which is use by the formSubmit.
      self.toSection = section
    },
    formSubmit: function (e) {
      const self = this

      // if toSection has any value it means that we are trying to go to a section. so...
      if (self.toSection != null) {
        // do not submit to action
        e.preventDefault()
        // then change the current section to the toSectino
        self.currentSection = self.toSection

        self.toSection = null
      } else {
        // otherwise just save data
        self.saveData()
      }
    },
    isDataModified: function () {
      const self = this

      return _.isEqual(self.user, self.userFetchedData)
    },
    saveData: function () {
      const self = this

      // turn on loading spinner
      self.loading = true

      // let's only send the creatives that are not removed
      self.list.creatives = _.filter(self.list.creatives, creative => {
        return creative.status != 'removed'
      })

      // if this is not a new list...
      if (self.itemId) {
        // ...send data to server.

        // then update the list
        Api.updateList(self.list)
          .then(response => {
            // turn off loading spinner
            self.loading = false

            // emit event
            self.$emit('save-update-success', response)
          })
          .catch(error => {
            // turn off loading spinner
            self.loading = false

            alert('There was a problem updating this list. Please try again.')
          })
      } else {
        self.list.user_id = self.userInSession.id

        const list = _.pick(self.list, [
          'name',
          'visibility',
          'password',
          'keywords',
          'user_id',
          'private'
        ])

        // otherwise this is a new creative, so, create it
        Api.createList(list)
          .then(response => {
            // emit event
            self.$emit('save-new-success', response)
          })
          .catch(error => {
            alert('There was a problem updating this creative. Please try again.')
          })
      }
    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('cancel')
    },
    populateForm: function () {
      const self = this

      // if the itemId property has an id, then, let's fetch the creative data...
      if (self.itemId) {
        // ... then it means that we are trying to get a creative's profile...

        // so, load user data from API
        Api.getList(self.itemId, true)
          .then(list => {
            // store fetched data.
            self.listFetchedData = _.cloneDeep(list)

            // populate form with list's data
            self.list = _.cloneDeep(list)

            // turn off loading spinner
            self.loading = false

            return list
          })
      } else {
        // otherwise we are just trying to create a new list. So set defaults.
        self.listFetchedData = _.cloneDeep(self.listDefaults)
        self.list = _.cloneDeep(self.listDefaults)

        // turn off loading spinner
        self.loading = false
      }

      // load visibility options
      Api.getListsVisibilities()
        .then(visibilities => {
          // populate the visibility options
          self.visibilityOptions = visibilities
        })
        .catch(error => {
          self.$log.error(error)
        })
    },
    viewCreative: function (creative) {
      // open file url in a new window
      window.open('https://simpliv1.padsquad.com/c/' + creative.id, '_blank')
    },
    removeCreative: function (creative) {
      const self = this

      // before anything, let's store the current state of the creative in case we want to undo.. so...
      // let's looks for the creative in the undoBucket.
      const wasUndone = _.findIndex(self.undoBucket, { id: creative.id })

      // if we had already stored a previous state...
      if (wasUndone != -1) {
        // ...let's store the current state instead.
        self.undoBucket.splice(wasUndone, 1, creative)
      } else {
        // otherwise let's just store it's current state (clone deep so we are not creating a reference)
        self.undoBucket.push(_.cloneDeep(creative))
      }

      // now, let's stage file to be removed on save.
      // find index of the asset file to be deleted, in the lists.creative
      const index = _.findIndex(self.list.creatives, { id: creative.id })

      // create a creative with status removed so it is removed when saved
      const removedCreative = creative
      removedCreative.status = 'removed'

      // replace the creative in the list.creatives with the removedFile element
      self.list.creatives.splice(index, 1, removedCreative)
    },
    undoRemoveCreative: function (creative) {
      const self = this

      // retrieve the previous state's index in the undoBucket. This to do a splice later.
      const indexOnUndoBucket = _.findIndex(self.undoBucket, { id: creative.id })

      // retrieve the actual previous state. This to replace the current state with this one.
      const onUndoBucket = _.find(self.undoBucket, { id: creative.id })

      // find index of the creative to delete in the list.creatives
      const index = _.findIndex(self.list.creatives, { id: creative.id })

      // replace the current state of the creative in the list.creatives with the state in the undoBucket
      self.list.creatives.splice(index, 1, onUndoBucket)

      // remove the previous state from the undo bucket
      self.undoBucket.splice(indexOnUndoBucket, 1)
    }
  },
  created () {
    const self = this

    // populate form
    self.populateForm()
  },
  computed: {
    ...mapGetters([
      'userInSession'
    ]),
    isCreativeModified: function () {
      const self = this

      return !_.isEqual(self.list, self.listFetchedData)
    },
    currentUserIsAdmin: function () {
      const self = this
      return (self.userInSession.role == 'Admin')
    },
    isCurrentUserOwner: function () {
      const self = this
      return (self.userInSession.id == self.list.user_id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/common.scss";
</style>
