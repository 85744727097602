<script setup>
import { ref, computed, watch } from 'vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import JSZip, { file } from 'jszip'
import ApiCreativeProduction from '@/helpers/ApiCreativeProduction'
import { createToastInterface } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const props = defineProps({
  creativeId: {
    type: String,
    default: null
  },
  existingZipUrl: {
    type: String,
    default: null
  }
})

const emit = defineEmits([
  'get-asset-url',
  'disable-export',
  'update-ad-weight',
  'update-temporary-creative',
  'push-to-delete-queue',
  'new-assets-uploaded',
  'get-zip-url'
])

const { useActions } = createNamespacedHelpers('creatives')
const { uploadOversizedZip, uploadZip } = useActions([ 'uploadOversizedZip', 'uploadZip' ])

const selectedFile = ref(null)
const fileStatus = ref('empty')
const previewImageUrl = ref(null)
const adWeight = ref(0)
const showTooltip = ref(false)
const oversizedThreshold = 5000000


watch(
  () => props.existingZipUrl,
  () => {
    if (props.existingZipUrl !== null) {
      selectedFile.value = true
      fileStatus.value = 'valid'
      previewImageUrl.value = props.existingZipUrl
      emit('disable-export', false)
    }
  }
)

const pluginOptions = {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
}

const toast = createToastInterface(pluginOptions)

const handleDrop = (event) => {
  handleFileInput(event.dataTransfer.files[0])
}

const handleFileChange = (event) => {
  handleFileInput(event.target.files[0])
}


const handleFileInput = async (file) => {
  try {
    await validateFiles(file)

    if (fileStatus.value !== 'empty') clearFileUpload()
    selectedFile.value = file
    fileStatus.value = 'valid'
    await handleZipUpload()
  } catch (error) {
    if (fileStatus.value === 'empty') fileStatus.value = 'invalid'
    console.error(error)
  }
}

const copyImagePreviewUrl = () => {
  const tempInput = document.createElement('input')
  tempInput.value = previewImageUrl.value
  document.body.appendChild(tempInput)
  tempInput.select()
  tempInput.setSelectionRange(0, 99999)
  document.execCommand('copy')
  document.body.removeChild(tempInput)

  if (fileStatus.value === 'valid') {
   const tooltip = document.getElementById('tooltip')
   tooltip.innerHTML = 'Url copied!'
   tooltip.style.display = 'block'

   setTimeout(() => {
     tooltip.style.display = 'none'
   }, 2000)
 }
}

const extractPath = (url) => {
  if (!url) return ''
  const pathSegments = url.split('/')
  const key = pathSegments.slice(1, 3).join('/')
  return `${key}/`
}

const clearFileUpload = () => {
  const fileInput = document.getElementById('fileInput')
  const newFileInput = document.createElement('input')
  newFileInput.type = 'file'
  newFileInput.id = 'fileInput'
  newFileInput.style.display = 'none'
  newFileInput.addEventListener('change', handleFileChange)
  fileInput.parentNode.replaceChild(newFileInput, fileInput)

  if (fileStatus.value !== 'empty') {
    emit('push-to-delete-queue', extractPath(previewImageUrl.value))
    const tooltip = document.getElementById('tooltip')
    tooltip.innerHTML = 'File deleted!'
    tooltip.style.display = 'block'

    setTimeout(() => {
     tooltip.style.display = 'none'
   }, 2000)
  }
  emit('disable-export', true)
  emit('update-ad-weight', 0)
  previewImageUrl.value = '...'
  selectedFile.value = null
  fileStatus.value = 'empty'
}

const validateFiles = async (file) => {
  if (!file.name.endsWith('.zip')) {
    toast.error('The file must be a ZIP format.')
    throw new Error
  }
  if (file.size > 10000000) {
    toast.error('File size must be less than 10MB.')
    throw new Error
  }
  let hasIndexFile = false
  const zipContents = await getZipContents(file)
  adWeight.value = getAdWeight(zipContents)
  await zipContents.forEach((path, entry) => {
    if (entry.name === 'index.html') hasIndexFile = true
  })
  if (!hasIndexFile) {
    toast.error('Zip file must contain index.html.')
    throw new Error
  }
  return hasIndexFile
}


const getZipContents = async (zipFile) => {
  const jszip = new JSZip()
  let contents
  await jszip.loadAsync(zipFile).then((unzip) => (contents = unzip))
  return contents
}

const getAdWeight = (unzipContents) => {
  let totalWeight = 0
  for (const fileKey in unzipContents.files) {
    totalWeight += unzipContents.files[fileKey]._data.uncompressedSize
  }
  return totalWeight
}

const getStatusColor = () => {
  const colorMap = {
    valid: '#8AD622',
    invalid: 'red',
    empty: '#9B9B9B'
  }

  return colorMap[fileStatus.value] || 'empty'
}

const handleZipUpload = async () => {
  if (!selectedFile.value) return
  let res;
  if (adWeight.value > oversizedThreshold) {
    res = await uploadOversizedZip({
      file: selectedFile.value,
      fileName: selectedFile.value.name,
      creativeId: props.creativeId
    })
    emit('update-ad-weight', adWeight.value)
    emit('get-asset-url', res.url)
    emit('get-zip-url', res.zipUrl)
    emit('disable-export', false)
    emit('update-temporary-creative')
    emit('new-assets-uploaded')
    previewImageUrl.value = res.url
  } else {
    const fr = new FileReader()
    fr.readAsDataURL(selectedFile.value)
    fr.onload = async () => {
      res = await uploadZip({
        file: fr.result,
        fileName: selectedFile.value.name,
        creativeId: props.creativeId
      })
      emit('update-ad-weight', adWeight.value)
      emit('get-asset-url', res.url)
      emit('get-zip-url', res.zipUrl)
      emit('disable-export', false)
      emit('update-temporary-creative')
      emit('new-assets-uploaded')
      previewImageUrl.value = res.url
    }
  }
}

const uploadFileStatus = computed(() => {
  return selectedFile.value === null
    ? '(No files found)'
    : `http://${previewImageUrl.value}`
})
</script>

<template>
  <div
    class="dropzone mb-5 d-flex flex-column align-items-left justify-content-center"
    :style="`border: dotted 2px ${getStatusColor()}`"
    style="
      padding-top: 7px;
      padding-bottom: 8px;
      position: relative;
      border-radius: 10px;
      background-color: white;
    "
    @dragover.prevent
    @drop.prevent="handleDrop"
  >
    <label for="fileInput">
      <div
        class="d-flex align-items-center py-3 flex-wrap"
        style="margin-bottom: 0 !important; cursor: pointer"
      >
        <div
          class="upload-button d-flex align-items-center justify-content-center mx-3"
        >
          <font-awesome-icon
            icon="upload"
            class="i"
            style="color: #9B9B9B; padding-bottom: none !important;"
            size="lg"
          />
        </div>

        <div class="col">
          <div
          v-if="showTooltip && fileStatus === 'valid'"
          class="tooltip"
          >
           {{ uploadFileStatus.split('-')[0] }}-&#8288;{{ uploadFileStatus.split('-')[1] }}
          </div>
          <div
            :style="fileStatus === 'empty' ? 'color:#646464' : 'color: #9B9B9B'"
          >
            Drag & Drop or Click Here to Upload
          </div>
          <div
            class="filePath"
            @mouseover="showTooltip = true"
            @mouseout="showTooltip = false"
          >
            {{ uploadFileStatus }}
          </div>
        </div>
      </div>
    </label>

    <input
      id="fileInput"
      type="file"
      accept=".zip"
      style="display: none"
      @change="handleFileChange"
    />

    <!-- Copy & Clear Buttons -->
    <div
      class="d-flex flex-row-reverse align-self-end justify-self-end mr-2 mb-3"
      style="border: none; position: absolute; bottom: 0"
    >
      <button
        class="upload-options-buttons"
        style="cursor: pointer"
        @click.prevent="clearFileUpload()"
      >
        <font-awesome-icon
          icon="trash-alt"
          size="lg"
          style="height: 15px; width: 15px"
        />
      </button>

      <button
        class="upload-options-buttons"
        style="cursor: pointer"
        @click.prevent="copyImagePreviewUrl()"
      >
        <font-awesome-icon
          icon="copy"
          size="lg"
          style="height: 15px; width: 15px"
        />
      </button>
    <!-- tooltip for copy & clear buttons -->
    <div id="tooltip" class="copyDeleteTooltip"></div>
    </div>
  </div>
</template>

<style scoped>
.upload-options-buttons {
  border: none !important;
  color: #c3c3c3;
  background-color: transparent;
}

.upload-button {
  border-radius: 50%;
  border: none !important;
  background-color: #eeedee;
  height: 50px;
  width: 50px;
}

.filePath {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #9b9b9b;
}

.tooltip {
  position: absolute;
  top: -75px;
  left: 30px;
  word-wrap:break-word;
  max-width: 830px;
  background-color: #757070;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 999;
}

.copyDeleteTooltip {
 position: absolute;
  display: none;
  top: 35px;
  left: 35px;
  background-color: #757070;
  white-space: nowrap;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 9999;
    }
</style>
