<template>
    <section class="moduleFilter">

      <div class="moduleFilter__searchFilter">
        <slot name="searchField"></slot>
      </div>

      <div class="moduleFilter__collapsableFiltersToggle" :expanded="mobileFiltersExpanded">
        <FormField :labelGap="true">
          <button v-on:click="mobileFiltersExpanded=!mobileFiltersExpanded"><font-awesome-icon icon="filter" class="i" /></button>
        </FormField>
      </div>

      <div class="moduleFilter__collapsableFilters" :expanded="mobileFiltersExpanded">
        <slot name="collapsableFilters"></slot>
      </div>

    </section>
</template>

<script>
import FormField from './FormField.vue'

export default {
  name: 'ModuleFilter',
  props: {},
  data () {
    return {
      mobileFiltersExpanded: false
    }
  },
  components: {
    FormField
  }
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.moduleFilter {
  @extend .container-fluid;
  text-align: left;
  display: flex;
  padding-top: $spacing-unit*2;
  padding-bottom: 0;
  position: relative;

  &__filter {
    &__clear {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      font-size: 10px;
      color: $gray5;
      cursor: pointer;
    }
  }

  &__searchFilter {
    flex-grow: 1;
    margin-right: $spacing-unit;
  }

  &__collapsableFiltersToggle {

    button {
      @extend .button;

      .i {
        margin: 0;
      }
    }

    &[expanded] {

      button {
        background: $pink3;
        border-color: $pink3;
        color: white;
        line-height: 3rem;
        margin-bottom: -3rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

    }
  }

  &__collapsableFilters {
    width: calc(100% - #{$spacing-unit * 4});
    height: 0px;
    overflow: hidden;
    position: absolute;
    top: calc(100% - #{$spacing-unit});
    background: $pink3;
    box-sizing: border-box;
    padding: $spacing-unit * 2 $spacing-unit * 3 $spacing-unit;
    display: none;
    white-space: nowrap;
    z-index: 1001;

    &[expanded] {
      height: auto;
      display: block;
      top: 100%;
    }

    .moduleFilter & .formField {

      label {
        color: white;
      }

    }

  }

  .formField {

    input,
    select {
      color: $blue2;
    }

    label {
      color: $pink5;
    }

  }

  & + .moduleContent {
    padding-top: 0px;
  }

  @include media-breakpoint-up(md) {

    background: $gray1;
    border-bottom: 6px solid $gray2;

    &__searchFilter {
      .formField {
        max-width: 520px;
      }
    }

    &__collapsableFiltersToggle {
      display: none;
    }

    &__collapsableFilters {

      height: auto;
      position: initial;
      background: none;
      width: auto;
      padding: 0;
      left: initial;
      display: block;
      overflow: scroll;
      z-index: initial;

      .formField {
        max-width: 23rem;
      }

      .moduleFilter & .formField {
        display: inline-block;

        label {
          color: $pink5;
        }
      }

    }

    .formField {

      margin-right: $spacing-unit;

      input,
      select {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.8rem;
      }

      &:last-child {
        margin-right: 0;
      }

    }

    & + .moduleContent {
      padding-top: $spacing-unit * 3;
    }
  }
}
</style>
