<template>
    <router-view/>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CreativeProductionRepository',
  methods: {
    ...mapActions([
      'fetchAllTaxonomies'
    ]),
    ...mapActions('advertisers', {
      fetchAdvertisers: 'fetch'
    }),
    ...mapActions('creatives', {
      fetchCreatives: 'fetch'
    })
  },
  created () {
    const self = this

    // load taxonomies
    self.fetchAllTaxonomies()

    // load advertisers
    self.fetchAdvertisers()

    // load creatives production
    self.fetchCreatives()
  }
}
</script>
