import ApiLocationFeeder from '@/helpers/ApiLocationFeeder.js'

/** VUEX module for Location Feed  **/

export default {
    namespaced: true,
    // -----------------------------------------------------------------
    state: {
        locationFeeders: [],
        feederTypes: [],
        feederInputs: [],
        feederContentTypes: [],
        locationFeed: null
    },
    // -----------------------------------------------------------------
    getters: {
        list: (state) => {
            // let map campaigns to location feeders
            return state.locationFeeders
        },
        feederById: (state, getters) => id => {
            const feeders = getters.locationFeeders.find(item => item.id === id)
            return feeders
        },
        feederTypes: state => {
            return state.feederTypes
        },
        feederInputs: state => {
            return state.feederInputs
        },
        feederContentTypes: state => {
            return state.feederContentTypes
        }
    },
    // -----------------------------------------------------------------
    mutations: {
        SET_LOCATION_FEEDER_LIST (state, payload) {
            state.locationFeeders = payload
        },
        SET_BY_ID (state, payload) {
            state.locationFeed = payload
        },
        CREATE (state, payload) {
            state.locationFeeders.push(payload)
        },
        UPDATE: (state, payload) => {
            state.locationFeeders.push(payload)
        },
        DELETE: (state, id) => {
            const index = state.locationFeeders.findIndex(item => item.id === id)
            if (index !== -1) {
                state.locationFeeders.splice(index, 1)
            }
        },
        SET_FEEDER_TYPE_LIST: (state, payload) => {
            state.feederTypes = payload
        },
        SET_FEEDER_INPUT_LIST (state, payload) {
            state.feederInputs = payload
        },
        SET_FEEDER_CONTENT_TYPE_LIST (state, payload) {
            state.feederContentTypes = payload
        },

    },
    // -----------------------------------------------------------------
    actions: {
        fetch (context) {
            // app status: loading
            context.dispatch('registerTask', 'fetchLocationFeederList', { root: true })
    
            return new Promise((resolve, reject) => {
                ApiLocationFeeder.getLocationFeederList()
                    .then(data => context.commit('SET_LOCATION_FEEDER_LIST', data))
                    .then(data => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchLocationFeederList', { root: true })

                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchLocationFeederList', { root: true })

                        reject(error)
                    })
            })
        },
        fetchFeederById: (context, feederId) => {
            // app status: loading
            context.dispatch('registerTask', 'fetchFeederById', { root: true })

            return new Promise((resolve, reject) => {
                ApiLocationFeeder.getLocationFeederById(feederId)
                    .then(data => {
                        context.commit('SET_BY_ID', data)
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederById', { root: true })
                        
                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederById', { root: true })

                        reject(error)
                    });
            });
        },
        create(context, feeder) {
            context.dispatch('registerTask', 'createFeeder', { root: true })
            return new Promise((resolve, reject) => {
                ApiLocationFeeder.createLocationFeeder(feeder)
                .then(data => {
                        context.commit('CREATE', data)
                        context.dispatch('completeTask', 'createFeeder', { root: true })
                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'createFeeder', { root: true })

                        reject(error)
                    })
                    .finally(() => {
                        context.dispatch('completeTask', 'createFeeder', { root: true })
                    })
            })
        },
        update(context, updatedFeeder) {
            context.dispatch('registerTask', 'updateFeeder', { root: true })
            return new Promise((resolve, reject) => {
                ApiLocationFeeder.updateLocationFeeder(updatedFeeder)
                    .then(data => {
                        context.commit('UPDATE', updatedFeeder)
                        context.dispatch('completeTask', 'updateFeeder', { root: true })
                        resolve(data)
                    })
                    .catch(error => {
                        context.dispatch('completeTask', 'updateFeeder', { root: true })
                        reject(error)
                    })
            })
        },
        delete: (context, id) => {
            context.dispatch('registerTask', 'deleteFeeder', { root: true })
            return new Promise((resolve, reject) => {
                ApiLocationFeeder.deleteLocationFeeder(id)
                    .then(data => {
                        context.commit('DELETE', id)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        context.dispatch('completeTask', 'deleteFeeder', { root: true })
                    })
            })
        },
        fetchFeederTypes: context => {
            // app status: loading
            context.dispatch('registerTask', 'fetchFeederTypes', { root: true })
  
            return new Promise((resolve, reject) => {
                ApiLocationFeeder.getFeederTypeList()
                    .then(data => context.commit('SET_FEEDER_TYPE_LIST', data))
                    .then(data => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederTypes', { root: true })

                        // resolve
                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederTypes', { root: true })
        
                        reject(error)
                    })
            })
        },
        fetchFeederInputs: context => {
            // app status: loading
            context.dispatch('registerTask', 'fetchFeederInputs', { root: true })
  
            return new Promise((resolve, reject) => {
                ApiLocationFeeder.getFeederInputList()
                    .then(data => context.commit('SET_FEEDER_INPUT_LIST', data))
                    .then(data => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederInputs', { root: true })

                        // resolve
                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederInputs', { root: true })
        
                        reject(error)
                    })
            })
        },
        fetchFeederContentTypes: context => {
            // app status: loading
            context.dispatch('registerTask', 'fetchFeederContentTypes', { root: true })
  
            return new Promise((resolve, reject) => {
                ApiLocationFeeder.getFeederContentTypeList()
                    .then(data => context.commit('SET_FEEDER_CONTENT_TYPE_LIST', data))
                    .then(data => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederContentTypes', { root: true })

                        // resolve
                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchFeederContentTypes', { root: true })
        
                        reject(error)
                    })
            })
        }
    }

}