<template>
    <div>
        <div class="pencilBar">
            <div class="PencilBar__left">
                <slot name="left"></slot>
                <b-breadcrumb :items="breadcrumsData"/>
            </div>
            <div class="pencilBar__right">
                <slot name="right"></slot>
            </div>
        </div>
        <div v-show="title !== null" id="title-section">{{ title }}</div>
    </div>
</template>

<script>
import WebLinks from '@/components/WebLinks.vue'

export default {
  name: 'PencilBar',
  components: {
    WebLinks
  },
  computed: {
    breadcrumsData: function () {
      const self = this
      const routesNames = []

      _.forOwn(self.$route.matched, (route, index) => {
        let label = route.name
        const parentLabel = _.get(self.$route.matched, '[' + (index - 1) + '].name', '')

        // if the current route's name contains the parent's name
        if (label.includes(parentLabel)) {
          // remove that name portion
          label = label.replace(parentLabel, '')
        }

        routesNames.push({
          text: _.trim(label),
          href: route.path,
          active: (route.name == self.$route.name)
        })

        // add CAMPAIGNS to the breadcrumb if it's in campaign view, so is not in the campaigns children route
        if (_.trim(label) == 'Campaign') {
          routesNames.splice(routesNames.length - 1, 0, {
            text: 'CAMPAIGNS',
            href: '/tag-manager/campaigns/',
            active: false
          })
        }

        if (_.trim(label) == 'Performance Monitoring' || _.trim(label) == 'Live Analytics') {
          routesNames.splice(routesNames.length - 1, 0, {
            text: 'CAMPAIGNS',
            href: '/analytics/campaigns/',
            active: false
          })
        }

        if (_.trim(label) == 'Production Creatives') {
          routesNames.splice(routesNames.length - 1, 0, {
            text: 'CAMPAIGNS',
            href: '/creative-production/campaigns/',
            active: false
          })
        }
      })

      return routesNames
    },
    title: function () {
      // return (this.breadcrumsData.length < 3 ) ? ( (this.breadcrumsData[1].text === 'List') ? 'List' : (this.breadcrumsData[0].text) ) : null ;
      // return (this.breadcrumsData.length < 3 && this.breadcrumsData[1].text !== 'List') ? (this.breadcrumsData[0].text) : null ;
      if (this.breadcrumsData.length === 1) {
        return this.breadcrumsData[0].text
      } else if (this.breadcrumsData.length < 3 && this.breadcrumsData[1].text !== 'List') {
        return this.breadcrumsData[0].text
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
    @import "../scss/common.scss";

    .pencilBar {
        @include clearfix();
        font-size: 0.8rem;
        text-transform: uppercase;
        text-align: left;
        padding: $spacing-unit $spacing-unit * 4;
        display: none;

        a {
            color: $gray6;
        }

        @include media-breakpoint-up(md) {
            width: 100%;
            display: flex;

            &__left {
                text-align: left;
                flex-grow: 1;
            }

            &__right {
                text-align: right;
                flex-grow: 1;
            }

        }
    }

    .moduleFrame--tagManagerRepositoryPlacements .pencilBar{
        background-color: $green8;

        li{
            &::after{
                color:white;
            }
        }

        a{
            color:white;
        }

        span{
            color:$green3;
        }
    }

    .breadcrumb li:first-child a{
        font-weight: bold;
        color:$green6;
        // font-size: 1.5em;
    }

    #title-section{
        font-size: 3.5rem;
        // padding: $spacing-unit/2 $spacing-unit * 4;
        padding: 0.55rem $spacing-unit * 4;
        text-align: left;
        color:$green7;
        color: $secondary;
        font-weight: 600;

        @include media-breakpoint-down(sm) {
            font-size: 2.5rem;
            padding: $spacing-unit $spacing-unit * 2;
            text-align: center;
        }
    }
</style>
