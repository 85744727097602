import _ from 'lodash'
import ApiCreativeProduction from '@/helpers/ApiCreativeProduction'

/** VUEX module for creative in Creative Production section **/

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    creatives: [],
    creativesReadyTraffic: [],
    creative: null,
    temporaryCreative: null,
    isPadSquadTemporaryCreative: null,
    openPadSquadModal: null,
    creativeLogs: [],
    creativeModalSection: null
  },
  // -----------------------------------------------------------------
  getters: {
    list: (state, getters, rootState, rootGetters) => {
      const includingMappedData = state.creatives.map((item) => {
        const itemRef = _.cloneDeep(item)

        // map field into the creative
        // itemRef.advertiser = _.get(rootGetters['advertisers/list'], `[${itemRef.advertiser_id}].name`, "");
        // itemRef.kpi = _.get(rootGetters['KPIs'], `[${itemRef.kpi_id}].name`, {});
        // itemRef.deal_type = _.get(rootGetters['dealTypes'], `[${itemRef.deal_type_id}].name`, {});
        itemRef.touched_on = itemRef.updated_date || itemRef.created_date

        return itemRef
      })

      return includingMappedData
    },
    listByCampaignId: (state, getters, rootState, rootGetters) => (id) => {
      const item = getters.list.filter(
        (item) => item.external_campaign_id === id
      )
      return item
    },
    listReadyForTraffic: (state, getters, rootState, rootGetters) => {
      // let _list = getters.list.map(item => {
      //     if(item.status === 'Ready For Traffic' || item.status === 3){
      //         return item;
      //     }
      // });

      const includingMappedData = state.creativesReadyTraffic.map((item) => {
        const itemRef = _.cloneDeep(item)

        // map field into the creative
        // itemRef.advertiser = _.get(rootGetters['advertisers/list'], `[${itemRef.advertiser_id}].name`, "");
        // itemRef.kpi = _.get(rootGetters['KPIs'], `[${itemRef.kpi_id}].name`, {});
        // itemRef.deal_type = _.get(rootGetters['dealTypes'], `[${itemRef.deal_type_id}].name`, {});
        itemRef.touched_on = itemRef.updated_date || itemRef.created_date

        return itemRef
      })

      return includingMappedData

      return _list
    },
    itemById: (state, getters) => (id) => {
      return getters.list.find((item) => item.id === id)
    },
    selectedCreative: (state) => {
      return state.creative
    },
    mostRecentLogStatus: (state) => {
      if (state.creativeLogs.length === 0) return 'Development'
      const latestLog = state.creativeLogs?.reduce((mostRecent, log) => {
        return new Date(mostRecent.updatedDate) > new Date(log.updatedDate)
          ? mostRecent
          : log
      })
      return latestLog.status
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET: (state, payload) => {
      state.creatives = payload
      return payload
    },
    SET_READY_FOR_TRAFFIC: (state, payload) => {
      state.creativesReadyTraffic = payload
      return payload
    },
    SET_BY_ID: (state, payload) => {
      state.creative = payload
    },
    SET_TEMP_CREATIVE: (state, payload) => {
      state.temporaryCreative = payload
    },
    CREATE: (state, payload) => {
      state.creatives.push(payload)
    },
    UPDATE: (state, payload) => {
      const item = state.creatives.find((item) => item.id === payload.id)
      if (item) Object.assign(item, payload)
      else state.creatives.push(payload)
    },
    DELETE: (state, id) => {
      const index = _.findIndex(state.creatives, {
        id
      })

      state.creatives.splice(index, 1)
    },
    REPLACE: (state, payload) => {
      if (state.creatives.length == 0) {
        state.creatives.push(payload)
      } else {
        const index = _.findIndex(state.creatives, {
          id: payload.id
        })

        state.creatives.splice(index, 1, payload)
      }
    },
    ADD_EVENTS: (state, payload) => {
      const index = _.findIndex(state.creatives, {
        id: payload.id
      })

      state.creatives[index].events = payload.events
    },
    REMOVE_EVENTS: (state, payload) => {
      const index = _.findIndex(state.creatives, {
        id: payload.id
      })

      state.creatives.events.splice(index, 1)
    },
    SET_OPEN_MODAL: (state, payload) => {
      state.openPadSquadModal = payload
      return payload
    },
    SET_IS_TEMP: (state, payload) => {
      state.isPadSquadTemporaryCreative = payload
      return payload
    },
    SET_CREATIVE_LOGS: (state, payload) => {
      state.creativeLogs = payload
    },
    CLEAR_CREATIVE_LOGS: (state) => {
      state.creativeLogs = []
    },
    SET_MODAL_SECTION: (state, payload) => {
      state.creativeModalSection = payload
    }
  },
  // -----------------------------------------------------------------
  actions: {
    fetch: (context) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCreatives', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getCreativeList()
          .then((data) => context.commit('SET', data))
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreatives', { root: true })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreatives', { root: true })

            reject(error)
          })
      })
    },
    fetchPadSquadCreatives: (context) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCreatives', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getCreativeList()
          .then((data) => context.commit('SET', data))
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreatives', { root: true })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreatives', { root: true })

            reject(error)
          })
      })
    },
    fetchTemporaryCreativeList: (context) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchTemporaryCreatives', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getTemporaryCreativeList()
          .then((data) => context.commit('SET', data))
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchTemporaryCreatives', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchTemporaryCreatives', {
              root: true
            })

            reject(error)
          })
      })
    },
    fetchTemporaryCreativeById: (context, creativeId) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchTemporaryCreative', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getTemporaryCreativeById(creativeId)
          .then((data) => {
            // context.commit('UPDATE', data)
            context.commit('SET_TEMP_CREATIVE', data)
            context.commit('SET_BY_ID', data)
            // app status: ready
            context.dispatch('completeTask', 'fetchTemporaryCreative', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchTemporaryCreative', {
              root: true
            })

            reject(error)
          })
      })
    },
    fetchReadyForTraffic: (context) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCreativesInactive', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getCreativeListReadyForTraffic()
          .then((data) => context.commit('SET_READY_FOR_TRAFFIC', data))
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreativesInactive', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreativesInactive', {
              root: true
            })

            reject(error)
          })
      })
    },

    fetchById: (context, creativeId) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCampaign', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getCreativeById(creativeId.toString())
          .then((data) => {
            context.commit('UPDATE', data)
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaign', { root: true })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaign', { root: true })

            reject(error)
          })
      })
    },
    fetchByPsId: (context, creativeId) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCampaign', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getCreativeByPsId(creativeId)
          .then((data) => {
            context.commit('UPDATE', data)
            context.commit('SET_BY_ID', data)
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaign', { root: true })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaign', { root: true })

            reject(error)
          })
      })
    },
    model: (context, creativeData) => {
      return new Promise((resolve, reject) => {
        try {
          const creativeDataModel = [
            'name',
            'campaign_id',
            // 'external_campaign_id',
            // 'campaign_name',
            // 'status',
            'status_id',
            // 'creative_source',
            'external_creative_id',
            'format_id', // product
            // 'product', // format_id
            // 'production',//"Build Your Own",
            // 'production_id',
            // 'production_build_type',
            'external_template_id',
            'production_build_type_id',
            'main_kpi_id',
            'kpi_goal',
            'kpi2_id',
            'kpi2_goal',
            'creative_tag',
            'external_tag_id',
            'creative_type_id',
            'engagement_type',
            'vast_tag',
            'crossScreen'
          ]

          // if this is an existent campaign...
          if (creativeData.id) {
            // ... add the id to the model
            creativeDataModel.push('id')
            // creativeDataModel.push("modified_by");
          } else {
            // creativeDataModel.push("created_by");
          }

          // pick the values in the model from our creativeDataModel
          const creativeDataModeled = _.pick(creativeData, creativeDataModel)

          resolve(creativeDataModeled)
        } catch (error) {
          reject(error)
        }
      })
    },
    padSquadModel: (context, creativeData) => {
      return new Promise((resolve, reject) => {
        try {
          const creativeDataModel = [
            'id',
            'creative_ps_id',
            'campaign_id',
            'format_id',
            'creative_type_id',
            'main_kpi_id',
            'name',
            'creative_tag',
            'fitToScreen',
            'status_id',
            'production_build_type_id',
            'kpi_goal',
            'kpi2_id',
            'kpi2_goal',
            'creative_tag',
            'adType',
            'adSize',
            'orientationType',
            'zip_url',
            'asset_url',
            'displayOptions',
            'customDimensions',
            'sponsoredTop',
            'sponsoredBottom',
            'ad_weight',
            'crossScreen'
          ]
          resolve(creativeDataModel)
        } catch (error) {
          reject(error)
        }
      })
    },
    create: (context, creativeData) => {
      // app status: loading
      context.dispatch('registerTask', 'createCreative', { root: true })

      return new Promise((resolve, reject) => {
        context
          .dispatch('model', creativeData)
          .then((creativeDataModeled) => {
            return creativeDataModeled
          })
          .then((creativeDataModeled) =>
            ApiCreativeProduction.createCreative(creativeDataModeled)
          )
          .then((creativeDataFromServer) => {
            // app status: ready
            context.commit('CREATE', creativeDataFromServer)
            // context.commit('CREATE', creativeDataFromServer.data);
            context.dispatch('completeTask', 'createCreative', { root: true })
            resolve(creativeDataFromServer)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'createCreative', { root: true })

            reject(error)
          })
      })
    },
    createPadSquadCreative: async (context, creativeData) => {
      try {
        // Set the app status to 'loading'
        context.dispatch('registerTask', 'createPadSquadCreative', {
          root: true
        })

        // Call the padSquadModel action to map the data
        const creativeDataModel = await context.dispatch(
          'padSquadModel',
          creativeData
        )

        // Map the data using a separate function
        const mapData = (data, keys) => {
          return keys.reduce((result, key) => {
            if (data.hasOwnProperty(key)) {
              result[key] = data[key] !== null ? data[key] : null
            } else {
              result[key] = null // If the key is missing in the data, set its value to 0
            }
            return result
          }, {})
        }

        const creativeDataModeled = mapData(creativeData, creativeDataModel)
        // Call the API to create the padSquadCreative
        const creativeDataFromServer =
          await ApiCreativeProduction.createPadSquadCreative(
            creativeDataModeled
          )

        // Set the app status to 'ready'
        context.commit('CREATE', creativeDataFromServer)
        context.commit('SET_BY_ID', creativeDataFromServer)
        context.dispatch('completeTask', 'createPadSquadCreative', {
          root: true
        })

        return creativeDataFromServer
      } catch (error) {
        // Set the app status to 'ready' in case of an error
        context.dispatch('completeTask', 'createPadSquadCreative', {
          root: true
        })
        throw error
      }
    },
    createPadSquadTemporaryCreative: async (context, creativeData) => {
      try {
        // Set the app status to 'loading'
        context.dispatch('registerTask', 'createPadSquadTemporaryCreative', {
          root: true
        })

        // Call the padSquadModel action to map the data
        const creativeDataModel = await context.dispatch(
          'padSquadModel',
          creativeData
        )

        // Map the data using a separate function
        const mapData = (data, keys) => {
          return keys.reduce((result, key) => {
            if (data.hasOwnProperty(key)) {
              result[key] = data[key] !== null ? data[key] : null
            } else {
              result[key] = null // If the key is missing in the data, set its value to 0
            }
            return result
          }, {})
        }

        creativeData = {
          ...creativeData,
          adType: 'Generic Ad',
          adSize: '320 x 50',
          orientationType: 'ANY'
        }

        const creativeDataModeled = mapData(creativeData, creativeDataModel)
        // Call the API to create the padSquadCreative
        const creativeDataFromServer =
          await ApiCreativeProduction.createPadSquadTemporaryCreative(
            creativeDataModeled
          )

        // Set the app status to 'ready'
        context.dispatch('completeTask', 'createPadSquadTemporaryCreative', {
          root: true
        })

        return creativeDataFromServer
      } catch (error) {
        // Set the app status to 'ready' in case of an error
        context.dispatch('completeTask', 'createPadSquadTemporaryCreative', {
          root: true
        })
        throw error
      }
    },
    update: (context, creativeData) => {
      // app status: loading
      context.dispatch('registerTask', 'updateCreative', { root: true })

      return new Promise((resolve, reject) => {
        context
          .dispatch('model', creativeData)
          .then((creativeDataModeled) => {
            return creativeDataModeled
          })
          .then((creativeDataModeled) =>
            ApiCreativeProduction.updateCreative(creativeDataModeled)
          )
          .then((creativeDataFromServer) => {
            // app status: ready
            context.commit('UPDATE', creativeDataFromServer)
            context.dispatch('completeTask', 'updateCreative', { root: true })
            resolve(creativeDataFromServer)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'updateCreative', { root: true })

            reject(error)
          })
      })
    },
    updatePadSquadCreative: (context, creativeData) => {
      // app status: loading
      context.dispatch('registerTask', 'updatePadSquadCreative', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.updatePadSquadCreative(creativeData)
          .then((creativeDataFromServer) => {
            // app status: ready
            context.commit('UPDATE', creativeDataFromServer)
            context.dispatch('completeTask', 'updatePadSquadCreative', {
              root: true
            })
            resolve(creativeDataFromServer)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'updatePadSquadCreative', {
              root: true
            })
            reject(error)
          })
      })
    },
    updatePadSquadTemporaryCreative: (context, creativeData) => {
      // app status: loading
      context.dispatch('registerTask', 'updatePadSquadTemporaryCreative', {
        root: true
      })

      function removeKeys(obj, keysToRemove) {
        if (!obj || !keysToRemove || keysToRemove.length === 0) {
          return obj
        }

        const newObj = { ...obj }
        keysToRemove.forEach((key) => {
          if (newObj.hasOwnProperty(key)) {
            delete newObj[key]
          }
        })

        return newObj
      }

      context.commit('SET_TEMP_CREATIVE', creativeData)

      const keysToRemove = ['id', 'campaign_name']
      const creativeDataNoId = removeKeys(creativeData, keysToRemove)

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.updatePadSquadTemporaryCreative(creativeDataNoId)
          .then((creativeDataFromServer) => {
            // app status: ready
            // context.commit('UPDATE', creativeDataFromServer)
            context.dispatch(
              'completeTask',
              'updatePadSquadTemporaryCreative',
              {
                root: true
              }
            )
            resolve(creativeDataFromServer)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch(
              'completeTask',
              'updatePadSquadTemporaryCreative',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    updateCreativePlacementsOnCreativeChange: (context, data) => {
      // app status: loading
      context.dispatch('registerTask', 'updateCreativePlacementsOnCreativeChange', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.updateCreativePlacementsOnCreativeChange(data)
          .then((res) => {
            // app status: ready
            context.dispatch('completeTask', 'updateCreativePlacementsOnCreativeChange', { root: true })
            resolve(res)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'updateCreativePlacementsOnCreativeChange', { root: true })
            reject(error)
          })
      })
    },
    read: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'readCreative', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getCreativeById(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'readCreative', { root: true })
            context.commit('SET_BY_ID', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'readCreative', { root: true })

            reject(error)
          })
      })
    },
    delete: (context, id) => {
      context.dispatch('registerTask', 'deleteCreative', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.deleteCreative(id)
          .then((data) => {
            // Determine if we need to update the campaign here, because we are prompting the user to SAVE CAMPAIGN after syncing
            context.commit('DELETE', id)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'deleteCreative', { root: true })
          })
      })
    },
    eventModel: (context, data) => {
      return new Promise((resolve, reject) => {
        try {
          const modeled = data.map((item) => {
            return _.pick(item, ['event_id', 'creative_id', 'label_name'])
          })

          resolve(modeled)
        } catch (error) {
          reject(error)
        }
      })
    },
    addCreativeEvents: (context, data) => {
      context.dispatch('registerTask', 'addCreativeEvents', {
        root: true
      })

      const _events = data.events.map((item) => {
        return _.pick(item, ['event_id', 'creative_id', 'label_name'])
      })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.addCreativeEvents(data.id, _events)
          .then((response) => {
            // we need to receive all events
            context.commit('ADD_EVENTS', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'addCreativeEvents', {
              root: true
            })
          })
      })
    },
    // only receive an object
    removeCreativeEvents: (context, data) => {
      context.dispatch('registerTask', 'removeCreativeEvents', {
        root: true
      })

      const _event = _.pick(data.event, [
        'id',
        'event_id',
        'creative_event_id',
        'creative_id',
        'label_name'
      ])

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.removeCreativeEvents(data.id, _event)
          .then((response) => {
            // context.commit('REMOVE_EVENTS', {id, _event});
            // we need to receive all events
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'removeCreativeEvents', {
              root: true
            })
          })
      })
    },
    updateCreativeEvents: (context, data) => {
      context.dispatch('registerTask', 'updateCreativeEvents', {
        root: true
      })

      const _events = data.events.map((item) => {
        return _.pick(item, ['id', 'event_id', 'creative_id', 'label_name'])
      })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.updateCreativeEvents(data.id, _events)
          .then((response) => {
            // we need to receive all events
            // context.commit('ADD_EVENTS', _events);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'updateCreativeEvents', {
              root: true
            })
          })
      })
    },

    cloneCreatives: (context, data) => {
      // app status: loading
      context.dispatch('registerTask', 'cloneCreatives', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.cloneCreatives(data)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'cloneCreatives', { root: true })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'cloneCreatives', { root: true })

            reject(error)
          })
      })
    },

    createCreativesLogs: (context, data) => {
      // app status: loading
      context.dispatch('registerTask', 'createCreativesLogs', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.createCreativesLogs(data)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'createCreativesLogs', {
              root: true
            })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'createCreativesLogs', {
              root: true
            })

            reject(error)
          })
      })
    },
    createPadSquadCreativesLogs: (context, data) => {
      // app status: loading
      context.dispatch('registerTask', 'createPadSquadCreativesLogs', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.createPadSquadCreativesLogs(data)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'createPadSquadCreativesLogs', {
              root: true
            })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'createPadSquadCreativesLogs', {
              root: true
            })

            reject(error)
          })
      })
    },

    fetchAirtoryCreative: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchAirtoryCreative', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.fetchAirtoryCreative(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryCreative', {
              root: true
            })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryCreative', {
              root: true
            })

            reject(error)
          })
      })
    },

    fetchCreativesDownload: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCreativesDownload', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.fetchCreativesDownload(id)
          .then((data) => {
            // app status: ready
            // console.log('download creative', data);
            context.dispatch('completeTask', 'fetchCreativesDownload', {
              root: true
            })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreativesDownload', {
              root: true
            })

            reject(error)
          })
      })
    },

    fetchCreativesLogs: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCreativesLogs', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.fetchCreativesLogs(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreativesLogs', {
              root: true
            })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCreativesLogs', {
              root: true
            })

            reject(error)
          })
      })
    },
    fetchPadSquadCreativesLogs: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchPadSquadCreativesLogs', {
        root: true
      })
      return new Promise((resolve, reject) => {
        ApiCreativeProduction.fetchPadSquadCreativesLogs(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchPadSquadCreativesLogs', {
              root: true
            })
            context.commit('SET_CREATIVE_LOGS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchPadSquadCreativesLogs', {
              root: true
            })

            reject(error)
          })
      })
    },
    fetchCreativeGwdFiles: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'getCreativeGwdFiles', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.getCreativeGwdFiles(id)
          .then((response) => {
            // app status: ready
            context.dispatch('completeTask', 'getCreativeGwdFiles', {
              root: true
            })
            resolve(response)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'getCreativeGwdFiles', {
              root: true
            })

            reject(error)
          })
      })
    },

    fetchAirtoryTag: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchAirtoryTag', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.fetchAirtoryTag(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryTag', { root: true })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryTag', { root: true })

            reject(error)
          })
      })
    },

    uploadZip: async (context, data) => {
      context.dispatch('registerTask', 'uploadZip', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.uploadZip(data)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'uploadZip', { root: true })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'uploadZip', { root: true })

            reject(error)
          })
      })
    },

    uploadOversizedZip: async (context, data) => {
      context.dispatch('registerTask', 'uploadOversizedZip', { root: true })
      const reqBody = { fileName: data.fileName, creativeId: data.creativeId }
      // get signed url
      const signedUrlRes = await ApiCreativeProduction.getSignedUrl(reqBody)

      // directly upload to s3
      await ApiCreativeProduction.uploadViaSignedUrl(signedUrlRes.signedUrl, data.file)

      // handle oversized zip in s3
      const processOversizedZipRes = await ApiCreativeProduction.processOversizedZip({ fileName: signedUrlRes.zipUrl })

      context.dispatch('completeTask', 'uploadOversizedZip', { root: true })

      return processOversizedZipRes
    }
  }
}
