<template>
  <div class="fake-page">
    <div class="box-text">
      <div class="box-image"></div>
      <div class="texts">
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line text-line-80"></div>
      </div>
    </div>
    <div class="box-text">
      <div class="box-image"></div>
      <div class="texts">
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line text-line-80"></div>
      </div>
    </div>
    <div class="box-text">
      <div class="box-image"></div>
      <div class="texts">
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line text-line-80"></div>
      </div>
    </div>
    <div class="box-text">
      <div class="box-image"></div>
      <div class="texts">
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line text-line-80"></div>
      </div>
    </div>
    <div class="box-text">
      <div class="box-image"></div>
      <div class="texts">
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line text-line-80"></div>
      </div>
    </div>
    <div class="box-text">
      <div class="box-image"></div>
      <div class="texts">
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line text-line-80"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FakeArticles'
}
</script>

<style lang="scss" scoped>
    @import "@/scss/common.scss";

    .fake-page{
      padding: $spacing-unit;
    }

    .box-text{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .box-image{
            width: 54%;
            height: 55px;
            background: $gray9;
        }

        .texts{
            width: calc(46% - 8px);

            .text-line{
                width: 100%;
                height: 5px;
                margin-bottom: 5px;
                background: $gray9;
            }

            .text-line-80{
                width: 80%;
            }
        }
    }

</style>
