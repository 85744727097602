<script setup>
import { ref, computed } from 'vue'
import ApiAnalytics from '@/helpers/ApiAnalytics'
import { createToastInterface } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const emit = defineEmits(['send-upload-results'])

const selectedFile = ref(null)
const fileStatus = ref('empty')
const loading = ref(false)
const showTooltip = ref(false)

const pluginOptions = {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
}

const toast = createToastInterface(pluginOptions)

const handleDrop = (event) => {
  handleFileInput(event.dataTransfer.files[0])
}

const handleFileChange = (event) => {
  handleFileInput(event.target.files[0])
}

const handleFileInput = async (file) => {
  try {
    loading.value = true
    await validateFiles(file)

    if (fileStatus.value !== 'empty') clearFileUpload()
    selectedFile.value = file
    fileStatus.value = 'valid'
    await uploadZip()
  } catch (error) {
    if (fileStatus.value === 'empty') fileStatus.value = 'invalid'
    console.error(error)
  } finally {
    loading.value = false
  }
}

const clearFileUpload = () => {
  const fileInput = document.getElementById('fileInput')
  const newFileInput = document.createElement('input')
  newFileInput.type = 'file'
  newFileInput.id = 'fileInput'
  newFileInput.style.display = 'none'
  newFileInput.addEventListener('change', handleFileChange)
  fileInput.parentNode.replaceChild(newFileInput, fileInput)

  if (fileStatus.value !== 'empty') {
    const tooltip = document.getElementById('tooltip')
    tooltip.innerHTML = 'File deleted!'
    tooltip.style.display = 'block'

    setTimeout(() => {
      tooltip.style.display = 'none'
    }, 2000)
  }

  selectedFile.value = null
  fileStatus.value = 'empty'
}

const getStatusColor = () => {
  const colorMap = {
    valid: '#8AD622',
    invalid: 'red',
    empty: '#9B9B9B'
  }

  return colorMap[fileStatus.value] || 'empty'
}

const uploadZip = async () => {
  if (!selectedFile.value) return
  const fr = new FileReader()
  fr.readAsDataURL(selectedFile.value)
  fr.onload = async () => {
    const res = await ApiAnalytics.uploadDspReportingCSV({
      file: fr.result,
      fileName: selectedFile.value.name
    })
    emit('send-upload-results', res)
  }
}

const validateFiles = async (file) => {
  if (!file.name.endsWith('.csv')) {
    toast.error('The file must be a CSV file.')
    throw new Error()
  }
  let hasIndexFile = false

  return hasIndexFile
}

const uploadFileStatus = computed(() => {
  return selectedFile.value === null ? '(No files found)' : 'Success!'
})
</script>

<template>
  <div
    class="dropzone mb-5 d-flex flex-column align-items-left justify-content-center"
    :style="`border: dotted 2px ${getStatusColor()}`"
    style="
      padding-top: 7px;
      padding-bottom: 8px;
      position: relative;
      border-radius: 10px;
      background-color: white;
    "
    @dragover.prevent
    @drop.prevent="handleDrop"
  >
    <label for="fileInput">
      <div
        class="d-flex align-items-center py-3 flex-wrap"
        style="margin-bottom: 0 !important; cursor: pointer"
      >
        <div
          class="upload-button d-flex align-items-center justify-content-center mx-3"
        >
          <font-awesome-icon
            icon="upload"
            class="i"
            style="color: #9B9B9B; padding-bottom: none:  !important;"
            size="lg"
          />
        </div>

        <div class="col">
          <div
            v-if="showTooltip && fileStatus === 'valid'"
            class="tooltip"
          >
            {{ uploadFileStatus.split('-')[0] }}-&#8288;{{
              uploadFileStatus.split('-')[1]
            }}
          </div>
          <div
            :style="fileStatus === 'empty' ? 'color:#646464' : 'color: #9B9B9B'"
          >
            Drag & Drop or Click Here to Upload
          </div>
          <div
            class="filePath"
            @mouseover="showTooltip = true"
            @mouseout="showTooltip = false"
          >
            {{ uploadFileStatus }}
          </div>
        </div>
      </div>
    </label>

    <input
      id="fileInput"
      type="file"
      accept=".csv"
      style="display: none"
      @change="handleFileChange"
    />

    <!-- Loading Icon -->
    <div
      class="loader"
      :class="{ done: !loading }"
    >
      <font-awesome-icon
        icon="spinner"
        class="spinner"
      />
    </div>

    <!-- Clear Buttons -->
    <div
      class="d-flex flex-row-reverse align-self-end justify-self-end mr-2 mb-3"
      style="border: none; position: absolute; bottom: 0"
    >
      <button
        class="upload-options-buttons"
        style="cursor: pointer"
        @click.prevent="clearFileUpload()"
      >
        <font-awesome-icon
          icon="trash-alt"
          size="lg"
          style="height: 15px; width: 15px"
        />
      </button>
      <!-- tooltip for copy & clear buttons -->
      <div
        id="tooltip"
        class="copyDeleteTooltip"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.upload-options-buttons {
  border: none !important;
  color: #c3c3c3;
  background-color: transparent;
}

.upload-button {
  border-radius: 50%;
  border: none !important;
  background-color: #eeedee;
  height: 50px;
  width: 50px;
}

.filePath {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #9b9b9b;
}

.tooltip {
  position: absolute;
  top: -75px;
  left: 30px;
  word-wrap: break-word;
  max-width: 830px;
  background-color: #757070;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 999;
}

.copyDeleteTooltip {
  position: absolute;
  display: none;
  top: 35px;
  background-color: #757070;
  white-space: nowrap;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 9999;
}
</style>
