<template>

    <div class="appFrame">

        <Navbar :currentModulePath="'analytics'" :currentModuleName="'Analytics'" />

        <main class="moduleFrame moduleFrame--tagManagerRepository">

          <PencilBar />

          <ModuleHeader>
            <ul slot="tabs">
              <!-- <li><router-link to="/analytics/campaigns">Campaigns</router-link></li> -->
              <li selected><router-link to="/analytics/saved-reports">Saved Reports</router-link></li>
              <li v-if="currentUser.role_name != 'Reporting Only'"><router-link to="/analytics/dataloading/">Data Loading</router-link></li>
              <li v-if="currentUser.role_name != 'Reporting Only'"><router-link to="/analytics/pulse/">Pulse</router-link></li>
              <li v-if="currentUser.role_name != 'Reporting Only'"><router-link to="/analytics/campaign-pacing/">Campaign Pacing</router-link></li>
              <li ><router-link to="/analytics/vidstream-performance/">VidStream P1</router-link></li>
              <li><router-link to="/analytics/vidstream-performance2/">VidStream P2</router-link></li>
            </ul>

            <div slot="title">Saved Reports</div>

            <div slot="actions">
              <button class='moduleHeader__createButton mr-3' @click.prevent="download"><font-awesome-icon icon="download" class="i" />Data Availability Report</button>
              <button class='moduleHeader__createButton' @click.prevent="openGenerateCSV"><font-awesome-icon icon="table" class="i" />Generate Report</button>
            </div>
          </ModuleHeader>

          <ModuleFilter>
            <div slot="searchField">
              <FormField label="Search Reports" forId="filter-search" type="search" class="moduleFilter__filter">
                <input v-model="filterModuleData.search" id="filter-search" type="text" required />
              </FormField>
            </div>

            <div slot="collapsableFilters">
              <FormField label="Status" forId="filter-status" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.status" id="filter-status">
                  <option v-for="status in filterModuleData.filterOptions.status" :key="status">{{status}}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.status !== filterModuleData.filterDefaults.status"
                  @click="filterModuleData.filterValues.status = filterModuleData.filterDefaults.status">[X] CLEAR
                </div>
              </FormField>
              <FormField label="Date Range" forId="filter-date_range" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.date_range" id="filter-date_range">
                  <option v-for="date_range in filterModuleData.filterOptions.date_range" :key="date_range">{{ date_range }}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.date_range !== filterModuleData.filterDefaults.date_range"
                  @click="filterModuleData.filterValues.date_range = filterModuleData.filterDefaults.date_range">[X] CLEAR
                </div>
              </FormField>
            </div>
          </ModuleFilter>

          <section class="moduleContent">

            <div class="contentTableFrame">

              <ContentTableTools>
                <FormField slot="left" forId="tools-orderby" type="select" tagLeft="Order By:">
                  <select v-model="tableDataOptions.orderBy" id="tools-orderby">
                    <option v-for="(orderByOption, index) in tableDataOptions.options" :key="index" :value="orderByOption.value">{{orderByOption.label}}</option>
                  </select>
                </FormField>

                <FormField slot="left" forId="tools-order" type="select" tagLeft="Order:">
                  <select v-model="tableDataOptions.order" id="tools-order">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </FormField>

                <div slot="right" class="contentTableFrame__total">Total Reports: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

              <div class="contentTableWrapper" :class="{'contextualMenuExpanded': contextualMenuExpanded}">
                <table class="contentTable">
                  <thead>
                    <tr>
                      <th class="contentTable__dimension"><input type="checkbox" @change="selectAllToggle($event)"/>Report Name</th>
                      <th class="column-text-align-left">Campaign</th>
                      <th class="column-text-align-left">Date Range</th>
                      <th class="column-text-align-left">Created By</th>
                      <th class="column-text-align-left">Last Executed</th>
                      <th class="column-text-align-left">Status</th>
                      <th class="column-text-align-left">Frequency</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in paginatedItems" :key="item.id">
                      <td class="contentTable__dimension"><input type="checkbox" :name="'report_' + item.name" v-model="itemsSelected[item.id]" /><a :title="item.name" href="#" @click.prevent="openReportSettings(item.id)">{{item.name}}</a></td>
                      <td column-name="Campaign"  class="column-text-align-left">{{ (item.all_campaigns_selected) ? 'All Campaigns' : (item.campaigns.split(',').length > 1) ? 'Multiple Campaigns' :  item.campaigns.split(',')[0] }}</td>
                      <td column-name="Date Range"  class="column-text-align-left">{{ item.date_range }}</td>
                      <td column-name="Created By"  class="column-text-align-left">{{ item.created_by }}</td>
                      <td column-name="Last Executed"  class="column-text-align-left">{{ (item.frequency === 'No Schedule') ? 'N/A' : getFormattedDate(item.last_executed) }}</td>
                      <td column-name="Status" class="tal status">
                        <a v-if="(item.frequency !== 'No Schedule')" href="#" @click.prevent="filterModuleData.filterValues.status = item.status">
                          <font-awesome-icon
                            v-if="item.status"
                            :id="item.id"
                            :status="item.status"
                            icon="circle"
                          />
                          {{ item.status }}
                        </a>
                        <span v-else>{{ 'N/A' }}</span>
                      </td>
                      <td column-name="Frequency"  class="column-text-align-left">{{ item.frequency }}</td>
                      <td class="contentTable__actions">
                        <button :disabled="!item.last_generated_csv_url || item.date_range === 'Custom Range' || item.frequency === 'No Schedule'" @click="downloadReport(item)"><font-awesome-icon icon="download" class="i" /></button>
                        <button @click="runReport(item)"><font-awesome-icon icon="play-circle" class="i" /></button>
                        <ActionsDropdown :dropup="paginatedItems.length>10&&!(index<(paginatedItems.length-2))" :itemId="item.id" :actions="tableActions" @show="contextualMenuExpanded=true"  @hide="contextualMenuExpanded=false"/>
                      </td>
                    </tr>

                    <tr v-if="paginatedItems.length < 1">
                      <td colspan="9">No report matches your criteria</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ContentTableTools>
                <div slot="right" class="contentTableFrame__total">Total Reports: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

            </div>

          </section>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            :savedReportId="modalData.savedReportId"
            @done="hideModal()"
           />
        </b-modal>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import WebLinks from '@/components/WebLinks.vue'
import GenerateCSV from '@/views/modals/GenerateCSV.vue'
import moment from 'moment'
import axios from 'axios'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'
import UserProfileVue from '@/views/modals/UserProfile.vue'

export default {
  name: 'AnalyticsRepositorySavedReports',
  components: {
    Navbar, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks, GenerateCSV
  },
  data () {
    return {
      groupMetrics: [],
      groupDimensions: [],
      staticBModal: true,
      mobileFiltersExpanded: false,
      selectedCampaignsIds: [], // []
      filterModuleData: {
        search: '',
        filterValues: {
          status: 'All Status',
          date_range: 'All Date Range'
        },
        filterDefaults: {
          status: 'All Status',
          date_range: 'All Date Range'
        },
        filterOptions: {
          status: [],
          date_range: []
        }
      },
      tableDataOptions: {
        orderBy: 'update_at',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'update_at'
          },
          {
            label: 'Name',
            value: 'name'
          }
        ]
      },
      breadcrumsData: [
        {
          text: 'Analytics',
          href: '/analytics'
        },
        {
          text: 'Saved Reports',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        savedReportId: null
      },
      itemsSelected: {},
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'Report Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openReportSettings
        },
        {
          ico: 'trash-alt',
          text: 'Delete Report',
          clickAction: this.deleteReport
        }
      ],
      contextualMenuExpanded: false,
      selectAll: false
    }
  },
  methods: {
    ...mapActions([
      'registerModalOpened',
      'registerModalClosed',
      'registerDownloadingStarted',
      'registerDownloadingCompleted'
    ]),
    ...mapActions('analytics', {
      fetchSavedReports: 'fetchSavedReports',
      fetchAvailabilityReports: 'fetchAvailabilityReports',
      createSavedReport: 'createSavedReport',
      editSavedReport: 'editSavedReport',
      deleteSavedReport: 'deleteSavedReport',
      fetchMetrictsAndDimensions: 'fetchMetrictsAndDimensions'

    }),
    ...mapActions('campaigns', {
      getCampaignById: 'fetchById',
      fetchCampaigns: 'fetch'
    }),
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':
          break

        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    openReportSettings: function (itemId) {
      // set the modal item id to the userId we want to update
      this.modalData.itemId = itemId

      // register that we have just opened a modal
      this.registerModalOpened()

      // set the content for the modal to the user profile component
      this.modalData.savedReportId = itemId
      this.modalData.currentComponent = GenerateCSV

      // show modal
      this.$refs.moduleModal.show()
    },
    downloadReport: function (item) {
      // <a :href="item.last_generated_csv_url" :download="item.name"
      const a = document.createElement('a')
      a.href = item.last_generated_csv_url
      a.download = item.last_generated_csv_url.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    runReport: function (item) {
      console.log('runReport', item.campaigns)
      // call run for this item
      const selectedDimensions = []
      const selectedMetrics = []
      item.metrics.split(',').forEach(dt => {
        this.groupMetrics.every(item => {
          if (item.data.find(x => x.id == dt)) {
            selectedMetrics.push(item.data.find(x => x.id == dt).name)
            return false
          }
          return true
        })
      })

      // this.selectedDimensions = data.dimensions.split(',');
      item.dimensions.split(',').forEach(dt => {
        this.groupDimensions.every(item => {
          if (item.data.find(x => x.id == dt)) {
            selectedDimensions.push(item.data.find(x => x.id == dt).name)
            return false
          }
          return true
        })
      })

      const data = {
        startDate: moment.utc(item.startDate).format('YYYY-MM-D'),
        endDate: moment.utc(item.endDate).format('YYYY-MM-D'),
        // selectedCampaigns: (item.campaigns.split(',').find(item => parseInt(item) === 0) === undefined) ? item.campaigns : this.campaignsListAirtory.map(item => item.id).join(), // 0,
        selectedCampaigns: (item.campaigns.split(',').find(item => parseInt(item) === 0) === undefined) ? item.campaigns : 0, // 0,
        selectedPlacements: item.placements,
        selectedCreatives: item.creatives,
        selectedMetrics: selectedMetrics.join(','),
        selectedDimensions: selectedDimensions.join(','),
        ratesAsDecimals: item.rates_as_decimals,
        onlyEmailReport: item.only_email,
        emails: item.emails,
        reportName: item.name,
        reportId: item.id
      }

      this.registerDownloadingStarted()

      axios.get(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_API_CSV_DOWNLOAD}`, {
        params: data
      })
        .then(response => {
          if (response.data.data && response.data.data === 'RUNNING') {
            // 30s timeout for notifying user if the data takes too long to be proccessed it will be send to him by email
            this.callSwalModal('Success!', '<h3>Looks like your report will take some extra time to process. We will notify you via email once your request is completed.</h3>', 'success')
          } else {
            if (!data.onlyEmailReport) {
              this.callSwalModal('Success!', '<h3>Your file is being downloaded.</h3>', 'success')
              this.forceFileDownload(response, item.name)
            } else {
              this.callSwalModal('Success!', '<h3>Your file is being sent.</h3>', 'success')
            }
          }

          this.registerDownloadingCompleted()
        })
        .catch(error => {
          console.log(error)
          if (error == 'Error: Network Error') {
            const Reportdata = this.getSavedReportById(item.id)

            const a = document.createElement('a')
            a.href = Reportdata.last_generated_csv_url
            a.download = Reportdata.last_generated_csv_url.split('/').pop()
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            this.callSwalModal('Success!', '<h3>Your file is being downloaded.</h3>', 'success')
          } else {
            this.$swal({
              position: 'top',
              title: 'Failed to download the CSV.',
              html: `${error}`,
              showDenyButton: false,
              showConfirmButton: false,
              showCancelButton: true,
              confirmButtonText: 'TRY AGAIN',
              cancelButtonText: 'Close',
              allowOutsideClick: false,
              icon: 'error',
              toast: false
            })
          }

          this.registerDownloadingCompleted()
        })
    },
    forceFileDownload: function (response, name) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const date = new Date()
      const filename = `${name}_${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}_export.csv`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    callSwalModal: function (title, html, icon) {
      this.$swal({
        position: 'top',
        title,
        html,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon,
        timer: 10000,
        toast: false
      })
    },
    deleteReport: function (itemId) {
      const self = this

      // fet user from users.
      const report = _.find(self.listSavedReports, { id: itemId })

      this.$swal({
        position: 'top',
        title: `Are you sure you want to delete this report: ${report.name}?`,
        html: '',
        showDenyButton: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: 'No',
        allowOutsideClick: false,
        icon: 'warning',
        toast: false
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.deleteSavedReport(report.id)
              .catch(error => {
                this.$log.error(error)
                this.$swal({
                  position: 'top',
                  title: 'There was an issue deleting this report. Please try again.',
                  html: `Error Code:${error}`,
                  showDenyButton: false,
                  showConfirmButton: false,
                  showCancelButton: true,
                  confirmButtonText: 'YES',
                  cancelButtonText: 'Close',
                  allowOutsideClick: false,
                  icon: 'error',
                  toast: false
                })
              })
          }
        })
    },
    openGenerateCSV: function () {
      // register that we have just opened a modal
      this.registerModalOpened()

      // set the content for the modal to the user profile component
      this.modalData.savedReportId = null
      this.modalData.currentComponent = GenerateCSV
      this.isModalFullWidth = false

      // show modal
      this.$refs.moduleModal.show()
    },
    hideModal: function () {
      // register that we have just closed a modal
      this.registerModalClosed()

      // show modal
      this.$refs.moduleModal.hide()

      this.modalData.advertiserId = null
      this.modalData.advertiserName = null
    },
    getFormattedDate: function (date) {
      const formattedDate = this.$moment(date).utc().format('M/DD/YY h:mma')

      return (formattedDate != 'Invalid date') ? formattedDate : ''
    },
    populateFilters: function () {
      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(this.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(this.listSavedReports, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, param => {
        filterOptions[param].unshift(this.filterModuleData.filterDefaults[param])
      })

      // Store filter options.
      this.filterModuleData.filterOptions = filterOptions
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.paginatedItems.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.itemsSelected, String(item.id), true)
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.itemsSelected, String(item.id), false)
        })
      }
    },
    getCampaignName: function (id) {
      if (this.selectedCampaignsIds.length === 1 && this.selectedCampaignsIds[0] !== 0) {
        // return this.campaignsListAirtory.find(x => x.id == id).name;
        return this.listCampaignsFiltered.find(x => x.id == id).name
      }

      if (this.selectedCampaignsIds.length >= 1 && this.areAllCampaignSelected) {
        return 'All'
      }

      if (this.selectedCampaignsIds.length > 1 && this.selectedCampaignsIds.find(item => item === 0) === undefined) {
        return 'Multiple'
      }
    },
    informUserAndNotDownload: function () {
      this.$swal({
        position: 'top',
        title: 'Success!',
        html: '<h3>Looks like your report will take some extra time to process. We will notify you via email once your request is completed.</h3>',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'success',
        timer: 10000,
        toast: false
      })
    },
    informUserDownload: function () {
      const message = (this.onlyEmailReport) ? 'sent' : 'downloaded'

      this.$swal({
        position: 'top',
        title: 'Success!',
        html: `<h3>Your file is being ${message}.</h3>`,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'success',
        timer: 4000,
        toast: false
      })
    },
    forceDataFileDownload (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const date = new Date()
      const filename = `Data_Availability_Report_${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}_export.csv`
      console.log(filename)
      link.setAttribute('download', filename) // or any other extension
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    download: function () {
      this.registerDownloadingStarted()

      axios.get(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_DATA_AVAILABILITY_GET}`)
        .then(response => {
          // clearTimeout(_timeout);

          if (response.data && response.data === 'RUNNING') {
            // 30s timeout for notifying user if the data takes too long to be proccessed it will be send to him by email
            this.informUserAndNotDownload()
          } else {
            this.informUserDownload()
            if (!this.onlyEmailReport) this.forceDataFileDownload(response)
          }

          this.registerDownloadingCompleted()
        })
        .catch(error => {
          console.log('error', error)

          this.registerDownloadingCompleted()
        })
    }
  },
  computed: {
    ...mapGetters('analytics', {
      getMetrics: 'getMetrics',
      getDimensions: 'getDimensions',
      listSavedReports: 'getSavedReports',
      listSavedReportsScheduled: 'getSavedReportsScheduled',
      getSavedReportById: 'getSavedReportById'
    }),
    ...mapGetters('campaigns', {
      campaignsListAirtory: 'listAirtory'
    }),
    /**
     * Returns an array with the list of id for selected creatives.
     */
    listCampaignsFiltered: function () {
      // descending sort
      return [{ id: 0, name: 'All Campaigns' }, ...this.campaignsListAirtory.sort((a, b) => (a.id < b.id ? 1 : -1))]
    },
    areAllCampaignSelected: function () {
      return this.selectedCampaignsIds.find(item => item === 0) !== undefined
    },
    validItemsSelected: function () {
      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(this.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    filteredItems: function () {
      const filteredList = this.listSavedReports.filter(item => item.active === 1)

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(filteredList, this.filterModuleData.search)

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(this.filterModuleData.filterValues, (filter, key) => {
        // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
        const filterOption = _.get(this.filterModuleData.filterOptions, '[' + key + '][0]', false)

        // if filterOption is not false and the filter option is not the first option
        return filterOption && filter != filterOption
      })

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(filteredItems, [this.tableDataOptions.orderBy], [this.tableDataOptions.order])

      return filteredItems
    },
    paginatedItems: function () {
      // return paginated data
      return Utils.getPaginatedItems(this.filteredItems, this.pagination.currentPage, this.pagination.perPage)
    },
    currentUser: function () {
      const self = this

      // var currentUser = JSON.parse(self.$store.state.cognito.authenticated.storage._sUser);
      // self.$store.getters['userInSession']
      // self.$log.info(self.$store.getters['userInSession'], localStorage._sUser)
      const currentUser = JSON.parse(localStorage._sUser)

      return currentUser
    },
    isCurrentUserProfile: function () {
      const self = this

      // return (self.currentUser.id == self.user.id);
      return (self.currentUser.aws_user_id == self.user.aws_user_id)
    }
  },
  async created () {
    try {
      // if no campaigns
      if (!this.campaignsListAirtory || this.campaignsListAirtory.length === 0) {
        await this.fetchCampaigns()
      }

      await this.fetchMetrictsAndDimensions()

      this.getMetrics.forEach(element => {
        if (this.groupMetrics.length == 0) {
          this.groupMetrics.push({ vendor: element.name.split(' ')[0], data: [{ name: element.name, id: element.id }] })
        } else if (this.groupMetrics.find(x => x.vendor == element.name.split(' ')[0])) {
          this.groupMetrics.find(x => x.vendor == element.name.split(' ')[0]).data.push({ name: element.name, id: element.id })
        } else {
          this.groupMetrics.push({ vendor: element.name.split(' ')[0], data: [{ name: element.name, id: element.id }] })
        }
      })

      this.getDimensions.forEach(element => {
        if (this.groupDimensions.length == 0) {
          this.groupDimensions.push({ vendor: element.name.split(' ')[0], data: [{ name: element.name, id: element.id }] })
        } else if (this.groupDimensions.find(x => x.vendor == element.name.split(' ')[0])) {
          this.groupDimensions.find(x => x.vendor == element.name.split(' ')[0]).data.push({ name: element.name, id: element.id })
        } else {
          this.groupDimensions.push({ vendor: element.name.split(' ')[0], data: [{ name: element.name, id: element.id }] })
        }
      })

      await this.fetchSavedReports()
      await this.fetchAvailabilityReports()
    } catch (error) {
      console.log(error)
    }
  },
  watch: {
    listSavedReports: function (val) {
      this.populateFilters()
    }
  }
}
</script>
<style  scoped>
  .column-text-align-left{
    text-align: left;
  }
  .status{
    text-align: left;
  }
  .status .svg-inline--fa.fa-w-16 {
      width: .5em;
  }

  @media (min-width: 768px){
    .contentTable th:last-child, .contentTable td:last-child {
        padding: 1.5rem !important;
    }
  }
  .moduleHeader__tabs ul, .moduleHeader__tabs ol {
    display: flex !important;
  }

  .moduleFrame--tagManagerRepository .moduleHeader__tabs li {
    padding: 1.5rem 1.3rem;
}
</style>
