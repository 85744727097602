<template>
    <div class="product" :class="{ isSelected: selected, 'isRecommended': recommendedByKPI }" @click.prevent="select()">
        <!-- <div class="recommended-circle">
            <font-awesome-icon v-show="recommendedByKPI" icon="check" class="i" />
        </div> -->
        <div v-if="$slots.demo" class="product-demo">
            <slot name="demo"></slot>
            <fake-articles />
        </div>
        <product-info :title=title :selected=selected >
            <template v-if="showProductInfo" slot="body">
                <div class="decription mb1">
                    <h4>Distribution:</h4>
                    <ul>
                        <li>{{ distribution }}</li>
                    </ul>
                </div>
                <div class="decription">
                    <h4>Features:</h4>
                    <ul>
                        <li>Build Your Own <span @click="showInfo('build')" class='question'><font-awesome-icon icon="question" class="i" /></span></li>
                        <li>Managed Production <span @click="showInfo('manage')" class='question'><font-awesome-icon icon="question" class="i" /></span></li>
                    </ul>
                </div>
            </template>
            <template v-if="!showProductInfo && kpi !== null" slot="body">
                <div class="decription mb1">
                    <h4>Main KPI:</h4>
                    <ul>
                        <li>{{ kpi }}</li>
                    </ul>
                </div>
            </template>
        </product-info>
    </div>
</template>

<script>
import Utils from '@/helpers/Utils.js'
import ProductInfo from '@/components/ProductInfo.vue'
import FakeArticles from '@/components/FakeArticles.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Product',
  components: { ProductInfo, FakeArticles },
  props: {
    title: null,
    mobile: false,
    desktop: false,
    selected: false,
    showProductInfo: true,
    recommendedByKPI: false,
    kpi: null
  },
  data () {
    return {
      // selected: false
    }
  },
  computed: {
    distribution: function () {
      // if(this.desktop){
      //     return "Mobile Web, Desktop Web, mRaid";
      // } else {
      //     return "Mobile Web, mRaid";
      // }

      return 'Mobile Web, Desktop Web, mRaid'
    }
  },
  methods: {
    showInfo: function (type) {
      this.$log.info(`Show Info ${type}`)
    },
    select: function () {
      // this.selected = true;
      this.$emit('selected', this.title)
      console.log('selected')
    },
    unselect: function () {
      // this.selected = false;
    }
  }
}
</script>

<style lang="scss">
    @import "@/scss/common.scss";

    .product{
        // width: 32%;
        // max-width: 240px;
        border: 2px solid $gray5;
        cursor: pointer;
        pointer-events: auto;
        // margin-bottom: $spacing-unit;
        float: left;
        user-select: none;

        .description{
            font-size: 1.2rem;
        }
    }

    .isRecommended{
        border: 2px solid rgb(221, 123, 31) !important;
    }

    .isSelected{
        border: 2px solid $primary1;
        pointer-events: none;
    }

    .product-demo{
        width: 100%;
        height: 340px;
        overflow-y: hidden;
        background: white;
        margin:0;
    }

</style>
