<template>
  <section
    v-if="itemId != null"
    class="sModal"
  >
    <Preloader :in-modal="true"></Preloader>

    <!-- Preloader is not always working  -->
    <div
      v-if="loading && appStatus === 'ready'"
      class="loader"
      :class="{ done: !loading }"
    >
      <font-awesome-icon
        icon="spinner"
        class="spinner"
      />
    </div>

    <header class="sModal__header">Traffic Plan Creative Production</header>

    <form @submit.prevent="formSubmit">
      <div
        v-if="currentSection == 'details'"
        class="sModal__body"
      >
        <div class="buttons">
          <button
            class="button button--green mr1"
            @click.prevent="exportTable"
          >
            <font-awesome-icon
              icon="download"
              class="i"
            />
            Download Traffic Plan
          </button>
        </div>
        <small
          >The trafficking plan is the excel template file with existing
          placement information that you can modify or add new
          placements.</small
        >
        <div class="mb2"></div>
        <FormField
          label="Upload Trafficking Plan"
          description=""
          for-id="creative-delivered-impressions"
        >
          <!-- <b-form-file class="button-file" v-model="assetUploader.file" @change="uploadAssets" accept=".csv, application/vnd.sealed.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :state="Boolean(assetUploader.file)" placeholder="Choose a file..."></b-form-file> -->
          <b-form-file
            v-model="assetUploader.file"
            class="button-file"
            accept=".csv, application/vnd.sealed.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :state="Boolean(assetUploader.file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            @change="importTable"
          ></b-form-file>
        </FormField>

        <vue-excel-editor
          ref="grid"
          v-model="jsondata"
          no-paging
          autocomplete
          @select="onRowSelectUnselect"
        >
          <vue-excel-column
            field="name"
            label="Name"
            :change="onBeforeCreativeChange"
            width="250px"
          />
          <vue-excel-column
            field="id"
            readonly
            label="ID"
            width="50px"
          />
          <vue-excel-column
            field="external_placement_id"
            readonly
            label="External Placement ID"
            width="200px"
          />
          <vue-excel-column
            field="third_party_id"
            label="Measurement 3P ID"
          />
          <vue-excel-column
            field="verification_third_party_id"
            label="Verification 3P ID"
          />
          <!-- <vue-excel-column field="placement_source"  label="Tag Source"/> -->
          <!-- <vue-excel-column field="format_name"  label="Format" type="select" :options="getFormatsList"/> -->
          <!-- <vue-excel-column field="main_kpi"  label="Main KPI" type="select" :options="getKPIsList"/> -->
          <!-- <vue-excel-column field="creative_tag"  readonly label="Tag Code" width='300px'/> -->
          <vue-excel-column
            field="append_script"
            label="Append Script"
          />
          <!-- <vue-excel-column field="creative_id"  label="Creative Id" />
          <vue-excel-column field="external_creative_id"  label="External Creative ID" /> -->
          <vue-excel-column
            field="creative"
            label="Assigned Creative"
            type="select"
            :options="getCreatives"
            :change="onBeforeCreativeChange"
            width="200px"
          />

          <vue-excel-column
            field="tracker_1_label"
            label="Event:1:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_1_pixel"
            label="Event:1:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_1_use_as_destination"
            label="Event:1:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_2_label"
            label="Event:2:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_2_pixel"
            label="Event:2:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_2_use_as_destination"
            label="Event:2:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_3_label"
            label="Event:3:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_3_pixel"
            label="Event:3:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_3_use_as_destination"
            label="Event:3:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_4_label"
            label="Event:4:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_4_pixel"
            label="Event:4:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_4_use_as_destination"
            label="Event:4:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_5_label"
            label="Event:5:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_5_pixel"
            label="Event:5:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_5_use_as_destination"
            label="Event:5:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_6_label"
            label="Event:6:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_6_pixel"
            label="Event:6:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_6_use_as_destination"
            label="Event:6:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_7_label"
            label="Event:7:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_7_pixel"
            label="Event:7:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_7_use_as_destination"
            label="Event:7:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_8_label"
            label="Event:8:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_8_pixel"
            label="Event:8:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_8_use_as_destination"
            label="Event:8:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_9_label"
            label="Event:9:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_9_pixel"
            label="Event:9:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_9_use_as_destination"
            label="Event:9:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_10_label"
            label="Event:10:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_10_pixel"
            label="Event:10:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_10_use_as_destination"
            label="Event:10:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_11_label"
            label="Event:11:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_11_pixel"
            label="Event:11:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_11_use_as_destination"
            label="Event:11:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_12_label"
            label="Event:12:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_12_pixel"
            label="Event:12:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_12_use_as_destination"
            label="Event:12:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_13_label"
            label="Event:13:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_13_pixel"
            label="Event:13:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_13_use_as_destination"
            label="Event:13:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_14_label"
            label="Event:14:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_14_pixel"
            label="Event:14:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_14_use_as_destination"
            label="Event:14:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
          <vue-excel-column
            field="tracker_15_label"
            label="Event:15:Label"
            type="select"
            :options="getTrackers"
            :change="onBeforeTrackerChange"
            width="200px"
          />
          <vue-excel-column
            field="tracker_15_pixel"
            label="Event:15:Pixel:URL"
            width="300px"
          />
          <vue-excel-column
            field="tracker_15_use_as_destination"
            label="Event:15:Use As Destination"
            type="select"
            :options="getYesNo"
            width="100px"
          />
        </vue-excel-editor>

        <button
          class="button mt-3 mr-3 button-add-row"
          :disabled="campaign.status_source == 'Updating'"
          @click.prevent="newRecord"
        >
          <font-awesome-icon
            icon="plus-circle"
            class="i"
          />
        </button>
        <button
          v-if="showDuplicateRecord"
          class="button mt-3 mr-3 button-add-row"
          :disabled="campaign.status_source == 'Updating'"
          @click.prevent="cloneRecord"
        >
          <font-awesome-icon
            icon="clone"
            class="i"
          />
        </button>
        <button
          v-if="showDeleteRecord"
          class="button mt-3 button-add-row"
          :disabled="campaign.status_source == 'Updating'"
          @click.prevent="deleteRecord"
        >
          <font-awesome-icon
            icon="trash-alt"
            class="i"
          />
        </button>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button
            class="cancelButton"
            @click.prevent="cancel"
          >
            <font-awesome-icon
              icon="ban"
              class="i"
            />Cancel
          </button>
        </div>

        <div class="sModal__footer__right">
          <!-- <button :disabled="itemId != null && !validItemsSelected.length" class="saveButton"> -->
          <button
            :disabled="
              itemId === null ||
              campaign.id === 10590 ||
              campaign.status_source == 'Updating'
            "
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Update Placements List
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/ApiTagManager.js'
import Utils from '@/helpers/Utils.js'
import Preloader from '@/components/Preloader.vue'
import axios from 'axios'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TrafficPlanProfile',
  components: {
    FormField,
    Preloader
  },
  props: {
    itemId: null,
    campaignProp: null
  },
  data() {
    return {
      loading: false,
      listMatched: [],
      listUploaded: [],
      contextualMenuExpanded: false,
      itemsSelected: {},
      assetUploader: {
        file: null
      },
      sections: ['details'],
      assetFiles: [],
      blank: false,
      currentSection: 'details',
      synced: false,
      selectAll: false,
      campaign: null,
      jsondata: [],
      showDeleteRecord: true,
      showDuplicateRecord: true
    }
  },
  computed: {
    ...mapGetters('campaigns', ['itemById']),
    ...mapGetters(['creativeFormats', 'KPIs']),
    ...mapGetters(['appStatus']),
    getFormatsList: function () {
      return _.map(this.creativeFormats, 'name')
    },
    getCreatives: function () {
      return _.map(this.campaign.creatives, 'name')
    },
    getTrackers: function () {
      // return label and avoid duplicates with Set
      const trackers = new Set()
      trackers.add('No tracker')

      this.campaign.creatives.forEach((item) => {
        if (item.events != undefined) {
          if (item.events.length > 0) {
            item.events.forEach((t) => {
              trackers.add(
                `[${t.display_name}]:${String(t.label_name).toLowerCase()}`
              )
            })
          }
        }
      })

      this.$log.info(trackers.size)

      return Array.from(trackers)
      // return ['[Standard Event]:exit_1', '[Custom Click]:exit_url', '[Custom Event]:exit_event']
    },
    getYesNo() {
      return ['No', 'Yes']
    },
    getKPIsList: function () {
      return _.map(this.KPIs, 'name')
    },
    getStatusCount: function () {
      const updateCount = _.filter(this.listMatched, [
        'status',
        'update'
      ]).length
      const newCount = _.filter(this.listMatched, ['status', 'new']).length
      // let newCount = this.assetFiles.length;

      const s = updateCount > 1 ? 's' : ''

      // if(!updateCount && !newCount) return '';

      return `(${updateCount} Update${s}, ${newCount} New)`
    },
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validItemsSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    validItemsSelectedToObjects: function () {
      const data = []

      this.validItemsSelected.map((item) => {
        const _item = _.find(this.listUploaded, { name: String(item) })
        data.push(_item)
      })

      return data
    }
  },
  created() {
    const self = this
    self.campaign = self.campaignProp
    // self.fetchCampaignById(this.$route.params.id)
    // self.fetchByIdIncludeTrackers(this.$route.params.id)
    this.getDataByCampaign()

    // } else {
    //   // populate form
    //   self.populateForm();
    // }
  },
  methods: {
    ...mapActions('campaigns', {
      fetchCampaignById: 'fetchById',
      fetchByIdIncludeTrackers: 'fetchByIdIncludeTrackers',
      getCampaign: 'fetchByIdNoPlacements'
    }),
    ...mapActions('placements', [
      'create',
      'update',
      'delete',
      'updateBulk',
      'createBulk',
      'syncTrafficPlanToList',
      'setPlacements',
      'fetchWithOutTrackers',
      'fetchTrackersByPlacementList'
    ]),
    ...mapActions(['registerTask', 'completeTask']),
    mapPlacementsToJsonData() {
      this.campaign.placements.map((placement) => {
        const format_name = _.get(
          _.find(this.creativeFormats, { id: placement.format_id }),
          'name',
          ''
        )
        const creativeObj = _.find(this.campaign.creatives, {
          id: placement.creative_id
        })
        const creative = _.get(creativeObj, 'name', '')
        // let main_kpi_name = this.getKPIName(placement.main_kpi);

        // let tag = (placement.creative_tag) ? placement.creative_tag : "";
        const script
          = placement.append_script && placement.append_script !== ' '
            ? placement.append_script
            : ''

        // filter if the tracker creative_event_id is in creatives
        if (placement.trackers.length > 0) {
          placement.trackers = placement.trackers.filter((item) => {
            if (
              _.find(creativeObj.events, {
                creative_event_id: item.creative_event_id
              })
            ) {
              return item
            }
          })
        }

        // sort trackers by event id
        placement.trackers = _.sortBy(placement.trackers, ['event_id', 'order'])

        const trackers = {
          tracker_1_label:
            placement.trackers[0] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[0].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[0].label_name).toLowerCase()}`
              : '',
          tracker_1_pixel:
            placement.trackers[0] !== undefined
              ? placement.trackers[0].tracker_value
              : '',
          tracker_1_use_as_destination: this.checkOption(placement.trackers[0])
            ? 'Yes'
            : 'No',
          tracker_2_label:
            placement.trackers[1] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[1].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[1].label_name).toLowerCase()}`
              : '',
          tracker_2_pixel:
            placement.trackers[1] !== undefined
              ? placement.trackers[1].tracker_value
              : '',
          tracker_2_use_as_destination: this.checkOption(placement.trackers[1])
            ? 'Yes'
            : 'No',
          tracker_3_label:
            placement.trackers[2] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[2].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[2].label_name).toLowerCase()}`
              : '',
          tracker_3_pixel:
            placement.trackers[2] !== undefined
              ? placement.trackers[2].tracker_value
              : '',
          tracker_3_use_as_destination: this.checkOption(placement.trackers[2])
            ? 'Yes'
            : 'No',
          tracker_4_label:
            placement.trackers[3] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[3].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[3].label_name).toLowerCase()}`
              : '',
          tracker_4_pixel:
            placement.trackers[3] !== undefined
              ? placement.trackers[3].tracker_value
              : '',
          tracker_4_use_as_destination: this.checkOption(placement.trackers[3])
            ? 'Yes'
            : 'No',
          tracker_5_label:
            placement.trackers[4] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[4].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[4].label_name).toLowerCase()}`
              : '',
          tracker_5_pixel:
            placement.trackers[4] !== undefined
              ? placement.trackers[4].tracker_value
              : '',
          tracker_5_use_as_destination: this.checkOption(placement.trackers[4])
            ? 'Yes'
            : 'No',
          tracker_6_label:
            placement.trackers[5] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[5].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[5].label_name).toLowerCase()}`
              : '',
          tracker_6_pixel:
            placement.trackers[5] !== undefined
              ? placement.trackers[5].tracker_value
              : '',
          tracker_6_use_as_destination: this.checkOption(placement.trackers[5])
            ? 'Yes'
            : 'No',
          tracker_7_label:
            placement.trackers[6] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[6].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[6].label_name).toLowerCase()}`
              : '',
          tracker_7_pixel:
            placement.trackers[6] !== undefined
              ? placement.trackers[6].tracker_value
              : '',
          tracker_7_use_as_destination: this.checkOption(placement.trackers[6])
            ? 'Yes'
            : 'No',
          tracker_8_label:
            placement.trackers[7] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[7].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[7].label_name).toLowerCase()}`
              : '',
          tracker_8_pixel:
            placement.trackers[7] !== undefined
              ? placement.trackers[7].tracker_value
              : '',
          tracker_8_use_as_destination: this.checkOption(placement.trackers[7])
            ? 'Yes'
            : 'No',
          tracker_9_label:
            placement.trackers[8] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[8].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[8].label_name).toLowerCase()}`
              : '',
          tracker_9_pixel:
            placement.trackers[8] !== undefined
              ? placement.trackers[8].tracker_value
              : '',
          tracker_9_use_as_destination: this.checkOption(placement.trackers[8])
            ? 'Yes'
            : 'No',
          tracker_10_label:
            placement.trackers[9] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[9].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[9].label_name).toLowerCase()}`
              : '',
          tracker_10_pixel:
            placement.trackers[9] !== undefined
              ? placement.trackers[9].tracker_value
              : '',
          tracker_10_use_as_destination: this.checkOption(placement.trackers[9])
            ? 'Yes'
            : 'No',
          tracker_11_label:
            placement.trackers[10] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[10].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[10].label_name).toLowerCase()}`
              : '',
          tracker_11_pixel:
            placement.trackers[10] !== undefined
              ? placement.trackers[10].tracker_value
              : '',
          tracker_11_use_as_destination: this.checkOption(
            placement.trackers[10]
          )
            ? 'Yes'
            : 'No',
          tracker_12_label:
            placement.trackers[11] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[11].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[11].label_name).toLowerCase()}`
              : '',
          tracker_12_pixel:
            placement.trackers[11] !== undefined
              ? placement.trackers[11].tracker_value
              : '',
          tracker_12_use_as_destination: this.checkOption(
            placement.trackers[11]
          )
            ? 'Yes'
            : 'No',
          tracker_13_label:
            placement.trackers[12] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[12].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[12].label_name).toLowerCase()}`
              : '',
          tracker_13_pixel:
            placement.trackers[12] !== undefined
              ? placement.trackers[12].tracker_value
              : '',
          tracker_13_use_as_destination: this.checkOption(
            placement.trackers[12]
          )
            ? 'Yes'
            : 'No',
          tracker_14_label:
            placement.trackers[13] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[13].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[13].label_name).toLowerCase()}`
              : '',
          tracker_14_pixel:
            placement.trackers[13] !== undefined
              ? placement.trackers[13].tracker_value
              : '',
          tracker_14_use_as_destination: this.checkOption(
            placement.trackers[13]
          )
            ? 'Yes'
            : 'No',
          tracker_15_label:
            placement.trackers[14] !== undefined
              ? `[${_.get(_.find(creativeObj.events, { creative_event_id: placement.trackers[14].creative_event_id }), 'display_name', '')}]:${String(placement.trackers[14].label_name).toLowerCase()}`
              : '',
          tracker_15_pixel:
            placement.trackers[14] !== undefined
              ? placement.trackers[14].tracker_value
              : '',
          tracker_15_use_as_destination: this.checkOption(
            placement.trackers[14]
          )
            ? 'Yes'
            : 'No'
        }

        this.jsondata.push({
          id: placement.id,
          name: placement.name,
          // campaign_id: placement.campaign_id,
          external_placement_id: placement.external_placement_id,
          placement_source: this.campaign.campaign_source,
          format_name,
          third_party_id: placement.third_party_id || '',
          verification_third_party_id:
            placement.verification_third_party_id || '',
          // main_kpi: main_kpi_name,
          // creative_tag: tag,
          append_script: script,
          // creative_id: placement.creative_id || "",
          // external_creative_id: placement.external_creative_id || "",
          creative: creative || '',

          // **************
          // we'll need to iterate over the placement trackers
          // old trackers that are not in the assigned creative, are still there ?????????
          ...trackers
          // --------
        })
      })
    },
    getNewEmptyRecord() {
      const rec = {
        id: '',
        name: '',
        external_placement_id: '',
        placement_source: this.campaign.campaign_source, // "Airtory Sync",
        format_name: '',
        third_party_id: '',
        verification_third_party_id: '',
        // main_kpi: "",
        append_script: '',
        creative: '',

        tracker_1_label: '',
        tracker_1_pixel: '',
        tracker_1_use_as_destination: 'No',
        tracker_2_label: '',
        tracker_2_pixel: '',
        tracker_2_use_as_destination: 'No',
        tracker_3_label: '',
        tracker_3_pixel: '',
        tracker_3_use_as_destination: 'No',
        tracker_4_label: '',
        tracker_4_pixel: '',
        tracker_4_use_as_destination: 'No',
        tracker_5_label: '',
        tracker_5_pixel: '',
        tracker_5_use_as_destination: 'No',
        tracker_6_label: '',
        tracker_6_pixel: '',
        tracker_6_use_as_destination: 'No',
        tracker_7_label: '',
        tracker_7_pixel: '',
        tracker_7_use_as_destination: 'No',
        tracker_8_label: '',
        tracker_8_pixel: '',
        tracker_8_use_as_destination: 'No',
        tracker_9_label: '',
        tracker_9_pixel: '',
        tracker_9_use_as_destination: 'No',
        tracker_10_label: '',
        tracker_10_pixel: '',
        tracker_10_use_as_destination: 'No',
        tracker_11_label: '',
        tracker_11_pixel: '',
        tracker_11_use_as_destination: 'No',
        tracker_12_label: '',
        tracker_12_pixel: '',
        tracker_12_use_as_destination: 'No',
        tracker_13_label: '',
        tracker_13_pixel: '',
        tracker_13_use_as_destination: 'No',
        tracker_14_label: '',
        tracker_14_pixel: '',
        tracker_14_use_as_destination: 'No',
        tracker_15_label: '',
        tracker_15_pixel: '',
        tracker_15_use_as_destination: 'No'
      }

      return rec
    },
    newRecord() {
      // Call this to new record
      this.$refs.grid.newRecord(this.getNewEmptyRecord())
    },
    cloneRecord() {
      const selectedRecords = this.$refs.grid.getSelectedRecords()
      let transformedRecords

      if (selectedRecords.length > 0) {
        transformedRecords = selectedRecords.map((item) => {
          const _item = _.cloneDeep(item)
          delete _item.$id
          _item.name = _item.name + '_copy'
          _item.id = ''
          _item.external_placement_id = ''

          this.jsondata.push(_item)
          // this.$refs.grid.newRecord(_item)
          return _item
        })
      }

      this.$log.info(transformedRecords, this.jsondata)
    },
    deleteRecord() {
      let allAreNewRecords = false
      const selectedRecords = this.$refs.grid.getSelectedRecords()
      const existentPlacements = []

      if (selectedRecords.length > 0) {
        // show buttons duplicate and delete
        selectedRecords.map((item) => {
          if (item.id === '') {
            allAreNewRecords = true
          } else {
            existentPlacements.push(item.id)
          }
        })
      }

      if (allAreNewRecords) {
        this.$refs.grid.deleteSelectedRecords()
      } else {
        // we still don't have delete in bulk
        if (existentPlacements.length === 1) {
          this.delete(existentPlacements[0])
            .then((response) => {
              this.$refs.grid.deleteSelectedRecords()
            })
            .catch((error) => {
              alert('There was an error deleting the placement.')
            })
        } else {
          // bulk delete
          alert(
            'It is not allowed to delete more than one placement at a time.'
          )
        }
      }
    },
    onRowSelectUnselect(aoo) {
      // TODO no working vue-excel-editor has an error when trigger unselect, remain getSelectedRecords length
      // aoo array of objects, we don't know when are seleted or unselected, is the same emit
      // this.$log.info('Select / Unselect', aoo);
      let allAreNewRecords = false

      const selectedRecords = this.$refs.grid.getSelectedRecords()
      if (selectedRecords.length > 0) {
        // show buttons duplicate and delete
        selectedRecords.map((item) => {
          if (item.id === '') {
            allAreNewRecords = true
          } else {
            allAreNewRecords = false
          }
        })

        if (allAreNewRecords) this.showDeleteRecord = true
        this.showDuplicateRecord = true
      } else {
        this.showDeleteRecord = this.showDuplicateRecord = false
      }
    },
    onBeforeCreativeChange(newVal, oldVal, oldRow, field) {
      // let's update the creative tag based on the assigned creative
      // const creative = _.find(this.campaign.creatives, { name: newVal } );
      // console.log(creative, creative.format_id);
      // oldRow.creative_tag = creative.creative_tag
    },
    onBeforeTrackerChange(newVal, oldVal, oldRow, field) {
      this.$log.info(newVal)
    },
    checkOption(tracker) {
      if (tracker === undefined) return false

      const l = tracker.options.length
      // this.$log.info(tracker.options);
      return l > 0 && tracker.options[l - 1].option_value === '1'
    },
    getOptionValueFromYesNo(val) {
      if (val === 'Yes') return '1'
      return '0'
    },
    getCleanLabel(label) {
      if (label === '') return false
      return String(label).slice(
        String(label).indexOf(']:') + 2,
        String(label).length
      )
    },
    exportTable() {
      // turn on loading spinner
      this.loading = true

      const jsonData
        = this.jsondata.length > 0 ? this.jsondata : [this.getNewEmptyRecord()]

      const payloadXLS = {
        campaign: {
          name: this.campaign.name,
          id: this.campaign.id,
          advertiser: this.campaign.advertiser
        },
        kpis: this.getKPIsList,
        formats: this.getFormatsList,
        creatives: this.getCreatives,
        trackers: this.getTrackers,
        destination: this.getYesNo,
        placements: jsonData
      }

      // this.$log.info(JSON.stringify(payloadXLS));
      // we have to check the region and set the endpoint accordingly if is east or west
      const endpointDownload = Utils.validateRegion(localStorage.current_region)
        ? process.env.VUE_APP_ENDPOINT_TRAFFIC_PLAN_XLSX_DOWNLOAD
        : process.env.VUE_APP_ENDPOINT_TRAFFIC_PLAN_XLSX_DOWNLOAD_WEST

      axios
        .post(endpointDownload, payloadXLS, {
          responseType: 'arraybuffer'
        })
        // Api.trafficPlanFileDownload(payloadXLS)
        .then((response) => {
          const bufferData = response.data
          // this.$log.info('axios', bufferData);

          const blob = new Blob([bufferData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          const url = window.URL.createObjectURL(blob)
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.download = `Traffic_Plan_${String(this.campaign.name).split(' ').join('-')}_[ID:${this.campaign.id}]_${Date.now()}_.xlsx`
          // anchor.click();
          anchor.dispatchEvent(new MouseEvent('click'))
          window.URL.revokeObjectURL(url)
        })
        .catch((error) => {
          console.log('axios', error)
        })
        .finally(() => {
          // turn off loading spinner
          this.loading = false
        })

      // Call this to export
      // this.$refs.grid.exportTable('xlsx', false, `TrafficPlan_${this.campaign.id}_${Date.now()}`)
    },
    importTable(e) {
      this.$log.info(this.jsondata, e, this.assetUploader.file)

      // Call this to import
      // this.$refs.grid.doImport(e);
      this.assetUploader.file = e.target.files

      this.doImportLocal(e)

      // set the callback
      // this.$refs.grid.importTable(this.printResult);
    },
    doImportLocal(e) {
      const self = this

      // turn on loading spinner
      self.loading = true

      // this.clearAllSelected()
      setTimeout(() => {
        const files = e.target.files // this.assetUploader.file
        // this.$log.info('e', e, this.assetUploader.file);
        if (!files || files.length === 0) {
          // turn off loading spinner
          self.loading = false
          return
        }
        const file = files[0]
        const fileReader = new FileReader()

        fileReader.onload = async (e) => {
          try {
            // send buffer data to endpoint to parseXLS in exceljs <<<<<<<<<
            const data = e.target.result

            // const response = await axios.post('http://localhost:3000/api/v1/xls/fileupload', { buffer: data })
            // we have to check the region and set the endpoint accordingly if is east or west
            const endpointUpload = Utils.validateRegion(
              localStorage.current_region
            )
              ? process.env.VUE_APP_ENDPOINT_TRAFFIC_PLAN_XLSX_UPLOAD
              : process.env.VUE_APP_ENDPOINT_TRAFFIC_PLAN_XLSX_UPLOAD_WEST

            const response = await axios.post(endpointUpload, { buffer: data })
            // const response = await Api.trafficPlanFileUpload({ buffer: data })
            // console.log('axios', response);
            this.jsondata = response.data.data.data
          } catch (e) {
            self.$log.info('Error', e.stack)
          } finally {
            // turn off loading spinner
            self.loading = false
            self.assetUploader.file = null
          }
        }
        fileReader.onerror = (e) => {
          // turn off loading spinner
          self.loading = false
          self.assetUploader.file = null
          self.$log.info('Error', e.stack)
        }
        fileReader.readAsBinaryString(file)
      }, 500)
    },
    printResult(result) {
      this.$log.info(result, this.jsondata)
    },
    formSubmit: function (e) {
      const self = this

      this.listUploaded = []
      let creative, creative_requested, format_id, main_kpi
      let creative_event_1,
        creative_event_2,
        creative_event_3,
        creative_event_4,
        creative_event_5,
        creative_event_6,
        creative_event_7,
        creative_event_8,
        creative_event_9,
        creative_event_10,
        creative_event_11,
        creative_event_12,
        creative_event_13,
        creative_event_14,
        creative_event_15
      let impression, click, viewability

      this.jsondata.map((placement, index) => {
        const _placement = {}
        // format_id = _.get(_.find(this.creativeFormats, {name: placement.format_name}), 'id', '');
        // main_kpi = this.getKPIID(placement.main_kpi);
        // find creatives event id
        creative = _.find(self.campaign.creatives, { name: placement.creative })

        if (!creative)
          alert(
            `The creative ${placement.creative} does not exists or the name is incorrect.`
          )

        // make sure that every placement has this campaign id
        _placement.id = placement.id
        _placement.name = placement.name
        _placement.external_placement_id = placement.external_placement_id
        _placement.placement_source = self.campaign.campaign_source
        _placement.format_id = creative.format_id || ''
        _placement.third_party_id = placement.third_party_id || ''
        _placement.verification_third_party_id
          = placement.verification_third_party_id || ''
        _placement.main_kpi = creative.main_kpi_id || 0
        _placement.creative_tag_id = 1
        _placement.creative_tag = placement.creative_tag // placement.creative_tag; //
        _placement.append_script = placement.append_script
        _placement.creative_id = Number(creative.id)
        _placement.external_creative_id = creative.external_creative_id
        _placement.campaign_id = this.campaign.id
        ;(_placement.external_campaign_id = this.campaign.external_campaign_id),
          (_placement.campaign_name = this.campaign.name),
          (_placement.start_date = this.campaign.start_date),
          (_placement.end_date = this.campaign.end_date),
          (_placement.goal = this.campaign.kpi_goal || 100000),
          (_placement.publisher_name
            = this.campaign.publisher_name || 'PadSquad'),
          (_placement.trackers = []),
          (_placement.status_source = 'Updating')
        _placement.updateSource = 0

        // impression = creative.events.find(item => item.label_name === 'impression' || item.label_name === 'Impression');
        // click = creative.events.find(item => item.label_name === 'click' || item.label_name === 'Click' || item.label_name === 'Cick');
        // viewability = creative.events.find(item => item.label_name === 'viewability');
        // creative_requested = creative.events.find(item => item.label_name === 'creative requested');
        // console.log('variables', impression, click, viewability, creative_requested)

        // select creative event (to get its id) based on the creative of this placement and the label [Custom]:click
        creative_event_1 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_1_label)
        )
        creative_event_2 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_2_label)
        )
        creative_event_3 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_3_label)
        )
        creative_event_4 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_4_label)
        )
        creative_event_5 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_5_label)
        )
        creative_event_6 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_6_label)
        )
        creative_event_7 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_7_label)
        )
        creative_event_8 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_8_label)
        )
        creative_event_9 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_9_label)
        )
        creative_event_10 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_10_label)
        )
        creative_event_11 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_11_label)
        )
        creative_event_12 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_12_label)
        )
        creative_event_13 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_13_label)
        )
        creative_event_14 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_14_label)
        )
        creative_event_15 = creative.events.find(
          (item) =>
            String(item.label_name).toLowerCase()
            === this.getCleanLabel(placement.tracker_15_label)
        )
        // this.$log.info('Creative Event', creative_event_1)

        // TODO we need to add de placement_id on each tracker only when is not '' empty
        if (_placement.id !== '' || typeof _placement.id === 'undefined') {
          // find trackers id
          if (
            creative_event_1
            && placement.tracker_1_label !== ''
            && placement.tracker_1_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_1.creative_event_id,
              tracker_value: placement.tracker_1_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_1_use_as_destination
                )
              }
            })
          if (
            creative_event_2
            && placement.tracker_2_label !== ''
            && placement.tracker_2_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_2.creative_event_id,
              tracker_value: placement.tracker_2_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_2_use_as_destination
                )
              }
            })
          if (
            creative_event_3
            && placement.tracker_3_label !== ''
            && placement.tracker_3_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_3.creative_event_id,
              tracker_value: placement.tracker_3_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_3_use_as_destination
                )
              }
            })
          if (
            creative_event_4
            && placement.tracker_4_label !== ''
            && placement.tracker_4_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_4.creative_event_id,
              tracker_value: placement.tracker_4_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_4_use_as_destination
                )
              }
            })
          if (
            creative_event_5
            && placement.tracker_5_label !== ''
            && placement.tracker_5_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_5.creative_event_id,
              tracker_value: placement.tracker_5_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_5_use_as_destination
                )
              }
            })
          if (
            creative_event_6
            && placement.tracker_6_label !== ''
            && placement.tracker_6_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_6.creative_event_id,
              tracker_value: placement.tracker_6_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_6_use_as_destination
                )
              }
            })
          if (
            creative_event_7
            && placement.tracker_7_label !== ''
            && placement.tracker_7_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_7.creative_event_id,
              tracker_value: placement.tracker_7_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_7_use_as_destination
                )
              }
            })
          if (
            creative_event_8
            && placement.tracker_8_label !== ''
            && placement.tracker_8_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_8.creative_event_id,
              tracker_value: placement.tracker_8_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_8_use_as_destination
                )
              }
            })
          if (
            creative_event_9
            && placement.tracker_9_label !== ''
            && placement.tracker_9_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_9.creative_event_id,
              tracker_value: placement.tracker_9_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_9_use_as_destination
                )
              }
            })
          if (
            creative_event_10
            && placement.tracker_10_label !== ''
            && placement.tracker_10_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_10.creative_event_id,
              tracker_value: placement.tracker_10_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_10_use_as_destination
                )
              }
            })
          if (
            creative_event_11
            && placement.tracker_11_label !== ''
            && placement.tracker_11_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_11.creative_event_id,
              tracker_value: placement.tracker_11_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_11_use_as_destination
                )
              }
            })
          if (
            creative_event_12
            && placement.tracker_12_label !== ''
            && placement.tracker_12_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_12.creative_event_id,
              tracker_value: placement.tracker_12_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_12_use_as_destination
                )
              }
            })
          if (
            creative_event_13
            && placement.tracker_13_label !== ''
            && placement.tracker_13_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_13.creative_event_id,
              tracker_value: placement.tracker_13_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_13_use_as_destination
                )
              }
            })
          if (
            creative_event_14
            && placement.tracker_14_label !== ''
            && placement.tracker_14_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_14.creative_event_id,
              tracker_value: placement.tracker_14_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_14_use_as_destination
                )
              }
            })
          if (
            creative_event_15
            && placement.tracker_15_label !== ''
            && placement.tracker_15_pixel !== ''
          )
            _placement.trackers.push({
              placement_id: Number(_placement.id),
              creative_event_id: creative_event_15.creative_event_id,
              tracker_value: placement.tracker_15_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_15_use_as_destination
                )
              }
            })
        } else {
          if (
            creative_event_1
            && placement.tracker_1_label !== ''
            && placement.tracker_1_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_1.creative_event_id,
              tracker_value: placement.tracker_1_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_1_use_as_destination
                )
              }
            })
          if (
            creative_event_2
            && placement.tracker_2_label !== ''
            && placement.tracker_2_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_2.creative_event_id,
              tracker_value: placement.tracker_2_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_2_use_as_destination
                )
              }
            })
          if (
            creative_event_3
            && placement.tracker_3_label !== ''
            && placement.tracker_3_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_3.creative_event_id,
              tracker_value: placement.tracker_3_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_3_use_as_destination
                )
              }
            })
          if (
            creative_event_4
            && placement.tracker_4_label !== ''
            && placement.tracker_4_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_4.creative_event_id,
              tracker_value: placement.tracker_4_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_4_use_as_destination
                )
              }
            })
          if (
            creative_event_5
            && placement.tracker_5_label !== ''
            && placement.tracker_5_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_5.creative_event_id,
              tracker_value: placement.tracker_5_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_5_use_as_destination
                )
              }
            })
          if (
            creative_event_6
            && placement.tracker_6_label !== ''
            && placement.tracker_6_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_6.creative_event_id,
              tracker_value: placement.tracker_6_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_6_use_as_destination
                )
              }
            })
          if (
            creative_event_7
            && placement.tracker_7_label !== ''
            && placement.tracker_7_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_7.creative_event_id,
              tracker_value: placement.tracker_7_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_7_use_as_destination
                )
              }
            })
          if (
            creative_event_8
            && placement.tracker_8_label !== ''
            && placement.tracker_8_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_8.creative_event_id,
              tracker_value: placement.tracker_8_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_8_use_as_destination
                )
              }
            })
          if (
            creative_event_9
            && placement.tracker_9_label !== ''
            && placement.tracker_9_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_9.creative_event_id,
              tracker_value: placement.tracker_9_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_9_use_as_destination
                )
              }
            })
          if (
            creative_event_10
            && placement.tracker_10_label !== ''
            && placement.tracker_10_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_10.creative_event_id,
              tracker_value: placement.tracker_10_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_10_use_as_destination
                )
              }
            })
          if (
            creative_event_11
            && placement.tracker_11_label !== ''
            && placement.tracker_10_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_10.creative_event_id,
              tracker_value: placement.tracker_10_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_10_use_as_destination
                )
              }
            })
          if (
            creative_event_12
            && placement.tracker_12_label !== ''
            && placement.tracker_10_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_10.creative_event_id,
              tracker_value: placement.tracker_10_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_10_use_as_destination
                )
              }
            })
          if (
            creative_event_13
            && placement.tracker_13_label !== ''
            && placement.tracker_10_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_10.creative_event_id,
              tracker_value: placement.tracker_10_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_10_use_as_destination
                )
              }
            })
          if (
            creative_event_14
            && placement.tracker_14_label !== ''
            && placement.tracker_10_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_10.creative_event_id,
              tracker_value: placement.tracker_10_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_10_use_as_destination
                )
              }
            })
          if (
            creative_event_15
            && placement.tracker_15_label !== ''
            && placement.tracker_10_pixel !== ''
          )
            _placement.trackers.push({
              creative_event_id: creative_event_10.creative_event_id,
              tracker_value: placement.tracker_10_pixel,
              option: {
                option_id: 1,
                option_value: this.getOptionValueFromYesNo(
                  placement.tracker_10_use_as_destination
                )
              }
            })
        }
        if (_placement.name !== '' && _placement.name !== null)
          this.listUploaded.push(_placement)
        this.$log.info('placement', _placement)
      })

      self.createUpdatePlacements()
    },
    saveData: function () {},
    cancel: function () {
      const self = this

      // emit event
      self.$emit('done')
    },
    getStatus: function (id) {
      const item = _.find(this.listMatched, { id }) // {id:id}
      if (!item) return ''

      return item.status
      // return 'new';
    },
    matchItems: function () {
      this.listMatched = []
      // match items to see if are new or updated
      if (this.campaign.placements.length < 1) {
        this.listUploaded.map((item, index) => {
          this.listMatched.push({ id: item.id, status: 'new' })
        })
      } else {
        this.listUploaded.map((item, index) => {
          const itemOld = this.campaign.placements.find(
            (oldItem) => oldItem.id == item.id
          )
          if (itemOld) {
            if (_.isEqual(item, itemOld)) {
              this.listMatched.push({ id: item.id, status: '' })
            } else {
              this.listMatched.push({ id: item.id, status: 'update' })
            }
          } else {
            this.listMatched.push({ id: item.id, status: 'new' })
          }
        })
      }
    },
    getFormatName: function (formatId) {
      return _.get(_.find(this.creativeFormats, { id: formatId }), 'name', '')
    },
    getKPIName: function (kpiID) {
      return _.get(_.find(this.KPIs, { id: kpiID }), 'name', '')
    },
    getKPIID: function (KPIName) {
      return _.get(_.find(this.KPIs, { name: KPIName }), 'id', '')
    },
    createUpdatePlacements: function () {
      const self = this

      // self.loading = true;

      // if(this.validItemsSelectedToObjects.length == 0) return;

      // CHECK STATUS TO SEE WE NEED TO CREATE OR UPDATE PLACEMENT
      const promises = []
      const toUpdatePlacements = []
      const toCreatePlacements = []

      self.listUploaded.map((placement, index) => {
        // if(item.status == "new") {
        if (placement.id === '' || typeof placement.id === 'undefined') {
          toCreatePlacements.push(placement)
        } else {
          placement.id = parseInt(placement.id)

          const oldplacement = self.campaign.placements.find(
            (x) => x.id == placement.id
          )
          if (
            oldplacement.name != placement.name
            || oldplacement.creative_id != placement.creative_id
          ) {
            placement.updateSource = 1
          }

          const isequal = _.isEqual(oldplacement, placement)
          toUpdatePlacements.push(placement)
        }
      })

      self.$log.info(toUpdatePlacements, toCreatePlacements)
      // console.log(toUpdatePlacements, toCreatePlacements);

      // Notify user saving the data

      // bulk create
      // idlc splice the placemente list
      const ListOfPlacementList = []
      const ListOfPlacementListUpdate = []
      const index = 40
      const timeout = 1
      let listRequestCount = 0
      while (toCreatePlacements.length > index) {
        listRequestCount++
        ListOfPlacementList.push(toCreatePlacements.splice(0, index))
      }

      // add to the list the records  down to 50
      if (toCreatePlacements.length > 0 && toCreatePlacements.length < index) {
        ListOfPlacementList.push(toCreatePlacements)
        listRequestCount++
      }
      const createdData = []
      ListOfPlacementList.map((item) => {
        promises.push(
          self.createBulk({ campaign_id: self.campaign.id, placements: item })
        )
      })
      while (toUpdatePlacements.length > index) {
        ListOfPlacementListUpdate.push(toUpdatePlacements.splice(0, index))
      }
      // add to the list of update records  down to 50
      if (toUpdatePlacements.length > 0) {
        ListOfPlacementListUpdate.push(toUpdatePlacements)
      }

      ListOfPlacementListUpdate.map((item) => {
        promises.push(
          self.createBulk({ campaign_id: self.campaign.id, placements: item })
        )
      })
      setTimeout(() => {
        self
          .getCampaign(self.campaign.id)
          .then((response) => {
            self.$emit('done')
          })
          .catch((err) => {
            console.log('error', err)
          })
        /*      self.fetchWithOutTrackers(self.campaign.id).then(
                  placements=> {
                    console.log('self.campaign.id',self.campaign.id)
                 console.log('placements',placements)
                      self.setPlacements(placements);
                    self.$emit('done', placements);

                  }) */
      }, 5000)
    },
    downloadAsset: function (assetFile) {
      const link = document.createElement('a')
      link.download = assetFile.name
      link.target = '_blank'
      link.href = assetFile.path
      link.click()
    },
    populateForm: function () {
      const self = this

      self.$log.info('TRAFFIC PLAN', self.campaign.placements, self.campaign)

      this.mapPlacementsToJsonData()
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.listUploaded.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.itemsSelected, String(item.name), true)
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.listUploaded.map((item, key) => {
          this.$set(this.itemsSelected, String(item.name), false)
        })
      }
    },
    informUserLoadingTp: function () {
      this.$swal({
        position: 'top',
        title: 'Success!',
        html: '<h3>Looks like your traffic plan is large and will take some extra time to load. Please wait until your request is completed.</h3>',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'success',
        timer: 10000,
        toast: false
      })
    },
    getDataByCampaign: function () {
      const self = this
      self.loading = true
      self
        .getCampaign(this.$route.params.id)
        .then((response) => {
          self.fetchWithOutTrackers(response.id).then((placements) => {
            if (placements.length > 100) {
              self.informUserLoadingTp()
            }
            let listRequestCount = 0
            const newplacements = placements.map((x) => {
              x.trackers = []
              return x
            })
            response.placements.push(...newplacements)
            Object.assign(self.campaign, response)
            // let dataids = [...newplacements]
            // let  trackersList =[];
            const ListOfPlacementList = []
            const index = 25
            // let timeout=0
            const promise = []
            while (newplacements.length > index) {
              listRequestCount++
              ListOfPlacementList.push(newplacements.splice(0, index))
            }
            if (newplacements.length > 0 && newplacements.length <= index) {
              listRequestCount++
              ListOfPlacementList.push(newplacements)
            }

            ListOfPlacementList.map((item) => {
              // timeout++

              promise.push(
                this.fetchTrackersByPlacementList(item.map((id) => id.id))
              )
              /*
                    setTimeout(() => {
                      this.fetchTrackersByPlacementList(item.map(id=> id.id))
                          .then(response => {

                            if(response != undefined)
                              {
                              item.map(t=> {
                              let trackers =response.filter(tra=> tra.placement_id ==t.id )

                              trackersList.push({placement_id: t.id, trackers :trackers})
                            })

                              listRequestCount--;
                              console.log('listRequestCount',listRequestCount)
                              console.log('trackersList',trackersList)
                            if (listRequestCount==0) {

                              if(trackersList.length > 0){
                              self.campaign.placements = self.campaign.placements.map(p=> {
                                  let trackers = trackersList.find(x=> x.placement_id ==p.id)
                                  p.trackers.push(...trackers.trackers)
                                  return p;
                                })
                                }

                                self.setPlacements(self.campaign.placements);
                                setTimeout(() => {
                                  self.loading = false;
                                  self.populateForm();
                                  self.$log.info('CAMPAIGN', self.campaign);
                                }, 2000);

                            }
                              }
                      });
                      }, (timeout * 5000));
                      */
              // promises.push(this.fetchTrackersByPlacementList(item.map(id=> id.id)))
              // promises.push(this.getCampaignByIdTopromise(campaignId,index,item.map(id=> id.id)))
            })

            Promise.all(promise)
              .then((response) => {
                // self.loading = false;
                let newlistplacements
                if (response != undefined) {
                  newlistplacements = self.campaign.placements.map((p) => {
                    response.forEach((item) => {
                      if (item != undefined) {
                        const trackers = item.filter(
                          (tra) => tra.placement_id == p.id
                        )
                        if (trackers != undefined) {
                          p.trackers.push(...trackers)
                        }
                      }
                    })
                    return p
                  })
                }
                self.setPlacements(newlistplacements)
                setTimeout(() => {
                  self.loading = false
                  self.populateForm()
                  self.$log.info('CAMPAIGN', self.campaign)
                }, 2000)
                // self.$emit('done', response);
              })
              .catch((err) => {
                alert(
                  'It appears that there was an error updating the placements. Please try again.'
                )

                self.loading = false
              })
          })
        })
        .catch((error) => {
          self.$log.error('CAMPAIGN', error)
          alert('There was an error loading the campaign.')
        })
    }
  },
  watch: {
    /* when the section is changed */
    currentSection: function (value) {
      const self = this
    },
    listUploaded: function () {
      const self = this
      self.matchItems()
    }
  },
  beforeUnmount() {
    const self = this
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';

.new {
  color: white;
  background-color: $green1;
  margin-right: $spacing-unit/2;
  padding: 0.4rem $spacing-unit/2;
  border-radius: $roundness;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.update {
  color: white;
  background-color: $green9;
  margin-right: $spacing-unit/2;
  padding: 0.4rem $spacing-unit/2;
  border-radius: $roundness;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.buttons {
  margin-bottom: $spacing-unit;
}

.vue-excel-editor {
  display: block !important;
}

.systable th[data-v-cf2e49d2],
.systable td[data-v-cf2e49d2] {
  font-size: 1rem !important;
}

.button-add-row .i {
  margin-right: 0 !important;
}
</style>
