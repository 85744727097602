<script setup>
import { computed, ref } from 'vue'
import RenderIFrameContent from '@/components/creative-preview/RenderIFrameContent.vue'
import CreativePreviewMobileShell from '@/components/creative-preview/device-shells/CreativePreviewMobileShell.vue'
import CreativePreviewDesktopShell from '@/components/creative-preview/device-shells/CreativePreviewDesktopShell.vue'

const props = defineProps({
  creative: {
    type: Object,
    required: true
  },
  view: {
    type: String,
    required: true
  }
})

const previewSettings = ref({
  mobileSize: 'small',
  landscape: false
})
const shellSettings = {
  mobileScreenBgColor: '#fff'
}

const shellComponent = computed(() => {
  return props.view === 'desktop' ? CreativePreviewDesktopShell : CreativePreviewMobileShell
})
</script>

<template>
  <shellComponent
    :preview-settings="previewSettings"
    :shell-settings="shellSettings"
  >
    <template v-slot:iFrameContent>
      <RenderIFrameContent
        :creative-data="creative"
        :is-dragging="false"
      />
    </template>
  </shellComponent>
</template>
