import _ from 'lodash'
import axios from 'axios'

// var ax = axios.create({
//     baseURL: process.env.VUE_APP_BACKEND_ENDPOINT,
//     headers: {
//         'x-api-key': process.env.VUE_APP_X_API_KEY
//     }
// });

const ApiCreativeProduction = function () {
  // CREATIVES
  /**
   * returns list of creatives
   */
  this.getCreativeList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVE_LIST}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  /**
   * returns list of temporary creatives
   */
  this.getTemporaryCreativeList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_TEMPORARY_CREATIVE_LIST}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * returns list of Airtory creatives
   */
  this.getCreativeListReadyForTraffic = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVE_LIST_TRAFFIC}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * Get a creative by id
   * @param {number} id - Creative ID
   */
  this.getCreativeById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVE_BY_ID}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  /**
   * Get a creative by PS id
   * @param {number} id - Creative ID
   */
  this.getCreativeByPsId = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVE_BY_PS_ID}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  /**
   * Get a temporary padsquad creative by id
   * @param {number} id - Creative ID
   */
  this.getTemporaryCreativeById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_TEMPORARY_CREATIVE_BY_ID}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * create a creative
   * @param {number} data - Creative Data to be inserted
   */
  this.createCreative = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVE_CREATE}`, data)
        .then((response) => {
          // console.log(response)
          resolve(response.data.data)
        })
        .catch((error) => {
          // console.log(error)
          reject(error)
        })
    })
  }
  /**
   * create a padsquad creative
   * @param {number} data - Creative Data to be inserted
   */
  this.createPadSquadCreative = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVE_CREATE_PS}`, data)
        .then((response) => {
          // console.log(response)
          resolve(response.data.data)
        })
        .catch((error) => {
          // console.log(error)
          reject(error)
        })
    })
  }
  // ***** */
  // copiedFeatureHere
  // ***** */
  /**
   * create a temporary padsquad creative
   * @param {number} data - Creative Data to be inserted
   */
  this.createPadSquadTemporaryCreative = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_ENDPOINT_TEMPORARY_CREATIVE_CREATE_PS}`,
          data
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          // console.log(error)
          reject(error)
        })
    })
  }

  /**
   * Clone a creative
   * @param {number} data - Creative Data to be inserted
   */
  this.cloneCreatives = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVES_CLONE}`, data)
        .then((response) => {
          // console.log(response)
          resolve(response.data.data)
        })
        .catch((error) => {
          // console.log(error)
          reject(error)
        })
    })
  }

  /**
   * update a creative
   * @param {number} data - Creative Data to be inserted
   */
  this.updateCreative = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVE_UPDATE}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  /**
   * update a creative
   * @param {number} data - Creative Data to be inserted
   */
  this.updatePadSquadCreative = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVE_UPDATE_PS}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  // ***** */
  // copiedFeatureHere
  // ***** */
  /**
   * update a temporary padsquad creative
   * @param {number} data - Creative Data to be inserted
   */
  this.updatePadSquadTemporaryCreative = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_ENDPOINT_TEMPORARY_CREATIVE_UPDATE_PS}`,
          data
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * update all placements depending on a creative to reflect
   * changes made to creative
   * @param {number} data - Creative Data
   */
  this.updateCreativePlacementsOnCreativeChange = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_ENDPOINT_UPDATE_CREATIVE_PLACEMENTS_ON_CREATIVE_CHANGE}`,
          data
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * delete a creative by id
   * @param {number} id - Creative ID
   */
  this.deleteCreative = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVE_DELETE}${id}`)
        .then((response) => {
          if (response.data.status === 'ok') {
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * fetch a Airtory campaign
   * @param {number} id - Campaign Id to be inserted
   */
  this.fetchAirtoryCampaign = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_AIRTORY_SYNC_CAMPAIGN}${id}?source=airtory`
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * fetch a Airtory Creative
   * @param {number} id - Creative Id to be inserted
   */
  this.fetchAirtoryCreative = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_AIRTORY_SYNC_CREATIVE}${id}?source=airtory`
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * fetch a Airtory Placement
   * @param {number} id - Placement Id to be inserted
   */
  this.fetchAirtoryPlacement = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_AIRTORY_SYNC_PLACEMENT}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * fetch a Airtory Tag
   * @param {number} id - Tag Id to be inserted
   */
  this.fetchAirtoryTag = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_AIRTORY_SYNC_TAG}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * create a Creative events/s
   * @param {number} id - Creative Id to be inserted
   * @param {object or array} events - Creative Tracker/s events to be inserted
   */
  this.addCreativeEvents = (id, events) => {
    console.log('Data', id, events)
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_ENDPOINT_CREATIVE_ADD_EVENTS}${id}/events`,
          events
        )
        .then((response) => {
          // resolve(events);
          // console.log('response', response)
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * remove a Creative events/s
   * @param {number} id - Creative Id to be inserted
   * @param {object or array} events - Creative Tracker/s events to be inserted
   */
  this.removeCreativeEvents = (id, event) => {
    // console.log('Event api',  id, event);
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${process.env.VUE_APP_ENDPOINT_CREATIVE_DELETE_EVENTS}${id}/events/${event.creative_event_id}`
        ) //, { data: { events }})
        .then((response) => {
          // resolve(events);
          // console.log('response', response)
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * create a Creative events/s
   * @param {number} id - Creative Id to be inserted
   * @param {object or array} events - Creative Tracker/s events to be inserted
   */
  this.updateCreativeEvents = (id, events) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_ENDPOINT_CREATIVE_UPDATE_EVENTS}${id}/events`,
          events
        )
        .then((response) => {
          // resolve(events);
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * create creatives log
   * @param {number} data - Creative Data to be inserted
   */
  this.createCreativesLogs = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVES_CREATE_LOGS}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
  /**
   * create padsquad creatives log
   * @param {number} data - Creative Data to be inserted
   */
  this.createPadSquadCreativesLogs = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVES_CREATE_PS_LOGS}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * return creatives upload logs by creative id
   * @param {number} id - Creative ID
   */
  this.fetchCreativesLogs = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVES_GET_LOGS}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
  /**
   * return creatives upload logs by creative id
   * @param {number} id - Creative ID
   */
  this.fetchPadSquadCreativesLogs = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVES_GET_PS_LOGS}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * return creatives upload logs by creative id
   * @param {number} id - Creative ID
   */
  this.fetchPadSquadCreativesLogsByPsId = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVES_GET_PS_LOGS}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * Download a creative by id
   * @param {number} id - Creative ID
   */
  this.fetchCreativesDownload = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_AIRTORY_CREATIVES_DOWNLOAD}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * returns data from a creative
   * @param {number} creativeId - User ID
   */
  this.getCreativeGwdFiles = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_CREATIVES_GET_GWD_FILES}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
  /**
   * uploads zip file for creative
   * returns the url at which zip file was saved
   */
  this.uploadZip = (data) => {
    return new Promise((resolve, reject) => {
      const headers = { 'content-type': 'multipart/form-data' }
      axios
        .post(
          `${process.env.VUE_APP_ENDPOINT_CREATIVES_UPLOAD_CREATIVE_ZIP}`,
          data,
          { headers }
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
  /**
   * @param {object} data - fileName to be uploaded
   * returns a signed url for upload directly to s3 for large files
   */
    this.getSignedUrl = (data) => {
      return new Promise((resolve, reject) => {
        const headers = { 'content-type': 'application/json' }
        axios
          .post(
            `${process.env.VUE_APP_ENDPOINT_CREATIVES_GET_SIGNED_URL}`,
            data,
            { headers }
          )
          .then((response) => {
            resolve(response.data.data)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    }

  /**
   * @param {string} signedUrl - pre-signed url
   * @param {object} data - file to be uploaded
   * Takes a signedUrl and data and performs a PUT request to upload file to S3
   */
  this.uploadViaSignedUrl = (signedUrl, data) => {
    return new Promise((resolve, reject) => {
      // delete axios.defaults.headers.common['Authorization']
      const customAxios = axios.create()
      const headers = { 'content-type': 'application/json' }
      customAxios
        .put(
          signedUrl,
          data,
          { headers }
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
  /**
   * @param {object} data - must contain fileName to reference zip file
   * Triggers a lambda function to fetch a zip file, unpack it, and upload its contents
   */
    this.processOversizedZip = (data) => {
      return new Promise((resolve, reject) => {
        const headers = { 'content-type': 'application/json' }
        axios
          .post(
            `${process.env.VUE_APP_ENDPOINT_CREATIVES_PROCESS_OVERSIZED_ZIP}`,
            data,
            { headers }
          )
          .then((response) => {
            resolve(response.data.data)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    }

  /**
   * generates creative tag with form data
   * returns an object with the tag at key 'tag'
   */
  this.generateCreativeScript = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_GENERATE_CREATIVE_SCRIPT}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * delete zip at filepath 'key'
   */
  this.deleteZip = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVES_DELETE_ZIP}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * creates a qr code based on data.url
   */
  this.createQR = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_CREATIVES_CREATE_QR}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * return live poll list
   */
  this.getLivePollList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_LIVE_POLL_LIST}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * return live poll by id
   * @param {number} id - feed ID
   */
  this.getLivePollById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_LIVE_POLL_BY_ID}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * create a live poll
   * @param {object} data - Live Poll Data to be inserted
   */
  this.createLivePoll = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_LIVE_POLL_CREATE}`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * update a live poll
   * @param {object} data -
   */
  this.updateLivePoll = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_LIVE_POLL_UPDATE}`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * delete a Live Poll
   * @param {number} id - Location Feeder ID
   */
  this.deleteLivePoll = function (id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_LIVE_POLL_DELETE}${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export default new ApiCreativeProduction()
