<template>
    <footer class="moduleFooter">
        <div class="moduleFooter__left">
            <slot name="left"></slot>
        </div>
        <div class="moduleFooter__right">
            <slot name="right"></slot>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'ModuleFooter'
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.moduleFooter {
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
//   padding: $spacing-unit*2 $spacing-unit * 4;
  padding: 24px $spacing-unit * 4;
  background: $gray2;
  display: flex;
  flex-direction: column;

    a {
        color: $gray6;
    }

    .weblinks {
        .links__link {
            display: block;
            margin-bottom: $spacing-unit * 0.5;
        }
    }

    &__right {
        order: -1;
        margin-bottom: $spacing-unit;
    }

    @include media-breakpoint-up(md) {

        flex-direction: row;
        width: 100%;

        &__left {
            text-align: left;
            flex-grow: 1;
        }

        &__right {
            text-align: right;
            flex-grow: 1;
            order: initial;
            margin-bottom: 0;
        }

        .weblinks {
            .links__link {
                display: inline-block;
                margin-bottom: 0;
            }
        }

    }
}
</style>
