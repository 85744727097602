/* eslint-disable no-console */

import { register } from 'register-service-worker'

/**
 * we are redirecting all traffic from simpliv1 to studio.simpli.padsquad.com
* */
function shouldWeGo () {
  // un register the service workers here...
  navigator.serviceWorker.getRegistrations()
    .then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
    .catch(function (err) {
      console.log('Service Worker registration failed: ', err)
    })

  // redirect to simpli
  window.location.href = 'https://studio.simpli.padsquad.com' + window.location.pathname + window.location.search
}

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  // so, if we are running on simpliv1.padsquad.com
  if (window.location.hostname == 'simpliv1.padsquad.com') {
    shouldWeGo()
  } else {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready () {
        console.log(
          'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        )
      },
      cached () {
        console.log('Content has been cached for offline use.')
      },
      updatefound () {
        console.log('New content is downloading.')
      },
      updated (registration) {
        console.log('New content is available; please refresh.')
        // alert("New content is available; please refresh your browser.");

        // custom top alert
        const _alert = document.createElement('div')
        // _alert.innerHTML = "You're seeing an older version of Simpli's UI, please <a onclick='window.location.reload(true)'>refresh the page</a> to update.";
        _alert.innerHTML = "You are previewing an outdated version, please <a onclick='window.location.reload(true)'>refresh your browser.</a>"
        _alert.className = 'alertTop'

        const _app = document.getElementById('app')
        _app.insertBefore(_alert, _app.firstChild)

        const worker = registration.waiting
        worker.postMessage('SKIP_WAITING')
        // console.log(worker);
      },
      offline () {
        console.log(
          'No internet connection found. App is running in offline mode.'
        )
      },
      error (error) {
        console.error('Error during service worker registration:', error)
      }
    })
  }
}

// /* eslint-disable no-console */

// import { register } from 'register-service-worker';

// if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     ready() {
//       console.log(
//         'App is being served from cache by a service worker.\n'
//         + 'For more details, visit https://goo.gl/AFskqB',
//       );
//     },
//     registered() {
//       console.log('Service worker has been registered.');
//     },
//     cached() {
//       console.log('Content has been cached for offline use.');
//     },
//     updatefound() {
//       console.log('New content is downloading.');
//     },
//     updated() {
//       console.log('New content is available; please refresh.');
//     },
//     offline() {
//       console.log('No internet connection found. App is running in offline mode.');
//     },
//     error(error) {
//       console.error('Error during service worker registration:', error);
//     },
//   });
// }
