<template>
  <section class="sModal">
    <div class="loader" :class="{'done': (!loading)}">
      <font-awesome-icon icon="spinner" class="spinner"/>
    </div>

    <header class="sModal__header">Add Multiple Creatives to Lists</header>

    <form @submit.prevent="formSubmit">

      <div class="sModal__body">
        <FormField label="Search Lists" forId="filter-search" type="search">
          <input v-model="filterData.search" id="filter-search" type="text" placeholder="Search Lists..." />
        </FormField>

        <FormField>
          <div class="listBox">
            <ul>
              <li v-for="(list) in filteredLists" :key="list.id" :class="{hidden: !list.userCanAddTo}">
                <div class="listBox__label">
                  {{list.name}}
                </div>
                <div>
                  <!-- the checkbox v-module is the .checked property in the lists array.
                  There may be a better approach, but, for this I had to find the index of the current
                  list form the lists array by it's id property. So pretty processor intense in my opinion. -->
                  <input type="checkbox" :disabled="!list.userCanAddTo" v-model="lists[_.indexOf(lists, _.find(lists, {id:list.id}))].checked" @click="checkboxHandler($event, list.id)" />
                </div>
              </li>
            </ul>
          </div>
        </FormField>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" /> Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Save New Creative' if this is new creative and we are on the last screen-->
          <button class="saveButton" @click="saveData"><font-awesome-icon icon="check" class="i" />Save</button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'
import { mapGetters } from 'vuex'

/* sample data */
import listsSampleData from '@/assets/sample-data/all-lists.json'

export default {
  name: 'AddToList',
  components: {
    FormField
  },
  props: {
    itemId: null
  },
  data () {
    return {
      loading: true,
      filterData: {
        searchBox: ''
      },
      creative: {},
      creativeFetchedData: {},
      creatives: {},
      creativeaFetchedData: {},
      lists: []
    }
  },
  methods: {
    checkboxHandler: function (event, listId) {

    },
    formSubmit: function () {},
    isDataModified: function () {
      const self = this

      return _.isEqual(self.creative, self.creativeFetchedData)
    },
    saveData: function () {
      const self = this

      const creatives = _.cloneDeep(self.creatives)
      const creativesOriginal = _.cloneDeep(self.creatives)

      // turn on loading spinner
      self.loading = true

      // get checked lists. Just include the id.
      const checkedLists = self.lists.filter(list => list.checked).map(list => { return ({ id: list.id }) })

      // add checked lists to creative.lists. Only the ones that are not already in.
      const creativesWithLists = creatives.map(creative => {
        // add the lists that do not exist to creative.lists
        creative.lists = _.union(creative.lists, checkedLists)

        // remove duplicates
        creative.lists = _.uniqBy(creative.lists, 'id')

        return creative
      })

      self.$log.info('creative with lists:', creativesWithLists)

      // pick the creatives which lists have changed
      const creativesToUpdate = creativesWithLists.filter((creative, index) => {
        return !_.isEqual(creative.lists, creativesOriginal[index].lists)
      })

      self.$log.info('checked lists:', checkedLists)
      self.$log.info('creative as fetched:', creativesOriginal)
      self.$log.info('creatives to update:', creativesToUpdate)

      function updateCreatives () {
        return Promise.all(creativesToUpdate.map(creative => {
          const creativePostModel = {
            creativeId: creative.id,
            lists: creative.lists
          }

          return Api.updateCreativeLists(creativePostModel)
        }))
      }

      updateCreatives()
        .then(response => {
          // turn off loading spinner
          self.loading = false

          // emit event
          self.$emit('done', response)
        })
    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('done')
    },
    populate: function () {
      const self = this

      function loadCreatives () {
        return Promise.all(self.itemId.map(id => {
          return Api.getCreative(id, false, true)
        }))
      }

      loadCreatives()
        .then(creatives => {
          self.creatives = _.cloneDeep(creatives)
          self.creativesFetchedData = _.cloneDeep(creatives)
        })
        .then(Api.getListsList)
        .then(lists => {
          // store lists
          self.lists = lists.map(list => {
            list.checked = false
            list.userCanAddTo = self.userInSession.id == list.user_id || list.private != 1 || self.userInSession.role == 'Admin'
            return list
          })

          // turn off loading spinner
          self.loading = false
        })
        .catch(error => {
          self.$log.error(error)
        })
    }
  },
  created () {
    const self = this

    self.populate()
  },
  computed: {
    ...mapGetters([
      'userInSession'
    ]),
    isCreativeModified: function () {
      const self = this

      return !_.isEqual(self.creative, self.creativeFetchedData)
    },
    filteredLists: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(self.lists, self.filterData.search)

      return searchedItems
    }
  },
  watch: {
    /* when the filtered list has any change */
    filteredLists: function () {
      const self = this

      // iterate over all lists
      _.forOwn(self.lists, (list, key) => {
        const listIndexInCreativeLists = _.findIndex(self.creative.lists, { id: list.id })
        const listIndexInOriginalCreativeLists = _.findIndex(self.creativeFetchedData.lists, { id: list.id })

        // if the list exists in creative.lists...
        if (listIndexInCreativeLists > -1) {
          // ... and if the list is checked...
          if (list.checked) {
            // ... then make it active
            self.creative.lists[listIndexInCreativeLists].status = 'active'
          } else {
            // ... if it's not checked, and the creative was originally in the in creative.lists
            if (listIndexInOriginalCreativeLists > -1) {
              // ...just set it as removed, because we want to send it to the api on save.
              self.creative.lists[listIndexInCreativeLists].status = 'removed'
            } else {
              // ... otherwise just remove it from the creative.lists because there's no purpose on having it there.
              self.creative.lists.splice(listIndexInCreativeLists, 1)
            }
          }
        } else {
          // ...if it's not in the list
          // ...and the list's status is checked, then add it to creative.lists
          if (list.checked) {
            self.creative.lists.push({
              id: list.id,
              status: 'active'
            })
          }
        }
      })
    },
    lists: {
      handler: function () {
        const self = this

        self.creative.lists = _.map(_.filter(self.lists, list => { return list.checked }), list => {
          return {
            id: list.id
          }
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/common.scss";
</style>
