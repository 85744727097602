<template>
    <header class="moduleHeader">

        <div class="moduleHeader_content">
            <div v-if="$slots.tabs" class="moduleHeader__tabs">
                <slot name="tabs"></slot>
            </div>
            <span v-if="$slots.title" class="moduleHeader__title">
                <slot name="title"></slot>
            </span>
        </div>

        <div class="moduleHeader__actions">
            <slot name="actions"></slot>
        </div>

    </header>
</template>

<script>
export default {
  name: 'ModuleHeader',
  props: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">

@import "../scss/common.scss";

.moduleHeader {

  @extend .container-fluid;
  background: $moduleheader-background;
  text-align: left;
  color: white;
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;
  border-bottom: 5px solid $moduleheader-border;

  background: $green7;
  margin-top: $spacing-unit;
  padding-top: $spacing-unit*0;
  padding-bottom: 1rem;

  &__content {
    text-align: left;
  }

  &__actions {
    @include vertical-align();
    flex-grow: 1;
    text-align: right;
    display: none;
    vertical-align: middle;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: bold;
    color: $moduleheader-title;
    display: flex;

    &__brand {
      display: inline-block;
      margin-right: 2rem;

      img {
        height: 1.4em;
      }
    }
  }

  &__tabs {

    display: none;

    ul, ol {
      padding: 0;
      margin: 0;
    }

    li {
      font-size: 2.4rem;
      font-weight: bold;
      display: inline-block;
      padding: $spacing-unit $spacing-unit*2;
      margin-bottom: -$spacing-unit;
      color: $moduleheader-tabs-item-text;

      color: white;

      a {
        color: $moduleheader-tabs-item-text;
        text-decoration: none;

        color: white;

        &:hover {
          text-decoration: underline;
        }
      }

      &[selected] {
        color: $moduleheader-tabs-item--selected-text;
        background: $moduleheader-tabs-item--selected-background;

        a {
          color: $moduleheader-tabs-item--selected-text;
        }
      }
    }

  }

  &__createButton {
    @extend .button;
    color: $moduleheader-create-button-text;
    background-color: $moduleheader-create-button-background;
    border: none;
    width: auto;

    background-color: $green1;
    margin-top: 1rem;

    &:hover {
      background-color: $moduleheader-create-button-background--hover;
      background-color: $green1-2;
    }
  }

  @include media-breakpoint-up(md) {

    display: flex;

    &__actions {
      display: table-cell;
    }

    &__tabs {
      display: block;
    }

    &__title {
      font-size: 2.4rem;
    }

    &__tabs + &__title {
      display: none;
    }

  }

}

.moduleFrame--creativeRepositoryList .moduleHeader{
  background: white !important;
}

.moduleFrame--tagManagerRepository .moduleHeader{

  background: $green7;
  color: white;
  margin-top: $spacing-unit;
  padding-top: $spacing-unit*0;
  padding-bottom: 1rem;

  &__tabs {

    li {
      margin-bottom: -$spacing-unit;
      color: $moduleheader-tabs-item-text;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &[selected] {
        color: $moduleheader-tabs-item--selected-text;
        background: $moduleheader-tabs-item--selected-background;

        a {
          color: $moduleheader-tabs-item--selected-text;
        }
      }
    }

  }

  &__createButton {
    color: $moduleheader-create-button-text;
    background-color: $green1;
    // margin-top: $spacing-unit;
    margin-top: 1rem;

    &:hover {
      background-color: $green1-2;
    }
  }

   @include media-breakpoint-down(sm) {
        padding-top: 1rem ;
        padding-bottom: 1rem ;
    }

}

.moduleFrame--tagManagerRepositoryPlacements .moduleHeader{

  background: $green7;
  color: white;
  margin-top: 0;//$spacing-unit;
  padding-top: $spacing-unit*0;
  padding-bottom: 1rem;

  &__tabs {

    li {
      margin-bottom: -$spacing-unit;
      color: $moduleheader-tabs-item-text;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &[selected] {
        color: $moduleheader-tabs-item--selected-text;
        // background: $green8;
        background: $moduleheader-tabs-item--selected-background;

        a {
          color: $moduleheader-tabs-item--selected-text;
        }
      }
    }

  }

  &__createButton {
    color: $moduleheader-create-button-text;
    background-color: $green1;
    // margin-top: $spacing-unit;
    margin-top: 1rem;

    &__first{
      margin-right: $spacing-unit;
    }

    &:hover {
      background-color: $green1-2;
    }
  }

}

</style>
