<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  creativeData: {
    type: Object,
    required: true
  },
  isTagReady: {
    type: Boolean,
    required: true
  },
  mockSiteIframeKey: {
    type: Number,
    required: true
  }
})

onMounted(() => {
  window.addEventListener('message', (e) => handleMsg(e))
})

onUnmounted(() => {
  window.removeEventListener('message', (e) => handleMsg(e))
})

const events = ref([])
const isNewEvent = ref(false)
const scrollContainer = ref(null)
const blacklistedEventTypes = [
  'fourthquartile',
  'video-play-time'
]

const infoFields = computed(() => {
  return [
    {
      key: 'Traffic size',
      value: props.creativeData.adSize
    },
    {
      key: 'Total weight',
      value: formatAdWeight()
    }
  ]
})

const checkForUserEvent = (event) => {
  return event.label && event.label.startsWith('user')
}

const isJSONString = (val) => {
  try {
    JSON.parse(val)
    return true
  } catch (e) {
    return false
  }
}

const checkBlacklist = (event) => {
  let isBlacklisted = false

  if (blacklistedEventTypes.includes(event)) isBlacklisted = true

  blacklistedEventTypes.forEach(blacklistedEvent => {
    if (event.includes(blacklistedEvent)) isBlacklisted = true
  })

  return isBlacklisted
}

const handleMsg = (e) => {
  if (!e.data) return
  try {
    const msg = isJSONString(e.data) ? JSON.parse(e.data) : e.data
    if (checkBlacklist(msg?.eventValue)) return
    addEvent(msg)
  } catch (err) {}
}

const addEvent = (msg) => {
  events.value.push({
    eventType: msg.eventType || 'Custom',
    label: msg.event || msg.eventValue || msg.keyName || 'n/a'
  })
  isNewEvent.value = true
  setTimeout(() => (isNewEvent.value = false), 1000)
  setTimeout(() => {
    if (scrollContainer.value) {
      scrollContainer.value.scroll({
        top: scrollContainer.value.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, 200)
}

const clearEvents = () => {
  events.value = []
}

const getEventBackgroundColor = (idx) => {
  if (idx === events.value.length - 1 && isNewEvent.value) return '#9BCFC4'
  return idx % 2 === 0 ? '#f0f0f0' : '#FAFAFA'
}

const formatAdWeight = () => {
  return `${Math.round(props.creativeData.ad_weight / 1000)} kb`
}

const copy = (text) => {
  navigator.clipboard.writeText(text)
}
</script>

<template>
  <div class="h-100 d-flex flex-column">
    <!-- info section -->
    <div class="info-section p-4 text-white">
      <div
        v-for="(field, idx) in infoFields"
        :key="idx"
        class="w-100 d-flex my-4"
      >
        <div class="w-50" style="font-weight: 200;">{{ field.key }}</div>
        <div class="w-50">{{ field.value }}</div>
      </div>
    </div>

    <!-- event monitor -->
    <div class="flex-grow-1 d-flex flex-column justify-content-center p-3">
      <div class="my-3 text-white pl-4">EVENT MONITOR</div>
      <div class="event-monitor-container w-100 flex-grow-1 overflow-hidden">

        <!-- header -->
        <div class="monitor-header w-100 d-flex p-3 align-items-center">
          <div class="w-25">Event Type</div>
          <div class="separator mx-3"/>
          <div class="w-50">Report Label</div>
          <button
            class="clear-button px-2"
            @click="clearEvents"
          >
            CLEAR
          </button>
        </div>

        <!-- events -->
        <div class="w-100 scroll-container" ref="scrollContainer">
          <div
            v-for="(event, idx) in events"
            :key="idx"
            class="w-100 d-flex p-3 align-items-center"
            :style="`
              background-color: ${getEventBackgroundColor(idx)};
              transition: background-color 300ms linear;
            `"
          >
            <div class="w-25 text-truncate mr-5">{{ event.eventType }}</div>
            <div class="w-50 text-truncate d-block">{{ event.label }}</div>
            <div class="icons">
              <font-awesome-icon
                v-if="checkForUserEvent(event)"
                icon="user"
                class="user-icon"
              />
              <font-awesome-icon
                icon="copy"
                class="copy-icon"
                @click="copy(event.label)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.info-section {
  border-bottom: 1px solid #464646;
  min-height: 15%;
}
.event-monitor-container {
  border-radius: 5px;
  position: relative;
}
.monitor-header {
  background-color: #464646;
  color: #CACACA;
  border-radius: 3px 3px 0 0;
  height: 10%;
  position: absolute;
  inset: 0;
}
.clear-button {
  border: none !important;
  background-color: #CACACA;
  color: #464646;
  cursor: pointer;
  border-radius: 3px;
  font-size: 8px;
  margin-left: auto;
}
.separator {
  border-left: 2px solid #6D6D6D;
  height: 60%;
}
.user-icon {
  height: 10px;
  width: 10px;
}
.copy-icon {
  height: 10px;
  width: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.icons {
  margin-left: auto;
}
.copy-icon:active {
  color: #666
}
.scroll-container {
  position: absolute;
  inset: 0;
  top: 10%;
  flex-grow: 1;
  overflow: auto;
  background-color: #f0f0f0;
}
</style>
