import Api from '@/helpers/ApiUser'
import Auth from '@aws-amplify/auth'
import * as CONSTANTS from './constants'

export default {
  // Login
  async signIn ({ commit, getters, dispatch }, userData) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)
    try {
      const response = await Auth.signIn(userData.email, userData.password)
      console.log('response login', response)
      // if is an invitation, NEW PASSWORD REQUIRED
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // let aws_user_id = response.username;
        commit(CONSTANTS.SET_USER, { user: response, userInfo: { id: response.username } })
        commit(CONSTANTS.SET_FORM_TO_SHOW, 'CompleteNewPassword')
        commit(CONSTANTS.SET_MESSAGE, { text: 'Please complete the registration with a new password.', wasSent: true, gotError: false })
        commit(CONSTANTS.SET_LOADING, false)
      } else {
        /* console.log('response',response)
            console.log('attributes',response.attributes)
            console.log('attributes',response.attributes.sub)
            console.log('response.challengeName',response.challengeName) */

        setTimeout(() => {
          const buff = Buffer.from(userData.password).toString('base64')
          Api.migrateUser({ email: userData.email, password: buff, id: response.attributes.sub }).then(data => {

          })
        }, 5000)

        dispatch('fetchUser')
      }
    } catch (error) {
      // console.log(`Error: ${error.message}`, error.message == 'User is not confirmed.')
      console.log('check error', error.message)
      if (error.message == 'User is not confirmed.') {
        commit(CONSTANTS.CONFIRM_SIGNUP, true)
        commit(CONSTANTS.SET_MESSAGE, { text: 'Check your email for the verification code.', wasSent: true, gotError: false })
        commit(CONSTANTS.SET_FORM_TO_SHOW, 'Confirm')
      } else {
        commit(CONSTANTS.SET_MESSAGE, { text: 'Incorrect username or password.', wasSent: false, gotError: true })
      }

      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Sign Up
  async signUp ({ dispatch, commit, getters }, userData) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    try {
      const response = await Auth.signUp({
        username: userData.email,
        email: userData.email,
        password: userData.password,
        attributes: {
          email: userData.email
        },
        validationData: []
      })

      // console.log('response', response, response.userSub);

      const userDataComplete = await _.cloneDeep(userData)
      userDataComplete.aws_user_id = response.userSub

      // console.log(userDataComplete)

      await commit(CONSTANTS.SET_USER_DATA, userDataComplete)
      await commit(CONSTANTS.SET_SEND_DATA_TO_DB, true)

      // switch email confirmation form
      commit(CONSTANTS.CONFIRM_SIGNUP, true)
      commit(CONSTANTS.SET_MESSAGE, { text: 'Check your email for the verification code.', wasSent: true, gotError: false })

      if (getters.sendDataToDB) {
        // REGISTER USER TO DB
        await dispatch('registerToDB', getters.userData)
      } else {
        commit(CONSTANTS.SET_LOADING, false)
      }

      commit(CONSTANTS.SET_FORM_TO_SHOW, 'Confirm')
    } catch (error) {
      commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })

      if (error.message == 'An account with the given email already exists.') {
        // please login
        await dispatch('setFormToShow', 'SignIn')
      }
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Sign Up by backend Invitation
  async signUpByInvitation ({ dispatch, commit, getters }, userData) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    try {
      const response = await Api.inviteUser(userData)
      // console.log(response, userData.created_by == null);

      // if the user is not invited/created by another user, is trying to sign up
      if (userData.created_by == null) {
        const userDataComplete = await _.cloneDeep(userData)

        // console.log('user data', userDataComplete);

        await commit(CONSTANTS.SET_USER_DATA, userDataComplete)

        // switch email confirmation form
        await commit(CONSTANTS.CONFIRM_SIGNUP, true)
        await commit(CONSTANTS.SET_MESSAGE, { text: 'Check your email for the verification code.', wasSent: true, gotError: false })
        await commit(CONSTANTS.SET_LOADING, false)

        await commit(CONSTANTS.SET_FORM_TO_SHOW, 'Confirm')

        return { error: false, data: response.message }
      } else {
        return { error: false, data: response.message }
      }
    } catch (error) {
      if (userData.created_by == null) {
        await commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })

        if (error.message == 'An account with the given email already exists.') {
          // please login
          await dispatch('setFormToShow', 'SignIn')
        }
        await commit(CONSTANTS.SET_LOADING, false)

        // return error
        return { error: true, data: error.message }
      } else {
        // return error
        return { error: true, data: error.message }
      }
    }
  },
  // Confirm Sign Up
  async confirmSignUp ({ dispatch, commit }, userData) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    try {
      const response = await Auth.confirmSignUp(userData.email, userData.code, { forceAliasCreation: true })

      // go to login and set message
      // console.log('Confirmation response', response);
      // await dispatch('setUserStatus');
      await dispatch('setFormToShow', 'SignIn')
      commit(CONSTANTS.SET_MESSAGE, { text: 'Confirmation approved.', wasSent: true, gotError: false })
    } catch (error) {
      // console.log(`Error: ${error.message}`);
      commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Resend signup code
  async resendSignUpCode ({ commit }, username) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    try {
      await Auth.resendSignUp(username)
      commit(CONSTANTS.SET_MESSAGE, { text: 'Code resent successfully', wasSent: true, gotError: false })
      commit(CONSTANTS.SET_LOADING, false)
    } catch (error) {
      commit(CONSTANTS.SET_MESSAGE, error.message)
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Complete new password
  async completeNewPassword ({ dispatch, commit, getters }, userData) {
    console.log('complete new password!')
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    // console.log(userData)

    let phone = ''

    if (userData.phone_number != null) {
      // cognito requires +13330004444 phon number format
      phone = `+1${userData.phone_number}`
    }

    /*  let newUserData = {
            phone_number: phone,
            email:userData.email
        } */
    // console.log(newUserData)

    try {
      // idlc: no need to send UserDataa anymore
      const response = await Auth.completeNewPassword(getters.user, userData.password, {})

      // console.log('response', response);

      // //add id
      // let userDataComplete = _.cloneDeep(userData);
      // userDataComplete.aws_user_id = response.username; //response.signInUserSession.idToken.payload.sub,

      // await commit(CONSTANTS.SET_USER_DATA, userDataComplete)
      // await commit(CONSTANTS.SET_SEND_DATA_TO_DB, true)

      // //REGISTER USER TO DB
      // console.log('user data', getters.userData);
      // await dispatch('registerToDB', getters.userData);

      commit(CONSTANTS.SET_MESSAGE, { text: 'Now you can sign in with your new password.', wasSent: true, gotError: false })
      commit(CONSTANTS.SET_FORM_TO_SHOW, 'SignIn')
      commit(CONSTANTS.SET_LOADING, false)
    } catch (error) {
      commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  async changePassword ({ dispatch, commit, getters }, userData) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    // console.log(userData)

    try {
      const response = await Auth.changePassword(getters.user, userData.old_password, userData.password)

      // console.log('response', response);

      commit(CONSTANTS.SET_MESSAGE, { text: 'Your password has been changed. Now you can sign in with your new password.', wasSent: true, gotError: false })
      commit(CONSTANTS.SET_FORM_TO_SHOW, 'SignIn')
      commit(CONSTANTS.SET_LOADING, false)
    } catch (error) {
      commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Forgot password
  async forgotPassword ({ commit, dispatch }, userData) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    try {
      await Auth.forgotPassword(userData.email)
      commit(CONSTANTS.FORGOT_PASSWORD_SUBMIT, true)
      commit(CONSTANTS.SET_MESSAGE, { text: 'Check your email for the verification code.', wasSent: true, gotError: false })
      commit(CONSTANTS.SET_LOADING, false)
    } catch (error) {
      // console.log(`Error: ${error.message}`)

      commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Confirm forgot password
  async confirmForgotPassword ({ commit, dispatch }, userData) {
    commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })
    commit(CONSTANTS.SET_LOADING, true)

    try {
      await Auth.forgotPasswordSubmit(userData.email, userData.code, userData.password)
      commit(CONSTANTS.FORGOT_PASSWORD_SUBMIT, false)
      commit(CONSTANTS.SET_MESSAGE, { text: 'Now you can sign in with your new password.', wasSent: true, gotError: false })
      commit(CONSTANTS.SET_FORM_TO_SHOW, 'SignIn')
      commit(CONSTANTS.SET_LOADING, false)
    } catch (error) {
      commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Fetch User
  async fetchUser ({ commit, dispatch, getters }, changeForm = false) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })
      const userInfo = await Auth.currentUserInfo()
      const session = user.signInUserSession // await user.getSignInUserSession()
      const jwt = session.idToken.jwtToken
      // We registered the user with the sub from the object returned in signUp
      // const sub = userInfo.attributes.sub

      // Refresh session token
      const expires = session.idToken.payload.exp - Math.floor(new Date().getTime() / 1000)
      // console.log(`Token expires in ${expires} seconds`)

      // If stay sign in
      setTimeout(async () => {
        // console.log(`Renewing Token at time ${new Date()}`)
        // 1 way
        dispatch('fetchUser')
      }, expires * 1000)

      commit(CONSTANTS.SET_USER, { user, userInfo })
      commit(CONSTANTS.SET_JWT_TOKEN, { token: jwt, expires: session.idToken.payload.exp })
      commit(CONSTANTS.SET_AUTHENTICATED, true)

      if (userInfo.attributes.email_verified == false) {
        commit(CONSTANTS.CONFIRM_SIGNUP, true)
        commit(CONSTANTS.SET_MESSAGE, { text: 'Check your email for the verification code.', wasSent: true, gotError: false })
        dispatch('setFormToShow', 'Confirm')
      } else {
        commit(CONSTANTS.CONFIRM_SIGNUP, false)
        commit(CONSTANTS.SET_MESSAGE, { text: '', wasSent: false, gotError: false })

        // Fetch User Info from DB (userInSession)
        await dispatch('fetchUserInSessionInfo', user.username)// getters.userData.aws_user_id)

        await dispatch('setFormToShow', '')
      }
    } catch (error) {
      commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })
      commit(CONSTANTS.SET_LOADING, false)
    }
  },
  // Set message
  setMessage ({ commit }, message) {
    commit(CONSTANTS.SET_MESSAGE, message)
  },
  setFormToShow ({ commit }, value) {
    commit(CONSTANTS.SET_FORM_TO_SHOW, value)
    commit(CONSTANTS.SET_LOADING, false)
    // reset message
    commit(CONSTANTS.SET_MESSAGE, '')
  },
  registerToDB ({ dispatch, commit }, userData) {
    Api.createUser(userData)
      .then(response => {
        // console.log(response)
        commit(CONSTANTS.SET_SEND_DATA_TO_DB, false)
        commit(CONSTANTS.SET_LOADING, false)
      })
      .catch(error => {
        commit(CONSTANTS.SET_MESSAGE, { text: error.message, wasSent: false, gotError: true })
        commit(CONSTANTS.SET_LOADING, false)
      })
  },
  // Sign Out
  signOut ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then(response => {
          commit(CONSTANTS.SET_USER, {
            user: null,
            userInfo: {
              id: null,
              attributes: {
                email: null,
                email_verified: false
              }
            }
          })
          commit(CONSTANTS.SET_AUTHENTICATED, false)
          dispatch('setFormToShow', 'SignIn')
          resolve(response)
        })
        .catch(reject)
    })
  },
  // FETCH USER INFO FROM DB
  /**
     * user info from DB.
     * @param {number} userAWS_ID
     */
  fetchUserInSessionInfo: ({ commit, dispatch }, userAWS_ID) => {
    commit(CONSTANTS.SET_LOADING, true)

    return new Promise((resolve, reject) => {
      // get the info from our server
      Api.getUser(userAWS_ID)
        .then(response => {
          const _sUser = response.data[0]
          if (_sUser == undefined || _sUser == null) {
            throw new Error('User does not exists')
          } else {
            return _sUser
          }
        })
        .then(_sUser => {
          //* **let's see if we can send the CONFIRMED here
          // console.log('user fetched', _sUser, _sUser.status, _sUser.status === "CONFIRMED", _sUser.status === "UNCONFIRMED")
          if (_sUser.status === 'UNCONFIRMED') {
            dispatch('setUserStatus', _sUser.aws_user_id)
              .then(data => {
                // set confirmed to the _sUser
                _sUser.status = 'CONFIRMED'

                // repeat the commit and local storage
                commit(CONSTANTS.SET_USER_IN_SESSION, _sUser)
                localStorage._sUser = JSON.stringify(_sUser)

                resolve(_sUser)
                commit(CONSTANTS.SET_LOADING, false)
              })
              .catch(error => console.log('Error setting status'))
          } else {
            commit(CONSTANTS.SET_USER_IN_SESSION, _sUser)
            localStorage._sUser = JSON.stringify(_sUser)
            resolve(_sUser)
            commit(CONSTANTS.SET_LOADING, false)
          }
        })
        .catch(error => {
          commit(CONSTANTS.SET_LOADING, false)
          reject(error)
        })
    })
  },
  // check authentication
  async checkAuthentication ({ getters, commit, dispatch }) {
    try {
      // check user authentited
      if (getters.authenticated) {
        // console.log(`refresh token ${getters.tokenExpires} ${(getters.tokenExpires - Math.floor(new Date().getTime() / 1000)) - 3200}`);

        // check if the token has expired
        if (((getters.tokenExpires - Math.floor(new Date().getTime() / 1000) - 3200) <= 0)) {
          const user = await Auth.currentAuthenticatedUser({
            bypassCache: true
          })
          const userSession = await user.signInUserSession
          // refresh session
          user.refreshSession(userSession.refreshToken, (err, session) => {
            // console.log('refresh session', err, session);
            const { idToken, refreshToken, accessToken } = session
            const jwt = idToken.jwtToken
            const expiration = idToken.payload.exp

            // Refresh session token
            const expires = (expiration - Math.floor(new Date().getTime() / 1000) - 3200)
            // console.log(`Token expires in ${expires} seconds in session`, session);

            // If stay sign in
            setTimeout(async () => {
              // console.log(`Renewing Token at time ${new Date()}`)
              dispatch('checkAuthentication')
            }, expires * 1000)

            commit(CONSTANTS.SET_JWT_TOKEN, { token: jwt, expires: expiration })

            return user
          })
        } else {
          // console.log('already authenticated');
          return getters.user
        }
      } else {
        // console.log('authenticating ...')
        // bypass cache for avoiding user to log in when has been deleted from AWS
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true
        })
        const userInfo = await Auth.currentUserInfo()

        // console.log(user)

        const session = await user.signInUserSession // await user.getSignInUserSession()
        const { idToken } = await user.signInUserSession // await user.getSignInUserSession()
        const jwt = await idToken.jwtToken

        // expiration token
        const expires = (idToken.payload.exp - Math.floor(new Date().getTime() / 1000)) - 2900
        // console.log(`Token expires in ${expires} seconds in session`, session);

        // If stay sign in
        setTimeout(async () => {
          // console.log(`Renewing Token at time ${new Date()}`)
          dispatch('checkAuthentication')
        }, expires * 1000)

        await commit(CONSTANTS.SET_USER, { user, userInfo })
        await commit(CONSTANTS.SET_JWT_TOKEN, { token: jwt, expires: idToken.payload.exp })
        await commit(CONSTANTS.SET_AUTHENTICATED, true)

        return user
      }
    } catch (error) {
      return error
    }
  },
  // set status CONFIRMED
  setUserStatus: ({ getters }, aws_user_id) => {
    return new Promise((resolve, reject) => {
      Api.setUserStatus(aws_user_id)
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
