<template>
    <div class="appFrame">
        <main class="moduleFrame moduleFrame--noBar moduleFrame--tagManagerRepositoryTags">
            <header class="white-header">
                <div class="dfjsb maxWidth800">
                    <div class="logo"></div>
                    <div class="heading">Tag Generator</div>
                </div>
            </header>

            <section class="moduleContent">
                <div v-if="campaign == null">{{placeholderMessage}}</div>
                <div v-if="campaign != null" class="maxWidth800">
                    <div class="wrapper-group mb2">
                        <label class="label-green">GENERATE TAGS FOR YOUR CAMPAIGN:</label>
                        <h1 class="title-tag">{{ campaign.name }}</h1>
                    </div>

                    <div v-if="currentUserIsAuthenticated" class="wrapper-group select mb2">
                        <label class="label-green">SELECT TAG VERSION</label>
                        <FormField forId="tools-adserver" type="select">
                            <select v-model="tagVersionOption.selected" id="tools-adserver" @change="onChangeTagVersion($event)">
                                <option disabled>Select Tag Version:</option>
                                <option v-for="(tag, index) in tag_versions" :key="index" :value="tag.id">{{tag.name}}</option>
                            </select>
                        </FormField>
                    </div>

                    <div v-if="currentUserIsAuthenticated" class="wrapper-group select mb2">
                        <label class="label-green">SELECT A DSP / AD SERVER</label>
                        <FormField forId="tools-adserver" type="select">
                            <select v-model="adserverOption.selected" id="tools-adserver" @change="onChangeAdserver($event)">
                                <option disabled>Select a DSP / Adserver:</option>
                                <option v-for="(adserver, index) in adservers" :key="index" :value="adserver.id">{{adserver.name}}</option>
                            </select>
                        </FormField>
                    </div>

                    <div class="wrapper-group mb3">
                        <label class="label-green">DOWNLOAD AND COPY TAGS</label>
                        <div class="dfjsc">
                            <button class='button mr1' :disabled="adserverOption.selected == 'Select a DSP / Adserver:'" @click.prevent="downloadTrafficTemplateCSV"><font-awesome-icon icon="download" class="i" />CSV TEMPLATE</button>
                            <button class='button mr1' :disabled="adserverOption.selected == 'Select a DSP / Adserver:'" @click.prevent="downloadTrafficTemplateTXT"><font-awesome-icon icon="download" class="i" />TXT TEMPLATE</button>
                            <button v-if="currentUserIsAuthenticated" class='button' :disabled="adserverOption.selected == 'Select a DSP / Adserver:'" @click.prevent="shareLink"><font-awesome-icon icon="copy" class="i" />COPY SHAREABLE LINK</button>
                        </div>
                    </div>

                    <div>
                        <div class="tileBoxBack" v-for="(placement, index) in placements" :key="index">
                            <label class="label-green">PLACEMENT NAME</label>
                            <p>{{ placement.name }}</p>
                            <label class="label-green">FORMAT</label>
                            <p>{{ placement.format }}</p>
                            <div v-if="adserverOption.selected != 'Select a DSP / Adserver:'" class="mb1 checkBoxBack">
                              <div class="checkbox-wrapper">
                                <input v-model="placement.overwrite_creative_size" id="ecreativesize" type="checkbox" @change="toggleShowCreativeSize($event,index)"/> Override Creative Size</div>
                              <FormField  v-if="showCreativeSize[index]" class="mt1" forId="safe-frame" type="select">
                                  <select v-model="creativeSizeOption[index].selected" id="tools-sizes" @change="onChangeCreativeSize($event, index)">
                                      <option disabled>Select a size:</option>
                                      <option v-for="(creativeSize, index) in creative_sizes" :key="index" :value="creativeSize.value">{{creativeSize.name}}</option>
                                  </select>
                              </FormField>
                            </div>

                            <FormField id="form-wrapper-courier" class="mb0">
                                <label class="label-green">PLACEMENT TAG</label>
                                <button v-if="placement.tag != ''" @click.prevent="copyTag(placement.tag)" class="button button-over-div"><font-awesome-icon icon="copy" class="i" />COPY TAG</button>
                                <!-- <button v-if="placement.tag != ''" @click.prevent="quickView(placement.tag)" class="button button-over-div button-over-div-second"><font-awesome-icon icon="eye" class="i" />PREVIEW</button> -->
                                <textarea-autosize placeholder="Please, select an ad server at the top of this page in order to get your tag." v-model="placement.tag" :rows="placement.tag != '' ? 30 : 10" style="pointer-events:none"></textarea-autosize>
                                <!-- Please, select an ad server at the top of this page in order to get your tag. -->
                            </FormField>

                            <div v-if="adserverOption.selected != 'Select a DSP / Adserver:'" class="mt1 checkbox-wrapper line-top">
                              <button id="toggle-button" ref="toggle-button" class="button no-focus"  @click.prevent="toggleShowQuickViewForm"> PREVIEW THIS TAG ON A TEST PAGE</button>
                            </div>
                            <!-- <div v-if="adserverOption.selected != 'Select a DSP / Adserver:'" class="mt1 checkbox-wrapper line-top"><input class="label-green" id="safe-frame" type="checkbox" @change="toggleShowQuickViewForm" /> PREVIEW THIS TAG ON A TEST PAGE</div>> -->
                            <div class="mt1 mb0" v-if="showQuickViewForm && adserverOption.selected != 'Select a DSP / Adserver:'">
                              <FormField id="form-wrapper-courier" class="mb1" type="select">
                                  <!-- <label class="label-green">QUICK VIEW PREVIEW</label> -->
                                  <select v-model="websiteOption.selected" id="tools-website" @change="onChangeWebsite($event)">
                                      <option disabled>Select a website:</option>
                                      <optgroup label="Simpli Mock Sites">
                                              <option value="creativepreview.html">Creative Preview</option>
                                      </optgroup>
                                      <optgroup label="Publisher sites (cached)">
                                              <option v-for="(website, index) in websites" :key="index" :value="website.value">{{website.name}}</option>
                                      </optgroup>
                                  </select>
                              </FormField>

                              <div class="mb1 checkbox-wrapper"><input v-model="campaign.use_safe_frame" id="safe-frame" type="checkbox" :disabled="websiteOption.selected == 'Select a website:'" @change="toggleShowSize" /> SAFE FRAME</div>
                              <FormField v-if="showSize"  class="mb1" forId="safe-frame" type="select">
                                  <select v-model="sizeOption.selected" id="tools-sizes" @change="onChangeSize($event)">
                                      <option disabled>Select a size:</option>
                                      <option v-for="(size, index) in sizes" :key="index" :value="size.value">{{size.name}}</option>
                                  </select>
                              </FormField>
                              <button v-if="placement.tag != ''" @click.prevent="quickView(placement.tag)" class="button" :disabled="websiteOption.selected == 'Select a website:'"><font-awesome-icon icon="eye" class="i" />OPEN PREVIEW PAGE</button>
                              <button class="button question-button" @click.prevent="showHowTo()"><font-awesome-icon icon="question" class="i" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModuleFooter>
                <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
                <WebLinks slot="right" />
            </ModuleFooter>
        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          :class="{ modalFullWidth: false }"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            @done="hideModal()"
           />
        </b-modal>
    </div>
</template>

<script>
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import WebLinks from '@/components/WebLinks.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import QuickViewModal from '@/views/modals/RepoQuickViewSteps.vue'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'
import Api from '@/helpers/Api.js'

export default {
  name: 'TagManagerRepositoryTags',
  components: {
    FormField, ModuleHeader, ModuleFooter, ActionsDropdown, WebLinks, QuickViewModal
  },
  data () {
    return {
      staticBModal: true,
      modalData: {
        currentComponent: null
      },
      tagVersionOption: {
        selected: null // default Version based on campaignSource
      },
      adserverOption: {
        selected: 'Select a DSP / Adserver:'
      },
      websiteOption: {
        selected: 'Select a website:'
      },
      sizeOption: {
        selected: 'Select a size:'
      },
      creativeSizeOption: [],
      checkCreativeSize: [],
      showCreativeSize: [],
      showSize: false,
      showQuickViewForm: false,
      placeholderMessage: 'NO CONTENT AVAILABLE.'
    }
  },
  methods: {
    ...mapActions('tags', [
      'fetch',
      'setTagsByAdServer',
      'setTagVersion',
      'setCreativeSize',
      'setCreativeSizeChecked',
      'setCreatives'
    ]),
    ...mapActions([
      'registerModalOpened',
      'registerModalClosed'
    ]),
    ...mapActions('quickview', [
      'setWebsite',
      'setSize'
    ]),
    ...mapActions('campaigns', {
      getCampaignById: 'fetchById'
    }),
    copyTag: function (tag) {
      const self = this
      Utils.copyToClipboard(tag, false)
    },
    showHowTo: function () {
      const self = this

      // this.isModalFullWidth = false;
      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = QuickViewModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()
    },
    quickView: function (tag) {
      const self = this
      const creativeTag = btoa(unescape(encodeURIComponent(tag)))
      const page = self.website_selected
      let size = ''
      const datetime = new Date().toISOString()
      // if safe frame is true them add size arguments
      if (self.showSize && self.sizeOption.selected != 'Select a size:') {
        const sizeItem = self.size_selected.split('x')
        const width = sizeItem[0]
        const height = sizeItem[1]
        size = '&width=' + width + '&height=' + height + '&fi=' + false
      } else {
        size = ''
      }
      window.open(`https://tools.padsquad.com/quick-view/${page}?preview=on&creativeTag=${creativeTag}${size}&date=${datetime}`, '_blank')
    },
    downloadTemplate: function (file) {
      const link = document.createElement('a')
      link.download = file.name
      link.target = '_blank'
      link.href = file.path
      link.click()
    },
    onChangeAdserver (event) {
      // this.$log.info(`Adserver selected: ${ event.target.value }`);
      this.setTagsByAdServer(this.adserverOption.selected)
      if (this.showCreativeSize) this.resetCreativeSize()
    },
    onChangeTagVersion (event) {
      // this.$log.info(this.tagVersionOption.selected)
      this.setTagVersion(this.tagVersionOption.selected)
      // update adserver for updating tags
      this.onChangeAdserver(event)
    },
    onChangeWebsite (event) {
      // this.$log.info(`Website selected: ${ event.target.value }`);
      // set website
      this.setWebsite(this.websiteOption.selected)
    },
    onChangeSize (event) {
      // this.$log.info(`Size selected: ${ event.target.value }`);
      // set website
      this.setSize(this.sizeOption.selected)
    },
    onChangeCreativeSize (event, index) {
      // this.$log.info(`Size selected: ${ event.target.value }`);
      this.setCreativeSize(this.creativeSizeOption, index)
      this.setTagsByAdServer(this.adserverOption.selected)
    },
    toggleShowSize: function () {
      this.showSize = !this.showSize
    },
    toggleShowCreativeSize: function (event, index) {
      this.checkCreativeSize[index] = !this.checkCreativeSize[index]
      this.showCreativeSize[index] = !this.showCreativeSize[index]

      if (this.checkCreativeSize[index] == true) {
        this.creativeSizeOption[index].selected = 'Select a size:'
        this.setCreativeSize(this.creativeSizeOption[index], index)
      } else {
        this.creativeSizeOption[index].selected = 'Select a size:'
        this.setCreativeSize(this.creativeSizeOption, index)
      }
    },
    resetCreativeSize: function () {
      // map placements and put this.checkCreativeSize to false
      if (document.querySelector('input#ecreativesize')?.checked) {
        for (let i = 0; i < this.creativeSizeOption.length; i++) {
          this.checkCreativeSize[i] = false
          this.showCreativeSize[i] = false
          this.creativeSizeOption[i].selected = 'Select a size:'
        }
        const list = document.querySelectorAll('input#ecreativesize')
        const list_array = [...list] // converts NodeList to Array
        list_array.forEach(function (item) {
          item.checked = false
        })
        // querySelector each input#ecreativesize and set to false
      }
    },
    toggleShowQuickViewForm: function () {
      this.showQuickViewForm = !this.showQuickViewForm
      const btn = document.getElementById('toggle-button')

      if (this.showQuickViewForm) {
        btn.innerHTML = 'HIDE PREVIEW TAG FORM'
      } else {
        btn.innerHTML = 'PREVIEW THIS TAG ON A TEST PAGE'
      }
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('M/DD/YY h:mma')

      return (formattedDate != 'Invalid date') ? formattedDate : ''
    },
    populatePlacements: function () {
    },
    downloadTrafficTemplateTXT () {
      const self = this

      const tableData = []

      self.placements.map(placement => {
        tableData.push({
          measurementId: placement.third_party_id,
          tagName: placement.name,
          tagFormat: placement.format,
          tagCode: placement.tag // '"' + placement.tag.replace(/"/g, '""') + '"'
        })
      })

      if (tableData.length > 0) {
        const formatedDataOutput = formatOutput(tableData)
        downloadTXT(self.campaign.name + '', formatedDataOutput)
      } else {
        alert('There was an issue downloading tags. Please try again. Alternatively you could copy the tags one by one.')
      }
    },
    downloadTrafficTemplateCSV () {
      const self = this

      const tableData = []

      self.placements.map((placement, idx) => {
        const _selectedSize = self.creativeSizeOption[idx].selected
        tableData.push({
          measurementId: '"' + placement.third_party_id + '"',
          tagName: placement.name,
          tagFormat: placement.format,
          selectedSize: _selectedSize,
          // tagCode: '"' + placement.tag.replace(/"/g, '""') + '"'
          tagCode: '"' + placement.tagcsv.replace(/"/g, '""') + '"'
        })
      })

      // if there are any placements
      if (tableData.length > 0) {
        // format the output before starting downloading this
        const formatedDataOutput = formatOutputCSV(tableData)

        // download
        downloadCSV(self.campaign.name + '', formatedDataOutput)
      } else {
        alert('There was an issue downloading tags. Please try again. Alternatively you could copy the tags one by one.')
      }
    },
    shareLink () {
      // in case the url does have params and deleted
      const url = new URL(window.location)

      const link = `${url.origin}${url.pathname}?tagversion=${this.tagVersionOption.selected}&adserver=${this.adserverOption.selected}`
      Utils.copyToClipboard(link, false)
    }
  },
  computed: {
    ...mapGetters('tags', [
      'campaign',
      'placements',
      'adservers',
      'templates',
      'getTagsByAdServer',
      'tag_versions',
      'creative_sizes',
      'creative_size_selected'
    ]),
    ...mapGetters([
      'appStatus'
    ]),
    ...mapGetters('quickview', [
      'websites',
      'sizes',
      'url',
      'website_selected',
      'size_selected'
    ]),
    ...mapGetters('placements', {
      getPlacementById: 'itemById'
    }),
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validItemsSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    currentUser: function () {
      let currentUser

      // var currentUser = JSON.parse(self.$store.state.cognito.authenticated.storage._sUser);
      // self.$store.getters['userInSession']
      // self.$log.info(self.$store.getters['userInSession'], localStorage._sUser)
      if (localStorage._sUser) {
        currentUser = JSON.parse(localStorage._sUser)
      } else {
        currentUser = undefined
      }

      return currentUser
    },
    currentUserIsAdmin: function () {
      const self = this

      return (self.currentUser.role_name == 'Admin')
    },
    currentUserIsAuthenticated: function () {
      return (this.currentUser !== undefined)
    }
  },
  async created () {
    const self = this
    const placementsId = String(this.$route.params.placements).split('-').map((id) => {
      // return {id: id};
      return id
    })

    // console.log('on load',this.creativeSizeOption, this.showCreativeSize, this.checkCreativeSize);
    placementsId.map((e, i) => {
      this.creativeSizeOption[i] = { selected: 'Select a size:' }
      this.showCreativeSize[i] = false
      this.checkCreativeSize[i] = false
      // console.log('on load',this.creativeSizeOption, this.showCreativeSize, this.checkCreativeSize);
    })

    const campaignId = this.$route.params.campaignId
    const campaign = await self.getCampaignById(campaignId)
    const creatives = placementsId.map(placementId => {
      const placement = campaign.placements.find(x => placementId === x.id.toString())
      if (!placement) return {}
      return {
        placementId,
        id: campaign.campaign_source === 'PadSquad' ? placement.creative_id : placement.external_creative_id
      }
    })

    this.setCreatives(creatives)

    const data = { campaign_id: campaignId, placements: placementsId }

    self.fetch(data)
      .catch(() => {
        self.placeholderMessage = 'It seems like you have reached an invalid url, please verify the url and try again.'
      })

    // get campaignsource
    this.campaignSource = campaign.campaign_source === 'Celtra Sync' ? 1 : 2
      
    // Let's find the query strings params, tagversion adserver
    const params = new URLSearchParams(window.location.search)
    if (params.has('tagversion')) {
      this.tagVersionOption.selected = parseInt(params.get('tagversion'))
    } else {
      this.tagVersionOption.selected = campaign.campaign_source === 'PadSquad' ? 3 : this.campaignSource
    }
    this.setTagVersion(this.tagVersionOption.selected)

    if (params.has('adserver')) {
      this.adserverOption.selected = parseInt(params.get('adserver'))
      this.setTagsByAdServer(this.adserverOption.selected)
    }
  },
  watch: {
    placements: function () {
      const self = this
      self.populatePlacements()
    }
  }
}

function formatOutput (data) {
  let formatedData = ''
  data.forEach(function (tagData) {
    formatedData += 'Tag Name: ' + tagData.tagName
    formatedData += '\n'
    formatedData += 'Measurement 3P ID: ' + tagData.measurementId
    formatedData += '\n'
    formatedData += 'Format: ' + tagData.tagFormat
    formatedData += '\n'
    formatedData += 'Tag Code: '
    formatedData += '\n'
    formatedData += '-------------------'
    formatedData += '\n'
    formatedData += tagData.tagCode
    formatedData += '\n'
    formatedData += '\n'
    formatedData += '-------------------'
    formatedData += '\n'
    formatedData += '\n'
  })

  return formatedData
}

function formatOutputCSV (data) {
  const self = this
  let formatedData = ''
  formatedData += 'Name, Measurement 3P ID, Size, Tag Content'
  formatedData += '\n'

  data.forEach(function (element, i) {
    const size = (element.selectedSize != 'Select a size:' ? element.selectedSize : (element.tagFormat === 'Hover' || element.tagFormat === 'Headliner' || element.tagFormat === 'Brandscape' || element.tagFormat === 'Swell' || element.tagFormat === 'Standard 320x50' || element.tagFormat === 'Reactive Hover' || element.tagFormat === 'Superscape' || element.tagFormat === 'FT Adhesion') ? '320x50' : '300x250')
    formatedData += element.tagName + ',' + element.measurementId + ',' + size + ',' + element.tagCode
    formatedData += '\n'
  })

  return formatedData
}

function getDataFromTable (table) {
  const data = []
  const checkedCheckboxes = document.querySelectorAll('.table-tags tbody input[type=checkbox]:checked')
  checkedCheckboxes.forEach(function (e) {
    const checkbox = e
    const checkboxTd = checkbox.parentElement
    const checkboxTr = checkboxTd.parentElement

    data.push({
      tagName: checkboxTr.children[1].innerHTML,
      tagFormat: checkboxTr.children[2].innerHTML,
      tagCreated: checkboxTr.children[3].innerHTML,
      tagCode: checkboxTr.children[4].firstChild.value
    })
  })
  return data
}

function downloadTXT (filename, text) {
  const today = new Date()

  filename += '-' + (today.getMonth() + 1) + today.getDate() + today.getFullYear() + '.txt'

  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
}

function downloadCSV (filename, text) {
  const today = new Date()

  filename += '-' + (today.getMonth() + 1) + today.getDate() + today.getFullYear() + '.csv'

  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
}
</script>

<style lang="scss">
  @import "@/scss/common.scss";

  #form-wrapper-courier textarea{
    font-family: courier;
  }

 .white-header{
     padding:$spacing-unit 0;
     margin-bottom: $spacing-unit*2;
     border-bottom: 1px solid $gray3;
     background-color: white;

     .logo{
         width:176px;
         height:45px;
         background: url(../../assets/simpli-logo.svg) center no-repeat;
     }

     .heading{
       font-size: 32px;
       font-weight: 600;
       text-align: right;
       color: $green7;
       background: transparent;
       margin:0;
       padding:0 !important;
       clear:initial;

       button{
         width:42px;
         height: 26px;
         border-radius: 4px;
         border:0px;
         font-size: 1.2rem;
         background: $green3;
         color:white;
         margin-left: $spacing-unit;
         cursor:pointer;
       }
    }
 }

 .maxWidth800{
    max-width: 800px;
    margin:0 auto;
 }

 .moduleFrame--tagManagerRepositoryTags{
     .heading{
       color: $green8 !important;
     }

     label{
         font-size: 1.1rem;
     }
     .tileBoxBack{
         text-align: left;
         margin-bottom: $spacing-unit*2;
     }

     .title-tag{
         font-size: 2.2rem;
     }

     .select{
         max-width: 330px;
         margin: 0 auto;
     }

     .button-over-div{
        position: absolute;
        z-index: 10;
        width: auto;
        right: 15px;
        top: 40px;
        display: inline-block;
        color: #646464;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-transform: uppercase;
        border-radius: 4px;
        padding: 1rem 1.5rem;
        cursor: pointer;
     }

     .button-over-div-second{
        right: 145px;
     }
 }

 .line-top{
   border-top: 1px solid $gray3;
   margin-top: $spacing-unit*1.5 !important;
   padding-top: $spacing-unit;
 }

 .checkbox-wrapper{
   display: flex;
   align-items: center;
 }

 #safe-frame {
   margin-right: $spacing-unit/2;
 }

 .question-button{
    width: 40px;
    height: 40px;
    position: initial;
    float: right;
    border-radius: 70px !important;
 }

 #toggle-button{

 }

 .no-focus, button{
   outline: 0px dotted !important;
 }

 #ecreativesize {
   margin-right: $spacing-unit/2;
 }

 .checkBoxBack {
    border: 1px solid #dee2e6;
    padding: 2rem;
    box-sizing: border-box;
 }

</style>
