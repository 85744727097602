<script setup>
import { ref, computed, onMounted } from 'vue'
import CreativePreviewDrawerInfo from './CreativePreviewDrawerInfo.vue'
import CreativePreviewEventConsole from './CreativePreviewEventConsole.vue'
import CreativePreviewContextSimulator from './CreativePreviewContextSimulator.vue'

const props = defineProps({
  previewSettings: {
    type: Object,
    required: true
  },
  creativeData: {
    type: Object,
    required: true
  },
  isTagReady: {
    type: Boolean,
    required: true
  },
  mockSiteIframeKey: {
    type: Number,
    required: true
  },
  isDragging: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits([
  'modify-url-params',
  'refresh-creative',
  'update-dragging'
])

const selectedTab = ref('info')
const drawerWidth = ref(0)
const mousePos = ref({ start: null, current: null })

const tabs = computed(() => {
  const tabs = [
    {
      name: 'info',
      icon: 'info-circle'
    },
    {
      name: 'eventConsole',
      icon: 'terminal'
    }
  ]
  if (props.previewSettings.hasDCO) {
    tabs.push({
      name: 'contextSimulator',
      icon: 'sliders-h'
    })
  }
  return tabs
})

const dragAmount = computed(() => {
  if (!mousePos.value.start || !mousePos.value.current) return 0
  return Math.abs(mousePos.value.start - mousePos.value.current)
})

const selectTab = (tab) => {
  selectedTab.value = tab.name
}

const onResizerMouseDown = (e) => {
  e.preventDefault()
  emit('update-dragging', true)
  document.addEventListener('mousemove', onDrag)
  document.addEventListener('mouseup', onResizerMouseUp)
  document.body.style.cursor = 'ew-resize'
  mousePos.value.start = e.clientX
}

const onResizerMouseUp = (e) => {
  if (!props.isDragging) {
    e.stopPropagation()
    return
  }
  document.removeEventListener('mousemove', onDrag)
  document.removeEventListener('mouseup', onResizerMouseUp)
  document.body.style.cursor = 'auto'
  emit('update-dragging', false)
}

const onDrag = (e) => {
  if (!props.isDragging) return
  mousePos.value.current = e.clientX
  if (dragAmount.value < 5) return
  drawerWidth.value = document.body.clientWidth - mousePos.value.current
}

onMounted(() => {
  // init drawer width
  drawerWidth.value = document.body.clientWidth * (props.previewSettings.mobileScreen ? 0.7 : 0.2)
})
</script>

<template>
  <div
    class="drawer h-100 d-flex flex-column"
    :style="`
      width: ${drawerWidth}px;
      transition: width 0;
      position: relative;
    `"
  >
    <div
      class="drawer-resizer"
      :class="{'drawer-resizer__resizing': isDragging}"
      @mousedown="onResizerMouseDown"
    />
    <!-- icons -->
    <div class="drawer-icons-container d-flex">
      <button
        v-for="(tab, idx) in tabs"
        :key="idx"
        class="icon-button d-flex justify-content-center align-items-center flex-grow-1"
        @click="selectTab(tab)"
        :style="`
          background-color: ${tab.name === selectedTab ? '#6D6D6D' : '#575757'};
          border-bottom: ${tab.name !== selectedTab ? '1px solid #464646' : 'none'};
          border-left: ${(tab.name !== selectedTab && idx !== 0 && tabs[idx-1].name === selectedTab) ? '1px solid #464646' : 'none'};
          border-right: ${(tab.name !== selectedTab && idx !== (tabs.length - 1)) ? '1px solid #464646' : 'none'};
        `"
      >
        <font-awesome-icon
          :icon="tab.icon"
          style="height: 16px; width: 16px;"
        />
      </button>
    </div>
    <div class="flex-grow-1">
      <div
        v-show="selectedTab === 'info'"
        class="h-100"
      >
        <CreativePreviewDrawerInfo
          :creative-data="creativeData"
          @modify-url-params="emit('modify-url-params', ...arguments)"
          @refresh-creative="emit('refresh-creative')"
        />
      </div>
      <div
        v-show="selectedTab === 'eventConsole'"
        class="h-100"
      >
        <CreativePreviewEventConsole
          :creative-data="creativeData"
          :is-tag-ready="isTagReady"
          :mock-site-iframe-key="mockSiteIframeKey"
          @modify-url-params="emit('modify-url-params', ...arguments)"
          @refresh-creative="emit('refresh-creative')"
        />
      </div>
      <div
        v-show="selectedTab === 'contextSimulator'"
        class="h-100"
      >
        <CreativePreviewContextSimulator
          v-if="previewSettings.hasDCO"
          :creative-data="creativeData"
          @modify-url-params="emit('modify-url-params', ...arguments)"
          @refresh-creative="emit('refresh-creative')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.drawer {
  background-color: #6D6D6D;
  right: 0px;
  border-left: 1px solid #464646;
}
.drawer-icons-container {
  height: 5%;
}
.icon-button {
  cursor: pointer;
  border: none;
  color: #CACACA;
}
.drawer-resizer {
  cursor: ew-resize;
  width: 5px;
  height: 100%;
  position: absolute;
  float: left;
  z-index: 10;

  &__resizing {
    background-color: #888888;
  }
}
</style>
