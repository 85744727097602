<template>
    <router-view/>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LocationFeederRepository',
  methods: {
    ...mapActions('locationFeeders', [
      'fetchFeederTypes',
      'fetchFeederInputs',
      'fetchFeederContentTypes'
    ])
  },
  created () {
    // load feeder types, inputs, and content typeslo
    this.fetchFeederTypes()
    this.fetchFeederInputs()
    this.fetchFeederContentTypes()
  }
}
</script>
