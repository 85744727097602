<template>
  <section v-if="itemId != null" class="sModal">

    <Preloader :inModal="true"></Preloader>

    <header class="sModal__header">Celtra Sync</header>

    <form @submit.prevent="formSubmit">

      <div class="sModal__body" v-if="currentSection=='details'">
        <section>
          <div slot="searchField">
            <FormField label="Search Placements" forId="filter-search" type="search" class="moduleFilter__filter">
              <input v-model="filterModuleData.search" id="filter-search" type="text"/>
            </FormField>
          </div>

            <div>
              <div class="contentTableWrapper" :class="{'contextualMenuExpanded': contextualMenuExpanded}">
                <table class="contentTable">
                  <thead>
                    <tr>
                      <th class="contentTable__dimension"><input type="checkbox" @change="selectAllToggle($event)"/>Placement Name</th>
                      <th>Celtra Placement Id</th>
                      <th>Format</th>
                      <th>3P ID</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in paginatedItems" :key="item.placement_id">
                      <td class="contentTable__dimension"><input type="checkbox" :name="'placement_' + item.name" v-model="itemsSelected[item.placement_id]" /><span :class="getStatus(item.placement_id, item.name)" v-text="getStatus(item.placement_id, item.name)"></span> {{item.name}}</td>
                      <td column-name="Celtra Placement Id"><a href="#" v-on:click.prevent="filterModuleData.filterValues.placement_id = item.placement_id">{{ item.placement_id }}</a></td>
                      <td column-name="Format"><a href="#" v-on:click.prevent="filterModuleData.filterValues.padsquad_product = item.padsquad_product">{{ item.padsquad_product }}</a></td>
                      <td column-name="3P ID"><a href="#" v-on:click.prevent="filterModuleData.filterValues.third_party_id = item.third_party_id">{{ item.third_party_id }}</a></td>
                    </tr>

                    <tr v-if="paginatedItems.length < 1">
                      <td colspan="9">No placements matches your criteria</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- <ContentTableTools>
                <div slot="right" class="contentTableFrame__total">Total Placements: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools> -->

            </div>

          </section>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" />Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <button v-if="itemId != null" class="saveButton" :disabled="!validItemsSelected.length">
            <font-awesome-icon icon="check" class="i" />Fetch & Update Celtra Placements
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'
import Preloader from '@/components/Preloader.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CeltraSyncProfile',
  components: {
    FormField, Preloader, ModuleFilter, ContentTableTools
  },
  props: {
    itemId: null,
    campaign: null
  },
  data () {
    return {
      loading: true,
      listMatched: [],
      contextualMenuExpanded: false,
      filterModuleData: {
        search: '',
        filterValues: {
          productType: 'All Products',
          tagSource: 'All Tag Sources',
          campaignSource: 'All Campaign Sources'
        },
        filterDefaults: {
          productType: 'All Products',
          tagSource: 'All Tag Sources',
          campaignSource: 'All Campaign Sources'
        },
        filterOptions: {
          productType: [],
          tagSource: [],
          campaignSource: []
        }
      },
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Format Type',
            value: 'formatType'
          },
          {
            label: 'Created Date',
            value: 'placementCreatedDate'
          }
        ]
      },
      itemsSelected: {},
      pagination: {
        perPage: 200,
        currentPage: 1
      },
      sections: [
        'details'
      ],
      currentSection: 'details',
      synced: false,
      selectAll: false

    }
  },
  methods: {
    ...mapActions('placements', [
      'fetch',
      'fetchCeltra',
      'syncCeltraToList',
      'sync',
      'fetchWithOutTrackers',
      'setPlacements'
    ]),
    ...mapActions('campaigns', [
      'replace'
    ]),
    ...mapActions([
      'registerTask',
      'completeTask'
    ]),
    syncPlacementDetails: function () {
      const self = this
      const celtraId = self.itemId

      // self.$log.info('Sync Campaign Details ID: ' + celtraId, this.validItemsSelectedToObjects);

      if (this.validItemsSelectedToObjects.length == 0) return

      // CHECK STATUS TO SEE WE NEED TO CREATE OR UPDATE PLACEMENT
      const itemsWithStatus = this.validItemsSelectedToObjects.map(item => {
        item.status = this.getStatus(item.placement_id, item.name)
        item.verification_third_party_id = (!item.verification_third_party_id) ? '' : item.verification_third_party_id

        // these elements are required by the schema
        item.placement_source = 'Celtra Sync'
        item.main_kpi = 0 // there is no main_kpi from Celtra
        item.external_campaign_id = '' // there is no external_campaign_id from Celtra
        item.campaign_name = '' // there is no campaign_name from Celtra
        item.append_script = ''
        item.start_date = ''
        item.end_date = ''
        item.goal = 0
        item.publisher_name = ''
        item.trackers = []

        return item
      })

      // self.$log.info('With Status', itemsWithStatus);

      // set the celtra placements to list db placements
      // self.syncCeltraToList({"placements":this.listCeltra, "id":celtraId })
      self.syncCeltraToList({ placements: itemsWithStatus, id: celtraId, campaignId: parseInt(self.$route.params.id) })
        .then(response => {
          // idlc
          self.loading = true
          setTimeout(() => {
            self.fetchWithOutTrackers(self.$route.params.id).then(
              placements => {
                console.log('placements', placements)
                console.log('self.campaign', self.campaign)
                // self.campaign.placements =[...placements];
                self.setPlacements(placements)
              })
            self.$log.info('Sync response ', response)
            self.synced = true
            self.loading = false
          }, 3000)
        }
        )
        .then(response => self.$emit('done', response))
        .catch(error => {
          // notify the user
          alert('There was a problem syncing this placement. Please try again.')
        })
    },
    formSubmit: function (e) {
      const self = this

      self.syncPlacementDetails()
    },
    saveData: function () {

    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('done')
    },
    getStatus: function (id, name) {
      const item = _.find(this.listMatched, { placement_id: id })// {id:id}
      const itemName = _.find(this.listMatched, { name })// delete this once we have placement_id or celtra_placement_id in data base
      if (!item && !itemName) return ''

      return (item != null) ? item.status : itemName.status
    },
    matchItems: function () {
      this.listMatched = []
      // match items to see if are new or updated
      if (this.list.length < 1) {
        this.listCeltra.map((item, index) => {
          this.listMatched.push({ placement_id: item.placement_id, name: item.name, status: 'new' })
        })
      } else {
        this.listCeltra.map((item, index) => {
          const itemOld = this.list.find(oldItem => oldItem.placement_id == item.placement_id)
          const itemOldName = this.list.find(oldItem => oldItem.name == item.name)// delete this once we have placement_id or celtra_placement_id in data base
          if (itemOld || itemOldName) {
            // set id from DB, from Celtra there's no id
            item.id = (itemOld != null) ? itemOld.id : itemOldName.id

            if (_.isEqual(item, itemOld)) {
              this.listMatched.push({ placement_id: item.placement_id, status: '' })
            } else {
              this.listMatched.push({ placement_id: item.placement_id, status: 'update' })
            }
          } else {
            this.listMatched.push({ placement_id: item.placement_id, status: 'new' })
          }
        })
      }
    },
    populateFilters: function () {
      const self = this

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.listCeltra, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, param => {
        filterOptions[param].unshift(self.filterModuleData.filterDefaults[param])
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    },
    populateForm: function () {
      this.$log.info('Celtra Sync Item id: ', this.itemId)
      this.fetchCeltra(this.itemId)
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.paginatedItems.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.itemsSelected, String(item.placement_id), true)
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.itemsSelected, String(item.placement_id), false)
        })
      }
    }
  },
  created () {
    const self = this
    // populate form
    self.populateForm()
  },
  beforeDestroy () {
    const self = this
  },
  computed: {
    ...mapGetters([
      'creativeFormats'
    ]),
    ...mapGetters('placements', [
      'list',
      'listCeltra',
      'itemById'
    ]),
    isDataModified: function () {
      const self = this

      return !_.isEqual(self.placement, self.itemById(self.itemId))
    },
    /**
       * Returns an array with the list of id for selected creatives.
       */
    validItemsSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    validItemsSelectedToObjects: function () {
      const data = []
      this.validItemsSelected.map(item => {
        const _item = _.find(this.listCeltra, { placement_id: String(item) })
        _item.external_placement_id = _item.placement_id

        data.push(_item)
      })

      return data
    },
    filteredItems: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(self.listCeltra, self.filterModuleData.search)

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(self.filterModuleData.filterValues, (filter, key) => {
        // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
        const filterOption = _.get(self.filterModuleData.filterOptions, '[' + key + '][0]', false)

        // if filterOption is not false and the filter option is not the first option
        return filterOption && filter != filterOption
      })

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(filteredItems, [self.tableDataOptions.orderBy], [self.tableDataOptions.order])

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(self.filteredItems, self.pagination.currentPage, self.pagination.perPage)
    }
  },
  watch: {
    /* when the section is changed */
    currentSection: function (value) {
      const self = this
    },
    listCeltra: function () {
      const self = this
      self.matchItems()
      self.populateFilters()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/common.scss";

  .new{
    color:white;
    background-color:$green1;
    margin-right: $spacing-unit/2;
    padding: .4rem $spacing-unit/2;
    border-radius: $roundness;
    text-transform: uppercase;
    font-size:0.8rem;
  }

  .update{
    color:white;
    background-color: $green9;
    margin-right: $spacing-unit/2;
    padding: .4rem $spacing-unit/2;
    border-radius: $roundness;
    text-transform: uppercase;
    font-size:0.8rem;
  }

</style>
