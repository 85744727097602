<template>

  <div id="app">

    <router-view/>

    <preloader></preloader>

  </div>

</template>

<script>
import Preloader from '@/components/Preloader.vue'
import ApiUser from '@/helpers/ApiUser.js'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'app',
  components: {
    Preloader
  },
  methods: {
    ...mapActions([
      'signOut'
    ])
  },
  created () {
    this.$route
    this.$log.info(process.env.VUE_APP_ENV)
  },
  watch: {
    '$route' (to, from) {
      const self = this

      ApiUser.gethealthcheck() // pass true for dummy data
        .then(data => {
          if (localStorage.current_region == 'us-west-2' && data.region != 'us-west-2') {
            console.log('should logout to est')
            this.signOut()
              .then(response => {
                localStorage._sUser = null
                localStorage.reload_dr = 'yes'
                self.$router.push('/sign-in')
              })
              .catch(error => {

              }
              )
          } else if (localStorage.current_region == 'us-east-1' && data.region != 'us-east-1') {
            console.log('should logout to west')
            this.signOut()
              .then(response => {
                localStorage._sUser = null
                localStorage.reload_dr = 'yes'
                self.$router.push('/sign-in')
              })
              .catch(error => {

              }
              )
          }

          /*       if (data.region == "west") {
                    localStorage.current_region ='us-west-2'
                    this.setRegionAuth('us-west-2');
                    this.$log.info(`us-west-2`);

                }else{
                    this.setRegionAuth('us-east-1');
                    localStorage.current_region ='us-east-1'
                    this.$log.info(`us-west-2`);
                } */
        })
        .catch(error => {
          console.log('error Route watch health', error)
        })
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/common.scss";
</style>
