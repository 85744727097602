<template>
    <div class="weblinks">
        <ul>
            <li v-for="(link, index) in links" :key="index" class="links__link">
                <a href="#" @click.prevent="link.clickAction">{{link.text}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'WebLinks',
  data () {
    return {
      links: [
        {
          text: 'Legal',
          clickAction: function () {
            window.open('http://padsquad.com/privacy-policy/', '_blank')
          }
        },
        {
          text: 'About PadSquad',
          clickAction: function () {
            window.open('http://padsquad.com/', '_blank')
          }
        },
        {
          text: 'Support',
          clickAction: function () {
            window.open('mailto:simpli@padsquad.com?subject=Support', '_self')
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "../scss/common.scss";
.weblinks {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

   li {
       display: inline-block;
       padding: 0;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }
  }

  @include media-breakpoint-up(md) {
    li {
      padding: 0 $spacing-unit * 2;
    }
  }
}
</style>
