<template>

    <div class="appFrame">

        <Navbar :currentModulePath="'analytics'" :currentModuleName="'Analytics'" />

        <main class="moduleFrame moduleFrame--tagManagerRepository">

          <PencilBar />

          <ModuleHeader>
            <ul slot="tabs">
              <li selected><router-link to="/analytics/campaigns">Campaigns</router-link></li>
              <li><router-link to="/analytics/saved-reports">Saved Reports</router-link></li>
              <li><router-link to="/analytics/dataloading/">Data Loading</router-link></li>
              <!-- <li><router-link to="/analytics/productPerformance">Product Performance</router-link></li> -->
              <!-- <li><router-link to="/tag-manager/campaigns/inactive">Inactive Campaigns</router-link></li> -->
            </ul>

            <div slot="title">Campaigns</div>

            <div slot="actions">
              <button class='moduleHeader__createButton' @click.prevent="openGenerateCSV"><font-awesome-icon icon="table" class="i" />Generate Report</button>
              <!-- <button class='moduleHeader__createButton mr-3' @click.prevent="openModalSettings(campaign.id, null, '3PData')"><font-awesome-icon icon="table" class="i" /> Manual Data Upload</button> -->
            </div>
          </ModuleHeader>

          <ModuleFilter>
            <div slot="searchField">
              <FormField label="Search Campaigns" forId="filter-search" type="search" class="moduleFilter__filter">
                <input v-model="filterModuleData.search" id="filter-search" type="text" required />
              </FormField>
            </div>

            <div slot="collapsableFilters">

              <FormField label="Advertisers" forId="filter-advertiser" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.advertiser" id="filter-advertiser">
                  <option v-for="(advertiser) in filterModuleData.filterOptions.advertiser" :key="advertiser">{{advertiser}}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.advertiser !== filterModuleData.filterDefaults.advertiser"
                  v-on:click="filterModuleData.filterValues.advertiser = filterModuleData.filterDefaults.advertiser">[X] CLEAR
                </div>
              </FormField>

              <FormField label="Deal Type" forId="filter-deal_type" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.deal_type" id="filter-deal_type">
                  <option v-for="deal_type in filterModuleData.filterOptions.deal_type" :key="deal_type">{{deal_type}}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.deal_type !== filterModuleData.filterDefaults.deal_type"
                  v-on:click="filterModuleData.filterValues.deal_type = filterModuleData.filterDefaults.deal_type">[X] CLEAR
                </div>
              </FormField>

              <FormField label="Main KPI" forId="filter-kpi" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.kpi" id="filter-kpi">
                  <option v-for="kpi in filterModuleData.filterOptions.kpi" :key="kpi">{{kpi}}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.kpi !== filterModuleData.filterDefaults.kpi"
                  v-on:click="filterModuleData.filterValues.kpi = filterModuleData.filterDefaults.kpi">[X] CLEAR
                </div>
              </FormField>
            </div>
          </ModuleFilter>

          <section class="moduleContent">

            <div class="contentTableFrame">

              <ContentTableTools>
                <FormField slot="left" forId="tools-orderby" type="select" tagLeft="Order By:">
                  <select v-model="tableDataOptions.orderBy" id="tools-orderby">
                    <option v-for="(orderByOption, index) in tableDataOptions.options" :key="index" :value="orderByOption.value">{{orderByOption.label}}</option>
                  </select>
                </FormField>

                <FormField slot="left" forId="tools-order" type="select" tagLeft="Order:">
                  <select v-model="tableDataOptions.order" id="tools-order">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </FormField>

                <div slot="right" class="contentTableFrame__total">Total Campaigns: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

              <div class="contentTableWrapper" :class="{'contextualMenuExpanded': contextualMenuExpanded}">
                <table class="contentTable">
                  <thead>
                    <tr>
                      <th class="contentTable__dimension"><input type="checkbox" @change="selectAllToggle($event)"/>Campaign Name</th>
                      <th>Campaign ID</th>
                      <th>Campaign Source</th>
                      <th>Status</th>
                      <th>Last Update</th>
                      <!-- <th>Placements</th> -->
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in paginatedItems" :key="item.id">
                      <td class="contentTable__dimension"><input type="checkbox" :name="'campaign_' + item.name" v-model="itemsSelected[item.id]" /><a class="overflow-ellipsis" :title="item.name" href="#" @click.prevent="navigateToCampaignPlacements(item)">{{item.name}}</a></td>
                      <td column-name="Campaign ID"><a href="#" @click.prevent="filterModuleData.filterValues.id = item.id">{{ item.id }}</a></td>
                      <td column-name="Campaign Source"><a href="#" @click.prevent="filterModuleData.filterValues.campaign_source = item.campaign_source">{{ item.campaign_source }}</a></td>
                      <td column-name="Status"><a href="#" @click.prevent="filterModuleData.filterValues.type = item.type">{{ (item.active == true) ? 'Active' : 'Inactive'}}</a></td>
                      <td column-name="Last Update"><a href="#" @click.prevent="filterModuleData.filterValues.format = item.format">{{getFormattedDate(item.touched_on)}}</a></td>
                      <!-- <td column-name="Creative Tags"><a href="#" @click.prevent="''">{{ item.tags || 0 }}</a></td> -->
                      <!-- <td column-name="Placements"><a href="#" @click.prevent="''">{{ item.placements.length || 0 }}</a></td> -->
                      <td class="contentTable__actions">
                        <button @click="openCampaignSettings(item.id, true)"><font-awesome-icon icon="chart-bar" class="i" /></button>
                        <ActionsDropdown :dropup="paginatedItems.length>10&&!(index<(paginatedItems.length-2))" :itemId="item.id" :actions="tableActions" @show="contextualMenuExpanded=true"  @hide="contextualMenuExpanded=false"/>
                      </td>
                    </tr>

                    <tr v-if="paginatedItems.length < 1">
                      <td colspan="9">No campaign matches your criteria</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ContentTableTools>
                <div slot="right" class="contentTableFrame__total">Total Campaigns: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

            </div>

          </section>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            :campaignId="modalData.campaignId"
            :campaignName="modalData.campaignName"
            :advertiserId="modalData.advertiserId"
            :advertiserName="modalData.advertiserName"
            @done="hideModal()"
           />
        </b-modal>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import ProfileModal from '@/views/modals/RepoCampaignProfile.vue'
import WebLinks from '@/components/WebLinks.vue'
import GenerateCSV from '@/views/modals/GenerateCSV.vue'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'

export default {
  name: 'AnalyticsRepositoryCampaigns',
  components: {
    Navbar, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks, ProfileModal, GenerateCSV
  },
  data () {
    return {
      staticBModal: true,
      mobileFiltersExpanded: false,
      filterModuleData: {
        search: '',
        filterValues: {
          advertiser: 'All Advertisers',
          deal_type: 'All Deal types',
          kpi: 'All KPI',
          status: 'All Status'
        },
        filterDefaults: {
          advertiser: 'All Advertisers',
          deal_type: 'All Deal types',
          kpi: 'All KPI',
          status: 'All Status'
        },
        filterOptions: {
          advertiser: [],
          deal_type: [],
          kpi: [],
          status: []
        }
      },
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          }
        ]
      },
      breadcrumsData: [
        {
          text: 'Analytics',
          href: '/analytics'
        },
        {
          text: 'Camapigns',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        advertiserId: null,
        advertiserName: null,
        campaignId: null,
        campaignName: null
      },
      itemsSelected: {},
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'chart-line',
          text: 'Airtory Analytics',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openAnalytics
        },
        {
          ico: 'cog',
          text: 'Campaign Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openCampaignSettings
        },
        {
          ico: 'trash-alt',
          text: 'Delete Campaign',
          clickAction: this.deleteCampaign
        }
      ],
      contextualMenuExpanded: false,
      selectAll: false
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'fetch',
      'delete'
    ]),
    ...mapActions([
      'registerModalOpened',
      'registerModalClosed'
    ]),
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':
          break

        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    navigateToCampaignPlacements: function (itemData) {
      // var self = this;

      // self.$router.push({
      //   name: 'Performance Monitoring',
      //   params:{
      //     id:itemData.id,
      //     campaign: itemData
      //   }
      // });
      this.openCampaignSettings(itemData.id, true)
    },
    openCampaignSettings: function (itemId, showCSV = false) {
      const self = this
      // set the modal item id to the userId we want to update
      self.modalData.itemId = itemId

      // //for advertiser autocomplete initial values, because are not loaded on mounted in RepoCampaign
      const _campaign = this.itemById(itemId)
      const _advertiser = this.advertiserById(_campaign.advertiser_id)

      self.modalData.advertiserId = _advertiser.id
      self.modalData.advertiserName = _advertiser.name

      // register that we have just opened a modal
      self.registerModalOpened()

      if (showCSV) {
        // set the content for the modal to the user profile component
        self.modalData.campaignId = itemId
        // self.modalData.campaignName = this.campaign.name;
        self.modalData.currentComponent = GenerateCSV
      } else {
        self.modalData.currentComponent = ProfileModal
      }

      // show modal
      self.$refs.moduleModal.show()
    },
    deleteCampaign: function (itemId) {
      const self = this

      // fet user from users.
      const campaign = _.find(self.listAirtory, { id: itemId })

      // if the user we are trying to delete is not the logged in user...
      if (confirm(`Are you sure you want to delete this campaign: ${campaign.name}?`)) {
        // delete creative
        self.delete(campaign.id)
          .catch(error => {
            self.$log.error(error)
            alert(`There was an issue deleting this campaign. Please try again. Error Code:${error}`)
          })
      }
    },
    openAnalytics (id) {
      const p = this.listAirtory.find(item => item.id === id)
      if (p) window.open(`https://studio.airtory.com/#/analytics/campaign/${p.external_campaign_id}`, '_blank')
    },
    openNewCampaign: function () {
      const self = this

      // set the modal item id to null to create new user
      self.modalData.itemId = null

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = ProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    openGenerateCSV: function () {
      const self = this

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      // self.modalData.campaignId = this.$route.params.id;
      self.modalData.campaignId = null
      // self.modalData.campaignName = this.campaign.name;
      self.modalData.currentComponent = GenerateCSV
      this.isModalFullWidth = false

      // show modal
      self.$refs.moduleModal.show()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()

      self.modalData.advertiserId = null
      self.modalData.advertiserName = null
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('M/DD/YY h:mma')

      return (formattedDate != 'Invalid date') ? formattedDate : ''
    },
    populateFilters: function () {
      const self = this

      // set status based on active
      self.listAirtory.map(item => {
        item.status = (item.active === 1) ? 'Active' : 'Inactive'
        return item
      })

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.listAirtory, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, param => {
        filterOptions[param].unshift(self.filterModuleData.filterDefaults[param])
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.paginatedItems.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.itemsSelected, String(item.id), true)
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.itemsSelected, String(item.id), false)
        })
      }
    }
  },
  computed: {
    ...mapGetters('campaigns', [
      'listAirtory',
      'itemById'
    ]),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById'
    }),
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validItemsSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    filteredItems: function () {
      const self = this
      const filteredList = self.listAirtory.filter(item => item.active === 1)

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(filteredList, self.filterModuleData.search)

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(self.filterModuleData.filterValues, (filter, key) => {
        // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
        const filterOption = _.get(self.filterModuleData.filterOptions, '[' + key + '][0]', false)

        // if filterOption is not false and the filter option is not the first option
        return filterOption && filter != filterOption
      })

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(filteredItems, [self.tableDataOptions.orderBy], [self.tableDataOptions.order])

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(self.filteredItems, self.pagination.currentPage, self.pagination.perPage)
    }
  },
  created () {
    const self = this

    self.fetch()
  },
  watch: {
    listAirtory: function () {
      const self = this
      self.populateFilters()
    }
  }
}
</script>
