<template>
  <div class="appFrame">
    <Navbar
      :current-module-path="'location-feeder'"
      :current-module-name="'Location Feeder Feeds'"
    />

    <main class="moduleFrame moduleFrame--creativeRepository">
      <PencilBar />

      <ModuleHeader>
        <ul slot="tabs">
          <li selected>
            <router-link to="/tools/location-feeder/feeds"
              >Location Feeder</router-link
            >
          </li>
          <li>
            <router-link to="/tools/xandr-placements"
              >Xandr Placements</router-link
            >
          </li>
        </ul>

        <div slot="title">Feeds</div>

        <div slot="actions">
          <button
            class="moduleHeader__createButton"
            @click.prevent="openNewFeed"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            />
            New Feed
          </button>
        </div>
      </ModuleHeader>

      <ModuleFilter>
        <div slot="searchField">
          <FormField
            label="Search Feeds"
            for-id="filter-search"
            type="search"
          >
            <input
              id="filter-search"
              v-model="filterModuleData.search"
              type="text"
              required
            />
          </FormField>
        </div>
      </ModuleFilter>

      <section class="moduleContent">
        <div class="contentTableFrame">
          <ContentTableTools>
            <!-- Order by Order options -->
            <FormField
              slot="left"
              for-id="tools-orderby"
              type="select"
              tag-left="Order By:"
            >
              <select
                id="tools-orderby"
                v-model="tableDataOptions.orderBy"
              >
                <option
                  v-for="(orderByOption, index) in tableDataOptions.options"
                  :key="index"
                  :value="orderByOption.value"
                >
                  {{ orderByOption.label }}
                </option>
              </select>
            </FormField>

            <FormField
              slot="left"
              for-id="tools-order"
              type="select"
              tag-left="Order:"
            >
              <select
                id="tools-order"
                v-model="tableDataOptions.order"
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </FormField>

            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Feeds: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>

          <div class="contentTableWrapper">
            <table class="contentTable">
              <thead>
                <tr>
                  <th class="contentTable__dimension">Feed Name</th>
                  <th>Feed ID</th>
                  <th>Feed Type</th>
                  <th>Last Update</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in paginatedItems"
                  :key="index"
                >
                  <td class="contentTable__dimension">
                    <input
                      v-model="feedsSelected[item.id]"
                      type="checkbox"
                      :name="'feed_' + item.feed_name"
                    /><a
                      class="overflow-ellipsis"
                      :title="item.feed_name"
                      href="#"
                      @click.prevent="navigateToFeedBuilder(item)"
                      >{{ item.feed_name }}</a
                    >
                  </td>
                  <td column-name="Feed ID">
                    <span
                      @click.prevent="
                        filterModuleData.filterValues.id = item.id
                      "
                      >{{ item.id }}</span
                    >
                  </td>
                  <td column-name="Feed Type">
                    <span
                      @click.prevent="
                        filterModuleData.filterValues.feeder_type_name =
                          item.feeder_type_name
                      "
                      >{{ item.feeder_type_name }}</span
                    >
                  </td>
                  <td column-name="Last Update">
                    <span
                      @click.prevent="
                        filterModuleData.filterValues.update_at = item.update_at
                      "
                      >{{ getFormattedDate(item.update_at) }}</span
                    >
                  </td>
                  <td class="contentTable__actions">
                    <button
                      title="Open Endpoint"
                      @click.prevent="openEndpointModal(item)"
                    >
                      <font-awesome-icon
                        icon="link"
                        class="i"
                      />
                    </button>
                    <button
                      title="Edit Feed"
                      @click.prevent="openEditFeed(item.id)"
                    >
                      <font-awesome-icon
                        icon="edit"
                        class="i"
                      />
                    </button>
                    <button
                      title="Delete Feed"
                      @click.prevent="deleteFeed(item.id)"
                    >
                      <font-awesome-icon
                        icon="trash-alt"
                        class="i"
                      />
                    </button>
                  </td>
                </tr>
                <tr v-if="paginatedItems.length < 1">
                  <td colspan="9">No feeds matches your criteria</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ContentTableTools>
            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Feeds: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>
        </div>
      </section>

      <b-modal
        ref="endpointModal"
        class="sModal"
        centered
        no-close
        hide-footer
        hide-header
      >
        <header
          v-if="currentItem"
          class="sModal__header"
        >
          {{ currentItem && currentItem.feed_name + ' - LOCATION' }}
        </header>
        <nav class="sModal__tabs">
          <ul>
            <li>
              <button
                :class="{ active: currentSection === 'dynamic' }"
                @click.prevent="currentSection = 'dynamic'"
              >
                NEARBY
              </button>
            </li>
          </ul>
        </nav>
        <div
          v-if="currentItem"
          class="sModal__body"
        >
          <div v-if="currentSection === 'dynamic'">
            <FormField>
              <textarea
                id="dynamic_url"
                v-model="currentItem.nearby_url"
                class="fh-text"
                readonly
              ></textarea>
            </FormField>
          </div>
          <div class="content-btn">
            <button
              class="endpoints-btn"
              @click.prevent="downloadSample(currentItem.dynamic_url)"
            >
              <font-awesome-icon
                icon="download"
                class="i"
              />
              Sample Endpoint
            </button>
            <button
              class="endpoints-btn"
              @click.prevent="copyToClipboard"
            >
              <font-awesome-icon
                icon="copy"
                class="i"
              />
              Copy
            </button>
          </div>
          <div
            v-if="copySuccess"
            class="alert alert-info"
          >
            URL has been copied to the clipboard!
          </div>
        </div>
      </b-modal>

      <ModuleFooter>
        <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
        <WebLinks slot="right" />
      </ModuleFooter>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import WebLinks from '@/components/WebLinks.vue'
import Utils from '@/helpers/Utils.js'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LocationFeederRepositoryFeeds',
  components: {
    Navbar,
    FormField,
    ModuleHeader,
    ModuleFooter,
    ModuleFilter,
    PencilBar,
    ContentTableTools,
    WebLinks
  },
  data() {
    return {
      filterModuleData: {
        search: '',
        filterValues: {},
        filterDefaults: {},
        filterOptions: {}
      },
      tableDataOptions: {
        orderBy: 'update_at',
        order: 'desc',
        options: [
          {
            label: 'Name',
            value: 'feed_name'
          },
          {
            label: 'Feed ID',
            value: 'id'
          },
          {
            label: 'Last Update',
            value: 'update_at'
          }
        ]
      },
      mobileFiltersExpanded: false,
      breadcrumsData: [
        {
          text: 'Locator Feed',
          href: '/locator-feed'
        },
        {
          text: 'Feeds',
          active: true
        }
      ],
      currentSection: '',
      copySuccess: false,
      feedsSelected: {},
      currentItem: null,
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      feedName: '',
      input: 'File',
      type: 'Data Sheet',
      data: [],
      contextualMenuExpanded: false,
      selectAll: false
    }
  },
  methods: {
    ...mapActions('locationFeeders', ['fetch', 'delete']),
    deleteFeed: function (feedId) {
      const feed = this.list.find((item) => item.id === feedId)

      this.$swal({
        position: 'top',
        title: `Are you sure you want to delete this feed: ${feed.feed_name}?`,
        html: '',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
        icon: 'warning',
        toast: false,
        timer: 7000
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(feedId)
            .then(() => {
              this.showAlerts('Succes!', 'Feed deleted successfully', 'success')
            })
            .catch((error) => {
              this.$log.error(error)
              this.showAlerts(
                'Error!',
                'There was an issue deleting this feed. Please try again.',
                'error'
              )
            })
        }
      })
    },
    openNewFeed() {
      this.$router.push('/tools/location-feeder/feed-builder')
    },
    openEditFeed: function (feedId) {
      this.$router.push(`/tools/location-feeder/feed-builder/${feedId}`)
    },
    openEndpointModal: function (item) {
      this.currentSection = 'dynamic'
      this.currentItem = item
      this.currentItem.nearby_url = item.dynamic_url.replace(/&pos=[^&]+/, '')
      this.$refs.endpointModal.show()
    },
    getFormattedDate: function (date) {
      const formattedDate = this.$moment(date).utc().format('MMM DD, YYYY')
      return formattedDate !== 'Invalid date' ? formattedDate : ''
    },
    populateFilters() {
      // Get keys for the filter
      const paramsToFilterBy = Object.keys(this.filterModuleData.filterValues)

      // populate filterOptions based on locationFeeder
      this.list.map((item) => {
        item.status = item.active === 1 ? 'active' : 'inactive'
        return item
      })

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(this.list, paramsToFilterBy)

      // Add filterModuleData.filterValues's default values as the first option for each filter
      paramsToFilterBy.forEach((param) => {
        filterOptions[param].unshift(
          this.filterModuleData.filterDefaults[param]
        )
      })

      // Store filter options
      this.filterModuleData.filterOptions = filterOptions
    },
    copyToClipboard: function () {
      const url
        = this.currentSection === 'static'
          ? this.currentItem.static_url
          : this.currentItem.nearby_url
      navigator.clipboard.writeText(url)
      this.copySuccess = true
      setTimeout(() => {
        this.copySuccess = false
      }, 1500)
    },
    downloadSample: function (sampleurl) {
      window.open(sampleurl, '_blank')
    },
    showAlerts: function (type, message, icon) {
      this.$swal({
        position: 'top',
        title: type,
        html: `<h3>${message}</h3>`,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: icon,
        timer: 7000,
        toast: false
      })
    },
    navigateToFeedBuilder: function (itemData) {
      this.$router.push({
        name: 'Edit Feed Builder',
        params: {
          id: itemData.id
        }
      })
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll === true) {
        this.paginatedItems.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.feedsSelected, String(item.id), true)
          return item
        })
      }
      // deselect all
      if (this.selectAll === false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.feedsSelected, String(item.id), false)
          return item
        })
      }
    }
  },
  computed: {
    ...mapGetters('locationFeeders', ['list', 'fetchById']),
    filteredItems: function () {
      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        this.list,
        this.filterModuleData.search
      )

      this.$log.info(searchedItems)

      // Pick properties to filter by: the ones that are not using the default values
      const propsToFilterBy = Object.keys(this.filterModuleData.filterValues)
        .filter((key) => {
          const filterOption
            = this.filterModuleData.filterOptions
            && this.filterModuleData.filterOptions[key]
              ? this.filterModuleData.filterOptions[key][0]
              : false

          return (
            filterOption
            && this.filterModuleData.filterValues[key] !== filterOption
          )
        })
        .reduce((obj, key) => {
          obj[key] = this.filterModuleData.filterValues[key]
          return obj
        }, {})

      // filter data
      const filteredItems = Utils.getFilteredItems(
        searchedItems,
        propsToFilterBy
      )

      // order data asc or desc
      filteredItems.sort((a, b) => {
        const field = this.tableDataOptions.orderBy
        if (a[field] < b[field]) {
          return this.tableDataOptions.order === 'asc' ? -1 : 1
        }
        if (a[field] > b[field]) {
          return this.tableDataOptions.order === 'asc' ? 1 : -1
        }
        return 0
      })

      return filteredItems
    },
    paginatedItems: function () {
      // return paginated data
      return Utils.getPaginatedItems(
        this.filteredItems,
        this.pagination.currentPage,
        this.pagination.perPage
      )
    }
  },
  watch: {
    // Watch for changes in locationFeeder List
    list: {
      immediate: true,
      handler(newVal, oldVal) {
        this.populateFilters()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style lang="scss">
.modal-content {
  background: #ffff !important;
}

button.active {
  background-color: #ffff !important;
  color: #14123a !important;
}

.sModal__tabs li:after {
  display: none !important;
}

.content-btn {
  display: flex;
  // align-items: right;
  justify-content: space-between;
  padding-bottom: 1em;
}

.endpoints-btn {
  display: inline-block;
  color: #646464;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  background: white;
  padding: 1rem 1.5rem;
  cursor: pointer;
  &:hover {
    background: #f4f4f4;
  }
}

.fh-text {
  height: auto !important;
  min-height: 100%;
  max-height: 100%;
}
</style>
