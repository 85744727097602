<template>
    <div class="appFrame">
        <main class="moduleFrame moduleFrame--CreativeRepositoryPreview">
            <div class="loader" :class="{'done': (!loading)}">
                <font-awesome-icon icon="spinner" class="spinner"/>
            </div>

            <div class="stickyNav">
                <ul>
                    <li :selected="currentSection=='overview'">
                        <a @click="currentSection='overview'">Overview</a>
                    </li>
                    <li  v-if="creative.demoTag" :selected="currentSection=='demo'">
                        <a @click="currentSection='demo'">Demo</a>
                    </li>
                    <li v-if="creative.designImages.length>0" :selected="currentSection=='design'">
                        <a @click="currentSection='design'">Design</a>
                    </li>
                </ul>

                <a href="http://padsquad.com/" target="_blank" class="stickyNav__brand stickyNav__brand--mobile">
                    <img src="@/assets/simpli-icon.svg"/>
                </a>

                <a href="http://padsquad.com/" target="_blank" class="stickyNav__brand stickyNav__brand--desktop">
                    <img src="@/assets/simpli-logo-white.svg"/>
                </a>
            </div>

            <div class="creativePreview" :class="{'creativePreview__demo--height' : currentSection == 'demo' && notDesktop}">
                <div v-if="currentSection=='overview' || !notDesktop" class="creativePreview__panel creativePreview__panel--overview">
                    <div class="overview">
                        <header class="overview__name">{{creative.name}}</header>

                        <div class="overview__infoBox">
                            <div v-if="creative.description" class="overview__infoBox__row overview__description" v-html="creative.description"></div>
                        </div>
                    </div>
                </div>

                <div v-if="currentSection=='demo'" class="creativePreview__panel creativePreview__panel--demo">
                    <div class="creativePreview__panel__tabs">
                        <ul>
                            <li v-if="creative.designImages.length > 0">
                                <a @click="currentSection='design'">Design</a>
                            </li>
                            <li selected>
                                <a @click="currentSection='demo'">Demo</a>
                            </li>
                        </ul>
                    </div>

                    <div class="creativePreview__panel__controls">
                        <ul>
                            <li v-if="creative.supports_mobile == 1" :selected="demo.controls.deviceSelectExpanded">
                                <a @click="demo.controls.deviceSelectExpanded=!demo.controls.deviceSelectExpanded">
                                    <font-awesome-icon icon="mobile-alt" class="i" />
                                </a>
                                <ul>
                                    <li :selected="demo.currentDevice=='sm'">
                                        <a @click="demo.currentDeviceType ='mobile'; demo.currentDevice='sm'; demo.controls.deviceSelectExpanded=false">S</a>
                                    </li>
                                    <li :selected="demo.currentDevice=='md'">
                                        <a @click="demo.currentDeviceType ='mobile'; demo.currentDevice='md'; demo.controls.deviceSelectExpanded=false">M</a>
                                    </li>
                                    <li :selected="demo.currentDevice=='lg'">
                                        <a @click="demo.currentDeviceType ='mobile'; demo.currentDevice='lg'; demo.controls.deviceSelectExpanded=false">L</a>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="creative.format === 'FT (Adhesion)'">
                                <a @click="demo.currentDeviceType ='desktop';">
                                    <font-awesome-icon icon="desktop" class="i" />
                                </a>
                            </li>
                            <li>
                                <a @click="demoRefresh">
                                    <font-awesome-icon icon="sync-alt" class="i" />
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="demoPresentation" :class="'demoPresentation---'+demo.currentDeviceType">
                        <div class="demoPresentation__inner">
                            <div class="demoPresentation__viewport" :class="'demoPresentation__viewport--'+demo.currentDevice">
                                <iframe v-if="device=='desktop'" :src="'/assets/mocksites/mobile1.html?tag='+tagToRender"></iframe>
                                <div v-if="device=='mobile'" v-html="mockSiteMarkUp" class="mockSite"></div>
                            </div>
                        </div>
                    </div>

                    <div class="creativePreview__panel__footer">&copy; {{ new Date() | moment('YYYY') }}. This is a legal, probably you will not be that nice, but we want you to be nice so we don’t sue you. Best, us.</div>
                </div>

                <div v-if="currentSection=='design'" class="creativePreview__panel creativePreview__panel--design">
                    <div class="creativePreview__panel__tabs">
                        <ul>
                            <li selected>
                                <a @click="currentSection='design'">Design</a>
                            </li>
                            <li v-if="creative.demoTag != ''">
                                <a @click="currentSection='demo'">Demo</a>
                            </li>
                        </ul>
                    </div>

                    <div class="designPresentation">
                        <div class="designPresentation__inner">
                            <div class="designPresentation__viewport designPresentation__viewport--md" :style="'--design-viewport-height:'+design.viewportHeight+'px'">
                                <div class="designPresentation__slides">
                                    <ul>
                                        <li v-for="(designImage, index) in creative.designImages" :key="index" :class="{'current': (index==design.currentSlide)}" :style="{'background-image': 'url('+designImage.path+')'}"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="designPresentation__controls">
                            <div class="designPresentation__controls__breadcrumbs">
                                <ul>
                                    <li v-for="(designImage, index) in creative.designImages" :key="index" :selected="index==design.currentSlide"></li>
                                </ul>
                            </div>
                            <a class="designPresentation__controls__control designPresentation__controls__control--prev" @click="designPrev()">
                                <font-awesome-icon icon="angle-left" class="i" />
                            </a>
                            <a class="designPresentation__controls__control designPresentation__controls__control--next" @click="designNext()">
                                <font-awesome-icon icon="angle-right" class="i" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <div v-html="linkedinInsight"></div>

    </div>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'

export default {
  name: 'CreativeRepositoryCreative',
  data () {
    return {
      loading: true,
      creative: {
        name: '',
        format: '',
        creative_type: '',
        designImages: [],
        performanceMetrics: [],
        description: '',
        type: '',
        supports_mobile: false,
        supports_desktop: false
      },
      currentSection: 'demo',
      demo: {
        currentDeviceType: 'mobile',
        currentDevice: this.deviceThatFits(),
        controls: {
          deviceSelectExpanded: false
        }
      },
      design: {
        viewportHeight: 0,
        currentSlide: 0
      },
      windowWidth: window.innerWidth
    }
  },
  created () {
    const self = this
    const promises = []

    // get data
    Api.getCreativePreviewById(self.$route.params.id, true)
      .then(creativeData => {
        const self = this

        if (creativeData.campaignStartDate) creativeData.campaignStartDate = self.$moment(creativeData.campaignStartDate).utc()
        if (creativeData.campaignEndDate) creativeData.campaignEndDate = self.$moment(creativeData.campaignEndDate).utc()

        // sort performance metrics
        creativeData.performanceMetrics = _.sortBy(creativeData.performanceMetrics, 'order')

        self.$log.info('creative:', creativeData)

        // set default section (design|demo)
        // if a demo tag came through
        if (!_.isEmpty(creativeData.demoTag)) {
          self.currentSection = 'demo'
        } else {
          self.currentSection = 'design'
        }

        self.creative = creativeData
        self.creative.designImages = creativeData.assets

        // turn off loading spinner
        self.loading = false
      })
      .catch(error => {
        self.$log.error(error)
        // take the user to simpli's home page if the creative does not exist.
        window.location.replace('https://padsquad.com/simpli')
      })
  },
  mounted () {
    const self = this

    // add an event listener for window.resize. This is used for a couple of things.
    window.addEventListener('resize', () => {
      // 1. this is to hide certain panels depending if mobile or not
      self.windowWidth = window.innerWidth

      // if this is the design section
      if (self.currentSection == 'design') {
        // 2. In mobile this is used to calculate the aspect ratio for vertical resize of the design slides container.
        self.design.viewportHeight = document.querySelector('.designPresentation__viewport').offsetHeight
      }
    })
  },
  computed: {
    quarters: function () {
      const self = this

      if (self.creative.campaignStartDate && self.creative.campaignEndDate) {
        // date on creatives is MM/DD/YYYY. Watch out for any future changes
        const startQuarter = Utils.getQuarterFromMonth(self.creative.campaignStartDate.month())
        const endQuarter = Utils.getQuarterFromMonth(self.creative.campaignEndDate.month())
        // how many quarters the flight spreads acros
        const numberOfQuarters = Math.abs(endQuarter - startQuarter) + 1

        const quartersList = _.times(numberOfQuarters, key => {
          let q = startQuarter + key

          if (q > 4) q = q - 4

          return `Q${q}`
        })

        // compose an array with the quarters the campaign ran on.
        return quartersList
      } else {
        return []
      }
    },
    engagemenRate: function () {
      const self = this

      return Math.round((self.creative.engagements / self.creative.deliveredImpressions) * 100)
    },
    videoCompletionRate: function () {
      const self = this

      return Math.round((self.creative.videoCompletions / self.creative.deliveredImpressions) * 100)
    },
    viewabilityRate: function () {
      const self = this

      return Math.round((self.creative.viewableImpressions / self.creative.deliveredImpressions) * 100)
    },
    notDesktop: function () {
      const self = this

      return self.windowWidth < 768
    },
    device: function () {
      const self = this

      return (self.notDesktop) ? 'mobile' : 'desktop'
    },
    tagToRender: function () {
      const self = this

      // let renderType = _.find(self.formats, format => format.id == self.creative.format_id).demoRenderStyle || false;
      let renderType = false

      // if we selected Brandscape or FT (Adhesion) format we'll replace web.js for preview.js in the TAG
      // let id = self.creative.format_id;
      const formatName = self.creative.format
      // Brandscape id:2
      // FT (Adhesion) id:36
      // if(parseInt(id) == 2 || parseInt(id) == 36 || ) {
      if (formatName === 'Brandscape' || formatName === 'FT (Adhesion)') {
        const tag = self.creative.demoTag
        self.creative.demoTag = tag.replace('web.js', 'preview.js')
        renderType = 'straight'
      }

      switch (renderType) {
        case 'straight':
          return _.trim(btoa(self.creative.demoTag))
          break

        default:
          return btoa(`<iframe srcdoc="&lt;script&gt;var inDapIF = true; &lt;/script&gt; ${_.trim(_.escape(self.creative.demoTag))}"></iframe>`)
          break
      }
    },

    mockSiteIframe: function () {
      const self = this

      return `
                <!doctype html>
                <html lang='en'>
                <head>
                    <meta charset='UTF-8'>
                    <meta name='viewport' content='width=device-width, initial-scale=1.0'>
                    <meta http-equiv='X-UA-Compatible' content='ie=edge'>
                    <link rel='stylesheet' type='text/css' href='https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css'>
                    <link rel='stylesheet' type='text/css' href='https://use.typekit.net/adn0bei.css'>
                    
                    <style>

                        html {
                            height: 100%;
                            font-size: 62.5%; /* Now 10px = 1rem! */
                            font-family: 'montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
                        }

                        body {
                            font-size: 1.2rem;
                            text-align: center;
                            line-height: 1.5em;
                            color: #707070;
                        }
                        #page-container{
                            padding: 0 30px;

                        }
                        body.mobile .browser-toolbar {
                            display:none;
                        }

                        body.mobile {
                            padding: 60px 30px 0;
                        }

                        article {
                            padding-top: 30px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid #CBCBCB;
                            color: #646464;
                        }

                        .adBreak {
                            width: 100vw;
                            margin-left: -30px;
                            min-height: 50px;
                            margin-bottom: 15px;
                        }

                        .adBreak * {
                            max-height: 100vh !important;
                        }

                        .postImage {
                            width: 100%;
                            height: 200px;
                            background-position: center;
                            background-size: cover;
                            -webkit-filter: grayscale(100%);
                            filter: grayscale(100%);
                            margin-bottom: 15px;
                            border: 1px solid #646464;
                            opacity: 0.2;
                        }

                        h3 {
                            font-size: 1.6rem;
                            font-weight: bold;
                            margin-bottom: 15px;
                        }

                        p {
                            font-size: 1.2rem;
                        }

                        footer {
                            font-weight: bold;
                            font-size: 1rem;
                            padding: 15px;
                            background: #F5F5F5;
                            text-align: center;
                            margin: 30px -30px 0;
                        }
                        .browser-toolbar {
                            visibility: visible;
                            opacity: 1;
                            transition: top 400ms linear;
                            background-color:#eeeeee;
                            width:100%;
                            height:30px;
                            top:0px;
                            position:relative;
                            margin-left:auto;
                            margin-right:auto;
                            border-radius:2px 2px 0 0;
                            box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.5); 
                        }

                        .browser-url {
                            width: 90%;
                            background-color: white;
                            position: relative;
                            margin-top: 4px;
                            border-radius: 2px;
                            margin-left: 20px;
                            float: left;
                            text-align: left;
                            padding: 1px;
                            vertical-align: middle;
                        }

                        .browser-url  span {
                            font-size:11px;
                            margin-left:5px;
                            position:relative;
                            opacity:0.7;
                         }
                       
                        .hide-browser-toolbar {
                            visibility: hidden;
                            opacity: 0;
                            transition: visibility 0s 2s, opacity 1s linear, top 400ms linear;
                            top:-40px;
                        } 
                        
                        #toast {
                            visibility: hidden;
                            max-width: 80%;
                            height: 40px;
                            /*margin-left: -125px;*/
                            margin: auto;
                            background: rgba(0, 0, 0, 0.6);
                            color: #fff;
                            text-align: center;
                            border-radius: 30px;
                            position: fixed;
                            z-index: 1;
                            left: 0;right:0;
                            bottom: 30px;
                            font-size: 14px;
                            white-space: nowrap;
                        }
                        #toast #desc{
                            color: #fff;
                            padding: 10px;
                            overflow: hidden;
                            white-space: nowrap;
                           vertical-align: middle;
                        }

                        #toast.show {
                            visibility: visible;
                            -webkit-animation: fadein 0.5s;
                            animation: fadein 0.5s;
                        }
                         #toast.hide {
                            visibility: visible;
                            opacity: 0;
                            transition: visibility 0s 2s, opacity 400ms linear, bottom 400ms linear;
                            bottom:0px;
                        }

                        @-webkit-keyframes fadein {
                            from {bottom: 0; opacity: 0;} 
                            to {bottom: 30px; opacity: 1;}
                        }

                        @keyframes fadein {
                            from {bottom: 0; opacity: 0;}
                            to {bottom: 30px; opacity: 1;}
                        }
        
                    </style>
                    <script>
            
                    window.addEventListener('load', function() {
                        var toast = document.getElementById('toast');
                        toast.classList.add('show');
                    })
                    window.addEventListener("scroll", Scroll);
                    function Scroll(){
                        var toast = document.getElementById('toast');
                        var browserbar = document.querySelector(".browser-toolbar");
                        var ypos = window.pageYOffset;
                        if (ypos > 2) {
                            browserbar.classList.add("hide-browser-toolbar");
                        } else {
                            browserbar.classList.remove("hide-browser-toolbar");
                        }
                        if (ypos > 150) {
                            toast.classList.remove("show");
                            toast.classList.add('hide');
                        } else {
                            toast.classList.remove("hide");
                            toast.classList.add('show');

                        }
                     
                    }
                    <\/script>
                </head>
                <body>
                    ${self.mockSiteMarkUp}
                </body>
                </html>`
    },
    mockSiteMarkUp: function () {
      const self = this

      const strimReplaced = (self.creative.demoTag != '' && self.creative.demoTag != undefined) ? _.trim(_.escape(self.creative.demoTag.replace('preview.js', 'web.js'))) : ''

      return `              
                <article>
                    <div class='postImage' style='background-image: url(https://studio.simpli.padsquad.com/assets/mocksites/preview-img1.jpg)'></div>
                    <h3>PadSquad launches VVital Interactive Vertical Video for the Mobile Web</h3>
                    <p>We are excited to announce the launch of Vvital, PadSquad’s innovative HD vertical video offering for brands and agencies.</p>
                </article>

                <article>
                    <div class='postImage' style='background-image: url(https://studio.simpli.padsquad.com/assets/mocksites/preview-img2.jpg)'></div>
                    <h3>Just say no to mobile ad disruption and yes to polite engagement!</h3>
                    <p>As an industry it is time to unite and just say NO to pesky mobile overlays and disruption! Grabbing the attention of consumers is no easy task...</p>
                </article>

                <article>
                    <div class='postImage' style='background-image: url(https://studio.simpli.padsquad.com/assets/mocksites/preview-img2.jpg)'></div>
                    <h3>The future of Digital Advertising, optimizing mobile content for consumers</h3>
                    <p>The Timberland engagement rate exceeding retail industry benchmark for expandable units by 307%. The smartphone hover...</p>
                </article>

                <article>
                    <div class='postImage' style='background-image: url(https://studio.simpli.padsquad.com/assets/mocksites/preview-img3.jpg)'></div>
                    <h3>Who’s in charge here? Mobile advertising &amp; spring flowers…</h3>
                    <p>How much time does your agency or brand marketing team spend on strategy? On technology?</p>
                </article>
                <article>
                    <div class="adBreak">
                        <iframe srcdoc="&lt;script&gt;var inDapIF = true; &lt;/script&gt; ${strimReplaced}"></iframe>
                    </div>
                </article>

                <article>
                    <div class='postImage' style='background-image: url(https://studio.simpli.padsquad.com/assets/mocksites/preview-img4.jpg)'></div>
                    <h3>Stop the Scroll with Memorable Creative by PadSquad</h3>
                    <p>How do brands balance the act of innovation and intrusion when battling for user’s attention? How do publishers better monetize rapidly growing...</p>
                </article>

                <article>
                    <div class='postImage' style='background-image: url(https://studio.simpli.padsquad.com/assets/mocksites/preview-img5.jpg)'></div>
                    <h3>Mobile brand storytelling</h3>
                    <p>What makes an epic mobile brand storytelling experience? In my role at PadSquad, I am frequently asked questions about creative best...</p>
                </article>

                <article>
                    <div class='postImage' style='background-image: url(https://studio.simpli.padsquad.com/assets/mocksites/preview-img6.jpg)'></div>
                    <h3>PadSquad launches VVital Interactive Vertical Video for the Mobile Web.</h3>
                    <p>We are excited to announce the launch of Vvital, PadSquad’s innovative HD vertical video offering for brands and agencies.</p>
                </article>

                <footer>&copy; ${self.$moment().format('YYYY')} PADSQUAD LLC</footer>`
    },
    linkedinInsight: function () {
      const self = this

      return `
                <!-- LinkedIn Insights tag -->
                <script type="text/javascript"> _linkedin_partner_id = "1539297"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); <\/script><script type="text/javascript"> (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(); <\/script> <noscript> <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1539297&fmt=gif" /> </noscript>
            `
    }
  },
  methods: {
    demoRefresh: function () {
      const self = this

      const demoTag = _.cloneDeep(self.creative.demoTag)

      // premise: we will perform by updating the creative.demoTag,
      // which will trigger the mockSiteMarkUp computed value to update. Hence refreshing it.

      // change the demo tag to something else.
      self.creative.demoTag = '_'

      // and put it back in.
      setTimeout(() => {
        self.creative.demoTag = demoTag
      }, 100)
    },
    designNext: function () {
      const self = this

      if (self.design.currentSlide < self.creative.designImages.length - 1) {
        self.design.currentSlide++
      } else {
        self.design.currentSlide = 0
      }
    },
    designPrev: function () {
      const self = this

      if (self.design.currentSlide > 0) {
        self.design.currentSlide--
      } else {
        self.design.currentSlide = self.creative.designImages.length - 1
      }
    },
    deviceThatFits: function () {
      if (window.innerHeight < 800) {
        return 'sm'
      } else {
        return 'md'
      }
    }
  },
  watch: {
    notDesktop: function () {
      const self = this

      // if it just switched to desktop and it's in the overview sections...
      if (!self.notDesktop) {
        // set default section (design|demo)
        if (!_.isEmpty(self.creative.demoTag)) {
          self.currentSection = 'demo'
        } else {
          self.currentSection = 'design'
        }
      }
    },
    currentSection: function () {
      const self = this
      // if this is the design section
      if (self.currentSection == 'design') {
        setTimeout(() => {
          // In mobile this is used to calculate the aspect ratio for vertical resize of the design slides container.
          self.design.viewportHeight = document.querySelector('.designPresentation__viewport').offsetHeight
        }, 100)
      }
    },
    mockSiteIframe: function () {
      const self = this
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/common.scss";

html,
body,
.appFrame,
#app {
    height: 100%;
}

.moduleFrame.moduleFrame--CreativeRepositoryPreview {
    top: 0;
    height: 100vh;
    background: white;

    @include media-breakpoint-up(md) {
        display: block;
        width: 100%;
        left: 0px;

        .creativePreview {
            display: flex;
        }
    }
}

.creativePreview {
    height: 100%;

    /* fixed scrollers demo bugs */
    &__demo--height{
        height: auto;
    }

    &__panel {
        &__tabs,
        &__controls,
        &__footer {
            display: none
        }

        &--overview {
            @extend .container-fluid;

            overflow-x: hidden;
            // overflow-y: hidden;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &--demo {
            padding: 0px;
            width: 100%;
            height: 100%;

            overflow-x: hidden;
        }

        &--design {
            background: $gray2;
            width: 100%;
            height: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;

        &__panel {
            flex-grow: 1;
            overflow: scroll;
            position: relative;
            min-width: 540px;

            &__tabs {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;

                ul {
                    display: block;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    white-space: nowrap;

                    li {
                        display: inline-block;
                        color: white;

                        a {
                            display: block;
                            height: 6rem;
                            line-height: 6rem;
                            font-size: 2.4rem;
                            font-weight: 600;
                            color: white;
                            text-decoration: none;
                            width: 100%;
                            padding-left: $spacing-unit*2;
                            padding-right: $spacing-unit*2;
                            cursor: pointer;

                            &:hover {
                                background: $pink5;
                            }
                        }

                        &[selected] {
                            a {
                                color: $pink6;
                                background: white;

                                &:hover {
                                    color: $pink5;
                                }
                            }
                        }
                    }
                }
            }

            &__footer {
                position: absolute;
                top: calc(100% + #{$spacing-unit*8});
                left: 0;
                display: none !important;
                padding: $spacing-unit*2;
                color: white;
                font-size: 1rem;
            }

            &__controls {
                position: absolute;
                top: 50%;
                margin-top: -60px;
                right: $spacing-unit*2;
                width: 50px !important;
                z-index: 1;

                ul {
                    list-style: none;
                    margin: 0;
                    padding:0;

                    li {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        background: white;
                        margin-bottom: $spacing-unit;

                        &[selected] {

                            > a {
                                color: white;
                                background: $green4;
                            }

                            > ul {
                                display: block;
                                position: absolute;
                                top: 0;
                                right: calc(100% + #{$spacing-unit});

                                li {

                                    a {
                                        border: $pink5;
                                    }
                                }
                            }
                        }

                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            font-size: 1.5rem;
                            cursor: pointer;
                            color: $gray6;

                            &:hover {
                                background: $green4;
                                color: white !important;
                            }
                        }

                        & > ul {
                            display: none;
                        }
                    }
                }
            }

            &--overview {
                @include vertical-align();

                flex-grow: 1;
                width: 50%;
                max-width: 700px;

                overflow-x: hidden;
                // overflow-y: hidden;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &--demo,
            &--design {
                flex-grow: 1;
                width: 70%;

                @include vertical-align();
                padding: $spacing-unit*6 0 $spacing-unit*2;
                background: $green5;
            }

            &--demo {
                overflow-x: hidden;
            }
        }
    }
}

.overview {

    padding-top: $spacing-unit*4;

    &__type {
        font-size: 1.2rem;
        font-weight: 600;
        color: $gray6;
        margin-bottom: 0;
    }

    &__name {
        font-size: 1.6rem;
        font-weight: 600;
        color: $blue2;
        margin-bottom: $spacing-unit*2;
    }

    &__infoBox {
        border: 1px solid $borders-color;

        &:empty {
            border: none;
        }

        &__row {
            padding: $spacing-unit*2 $spacing-unit;
            border-bottom: 1px solid $borders-color;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__flight {
        text-align: center;

        & > * {
            display: inline-block;
        }

        &__startDate,
        &__endDate {
            color: $pink5;
            font-weight: 800;
        }

        &__yearSplit {
            margin-left: $spacing-unit/2;
            margin-right: $spacing-unit/2;
            position: relative;
            min-width: 25px;
            height: 25px;
            line-height: 25px;

            .curve {
                display: none;
            }

            :before {
                content: '';
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: $green4;
                top: 50%;
                left: 0;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    opacity: 0;
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    vertical-align: middle;
                    background: $green1;
                    border-radius: 100%;
                    color: white;
                    font-weight: bold;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;

                    &:not(:first-child) {
                        display: none;
                    }
                }
            }
        }
    }

    &__description {
        h3 {
           color: $pink5;
        }

        p {
            color: $gray6;
            line-height: 2rem;

            & + h3 {
                margin-top: 2rem;
            }
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    &__performance {
        &.overview__infoBox__row {
            padding-bottom: 0;
        }

        &__metric {
            margin-bottom: $spacing-unit*2;
            display: inline-block;
            width: 100%;

            &__value {
                font-size: 3rem;
                font-weight: 600;
                color: $pink5;
            }

            &__label {
                color: $green4;
            }
        }
    }

    @include media-breakpoint-up(sm) {

        padding-top: 0;
        padding-bottom: 60px;

        &__flight {
            :before {
                display: none;
            }

            &__yearSplit {
                margin-left: $spacing-unit;
                margin-right: $spacing-unit;

                ul {
                    position: relative;
                    li {
                        opacity: 1;

                        &:not(:first-child) {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        &__description {}
        &__performance {

            &__metric {
                &--full {
                    width: 100%;
                }

                margin-bottom: $spacing-unit*2;
                display: inline-block;
                width: 50%;

                &__value {
                    font-size: 4rem;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__name {
            font-size: 2.4rem;
            margin-bottom: $spacing-unit*2;
        }

        &__infoBox {

            &__row {
                padding: $spacing-unit*2;
            }
        }

        &__flight {
            &__yearSplit {
                margin-left: $spacing-unit;
                margin-right: $spacing-unit;

                .curve {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    /* display: block; */

                    img {
                        position: absolute;
                        width: calc(100% + #{1rem * 2});
                        left: -1rem;
                        bottom: 50%;
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: -1rem;
                        margin-top: -5px;
                        width: 5px;
                        height: 5px;
                        background: white;
                        border: 1px solid #DB2C91;
                        border-radius: 100%;
                        z-index: 1;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -1rem;
                        margin-top: -5px;
                        width: 5px;
                        height: 5px;
                        background: white;
                        border: 1px solid #DB2C91;
                        border-radius: 100%;
                        z-index: 1;
                    }

                }
            }
        }

        &__performance {
            &__metric {

                &--full {
                    width: 100%;
                }

                margin-bottom: $spacing-unit*2;
                display: inline-block;
                width: 50%;

                &__value {
                    font-size: 4rem;
                    font-weight: 600;
                    color: $pink5;
                }

                &__label {
                    color: $green4;
                }

            }

        }
    }
}

.demoPresentation {
    width: auto !important;
    height: calc(100% - #{$spacing-unit*4});
    margin-top: $spacing-unit*4;
    &__inner {
        height: 100%;
    }

    &__viewport {
        height: 100%;

        iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        height: auto;

        &__inner {
            display: inline-block;
            background: white;
            padding: 4rem 2.5rem;
            border-radius: 4rem;
        }

        &--desktop {

        }

        &--mobile {

        }

        &__viewport {
            border: 1px solid $gray2;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 2px solid black;
                opacity: 0.05;
                pointer-events: none;
            }

            &--sm {
                width: 322px;
                height: 570px;
            }

            &--md {
                width: 375px;
                height: 667px;
            }

            &--lg {
                width: 414px;
                height: 736px;
            }
        }
    }
}

.designPresentation {
    position: relative;
    width: auto !important;
    height: 100%;

    &__inner {
        height: calc(100% - 51px);
        padding: $spacing-unit*5 0 $spacing-unit;
        background: $gray2;
    }

    &__viewport {
        display: inline-block;
        width: calc((var(--design-viewport-height) * 0.56 ));
        height: 100%;
        position: relative;
    }

    &__controls {

        display: flex;
        background: white;
        border-top: 1px solid $borders-color;

        &__breadcrumbs {
            flex-grow: 1;
            height: 50px;
            border-right: 1px solid $borders-color;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                height: 100%;
                line-height: 50px;
                padding: 0 30px;
                text-align: left;

                li {
                    display: inline-block;
                    line-height: 50px;
                    width: 12px;
                    height: 12px;
                    background: $gray3;
                    border-radius: 100%;
                    margin-right: 10px;

                    &[selected] {
                        background: $pink2;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &__control {
            display: block;
            width: 50px;
            height: 50px;
            background: white;
            font-size: 2rem;
            line-height: 50px;
            color: $gray6;
            cursor: pointer;
            border-right: 1px solid $borders-color;

            &:last-child {
                border-right: none;
            }

            &--prev {
            }

            &--next {
            }
        }
    }

    &__slides {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            width: 100%;
            height: 100%;

            li {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

                &.current {
                    display: block
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        height: auto;

        &__inner {
            display: inline-block;
            background: white;
            padding: 4rem 2.5rem;
            border-radius: 4rem;
        }

        &__viewport {
            border: 1px solid $gray2;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 2px solid black;
                opacity: 0.05;
                pointer-events: none;
            }

            &--sm {
                width: 322px;
                height: 570px;
            }

            &--md {
                width: 384px;
                height: 615px;
            }

            &--lg {
                width: 414px;
                height: 736px;
            }
        }

        &__controls {
            display: block;
            border-top: none;

            &__breadcrumbs {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 40px;
                border-right: none;

                ul {
                    line-height: 40px;
                    text-align: center;
                    li {

                    }
                }
            }

            &__control {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                z-index: 1;
                border-right: none;

                &--prev {
                    left: -50px;
                }

                &--next {
                    right: -50px;
                }
            }
        }
    }
}

.stickyNav {
    @extend .no-select;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: $green4;
    z-index: 1;
    display: flex;

    ul {
        flex-grow: 1;
        list-style: none;
        margin: 0 0 0 10px;
        padding: 0;
        text-align: left;

        li {
            position: relative;
            height: 100%;
            line-height: 60px;
            display: inline-block;
            color: white;

            &[selected] + & {
                &:after {
                    display: none;
                }
            }

            &[selected] {
                a {
                    color: $pink2;
                    background: white;

                    &:hover {
                        color: $pink2;
                        background: white;
                    }
                }

                &:after {
                    display: none;
                }
            }

            &:first-child {
                &:after {
                    display: none;
                }
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 1px;
                height: 10px;
                margin-top: -5px;
                background: #FF8ECF;
            }

            a {
                display: inline-block;
                line-height: 40px;
                cursor: pointer;
                padding: 0 $spacing-unit;

                &:hover {
                    background: $green4;
                }
            }
        }
    }

    &__brand {
        width: 60px;
        height: 100%;
        line-height: 60px;
        color: white;
        background: $green4;

        img {
            height: 50%;
        }

        &--desktop {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        width: auto;
        top: auto;
        bottom: 0;

        ul {
            display: none;
        }

        &__brand {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: block;
                width: auto;
                padding: 0 $spacing-unit*2;
            }
        }
    }
}

.mockSite {

    padding: 30px 30px 0;

    article {
        padding-top: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid #CBCBCB;
        color: #646464;
    }

    .adBreak {
        width: 100vw;
        margin-left: -30px;
        min-height: 50px;
        margin-bottom: 15px;
    }

    .adBreak * {
        max-height: 100vh !important;
    }

    .postImage {
        width: 100%;
        height: 200px;
        background-position: center;
        background-size: cover;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        margin-bottom: 15px;
        border: 1px solid #646464;
        opacity: 0.2;
    }

    h3 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 15px;
    }

    p {
        font-size: 1.2rem;
    }

    footer {
        font-weight: bold;
        font-size: 1rem;
        padding: 15px;
        background: #F5F5F5;
        text-align: center;
        margin: 30px -30px 0;
    }
    .browser-toolbar {
        visibility: visible;
        opacity: 1;
        transition: top 400ms linear;
        background-color:#eeeeee;
        width:100%;
        height:30px;
        top:0px;
        position:relative;
        margin-left:auto;
        margin-right:auto;
        border-radius:2px 2px 0 0;
        box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.5);
    }

    .browser-url {
        width: 90%;
        background-color: white;
        position: relative;
        margin-top: 4px;
        border-radius: 2px;
        margin-left: 20px;
        float: left;
        text-align: left;
        padding: 1px;
        vertical-align: middle;
    }

    .browser-url  span {
        font-size:11px;
        margin-left:5px;
        position:relative;
        opacity:0.7;
        }

    .hide-browser-toolbar {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 2s, opacity 1s linear, top 400ms linear;
        top:-40px;
    }

    #toast {
        visibility: hidden;
        max-width: 80%;
        height: 40px;
        /*margin-left: -125px;*/
        margin: auto;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        text-align: center;
        border-radius: 30px;
        position: fixed;
        z-index: 1;
        left: 0;right:0;
        bottom: 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    #toast #desc{
        color: #fff;
        padding: 10px;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: middle;
    }

    #toast.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;
    }
        #toast.hide {
        visibility: visible;
        opacity: 0;
        transition: visibility 0s 2s, opacity 400ms linear, bottom 400ms linear;
        bottom:0px;
    }

    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

}

</style>
