var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mobile-shell d-flex",class:[
    `${_setup.sizeName}__shell`,
    _setup.landscape ? `justify-content-end align-items-center ${_setup.sizeName}__shell__landscape` : 'justify-content-center align-items-end'
  ]},[_c('div',{staticClass:"mobile-screen d-flex overflow-hidden flex-column",class:[
      `${_setup.sizeName}__screen`,
      _setup.landscape ? `${_setup.sizeName}__screen__landscape` : ''
    ],style:(`background-color: ${_setup.screenBgColor};`)},[_c('div',{staticClass:"mobile-top-bar",class:[
      `${_setup.sizeName}__top-bar`,
      _setup.landscape ? `${_setup.sizeName}__top-bar__landscape mobile-top-bar__landscape` : ''
    ]}),_c('div',{staticClass:"flex-grow-1 d-flex overflow-hidden"},[(_setup.landscape && (_setup.sizeName === 'medium' || _setup.sizeName === 'large'))?_c('div',{staticClass:"left-gap",class:`${_setup.sizeName}__gap`}):_vm._e(),_vm._t("iFrameContent",function(){return [_vm._v("empty")]}),(_setup.landscape)?_c('div',{staticClass:"right-gap",class:`${_setup.sizeName}__gap`}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }