<template>

    <div class="appFrame">

        <Navbar :currentModulePath="'analytics'" :currentModuleName="'Analytics'" />

        <main class="moduleFrame moduleFrame--tagManagerRepository">

          <div class="loader" :class="{'done': (!loading)}">
            <font-awesome-icon icon="spinner" class="spinner"/>
          </div>

          <PencilBar />

          <ModuleHeader>
            <ul slot="tabs">
              <li><router-link to="/analytics/saved-reports">Saved Reports</router-link></li>
              <li><router-link to="/analytics/dataloading/">Data Loading</router-link></li>
              <li selected><router-link to="/analytics/pulse/">Pulse</router-link></li>
              <li><router-link to="/analytics/campaign-pacing/">Campaign Pacing</router-link></li>
              <li ><router-link to="/analytics/vidstream-performance/">VidStream P1</router-link></li>
              <li><router-link to="/analytics/vidstream-performance2/">VidStream P2</router-link></li>
            </ul>

            <div slot="title">Pulse</div>

          </ModuleHeader>

          <div id="dashsample" class="container">

            <div  v-if="!tableauLoading">
              <tableau-viz id="tableauViz"
                src='https://prod-useast-a.online.tableau.com/t/padsquad/views/AdProductPerformanceWorkbook/AdProductDashboard_1?%3Aembed=y&%3AapiID=embhost1&%3AapiInternalVersion=1.117.0&%3AapiExternalVersion=3.3.0&navType=0&navSrc=Opt&%3AdisableUrlActionsPopups=n&%3Atabs=n&%3Atoolbar=bottom&%3Adevice=default&mobile=n&%3AhideEditButton=n&%3AhideEditInDesktopButton=n&%3AsuppressDefaultEditBehavior=n&%3Ajsdebug=n#2'
                toolbar="bottom" hide-tabs
                :token="sightUrl">
              </tableau-viz>
            </div>

          </div>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>
    </div>

</template>

<script>
import Navbar from '@/components/Navbar.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import WebLinks from '@/components/WebLinks.vue'
import Api from '@/helpers/Api'

export default {
  loading: true,
  name: 'AnalyticsRepositoryAppDb',
  components: {
    Navbar, ModuleHeader, PencilBar, ModuleFooter, ContentTableTools, WebLinks
  },
  data() {
    return {
      loading: true,
      tableauLoading: true,
      data: [],
      sightUrl: ''
    };
  },
  methods: {
    informUserEnableCookie: function (e) {
      this.$swal({
        position: 'top',
        title: 'Please Enable Cookies!',
        html: e,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'info',
        timer: 15000,
        toast: false
      });
    },
    informSessionTimeout: function (e) {
      this.$swal({
        position: 'top',
        title: 'Session Timeout!',
        html: '<h3>This sessions timeout, please refresh page.</h3>',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'info',
        timer: 15000,
        toast: false
      })
    },
    checkingCookiesAfterHour: function () {
      //check if has cookies enable or disable
      const iframe = document.getElementById('thirdpartychecker')
      iframe.contentWindow.location = iframe.src;

      const isSafari = window.safari !== undefined
      const isChrome = window.chrome !== undefined
      const cookieEnabled = navigator.cookieEnabled;

      const receiveMessage = (evt) => {
        let thirdValidation
        if (evt.data === 'MM:3PCunsupported') {
          thirdValidation = false
        } else if (evt.data === 'MM:3PCsupported') {
          thirdValidation = true
        }
        this.informUserCookies(thirdValidation, isSafari, isChrome, cookieEnabled);
      }
      window.addEventListener('message', receiveMessage, false)
    },
    informUserCookies: function (evt0, evt1, evt2, evt3) {
      let e
      if (evt0 == false || evt3 == false) {
        if (evt1) {
          e = `<h3>This dashboard requires cookies in order to display the data.</h3>
          <h2>Safari > Preferences > Privacy > Disable Prevent cross-site tracking.</h2>`}
        if (evt2) {
          e = `<h3>This dashboard requires cookies in order to display the data.</h3>
          <h2>Click on the Third Party Blocking (Eye) button at the right side of the URL bar.</h2>`}
        this.informUserEnableCookie(e)
      }
    },
    async  getToken() {
      let storedToken = localStorage.getItem('tableauToken')
      let expiryTime = localStorage.getItem('tableauTokenExpiry')

      // Validate the stored token and expiry time
      if (storedToken && expiryTime && new Date().getTime() < expiryTime) {
        return storedToken;
      } else {
        let data = await Api.getQuicksightUrl();
        // Saving new token and expiry time locally
        localStorage.setItem('tableauToken', data.tableau)
        localStorage.setItem('tableauTokenExpiry', 3600)

        return data.tableau;
      }
    },
  },
  created() {
    const isSafari = window.safari !== undefined
    const isChrome = window.chrome !== undefined
    const cookieEnabled = navigator.cookieEnabled

    let receiveMessage = (evt) => {
      let thirdValidation;
      if (evt.data === 'MM:3PCunsupported') {
        thirdValidation = false;
      } else if (evt.data === 'MM:3PCsupported') {
        thirdValidation = true;
      }
      this.informUserCookies(thirdValidation, isSafari, isChrome, cookieEnabled)
    }
    window.addEventListener('message', receiveMessage, false)
  },
  mounted: function () {
    //Check cookies every 10 minutes
    window.setInterval(() => {
      this.checkingCookiesAfterHour()
    }, 1500000)

    // get Tableau token
    this.getToken()
      .then(token => {
        setTimeout(() => {
          this.sightUrl = token;
          this.tableauLoading = false;
          setTimeout(() => { this.loading = false }, 2000)
        }, 500)
      })
      .catch(error => {
        console.error('Error occurred while fetching token:', error)
      })
  }
}
</script>

<style lang="scss">
@import "@/scss/common.scss";

#dashsample iframe{
  border: none;
  padding: 10px 10px 0;
}

.contentTableFrame {
  display: block;
}

.container {
  position: relative;
  overflow: hidden;
  //padding-top: 56.25%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 100%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

tableau-viz#tableauViz {
  width: 100%;
  height: 85vh;
}

</style>
