<template>

    <div class="appFrame">

        <Navbar :currentModulePath="'tag-manager'" :currentModuleName="'Tag Manager'" />

        <main class="moduleFrame moduleFrame--tagManagerRepository moduleFrame--tagManagerRepositoryPlacements moduleFrame--tagManagerRepositoryCampaignChild">

          <PencilBar />

          <div v-if="campaign != null" class="heading">{{ campaign.name }} </div>
          <!-- <button class="button" @click="openModalSettings(campaign.id, null, 'Campaign')"><font-awesome-icon icon="cog" class="i" /></button> -->

          <ModuleHeader>
            <ul slot="tabs">
                <li><router-link :to="{path:'/tag-manager/placements/campaign/' + campaign.id, params:{ id:campaign.id, data:campaign}}">Placements</router-link></li>
                <!-- <li><router-link :to="{path:'/creative-production/creatives/campaign/' + campaign.id}">Creatives</router-link></li> -->
                <li><router-link :to="{path:'/analytics/performance/campaign/' + campaign.id}">Monitoring</router-link></li>
                <li selected>Live</li>
            </ul>

            <!-- <div slot="title">Performance</div> -->
            <div slot="actions">
              <button class='moduleHeader__createButton' @click.prevent="openModalSettings(campaign.id, null, '3PData')"><font-awesome-icon icon="table" class="i" /> Manual Data Upload</button>
            </div>

          </ModuleHeader>

          <div class="container-fluid" style="background-color: #EDEDED;">
              <h5 v-if="topBannerIsLoading" class="loading" :ref="topBannerIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
              <div class="row">
                  <MetaData v-for="(item, index) in topBannerData" :key="index" v-bind:title="item.title" v-bind:value="item.value | numberWithCommas" v-bind:subValue="item.subValue" v-bind:change="item.change" v-bind:color="bannerBackgroundColor"/>
              </div>
          </div>

          <div class="container-fluid pb-4">
            <div class="row">
              <div class="d-inline">
                  <datepicker v-model="selectedDate" ref="datePicker" placeholder="Start Date"></datepicker>
              </div>
              <div class="d-inline px-2 pt-2">
                  <font-awesome-icon @click="openDatePicker" class="calendar-button-icon" icon="calendar-alt"/>
              </div>
              <div class="d-inline px-2">
                  <select class="custom-select" v-model="placementId">
                    <option value="">Placement</option>
                    <option v-for="(placement, index) in viewFilterData.placements" :key="index" v-bind:value="placement.placementId">{{placement.placementName}}</option>
                  </select>
              </div>
              <div class="d-inline px-2">
                  <button type="button" class="apply-button" v-on:click="getLiveAnalytics">Apply</button>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-3 pb-5">
                  <h3 class="campaign-data-heading">
                      Campaign Data
                  </h3>
              </div>
            </div>
            <div class="row card-border">
              <div class="col-12">
                  <h5 v-if="campaignDataIsLoading" class="loading" ref="campaignDataIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                  <GChart
                      ref="campaignData"
                      type="LineChart"
                      :data="campaignData.chartData"
                      :events="campaignDataChartEvents"
                      :options="campaignDataChartOptions"
                      @ready="onChartReady"
                    />
              </div>
            </div>
            <div class="row card-border mt-5 pt-5">
              <div class="col-6 pb-5">
                <h5 v-if="topDomainsIsLoading" class="loading" ref="topDomainsIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                <h3 class="top-domain-heading pb-5">
                  Top Domains with High Hostile iFrame Incidence
                </h3>
                <div class="top-domain-links" v-for="(item, index) in topDomains.topDomainsWithHighHostileIframeIncidence" :key="index">
                  <span class="pr-5" style="color: #5C5C5C;">{{index + 1}}</span>{{item}}
                </div>
              </div>
              <div class="col-6 pb-5">
                <h5 v-if="topDomainsIsLoading" class="loading" ref="topDomainsIsLoading1"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                <h3 class="top-domain-heading pb-5">
                  Top Domains With Low IAB Viewability
                </h3>
                <div class="top-domain-links" v-for="(item, index) in topDomains.topDomainsWithLowLABViewability" :key="index">
                  <span class="pr-5" style="color: #5C5C5C;">{{index + 1}}</span>{{item}}
                </div>
              </div>
            </div>
            <div class="row children-to-right">
                <div class="pt-5 mr-3">
                  <select class="custom-select" v-model="timeperiod" @change="getBlacklistWhitelist">
                    <option value="">Time Period</option>
                    <option v-for="(period, index) in timePeriods" :key="index" v-bind:value="period.value">{{period.label}}</option>
                  </select>
                </div>
                <div class="pt-4">
                  <button class='moduleHeader__createButton mr-3' @click.prevent="downloadBWList"><font-awesome-icon icon="table" class="i" />Download List</button>
                </div>
            </div>
            <div class="row card-border mt-5 pt-5">
              <div class="col-6 pb-5">
                <h5 v-if="bwListIsLoading" class="loading" ref="bwListIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                <h3 class="top-domain-heading pb-5">
                  Blacklist
                </h3>
                <div class="top-domain-links" v-for="(item, index) in bwlist.Blacklist" :key="index">
                  <span class="pr-5" style="color: #5C5C5C;">{{index + 1}}</span>{{item}}
                </div>
              </div>
              <div class="col-6 pb-5">
                <h5 v-if="bwListIsLoading" class="loading" ref="bwListIsLoading1"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                <h3 class="top-domain-heading pb-5">
                  Whitelist
                </h3>
                <div class="top-domain-links" v-for="(item, index) in bwlist.Whitelist" :key="index">
                  <span class="pr-5" style="color: #5C5C5C;">{{index + 1}}</span>{{item}}
                </div>
              </div>
            </div>
          </div>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          :class="{ modalFullWidth: isModalFullWidth }"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            :campaignId="modalData.campaignId"
            :campaignCeltraId="modalData.campaignCeltraId"
            :campaignIsCeltraSync="modalData.campaignIsCeltraSync"
            :advertiserId="modalData.advertiserId"
            :advertiserName="modalData.advertiserName"
            :campaign="modalData.campaign"
            :currentSectionProp="modalData.currentSectionProp"
            @done="hideModal()"
            @fallbackChanged="onFallbackChanged"
           />
        </b-modal>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import ProfileModalCampaign from '@/views/modals/RepoCampaignProfile.vue'
import ProfileModal3PData from '@/views/modals/RepoManual3PDataUploadProfile.vue'
import WebLinks from '@/components/WebLinks.vue'
import MetaData from '@/components/MetaData.vue'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import Utils from '@/helpers/Utils.js'
import Datepicker from 'vuejs-datepicker'
// import Google charts modules here
import { GChart } from 'vue-google-charts'

export default {
  name: 'TagManagerRepositoryPerformance',
  components: {
    Navbar, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks, ProfileModalCampaign, ProfileModal3PData, GChart, MetaData, Datepicker
  },
  data () {
    return {
      staticBModal: true,
      topBannerIsLoading: true,
      campaignDataIsLoading: true,
      topDomainsIsLoading: true,
      bwListIsLoading: true,
      selectedDate: new Date(),
      placementId: '',
      chartsLib: null,
      topBannerData: [{ title: 'Third Party CTR', value: '--', subValue: 'Campaign Average -', change: 'negative' }, { title: 'Simpli CTR', value: '--', subValue: 'Campaign Average -', change: 'negative' }, { title: 'Simpli Engagement Rate', value: '--', subValue: 'Campaign Average -', change: 'positive' }, { title: 'Celtra Engagement Rate', value: '--', subValue: 'Campaign Average -', change: 'negative' }],
      campaignData: { chartData: [['Hour', 'Impressions', 'Friendly Iframe Impressions (%)', 'IAB Friendly Iframe Impressions (%)', 'IAB Viewable Impressions (%)', 'Secure Impression (%)'], ['AM 12', 0, 0.0, 0.0, 0.0, 0.0], ['1', 0, 0.0, 0.0, 0.0, 0.0], ['2', 0, 0.0, 0.0, 0.0, 0.0], ['3', 0, 0.0, 0.0, 0.0, 0.0], ['4', 0, 0.0, 0.0, 0.0, 0.0], ['5', 0, 0.0, 0.0, 0.0, 0.0], ['6', 0, 0.0, 0.0, 0.0, 0.0], ['7', 0, 0.0, 0.0, 0.0, 0.0], ['8', 0, 0.0, 0.0, 0.0, 0.0], ['9', 0, 0.0, 0.0, 0.0, 0.0], ['10', 0, 0.0, 0.0, 0.0, 0.0], ['11', 0, 0.0, 0.0, 0.0, 0.0], ['12', 0, 0.0, 0.0, 0.0, 0.0], ['1', 0, 0.0, 0.0, 0.0, 0.0], ['2', 0, 0.0, 0.0, 0.0, 0.0], ['3', 0, 0.0, 0.0, 0.0, 0.0], ['4', 0, 0.0, 0.0, 0.0, 0.0], ['5', 0, 0.0, 0.0, 0.0, 0.0], ['6', 0, 0.0, 0.0, 0.0, 0.0], ['7', 0, 0.0, 0.0, 0.0, 0.0], ['8', 0, 0.0, 0.0, 0.0, 0.0], ['9', 0, 0.0, 0.0, 0.0, 0.0], ['10', 0, 0.0, 0.0, 0.0, 0.0], ['11', 0, 0.0, 0.0, 0.0, 0.0], ['12', 0, 0.0, 0.0, 0.0, 0.0]] },
      campaignDataValues: {},
      campaignDataColumns: [],
      campaignDataSeries: {},
      topDomains: { topDomainsWithLowLABViewability: ['No Domains meet the filter critera'], topDomainsWithHighHostileIframeIncidence: ['No Domains meet the filter critera'] },
      viewFilterData: {},
      timeperiod: 1,
      timePeriods: [
        {
          label: 'Last Day',
          value: 1
        },
        {
          label: 'Last 7 Days',
          value: 7
        },
        {
          label: 'Last 15 Days',
          value: 15
        },
        {
          label: 'Last 30 Days',
          value: 30
        },
        {
          label: 'Last 90 Days',
          value: 90
        },
        {
          label: 'Lifetime of Campaign',
          value: -1
        }
      ],
      bwlist: { Blacklist: ['No Domains meet the filter critera'], Whitelist: ['No Domains meet the filter critera'] },
      bannerBackgroundColor: '#EDEDED',
      campaignDataChartOptions: {
        title: '',
        width: '100%',
        height: 500,
        hAxis: { showTextEvery: 1 },
        vAxes: {
          0: {
            viewWindowMode: 'explicit',
            gridlines: { color: 'transparent' }
          },
          1: {
            gridlines: { color: 'transparent' },
            format: '#%'
          },
          2: {
            gridlines: { color: 'transparent' },
            format: '#%'
          }
        },
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 0 },
          2: { targetAxisIndex: 1 }
        },
        colors: ['#1B5F55', '#3BBCA1', '#A0B469', '#F2BF2D', '#3E6990', '#6457A6', '#FF715B', '#2F9B8C', '#6DABE0', '#7E5FFF', '#FF789B', '#70C278', '#87CCE5', '#AE61FF', '#EAA15E', '#8AD622', '#66E2FF', '#F466FF', '#AD7AB4'],
        legend: { position: 'bottom' },
        chartArea: { width: '90%' }
      },
      campaignDataChartEvents: {
        select: () => {
          const chart = this.$refs.campaignData.chartObject
          const sel = chart.getSelection()
          // if selection length is 0, we deselected an element
          if (sel.length > 0) {
            // if row is undefined, we clicked on the legend
            if (sel[0].row === null) {
              const col = sel[0].column
              if (this.campaignDataColumns[col] == col) {
                // hide the data series
                this.campaignDataColumns[col] = {
                  label: this.campaignDataValues.getColumnLabel(col),
                  type: this.campaignDataValues.getColumnType(col),
                  calc: function () {
                    return null
                  }
                }

                // grey out the legend entry
                this.campaignDataSeries[col - 1].color = '#CCCCCC'
              } else {
                // show the data series
                this.campaignDataColumns[col] = col
                this.campaignDataSeries[col - 1].color = null
              }
              this.campaignDataChartOptions.series = this.campaignDataSeries
              const view = new this.chartsLib.visualization.DataView(this.campaignDataValues)
              view.setColumns(this.campaignDataColumns)
              chart.draw(view, this.campaignDataChartOptions)
            }
          }
        }
      },
      mobileFiltersExpanded: false,
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Format Type',
            value: 'formatType'
          },
          {
            label: 'Created Date',
            value: 'placementCreatedDate'
          }
        ]
      },
      breadcrumsData: [
        {
          text: 'Tag Manager',
          href: '/tag-manager'
        },
        {
          text: 'Campaigns',
          href: '/tag-manager/campaigns'
        },
        {
          text: 'Campaign',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        campaignId: null,
        campaignCeltraId: null,
        campaignIsCeltraSync: null,
        advertiserId: null,
        advertiserName: null,
        campaign: null,
        currentSectionProp: null
      },
      contextualMenuExpanded: false,
      campaign: { campaign_source: { id: null } },
      fallbackImages: [],
      isModalFullWidth: false,
      selectAll: false
    }
  },
  methods: {
    onChartReady (chart, google) {
      // now we have google lib loaded. Let's create data table based using it.
      this.chartsLib = google
    },
    ...mapActions('campaigns', {
      replaceCampaign: 'replace',
      fetchCampaignById: 'fetchById'
    }),
    ...mapActions('analytics', {
      fetchLiveAnalyticsTopBanner: 'fetchLiveAnalyticsTopBanner',
      fetchLiveAnalyticsCampaignData: 'fetchLiveAnalyticsCampaignData',
      fetchLiveAnalyticsTopDomains: 'fetchLiveAnalyticsTopDomains',
      fetchFilterData: 'fetchFilterData',
      fetchBlacklistWhitelist: 'fetchBlacklistWhitelist'
    }),
    ...mapActions([
      'fetchCreativeFormats',
      'registerModalOpened',
      'registerModalClosed'
    ]),
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':

          break
        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    openModalSettings: function (itemId, currentSection, modal) {
      const self = this
      // set the modal item id to the userId we want to update
      self.modalData.itemId = itemId

      // for opening fallbacks directly
      self.modalData.currentSectionProp = currentSection

      // self.$log.info('Campaign Id', itemId, this.listCampaign);

      // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCampaign
      const _advertiser = this.advertiserById(this.campaign.advertiser_id)

      self.modalData.advertiserId = _advertiser.id
      self.modalData.advertiserName = _advertiser.name

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = (modal === 'Campaign') ? ProfileModalCampaign : ProfileModal3PData
      this.isModalFullWidth = false

      // show modal
      self.$refs.moduleModal.show()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()
    },
    onFallbackChanged: function (fallbacks) {
      this.fallbackImages = fallbacks.filter(fallback => {
        return fallback.image_url != null
      })
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('M/DD/YY h:mma')

      return (formattedDate != 'Invalid date') ? formattedDate : ''
    },
    populateFilters: function () {
      const self = this
    },
    openDatePicker: function () {
      this.$refs.datePicker.showCalendar()
    },
    getLiveAnalytics: function () {
      this.$store.dispatch('analytics/fetchLiveAnalyticsTopBanner', { id: this.$route.params.id, date: this.selectedDate, placementId: this.placementId })
      this.$store.dispatch('analytics/fetchLiveAnalyticsCampaignData', { id: this.$route.params.id, date: this.selectedDate, placementId: this.placementId })
      this.$store.dispatch('analytics/fetchLiveAnalyticsTopDomains', { id: this.$route.params.id, date: this.selectedDate, placementId: this.placementId })
      this.$store.dispatch('analytics/fetchBlacklistWhitelist', { id: this.$route.params.id })
    },
    getBlacklistWhitelist: function () {
      this.$store.dispatch('analytics/fetchBlacklistWhitelist', { id: this.$route.params.id, timeperiod: this.timeperiod })
    },
    downloadBWList: function () {
      const ax = axios.create({
        // baseURL: process.env.VUE_ANALYTICS_BACKEND_ENDPOINT,
        baseURL: '',
        headers: {
          'x-api-key': process.env.VUE_ANALYTICS_X_API_KEY,
          Authorization: `Bearer ${this.$store.getters.token}`
        }
      })
      ax.get(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_BLACKLIST_WHITELIST}`, {
        params: {
          id: this.$route.params.id,
          timeperiod: this.timeperiod,
          type: 'csv'
        }
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'BW_List.csv') // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  computed: {
    ...mapGetters('campaigns', {
      listCampaign: 'list',
      campaignById: 'itemById'
    }),
    ...mapGetters('analytics', {
      filterData: 'filterData',
      liveAnalyticsTopBanner: 'liveAnalyticsTopBanner',
      liveAnalyticsCampaignData: 'liveAnalyticsCampaignData',
      liveAnalyticsTopDomains: 'liveAnalyticsTopDomains',
      blacklistWhitelist: 'blacklistWhitelist'
    }),
    ...mapGetters([
      'creativeFormats'
    ]),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById'
    })
  },
  destroyed () {
    // set to show preloader
    this.$store.commit('UNSET_DO_NOT_USE_LOADER')
  },
  created () {
    const self = this

    self.fetchCampaignById(this.$route.params.id)
      .then(response => {
        self.campaign = response
        self.fallbackImages = self.campaign.fallbackImages
        self.$log.info(self.campaign)

        // set not to show preloader
        self.$store.commit('SET_DO_NOT_USE_LOADER')

        self.fetchFilterData({ id: this.$route.params.id })
          .then(response2 => {
            self.$log.info(response2)
            self.viewFilterData = response2
            fetchBannerCampaignDomainBWList()
          })
          .catch(err => {
            self.$log.error(err)
            fetchBannerCampaignDomainBWList()
          })
      })

    function fetchBannerCampaignDomainBWList () {
      self.fetchLiveAnalyticsTopBanner({ id: self.$route.params.id, date: self.selectedDate, placementId: self.placementId })
        .then(response3 => {
          self.$log.info(response3)
          self.topBannerData = response3

          self.topBannerIsLoading = false

          fetchCampaignDomainBWList()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.topBannerIsLoading.innerHTML = 'Error'
          fetchCampaignDomainBWList()
        })
    }

    function fetchCampaignDomainBWList () {
      self.fetchLiveAnalyticsCampaignData({ id: self.$route.params.id, date: self.selectedDate, placementId: self.placementId })
        .then(response4 => {
          self.$log.info(response4)
          self.campaignData = response4

          self.campaignDataIsLoading = false

          fetchDomainBWList()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.campaignDataIsLoading.innerHTML = 'Error'
          fetchDomainBWList()
        })
    }

    function fetchDomainBWList () {
      self.fetchLiveAnalyticsTopDomains({ id: self.$route.params.id, date: self.selectedDate, placementId: self.placementId })
        .then(response5 => {
          self.$log.info(response5)
          self.topDomains = response5

          self.topDomainsIsLoading = false

          fetchBWList()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.topDomainsIsLoading.innerHTML = 'Error'
          self.$refs.topDomainsIsLoading1.innerHTML = 'Error'
          fetchBWList()
        })
    }

    function fetchBWList () {
      self.fetchBlacklistWhitelist({ id: self.$route.params.id, date: self.selectedDate, placementId: self.placementId, timeperiod: self.timeperiod })
        .then(response6 => {
          self.$log.info(response6)
          self.bwlist = response6

          self.bwListIsLoading = false
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.bwListIsLoading.innerHTML = 'Error'
          self.$refs.bwListIsLoading1.innerHTML = 'Error'
        })
        .finally(() => {
          // set to show preloader
          self.$store.commit('UNSET_DO_NOT_USE_LOADER')
        })
    }
  },
  watch: {
    listCampaign: function () {
      this.campaign = this.campaignById(this.$route.params.id)
    },
    liveAnalyticsTopBanner: function (val) {
      this.topBannerData = val
    },
    liveAnalyticsCampaignData: function (val) {
      this.campaignData = val
      this.campaignDataValues = this.chartsLib.visualization.arrayToDataTable(this.campaignData.chartData)
      this.campaignDataColumns = []
      this.campaignDataSeries = []

      for (let i = 0; i < this.campaignDataValues.getNumberOfColumns(); i++) {
        this.campaignDataColumns.push(i)
        if (i > 0) {
          this.campaignDataSeries[i] = { targetAxisIndex: 1 }
          // this.campaignDataSeries[i - 1] = {}
        } else {
          this.campaignDataSeries[0] = { targetAxisIndex: 0 }
        }
      }
      this.campaignDataChartOptions.series = this.campaignDataSeries
    },
    liveAnalyticsTopDomains: function (val) {
      this.topDomains = val
    },
    blacklistWhitelist: function (val) {
      this.bwlist = val
    },
    live_analytics: function () {
      // do something with this.live_analytics
    }

  }
}
</script>

<style lang="scss">
  @import "@/scss/common.scss";

  .backgroundDarker{
    background: $gray2 !important;
  }

  .card-border {
      box-shadow: -5px 5px 20px #0000002B;
      border-radius: 10px;
  }

  .campaign-data-heading {
      text-align: left;
      font: Bold 16px/19px Montserrat;
      letter-spacing: 0;
      color: $secondary;
      text-transform: uppercase;
  }

  .top-domain-heading {
    color: $secondary;
    text-align: left;
    font: Bold 18px/22px Montserrat;
  }

  .top-domain-links {
    text-align: left;
    font: 14px/40px Montserrat;
    color: $primary1;
    font-weight: 500;
    border-bottom: 1px solid lightgray;
  }

  .calendar-button-icon {
    cursor: pointer;
    color: #888888;
    font-size: 13px;
  }

  .top-domain-links .pr-5{
    position: relative;
    margin-left: 10px;
    color: white !important;
    font-size: 11px;
    font-weight: 600;
  }

  .top-domain-links .pr-5::after{
    position: absolute;
    z-index: -1 ;
    content: '';
    top: -5px;
    left: -10px;
    background-color: $gray5;
    width: 26px;
    height: 26px;
    border-radius: 20px;
  }

  .card-border{
    padding: 1.5rem;
  }

  .children-to-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

</style>
