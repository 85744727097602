<script setup>
import { ref, computed, onMounted } from 'vue'
import { trim, escape } from 'lodash'

const props = defineProps({
  creativeData: {
    type: Object,
    required: true
  },
  isDragging: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits(['ps-tag-ready', 'has-dco'])

const mockSiteIframe = ref(null)

const tagToRender = computed(() => {
  return btoa(`<iframe style="border: none !important;" srcdoc="&lt;script&gt;var inDapIF = true; &lt;/script&gt; ${trim(escape(props.creativeData.creative_tag))}"></iframe>`)
})

const makePSTagVisible = () => {
  const waitForPsTag = setInterval(() => {
    // TODO: extract to fn if possible
    if (mockSiteIframe.value) {
      const iframeDoc = mockSiteIframe.value.contentDocument || mockSiteIframe.value.contentWindow.document
      if (iframeDoc) {
        const psTag = iframeDoc.getElementsByClassName('ps-tag')[0]
        if (psTag) {
          psTag.style.display = 'block'
          clearInterval(waitForPsTag)
          emit('ps-tag-ready')
          emit('has-dco', hasDCO(psTag))
        }
      }
    }
  }, 1000) // if i don't wait long enough, something reassigns ps-tag.style.display=none
  setTimeout(() => clearInterval(waitForPsTag), 4000)
}

const hasDCO = (el) => {
  const psFrame = el.ownerDocument.getElementById('psFrame')
  if (!psFrame) return false
  const dco = psFrame.contentWindow.__dco
  if (typeof dco !== 'object') return false
  return Object.keys(dco).length > 0
}

onMounted(() => {
  makePSTagVisible()
})
</script>

<template>
  <iframe
    ref="mockSiteIframe"
    class="h-100 w-100"
    :class="{'isDraggingOverIframe': isDragging}"
    style="border: none !important;"
    :src="`/assets/mocksites/creativepreview-redux.html?tag=${tagToRender}`"
  />
</template>

<style>
.isDraggingOverIframe {
  pointer-events: none;
}
</style>