import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { useRouterApi } from '@/composables/useRouterApi'
import SignIn from '@/views/user/SignIn'
import AccessManagement from '@/views/user/AccessManagement'

import CreativeRepository from '@/views/creative-repository/CreativeRepository'
import CreativeRepositoryCreatives from '@/views/creative-repository/CreativeRepositoryCreatives'
import CreativeRepositoryCreative from '@/views/creative-repository/CreativeRepositoryCreativePreview'
import CreativeRepositoryCreativeExtended from '@/views/creative-repository/CreativeRepositoryCreativePreviewExtended'
import CreativePreviewExtendedNew from '@/views/creative-repository/CreativeRepositoryCreativePreviewExtendedNew'
import CreativePreviewNew from '@/views/creative-repository/CreativeRepositoryCreativePreviewNew'
import CreativeRepositoryLists from '@/views/creative-repository/CreativeRepositoryLists'
import CreativeRepositoryList from '@/views/creative-repository/CreativeRepositoryList'
import CreativePreview from '@/views/creative-preview/CreativePreview'

import CreativeProductionRepository from '@/views/creative-production/CreativeProductionRepository'
import CreativeProductionRepositoryCampaigns from '@/views/creative-production/CreativeProductionRepositoryCampaigns'
import CreativeProductionRepositoryCampaignCreatives from '@/views/creative-production/CreativeProductionRepositoryCampaignCreatives'
import TagManagerRepositoryLivePolls from '@/views/tag-manager-repository/CreativeProductionRepositoryLivePolls'

import TagManagerRepository from '@/views/tag-manager-repository/TagManagerRepository'
import TagManagerRepositoryCampaigns from '@/views/tag-manager-repository/TagManagerRepositoryCampaigns'
import TagManagerRepositoryCreatives from '@/views/tag-manager-repository/TagManagerRepositoryCreatives'
import TagManagerRepositoryCampaignsInactive from '@/views/tag-manager-repository/TagManagerRepositoryCampaignsInactive'
import TagManagerRepositoryPlacements from '@/views/tag-manager-repository/TagManagerRepositoryPlacements'
import TagManagerRepositoryPerformance from '@/views/tag-manager-repository/TagManagerRepositoryPerformance'
import TagManagerRepositoryLiveAnalytics from '@/views/tag-manager-repository/TagManagerRepositoryLiveAnalytics'
import TagManagerRepositoryTags from '@/views/tag-manager-repository/TagManagerRepositoryTagGenerator'

import AnalyticsRepository from '@/views/analytics-repository/AnalyticsRepository'
import AnalyticsRepositoryCampaigns from '@/views/analytics-repository/AnalyticsRepositoryCampaigns'
import AnalyticsRepositoryDataLoading from '@/views/analytics-repository/AnalyticsRepositoryDataLoading'
import AnalyticsRepositorySavedReports from '@/views/analytics-repository/AnalyticsRepositorySavedReports'
import AnalyticsRepositoryPulse from '@/views/analytics-repository/AnalyticsRepositoryPulse'
import AnalyticsRepositoryCampaignPacing from '@/views/analytics-repository/AnalyticsRepositoryCampaignPacing'
import AnalyticsRepositoryVidStreamPerformance from '@/views/analytics-repository/AnalyticsRepositoryVidStreamPerformance'
import AnalyticsRepositoryVidStreamPerformance2 from '@/views/analytics-repository/AnalyticsRepositoryVidStreamPerformance2'
import ProductPerformance from '@/views/analytics-repository/ProductPerformance'

import PadSquadCreativeUpload from '@/views/creative-upload/PadSquadCreativeUpload'
import LocationFeederRepository from '@/views/location-feeder/LocationFeederRepository'
import LocationFeederRepositoryFeeds from '@/views/location-feeder/LocationFeederRepositoryFeeds'
import LocationFeederRepositoryFeedBuilder from '@/views/location-feeder/LocationFeederRepositoryFeedBuilder'

import XandrPlacements from '@/views/dsp-reporting/DspReporting.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/sign-in',
      name: 'Sign In',
      component: SignIn,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
        requiresReportingOnly: false
      }
    },
    {
      path: '/creative-studio/',
      name: 'Creative Studio',
      redirect: {
        name: 'Creative Studio Creatives'
      },
      component: CreativeRepository,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        requiresReportingOnly: false,
        showInNavbar: true,
        navbarIcon: 'shapes',
        navbarColorDefault: '#48ceb1'
      },
      children: [
        {
          path: '/creative-studio/creatives/',
          name: 'Creative Studio Creatives',
          component: CreativeRepositoryCreatives,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/creative-studio/creative/:id',
          name: 'Creative Studio Creative',
          component: CreativeRepositoryCreative,
          alias: '/c-old/:id',
          meta: {
            requiresAuth: false,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/creative-studio/creative-extended/:id',
          name: 'Creative Studio Creative Extended',
          component: CreativeRepositoryCreativeExtended,
          alias: '/ce-old/:id',
          meta: {
            requiresAuth: false,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/creative-studio/creative-extended-new/:id',
          name: 'Creative Studio Creative Extended New',
          component: CreativePreviewExtendedNew,
          alias: '/ce/:id',
          meta: {
            requiresAuth: false,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/creative-studio/creative-new/:id',
          name: 'Creative Studio Creative New',
          component: CreativePreviewNew,
          alias: '/c/:id',
          meta: {
            requiresAuth: false,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/creative-studio/lists/',
          name: 'Creative Studio Lists',
          component: CreativeRepositoryLists,
          meta: {
            requiresAuth: false,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/creative-studio/list/:id',
          name: 'Creative Studio List',
          component: CreativeRepositoryList,
          alias: '/l/:id',
          meta: {
            requiresAuth: false,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        }
      ]
    },
    {
      path: '/creative-preview/:id',
      name: 'Creative Preview',
      component: CreativePreview,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
        requiresReportingOnly: false
      }
    },
    {
      path: '/tag-manager/',
      name: 'Tag Manager',
      redirect: {
        name: 'Tag Manager Campaigns'
      },
      component: TagManagerRepository,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        requiresReportingOnly: false,
        showInNavbar: true, // (process.env.VUE_APP_ENV != 'production'),
        navbarIcon: 'fire',
        navbarColorDefault: '#48ceb1',
        requiresNoProduction: false
      },
      children: [
        {
          path: '/tag-manager/campaigns/',
          name: 'Tag Manager Campaigns',
          component: TagManagerRepositoryCampaigns,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/tag-manager/campaigns/inactive',
          name: 'Tag Manager Campaigns Inactive',
          component: TagManagerRepositoryCampaignsInactive,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/tag-manager/creatives',
          name: 'Tag Manager Creatives',
          component: TagManagerRepositoryCreatives,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/tag-manager/placements/campaign/:id',
          name: 'Tag Manager Campaign',
          component: TagManagerRepositoryPlacements,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          },
          props: true
        },
        {
          path: '/tag-manager/live-polls/campaign/:id',
          name: 'Live Polls',
          component: TagManagerRepositoryLivePolls,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          },
          props: true
        }
      ]
    },
    {
      path: '/tag-manager/tags/:campaignId/:placements',
      name: 'Tag Generator',
      alias: '/tg/:campaignId/:placements',
      component: TagManagerRepositoryTags,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
        requiresReportingOnly: false
      }
    },
    {
      path: '/access-management',
      name: 'Access Management',
      component: AccessManagement,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        requiresReportingOnly: false,
        showInNavbar: true,
        navbarIcon: 'users-cog'
      }
    },
    {
      path: '/analytics',
      name: 'Simpli Analytics',
      redirect: {
        name: 'Saved Reports'
      },
      component: AnalyticsRepository,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        requiresReportingOnly: true,
        showInNavbar: true,
        navbarIcon: 'chart-pie',
        navbarColorDefault: '#48ceb1'
      },
      children: [
        {
          path: '/analytics/campaigns/',
          name: 'Campaigns',
          component: AnalyticsRepositoryCampaigns,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: true,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/saved-reports/',
          name: 'Saved Reports',
          component: AnalyticsRepositorySavedReports,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: true,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/performance/campaign/:id',
          name: 'Performance Monitoring',
          component: TagManagerRepositoryPerformance,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: true,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/live/campaign/:id',
          name: 'Live Analytics',
          component: TagManagerRepositoryLiveAnalytics,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/productPerformance/',
          name: 'Product Performance',
          component: ProductPerformance,
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/dataloading/',
          name: 'Data Loading',
          component: AnalyticsRepositoryDataLoading,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/pulse/',
          name: 'Ad Product Performance Database',
          component: AnalyticsRepositoryPulse,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/campaign-pacing/',
          name: 'Campaign Pacing',
          component: AnalyticsRepositoryCampaignPacing,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/vidstream-performance/',
          name: 'VidStream+ Performance',
          component: AnalyticsRepositoryVidStreamPerformance,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        },
        {
          path: '/analytics/vidstream-performance2/',
          name: 'VidStream+ Performance 2',
          component: AnalyticsRepositoryVidStreamPerformance2,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false,
            requiresNoProduction: false
          }
        }
      ]
    },
    {
      path: '/creative-production',
      name: 'Creative Production',
      component: CreativeProductionRepository,
      redirect: {
        name: 'Production Campaigns'
      },
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        requiresReportingOnly: false,
        showInNavbar: true,
        navbarIcon: 'pencil-ruler',
        navbarColorDefault: '#48ceb1'
      },
      children: [
        {
          path: '/creative-production/campaigns/',
          name: 'Production Campaigns',
          component: CreativeProductionRepositoryCampaigns,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/creative-production/creatives/campaign/:id',
          name: 'Production Creatives',
          component: CreativeProductionRepositoryCampaignCreatives,
          meta: {
            requiresAuth: true,
            requiresAdmin: false
          },
          props: true
        },
        {
          path: '/creative-production/creative-upload/:id',
          name: 'PadSquad Creative Upload',
          component: PadSquadCreativeUpload,
          meta: {
            requiresAuth: true,
            requiresAdmin: false
          },
          props: true
        }
      ]
    },
    {
      path: '/tools',
      name: 'Simpli Tools',
      component: LocationFeederRepository,
      redirect: {
        name: 'Location Feeder'
      },
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        requiresReportingOnly: false,
        requiresNoProduction: false,
        showInNavbar: true,
        navbarIcon: 'tools',
        navbarColorDefault: '#48ceb1'
      },
      children: [
        {
          path: '/tools/location-feeder',
          name: 'Location Feeder',
          component: LocationFeederRepository,
          redirect: {
            name: 'Location Feeder Feeds'
          }
        },
        {
          path: '/tools/location-feeder/feeds/',
          name: 'Location Feeder Feeds',
          component: LocationFeederRepositoryFeeds,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/tools/location-feeder/feed-builder/',
          name: 'New Feed Builder',
          component: LocationFeederRepositoryFeedBuilder,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/tools/location-feeder/feed-builder/:id',
          name: 'Edit Feed Builder',
          component: LocationFeederRepositoryFeedBuilder,
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        },
        {
          path: '/tools/xandr-placements',
          name: 'Xandr Placements',
          component: XandrPlacements,
          meta: {
            requiresAuth: false,
            requiresAdmin: false,
            requiresReportingOnly: false
          }
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // if the route existed in the router
  if (to.name) {
    // check authentication
    store.dispatch('checkAuthentication')
      // retrieve logged in user if any
      .then(user => {
        // console.log('user checked', user);
        // console.log('userInSession', localStorage._sUser);

        const loggedIn = true

        // get user in session
        // store.getters['users/userInSession'] || null;
        const userInSession = store.getters.userInSession || null

        return new Promise((resolve, reject) => {
          // if we are logged in and don't have userInSession...
          if (loggedIn && !userInSession && to.meta.requiresAuth) {
            // fetch user info from backend based on the logged in AWS_ID.
            // console.log('router fetch')
            store.dispatch('fetchUserInSessionInfo', user.username)
              .then(userInSession => {
                resolve(userInSession)
              })
              .catch(error => {
                resolve()
              })
          } else {
            resolve()
          }
        })
      })
      // decide where to take the user
      .then(() => {
        return new Promise((resolve, reject) => {
          // get user in session
          const userInSession = store.getters.userInSession

          // if route requires authentication...
          if (to.meta.requiresAuth) {
            // ... and user is in fact logged in
            if (userInSession !== null) {
              // if route requires the user to be an admin
              if (to.meta.requiresAdmin) {
                // ... and user is admin
                // console.log('userInSession', userInSession.role_name)

                if (userInSession.role_name == 'Admin') {
                  // go to route
                  resolve('next')
                } else {
                  // otherwise go to default route
                  resolve('default')
                }
              } else if (to.meta.requiresReportingOnly && userInSession.role_name == 'Reporting Only') {
                if (userInSession.role_name == 'Reporting Only') {
                  // go to route
                  resolve('next')
                }
              } else {
                if (userInSession.role_name == 'Reporting Only') {
                  resolve('reportsOnly')
                }
                // if it does not require admin then just go to route
                resolve('next')
              }
            } else {
              // otherwise just go to route
              reject()
            }
          } else {
            // otherwise just go to route
            resolve('next')
          }
        })
      })
      .then(where => {
        if (where == 'default') {
          next({ name: process.env.VUE_APP_DEFAULT_ROUTE })
        } else if (where == 'reportsOnly') {
          console.log('is reportonly', where)
          next({ name: 'Simpli Analytics' })
        } else {
          // if it's a section only visible for developement and staging enviroments
          if (to.meta.requiresNoProduction) {
            if (process.env.VUE_APP_ENV == 'production') {
              // just go to default route
              next({ name: process.env.VUE_APP_DEFAULT_ROUTE })
            } else {
              // just go to route
              next()
            }
          } else {
            next()
          }
        }
      })
      .catch(error => {
        //  console.log('Error Auth', error);
        // clear localStorage
        localStorage.clear()

        // otherwise go to sign in page
        next({
          name: 'Sign In',
          params: {
            redirectTo: { name: to.name, params: to.params }
          }
        })
      })
  } else {
    // otherwise go to sign in page
    next({ name: process.env.VUE_APP_DEFAULT_ROUTE })
  }
})

const { useRoute, useRouter } = useRouterApi(router)
export { useRoute, useRouter }
export default router
