import _ from 'lodash'
import ApiAnalytics from '@/helpers/ApiAnalytics'

/** VUEX module for Quick View in Tag Generator section **/
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    filterData: {},
    cpmAwarenessTopBannerData: [],
    cpmAwarenessImpressionsData: {},
    cpmAwarenessTopFivePlacementAds: [],
    cpmAwarenessPerformanceDataByDay: [],
    cpmAwarenessViewableImpressionsData: {},
    cpmAwarenessBottomBannerData: [],
    cpmAwarenessBrowserUsageData: [],
    cpmEngagementsUnitEngagementsData: [],
    cpmEngagementsPlacementEffectivenessData: {},
    cpmEngagementsTopFivePlacementsData: {},
    cpmEngagementsCustomEventOccurrencesData: {},
    cpmEngagementsCustomEventOccurrencesMetaData: [],
    cpmEngagementsQuartilePerformance: {},
    cpmEngagementsInlineVideoCompletionRate: {},
    cpmEngagementsInlineVideoCompletionRateMetaData: [],
    liveAnalyticsTopBanner: [],
    liveAnalyticsCampaignData: {},
    liveAnalyticsTopDomains: {},
    adProductDatabase: [],
    blacklistWhitelist: [],
    adProductDatabaseFilters: [],
    jobAnalyticsData: [],
    jobStatus: [
      {
        label: 'Requested',
        value: 'Requested'
      },
      {
        label: 'Pending',
        value: 'Pending'
      },
      {
        label: 'Failed',
        value: 'Failed'
      },
      {
        label: 'Completed',
        value: 'Completed'
      },
      {
        label: 'Paused',
        value: 'Paused'
      }
    ],
    selectedJob: {},
    metrics: [],
    dimensions: [],
    savedReports: [],
    availabilityReports: []
    // performance_awareness: {
    //     // Array will be automatically processed with visualization.arrayToDataTable function
    //     chartData: [
    //         ['Year', 'Sales', 'Expenses', 'Profit'],
    //         ['2013', 600, 300, 200],
    //         ['2014', 1000, 400, 200],
    //         ['2015', 1170, 460, 250],
    //         ['2016', 660, 1120, 300],
    //         ['2017', 1030, 540, 350]
    //     ],
    //     chartOptions: {
    //         chart: {
    //             title: 'Company Performance',
    //             subtitle: 'Sales, Expenses, and Profit: 2014-2017',
    //         },
    //         colors: ['#195D53', '#3CbCA1', '#87CDE5', '#89D623', '#F3BF2C', '#9F9F9F']
    //     },
    // },
    // performance_engagements: {
    //     sankey: {
    //         chartData: [
    //             ['From', 'To', 'Weight'],
    //             ['A', 'X', 5],
    //             ['A', 'Y', 7],
    //             ['A', 'Z', 6],
    //             ['B', 'X', 2],
    //             ['B', 'Y', 9],
    //             ['B', 'Z', 4],
    //           ],
    //           chartOptions: {
    //             chart: {
    //               title: "Company Performance",
    //               subtitle: "Sales, Expenses, and Profit: 2014-2017"
    //             }
    //           },
    //     }
    // },
    // live_analytics:null
  },
  getters: {
    filterData: (state) => {
      return state.filterData
    },
    cpmAwarenessTopBannerData: (state) => {
      return state.cpmAwarenessTopBannerData
    },
    cpmAwarenessImpressionsData: (state) => {
      return state.cpmAwarenessImpressionsData
    },
    cpmAwarenessTopFivePlacementAds: (state) => {
      return state.cpmAwarenessTopFivePlacementAds
    },
    cpmAwarenessPerformanceDataByDay: (state) => {
      return state.cpmAwarenessPerformanceDataByDay
    },
    cpmAwarenessViewableImpressionsData: (state) => {
      return state.cpmAwarenessViewableImpressionsData
    },
    cpmAwarenessBottomBannerData: (state) => {
      return state.cpmAwarenessBottomBannerData
    },
    cpmAwarenessBrowserUsageData: (state) => {
      return state.cpmAwarenessBrowserUsageData
    },
    cpmEngagementsUnitEngagementsData: (state) => {
      return state.cpmEngagementsUnitEngagementsData
    },
    cpmEngagementsPlacementEffectivenessData: (state) => {
      return state.cpmEngagementsPlacementEffectivenessData
    },
    cpmEngagementsTopFivePlacementsData: (state) => {
      return state.cpmEngagementsTopFivePlacementsData
    },
    cpmEngagementsCustomEventOccurrencesData: (state) => {
      return state.cpmEngagementsCustomEventOccurrencesData
    },
    cpmEngagementsCustomEventOccurrencesMetaData: (state) => {
      return state.cpmEngagementsCustomEventOccurrencesMetaData
    },
    cpmEngagementsQuartilePerformance: (state) => {
      return state.cpmEngagementsQuartilePerformance
    },
    cpmEngagementsInlineVideoCompletionRate: (state) => {
      return state.cpmEngagementsInlineVideoCompletionRate
    },
    cpmEngagementsInlineVideoCompletionRateMetaData: (state) => {
      return state.cpmEngagementsInlineVideoCompletionRateMetaData
    },
    liveAnalyticsTopBanner: (state) => {
      return state.liveAnalyticsTopBanner
    },
    liveAnalyticsCampaignData: (state) => {
      return state.liveAnalyticsCampaignData
    },
    liveAnalyticsTopDomains: (state) => {
      return state.liveAnalyticsTopDomains
    },
    adProductDatabase: (state) => {
      return state.adProductDatabase
    },
    blacklistWhitelist: (state) => {
      return state.blacklistWhitelist
    },
    adProductDatabaseFilters: (state) => {
      return state.adProductDatabaseFilters
    },
    getDashBoardData: (state) => {
      return state.jobAnalyticsData
    },
    getMetrics: (state) => state.metrics,
    getDimensions: (state) => state.dimensions,
    getSavedReports: (state) => state.savedReports,
    getSavedReportsScheduled: (state) =>
      state.savedReports.filter((item) => item.frequency !== 'No Schedule'),
    getSavedReportById: (state, getters) => (id) => {
      const report = state.savedReports.find((item) => item.id === id)
      return report
    }
    // performance_awareness:(state) => {
    //     return state.performance_awareness
    // },
    // performance_engagements:(state) => {
    //     return state.performance_engagements
    // },
    // live_analytics:(state, getters, rootState, rootGetters) => {
    //     return state.live_analytics
    // },
  },
  mutations: {
    SET: (state, payload) => {
      state.jobAnalyticsData = payload
      return payload
    },
    SET_BY_ID: (state, payload) => {
      state.selectedJob = payload
    },
    CREATE: (state, payload) => {
      state.jobAnalyticsData.push(...payload)
    },
    UPDATE: (state, payload) => {
      const index = _.findIndex(state.jobAnalyticsData, {
        JobId: payload.JobId
      })
      if (index) {
        state.jobAnalyticsData.splice(index, 1, payload)
      }
    },
    DELETE: (state, payload) => {
      const index = _.findIndex(state.jobAnalyticsData, {
        JobId: payload.JobId
      })
      if (index) {
        state.jobAnalyticsData.splice(index, 1)
      }
    },
    CLEAR: (state, payload) => {
      let index
      _.each(payload, function (eachItem) {
        index = state.jobAnalyticsData.findIndex((x) => x.JobId == eachItem)
        state.jobAnalyticsData.splice(index, 1)
      })
    }, // console.log('clear',payload);
    // state.jobAnalyticsData.map((x)=> {
    // return payload.includes(x.JobId);}) //= myArray.filter( ( el ) => !toRemove.includes( el ) );

    // SET_PERFORMANCE_AWARENESS:(state, payload) => {
    //     state.performance_awareness = payload
    // },
    // SET_PERFORMANCE_ENGAGEMENTS:(state, payload) => {
    //     state.performance_engagements = payload
    // },
    // SET_LIVE_ANALYTICS:(state, payload) => {
    //     state.live_analytics = payload
    // },
    SET_FILTER_DATA: (state, payload) => {
      state.filterData = payload
    },
    SET_CPM_AWARENESS_TOP_BANNER_DATA: (state, payload) => {
      state.cpmAwarenessTopBannerData = payload
    },
    SET_CPM_AWARENESS_IMPRESSIONS_DATA: (state, payload) => {
      state.cpmAwarenessImpressionsData = payload
    },
    SET_CPM_AWARENESS_TOP_FIVE_PLACEMENT_ADS: (state, payload) => {
      state.cpmAwarenessTopFivePlacementAds = payload
    },
    SET_CPM_AWARENESS_PERFORMANCE_DATA_BY_DAY: (state, payload) => {
      state.cpmAwarenessPerformanceDataByDay = payload
    },
    SET_CPM_AWARENESS_VIEWABLE_IMPRESSIONS_DATA: (state, payload) => {
      state.cpmAwarenessViewableImpressionsData = payload
    },
    SET_CPM_AWARENESS_BOTTOM_BANNER_DATA: (state, payload) => {
      state.cpmAwarenessBottomBannerData = payload
    },
    SET_CPM_AWARENESS_BROWSER_USAGE_DATA: (state, payload) => {
      state.cpmAwarenessBrowserUsageData = payload
    },
    SET_CPM_ENGAGEMENTS_UNIT_ENGAGEMENTS_DATA: (state, payload) => {
      state.cpmEngagementsUnitEngagementsData = payload
    },
    SET_CPM_ENGAGEMENTS_PLACEMENT_EFFECTIVENESS_DATA: (state, payload) => {
      state.cpmEngagementsPlacementEffectivenessData = payload
    },
    SET_CPM_ENGAGEMENTS_TOP_FIVE_PLACEMENTS: (state, payload) => {
      state.cpmEngagementsTopFivePlacementsData = payload
    },
    SET_CPM_ENGAGEMENTS_CUSTOM_EVENT_OCCURRENCES: (state, payload) => {
      state.cpmEngagementsCustomEventOccurrencesData = payload
    },
    SET_CPM_ENGAGEMENTS_CUSTOM_EVENT_OCCURRENCES_META_DATA: (
      state,
      payload
    ) => {
      state.cpmEngagementsCustomEventOccurrencesMetaData = payload
    },
    SET_CPM_ENGAGEMENTS_QUARTILE_PERFORMANCE: (state, payload) => {
      state.cpmEngagementsQuartilePerformance = payload
    },
    SET_CPM_ENGAGEMENTS_INLINE_VIDEO_COMPLETION_RATE: (state, payload) => {
      state.cpmEngagementsInlineVideoCompletionRate = payload
    },
    SET_CPM_ENGAGEMENTS_INLINE_VIDEO_COMPLETION_RATE_META_DATA: (
      state,
      payload
    ) => {
      state.cpmEngagementsInlineVideoCompletionRateMetaData = payload
    },
    SET_LIVE_ANALYTICS_TOP_BANNER: (state, payload) => {
      state.liveAnalyticsTopBanner = payload
    },
    SET_LIVE_ANALYTICS_CAMPAIGN_DATA: (state, payload) => {
      state.liveAnalyticsCampaignData = payload
    },
    SET_LIVE_ANALYTICS_TOP_DOMAINS: (state, payload) => {
      state.liveAnalyticsTopDomains = payload
    },
    SET_AD_PRODUCT_DATABASE: (state, payload) => {
      state.adProductDatabase = payload
    },
    SET_BLACKLIST_WHITELIST: (state, payload) => {
      state.blacklistWhitelist = payload
    },
    SET_AD_PRODUCT_DATABASE_FILTERS: (state, payload) => {
      state.adProductDatabaseFilters = payload
    },
    SET_METRICS: (state, payload) => {
      state.metrics = payload
    },
    SET_DIMENSIONS: (state, payload) => {
      state.dimensions = payload
    },
    SET_SAVED_REPORTS: (state, payload) => {
      state.savedReports = payload.map((item) => {
        if (item.update_at === null) item.update_at = item.created_at
        return item
      })
      // console.log(state.savedReports);
    },
    SET_AVAILABILITY_REPORTS: (state, payload) => {
      state.availabilityReports.push(payload)
      // var newObj = {};
      // for (var i in payload) {
      //   newObj[i] = obj[i].replace(',','');
      // }
      // console.log(newObj)
      // state
    },
    CREATE_REPORT: (state, payload) => {
      state.savedReports.push(payload)
    },
    UPDATE_REPORT: (state, payload) => {
      const item = state.savedReports.find((item) => item.id === payload.id)
      Object.assign(item, payload)
    },
    DELETE_REPORT: (state, id) => {
      const index = _.findIndex(state.savedReports, {
        id
      })

      state.savedReports.splice(index, 1)
    }
  },
  actions: {
    fetchFilterData: ({ commit, dispatch }, campaignId) => {
      // app status: loading
      dispatch('registerTask', 'fetchFilterData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getFilterData(campaignId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchFilterData', {
              root: true
            })
            commit('SET_FILTER_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchFilterData', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchCpmAwarenessTopBannerData: ({ commit, dispatch }, campaignId) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmAwarenessTopBannerData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getTopBanner(campaignId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessTopBannerData', {
              root: true
            })
            commit('SET_CPM_AWARENESS_TOP_BANNER_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessTopBannerData', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchCpmAwarenessImpressionsData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      dataSource
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmAwarenessImpressionsData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getImpressions(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          dataSource
        )
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessImpressionsData', {
              root: true
            })
            commit('SET_CPM_AWARENESS_IMPRESSIONS_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessImpressionsData', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchCpmAwarenessTopFivePlacementAdsData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmAwarenessTopFivePlacementAdsData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getTopFivePlacementAds(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmAwarenessTopFivePlacementAdsData',
              {
                root: true
              }
            )
            commit('SET_CPM_AWARENESS_TOP_FIVE_PLACEMENT_ADS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmAwarenessTopFivePlacementAdsData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmAwarenessPerformanceDataByDayData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmAwarenessPerformanceDataByDayData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getPerformanceDataByDay(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmAwarenessPerformanceDataByDayData',
              {
                root: true
              }
            )
            commit('SET_CPM_AWARENESS_PERFORMANCE_DATA_BY_DAY', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmAwarenessPerformanceDataByDayData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmAwarenessViewableImpressionsData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      dataSource
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmAwarenessViewableImpressionsData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getViewableImpressions(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          dataSource
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmAwarenessViewableImpressionsData',
              {
                root: true
              }
            )
            commit('SET_CPM_AWARENESS_VIEWABLE_IMPRESSIONS_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmAwarenessViewableImpressionsData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmAwarenessBottomBannerData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmAwarenessBottomBannerData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getBottomBanner(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId
        )
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessBottomBannerData', {
              root: true
            })
            commit('SET_CPM_AWARENESS_BOTTOM_BANNER_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessBottomBannerData', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchCpmAwarenessBrowserUsageData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmAwarenessBrowserUsageData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getBrowserUsage(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId
        )
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessBrowserUsageData', {
              root: true
            })
            commit('SET_CPM_AWARENESS_BROWSER_USAGE_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmAwarenessBrowserUsageData', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchCpmEngagementsUnitEngagementsData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      dataSource
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmEngagementsUnitEngagementsData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getUnitEngagements(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          dataSource
        )
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmEngagementsUnitEngagementsData', {
              root: true
            })
            commit('SET_CPM_ENGAGEMENTS_UNIT_ENGAGEMENTS_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmEngagementsUnitEngagementsData', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchCpmEngagementsPlacementEffectivenessData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      sortBy,
      dataSource
    ) => {
      // app status: loading
      dispatch(
        'registerTask',
        'fetchCpmEngagementsPlacementEffectivenessData',
        {
          root: true
        }
      )

      return new Promise((resolve, reject) => {
        ApiAnalytics.getPlacementEffectiveness(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          sortBy,
          dataSource
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsPlacementEffectivenessData',
              {
                root: true
              }
            )
            commit('SET_CPM_ENGAGEMENTS_PLACEMENT_EFFECTIVENESS_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsPlacementEffectivenessData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmEngagementsTopFivePlacementsData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      sortBy
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmEngagementsTopFivePlacementsData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getTopFivePlacements(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          sortBy
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsTopFivePlacementsData',
              {
                root: true
              }
            )
            commit('SET_CPM_ENGAGEMENTS_TOP_FIVE_PLACEMENTS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsTopFivePlacementsData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmEngagementsCustomEventOccurrencesData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      sortBy
    ) => {
      // app status: loading
      dispatch(
        'registerTask',
        'fetchCpmEngagementsCustomEventOccurrencesData',
        {
          root: true
        }
      )

      return new Promise((resolve, reject) => {
        ApiAnalytics.getCustomEventOccurrences(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          sortBy
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsCustomEventOccurrencesData',
              {
                root: true
              }
            )
            commit('SET_CPM_ENGAGEMENTS_CUSTOM_EVENT_OCCURRENCES', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsCustomEventOccurrencesData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmEngagementsCustomEventOccurrencesMetaData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId
    ) => {
      // app status: loading
      dispatch(
        'registerTask',
        'fetchCpmEngagementsCustomEventOccurrencesMetaData',
        {
          root: true
        }
      )

      return new Promise((resolve, reject) => {
        ApiAnalytics.getCustomEventOccurrencesMetaData(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsCustomEventOccurrencesMetaData',
              {
                root: true
              }
            )
            commit(
              'SET_CPM_ENGAGEMENTS_CUSTOM_EVENT_OCCURRENCES_META_DATA',
              data
            )
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsCustomEventOccurrencesMetaData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmEngagementsQuartilePerformance: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      dataSource
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmEngagementsQuartilePerformance', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getQuartilePerformance(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          dataSource
        )
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmEngagementsQuartilePerformance', {
              root: true
            })
            commit('SET_CPM_ENGAGEMENTS_QUARTILE_PERFORMANCE', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchCpmEngagementsQuartilePerformance', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchCpmEngagementsInlineVideoCompletionRate: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      dataSource
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchCpmEngagementsInlineVideoCompletionRate', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getInlineVideoCompletionRate(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          dataSource
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsInlineVideoCompletionRate',
              {
                root: true
              }
            )
            commit('SET_CPM_ENGAGEMENTS_INLINE_VIDEO_COMPLETION_RATE', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsInlineVideoCompletionRate',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchCpmEngagementsInlineVideoCompletionRateMetaData: (
      { commit, dispatch },
      campaignId,
      startDate,
      endDate,
      placementId,
      productId,
      dataSource
    ) => {
      // app status: loading
      dispatch(
        'registerTask',
        'fetchCpmEngagementsInlineVideoCompletionRateMetaData',
        {
          root: true
        }
      )

      return new Promise((resolve, reject) => {
        ApiAnalytics.getInlineVideoPerformanceMetaData(
          campaignId,
          startDate,
          endDate,
          placementId,
          productId,
          dataSource
        )
          .then((data) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsInlineVideoCompletionRateMetaData',
              {
                root: true
              }
            )
            commit(
              'SET_CPM_ENGAGEMENTS_INLINE_VIDEO_COMPLETION_RATE_META_DATA',
              data
            )
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch(
              'completeTask',
              'fetchCpmEngagementsInlineVideoCompletionRateMetaData',
              {
                root: true
              }
            )
            reject(error)
          })
      })
    },
    fetchLiveAnalyticsTopBanner: (
      { commit, dispatch },
      campaignId,
      date,
      placementId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchLiveAnalyticsTopBanner', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getLiveAnalyticsTopBanner(campaignId, date, placementId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchLiveAnalyticsTopBanner', {
              root: true
            })
            commit('SET_LIVE_ANALYTICS_TOP_BANNER', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchLiveAnalyticsTopBanner', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchLiveAnalyticsCampaignData: (
      { commit, dispatch },
      campaignId,
      date,
      placementId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchLiveAnalyticsCampaignData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getLiveAnalyticsCampaignData(campaignId, date, placementId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchLiveAnalyticsCampaignData', {
              root: true
            })
            commit('SET_LIVE_ANALYTICS_CAMPAIGN_DATA', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchLiveAnalyticsCampaignData', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchLiveAnalyticsTopDomains: (
      { commit, dispatch },
      campaignId,
      date,
      placementId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchLiveAnalyticsTopDomains', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getLiveAnalyticsTopDomains(campaignId, date, placementId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchLiveAnalyticsTopDomains', {
              root: true
            })
            commit('SET_LIVE_ANALYTICS_TOP_DOMAINS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchLiveAnalyticsTopDomains', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchAdProductDatabase: (
      { commit, dispatch },
      campaignId,
      date,
      placementId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchAdProductDatabase', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getAdProductDatabase(campaignId, date, placementId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchAdProductDatabase', {
              root: true
            })
            commit('SET_AD_PRODUCT_DATABASE', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchAdProductDatabase', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchBlacklistWhitelist: (
      { commit, dispatch },
      campaignId,
      date,
      placementId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchBlacklistWhitelist', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getBlacklistWhitelist(campaignId, date, placementId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchBlacklistWhitelist', {
              root: true
            })
            commit('SET_BLACKLIST_WHITELIST', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchBlacklistWhitelist', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchAdProductDatabaseFilters: (
      { commit, dispatch },
      campaignId,
      date,
      placementId
    ) => {
      // app status: loading
      dispatch('registerTask', 'fetchAdProductDatabaseFilters', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getPerformanceFilters(campaignId, date, placementId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchAdProductDatabaseFilters', {
              root: true
            })
            commit('SET_AD_PRODUCT_DATABASE_FILTERS', data.data)
            resolve(data.data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchAdProductDatabaseFilters', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchDashBoardData: (context) => {
      // app status: loading

      context.dispatch('registerTask', 'fetchDashBoardData', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getDashBoardData()
          .then((data) => context.commit('SET', data.data))
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchDashBoardData', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchDashBoardData', {
              root: true
            })

            reject(error)
          })
      })
    },
    createJob: (context, jobdata) => {
      // app status: loading
      context.dispatch('registerTask', 'createJob', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.createJob(jobdata)
          .then((dataJob) => {
            // app status: ready
            context.commit(
              'CREATE',
              dataJob.map((x) => x.Attributes)
            )

            context.dispatch('completeTask', 'createJob', {
              root: true
            })
            resolve(dataJob)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'createJob', {
              root: true
            })
            reject(error)
          })
      })
    },
    updateJob: (context, jobdata) => {
      // app status: loading
      context.dispatch('registerTask', 'updateJob', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.updateJob(jobdata)
          .then((dataJob) => {
            // app status: ready
            // check if clear multiple jobs :
            if (jobdata.JobStatus == 'Stoped') {
              // check if clearing  multiple jobs
              if (jobdata.JobId != 0) {
                context.commit('DELETE', dataJob.Attributes)
              } else if (jobdata.JobId == 0) {
                // Clear alla selected job fron state
                context.commit('CLEAR', jobdata.Jobs)
              }
            } else {
              context.commit('UPDATE', dataJob.Attributes)
            }

            context.dispatch('completeTask', 'updateJob', {
              root: true
            })
            resolve(dataJob)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'updateJob', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchJobById: (context, jobId) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchJobById', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getJobById(jobId)
          .then((data) => {
            context.commit('SET_BY_ID', data)
            // app status: ready
            context.dispatch('completeTask', 'fetchJobById', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchJobById', {
              root: true
            })

            reject(error)
          })
      })
    },
    // fetchPerformanceAwareness: ({commit, dispatch}, campaignId) => {
    //     // app status: loading
    //     dispatch('registerTask', 'fetchPerformanceAwareness', { root: true });
    //
    //     return new Promise((resolve, reject) => {
    //         ApiAnalytics.getPerformanceAwareness(campaignId)
    //             .then(data => {
    //                 // app status: ready
    //                 dispatch('completeTask', 'fetchPerformanceAwareness', { root: true });
    //                 commit('SET_PERFORMANCE_AWARENESS', data);
    //                 resolve(data);
    //             })
    //             .catch(error => {
    //                 // app status: ready
    //                 dispatch('completeTask', 'fetchPerformanceAwareness', { root: true });
    //
    //                 reject(error);
    //             });
    //     });
    // },
    // fetchPerformanceEngagements: ({commit, dispatch}, campaignId) => {
    //     // app status: loading
    //     dispatch('registerTask', 'fetchPerformanceEngagements', { root: true });
    //
    //     return new Promise((resolve, reject) => {
    //         ApiAnalytics.getPerformanceEngagements(campaignId)
    //             .then(data => {
    //                 // app status: ready
    //                 dispatch('completeTask', 'fetchPerformanceEngagements', { root: true });
    //                 commit('SET_PERFORMANCE_ENGAGEMENTS', data);
    //                 resolve(data);
    //             })
    //             .catch(error => {
    //                 // app status: ready
    //                 dispatch('completeTask', 'fetchPerformanceEngagements', { root: true });
    //
    //                 reject(error);
    //             });
    //     });
    // },
    // fetchLiveAnalytics: ({commit, dispatch}, campaignId) => {
    //     // app status: loading
    //     dispatch('registerTask', 'fetchLiveAnalytics', { root: true });
    //
    //     return new Promise((resolve, reject) => {
    //         ApiAnalytics.getPerformanceEngagements(campaignId)
    //             .then(data => {
    //                 // app status: ready
    //                 dispatch('completeTask', 'fetchLiveAnalytics', { root: true });
    //                 commit('SET_LIVE_ANALYTICS', data);
    //                 resolve(data);
    //             })
    //             .catch(error => {
    //                 // app status: ready
    //                 dispatch('completeTask', 'fetchLiveAnalytics', { root: true });
    //
    //                 reject(error);
    //             });
    //     });
    // },
    fetchMetrictsAndDimensions: ({ commit, dispatch }) => {
      // app status: loading
      dispatch('registerTask', 'fetchMetrictsAndDimensions', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getMetricsAndDimensions()
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchMetrictsAndDimensions', {
              root: true
            })
            commit('SET_METRICS', data.metrics)
            commit('SET_DIMENSIONS', data.dimensions)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchMetrictsAndDimensions', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchMetricts: ({ commit, dispatch }) => {
      // app status: loading
      dispatch('registerTask', 'fetchMetricts', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getMetrics()
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchMetricts', {
              root: true
            })
            commit('SET_METRICS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchMetricts', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchDimensions: ({ commit, dispatch }) => {
      // app status: loading
      dispatch('registerTask', 'fetchDimensions', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getDimensions()
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchDimensions', {
              root: true
            })
            commit('SET_DIMENSIONS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchDimensions', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchSavedReports: ({ commit, dispatch }) => {
      // app status: loading
      dispatch('registerTask', 'fetchSavedReports', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getTemplatesAndSavedReports()
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchSavedReports', {
              root: true
            })
            commit('SET_SAVED_REPORTS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchSavedReports', {
              root: true
            })
            reject(error)
          })
      })
    },
    fetchAvailabilityReports: ({ commit, dispatch }, campaignId) => {
      // app status: loading
      dispatch('registerTask', 'fetchAvailabilityReports', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getDataAvailabilityReport(campaignId)
          .then((data) => {
            // app status: ready
            dispatch('completeTask', 'fetchAvailabilityReports', {
              root: true
            })
            commit('SET_AVAILABILITY_REPORTS', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            dispatch('completeTask', 'fetchAvailabilityReports', {
              root: true
            })
            reject(error)
          })
      })
    },

    createXandrPlacementLog: (context, data) => {
      context.dispatch('registerTask', 'createXandrPlacementLog', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.createXandrPlacementLog(data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'createXandrPlacementLog', {
              root: true
            })
          })
      })
    },

    fetchXandrPlacementLogs: (context, queryString) => {
      context.dispatch('registerTask', 'fetchXandrPlacementLogs', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.getXandrPlacementLogs(queryString)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'fetchXandrPlacementLogs', {
              root: true
            })
          })
      })
    },
    createSavedReport: (context, data) => {
      context.dispatch('registerTask', 'createSavedReport', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.createSavedReport(data)
          .then((response) => {
            context.commit('CREATE_REPORT', response)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'createSavedReport', {
              root: true
            })
          })
      })
    },
    editSavedReport: (context, data) => {
      context.dispatch('registerTask', 'editSavedReport', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.editSavedReport(data)
          .then((response) => {
            context.commit('UPDATE_REPORT', response)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'editSavedReport', {
              root: true
            })
          })
      })
    },
    deleteSavedReport: (context, id) => {
      context.dispatch('registerTask', 'deleteSavedReport', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiAnalytics.deleteSavedReport(id)
          .then((response) => {
            context.commit('DELETE_REPORT', id)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'deleteSavedReport', {
              root: true
            })
          })
      })
    }
  }
}
