<script setup>
import { computed, onBeforeMount, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRoute } from '@/router/index.js'
import { createNamespacedHelpers, useGetters, useActions } from 'vuex-composition-helpers'
import CreativePreviewHeader from '@/components/creative-preview/CreativePreviewHeader.vue'
import CreativePreviewDrawer from '@/components/creative-preview/drawer/CreativePreviewDrawer.vue'
import CreativePreviewInnerContent from '@/components/creative-preview/CreativePreviewInnerContent.vue'
import ApiCreativeProduction from '@/helpers/ApiCreativeProduction'
import debounce from '../../helpers/debounce'

const route = useRoute()
const { useActions: useCreativeActions } = createNamespacedHelpers('creatives')
const { fetchCreativeFormats } = useActions(['fetchCreativeFormats'])
const { getFormatById } = useGetters(['getFormatById'])
const { fetchByPsId } = useCreativeActions(['fetchByPsId'])

onBeforeMount(() => {
  checkWindowSize()
})

onMounted(async () => {
  creativeData.value = await fetchByPsId(creativePsId.value)
  parseDisplayOptions()
  await fetchCreativeFormats()

  creativeData.value.formatName = getFormatById.value(creativeData.value.format_id).name
  await createQr()
  window.addEventListener('resize', checkWindowSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkWindowSize)
})

const creativePsId = computed(() => route.params.id)
const currentUrl = computed(() => {
  return new URL(window.location.href)
})
const urlParams = computed(() => {
  const params = route.query
  for (const key in params) {
    if (params[key] !== 'true' && params[key] !== 'false') continue
    params[key] = params[key] === 'true'
  }
  return params
})
const isMobileScreenSize = ref(false)
const creativeData = ref({})
const mockSiteIframeKey = ref(1)
const isTagReady = ref(false)
const isDragging = ref(false)

const previewSettings = reactive({
  isPortraitOnly: urlParams.value.portraitOnly || false,
  isHorizontalOnly: urlParams.value.horizontalOnly || false,
  isMobileOnly: urlParams.value.mobileOnly || false,
  isDesktopOnly: urlParams.value.desktopOnly || false,
  device: urlParams.value.device || 'mobile',
  landscape: urlParams.value.portraitOnly
    ? false
    : urlParams.value.horizontalOnly
      || urlParams.value.landscape
      || false,
  mobileSize: urlParams.value.mobileSize || 'small',
  forceDrawerState: urlParams.value.forceDrawerState || null,
  hasDCO: false,
  mobileScreen: isMobileScreenSize.value
})

const isDrawerOpen = ref(
  previewSettings.forceDrawerState === 'open'
  || (previewSettings.device === 'mobile' && previewSettings.forceDrawerState !== 'closed')
)

const creativeDataPopulated = computed(() => {
  return Object.keys(creativeData.value).length > 0
})

const openDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value
}
const selectDevice = (name) => {
  isDrawerOpen.value = previewSettings.forceDrawerState === 'open'
  || (name === 'mobile' && previewSettings.forceDrawerState !== 'closed')
  modifyUrlParams('set', 'device', name)
}
const refreshCreative = () => {
  mockSiteIframeKey.value += 1
}
const setHasDCO = (val) => {
  previewSettings.hasDCO = val
}

const inDisplayOptions = (val) => {
  if (!creativeData.value.displayOptions) return false
  return creativeData.value.displayOptions.includes(val)
}

const parseDisplayOptions = () => {
  if (!creativeData.value.displayOptions || typeof creativeData.value.displayOptions === String) return

  const tabletPortrait = inDisplayOptions('Tablet Portrait')
  const tabletLandscape = inDisplayOptions('Tablet Landscape')
  const desktop = inDisplayOptions('Desktop')
  if (desktop && creativeData.value.displayOptions.length === 1) {
    previewSettings.isDesktopOnly = true
    selectDevice('desktop')
  }

  if (isMobileScreenSize.value) {
    selectDevice('desktop')
  }

  if (!tabletLandscape && !tabletPortrait && !desktop && !isMobileScreenSize.value) {
    previewSettings.isMobileOnly = true
    selectDevice('mobile')
  }
}

const checkWindowSize = () => {
  isMobileScreenSize.value = document.body.clientWidth < 768
  if (isMobileScreenSize.value) selectDevice('desktop')
}

const modifyUrlParams = (action, label, val) => {
  if (action === 'delete') {
    currentUrl.value.searchParams.delete(label)
  } else {
    currentUrl.value.searchParams.set(label, val)
  }
  window.history.replaceState({}, '', currentUrl.value.href)
  previewSettings[label] = val
  debounceCreateQr()
}

const createQr = async () => {
  const res = await ApiCreativeProduction.createQR({ url: window.location.href })
  creativeData.value = {
    ...creativeData.value,
    qr_code: res.qr
  }
}

const debounceRefresh = debounce(() => refreshCreative(), 500)
const debounceCreateQr = debounce(() => createQr(), 500)
</script>

<template>
  <div class="h-100 d-flex flex-column w-100" style="max-width: 100vw;">
    <CreativePreviewHeader
      v-if="!isMobileScreenSize"
      :creative-data="creativeData"
      :is-drawer-open="isDrawerOpen"
      :preview-settings="previewSettings"
      @open-drawer="openDrawer"
      @select-device="selectDevice"
      @refresh-creative="debounceRefresh"
    />
    <div class="w-100 d-flex flex-grow-1 overflow-hidden">
      <div class="h-100 flex-grow-1" style="position: relative;">
        <CreativePreviewInnerContent
          v-if="creativeDataPopulated"
          :creative-data="creativeData"
          :mock-site-iframe-key="mockSiteIframeKey"
          :preview-settings="previewSettings"
          :is-dragging="isDragging"
          @refresh-creative="debounceRefresh"
          @modify-url-params="modifyUrlParams"
          @ps-tag-ready="() => isTagReady = true"
          @has-dco="setHasDCO"
        />
      </div>
      <!-- Sidebar -->
      <div
        v-show="isDrawerOpen"
        :style="`
          position: ${previewSettings.mobileScreen ? 'absolute': 'relative'};
          right: ${previewSettings.mobileScreen ? '0px' : ''};
        `"
        class="h-100"
      >
        <CreativePreviewDrawer
          :creative-data="creativeData"
          :preview-settings="previewSettings"
          :is-tag-ready="isTagReady"
          :mock-site-iframe-key="mockSiteIframeKey"
          :is-dragging="isDragging"
          @update-dragging="(val) => isDragging = val"
          @modify-url-params="modifyUrlParams"
          @refresh-creative="debounceRefresh"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
