import axios from 'axios'

const ApiUser = function () {
  /**
     * creates a new user
     * @param {object} userData - user data to be stored.
     */
  this.createUser = userData => {
    return new Promise((resolve, reject) => {
      // console.log(userData)
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_CREATE_POST_CONFIRMATION}`, userData)
        .then(response => {
          // console.log(response)
          if (response.data.message == 'The request has succeeded.') {
            resolve(userData)
          } else {
            reject(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /**
     * creates a new user by invitation and in backend perform AWS adminCreateUser
     * @param {object} userData - user data to be stored.
     */
  this.inviteUser = userData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_INVITE_USER}`, userData)
        .then(response => {
          if (response.data.message == 'The request has succeeded.') {
            resolve(userData)
          } else {
            reject(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /**
     * creates a new user by invitation and in backend perform AWS adminCreateUser
     * @param {object} userData - user data to be stored.
     */
  this.migrateUser = userData => {
    // console.log('mydata',userData)
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_MIGRATE_USER}`, userData)
        .then(response => {
          if (response.data.message == 'The request has succeeded.') {
            resolve(userData)
          } else {
            reject(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /**
     * reinvite new user by invitation and in backend perform AWS adminCreateUser
     * @param {object} userData - user data to be stored.
     */
  this.reInviteUser = userData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_REINVITE_USER}`, userData)
        .then(response => {
          // console.log(response)
          if (response.data.message == 'The request has succeeded.' || response.message == 'The request has succeeded.') {
            resolve(userData)
          } else {
            reject(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * updates a users info
     * @param {object} userData - user updated data to be stored.
     */
  this.updateUser = userData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_UPDATE_POST_CONFIRMATION}`, userData)
        .then(response => {
          // console.log(response)
          if (response.data.message == 'The request has succeeded.') {
            resolve(userData)
          } else {
            reject(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * updates a users role
     * @param {object} userData - user updated data to be stored.
     */
  this.updateUserRole = userData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_UPDATE_ROLE}`, userData)
        .then(response => {
          // console.log(response)
          if (response.data.message == 'The request has succeeded.') {
            resolve(userData)
          } else {
            reject(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  this.getUser = AWS_ID => {
    return new Promise((resolve, reject) => {
      // console.log('AWS_ID',AWS_ID)
      axios.get(`${process.env.VUE_APP_ENDPOINT_USER_BY_AWS_ID}${AWS_ID}`)
        .then(response => {
          if (response.data.message == 'The request has succeeded.') {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * delete a  user
     * @param {object} userID - user id to be deleted.
     */
  this.deleteUser = userID => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_DELETE}${userID}`)
        .then(response => {
          if (response.data.message == 'The request has succeeded.') {
            // console.log(userID, response.data)
            resolve(response.data.data)
            // resolve(userData);
          } else {
            reject(response)
          }
        })
        .catch(reject)
    })
  }

  /**
     * returns the list of all users from incognito
     */
  this.getUsersList = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_USER_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * set user status confirmed
     */
  this.setUserStatus = (aws_user_id) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_POST_STATUS}${aws_user_id}/confirmed`)
        .then(response => {
          if (response.data.message == 'The request has succeeded.') {
            resolve(response.data.data)
          } else {
            reject(response)
          }
        })
        .catch(reject)
    })
  }

  /**
     * returns list of roles
     */
  this.getUsersRoles = () => {
    return new Promise((resolve, reject) => {
      resolve([{ id: 1, text: 'Admin' },
        { id: 2, text: 'Marketing' },
        { id: 3, text: 'Sales' },
        { id: 4, text: 'Reporting Only' }])
    })
  }
  /**
     * returns list of accounts
     */
  this.getUsersAccounts = () => {
    return new Promise((resolve, reject) => {
      resolve([{ id: 1, text: 'PadSquad' }])
    })
  }
  /**
     * returns list of user statuses
     */
  this.getUsersStatus = () => {
    return new Promise((resolve, reject) => {
      resolve(['Active', 'Suspended'])
    })
  }

  this.gethealthcheck = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_USER_HEALTH_CHECK}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
}

export default new ApiUser()
