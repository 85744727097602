<template>
  <section class="sModal">
    <Preloader :in-modal="true"></Preloader>

    <header class="sModal__header">
      {{ itemId != null ? 'Live Poll ID Generator' : 'New Live Poll' }}
    </header>

    <form @submit.prevent="formSubmit">
      <nav class="sModal__tabs">
        <ul>
          <li :selected="currentSection === 'details'">
            <button @click.prevent="currentSection = 'details'">Details</button>
          </li>
          <li
            v-if="editMode"
            :selected="currentSection === 'stats'"
          >
            <button @click.prevent="currentSection = 'stats'">Stats</button>
          </li>
        </ul>
      </nav>
      <div
        id="bodypolls"
        class="sModal__body"
      >
        <div v-if="currentSection === 'details'">
          <!-- Name -->
          <FormField
            label="Live Poll Name *"
            for-id="livePoll-name"
          >
            <input
              id="livePoll-name"
              v-model="livePoll.name"
              type="text"
              placeholder="Type your live poll name"
              required
            />
          </FormField>
          <!-- Questions -->
          <div
            v-for="(question, qIndex) in livePoll.questions"
            :key="qIndex"
          >
            <FormField
              :label="`Question ${qIndex + 1}`"
              class="mt5"
            >
              <div class="listBox">
                <ul>
                  <li class="fullWidth">
                    <div class="listBoxOneField noBorderBottom">
                      <div class="formFieldGroup listBox__label">
                        <div class="formFieldGroup__fields">
                          <FormField :for-id="'question' + qIndex">
                            <input
                              :id="'question' + qIndex"
                              v-model="question.question"
                              type="text"
                              placeholder="Type your question"
                              required
                            />
                          </FormField>
                        </div>
                      </div>

                      <div
                        class="edit-button"
                        tabindex="-1"
                      >
                        <button
                          :disabled="editMode"
                          tabindex="-1"
                          @click.prevent=""
                        >
                          <font-awesome-icon
                            icon="ellipsis-h"
                            class="i"
                            tabindex="-1"
                          />
                        </button>
                      </div>
                      <div
                        class="buttons-row"
                        tabindex="-1"
                      >
                        <button
                          v-if="livePoll.questions.length > 1 && !editMode"
                          tabindex="-1"
                          @click.prevent="removeQuestion(qIndex)"
                        >
                          <font-awesome-icon
                            icon="trash-alt"
                            class="i"
                          />
                        </button>
                        <button
                          v-if="
                            qIndex === livePoll.questions.length - 1 &&
                            !editMode
                          "
                          class="button--positive"
                          tabindex="-1"
                          @click.prevent="addQuestion()"
                        >
                          <font-awesome-icon
                            icon="plus-circle"
                            class="i"
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                  <!-- Answers -->
                  <li
                    v-for="(option, oIndex) in question.options"
                    :key="`option-${qIndex}-${oIndex}`"
                    class="fullWidth"
                  >
                    <div class="listBoxOneField noBorderBottom">
                      <div class="formFieldGroup listBox__label">
                        <FormField
                          :tag-left="`Answer ${oIndex + 1}`"
                          :for-id="'option' + qIndex + '-' + oIndex"
                        >
                          <input
                            :id="'option' + qIndex + '-' + oIndex"
                            v-model="option.option"
                            type="text"
                            placeholder="Type your answer"
                            required
                          />
                        </FormField>
                      </div>

                      <div
                        v-if="
                          question.options.length > 2 ||
                          oIndex === question.options.length - 1
                        "
                        class="edit-button"
                        tabindex="-1"
                      >
                        <button
                          :disabled="editMode"
                          tabindex="-1"
                          @click.prevent=""
                        >
                          <font-awesome-icon
                            icon="ellipsis-h"
                            class="i"
                            tabindex="-1"
                          />
                        </button>
                      </div>
                      <div
                        class="buttons-row"
                        tabindex="-1"
                      >
                        <button
                          v-if="question.options.length > 2 && !editMode"
                          tabindex="-1"
                          @click.prevent="removeAnswer(qIndex, oIndex)"
                        >
                          <font-awesome-icon
                            icon="trash-alt"
                            class="i"
                          />
                        </button>
                        <button
                          v-if="
                            oIndex === question.options.length - 1 && !editMode
                          "
                          class="button--positive"
                          tabindex="-1"
                          @click.prevent="addAnswer(qIndex)"
                        >
                          <font-awesome-icon
                            icon="plus-circle"
                            class="i"
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </FormField>
          </div>
        </div>
        <div v-if="currentSection === 'stats'">
          <div
            v-for="(question, qIndex) in livePoll.questions"
            :key="qIndex"
          >
            <span class="question-label mb-3">{{
              `Question ${qIndex + 1}`
            }}</span>
            <div class="wrapperBox">
              <h4 class="mb-3">{{ question.question }}</h4>
              <div
                v-for="(option, oIndex) in question.options"
                :key="oIndex"
                class="mb-2"
              >
                <span class="votes-label"
                  >{{ option.option }} ({{ option.vote_count }} votes)</span
                >
                <ProgressBar
                  :value="calculatePercentage(option.vote_count, qIndex)"
                ></ProgressBar>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button
            class="cancelButton"
            @click.prevent="cancel"
          >
            <font-awesome-icon
              icon="ban"
              class="i"
            />Cancel
          </button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Save Live Poll' if this not a new live Poll -->
          <button
            class="saveButton"
            :disabled="isFormValid || !isDataModified"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />
            {{ itemId != null ? 'Save Live Poll' : 'Create Live Poll' }}
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Preloader from '@/components/Preloader.vue'
import Multiselect from 'vue-multiselect'
import ProgressBar from '@/components/ProgressBar.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'RepoProductionLivePollProfile',
  components: {
    FormField,
    Preloader,
    Multiselect,
    ProgressBar
  },
  props: {
    itemId: null
  },
  data() {
    return {
      showToast: false,
      loading: true,
      livePoll: {
        name: '',
        questions: []
      },
      originalLivePoll: null,
      editMode: false,
      currentSection: 'details'
    }
  },
  methods: {
    ...mapActions('livePolls', ['fetchLivePollById', 'create', 'update']),
    cancel: function () {
      // emit event
      this.$emit('done')
    },
    addQuestion: function () {
      this.livePoll.questions.push({
        question: '',
        options: [{ option: '' }, { option: '' }]
      })
    },
    removeQuestion: function (qIndex) {
      this.livePoll.questions.splice(qIndex, 1)
    },
    addAnswer: function (qIndex) {
      this.livePoll.questions[qIndex].options.push({ option: '' })
    },
    removeAnswer: function (qIndex, oIndex) {
      this.livePoll.questions[qIndex].options.splice(oIndex, 1)
    },
    calculatePercentage: function (voteCount, questionIndex) {
      const totalVotes = this.livePoll.questions[questionIndex].options.reduce(
        (acc, option) => acc + option.vote_count,
        0
      )
      return totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0
    },
    showAlerts: function (type, message, icon) {
      this.$swal({
        position: 'top',
        title: type,
        html: `<h3>${message}</h3>`,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: icon,
        toast: false
      }).then(() => {
        this.$emit('done')
      })
    },
    formSubmit: function () {
      let payload = {}
      // if editMode, then update existing live poll
      if (this.editMode) {
        payload = {
          id: this.itemId,
          name: this.livePoll.name,
          updated_at: new Date().toISOString(),
          questions: this.livePoll.questions.map((question) => ({
            question_id: question.question_id,
            question: question.question,
            options: question.options.map((option) => ({
              option_id: option.option_id,
              option: option.option
            }))
          }))
        }

        this.update(payload)
          .then((response) => {
            this.$emit('pollCreated')
            this.showAlerts(
              'Success!',
              'Live Poll updated successfully.',
              'success'
            )
          })
          .catch((error) => {
            console.log(error)
            this.showAlerts(
              'Error!',
              'Failed updating Live Poll. Please try again.',
              'error'
            )
          })
      } else {
        // create new live poll
        payload = JSON.parse(JSON.stringify(this.livePoll))
        payload.questions = payload.questions.map((question) => {
          return {
            question: question.question,
            type: 'single',
            options: question.options.map((option) => {
              return {
                option: option.option
              }
            })
          }
        })
        this.create(payload)
          .then((response) => {
            this.$emit('pollCreated')
            this.showAlerts(
              'Success!',
              'Live Poll created successfully.',
              'success'
            )
          })
          .catch((error) => {
            this.showAlerts(
              'Error!',
              'Failed creating Live Poll. Please try again.',
              'error'
            )
          })
      }
    },
    populateForm: async function () {
      // if itemId is passed, then is a existing live poll
      if (this.itemId) {
        this.editMode = true
        try {
          const response = await this.fetchLivePollById(this.itemId)
          this.livePoll.name = response.name
          this.livePoll.questions = response.questions.map((q) => ({
            question_id: q.question_id,
            question: q.question,
            options: q.options.map((o) => ({
              option_id: o.option_id,
              option: o.option,
              vote_count: o.vote_count
            }))
          }))
          this.originalLivePoll = JSON.parse(JSON.stringify(this.livePoll))
        } catch (error) {
          console.error('Error fetching poll data:', error)
          this.loading = false
        }
      } else {
        this.livePoll = {
          name: '',
          questions: [
            {
              question: '',
              options: [{ option: '' }, { option: '' }]
            }
          ]
        }
        this.originalLivePoll = null
        this.loading = false
      }
    }
  },
  created() {
    this.populateForm()
  },
  computed: {
    ...mapGetters('livePolls', ['pollById']),
    isFormValid: function () {
      if (!this.livePoll.name.trim()) return true

      for (let question of this.livePoll.questions) {
        if (!question.question.trim()) return true
        for (let option of question.options) {
          if (!option.option.trim()) return true
        }
      }
      return false
    },
    isDataModified: function () {
      if (!this.originalLivePoll) return true
      return (
        JSON.stringify(this.livePoll) !== JSON.stringify(this.originalLivePoll)
      )
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';

.fullWidth {
  display: grid !important;
}

.buttons-row {
  position: absolute;
  z-index: 200;
  top: 15px;
  right: 15px;
  transition: all 0.3s ease-out;
  opacity: 0;
  // pointer-events: none;
}

.buttons-row button {
  float: left;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  margin-left: 0 !important;
}

.edit-button {
  display: block;
}

.buttons-row:hover {
  opacity: 1;
  z-index: 200;
  // pointer-events: auto;
}

.buttons-row:hover button {
  margin-left: $spacing-unit !important;
}

.edit-button button {
  pointer-events: none;
}

#bodypolls .formField__field > input[type='text']:disabled,
textarea:disabled {
  background: #fff !important;
  cursor: not-allowed;
}

.show :hover + .copy-event {
  visibility: visible;
}
.copy-event :hover {
  visibility: visible;
}
.copy-icon {
  pointer-events: auto;
}
.removed-events {
  color: #3bbca1;
}
.remove-event-trash {
  margin-top: 2.5rem;
}
.defaultLabel {
  position: absolute;
  font-size: 1rem;
}
.form-label {
  text-transform: uppercase;
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
  min-height: 1.5rem;
}

.l-red {
  color: #f00 !important;
}

.error-message {
  color: red;
  // font-size: 14px;
  margin-top: 5px;
  display: inline-block;
}

.sModal__tabs li button {
  padding: 1.2em 3em;
}

.question-label {
  text-transform: uppercase;
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
  min-height: 1.5rem;
}
.votes-label {
  display: block;
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  min-height: 1.5rem;
}
.wrapperBox {
  padding: 1.5rem 2.5rem;
  border: 0.5px solid #dee2e6;
  margin-bottom: 1rem;
}

.progress {
  height: 3rem;
  font-size: 2rem;
}
.progreess-bar {
  margin-top: 1rem;
  background-color: #3bbca1;
}
</style>
