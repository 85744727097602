<template>
  <section class="sModal">
    <div class="loader" :class="{'done': (!loading)}">
      <font-awesome-icon icon="spinner" class="spinner"/>
    </div>

    <header class="sModal__header">
      It's simple!
    </header>

      <div class="sModal__body">
          <ol class="ol-steps">
              <li>Select the website where you would like to preview the creative.</li>
              <li>Optionally, to preview the fallback version of the creative just mark the SAFE FRAME checkbox
                  and select the correspondent Unit Size.</li>
              <li>Click on the [OPEN PREVIEW PAGE] button.</li>
          </ol>

          <p>Note that not all of the creatives are meant to be cross-screen or work on every safe frame size.</p>

          <p>The safe frame size for all in-line creatives like Scrollers or VidTiles is only 300x250, while for
              floating units like Brandscape or Adhesion can be 320x50, 728x90 or 970x90.</p>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__right">
          <button class="cancelButton" @click.prevent="cancel"> Close</button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>

import FormField from '@/components/FormField.vue'
import Utils from '@/helpers/Utils.js'

export default {
  name: 'QuickViewModal',
  props: {

  },
  components: {
    FormField
  },
  data () {
    return {
      loading: true
    }
  },
  methods: {
    cancel: function () {
      const self = this

      // emit event
      self.$emit('done')
    },
    populateForm: function () {
      const self = this
      self.loading = false
    }
  },
  created () {
    const self = this

    // populate form
    self.populateForm()
  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/common.scss";

  .ol-steps{
    margin-bottom: 2em;
  }

  ol li{
    counter-increment: list;
    list-style-type: none;
    position: relative;
    margin-bottom: 0.7em;
    font-size: 14px;
  }

  ol li:before {
    font-weight: 600;
    content: counter(list) ".";
    left: -19px;
    position: absolute;
    text-align: left;
  }
</style>
