<template>
    <div class="product production" :class="[{ isSelected: selected }, { itemDisabled : disabled }]" @click.prevent="select()">
        <product-info :title=title :selected=selected >
            <template slot="body">
                <div class="decription mb1">
                    <div><slot name="description"></slot></div>
                </div>
                <div class="decription align-end">
                    <!-- <p class="powered">Powered by: <a href="https://www.airtory.com/" target="_blank">Airtory</a></p> -->
                    <h4>Build type:</h4>
                    <FormField :forId="'build'+title" type="select" v-if="!isPadSquadCampaign">
                        <select v-model="type" :id="'build'+title" @change="buildTypeChanged" required>
                            <!-- <option value="Simpli Template" disabled>Simpli Template</option> -->
                            <option v-for="(build, index) in _.orderBy(buildAirtoryTypes, 'id','desc')" :value="build.name" :key="index">{{build.name}}</option>
                        </select>
                    </FormField>
                    <FormField :forId="'build'+title" type="text" v-else>
                        <select disabled v-model="type" :id="'build'+title" required>
                            <option :value="padSquadBuildType.name">{{padSquadBuildType.name}}</option>
                        </select>
                    </FormField>
                </div>
            </template>
        </product-info>
    </div>
</template>

<script>
import ProductInfo from '@/components/ProductInfo.vue'
import FormField from '@/components/FormField.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Production',
  components: { ProductInfo, FormField },
  props: {
    title: null,
    selected: false,
    disabled: true,
    isPadSquadCampaign: Boolean
  },
  data () {
    const typeBasedOnCampaign = () => {
      return this.isPadSquadCampaign ? 'PadSquad Creative Upload' : 'Airtory Creative Upload';
    }
    return {
      type: typeBasedOnCampaign()
    }
  },
  computed: {
    ...mapGetters(['buildAirtoryTypes']),

     padSquadBuildType: function () {
      return {
        name: 'PadSquad Creative Upload',
        id: 4
      }
    }

  },
  mounted () {
    this.isPadSquadCampaign && this.$emit('buildType', { name: 'PadSquad Creative Upload', id: 4 });
  },
  methods: {
    showInfo: function (type) {
      this.$log.info(`Show Info ${type}`)
    },
    select: function () {
      // this.selected = true;
      this.$emit('selected', this.title)
      console.log('selected')
    },
    unselect: function () {
      // this.selected = false;
    },
    buildTypeChanged: function () {
      const buildType = this.buildAirtoryTypes.find(item => item.name === this.type)
      // this.$log.info(`Build type ${this.type} ${buildType.id}`);
      this.$emit('buildType', { name: this.type, id: buildType.id })
    }
  }
}
</script>

<style lang="scss">
    @import "@/scss/common.scss";

    .product{
        // width: 32%;
        // max-width: 240px;
        border: 2px solid $gray5;
        cursor: pointer;
        pointer-events: auto;
        // margin-bottom: $spacing-unit;
        float: left;
        user-select: none;

        .formField {
            margin-bottom: 0 !important;
        }
    }

    .isSelected{
        border: 2px solid $primary1;
        pointer-events: none;

        a, select{
            pointer-events: auto;
        }
    }

    .itemDisabled{
        pointer-events: none;
        opacity: 0.5;
        user-select: none;
    }

    .production {
        .product-body{
            position: relative;
            display: flex;
            // min-height: 240px; // with powered div
            min-height: 220px;
            justify-content: space-between;
            flex-direction: column;
        }
    }

    .powered{
        font-size: 1.1rem;
        margin-bottom: $spacing-unit;

        a{
            font-size: 1.1rem;
        }
    }

</style>
