<template>

    <div class="appFrame">
        <main class="moduleFrame moduleFrame--signIn" :class="{'heightSignUp': formToShow == 'SignUp'}">
            <div class="box">
                <div class="loader" :class="{'done': (!loading)}">
                  <font-awesome-icon icon="spinner" class="spinner"/>
                </div>

                <div class="box__inner">
                    <div class="box__imagePanel"></div>

                    <!-- login -->
                    <div v-show="formToShow == 'SignIn'" class="box__formPanel">
                        <b-alert :show="loginError" fade variant="danger">{{loginErrorMsg}}</b-alert>
                        <b-alert :show="resetSent" fade variant="success">{{resetMsg}}</b-alert>

                        <form @submit.prevent="validateUser()">
                            <img class="box__brand" src="@/assets/simpli-logo.svg" />

                            <FormField label="Email" forId="signin-email">
                                <input v-model="form.email" id="signin-email" type="email" required />
                            </FormField>

                            <FormField label="Password" forId="signin-password">
                                <input v-model="form.password" id="signin-password" type="password" required/>
                                <small class="formField__small--checkbox">
                                    <input type="checkbox" class="form-check-input" id="signin-keep" v-model="form.staySignIn">
                                    <label for="signin-keep">Stay signed in</label>
                                </small>
                            </FormField>

                            <div class="box__formPanel__actions">
                                <button class="box__formPanel__actions__sign_in_cta"><font-awesome-icon icon="sign-in-alt" class="i" />SIGN IN</button>
                                <a v-on:click.prevent="gotoForgotPassword()" href="">Forgot your password?</a>
                            </div>
                            <!-- <div class="box__formPanel__actions">
                                <a v-on:click.prevent="setFormToShow('SignUp')" href="" class="ml-5">Sign Up</a>
                            </div> -->
                        </form>
                    </div>

                    <!-- forgot password  -->
                    <div v-show="formToShow == 'ForgotPassword'" class="box__formPanel">
                        <b-alert :show="resetSent" fade variant="success">{{resetMsg}}</b-alert>
                        <b-alert :show="resetFail" fade variant="danger">{{resetMsg}}</b-alert>

                        <form v-on:submit.prevent>

                            <img class="box__brand" src="@/assets/simpli-logo.svg" />

                            <FormField  label="Email" forId="forgotpassword-email">
                                <input v-model="form.email" id="forgotpassword-email" type="email" required />
                            </FormField>

                            <FormField label="New Password" forId="forgotpassword-password"  v-show="verificationState"  fade>
                                <input v-model="form.password" id="forgotpassword-password" type="password"  @keyup="checkPolicy(form.password)"/>
                            </FormField>

                            <FormField  label="Verification Code" forId="forgotpassword-code" v-show="verificationState"  fade>
                                <input v-model="form.code" id="forgotpassword-code" type="text" @keyup="checkMatch(form.password, form.code)"/>
                            </FormField>

                            <div v-show="verificationState" class="pass-policy">
                               <h5 class="policy" :class="{ correct : hasMinimumLength }">Minimum characters: 8</h5>
                               <h5 class="policy" :class="{ correct : hasNumber}">Number required</h5>
                               <h5 class="policy" :class="{ correct : hasUppercase}">Uppercase required</h5>
                               <h5 class="policy" :class="{ correct : hasLowercase}">Lowercase required</h5>
                               <h5 class="policy" :class="{ correct : passwordsMatches}">Verification code</h5>
                            </div>

                            <div class="box__formPanel__actions">
                                <button v-show="!verificationState" fade v-on:click="localForgotPassword(form)" class="box__formPanel__actions__reset_cta"><font-awesome-icon icon="user-lock" class="i" />RESET</button>
                                <button v-show="verificationState" fade v-on:click="localConfirmForgotPassword(form)" class="box__formPanel__actions__reset_cta"><font-awesome-icon icon="user-lock" class="i" />CONFIRM</button>
                                <a v-on:click.prevent="setFormToShow('SignIn')" href="">Back to login</a>
                            </div>

                        </form>
                    </div>
                    <!-- change password  -->
                    <div v-show="formToShow == 'ChangePassword'" class="box__formPanel">
                        <b-alert :show="resetSent" fade variant="success">{{resetMsg}}</b-alert>
                        <b-alert :show="resetFail" fade variant="danger">{{resetMsg}}</b-alert>

                        <form v-on:submit.prevent>

                            <img class="box__brand" src="@/assets/simpli-logo.svg" />

                            <FormField  label="Email" forId="changepassword-email">
                                <input v-model="form.email" id="changepassword-email" type="email" required />
                            </FormField>

                            <FormField label="Old Password" forId="changepassword-old_password" fade>
                                <input v-model="form.old_password" id="changepassword-old_password" type="password"  @keyup="checkPolicy(form.old_password)"/>
                            </FormField>

                            <FormField label="New Password" forId="changepassword-password" fade>
                                <input v-model="form.password" id="changepassword-password" type="password"  @keyup="checkPolicy(form.password)"/>
                            </FormField>

                            <div class="pass-policy">
                               <h5 class="policy" :class="{ correct : hasMinimumLength }">Minimum characters: 8</h5>
                               <h5 class="policy" :class="{ correct : hasNumber}">Number required</h5>
                               <h5 class="policy" :class="{ correct : hasUppercase}">Uppercase required</h5>
                               <h5 class="policy" :class="{ correct : hasLowercase}">Lowercase required</h5>
                            </div>

                            <div class="box__formPanel__actions">
                                <button fade v-on:click="changePassword(form)" class="box__formPanel__actions__reset_cta" :disabled="!hasPassedAllPolicy"><font-awesome-icon icon="user-lock" class="i" />CHANGE PASSWORD</button>
                                <a v-on:click.prevent="setFormToShow('SignIn')" href="">Back to login</a>
                            </div>

                        </form>
                    </div>

                    <!-- confirm  signup  -->
                    <div v-show="formToShow == 'Confirm'" class="box__formPanel">
                        <b-alert :show="resetSent" fade variant="success">{{resetMsg}}</b-alert>
                        <b-alert :show="resetFail" fade variant="danger">{{resetMsg}}</b-alert>

                        <form v-on:submit.prevent>

                            <img class="box__brand" src="@/assets/simpli-logo.svg" />

                            <FormField  label="Email" forId="confirm-email">
                                <input v-model="form.email" id="confirm-email" type="email" required />
                            </FormField>

                            <FormField  label="Verification Code" forId="confirm-code" fade>
                                <input v-model="form.code" id="confirm-code" type="text" @keyup="checkMatch(form.password, form.code)"/>
                            </FormField>

                            <div class="box__formPanel__actions">
                                <button fade @click="confirmSignUp(form)" class="box__formPanel__actions__reset_cta" :disabled="!hasPassedAllPolicy"><font-awesome-icon icon="user-lock" class="i" />CONFIRM</button>
                                <a @click.prevent="resendSignUpCode(form.email)" href="">Resend code</a>
                            </div>

                        </form>
                    </div>

                    <!-- New Password Required-->
                    <div v-show="formToShow == 'SignUp' || formToShow == 'CompleteNewPassword'" class="box__formPanel">

                        <b-alert :show="resetSent" fade variant="success">{{resetMsg}}</b-alert>
                        <b-alert :show="resetFail" fade variant="danger">{{resetMsg}}</b-alert>

                        <form v-on:submit.prevent>

                            <img class="box__brand" src="@/assets/simpli-logo.svg" />

                            <FormField  v-show="formToShow == 'SignUp'" label="Email" forId="complete-email">
                                <input v-model="form.email" id="complete-email" type="email" />
                            </FormField>

                             <!-- <FormField v-show="formToShow == 'SignUp'" label="Password" forId="password"  fade>
                                <input v-model="form.password" id="password" type="password" @keyup="checkPolicy(form.password)"/>
                            </FormField> -->

                             <FormField v-show="formToShow == 'CompleteNewPassword'" label="New Password" forId="newpassword-password"  fade>
                                <input v-model="form.password" id="newpassword-password" type="password" @keyup="checkPolicy(form.password)"/>
                            </FormField>

                            <FormField v-show="formToShow == 'CompleteNewPassword'" label="Confirm Password" forId="newpassword-confirmPassword"  fade>
                                <input v-model="form.confirmPassword" id="newpassword-confirmPassword" type="password" @keyup="checkMatch(form.password, form.confirmPassword)"/>
                            </FormField>

                            <FormField v-show="formToShow == 'SignUp'" label="Name *" forId="name"  fade>
                                <input v-model="form.name" id="name" type="text" required/>
                            </FormField>

                            <FormField v-show="formToShow == 'SignUp'" label="Last Name *" forId="last-name"  fade>
                                <input v-model="form.last_name" id="last-name" type="text" required/>
                            </FormField>

                            <FormField v-show="formToShow == 'SignUp'" label="Phone Number *" forId="phone-number"  fade>
                                <input v-model="form.phone_number" id="phone-number" type="text" required/>
                            </FormField>

                            <FormField v-show="formToShow == 'SignUp'" label="Account *" forId="user-accountName" type="select">
                                <select v-model="form.account_type" id="user-accountName" @change="accountTypeChange" required>
                                    <option value="null" disabled>Select one</option>
                                    <option v-for="(account, index) in accounts_type" :key="index">{{account.text}}</option>
                                </select>
                            </FormField>

                            <FormField v-show="formToShow == 'SignUp'" label="Role *" forId="user-roleName" type="select">
                                <select v-model="form.role_type" id="user-roleName" @change="roleTypeChange" required>
                                    <option value="null" disabled>Select one</option>
                                    <option v-for="(role, index) in roles_type" :key="index">{{role.text}}</option>
                                </select>
                            </FormField>

                            <div v-show="formToShow == 'CompleteNewPassword'" class="pass-policy">
                               <h5 class="policy" :class="{ correct : hasMinimumLength }">Minimum characters: 8</h5>
                               <h5 class="policy" :class="{ correct : hasNumber}">Number required</h5>
                               <h5 class="policy" :class="{ correct : hasUppercase}">Uppercase required</h5>
                               <h5 class="policy" :class="{ correct : hasLowercase}">Lowercase required</h5>
                               <h5 class="policy" :class="{ correct : passwordsMatches}">Confirm password matches</h5>
                            </div>

                            <div class="box__formPanel__actions">
                                <button  v-show="formToShow == 'CompleteNewPassword'" v-on:click="validateBeforeSignUp(form)" class="box__formPanel__actions__reset_cta" :disabled="!hasPassedAllPolicy"><font-awesome-icon icon="user-lock" class="i" />CHANGE PASSWORD</button>
                                <!-- <button  v-show="formToShow == 'SignUp'" v-on:click="validateBeforeSignUp(form)" class="box__formPanel__actions__reset_cta" :disabled="!hasPassedAllPolicy"><font-awesome-icon icon="user-lock" class="i" />SIGN UP</button> -->
                                <a v-on:click.prevent="setFormToShow('SignIn')" href="">Back to login</a>
                            </div>

                        </form>

                    </div>
                </div>

            </div>

        </main>

    </div>

</template>

<script>
import FormField from '@/components/FormField.vue'
import axios from 'axios'
// import Api from '@/helpers/Api.js';
import Api from '@/helpers/Api.js'
import ApiUser from '@/helpers/ApiUser.js'
import Amplify from 'aws-amplify'
import Auth from '@aws-amplify/auth'
import { mapGetters, mapActions } from 'vuex'
// axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_X_API_KEY // for all requests

export default {
  name: 'SignIn',
  data () {
    return {
      email: null,
      password: null,
      lostEmail: null,
      newPassword: null,
      confirmPassword: null,
      verificationCode: null,
      verificationState: false,
      loginError: false,
      loginErrorMsg: null,
      resetSent: false,
      resetMsg: null,
      resetFail: false,
      authSession: null,
      currentState: 'signIn',
      passwordPolicy: {
        minimumLength: 8,
        requireNumber: true,
        requireUpprcase: true,
        requireLowercase: true
      },
      hasMinimumLength: false,
      hasNumber: false,
      hasUppercase: false,
      hasLowercase: false,
      passwordsMatches: false,
      hasPassedAllPolicy: false,
      /// ///////////////
      form: {
        username: null,
        email: null,
        password: null,
        old_password: null,
        confirmPassword: null,
        name: null,
        last_name: null,
        phone_number: null,
        account_type: null,
        role_type: null,
        staySignIn: false,
        code: null
      },
      show: true,
      isCodeConfirmation: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'userInfo',
      'confirm',
      'loading',
      'forgotPasswordSubmit',
      'message',
      'accounts_type',
      'roles_type',
      'formToShow',
      'wasSent',
      'gotError',
      'userInSession'
    ])
  },
  methods: {
    ...mapActions([
      'signIn',
      'signUp',
      'signUpByInvitation',
      'confirmSignUp',
      'resendSignUpCode',
      'signOut',
      'forgotPassword',
      'confirmForgotPassword',
      'changePassword',
      'completeNewPassword',
      'setMessage',
      'setFormToShow'
    ]),
    accountTypeChange: function (value) {
      // console.log('value', value, this.form.account_type)
      // this.form.account_type = value;
    },
    roleTypeChange: function (value) {
      // this.form.role_type = value;
    },
    onReset: function () {
      this.form.email = null
      this.form.password = null
      this.form.old_password = null
      this.form.name = null
      this.form.last_name = null
      this.form.phone_number = null
      this.form.account_type = null
      this.form.role_type = null
      this.form.code = null

      this.setMessage('')

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    validateBeforeSignUp: function (form) {
      if (this.formToShow == 'SignUp') {
        this.signUp(form)
        // this.signUpByInvitation(form);
      } else if (this.formToShow == 'CompleteNewPassword') {
        this.completeNewPassword(form)
      }
    },
    gotoForgotPassword: function () {
      const self = this
      self.loginError = false
      this.verificationState = false

      self.setFormToShow('ForgotPassword')
    },
    gotoSignIn: function () {
      const self = this

      self.loginError = false
      self.setFormToShow('SignIn')
    },
    validateUser: function () {
      try {
        const self = this
        // console.log('data ','data')
        self.signIn(self.form)
        // turn on spinner
        // self.loading = true;

        // ... hide alert
      } catch (error) {
        console.log('error catch', error)
      }
    },
    ///
    localForgotPassword (form) {
      this.forgotPassword(form)
      this.verificationState = true
    },
    ///
    localConfirmForgotPassword (form) {
      this.confirmForgotPassword(form)
      this.verificationState = false
    },
    checkPolicy (value) {
      const pass = String(value)

      this.hasMinimumLength = pass.length >= this.passwordPolicy.minimumLength
      this.hasNumber = pass.match('.*\\d.*')
      this.hasUppercase = pass.match('.*[A-Z].*')
      this.hasLowercase = pass.match('.*[a-z].*')

      // if pass and confirm matches
      this.passwordsMatches = false

      if (this.hasMinimumLength && this.hasNumber && this.hasUppercase && this.hasLowercase) {
        this.hasPassedAllPolicy = (this.currentState != 'newPasswordRequired')
      } else {
        this.hasPassedAllPolicy = false
      }
    },
    checkMatch (value, value2) {
      const pass = String(value)
      const confirmPass = String(value2)

      if (this.formToShow == 'CompleteNewPassword' || this.formToShow == 'SignUp') {
        // if pass and confirm matches
        this.passwordsMatches = (pass === confirmPass)
      } else {
        // in this case confirm pass is verificationCode instead
        this.passwordsMatches = (/^\d+$/.test(confirmPass) && confirmPass.length == 6)
        // console.log('pass code ' + this.passwordsMatches);
      }

      if (this.hasMinimumLength && this.hasNumber && this.hasUppercase && this.hasLowercase && this.passwordsMatches) {
        this.hasPassedAllPolicy = true
      } else {
        this.hasPassedAllPolicy = false
      }
    },
    setRegionAuth (region) {
      switch (region) {
        case 'us-east-1':
          console.log('EAST')
          Amplify.configure({
            Auth: {
              // REQUIRED - Amazon Cognito Identity Pool ID,
              identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID,
              // REQUIRED - Amazon Cognito Region
              region: process.env.VUE_APP_AUTH_REGION,
              // OPTIONAL - Amazon Cognito Federated Identity Pool Region
              // Required only if it's different from Amazon Cognito Region
              identityPoolRegion: process.env.VUE_APP_AUTH_IDENTITY_POOL_REGION,
              // OPTIONAL - Amazon Cognito User Pool ID
              userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,
              // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
              userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID

            }
          })
          break
        case 'us-west-2':
          // console.log('WEST')
          Amplify.configure({
            Auth: {
              // REQUIRED - Amazon Cognito Identity Pool ID,
              identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID_WEST,
              // REQUIRED - Amazon Cognito Region
              region: process.env.VUE_APP_AUTH_REGION_WEST,
              // OPTIONAL - Amazon Cognito Federated Identity Pool Region
              // Required only if it's different from Amazon Cognito Region
              identityPoolRegion: process.env.VUE_APP_AUTH_IDENTITY_POOL_REGION_WEST,
              // OPTIONAL - Amazon Cognito User Pool ID
              userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID_WEST,
              // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
              userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID_WEST

            }
          })
          break
        default:
          // console.log('EAST DEFAULT')
          /*      Amplify.configure({
                Auth: {
                    // REQUIRED - Amazon Cognito Identity Pool ID,
                    identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID,
                    // REQUIRED - Amazon Cognito Region
                    region: process.env.VUE_APP_AUTH_REGION,
                    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
                    // Required only if it's different from Amazon Cognito Region
                    identityPoolRegion: process.env.VUE_APP_AUTH_IDENTITY_POOL_REGION,
                    // OPTIONAL - Amazon Cognito User Pool ID
                    userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,
                    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                    userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID

                },}); */

          break
      }
      //
      if (localStorage.reload_dr == 'yes' || localStorage.reload_dr == undefined) {
        localStorage.reload_dr = 'no'
        window.location.reload(true)
      }
    }

  },
  components: {
    FormField
  },
  watch: {
    password: (value, oldValue) => { },
    message: function () {
      this.resetSent = this.wasSent
      this.loginError = this.resetFail = this.gotError
      this.resetMsg = this.loginErrorMsg = this.message
    },
    userInSession: function () {
      const self = this

      self.$log.info('user in session', this.userInSession)
      localStorage._sUser = JSON.stringify(this.userInSession)

      this.$swal({
        position: 'top-end',
        title: `Welcome ${this.userInSession.name} ${this.userInSession.last_name}!`,
        showConfirmButton: false,
        showDenyButton: false,
        showCancelButton: false,
        toast: true,
        icon: 'success',
        timer: 6000,
        timerProgressBar: true
      })

      // if we landed here because we were trying to get to go to a module
      if (self.$route.params.redirectTo) {
        // ... then, navigate to that url
        self.$router.push(self.$route.params.redirectTo)
      } else {
        // otherwise just go to the default module
        self.$router.push({ name: process.env.VUE_APP_DEFAULT_ROUTE })
      }
    }
  },
  created () {
    ApiUser.gethealthcheck() // pass true for dummy data
      .then(data => {
        console.log('dataregion', data.region)
        if (data.region == 'us-west-2') {
          console.log('WEST IDLC')
          localStorage.current_region = data.region
          this.setRegionAuth(data.region)
          this.$log.info(data.region)
        } else if (data.region == 'us-east-1') {
          console.log('EAST IDLC')
          this.setRegionAuth(data.region)
          localStorage.current_region = data.region
        }
      })
      .catch(error => {
        console.log('Some error', error)
        /*       setTimeout(() => {
                          window.location.reload(true)
                        }, 10); */
        /*         this.setRegionAuth('us-west-2');
                localStorage.current_region ='us-west-2' */
        /*   console.log('WEST REGION',error)
                console.log('west pool',process.env.VUE_APP_AUTH_IDENTITY_POOL_ID_WEST) */
      })
  }
}
</script>

<style lang="scss">
@import "@/scss/common.scss";

    html,
    body,
    .appFrame,
    #app {
        height: 100%;
    }

    .pass-policy{
        margin:0 0 20px;
    }

    .policy{
        color:$gray5;
    }

    .correct{
        color:$green1;
    }

    .box__formPanel__actions {
        margin-bottom: 7px;
    }

    .moduleFrame.moduleFrame--signIn {
        @include vertical-align();

        top: 0;
        // height: 100vh;
        height: 100vh;
        padding: 40px 0;
        background: white;

        @include media-breakpoint-up(md) {
            display: block;
            background: $signin-background;
            width: 100%;
            left: 0px;
        }
    }

    .heightSignUp{
        height: auto !important;
    }

    .box {
        background-color: white;
        text-align: left;
        max-width: 750px;
        position: relative;

        &__inner {
            width: 100%;
            display: flex;
            // max-height: 100vh;
            max-height: auto;
            -webkit-transition: max-height 0.2s; /* Safari */
            transition: max-height 0.2s;

        }

        &__imagePanel {
            position: relative;
            background-position: center;
            background-color: $green5;
            background-image: url(https://source.unsplash.com/collection/2254180);
            background-size: cover;
            width: 0px;
            border-radius: $roundness 0 0 $roundness;
            text-align: center;

            &:after {
                content: 'Colorful Collection by Unsplash';
                color: white;
                background: rgba(0,0,0,0.5);
                padding: 10px;
                font-size: 0.5rem;
                position: absolute;
                bottom: 1rem;
                left: 0;
            }

        }

        &__formPanel {
            @extend .container-fluid;

            flex-grow: 1;
            border-radius: 0 $roundness $roundness 0;

            &__actions {

                &__sign_in_cta {
                    @extend .button;
                    @extend .button--positive;

                    display: table;
                    margin-bottom: $spacing-unit;
                }

                &__reset_cta {
                    @extend .button;

                    display: table;
                    margin-bottom: $spacing-unit;
                }

            }
        }

        &__brand {
            height: 4.5rem;
            margin-bottom: $spacing-unit*4;
        }

        .alert {
            position: absolute;
            width: 100%;
            left: 0px;
            top: 0;
            margin-bottom: 0px;
        }

        // on tablet and up
        @include media-breakpoint-up(md) {
            @include box-shadow(0px 3px 6px rgba(black, 0.16));

            border-radius: $roundness;

            &__imagePanel {
                width: 310px;
            }

            &__formPanel {
                position: relative;

                &__actions {
                    &__sign_in_cta,
                    &__reset_cta {
                        float: right;
                        margin-top: -1rem;
                    }
                }
            }

            .alert {
                border-radius: 0 $roundness 0 0;
            }
        }
    }
</style>
