<template>
  <section class="sModal">
    <div class="loader" :class="{'done': (!loading)}">
      <font-awesome-icon icon="spinner" class="spinner"/>
    </div>

    <header v-if="itemId" class="sModal__header">{{creative.name}}</header>
    <header v-else class="sModal__header">New Creative</header>

    <form @submit.prevent="formSubmit">

      <div class="sModal__body">
        <FormField label="Add Creative To Lists" forId="filter-search" type="search">
          <input v-model="filterData.search" id="filter-search" type="text" placeholder="Search Lists..." />
        </FormField>

        <FormField>
          <div class="listBox">
            <ul>
              <li v-for="(list) in filteredLists" :key="list.id" :class="{hidden: !list.userCanAddTo}">
                <div class="listBox__label">
                  {{list.name}}
                </div>
                <div>
                  <!-- the checkbox v-module is the .checked property in the lists array.
                  There may be a better approach, but, for this I had to find the index of the current
                  list form the lists array by it's id property. So pretty processor intense in my opinion. -->
                  <input type="checkbox" :disabled="!list.userCanAddTo" v-model="lists[_.indexOf(lists, _.find( lists, {id:list.id}))].checked" />
                </div>
              </li>
            </ul>
          </div>
        </FormField>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" /> Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Save New Creative' if this is new creative and we are on the last screen-->
          <button v-bind:disabled="!isCreativeModified" class="saveButton" @click="saveData"><font-awesome-icon icon="check" class="i" />Save</button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'
import { mapGetters } from 'vuex'

export default {
  name: 'AddToList',
  components: {
    FormField
  },
  props: {
    itemId: null
  },
  data () {
    return {
      loading: true,
      filterData: {
        searchBox: ''
      },
      creative: {},
      creativeFetchedData: {},
      lists: []
    }
  },
  methods: {
    formSubmit: function () {},
    isDataModified: function () {
      const self = this

      return _.isEqual(self.creative, self.creativeFetchedData)
    },
    saveData: function () {
      const self = this

      const creative = _.cloneDeep(self.creative)

      // turn on loading spinner
      self.loading = true

      const postModel = {
        creativeId: creative.id,
        lists: _.filter(creative.lists, list => { return list.status != 'removed' })
      }

      // *** there's a promise issue... after we fix the promise issue we will move this to the success state of the updatecreativeLists
      self.$emit('done')

      // then update the user
      Api.updateCreativeLists(postModel)
        .then(response => {
          // turn off loading spinner
          self.loading = false

          // emit event
          // self.$emit('save-update-success', response);
        })
        .catch(error => {
          // turn off loading spinner
          self.loading = false

          alert('There was a problem updating this creative. Please try again.')
        })
    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('done')
    },
    mapCheckedList: function (theLists) {
      const self = this

      var theLists = theLists

      return new Promise(function (resolve, reject) {
        // iterate over the list of lists
        _.forOwn(theLists, (list, key) => {
          // if the current position exists in the creative.lists array then mark it as checked
          theLists[key].checked = (_.findIndex(self.creative.lists, { id: list.id }) != -1)
          theLists[key].userCanAddTo = self.userInSession.id == list.user_id || list.private != 1 || self.userInSession.role == 'Admin'
        })

        resolve(theLists)
      })
    },
    populate: function () {
      const self = this

      // fetch user data
      Api.getCreative(self.itemId, false, true)
        .then(creative => {
          self.$log.info('creative', creative)

          // store the data as it comes from the server. This to compare it later before saving.
          self.creativeFetchedData = _.cloneDeep(creative)

          // fill the form
          self.creative = _.cloneDeep(creative)
        })
        .then(Api.getListsList)
        .then(self.mapCheckedList)
        .then(lists => {
          self.lists = lists

          // turn off loading spinner
          self.loading = false
        })
        .catch(error => {
          // turn off loading spinner
          self.loading = false
          self.$log.error(error)
        })
    }
  },
  created () {
    const self = this

    self.populate()
  },
  computed: {
    ...mapGetters([
      'userInSession'
    ]),
    isCreativeModified: function () {
      const self = this

      return !_.isEqual(self.creative, self.creativeFetchedData)
    },
    filteredLists: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(self.lists, self.filterData.search)

      return searchedItems
    }
  },
  watch: {
    /* when the filtered list has any change */
    filteredLists: function () {
      const self = this

      // iterate over all lists
      _.forOwn(self.lists, (list, key) => {
        const listIndexInCreativeLists = _.findIndex(self.creative.lists, { id: list.id })
        const listIndexInOriginalCreativeLists = _.findIndex(self.creativeFetchedData.lists, { id: list.id })

        // if the list exists in creative.lists...
        if (listIndexInCreativeLists > -1) {
          // ... and if the list is checked...
          if (list.checked) {
            // ... then make it active
            self.creative.lists[listIndexInCreativeLists].status = 'active'
          } else {
            // ... if it's not checked, and the creative was originally in the in creative.lists
            if (listIndexInOriginalCreativeLists > -1) {
              // ...just set it as removed, because we want to send it to the api on save.
              self.creative.lists[listIndexInCreativeLists].status = 'removed'
            } else {
              // ... otherwise just remove it from the creative.lists because there's no purpose on having it there.
              self.creative.lists.splice(listIndexInCreativeLists, 1)
            }
          }
        } else {
          // ...if it's not in the list
          // ...and the list's status is checked, then add it to creative.lists
          if (list.checked) {
            self.creative.lists.push({
              id: list.id,
              status: 'active'
            })
          }
        }
      })
    },
    lists: {
      handler: function () {
        const self = this

        self.creative.lists = _.map(_.filter(self.lists, list => { return list.checked }), list => {
          return {
            id: list.id
          }
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/common.scss";
</style>
