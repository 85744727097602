import _ from 'lodash'
import ApiTagManager from '@/helpers/ApiTagManager'

/** VUEX module for placements in Tag Manager section **/

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    advertisers: [],
    advertiser: null
  },
  // -----------------------------------------------------------------
  getters: {
    list: (state, getters, rootState, rootGetters) => {
      return _.keyBy(state.advertisers, 'id')
    },
    itemById: (state, getters) => (id) => {
      const item = state.advertisers.find(item => item.id == id)

      return item
    },
    itemByName: (state, getters) => (name) => {
      const item = state.advertisers.find(item => item.name == name)

      return item
    },
    listAdvertisersArray: (state) => {
      return state.advertisers
    },
    selectedAdvertiser: (state) => {
      return state.advertiser
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET: (state, payload) => {
      state.advertisers = payload
    },
    UPDATE: (state, payload) => {
      const index = _.findIndex(state.advertisers, {
        id: payload.id
      })

      state.advertisers.splice(index, 1, payload)
    },
    CREATE: (state, payload) => {
      state.advertisers.push(payload)
    },
    DELETE: (state, id) => {
      const index = _.findIndex(state.advertisers, {
        id
      })

      state.advertisers.splice(index, 1)
    },
    SET_ADVERTISER: (state, payload) => {
      state.advertiser = payload
    }
  },
  // -----------------------------------------------------------------
  actions: {
    fetch: (context) => {
      // app status loading
      context.dispatch('registerTask', 'fetchAdvertisers', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getAdvertisersList()
          .then(data => context.commit('SET', data))
          .then(data => {
            context.dispatch('completeTask', 'fetchAdvertisers', { root: true })
            resolve(data)
          })
          .catch(error => {
            context.dispatch('completeTask', 'fetchAdvertisers', { root: true })

            reject(error)
          })
      })
    },
    create: (context, advertiserData) => {
      // app status loading
      context.dispatch('registerTask', 'createAdvertiser', { root: true })
      return new Promise((resolve, reject) => {
        ApiTagManager.createAdvertiser(advertiserData)
          .then(data => {
            context.commit('CREATE', data)
            context.dispatch('completeTask', 'createAdvertiser', { root: true })
            resolve(data)
          })
          .catch(error => {
            context.dispatch('completeTask', 'createAdvertiser', { root: true })

            reject(error)
          })
      })
    },
    update: (context, advertiserData) => {
      // console.log('update', advertiserData);

      // app status: loading
      context.dispatch('registerTask', 'updateAdvertiser', { root: true })

      return new Promise((resolve, reject) => {
        context.commit('UPDATE', advertiserData)
        context.dispatch('completeTask', 'updateAdvertiser', { root: true })
        resolve(advertiserData)
      })
    },
    delete: (context, id) => {

    },
    setAdvertiser: ({ commit }, advertiserData) => {
      return new Promise((resolve, reject) => {
        commit('SET_ADVERTISER', advertiserData)
        resolve(advertiserData)
      })
    }
  }
}
