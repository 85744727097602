<template>
  <section class="sModal">
    <Preloader :in-modal="true"></Preloader>

    <header
      v-if="currentSection !== 'build'"
      class="sModal__header"
    >
      {{
        creative.id === null || itemId === null ? 'New Creative:' : 'Creative:'
      }}
      {{ creative.name }}
    </header>
    <header
      v-if="currentSection === 'build'"
      class="sModal__header"
    >
      <button
        class="backButton"
        @click="backFromBuild()"
      >
        <font-awesome-icon
          icon="arrow-left"
          class="i"
        /></button
      >{{
        getCurrentSectionsGroup === sectionsDetails ? 'Edit' : 'New'
      }}
      Creative: {{ creative.name }}
    </header>

    <form @submit.prevent="formSubmit">
      <nav
        v-if="currentSection !== 'build'"
        class="sModal__tabs"
      >
        <ul>
          <!--
            Workaround:
            Each tab requires validations. I wanted to use the native validation. So...
            I am using buttons for the tabs so it triggers the form submittion. This also changes the toSection var.
            The idea is that when the formSubmit method is called it checks against the toSection var, if it has value then
            it will change currentSection to toSection value, so the tab changes. If the form is submitted with
            no toSection value (e.g. when the save creative button is hit), then it will just call saveData.
          -->
          <!-- CREATIVE DETAILS -->
          <li
            v-if="getCurrentSectionsGroup === sectionsDetails"
            :selected="currentSection == 'details'"
          >
            <button @click="gotoSection('details')">Details</button>
          </li>
          <!-- We'l define custom events events for the creative -->
          <li
            v-if="
              getCurrentSectionsGroup === sectionsDetails &&
              creative.id !== null
            "
            :selected="currentSection == 'events'"
          >
            <button @click="gotoSection('events')">Events</button>
          </li>
          <!-- GWD FILES -->
          <li
            v-if="
              getCurrentSectionsGroup === sectionsDetails &&
              creative.id !== null
            "
            :selected="currentSection == 'gwdfiles'"
          >
            <button @click="gotoSection('gwdfiles')">GWD Files</button>
          </li>
          <!-- creative upload logs -->
          <li
            v-if="
              getCurrentSectionsGroup === sectionsDetails &&
              creative.id !== null
            "
            :selected="currentSection == 'uploadlogs'"
          >
            <button @click="gotoSection('uploadlogs')">Upload Logs</button>
          </li>

          <!-- CREATIVE PRODUCTION -->
          <li
            v-if="getCurrentSectionsGroup === sections"
            :selected="currentSection == 'product'"
          >
            <button @click="gotoSection('product')">Format</button>
          </li>
          <li
            v-if="getCurrentSectionsGroup === sections"
            :selected="currentSection == 'production'"
          >
            <button
              :disabled="creative.product === '' || isPadSquadCampaign"
              @click="gotoSection('production')"
            >
              Production
            </button>
          </li>
          <li
            v-if="getCurrentSectionsGroup === sections"
            :selected="currentSection == 'template'"
          >
            <button
              :disabled="creative.production === null || isPadSquadCampaign"
              @click="gotoSection('template')"
            >
              PadSquad Template
            </button>
          </li>
          <!-- <li v-if="getCurrentSectionsGroup === sections" :selected="(currentSection=='design')">
            <button @click="gotoSection('design')" disabled>Design</button>
          </li>
          <li v-if="getCurrentSectionsGroup === sections" :selected="(currentSection=='assets')">
            <button @click="gotoSection('assets')" disabled>Assets</button>
          </li> -->
          <li
            v-if="getCurrentSectionsGroup === sections"
            :selected="currentSection == 'summary'"
          >
            <button
              :disabled="creative.production === null"
              @click="gotoSection('summary')"
            >
              Summary
            </button>
          </li>
        </ul>
      </nav>

      <div
        v-if="
          (productSelected &&
            currentSection !== 'product' &&
            getCurrentSectionsGroup !== sectionsDetails) ||
          currentSection === 'build'
        "
        class="message"
      >
        You choose to
        {{ getCurrentSectionsGroup === sectionsDetails ? 'edit' : 'create' }}
        {{
          creative.production_build_type === 'Airtory Creative Upload' &&
          isPadSquadCreativeUpload
            ? 'a ' + getProductName + ' by uploading creative assets'
            : 'a ' + getProductName
        }}
      </div>

      <!-- DETAILS -->
      <!-- <div class="sModal__body" v-show="currentSection=='details'"> -->
      <div
        v-if="currentSection == 'details'"
        id="details"
        class="sModal__body"
      >
        <FormField
          label="Creative Name  *"
          for-id="creative-name"
        >
          <input
            id="creative-name"
            v-model="creative.name"
            type="text"
            maxlength="50"
            required
          />
        </FormField>

        <FormField
          label="Status"
          for-id="creative-status"
          type="select"
        >
          <select
            id="creative-status"
            v-model="creative.status_id"
            required
          >
            <option
              v-for="(status, index) in creativeStatus"
              :key="index"
              :value="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Creative Tag Source *"
          for-id="creative-source"
          type="select"
        >
          <select
            id="creative-source"
            v-model="creative.creative_source"
            :disabled="
              creative.creative_source === 'Airtory Sync' || isPadSquadCampaign
            "
            required
          >
            <option
              v-for="(creativeTagSource, index) in campaignSources"
              :key="index"
              :value="creativeTagSource.name"
            >
              {{ creativeTagSource.name }}
            </option>
          </select>
        </FormField>

        <div
          v-if="!isPadSquadCampaign"
          class="formFieldGroup"
        >
          <div
            class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
          >
            <FormField
              label="Airtory Creative Id "
              for-id="airtory-id"
              class=""
            >
              <input
                id="airtory-id"
                v-model="creative.external_creative_id"
                :disabled="creative.id !== null || itemId !== null"
                type="text"
                maxlength="50"
              />
            </FormField>
            <!-- <FormField>
                <button @click.prevent="syncCreativeTag" id="airtory-sync-button" :disabled="creative.external_creative_id.length < 4"><span><font-awesome-icon icon="sync-alt" class="i" /></span>FETCH AIRTORY CREATIVE TAG</button>
              </FormField> -->
          </div>
          <!-- <small>You have to hit the ’Update Creative’ button in order for synced details to be saved.</small> -->
          <small>Id of the creative on Airtory.</small>
        </div>

        <FormField
          label="Format *"
          for-id="creative-format"
          type="select"
        >
          <!-- <select v-model="creative.format_id" id="creative-format" @change="productChanged($event)" required> -->
          <select
            id="creative-format"
            v-model="creative.format_id"
            required
            @change="productChanged($event)"
          >
            <option
              value=""
              selected
              disabled
            >
              Select a format
            </option>
            <option
              v-for="(format, index) in _.sortBy(creativeFormatsNoDeprecated, [
                'name'
              ])"
              :key="index"
              :value="format.id"
            >
              {{ format.name }}
            </option>
          </select>
        </FormField>

        <FormField>
          <div>
            <input
              id="check_vast"
              v-model="creative.vast_tag"
              type="checkbox"
              :disabled="!creative.format_id"
            />
            Has VAST Tag
          </div>
        </FormField>

        <FormField
          label="Primary KPI *"
          for-id="creative-kpi"
          type="select"
        >
          <select
            id="creative-kpi"
            v-model="creative.main_kpi_id"
            required
            @change="onChangeKPI($event, 'kpi1')"
          >
            <option value="0">No Primary KPI</option>
            <option
              v-for="kpi in KPIs"
              :key="kpi.id"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Primary KPI Goal (Client Benchmark 1)"
          for-id="creative-kpi-goal"
        >
          <input
            id="creative-kpi-goal"
            v-model="creative.kpi_goal"
            :disabled="creative.main_kpi_id == 0"
            type="text"
            @keyup="inputKPI($event)"
          />
          <small v-if="kpiDescription">{{ kpiDescription }}</small>
        </FormField>

        <FormField
          label="Secondary KPI"
          for-id="creative-kpi2"
          type="select"
        >
          <select
            id="creative-kpi2"
            v-model="creative.kpi2_id"
            @change="onChangeKPI($event, 'kpi2')"
          >
            <option
              value="0"
              selected
            >
              No Secondary KPI
            </option>
            <option
              v-for="(kpi, index) in KPIs"
              :key="index"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Secondary KPI Goal (Client Benchmark 2)"
          for-id="creative-kpi2-goal"
        >
          <input
            id="creative-kpi2-goal"
            v-model="creative.kpi2_goal"
            :disabled="creative.kpi2_id == 0"
            type="text"
            @keyup="inputKPI($event)"
          />
          <small v-if="kpiDescription2">{{ kpiDescription2 }}</small>
        </FormField>

        <FormField
          label="Production *"
          for-id="creative-production"
          type="select"
        >
          <select
            id="creative-production"
            v-model="creative.production"
            disabled
            required
          >
            <option
              v-for="(production, index) in _.sortBy(productionTypes, ['name'])"
              :key="index"
              :value="production.name"
            >
              {{ production.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Engagement Rate Type"
          for-id="er-option"
          type="select"
        >
          <select
            id="er-option"
            v-model="creative.engagement_type"
            @change="onChangeEngagment($event)"
          >
            <option
              v-for="(er, index) in _.sortBy(engagementType, ['id'])"
              :key="index"
              :value="er.name"
            >
              {{ er.name }}
            </option>
          </select>
        </FormField>

        <FormField
          v-if="logStatus && (creative.id !== null || itemId !== null)"
          label="Log Status"
          for-id="log-status"
          type="select"
        >
          <select
            id="log-status"
            v-model="creativeLogs.status"
            @change="onChangeLogStatus($event)"
          >
            <option
              v-for="(status, index) in _.sortBy(uploadedLogsStatus, ['id'])"
              :key="index"
              :value="status.name"
            >
              {{ status.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Creative Tag *"
          for-id="creative-demo-creative_tag"
          :is-airtory="true"
        >
          <button
            v-if="creative.creative_tag != ''"
            class="button button-over-div"
            @click.prevent="copyTag(creative.creative_tag)"
          >
            <font-awesome-icon
              icon="copy"
              class="i"
            />COPY TAG
          </button>
          <!-- <textarea-autosize v-model="creative.creative_tag" id="creative-demo-tag" :rows="creative.creative_tag != '' ? 15 : 10" :disabled="creative.creative_tag !== ''" :required="(activeDesignFiles==0)" ></textarea-autosize> -->
          <textarea-autosize
            id="creative-demo-tag"
            v-model="creative.creative_tag"
            :rows="creative.creative_tag != '' ? 15 : 10"
            :required="activeDesignFiles == 0"
          ></textarea-autosize>
        </FormField>
      </div>

      <!-- EVENTS -->
      <div
        v-if="currentSection === 'events'"
        class="sModal__body"
      >
        <!-- Clicks -->
        <FormField label="Custom Clicks">
          <div class="listBox">
            <ul>
              <!-- <draggable v-model="events"> -->
              <div
                v-for="(event, index) in creative.events"
                :key="index"
              >
                <li
                  v-if="event.event_id === 2"
                  class="listBoxOneField"
                >
                  <div class="formFieldGroup listBox__label">
                    <div class="formFieldGroup__fields">
                      <FormField
                        :for-id="'click__name' + index"
                        tag-left="Label"
                      >
                        <input
                          :id="'click__name' + index"
                          v-model="event.label_name"
                          :readonly="event.uploaded_event == 1"
                          type="text"
                          :required="
                            event.label_name !== '' &&
                            !passedValidation(event.label_name)
                          "
                          :class="{ notSaved: !event.saved }"
                          @blur="copyTag(event.label_name)"
                          @input="
                            event.label_name = $event.target.value.toLowerCase()
                          "
                        />
                        <div class="copy-event">
                          <font-awesome-icon
                            icon="copy"
                            class="copy-icon i"
                            @click.prevent="copyTag(event.label_name)"
                          />
                        </div>
                      </FormField>
                    </div>
                  </div>
                  <div
                    v-if="!event.saved && passedValidation(event.label_name)"
                  >
                    <button
                      class="button--positive"
                      :disabled="!passedValidation(event.label_name)"
                      @click.prevent="saveEvent(event)"
                    >
                      <font-awesome-icon
                        icon="check"
                        class="i"
                      />
                    </button>
                  </div>
                  <!-- <div v-if="event.saved && !passedValidation(event.label_name)"> -->
                  <div v-if="event.saved && event.uploaded_event == 0">
                    <button
                      :disabled="event.uploaded_event == 1"
                      @click.prevent="removeEvent(index, creative.events)"
                    >
                      <font-awesome-icon
                        icon="trash-alt"
                        class="i"
                      />
                    </button>
                  </div>
                </li>
              </div>
              <!-- </draggable> -->
              <li
                v-if="!hasEventsOfThisType(2)"
                class="empty"
              >
                <div>There are no custom clicks attached to this creative.</div>
              </li>
            </ul>
          </div>
          <small
            >Custom Clicks for tracking implementation at placement
            level.</small
          >
        </FormField>

        <!-- Button to add custom click, this feature has been disabled-->
        <!-- <FormField>
          <button class="button button--positive" @click.prevent="addEvent(creative.events, 2)"><font-awesome-icon icon="plus-circle" class="i" />New custom Click</button>
        </FormField> -->

        <!-- Customs -->
        <FormField
          label="Custom Events"
          class="mt5"
        >
          <div class="listBox">
            <ul>
              <!-- <draggable v-model="events"> -->
              <div
                v-for="(event, index) in creative.events"
                :key="index"
              >
                <li
                  v-if="event.event_id === 3"
                  class="listBoxOneField"
                >
                  <div class="formFieldGroup listBox__label">
                    <div class="formFieldGroup__fields">
                      <FormField
                        :for-id="'event__name' + index"
                        tag-left="Label"
                      >
                        <input
                          :id="'click__name' + index"
                          v-model="event.label_name"
                          :readonly="event.uploaded_event == 1"
                          type="text"
                          :required="
                            event.label_name !== '' &&
                            !passedValidation(event.label_name)
                          "
                          :class="{ notSaved: !event.saved }"
                          @blur="copyTag(event.label_name)"
                          @input="
                            event.label_name = $event.target.value.toLowerCase()
                          "
                        />
                        <div class="copy-event">
                          <font-awesome-icon
                            icon="copy"
                            class="copy-icon i"
                            @click.prevent="copyTag(event.label_name)"
                          />
                        </div>
                      </FormField>

                      <!-- <FormField :forId="'event__value'+index" tagLeft="Value">
                            <input v-model="event.value" :min="creative.campaignStartDate" :id="'event__value'+index" type="text" required />
                          </FormField> -->
                    </div>
                  </div>
                  <div
                    v-if="!event.saved && passedValidation(event.label_name)"
                  >
                    <button
                      class="button--positive"
                      :disabled="!passedValidation(event.label_name)"
                      @click.prevent="saveEvent(event)"
                    >
                      <font-awesome-icon
                        icon="check"
                        class="i"
                      />
                    </button>
                  </div>
                  <div v-if="event.saved && event.uploaded_event == 0">
                    <button
                      :disabled="event.uploaded_event == 1"
                      @click.prevent="removeEvent(index, creative.events)"
                    >
                      <font-awesome-icon
                        icon="trash-alt"
                        class="i"
                      />
                    </button>
                  </div>
                </li>
              </div>
              <!-- </draggable> -->
              <li
                v-if="!hasEventsOfThisType(3)"
                class="empty"
              >
                <div>There are no custom events attached to this creative.</div>
              </li>
            </ul>
          </div>
          <small
            >Custom Events for tracking implementation at placement
            level.</small
          >
        </FormField>

        <!-- Button to add custom event, this feature has been disabled -->
        <!-- <FormField>
          <button class="button button--positive" @click.prevent="addEvent(creative.events, 3)"><font-awesome-icon icon="plus-circle" class="i" />New custom Event</button>
        </FormField> -->
      </div>

      <!-- PRODUCT -->
      <div
        v-if="currentSection == 'product'"
        id="product"
        class="sModal__body sModal__body__search"
      >
        <ModuleFilter class="moduleFilterModal">
          <div slot="searchField">
            <FormField
              label="Search Formats"
              for-id="filter-search"
              type="search"
              class="moduleFilter__filter"
            >
              <input
                id="filter-search"
                v-model="filterModuleData.search"
                type="text"
              />
            </FormField>
          </div>
        </ModuleFilter>

        <div class="section-label mb1">Choose a Format</div>

        <div class="products">
          <!-- <Product v-for="(product, index) of _.sortBy(products, ['name'])" -->
          <Product
            v-for="(product, index) of _.sortBy(paginatedItems, ['name'])"
            :key="index"
            :title="product.name"
            :selected="product.selected"
            :mobile="product.supports_mobile"
            :desktop="product.supports_desktop"
            :show-product-info="true"
            @selected="productSelectedHandler(product)"
          >
            <template #demo>
              <div></div>
            </template>
          </Product>
        </div>

        <div class="pagination-wrapper">
          <div class="contentTableFrame__total">
            Total Formats: {{ filteredItems.length }}
          </div>
          <b-pagination
            slot="right"
            v-model="pagination.currentPage"
            class="contentTableFrame__pagination"
            :limit="3"
            :total-rows="filteredItems.length"
            :per-page="pagination.perPage"
          ></b-pagination>
        </div>
      </div>

      <!-- TEMPLATE -->
      <div
        v-if="currentSection == 'template'"
        class="sModal__body"
      >
        <div
          v-if="getProductAirtoryTemplates.length > 0"
          class="section-label mb1"
        >
          Choose a PadSquad Template
        </div>
        <div
          v-if="getProductAirtoryTemplates.length === 0"
          class="section-label mb1"
        >
          There's no PadSquad Template for this product yet.
        </div>

        <div
          v-if="campaign.kpi_id"
          class="section-label info-label mb1"
        >
          The product marked with the orange border is recommended for the
          {{ campaign.kpi }} KPI.
        </div>

        <div class="products templates">
          <Product
            v-for="(product, index) of _.sortBy(getProductAirtoryTemplates, [
              'name'
            ])"
            :key="index"
            :title="product.name"
            :selected="
              airtoryTemplateSelected !== null &&
              airtoryTemplateSelected.id === product.id
            "
            :show-product-info="false"
            :recommended-by-k-p-i="campaign.kpi_id === product.main_kpi_id"
            :kpi="getKPIName(product.main_kpi_id)"
            @selected="airtoryTemplateSelectedHandler(product)"
          >
            <template #demo>
              <div>{{ product.demo_url }}</div>
            </template>
          </Product>
        </div>
      </div>

      <!-- PRODUCTION -->
      <div
        v-show="currentSection == 'production'"
        class="sModal__body"
      >
        <div class="section-label mb1">Choose a Production Type</div>
        <div class="products-production col2">
          <Production
            v-for="(item, index) of _.sortBy(productions, ['id'])"
            :key="index"
            :title="item.name"
            :selected="item.selected"
            :disabled="item.disabled"
            :is-pad-squad-campaign="isPadSquadCampaign"
            @selected="productionSelectedHandler(item)"
            @buildType="productionBuildTypeSelected"
          >
            <template #description>{{ item.description }}</template>
          </Production>
        </div>
      </div>

      <!-- SUMMARY -->
      <div
        v-if="currentSection == 'summary'"
        id="summary"
        class="sModal__body"
      >
        <FormField
          label="Creative Name  *"
          for-id="creative-name"
        >
          <input
            id="creative-name"
            v-model="creative.name"
            type="text"
            maxlength="50"
            required
          />
        </FormField>

        <FormField
          label="Status *"
          for-id="creative-status"
          type="select"
        >
          <select
            id="creative-status"
            v-model="creative.status_id"
            required
          >
            <option
              v-for="(status, index) in creativeStatus"
              :key="index"
              :value="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </FormField>

        <div class="formFieldGroup mb1">
          <div
            class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
          >
            <FormField
              label="Format *"
              for-id="creative-product"
              type="select"
            >
              <!-- <input v-model="creative.product" id="creative-product" type="text" maxlength="50" disabled required /> -->
              <select
                id="creative-product"
                v-model="creative.format_id"
                required
                @change="productChanged($event)"
              >
                <option
                  v-for="(product, index) in _.sortBy(
                    creativeFormatsNoDeprecated,
                    ['name']
                  )"
                  :key="index"
                  :value="product.id"
                >
                  {{ product.name }}
                </option>
              </select>
            </FormField>
            <button
              id="airtory-catalog-button"
              class="button button-inline"
              @click.prevent="gotoSection('product')"
            >
              <span
                ><font-awesome-icon
                  icon="layer-group"
                  class="i" /></span
              >VIEW CATALOG
            </button>
          </div>
        </div>

        <FormField>
          <div>
            <input
              id="check_vast"
              v-model="creative.vast_tag"
              type="checkbox"
              :disabled="!creative.format_id"
            />
            Has VAST Tag
          </div>
        </FormField>

        <FormField
          label="Primary KPI *"
          for-id="creative-kpi"
          type="select"
        >
          <select
            id="creative-kpi"
            v-model="creative.main_kpi_id"
            required
            @change="onChangeKPI($event, 'kpi1')"
          >
            <option
              value="0"
              selected
            >
              No Primary KPI
            </option>
            <option
              v-for="(kpi, index) in KPIs"
              :key="index"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Primary KPI Goals (Client Benchmark 1)"
          for-id="creative-kpi-goal"
        >
          <input
            id="creative-kpi-goal"
            v-model="creative.kpi_goal"
            :disabled="creative.main_kpi_id == 0"
            type="text"
            @keyup="inputKPI($event)"
          />
          <small v-if="kpiDescription">{{ kpiDescription }}</small>
        </FormField>

        <FormField
          label="Secondary KPI"
          for-id="creative-kpi2"
          type="select"
        >
          <select
            id="creative-kpi2"
            v-model="creative.kpi2_id"
            @change="onChangeKPI($event, 'kpi2')"
          >
            <option
              value="0"
              selected
            >
              No Secondary KPI
            </option>
            <option
              v-for="(kpi, index) in KPIs"
              :key="index"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Secondary KPI Goal (Client Benchmark 2)"
          for-id="creative-kpi2-goal"
        >
          <input
            id="creative-kpi2-goal"
            v-model="creative.kpi2_goal"
            :disabled="creative.kpi2_id == 0"
            type="text"
            @keyup="inputKPI($event)"
          />
          <small v-if="kpiDescription2">{{ kpiDescription2 }}</small>
        </FormField>

        <FormField
          label="Engagement Rate Type"
          for-id="er-option"
          type="select"
        >
          <select
            id="er-option"
            v-model="creative.engagement_type"
            @change="onChangeEngagment($event)"
          >
            <option
              v-for="(er, index) in _.sortBy(engagementType, ['id'])"
              :key="index"
              :value="er.name"
            >
              {{ er.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Creative Tag Source *"
          for-id="creative-source"
          type="select"
        >
          <select
            id="creative-source"
            v-model="creative.creative_source"
            :disabled="creative.creative_source === 'Airtory Sync'"
            required
          >
            <option
              v-for="(creativeTagSource, index) in campaignSources"
              :key="index"
              :value="creativeTagSource.name"
            >
              {{ creativeTagSource.name }}
            </option>
          </select>
        </FormField>

        <FormField
          v-if="!isPadSquadCampaign"
          label="Airtory Creative Id *"
          for-id="airtory-id"
          class=""
        >
          <div class="ratioInputs df">
            <div class="mr1">
              <input
                id="new"
                v-model="typeOfData"
                type="radio"
                name="creativeNew"
                value="newCreative"
              />
              New Creative
            </div>
            <div class="radio-input">
              <div class="">
                <input
                  id="not_new"
                  v-model="typeOfData"
                  type="radio"
                  name="creativeNew"
                  value="syncCreative"
                />
                Creative Id:
              </div>
              <FormField
                label=""
                for-id="airtory-id"
                class="ml1"
              >
                <input
                  id="airtory-id"
                  v-model="creative.external_creative_id"
                  :disabled="typeOfData == 'newCreative'"
                  type="text"
                  maxlength="50"
                  required
                />
              </FormField>
              <button
                v-if="typeOfData == 'syncCreative'"
                class="button button-inline button-third"
                @click.prevent="syncAirtoryCreative()"
              >
                SYNC CREATIVE
              </button>
            </div>
          </div>
        </FormField>

        <div class="formFieldGroup mb1">
          <div
            class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
          >
            <FormField label="PRODUCTION TYPE *">
              <input
                id="creative-production"
                :value="`${creative.production} - ${creative.production_build_type}`"
                type="text"
                maxlength="50"
                disabled
                required
              />
            </FormField>
            <button
              class="button button-inline"
              @click.prevent="gotoSection('production')"
            >
              <span
                ><font-awesome-icon
                  icon="layer-group"
                  class="i" /></span
              >VIEW CATALOG
            </button>
          </div>
        </div>

        <!-- <FormField label="PRODUCTION" forId="creative-production">
          <input v-model="creative.production" id="creative-production" type="text" maxlength="50" disabled required />
        </FormField> -->
      </div>

      <!-- UPLOAD GWD FILES -->
      <div
        v-if="currentSection == 'gwdfiles'"
        id="gwdfiles"
        class="sModal__body"
      >
        <div
          v-if="loading"
          class="loader"
        >
          <font-awesome-icon
            icon="spinner"
            class="spinner"
          />
        </div>
        <FormField
          FormField
          label="Upload GWD Production Files"
          description="Supported Format: ZIP"
          for-id="uploaded-gwd"
        >
          <b-form-file
            ref="uploadFile"
            v-model="gwdFileUploader.file"
            class="button-file"
            accept="application/zip"
            :state="Boolean(gwdFileUploader.file)"
            placeholder="Choose a file..."
            @change="uploadFiles"
          ></b-form-file>
        </FormField>
        <FormField label="GWD Files">
          <div class="listBox">
            <ul>
              <li
                v-for="(file, index) in paginatedGwdFiles"
                :key="index"
              >
                <div class="listBox__label">
                  <a
                    href="#"
                    class="overflow-ellipsis-creatives"
                    :title="file.name"
                    >{{ file.name }}</a
                  >
                </div>
                <div class="listBox__label gwd-tab">
                  {{ file.project_type }}
                </div>
                <div class="listBox__label">{{ file.email }}</div>
                <div class="listBox__label">
                  {{ getFormattedDate(file.updated_date) }}
                </div>
                <div>
                  <button @click.prevent="downloadAsset(file)">
                    <font-awesome-icon
                      icon="download"
                      class="i"
                    />
                  </button>
                  <button
                    v-if="file.status != 'removed'"
                    @click.prevent="removeFile(file)"
                  >
                    <font-awesome-icon
                      icon="trash-alt"
                      class="i"
                    />
                  </button>
                </div>
              </li>
              <li
                v-if="filteredGwdFiles.length === 0"
                class="empty"
              >
                <div>There are no GWD files uploaded</div>
              </li>
            </ul>
          </div>
          <ContentTableTools v-show="filteredGwdFiles.length > 6">
            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total GWD Files: {{ filteredGwdFiles.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredGwdFiles.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>
          <!-- <small  v-if="creative.gwdFiles.length!=0">* You must save creative in order for New and Removed Files to be saved.</small> -->
        </FormField>
      </div>

      <!-- UPLOAD LOGS -->
      <div
        v-if="currentSection == 'uploadlogs'"
        id="uploadlogs"
        class="sModal__body"
      >
        <table class="contentTable">
          <thead>
            <tr v-if="filteredLogs.length >= 1">
              <th class="column-text-align-left">
                {{
                  creative.production_build_type === 'PadSquad Creative Upload'
                    ? 'Asset URL'
                    : 'Html Airtory ID'
                }}
              </th>
              <th class="column-text-align-left">Email</th>
              <th class="column-text-align-left">Status</th>
              <th class="column-text-align-left">Last Update</th>
              <th class="column-text-align-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(log, index) in _.orderBy(
                paginatedLogs,
                'updated_date',
                'desc'
              )"
              :key="index"
            >
              <td
                column-name="Html Airtory ID"
                class="column-text-align-left logTableEllipsis"
                :title="log.asset_url"
              >
                {{
                  creative.production_build_type === 'PadSquad Creative Upload'
                    ? log.zip_url
                    : log.html_airtory_id
                }}
              </td>
              <td
                column-name="Email"
                class="column-text-align-left"
              >
                {{ log.email }}
              </td>
              <td
                column-name="Status"
                class="column-text-align-left"
              >
                {{ log.status }}
              </td>
              <td
                column-name="Last Update"
                class="column-text-align-left"
              >
                {{ getFormattedDate(log.updated_date) }}
              </td>
              <td class="contentTable__actions">
                <button
                  :disabled="!log.zip_url"
                  @click.prevent="downloadCreativesZip(log)"
                >
                  <font-awesome-icon
                    icon="download"
                    class="i"
                  />
                </button>
              </td>
              <!-- Copy Asset URL PadSquad -->
              <td
                v-if="isPadSquadCreativeUpload"
                class="contentTable__actions"
              >
                <button
                  :disabled="!log.zip_url"
                  title="Copy Url"
                  @click.prevent="copyAssetUrl(log.asset_url)"
                >
                  <font-awesome-icon
                    icon="copy"
                    class="i"
                  />
                </button>
                <!-- tooltip for copy -->
                <div
                  id="tooltip"
                  class="copyAssetUrlTooltip"
                ></div>
              </td>
            </tr>
            <tr v-if="filteredLogs.length < 1">
              <td colspan="9">There are no upload logs for this creative.</td>
            </tr>
          </tbody>
        </table>
        <ContentTableTools v-show="filteredLogs.length > 6">
          <div
            slot="right"
            class="contentTableFrame__total"
          >
            Total Logs: {{ filteredLogs.length }}
          </div>
          <b-pagination
            slot="right"
            v-model="pagination.currentPage"
            class="contentTableFrame__pagination"
            :limit="3"
            :total-rows="filteredLogs.length"
            :per-page="pagination.perPage"
          ></b-pagination>
        </ContentTableTools>
      </div>

      <!-- BUILD -->
      <div
        v-show="currentSection == 'build'"
        id="sModal__body_iframe"
        :key="'23344_iframe'"
        class="sModal__body"
      >
        <!-- <div id="sModal__body_iframe" class="sModal__body"  :key="'23344_iframe'" :class="{ noHeight: currentSection !== 'build'}"> -->
        <div id="ay-ad__air_whitelabel"></div>
      </div>
      <footer
        v-if="currentSection !== 'build'"
        class="sModal__footer"
      >
        <div class="sModal__footer__left">
          <button
            class="cancelButton"
            @click.prevent="cancel"
          >
            <font-awesome-icon
              icon="ban"
              class="i"
            />Cancel
          </button>
        </div>

        <div
          v-if="getCurrentSectionsGroup === sections"
          class="sModal__footer__right"
        >
          <!-- show 'Next' if this is new creative and we are not on the last screen -->
          <button
            v-if="!itemId && !isFirstSection"
            class="saveButton"
            @click="gotoSection(prevSection)"
          >
            <font-awesome-icon
              icon="angle-left"
              class="i"
            />Previous
          </button>

          <!-- show 'Next' if this is new creative and we are not on the last screen -->
          <button
            v-if="!itemId && !isLastSection"
            :disabled="!productSelected"
            class="saveButton"
            @click="gotoSection(nextSection)"
          >
            Next<font-awesome-icon
              icon="angle-right"
              class="i last"
            />
          </button>

          <!-- show 'Save Creative' if this not a new creative or is a Synced creative -->
          <button
            v-if="
              itemId ||
              (typeOfData == 'syncCreative' &&
                !$store.state.creatives.isPadSquadTemporaryCreative)
            "
            :disabled="!isDataModified"
            class="saveButton"
            @click.prevent="saveData()"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Save Creative
          </button>

          <!-- show 'Save New Creative' if this is new creative and we are on the last screen-->
          <button
            v-if="
              !itemId &&
              isLastSection &&
              typeOfData == 'newCreative' &&
              !isPadSquadCreativeUpload
            "
            :disabled="!isDataModified"
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Start Building
          </button>
          <!-- PadSquad Creative Upload -->
          <!-- invoking build a temporary for now here -->
          <!-- newLogicHere -->
          <button
            v-if="
              !itemId &&
              isLastSection &&
              typeOfData == 'newCreative' &&
              isPadSquadCreativeUpload
            "
            :disabled="!isDataModified"
            class="saveButton"
            @click.prevent="buildPadSquadTemporaryCreativeUpload()"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Start Building
          </button>
        </div>

        <div
          v-if="getCurrentSectionsGroup === sectionsDetails"
          class="sModal__footer__right"
        >
          <button
            v-if="creative.production_build_type_id === 4"
            class="saveButton padSquadButton"
            @click="editPadSquadCreative()"
          >
            Edit PadSquad Creative
          </button>
          <!-- TODO ? show 'Edit' if this not a new creative -->
          <button
            v-else
            :disabled="!isDataModified"
            class="saveButton airtoryButton"
            @click="editOnAirtory('edit')"
          >
            Edit On Airtory
          </button>
          <!-- show 'Update Creative' if this is new creative and we are on the last screen-->
          <button
            v-if="!$store.state.creatives.isPadSquadTemporaryCreative"
            :disabled="!isDataModified"
            class="saveButton"
            @click.prevent="saveData()"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Save Creative
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import draggable from 'vuedraggable'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'
import ModuleFilter from '@/components/ModuleFilter.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import Autocomplete from '@/components/Autocomplete.vue'
import Preloader from '@/components/Preloader.vue'
import Product from '@/components/Product.vue'
import Production from '@/components/Production.vue'

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'CreativeProfile',
  components: {
    FormField,
    draggable,
    Preloader,
    ModuleFilter,
    ContentTableTools,
    Autocomplete,
    Product,
    Production
  },
  props: {
    /* if itemId prop comes empty, the template considers this as a new user */
    itemId: null,
    advertiserId: null,
    advertiserName: null,
    campaign: null,
    creativeProp: null,
    sectionProp: null
  },
  data() {
    return {
      loading: true,
      sections: [
        // "build",
        'product',
        'production',
        'template',
        'summary'
      ],
      sectionsDetails: ['details', 'events', 'gwdfiles', 'uploadlogs'],
      currentSectionsGroup: [],
      filterModuleData: {
        search: ''
      },
      pagination: {
        perPage: 6,
        currentPage: 1
      },
      products: [],
      productSelected: null,
      productions: [],
      productionSelected: null,
      campaignStartDateInput: '',
      campaignEndDateInput: '',
      currentSection: '',
      typeOfData: 'newCreative',
      sectionAfterBuilding: 'summary',
      // {
      //     "external_campaign_id": 1,
      //     "format_id": 34,
      //     "creative_type_id": 2, // TODO Pedro specified what is this field
      //     "main_kpi_id": 4,
      //     "name": "Visit Seattle Argosy Scroller",
      //     "creative_tag": "<tag>another tag</tag>",
      //     "status": "Production"
      // }
      setKpiOption: null,
      setKpiOption2: null,
      kpiDescription: null,
      kpiDescription2: null,
      creativesDownload: null,
      lastLogCreated: null,
      gwdFileUploader: {
        file: null
      },
      creative: {
        id: null,
        name: '',
        campaign_id: '',
        external_campaign_id: '',
        campaign_name: '',
        status: 'Production',
        status_id: 1,
        creative_source:
          this.campaign.campaign_source === 'PadSquad'
            ? 'PadSquad'
            : 'Airtory Sync',
        creative_type_id: 1, // HTML5 or Image
        external_creative_id: '', //
        format_id: '', // product
        vast_tag: 0,
        product: '', // format_id
        production: null, // "Build Your Own",
        production_id: 1,
        production_build_type:
          this.campaign.campaign_source === 'PadSquad'
            ? 'PadSquad Creative Upload'
            : 'Airtory Creative Upload',
        production_build_type_id:
          this.campaign.campaign_source === 'PadSquad' ? 4 : 3,
        main_kpi_id: null,
        kpi_goal: null,
        kpi2_id: 0,
        kpi2_goal: null,
        engagement_type: '',
        creative_tag: '',
        external_tag_id: '', // the same as external_creative_id,
        external_template_id: null, // this.airtoryTemplateSelected.external_template_id
        // EVENTS
        events: [
          // events standards 1, always we send these
          // { event_id: 1, label_name: "Impression", creative_id: ''},
          // { event_id: 1, label_name: "Click", creative_id: ''}
          // clicks 2
          // { event_id: 2, label_name: "exit_url1", creative_id: 23}
          // { event_id: 2, label_name: "exit_url2", creative_id: 23},
          // events customs 3
          // { event_id: 3, label_name: "CustomEvent1", creative_id: 23},
          // { event_id: 3, label_name: "CustomEvent2", creative_id: 23}
        ]
      },
      creativeLogs: {
        id: null,
        name: '',
        creative_id: null,
        html_airtory_id: null,
        email: `${this.$store.getters.userInSession.email}`,
        updated_date: null,
        status: '',
        zip_url: ''
      },
      uploadedCreativeLogs: [],
      gwdFiles: [],
      uploadedLogsStatus: [
        { id: 1, name: 'Development' },
        { id: 2, name: 'Client Revision' },
        { id: 3, name: 'Internal Revision' },
        { id: 4, name: 'Test' },
        { id: 5, name: 'QA Revision' },
        { id: 6, name: 'Creative Optimization' }
      ],
      logStatus: false,
      selectedLogStatus: null,
      engagementType: [
        { id: 1, name: 'Engagement Rate' },
        { id: 2, name: 'Fallback Engagement Rate' }
      ],
      // AIRTORY TEMPLATES AND IDS
      airtoryTemplateSelected: null,
      // AIRTORY DATA
      config: {
        companyId: process.env.VUE_APP_ENDPOINT_AIRTORY_COMPANY_ID,
        userName: process.env.VUE_APP_ENDPOINT_AIRTORY_USER_NAME,
        first_name: process.env.VUE_APP_ENDPOINT_AIRTORY_FIRST_NAME,
        last_name: process.env.VUE_APP_ENDPOINT_AIRTORY_LAST_NAME
      },
      adTypes: [
        { id: 1, name: 'interactive' },
        { id: 2, name: 'animated' },
        { id: 3, name: 'desktop' },
        { id: 4, name: 'native' },
        { id: 5, name: 'standard' }
      ],
      adSizes: [
        { id: 1, name: 'interstitials' },
        { id: 2, name: 'responsive' },
        { id: 3, name: 'banner' },
        { id: 4, name: 'square' },
        { id: 5, name: 'leaderboard' },
        { id: 6, name: 'halfpage' },
        { id: 7, name: 'skins' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'campaignSources',
      'creativeFormatsNoDeprecated',
      'airtoryTemplates',
      'productionTypes',
      'creativeStatus',
      'KPIs',
      'buildAirtoryTypes'
    ]),
    ...mapGetters('creatives', {
      productionCreativeById: 'itemById'
    }),
    getProductAirtoryTemplates: function () {
      const products = _.filter(this.airtoryTemplates, {
        format_id: this.creative.format_id
      })
      return products
    },
    isPadSquadCampaign: function () {
      return this.campaign.campaign_source === 'PadSquad'
    },
    getProductName: function () {
      const product = this.products.find(
        (item) => item.id == this.creative.format_id
      )
      return product.name
    },
    getEventsOfTypeClicks: function () {
      const foundEvents = this.creative.events.filter(
        (item) => item.event_id === 2
      )
      return foundEvents
    },
    isPadSquadCreativeUpload: function () {
      if (this.creative.production_build_type === 'PadSquad Creative Upload') {
        return true
      } else return false
    },
    isPadSquadProductionBuildType: function () {
      // production build type id 4 === 'PadSquad Creative Upload'
      return (
        this.creative.id !== null
        && this.creative.production_build_type_id === 4
      )
    },
    getEventsOfTypeEventCustoms: function () {
      const foundEvents = this.creative.events.filter(
        (item) => item.event_id === 3
      )
      return foundEvents
    },
    getCurrentSectionsGroup: function () {
      return this.currentSectionsGroup
    },
    activeDesignFiles: function () {
      const self = this
      // a list of design files that are not status = removed.
      return _.filter(self.creative.designImages, (di) => {
        return di.status != 'removed'
      })
    },
    isFirstSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return self.getCurrentSectionsGroup.indexOf(self.currentSection) + 1 == 1
    },
    isLastSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return (
        self.getCurrentSectionsGroup.indexOf(self.currentSection) + 1
        == self.getCurrentSectionsGroup.length
      )
    },
    prevSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex
        = self.getCurrentSectionsGroup.indexOf(self.currentSection) - 1

      // return the section name on the next position in the sections array;
      return self.getCurrentSectionsGroup[currentSectionIndex]
    },
    nextSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex
        = self.getCurrentSectionsGroup.indexOf(self.currentSection) + 1

      // return the section name on the next position in the sections array;
      return self.getCurrentSectionsGroup[currentSectionIndex]
    },
    isDataModified: function () {
      return true //! _.isEqual(self.creative, this.productionCreativeById(self.itemId));
    },
    filteredItems: function () {
      const self = this
      // let filteredList = self.products.filter(item => item.active === 1)
      const filteredList = self.products

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        filteredList,
        self.filterModuleData.search
      )

      // filter data
      let filteredItems = searchedItems // Utils.getFilteredItems(searchedItems, propsToFilterBy);

      // order data asc or desc
      filteredItems = _.orderBy(filteredItems, 'name', 'asc')

      return filteredItems
    },
    filteredLogs: function () {
      const self = this
      // let filteredList = self.products.filter(item => item.active === 1)
      const filteredList = self.uploadedCreativeLogs
      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        filteredList,
        self.filterModuleData.search
      )

      // filter data
      let filteredLogs = searchedItems // Utils.getFilteredItems(searchedItems, propsToFilterBy);

      // order data asc or desc
      filteredLogs = _.orderBy(filteredLogs, 'updated_date', 'desc')
      return filteredLogs
    },
    filteredGwdFiles: function () {
      const self = this
      // let filteredList = self.products.filter(item => item.active === 1)
      const filteredList = self.gwdFiles
      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        filteredList,
        self.filterModuleData.search
      )

      // filter data
      let filteredGwdFiles = searchedItems // Utils.getFilteredItems(searchedItems, propsToFilterBy);

      filteredGwdFiles = _.orderBy(filteredGwdFiles, 'updated_date', 'desc')
      // order data asc or desc
      return filteredGwdFiles
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(
        self.filteredItems,
        self.pagination.currentPage,
        self.pagination.perPage
      )
    },
    paginatedLogs: function () {
      const self = this
      // return paginated data
      return Utils.getPaginatedItems(
        self.filteredLogs,
        self.pagination.currentPage,
        self.pagination.perPage
      )
    },
    paginatedGwdFiles: function () {
      const self = this
      // return paginated data
      return Utils.getPaginatedItems(
        self.filteredGwdFiles,
        self.pagination.currentPage,
        self.pagination.perPage
      )
    }
  },
  watch: {
    /* when the section is changed */
    currentSection: function (value) {
      const self = this

      // if is build we emit an event to add a full with class for iframe build
      if (this.currentSection === 'build') {
        this.$emit('addModalClassForBuild')
      } else {
        this.$emit('removeModalClassForBuild')
      }
    },
    campaignStartDateInput: function (value) {
      const self = this

      // update the campaign flight dates of the creative
      self.creative.campaignStartDate = self
        .$moment(self.campaignStartDateInput)
        .utc()
        .toISOString()
    },
    campaignEndDateInput: function (value) {
      const self = this

      // update the campaign flight dates of the creative
      self.creative.campaignEndDate = self
        .$moment(self.campaignEndDateInput)
        .utc()
        .toISOString()
    }
  },
  async created() {
    const self = this
    this.creative.campaign_id = this.campaign.id
    this.creative.external_campaign_id = this.campaign.external_campaign_id // this.airtoryCampaignId;
    this.creative.campaign_name = this.campaign.name // this.airtoryCampaignName;
    this.creative.main_kpi_id = 0

    this.$log.info('Campaign Creative', this.campaign)

    // set the section group
    // this.setCurrentSectionsGroup(this.sectionsDetails);
    // populate form
    await this.populateForm()
  },
  mounted() {
    // let iframeScript = document.createElement('script')
    // iframeScript.setAttribute('src', 'http://cdn.airtory.com/js/embed_iframe.js')
    // document.head.appendChild(iframeScript);
    const self = this

    this.$loadScript(process.env.VUE_APP_ENDPOINT_AIRTORY_IFRAME)
      .then(() => {
        self.$log.info('Script loaded')

        // self.initializeAirtoryScript();
      })
      .catch(() => {
        // Failed to fetch script
        self.$log.error('Script fail loading')
      })

    if (
      self.$store.state.creatives.creativeLogs.length === 0
      && self.$store.state.creatives.isPadSquadTemporaryCreative === true
    ) {
      this.backFromBuild()

      Object.assign(
        self.creative,
        this.$store.state.creatives.temporaryCreative
      )
    }
  },

  beforeUnmount() {
    this.$unloadScript(process.env.VUE_APP_ENDPOINT_AIRTORY_IFRAME)
  },
  methods: {
    ...mapActions(['registerTask', 'completeTask']),
    ...mapActions('creatives', {
      fetchCreativeGwdFiles: 'fetchCreativeGwdFiles',
      fetchById: 'fetchById',
      fetchAirtoryCreative: 'fetchAirtoryCreative',
      addCreativeEvents: 'addCreativeEvents',
      fetchByPsId: 'fetchByPsId',
      updatePadSquadTemporaryCreative: 'updatePadSquadTemporaryCreative',
      removeCreativeEvents: 'removeCreativeEvents',
      createProductionCreative: 'create',
      updateProductionCreative: 'update',
      fetchCreativesDownload: 'fetchCreativesDownload',
      createCreativesLogs: 'createCreativesLogs',
      fetchCreativesLogsById: 'fetchCreativesLogs',
      fetchPadSquadCreativeLogsById: 'fetchPadSquadCreativesLogs',
      fetchTemporaryCreativeById: 'fetchTemporaryCreativeById',
      createPadSquadTemporaryCreative: 'createPadSquadTemporaryCreative',
      fetchTemporaryCreativeList: 'fetchTemporaryCreativeList',
      createPadSquadCreative: 'createPadSquadCreative'
    }),
    ...mapActions('campaigns', {
      addCreativeCampaign: 'addCreativeCampaign',
      updateCreativeCampaign: 'updateCreativeCampaign'
    }),
    ...mapMutations('creatives', {
      SET_BY_ID: 'SET_BY_ID',
      SET_IS_TEMP: 'SET_IS_TEMP',
      SET_OPEN_MODAL: 'SET_OPEN_MODAL',
      SET_MODAL_SECTION: 'SET_MODAL_SECTION'
    }),
    async buildPadSquadTemporaryCreativeUpload() {
      if (this.$store.state.creatives.isPadSquadTemporaryCreative !== true) {
        const res = await this.createPadSquadTemporaryCreative(this.creative)
        this.$store.commit('creatives/CLEAR_CREATIVE_LOGS')

        this.$router.push({
          name: 'PadSquad Creative Upload',
          params: { id: res.creative_ps_id }
        })
      } else {
        delete this.creative.id
        delete this.creative.creative_ps_id
        delete this.creative.displayOptions
        const res = await this.createPadSquadTemporaryCreative(this.creative)
        this.$store.commit('creatives/CLEAR_CREATIVE_LOGS')

        this.$router.push({
          name: 'PadSquad Creative Upload',
          params: { id: res.creative_ps_id }
        })
      }
      this.$store.commit('creatives/CLEAR_CREATIVE_LOGS')
    },
    async editPadSquadCreative() {
      await this.savePadSquadCreativeData()
      if (this.$store.state.creatives.creativeLogs.length > 0) {
        // Fetch data by creative_ps_id
        if (this.$store.state.creatives.isPadSquadTemporaryCreative !== true) {
          const data = await this.fetchByPsId(this.creativeProp.creative_ps_id)

          // Update PadSquadTemporaryCreative with the updated temporaryCreative
          delete data.events
          await this.updatePadSquadTemporaryCreative(data)
        } else
          await this.updatePadSquadTemporaryCreative(
            this.$store.state.creatives.temporaryCreative
          )
      } else {
        await this.updatePadSquadTemporaryCreative(
          this.$store.state.creatives.temporaryCreative
        )
      }
      this.SET_MODAL_SECTION(this.currentSection)
      // Redirect to the specified path
      this.$router.push({
        path: `/creative-production/creative-upload/${this.creativeProp.creative_ps_id}`
      })
    },
    getKPIName(kpi_id) {
      const kpi = _.find(this.KPIs, { id: kpi_id })
      return kpi !== null && kpi !== undefined ? kpi.name : null
    },
    onChangeKPI($event, kpiselected) {
      if (kpiselected == 'kpi1') {
        if (this.creative.main_kpi_id === 0) {
        } else {
          const kpiId = this.creative.main_kpi_id
          const kpiname
            = kpiId > 0 ? this.KPIs.find((kpi) => kpi.id == kpiId).name : ''

          this.selectKpiGoal(kpiselected, kpiname)
        }
      } else {
        if (this.creative.kpi2_id === 0) {
        } else {
          const kpiId = this.creative.kpi2_id
          const kpiname
            = kpiId > 0 ? this.KPIs.find((kpi) => kpi.id == kpiId).name : ''

          this.selectKpiGoal(kpiselected, kpiname)
        }
      }
    },
    selectKpiGoal(kpiselected, kpiname) {
      const self = this
      // kpi's object base on type of value
      const kpiList = [
        {
          name: 'integers',
          values: [
            'Impressions - 3rd Party',
            'Engagements',
            'Clicks',
            'Video Views - internal data',
            'Conversions',
            'Brand Study'
          ]
        },
        {
          name: 'percents',
          values: [
            'Engagement Rate (ER)',
            'Video Completion',
            'Video Completion Rate (VCR)',
            'CTR - 3rd Party',
            'Viewability',
            'Brand Lift',
            'Click Through Rate (CTR)',
            'Other'
          ]
        },
        { name: 'times', values: ['Time Spent'] }
      ]

      function findParent(kpiList, value) {
        for (let i = 0; i < kpiList.length; i++) {
          if (kpiList[i].values.indexOf(value) > -1) {
            return kpiList[i].name
          }
        }
      }
      // test findParent function and then parse kpi input value if needed
      if (kpiselected == 'kpi1') {
        self.setKpiOption = findParent(kpiList, kpiname)
        switch (self.setKpiOption) {
          case 'integers':
            self.kpiDescription = 'Amount in whole numbers'
            // evaluate if goal is an empty string
            if (self.creative.kpi_goal === '') {
              self.creative.kpi_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.creative.kpi_goal !== null) {
              self.creative.kpi_goal = Math.round(
                parseFloat(self.creative.kpi_goal)
              )
            }
            break
          case 'percents':
            self.kpiDescription = 'Amount in numbers %'
            if (self.creative.kpi_goal === '') {
              self.creative.kpi_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.creative.kpi_goal !== null) {
              self.creative.kpi_goal = parseFloat(self.creative.kpi_goal) + '%'
            }
            break
          case 'times':
            self.kpiDescription = 'Amount in seconds'
            if (self.creative.kpi_goal === '') {
              self.creative.kpi_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.creative.kpi_goal !== null) {
              self.creative.kpi_goal = Math.round(
                parseFloat(self.creative.kpi_goal)
              )
            }
            break
          case undefined:
            self.kpiDescription = null
            self.creative.kpi_goal = null
            break
        }
      } else {
        self.setKpiOption2 = findParent(kpiList, kpiname)
        switch (self.setKpiOption2) {
          case 'integers':
            self.kpiDescription2 = 'Amount in whole numbers'
            if (self.creative.kpi2_goal === '') {
              self.creative.kpi2_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.creative.kpi2_goal !== null) {
              self.creative.kpi2_goal = Math.round(
                parseFloat(self.creative.kpi2_goal)
              )
            }
            break
          case 'percents':
            self.kpiDescription2 = 'Amount in numbers %'
            if (self.creative.kpi2_goal === '') {
              self.creative.kpi2_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.creative.kpi2_goal !== null) {
              self.creative.kpi2_goal
                = parseFloat(self.creative.kpi2_goal) + '%'
            }
            break
          case 'times':
            self.kpiDescription2 = 'Amount in seconds'
            if (self.creative.kpi2_goal === '') {
              self.creative.kpi2_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.creative.kpi2_goal !== null) {
              self.creative.kpi2_goal = Math.round(
                parseFloat(self.creative.kpi2_goal)
              )
            }
            break
          case undefined:
            self.kpiDescription2 = null
            self.creative.kpi2_goal = null
            break
        }
      }
    },
    inputKPI: function (e) {
      let kpi_goal,
        characterReg = /([0-9.])$/
      if (e.target.id == 'creative-kpi-goal') {
        kpi_goal = this.creative.kpi_goal
        if (this.setKpiOption == 'percents') {
          characterReg = /^[0-9.]+%?$/
        } else if (this.setKpiOption == 'times') {
          characterReg = /^[0-9]+$/
        } else {
          characterReg = /([0-9])$/
        }
        if (!characterReg.test(kpi_goal)) {
          if (this.creative.kpi_goal != null) {
            this.creative.kpi_goal = this.creative.kpi_goal.slice(0, -1)
          }
          e.preventDefault()
        }
      } else {
        kpi_goal = this.creative.kpi2_goal
        if (this.setKpiOption2 == 'percents') {
          characterReg = /^[0-9.]+%?$/
        } else if (this.setKpiOption2 == 'times') {
          characterReg = /^[0-9]+$/
        } else {
          characterReg = /([0-9])$/
        }
        if (!characterReg.test(kpi_goal)) {
          if (this.creative.kpi2_goal != null) {
            this.creative.kpi2_goal = this.creative.kpi2_goal.slice(0, -1)
          }
          e.preventDefault()
        }
      }
    },
    onChangeLogStatus($event) {
      const self = this
      self.selectedLogStatus = $event.target.value
    },
    uploadFiles: function (event) {
      const self = this

      // turn on loading spinner
      self.loading = true

      // Files to be uploaded
      const files = event.target.files

      // amount of pending files.
      let filesQueue = files.length

      let errors = 0

      // iterate over the list of files to be uploaded.
      _.forOwn(files, (file) => {
        const fileNameSplit
          = file.name.substring(0, file.name.lastIndexOf('.')) || file.name

        Utils.toBase64(file)
          .then((fileString) => {
            return {
              fileData: fileString,
              fileName: `${self.creative.campaign_id}_${self.creative.id}_${fileNameSplit}`,
              updated_date: new Date(),
              creative_id: self.creative.id,
              email: `${this.$store.getters.userInSession.email}`
            }
          })
          .then(Api.uploadGwdFile)
          .then((newFile) => {
            // add the new file data to the creative.gwdFiles array
            self.gwdFiles.push(newFile)
            // reset field input
            self.$refs.uploadFile.reset()
            // one less from the Queue
            filesQueue--

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // turn off loading spinner
              self.loading = false

              if (errors) {
                self.$refs.uploadFile.reset()
                alert(
                  'It appears that there are some files we could not upload, check it is a valid production project. Please try again.'
                )
              }
            }
          })
          .catch((error) => {
            // one less from the Queue
            filesQueue--

            errors++

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // turn off loading spinner
              self.loading = false

              if (errors) {
                self.$refs.uploadFile.reset()
                alert(
                  'It appears that there are some files we could not upload, check it is a valid production project. Please try again.'
                )
              }
            }

            self.$log.error('uploadGWDFiles', error)
          })
      })
    },
    removeFile: function (gwdFile) {
      const self = this

      if (
        confirm(
          'This will remove the file permanently. Are you sure you want to remove this File?'
        )
      ) {
        // turn on loading spinner
        self.loading = true

        Api.deleteGwdFile(gwdFile.id)
          .then(() => {
            // now, let's stage file to be removed on save.
            // find index of the asset file to be deleted, in the creatives.assetFiles
            const index = _.findIndex(self.gwdFiles, { id: gwdFile.id })

            // create an assetFile with status removed so it is removed when saved
            const removedFile = gwdFile
            removedFile.status = 'removed'

            // replace the assetFile in the creative.assetFiles with the removedFile element
            self.gwdFiles.splice(index, 1)

            // turn off loading spinner
            self.loading = false
          })
          .catch((error) => {
            self.$log.error(error)

            // turn off loading spinner
            self.loading = false

            alert('There was an issue deleting the file. Please try again')
          })
      }
    },
    downloadAsset: function (gwdFile) {
      const link = document.createElement('a')
      link.download = gwdFile.name
      link.target = '_blank'
      link.href = gwdFile.path
      link.click()
    },
    hasEventsOfThisType: function (eventId) {
      let findEvent = false

      if (this.creative.events) {
        findEvent = this.creative.events.find(
          (item) => item.event_id === eventId
        )
      }

      return Boolean(findEvent)
    },
    // alert the user if click on save creative that there's events not saved
    hasEventNotSaved: function (eventId) {
      const events = []

      if (this.creative.events) {
        this.creative.events.map((item) => {
          if (item.saved === false && this.passedValidation(item.label_name)) {
            events.push(item)
          }
        })
      }

      return events
    },
    productChanged: function (e) {
      const product = this.products.find(
        (item) => item.id == this.creative.format_id
      )
      this.productSelectedHandler(product)
    },
    onChangeEngagment: function ($event) {
      const self = this
      self.creative.engagement_type = $event.target.value
    },
    productSelectedHandler: function (product) {
      // this.$log.info(product, this.productSelected, this.products)

      // deselect previous selected
      if (this.productSelected && typeof this.productSelected === 'object')
        this.productSelected.selected = false
      // new product so airtory template must be selected
      this.airtoryTemplateSelected = null

      this.productSelected = product
      this.productSelected.selected = true

      // this.creative.product = this.productSelected.name;
      this.creative.format_id = this.creative.product = this.productSelected.id

      // prepopulate creative name
      // set the name of the creative AdvertiserName_AdFormat_CreativeLabel editable CreativeLabel
      this.creative.name = `${this.campaign.advertiser
        .split(' ')
        .join('')}_${this.productSelected.name.split(' ').join('')}_`
    },
    productionSelectedHandler: function (production) {
      // this.$log.info(production, this.productionSelected, this.productions)

      // deselect previous selected
      if (this.productionSelected) this.productionSelected.selected = false

      this.productionSelected = production
      this.productionSelected.selected = true

      this.creative.production = this.productionSelected.name
      this.creative.production_id = this.productionSelected.id
    },
    productionBuildTypeSelected: function (value) {
      this.creative.production_build_type = value.name
      this.creative.production_build_type_id = value.id
    },
    airtoryTemplateSelectedHandler: function (product) {
      // select template
      this.airtoryTemplateSelected = product
      this.creative.external_template_id
        = this.airtoryTemplateSelected.external_template_id
    },
    passedValidation: function (value) {
      // if(!value) return false;
      return value.length >= 3
      // return Utils.validateURL(value);
    },
    saveEvent: function (item) {
      item.creative_id = this.creative.id
      // Save Tracker on DB
      this.addCreativeEvents({ id: this.creative.id, events: [item] })
        .then((response) => {
          // tracker added
          // show + add more
          item.id = response[0].id
          item.saved = true
        })
        .catch((error) => {
          alert('There was an error adding the event')
        })
    },
    addEvent: function (items, eventId, saved = false) {
      const self = this

      self.$log.info('Add', items)

      // self.events.customs.push({
      items.push({
        event_id: eventId,
        creative_id: this.creative.id,
        label_name: '',
        saved,
        uploaded_event: 0
      })
    },
    removeEvent: function (index, items) {
      const self = this

      self.$log.info(
        index,
        items[index],
        this.getEventsOfTypeClicks,
        this.getEventsOfTypeEventCustoms
      )

      // if the metric has any data
      // if(items[index].label_name !== "") {
      if (items[index].id !== undefined && items[index].saved) {
        // ask before removing
        if (confirm('Are you sure you want to remove this item?')) {
          // if yes, then remove the metric
          this.removeCreativeEvents({
            id: this.creative.id,
            event: items[index]
          })
            .then((response) => {
              // tracker added
              // show + add more
              items.splice(index, 1)
            })
            .catch((error) => {
              alert('There was an error removing the event')
            })
        }
      } else {
        // if the metric is empty, just remove it.
        items.splice(index, 1)
      }
    },
    gotoSection: function (section) {
      const self = this
      // If it is a PadSquad Campaign it will only navigate to Format tab and summary tab
      if (this.isPadSquadCampaign && !self.itemId) {
        // If the format is not selected user cannot leave page till format is selected
        if (!this.productSelected) {
          this.toSection = this.currentSection = 'product'
        } else {
          this.toSection
            = this.currentSection === 'product' ? 'summary' : 'product'
          this.currentSection = this.toSection
        }
      } else {
        if (
          !this.productSelected
          && this.getCurrentSectionsGroup === this.sections
        ) {
          // remain in the same
          self.toSection = self.currentSection = 'product'
          return
        }

        // if this a new creative and if the section we are trying to go to is previous to the currentSection (More to the left)
        if (!self.itemId && self.isPrevSection(section)) {
          // set toSection so the formSubmit knows we are navigation to another section.
          self.toSection = section

          // just change currentSection so we move and bypass the validation
          self.currentSection = section
        } else {
          // if(self.isPrevSection('production') && section === 'summary' && self.creative.production){
          //   self.$log.info('select a production');
          // }

          // if prev section is production
          if (section === 'template') {
            if (this.creative.production_build_type === 'PadSquad Template') {
              self.toSection = section
            } else {
              self.toSection = 'summary'
            }
          } else {
            // otherwise let's keep the validation and
            // change toSection, which is use by the formSubmit.
            self.toSection = section
          }
        }
      }
    },
    syncAirtoryCreative: function () {
      this.fetchAirtoryCreative(this.creative.external_creative_id)
        .then((response) => {
          this.$log.info('data', response)
          if (this.creative.name !== response.cname)
            alert('Your creative name will be updated from Airtory...')
          this.creative.name = response.cname
          this.creative.creative_tag = response.tag || ''
          if (response.formatid !== 'empty')
            this.creative.external_template_id = response.formatid
          if (response.formatname === 'Creative Upload') {
            this.creative.production_build_type = 'Airtory Creative Upload'
            this.creative.production_build_type_id = 3
          }
        })
        .catch((error) => {
          alert('Theres was an error syncing the creative.')
        })
    },
    syncCreativeTag: function () {
      this.$log.info('Sync Airtory Tag')
    },
    copyTag: function (tag) {
      const self = this
      Utils.copyToClipboard(tag, false)
    },
    formSubmit: function (e) {
      const self = this

      // if toSection has any value it means that we are trying to go to a section. so...
      if (self.toSection != null) {
        // do not submit to action
        e.preventDefault()
        // then change the current section to the toSection
        self.currentSection = self.toSection

        self.toSection = null
      } else {
        // otherwise just save data
        if (this.currentSection === 'summary') {
          self.$log.info('START BUILDIND, Load iframe', this.creative)
          this.buildOnAirtory()
        } else {
          // self.saveData();
        }
      }
    },
    buildOnAirtory: function () {
      this.editOnAirtory('create')
    },
    editOnAirtory: function (type) {
      this.$log.info(`${type} in Airtory`)

      this.initializeAirtoryScript(type)

      this.sectionAfterBuilding = this.currentSection
      this.gotoSection('build')
    },
    backFromBuild: function () {
      // this.$unloadScript(process.env.VUE_APP_ENDPOINT_AIRTORY_IFRAME);
      this.currentSection = this.sectionAfterBuilding
    },
    saveData: function () {
      const self = this

      const eventsNotSaved = this.hasEventNotSaved().length
      const message
        = eventsNotSaved > 1
          ? `You have ${eventsNotSaved} events not saved. Please save or delete them.`
          : `You have ${eventsNotSaved} event not saved. Please save or delete it.`

      if (eventsNotSaved > 0) {
        alert(message)
      } else {
        if (!this.isPadSquadCampaign) {
          self.saveCreativeData()
        } else self.savePadSquadCreativeData()
      }
      this.SET_IS_TEMP(false)
      this.SET_OPEN_MODAL(false)
    },
    saveCreativeData: async function () {
      const self = this
      // if this is not a new creative...
      if (self.itemId) {
        // update the creatives (clone deep so in case it fails none of the data gets messed up)
        self
          .updateProductionCreative(_.cloneDeep(self.creative))
          // emit done event
          .then(async (response) => {
            self.updateCreativeCampaign({
              external_campaign_id: self.creative.external_campaign_id,
              creative: _.cloneDeep(self.creative)
            })
            // check if there is a lastLog and is not null, then set the payload
            if (self.lastLogCreated !== null) {
              await self
                .fetchCreativesDownload(self.creative.external_tag_id)
                .then(async (r) => {
                  self.creativeLogs.creative_id = self.creative.id
                  self.creativeLogs.zip_url = r.url
                  self.creativeLogs.html_airtory_id = r.filename.substring(
                    0,
                    r.filename.indexOf('_')
                  )
                  self.creativeLogs.updated_date = new Date()
                  self.creativeLogs.status
                  self.creativeLogs.name = self.creative.name
                  // check if self.lasLogCreated.html_airtory_id is different from self.creativeLogs.html_airtory_id
                  if (
                    self.lastLogCreated.html_airtory_id
                    !== self.creativeLogs.html_airtory_id
                  ) {
                    try {
                      await self.createCreativesLogs(self.creativeLogs)
                    } catch (error) {
                      self.$log.error(error)
                    }
                  }
                })
                .catch((error) => {
                  self.$log.error(error)
                })
              // evaluate if build is airtory creative upload, then set the creativeLog payload
            } else if (self.creative.production_build_type_id === 3) {
              await self
                .fetchCreativesDownload(self.creative.external_tag_id)
                .then(async (r) => {
                  self.creativeLogs.creative_id = self.creative.id
                  self.creativeLogs.zip_url = r.url
                  self.creativeLogs.html_airtory_id = r.filename.substring(
                    0,
                    r.filename.indexOf('_')
                  )
                  self.creativeLogs.updated_date = new Date()
                  self.creativeLogs.status
                    = self.selectedLogStatus !== null
                      ? self.selectedLogStatus
                      : 'Development'
                  self.creativeLogs.name = self.creative.name
                  try {
                    await self.createCreativesLogs(self.creativeLogs)
                  } catch (error) {
                    self.$log.error(error)
                  }
                })
                .catch((error) => {
                  self.$log.error(error)
                })
            }
            self.$emit('done', response)
            location.reload()
          })
          .catch((error) => {
            self.$log.error(error)
            // notify the user
            alert(
              'There was a problem updating this creative. Please try again.'
            )
          })
      } else {
        // create the creative (clone deep so in case it fails none of the data gets messed up)
        self
          .createProductionCreative(_.cloneDeep(self.creative))
          // emit done event
          .then(async (response) => {
            self.addCreativeCampaign({
              external_campaign_id: self.creative.external_campaign_id,
              creative: response
            })
            // evaluate if build is airtory creative upload, then set the creativeLog payload
            if (self.creative.production_build_type_id === 3) {
              await self
                .fetchCreativesDownload(response.external_creative_id)
                .then(async (r) => {
                  self.creativeLogs.creative_id = response.id
                  self.creativeLogs.zip_url = r.url
                  self.creativeLogs.html_airtory_id = r.filename.substring(
                    0,
                    r.filename.indexOf('_')
                  )
                  self.creativeLogs.updated_date = new Date()
                  self.creativeLogs.status = 'Development'
                  self.creativeLogs.name = response.name
                  try {
                    await self.createCreativesLogs(self.creativeLogs)
                  } catch (error) {
                    self.$log.error(error)
                  }
                })
                .catch((error) => {
                  self.$log.error(error)
                })
            }
            self.$emit('done', response)
            location.reload()
          })
          .catch((error) => {
            // notify the user
            alert(
              'There was a problem creating this creative. Please try again.'
            )
          })
      }
      this.SET_IS_TEMP(false)
      this.SET_OPEN_MODAL(false)
    },
    savePadSquadCreativeData: async function () {
      const self = this
      // if this is not a new creative...
      if (self.itemId) {
        // update the creatives (clone deep so in case it fails none of the data gets messed up)
        self
          .updateProductionCreative(_.cloneDeep(self.creative))
          // emit done event
          .then(async (response) => {
            self.updateCreativeCampaign({
              external_campaign_id: self.creative.external_campaign_id,
              creative: _.cloneDeep(self.creative)
            })
            // check if there is a lastLog and is not null, then set the payload
            if (self.lastLogCreated !== null) {
              await self
                .fetchCreativesDownload(self.creative.external_tag_id)
                .then(async (r) => {
                  self.creativeLogs.creative_id = self.creative.id
                  self.creativeLogs.zip_url = r.url
                  self.creativeLogs.html_airtory_id = r.filename.substring(
                    0,
                    r.filename.indexOf('_')
                  )
                  self.creativeLogs.updated_date = new Date()
                  self.creativeLogs.status
                  self.creativeLogs.name = self.creative.name
                  // check if self.lasLogCreated.html_airtory_id is different from self.creativeLogs.html_airtory_id
                  if (
                    self.lastLogCreated.html_airtory_id
                    !== self.creativeLogs.html_airtory_id
                  ) {
                    try {
                      await self.createCreativesLogs(self.creativeLogs)
                    } catch (error) {
                      self.$log.error(error)
                    }
                  }
                })
                .catch((error) => {
                  self.$log.error(error)
                })
              // evaluate if build is airtory creative upload, then set the creativeLog payload
            }
            self.$emit('done', response)
          })
          .catch((error) => {
            self.$log.error(error)
            // notify the user
            alert(
              'There was a problem updating this creative. Please try again.'
            )
          })

        // update is very opiniated if creatives add keys, make sure to remove them here so update will accept
        const keysToDelete = [
          'events',
          'campaignEndDate',
          'campaignStartDate',
          'creative_source',
          'external_campaign_id',
          'product',
          'production_build_type',
          'production_id',
          'status',
          'production'
        ]

        keysToDelete.forEach((key) => delete self.creative[key])
        await self.updatePadSquadTemporaryCreative(self.creative)
      } else {
        // create the creative (clone deep so in case it fails none of the data gets messed up)
        self
          .createProductionCreative(_.cloneDeep(self.creative))
          // emit done event
          .then(async (response) => {
            self.addCreativeCampaign({
              external_campaign_id: self.creative.external_campaign_id,
              creative: response
            })
            // evaluate if build is airtory creative upload, then set the creativeLog payload
            if (self.creative.production_build_type_id === 3) {
              await self
                .fetchCreativesDownload(response.external_creative_id)
                .then(async (r) => {
                  self.creativeLogs.creative_id = response.id
                  self.creativeLogs.zip_url = r.url
                  self.creativeLogs.html_airtory_id = r.filename.substring(
                    0,
                    r.filename.indexOf('_')
                  )
                  self.creativeLogs.updated_date = new Date()
                  self.creativeLogs.status = 'Development'
                  self.creativeLogs.name = response.name
                  try {
                    await self.createCreativesLogs(self.creativeLogs)
                  } catch (error) {
                    self.$log.error(error)
                  }
                })
                .catch((error) => {
                  self.$log.error(error)
                })
            }
            self.$emit('done', response)
            location.reload()
          })
          .catch((error) => {
            // notify the user
            alert(
              'There was a problem creating this creative. Please try again.'
            )
          })
      }
      this.SET_IS_TEMP(false)
      this.SET_OPEN_MODAL(false)
    },
    cancel: function () {
      const self = this

      this.SET_IS_TEMP(false)
      this.SET_OPEN_MODAL(false)

      // emit event
      self.$emit('done')
    },
    setCurrentSectionsGroup: function (sectionsGroup) {
      this.currentSectionsGroup = sectionsGroup

      // just change currentSection so we move and bypass the validation
      this.currentSection = sectionsGroup[0]
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('M/DD/YY h:mma')
      return formattedDate != 'Invalid date' ? formattedDate : ''
    },
    downloadCreativesZip: async function (item) {
      const a = document.createElement('a')
      const zip_url = item.zip_url.substring(item.zip_url.lastIndexOf(':') + 1)
      if (this.isPadSquadCreativeUpload) {
        a.href = `https://${zip_url}`
      } else {
        a.href = zip_url
        a.target = '_blank'
        a.download = `${item.html_airtory_id}.zip`
      }
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      this.downloadLogTextfile(item)
    },
    copyAssetUrl: function (URL) {
      const tempInput = document.createElement('input')
      tempInput.value = URL
      document.body.appendChild(tempInput)
      tempInput.select()
      tempInput.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      if (URL) {
        const tooltip = document.getElementById('tooltip')
        tooltip.innerHTML = 'Url copied!'
        tooltip.style.display = 'block'
        tooltip.style.background = '#757070'

        setTimeout(() => {
          tooltip.style.display = 'none'
        }, 2000)
      }
    },
    downloadLogTextfile: function (item) {
      setTimeout(() => {
        const text = document.createElement('a')
        const txtdata = `---Creative Upload Log Details---\n\nCreative Name: ${
          item.name
        }\nUpdated Date: ${this.getFormattedDate(item.updated_date)}\nStatus: ${
          item.status
        }\nEmail: ${item.email}\n${
          this.isPadSquadCreativeUpload ? 'Asset Url' : 'Html Airtory ID'
        }: ${
          this.isPadSquadCreativeUpload ? item.asset_url : item.html_airtory_id
        }`
        text.href
          = 'data:text/html;charset=utf-8,' + encodeURIComponent(txtdata)
        text.download = `${
          this.isPadSquadCreativeUpload ? item.asset_url : item.html_airtory_id
        }_${item.name}_${item.status}_${this.getFormattedDate(
          item.updated_date
        )}.txt`
        document.body.appendChild(text)
        text.click()
        document.body.removeChild(text)
      }, 1700)
    },
    populateForm: async function () {
      const self = this

      // self.$log.info('advertiser', self.itemId, self.advertiserId, self.advertiserName);

      // if an itemId came in it means that we are trying to load settings for an existing creative... so if there's an itemId
      if (self.itemId) {
        self.setCurrentSectionsGroup(self.sectionsDetails)
        if (self.$store.state.creatives.isPadSquadTemporaryCreative === true) {
          _.assign(self.creative, self.$store.state.creatives.temporaryCreative)

          self.creative.events.map((item) => {
            item.saved = true
          })

          self.creative.production
            = self.creative.production || 'Build Your Own'
          self.creative.production_build_type
            = self.buildAirtoryTypes.find(
              (item) => item.id === self.creative.production_build_type_id
            ).name || self.buildAirtoryTypes[0].name
          self.creative.creative_source
            = self.creative.creative_source || 'PadSquad Sync'
          self.creative.product = self.productSelected = self.creative.format_id
          // self.creative.status = self.creativeStatus.find( item => item.id == self.creative.status_id).name;

          // since the input[type=date] requires YYY-MM-DD format (we are using ISO date format), let's use a local property to manage it and use a watch to update the dates in the object
          self.campaignStartDateInput = self.campaign.start_date
            ? self.$moment(self.campaign.start_date).utc().format('YYYY-MM-DD')
            : ''
          self.campaignEndDateInput = self.campaign.end_date
            ? self.$moment(self.campaign.end_date).utc().format('YYYY-MM-DD')
            : ''

          // validate actual kpi and set the value
          self.onChangeKPI(self, 'kpi1')
          self.onChangeKPI(self, 'kpi2')

          // validate engagment type and set the value.Defautl is 'Engagement Rate'
          self.creative.engagement_type === 'Fallback Engagement Rate'
            ? (self.creative.engagement_type = 'Fallback Engagement Rate')
            : (self.creative.engagement_type = 'Engagement Rate')
        } else {
          self.creative.id = self.itemId

          self.creative.events.map((item) => {
            item.saved = true
          })

          // let's fetch the creative

          if (self.$store.state.creatives.openPadSquadModal === true) {
            _.assign(self.creative, self.$store.state.creatives.creative)

            self.currentSection = self.$store.state.creatives.creativeModalSection

            self
              .fetchPadSquadCreativeLogsById(
                self.$store.state.creatives.creative.id
              )
              .then((data) => {
                if (data.length > 0) {
                  self.lastLogCreated = data[data.length - 1]
                  // store the last log created so we can compare it later
                  self.creativeLogs.status = self.lastLogCreated.status
                  self.uploadedCreativeLogs = data
                } else {
                  self.creativeLogs.status = 'Development'
                  self.lastLogCreated = null
                }
              })
              .catch((error) => {
                self.$log.error(error)
              })

            self.creative.events.map((item) => {
              item.saved = true
            })

            self.creative.production
              = self.creative.production || 'Build Your Own'
            self.creative.production_build_type
              = self.buildAirtoryTypes.find(
                (item) => item.id === self.creative.production_build_type_id
              ).name || self.buildAirtoryTypes[0].name
            self.creative.creative_source
              = self.creative.creative_source || 'PadSquad Sync'
            self.creative.product = self.productSelected
              = self.creative.format_id
            // self.creative.status = self.creativeStatus.find( item => item.id == self.creative.status_id).name;

            // since the input[type=date] requires YYY-MM-DD format (we are using ISO date format), let's use a local property to manage it and use a watch to update the dates in the object
            self.campaignStartDateInput = self.campaign.start_date
              ? self
                  .$moment(self.campaign.start_date)
                  .utc()
                  .format('YYYY-MM-DD')
              : ''
            self.campaignEndDateInput = self.campaign.end_date
              ? self.$moment(self.campaign.end_date).utc().format('YYYY-MM-DD')
              : ''

            // validate actual kpi and set the value
            self.onChangeKPI(self, 'kpi1')
            self.onChangeKPI(self, 'kpi2')

            // validate engagment type and set the value.Defautl is 'Engagement Rate'
            self.creative.engagement_type === 'Fallback Engagement Rate'
              ? (self.creative.engagement_type = 'Fallback Engagement Rate')
              : (self.creative.engagement_type = 'Engagement Rate')
          } else {
            self
              .fetchById(self.itemId)
              .then((data) => {
                // let creatif = _.cloneDeep(this.productionCreativeById(self.itemId));
                // let creatif = self.creativeProp;
                self.$log.info('CREATIVE', data)

                _.assign(self.creative, data)

                // set the events to saved
                self.creative.events.map((item) => {
                  item.saved = true
                })

                // fetch fetchCreativesLogsById
                if (!this.isPadSquadProductionBuildType) {
                  self
                    .fetchCreativesLogsById(self.itemId)
                    .then((data) => {
                      if (data.length > 0) {
                        self.lastLogCreated = data[data.length - 1]
                        // store the last log created so we can compare it later
                        self.creativeLogs.status = self.lastLogCreated.status
                        self.uploadedCreativeLogs = data
                      } else {
                        self.creativeLogs.status = 'Development'
                        self.lastLogCreated = null
                      }
                    })
                    .catch((error) => {
                      self.$log.error(error)
                    })
                } else {
                  self
                    .fetchPadSquadCreativeLogsById(self.itemId)
                    .then((data) => {
                      if (data.length > 0) {
                        self.lastLogCreated = data[data.length - 1]
                        // store the last log created so we can compare it later
                        self.creativeLogs.status = self.lastLogCreated.status
                        self.uploadedCreativeLogs = data
                      } else {
                        self.creativeLogs.status = 'Development'
                        self.lastLogCreated = null
                      }
                    })
                    .catch((error) => {
                      self.$log.error(error)
                    })
                }
                self.creative.production
                  = self.creative.production || 'Build Your Own'
                self.creative.production_build_type
                  = self.buildAirtoryTypes.find(
                    (item) => item.id === self.creative.production_build_type_id
                  ).name || self.buildAirtoryTypes[0].name
                self.creative.creative_source
                  = self.creative.creative_source || 'Airtory Sync'
                self.creative.product = self.productSelected
                  = self.creative.format_id
                // self.creative.status = self.creativeStatus.find( item => item.id == self.creative.status_id).name;

                // since the input[type=date] requires YYY-MM-DD format (we are using ISO date format), let's use a local property to manage it and use a watch to update the dates in the object
                self.campaignStartDateInput = self.campaign.start_date
                  ? self
                      .$moment(self.campaign.start_date)
                      .utc()
                      .format('YYYY-MM-DD')
                  : ''
                self.campaignEndDateInput = self.campaign.end_date
                  ? self
                      .$moment(self.campaign.end_date)
                      .utc()
                      .format('YYYY-MM-DD')
                  : ''

                // validate actual kpi and set the value
                self.onChangeKPI(self, 'kpi1')
                self.onChangeKPI(self, 'kpi2')

                // validate engagment type and set the value.Defautl is 'Engagement Rate'
                self.creative.engagement_type === 'Fallback Engagement Rate'
                  ? (self.creative.engagement_type = 'Fallback Engagement Rate')
                  : (self.creative.engagement_type = 'Engagement Rate')
              })
              .catch((error) => {
                alert(
                  'There was a problem loading this creative. Please report this issue to simpli@padsquad.com. Creative Id: '
                    + self.itemId
                )
                self.$log.error(error)
                // emit event to close the modal.
                self.$emit('done')
              })
          }
          // Fetches GWD files
          self
            .fetchCreativeGwdFiles(self.itemId)
            .then(async (data) => {
              // self.gwdFiles = Array.from(JSON.parse(JSON.stringify(data)))
              self.gwdFiles = Object.values(data)
            })
            .catch((error) => {
              self.$log.error(error)
            })
        }
      } else {
        self.creative.engagement_type = 'Engagement Rate'
        self.setCurrentSectionsGroup(self.sections)
      }

      // copy formats and add selected property for reactivity with Product prop
      const _productsRef = _.map(self.creativeFormatsNoDeprecated, _.cloneDeep)
      self.products = _productsRef.map((item) => {
        item.selected = false
        return item
      })
      // self.$log.info(self.products);

      // copy formats and add selected property for reactivity with Product prop
      const _productionsRef = _.map(self.productionTypes, _.cloneDeep)
      self.productions = _productionsRef.map((item) => {
        item.selected = false
        // select the first for default
        // item.selected = (item.id === 1) ? true : false;
        item.disabled = item.id === 2
        return item
      })
      const ownBuild = self.productions[0]
      self.productionSelectedHandler(ownBuild)
      self.loading = false
    },
    isPrevSection: function (toSection) {
      const self = this

      // if we are attempting to go to a section
      if (toSection) {
        // get currentSection index
        const currentSectionIndex
          = self.getCurrentSectionsGroup.indexOf(self.currentSection) + 1

        // get toSection index
        const toSectionIndex
          = self.getCurrentSectionsGroup.indexOf(toSection) + 1

        // if the toSection index is < than the currentSection,
        // then the validation is omited (all required fields will not be required on this case)
        // so return the comparison result;
        return toSectionIndex < currentSectionIndex
      } else {
        // otherwise just make it false so validation stays in place
        return false
      }
    },
    messageFromAirtoryIframe: function (tags) {
      this.$log.info('Message from iframe', tags)

      // {
      //   ad_campaign:{
      //     campaignid: "padsquad"
      //     cname: "padsquad"
      //   },
      //  ad_id:
      //   ad_format: "Press+Hold"
      //   ad_formatid: "296"
      //   ad_name: "hkjhjkhjk"
      //   ad_type: "native"
      //   dimension: {height: "responsive", width: "responsive"}
      //   preview_url: "https://bit.ly/2LzSzxy"
      //   serve_url: "https://bit.ly/2WA1hCs"
      //   tag: 'script'
      //   tagid: "49a66494bb321bc1aa2ed2217c6a794a"
      // }

      // UPDATE CREATIVE
      this.creative.external_creative_id = this.creative.external_tag_id
        = tags.tagid
      this.creative.name = tags.ad_name
      // this.creative.format_id = tags.ad_formatid;
      this.creative.creative_tag = tags.tag
      this.creative.external_campaign_id = tags.ad_campaign.campaignid

      // Go to details
      this.setCurrentSectionsGroup(this.sectionsDetails)
    },
    initializeAirtoryScript: function (type = 'create', init = false) {
      // TODO Add Airtory campaign id from props
      // this.config.campaignId = this.airtoryCampaignId;

      const self = this

      const callback = function (tags) {
        // called when you export the display creative
        self.messageFromAirtoryIframe(tags)
        self.logStatus = true
        // Your ad management logic goes here
      }

      // Script is loaded, do something
      // TODO set this variables in env files

      // WIDGET INITIALIZATION
      /** ***** Widget Home Page *******/
      // air_embed.init(self.config, function(tags){
      //   //called when the creative is exported
      //   self.messageFromAirtoryIframe(tags);
      // });

      // let filters = {
      //     adType: self.adTypes[1].name,
      //     adSize: self.adSizes[1].name,
      //     adWidth: 300,
      //     adHeight: 250,
      //     campaignId: self.creative.external_campaign_id,
      //     creativeName: 'Clorox Scentiva Press+Hold'
      // };

      /** ***** List formats based on the filters *******/
      // air_embed.init(self.config, callback, filters);
      // air_embed.init(self.config, callback);

      self.$log.info('AT', self.creative)

      if (type === 'create') {
        // TODO add the campaign id the the config.userName

        if (
          this.creative.production_build_type === 'Airtory Template'
          && this.airtoryTemplateSelected === null
        ) {
          const filters = {
            // adType: self.adTypes[4].name,
            // adSize: self.adSizes[1].name,
            // adWidth: 300,
            // adHeight: 250,
            campaignId: self.creative.external_campaign_id,
            creativeName: self.creative.name
          }

          /** ***** Widget Home Page *******/
          air_embed.init(self.config, callback, filters)
        } else {
          // NEW CREATIVE TEMPLATE or ASSET UPLOAD
          const options = {
            formatId:
              this.creative.production_build_type === 'Airtory Creative Upload'
                ? 'empty'
                : this.airtoryTemplateSelected.external_template_id,
            campaignId: self.creative.external_campaign_id, // '16a946cc902771319f5e31377227006a',
            creativeName: self.creative.name
          }

          self.$log.info(options)

          /** ***** Widget Home Page *******/
          air_embed.create(self.config, callback, options)
        }
      }

      if (type === 'edit') {
        // EDIT CREATIVE
        const options = {
          creativeId: self.creative.external_creative_id, // '49a66494bb321bc1aa2ed2217c6a794as'
          formatId:
            this.creative.production_build_type === 'Airtory Creative Upload'
              ? 'empty'
              : Number(self.creative.external_template_id)
                || self.creative.format_id, // 296 test
          // campaignId: self.creative.external_campaign_id,
          creativeName: self.creative.name
        }

        /** ***** Widget Home Page *******/
        air_embed.edit(self.config, callback, options)
      }

      // CREATIVE LISTING
      // To display Display creatives list of the user
      // air_embed.display(self.config, "display", function(tags){
      //   //called when the creative is exported
      //   self.messageFromAirtoryIframe(tags);
      // });

      /** ***** Display creatives list of the user of width 300px & height 250px *******/
      // const size = { width: 300, height: 250 }
      // air_embed.display(self.config, "display", callback, size);

      // TO display Video creatives list of the user
      // air_embed.display(self.config, "video", function(tags){
      //   //called when the creative is exported
      //   self.messageFromAirtoryIframe(tags);
      // });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';

#url-id,
input[type='number'] {
  min-width: 100%;
  height: 36px;
  padding-left: 1.3rem;
}

.section-label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: $gray6;
}

.info-label {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  color: $gray6;
}

.products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: $spacing-unit;
  grid-row-gap: $spacing-unit;
}

.message {
  position: relative;
  text-align: center;
  color: white;
  background: $green8;
  padding: 7px 0;
  margin: -10px 0 0;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin: $spacing-unit * 1.5 0;
}

.sModal__body__search {
  position: relative;
  padding-top: 150px;

  .moduleFilterModal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: -10px auto 0;
    // padding-top: 2rem;

    .moduleFilter__searchFilter {
      margin-right: 0 !important;
      display: none;
    }
  }

  .formField--search {
    margin: 1rem auto 4rem !important;
  }
}

.col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $spacing-unit;
  grid-row-gap: $spacing-unit;
}

.mt5 {
  margin-top: $spacing-unit * 3;
}

.formField label {
  color: $primary2;
}

.button-inline {
  height: 40px;
  margin: 0 0 10px 10px;
}

.radio-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  .formField {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.df {
  display: flex;
  align-items: center;
}

.airtoryButton {
  padding-left: 36px !important;
  background-image: url(../../../assets/airtory-logo.png) !important;
  background-position: center left 10px !important;
  background-repeat: no-repeat !important;
  background-size: 19px 19px !important;
}

.padSquadButton {
  padding-left: 36px !important;
  background-image: url(../../../assets/ps_icon_pink.png) !important;
  background-position: center left 10px !important;
  background-repeat: no-repeat !important;
  background-size: 19px 19px !important;
}

.backButton {
  padding: 0 1rem 0 0;
  pointer-events: auto;
  background: transparent !important;
  border: none !important;
}

#ay-ad__air_whitelabel {
  width: 100%;
  height: calc(100vh - 130px);

  .air-widget__content {
    height: 100% !important;
    margin: 0 auto !important;
    width: 100% !important;
  }
}

.noHeight {
  height: 0px !important;
  display: none;
}

.logTableEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.button-third {
  width: 150px;
  max-width: 150px;
}
.copy-event {
  text-align: right;
  color: #646464;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  visibility: hidden;
  margin-left: -4rem;
  pointer-events: none;
}
.show :hover + .copy-event {
  visibility: visible;
}
.copy-event :hover {
  visibility: visible;
}
.copy-icon {
  pointer-events: auto;
}

.contentTable td.contentTable__actions[data-v-11984d38] {
  padding: 1rem !important;
}

.gwd-tab {
  text-transform: uppercase;
  font-size: 10.5px;
}

.copyAssetUrlTooltip {
  position: absolute;
  display: none;
  top: 71%;
  left: 87%;
  background-color: transparent;
  white-space: nowrap;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 9999;
}

.tooltip {
  position: absolute;
  top: -75px;
  left: 30px;
  word-wrap: break-word;
  max-width: 830px;
  background-color: #757070;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 999;
}
</style>
