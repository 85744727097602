<template>
  <div class="contentTableTools">

    <div class="contentTableTools__left">
      <slot name="left"></slot>
    </div>

    <div class="contentTableTools__right">
      <slot name="right"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ContentTableTools',
  props: {}
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.contentTableTools {
  width: 100%;
  display: flex;
  margin-bottom: $spacing-unit;

  &__left {
    flex-grow: 1;
    text-align: left;
    display: none;
    align-self: flex-end;
  }

  &__right {
    flex-grow: 1;
    text-align: right;
    align-self: flex-end;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: $spacing-unit * 2;

    &__left {
      flex-grow: 1;
      text-align: left;
      display: block;

      &>* {
        margin-right: $spacing-unit;
      }
    }

    & .formField {
      margin-top: $spacing-unit;
      margin-bottom: 0px;
      display: inline-block;
    }

    & .pagination {
      margin-bottom: auto;
      white-space: nowrap;
    }
  }

}
</style>
