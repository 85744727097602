<script setup>
import { computed } from 'vue'

const props = defineProps({
  previewSettings: {
    type: Object,
    required: true
  }
})

const landscape = computed(() => props.previewSettings.landscape)
</script>

<template>
  <div
    class="tablet-shell d-flex justify-content-end align-items-center"
    :class="[landscape ? 'flex-row tablet-shell__landscape' : 'flex-column']"
  >
    <div
      class="tablet-speaker"
      :class="{'tablet-speaker__landscape': landscape}"
    />
    <div
      class="tablet-screen tablet-content d-flex overflow-hidden"
      :class="[landscape ? 'flex-row tablet-screen__landscape' : 'flex-column']"
    >
      <div
        class="tablet-top-bar"
        :class="[landscape ? 'tablet-top-bar__landscape' : '']"
      />
      <div class="flex-grow-1 overflow-hidden">
        <slot name="iFrameContent">empty</slot>
      </div>
    </div>
    <div class="tablet-home-button"/>
  </div>
</template>

<style scoped lang="scss">
.tablet-shell {
  height: 810px;
  width: 550px;
  border-radius: 40px;
  border: 1px solid #CACACA;

  &__landscape {
    height: 550px;
    width: 810px;
  }
}
.tablet-screen {
  height: 680px;
  width: 510px;
  border: 1px solid #CACACA;
  border-radius: 0px 0px 40px 40px;

  &__landscape {
    height: 510px;
    width: 680px;
    border-radius: 0px 40px 40px 0px;
  }
}
.tablet-speaker {
  width: 60px;
  height: 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  margin: auto;

  &__landscape {
    height: 60px;
    width: 10px;
  }
}
.tablet-top-bar {
  height: 20px;
  width: 100%;
  border-bottom: 1px solid #CACACA;
  background-color: #fff;

  &__landscape {
    height: 100%;
    width: 20px;
  }
}
.tablet-home-button {
  height: 35px;
  width: 35px;
  margin: auto;
  border-radius: 100%;
  border: 3px solid #F0F0F0;
}
.tablet-content {
  background-color: #F0F0F0;
}
.overflow-hidden {
  overflow: hidden;
}
</style>
