import ApiCreativeProduction from '@/helpers/ApiCreativeProduction.js'

/** VUEX module for Live Polls  **/

export default {
    namespaced: true,
    // -----------------------------------------------------------------
    state: {
        livePolls: [],
        poll: null
    },
    // -----------------------------------------------------------------
    getters: {
        list: (state) => {
            return state.livePolls;
        },
        pollById: (state, getters) => id => {
            const polls = getters.livePolls.find(item => item.id === id);
            return polls;
        }
    },
    // -----------------------------------------------------------------
    mutations: {
        SET_LIVE_POLL_LIST (state, payload) {
            state.livePolls = payload
        },
        SET_BY_ID (state, payload) {
            state.poll = payload;
        },
        CREATE (state, payload) {
            state.livePolls.push(payload);
        },
        DELETE: (state, id) => {
            const index = state.livePolls.findIndex(item => item.id === id);
            if (index !== -1) {
                state.livePolls.splice(index, 1);
            }
        },
        UPDATE: (state, payload) => {
            state.livePolls.push(payload)
        },
    },
    // -----------------------------------------------------------------
    actions: {
        fetch (context) {
            // app status: loading
            context.dispatch('registerTask', 'fetchLivePollList', { root: true })

            return new Promise((resolve, reject) => {
                ApiCreativeProduction.getLivePollList()
                    .then(data => context.commit('SET_LIVE_POLL_LIST', data))
                    .then(data => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchLivePollList', { root: true })
                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchLivePollList', { root: true })
                        reject(error)
                    })
            })
        },
        fetchLivePollById: (context, pollId) => {
            // app status: loading
            context.dispatch('registerTask', 'fetchLivePollById', { root: true })
            return new Promise((resolve, reject) => {
                ApiCreativeProduction.getLivePollById(pollId)
                    .then(data => {
                        context.commit('SET_BY_ID', data)
                        // app status: ready
                        context.dispatch('completeTask', 'fetchLivePollById', { root: true })

                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'fetchLivePollById', { root: true })
                        reject(error)
                    });
            });
        },
        create(context, livePoll) {
            context.dispatch('registerTask', 'createLivePoll', { root: true })
            return new Promise((resolve, reject) => {
                ApiCreativeProduction.createLivePoll(livePoll)
                .then(data => {
                        context.commit('CREATE', data)
                        context.dispatch('completeTask', 'createLivePoll', { root: true })
                        resolve(data)
                    })
                    .catch(error => {
                        // app status: ready
                        context.dispatch('completeTask', 'createLivePoll', { root: true })
                        reject(error)
                    })
                    .finally(() => {
                        context.dispatch('completeTask', 'createLivePoll', { root: true })
                    })
            })
        },
        delete: (context, id) => {
            context.dispatch('registerTask', 'deletePoll', { root: true })
            return new Promise((resolve, reject) => {
                ApiCreativeProduction.deleteLivePoll(id)
                    .then(data => {
                        context.commit('DELETE', id)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        context.dispatch('completeTask', 'deletePoll', { root: true })
                    })
            })
        },
        update(context, livePoll) {
            context.dispatch('registerTask', 'updatePoll', { root: true })
            return new Promise((resolve, reject) => {
                ApiCreativeProduction.updateLivePoll(livePoll)
                    .then(data => {
                        context.commit('UPDATE', livePoll)
                        context.dispatch('completeTask', 'updatePoll', { root: true })
                        resolve(data)
                    })
                    .catch(error => {
                        context.dispatch('completeTask', 'updatePoll', { root: true })
                        reject(error)
                    })
            })
        }
    }
}
