import { ref, computed } from 'vue'

const tempValue = computed(() => {
  const tempField = fields.value.find(x => x.name === 'temperature')
  const tempNum = tempField.customOptions.find(x => x.type === 'number').value
  const tempType = tempField.customOptions.find(x => x.type === 'select').value
  return `${tempNum} ${tempType}`
})

const initDate = () => {
  const date = new Date()
  const localDate = new Date(date - date.getTimezoneOffset() * 60000)

  localDate.setSeconds(null)
  localDate.setMilliseconds(null)
  console.log(localDate.toISOString())
  return localDate.toISOString().slice(0, -8)
}

const fields = ref([
  {
    name: 'date',
    display: 'Date/Time',
    value: '',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'datetime-local'
      }
    ]
  },
  {
    name: 'city',
    display: 'City',
    value: '',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'text',
        placeholder: 'Enter city name...'
      }
    ]
  },
  {
    name: 'state',
    display: 'State',
    value: '',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'text',
        placeholder: 'Enter state name...'
      }
    ]
  },
  {
    name: 'country',
    display: 'Country',
    value: '',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'text',
        placeholder: 'Enter country name...'
      }
    ]
  },
  {
    name: 'zipCode',
    display: 'Zip Code',
    value: '',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'text',
        placeholder: 'Enter zip code...'
      }
    ]
  },
  {
    name: 'weather',
    display: 'Weather',
    value: 'Clear',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'select',
        options: [
          'Clear',
          'Clouds',
          'Rain',
          'Snow',
          'Mist',
          'Fog',
          'Tornado'
        ]
      }
    ]
  },
  {
    name: 'temperature',
    display: 'Temperature',
    value: '10',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'number'
      }
    ]
  },
  {
    name: 'temperatureUnit',
    display: 'Temperature Unit',
    value: 'Fahrenheit',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'select',
        options: ['Fahrenheit', 'Celsius', 'Kelvin']
      }
    ]
  },
  {
    name: 'day',
    display: 'Day of Week',
    value: 'Sunday',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'select',
        options: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ]
      }
    ]
  },
  {
    name: 'wind',
    display: 'Wind',
    value: '1',
    isExpanded: false,
    selectedOption: 'Auto',
    customOptions: [
      {
        type: 'number'
      }
    ]
  }
])

export { fields, initDate }
