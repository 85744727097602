import Vue from 'vue'
import Vuex from 'vuex'
// import AmazonCognitoVuexModule from 'amazon-cognito-vuex-module';
import Api from '@/helpers/Api'
import _ from 'lodash'

import campaignsModule from './modules/campaigns'
import placementsModule from './modules/placements'
import tagGeneratorModule from './modules/tags'
import advertiserModule from './modules/advertisers'
import quickviewModule from './modules/quickview'
import analyticsModule from './modules/analytics'
import creativesModule from './modules/creatives'
import locationFeederModule from './modules/locationFeeders'
import livePollsModule from './modules/livePolls'

import userModule from './modules/user'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tasksQueue: [],
    modalOpen: false,
    downloading: false,
    dataSource: 'Simpli',
    doNotUsePreloader: false
  },
  getters: {
    appStatus: state => state.tasksQueue.length > 0 ? 'busy' : 'ready',
    appModalOpen: state => state.modalOpen,
    fileDownloading: state => state.downloading,
    dataSource: state => state.dataSource,
    doNotUsePreloader: state => state.doNotUsePreloader
  },
  mutations: {
    REGISTER_TASK: (state, taskName) => {
      state.tasksQueue.push(taskName)
    },
    COMPLETE_TASK: (state, taskName) => {
      state.tasksQueue.splice(state.tasksQueue.indexOf(taskName), 1)
    },
    REGISTER_MODAL_OPENED: state => {
      state.modalOpen = true
    },
    REGISTER_MODAL_CLOSED: state => {
      state.modalOpen = false
    },
    REGISTER_DOWNLOADING_STARTED: state => {
      state.downloading = true
    },
    REGISTER_DOWNLOADING_COMPLETED: state => {
      state.downloading = false
    },
    SET_DO_NOT_USE_LOADER: state => {
      state.doNotUsePreloader = true
    },
    UNSET_DO_NOT_USE_LOADER: state => {
      state.doNotUsePreloader = false
    }
  },
  actions: {
    registerTask: (context, taskName) => context.commit('REGISTER_TASK', taskName),
    completeTask: (context, taskName) => context.commit('COMPLETE_TASK', taskName),
    registerModalOpened: context => context.commit('REGISTER_MODAL_OPENED'),
    registerModalClosed: context => context.commit('REGISTER_MODAL_CLOSED'),
    registerDownloadingStarted: context => context.commit('REGISTER_DOWNLOADING_STARTED'),
    registerDownloadingCompleted: context => context.commit('REGISTER_DOWNLOADING_COMPLETED')
  },
  modules: {
    // cognito: new AmazonCognitoVuexModule({
    //     region: process.env.VUE_APP_COGNITO_REGION,
    //     userPoolId: process.env.VUE_APP_COGNITO_USERPOOLID,
    //     clientId: process.env.VUE_APP_COGNITO_CLIENTID
    // }),
    // users: {
    //     actions: {
    //         /**
    //          * user info from API.
    //          * @param {number} userId - Cognito Username
    //          */
    //         fetchUserInfo: (context, userId) => {
    //             return new Promise((resolve, reject) => {
    //                 // get the info from our server
    //                 axios.get( `${process.env.VUE_APP_BACKEND_ENDPOINT}user/${userId}`)
    //                 .then( response => {
    //                     resolve( response.data );
    //                 })
    //                 .catch(reject);
    //             });
    //         }
    //     }
    // },
    // userSession: {
    //     state: {
    //         user: null
    //     },
    //     getters: {
    //         userInSession: state => {
    //             return state.user;
    //         }
    //     },
    //     mutations: {
    //         SET_USER_SESSION: (state, userData) => {
    //             state.user = userData;
    //         }
    //     },
    //     actions: {
    //         setUserSession: (context, userData) => {
    //             return new Promise( (resolve, reject) => {
    //                 context.commit("SET_USER_SESSION", userData)
    //                 resolve(userData);
    //             });
    //         },
    //         /**
    //          * user info from API.
    //          * @param {number} userId - Cognito Username
    //          */
    //         fetchUserInSessionInfo: (context, userId) => {
    //             return new Promise((resolve, reject) => {
    //                 Api.getUser(userId)
    //                 .then(userData => context.dispatch('setUserSession', userData))
    //                 .then(userData => resolve(userData) )
    //                 .catch(error => reject(error));
    //             });
    //         }
    //     }
    // },
    users: userModule,
    taxonomies: {
      state: {
        verticals: [],
        campaignSources: [{ name: 'Manual', id: 0 }, { name: 'Celtra Sync', id: 1 }, { name: 'Airtory Sync', id: 2 }, { name: 'PadSquad', id: 3}],
        productionTypes: [{ name: 'Build Your Own', id: 1, description: 'Use our builder to upload assets and compose your creative. Afterward we will QA and move over the Creative to the Campaign Manager, where you can generate tags and go live.' }, { name: 'Managed Production', id: 2, description: 'PadSquad builds the creative for you. We will deliver The creative via a preview link, which you can use for approval. Afterward we will QA and move over the Creative to the Campaign Manager, where you can generate tags and go live.' }],
        buildAirtoryTypes: [{'name':'PadSquad Template', 'id':1}, {'name':'Airtory Template', 'id':2}, {'name':'Airtory Creative Upload', 'id':3}, {'name':'PadSquad Creative Upload', 'id':4}],
        status: [{ name: 'Inactive', id: 0 }, { name: 'Active', id: 1 }],
        creativeStatus: [{ name: 'Production', id: 1 }, { name: 'Creative QA', id: 2 }, { name: 'Ready For Traffic', id: 3 }],
        creativeFormats: [],
        creativeTypes: [],
        dealTypes: [],
        KPIs: [],
        partners: [],
        productTypes: [{ name: 'Video Scroll', id: 0 }, { name: 'Takeover', id: 1 }],
        creativeTagTypes: [{ name: 'HTML5', id: 1 }],
        formatTypes: [{ name: 'Scroller', id: 3 }, { name: 'Format One', id: 5 }],
        airtoryTemplates: [
          // {
          //     name: “template A”,
          //     format_id: 297,
          //     template_source: “Airtory Sync”,
          //     external_template_id: “1234-sf332-ssdfsd33”,
          //     demo_asset_url: “httt://url.test.com”,
          //     main_kpi_id: 1
          // }
        ],
        usersRoles: [],
        allLists: []
      },
      getters: {
        verticals: state => {
          return state.verticals
        },
        creativeFormats: state => {
          return state.creativeFormats
        },
        creativeFormatsNoDeprecated: getters => {
          const formatsNoDeprecated = _.filter(getters.creativeFormats, item => item.deprecated === 0)
          return formatsNoDeprecated
        },
        creativeTypes: state => {
          return state.creativeTypes
        },
        dealTypes: state => {
          return state.dealTypes
        },
        KPIs: state => {
          return state.KPIs
        },
        partners: state => state.partners,
        partnersVerification: getters => {
          return _.filter(getters.partners, item => item.type === 'Verification')
        },
        partnersMeasurement: getters => {
          return _.filter(getters.partners, item => item.type === 'Measurement')
        },
        Status: state => {
          return state.status
        },
        productTypes: state => {
          return state.productTypes
        },
        formatTypes: state => {
          return state.formatTypes
        },
        creativeTagTypes: state => {
          return state.creativeTagTypes
        },
        usersRoles: state => {
          return state.usersRoles
        },
        getVerticalById: (state) => (id) => {
          return state.verticals[id]
        },
        campaignSources: state => {
          return state.campaignSources
        },
        productionTypes: state => {
          return state.productionTypes
        },
        buildAirtoryTypes: state => {
          return state.buildAirtoryTypes
        },
        creativeStatus: state => {
          return state.creativeStatus
        },
        airtoryTemplates: state => {
          return state.airtoryTemplates
        },
        getFormatById: (state) => (id) => {
          return _.find(state.creativeFormats, (x) => { return x.id === id })
        }
      },
      mutations: {
        SET_VERTICALS: (state, verticals) => {
          // save items by id for easier mapping with creatives data
          state.verticals = _.keyBy(verticals, 'id')
        },
        SET_CREATIVE_FORMATS: (state, creativeFormats) => {
          // save items by id for easier mapping with creatives data
          state.creativeFormats = _.keyBy(creativeFormats, 'id')
        },
        SET_CREATIVE_TYPES: (state, creativeTypes) => {
          // save items by id for easier mapping with creatives data
          state.creativeTypes = _.keyBy(creativeTypes, 'id')
        },
        SET_DEAL_TYPES: (state, dealTypes) => {
          // save items by id for easier mapping with creatives data
          state.dealTypes = _.keyBy(dealTypes, 'id')
        },
        SET_KPIS: (state, KPIs) => {
          // save items by id for easier mapping with creatives data
          state.KPIs = _.keyBy(KPIs, 'id')
        },
        SET_PARTNERS: (state, partners) => {
          // save items by id for easier mapping with creatives data
          state.partners = _.keyBy(partners, 'id')
        },
        SET_PRODUCT_TYPES: (state, productTypes) => {
          state.productTypes = _.keyBy(productTypes, 'id')
        },
        SET_FORMAT_TYPES: (state, formatTypes) => {
          state.formatTypes = _.keyBy(formatTypes, 'id')
        },
        SET_CREATIVE_TAG_TYPES: (state, creativeTagTypes) => {
          // save items by id for easier mapping with creatives data
          state.creativeTagTypes = _.keyBy(creativeTagTypes, 'id')
        },
        SET_CAMPAIGN_SOURCES: (state, payload) => {
          // save items by id for easier mapping with  data
          state.campaignSources = _.keyBy(payload, 'id')
        },
        SET_USERS_ROLES: (state, usersRoles) => {
          // duck punch to store the data with an ID
          usersRoles.forEach((userRole, index) => {
            usersRoles[index] = {
              id: index,
              name: userRole
            }
          })

          state.usersRoles = _.keyBy(usersRoles, 'id')
        },
        SET_ALL_LISTS: (state, payload) => {
          state.allLists = payload

          state.verticals = _.keyBy(payload.verticals, 'id')

          // for testing in Airtory
          // let _copy = _.cloneDeep(payload.formats);
          // let testAirtoryFormat = { name: 'Press+Hold', id: 296 };
          // _copy.push(testAirtoryFormat);
          // state.creativeFormats = _.keyBy(_copy, "id");
          state.creativeFormats = _.keyBy(payload.formats, 'id')
          state.airtoryTemplates = _.keyBy(payload.templates, 'id')
          /// ///
          state.creativeTypes = _.keyBy(payload.creative_types, 'id')
          state.dealTypes = _.keyBy(payload.deal_types, 'id')
          // state.KPIs = _.keyBy(payload.kpis, "id");
          // order KPIs by Name Asc
          state.KPIs = _.orderBy(payload.kpis, ['name'], ['asc'])
          state.partners = _.keyBy(payload.partners, 'id')
        }
      },
      actions: {
        fetchVerticals: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchVerticals', { root: true })

          return new Promise((resolve, reject) => {
            Api.getVerticals()
              .then(verticals => context.commit('SET_VERTICALS', verticals))
              .then(verticals => {
                // app status: ready
                context.dispatch('completeTask', 'fetchVerticals', { root: true })

                resolve(verticals)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchVerticals', { root: true })

                reject(error)
              })
          })
        },
        fetchCreativeFormats: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchCreativeFormats', { root: true })

          return new Promise((resolve, reject) => {
            Api.getCreativesFormats()
              .then(creativeFormats => context.commit('SET_CREATIVE_FORMATS', creativeFormats))
              .then(creativeFormats => {
                // app status: ready
                context.dispatch('completeTask', 'fetchCreativeFormats', { root: true })

                resolve(creativeFormats)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchCreativeFormats', { root: true })

                reject(error)
              })
          })
        },
        fetchCreativeTypes: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchCreativeTypes', { root: true })

          return new Promise((resolve, reject) => {
            Api.getCreativesTypes()
              .then(creativeTypes => context.commit('SET_CREATIVE_TYPES', creativeTypes))
              .then(creativeTypes => {
                // app status: ready
                context.dispatch('completeTask', 'fetchCreativeTypes', { root: true })

                // resolve
                resolve(creativeTypes)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchCreativeTypes', { root: true })

                reject(error)
              })
          })
        },
        fetchDealTypes: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchDealTypes', { root: true })

          return new Promise((resolve, reject) => {
            Api.getDealTypes()
              .then(dealTypes => context.commit('SET_DEAL_TYPES', dealTypes))
              .then(dealTypes => {
                // app status: ready
                context.dispatch('completeTask', 'fetchDealTypes', { root: true })

                // resolve
                resolve(dealTypes)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchDealTypes', { root: true })

                reject(error)
              })
          })
        },
        fetchKPIs: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchKPIs', { root: true })

          return new Promise((resolve, reject) => {
            Api.getMainKPIs()
              .then(KPIs => context.commit('SET_KPIS', KPIs))
              .then(KPIs => {
                // app status: ready
                context.dispatch('completeTask', 'fetchKPIs', { root: true })

                // resolve
                resolve(KPIs)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchKPIs', { root: true })

                reject(error)
              })
          })
        },
        fetchPartners: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchPartners', { root: true })

          return new Promise((resolve, reject) => {
            Api.getPartners()
              .then(Partners => context.commit('SET_PARTNERS', Partners))
              .then(Partners => {
                // app status: ready
                context.dispatch('completeTask', 'fetchPartners', { root: true })

                // resolve
                resolve(Partners)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchPartners', { root: true })

                reject(error)
              })
          })
        },
        fetchProductTypes: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchProductTypes', { root: true })

          return new Promise((resolve, reject) => {
            Api.getProductTypes()
              .then(productTypes => context.commit('SET_PRODUCT_TYPES', productTypes))
              .then(productTypes => {
                // app status: ready
                context.dispatch('completeTask', 'fetchProductTypes', { root: true })

                // resolve
                resolve(productTypes)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchProductTypes', { root: true })

                reject(error)
              })
          })
        },
        fetchFormatTypes: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchFormatTypes', { root: true })

          return new Promise((resolve, reject) => {
            Api.getFormatTypes()
              .then(formatTypes => {
                context.commit('SET_FORMAT_TYPES', formatTypes)
              })
              .then(formatTypes => {
                // app status: ready
                context.dispatch('completeTask', 'fetchFormatTypes', { root: true })

                // resolve
                resolve(formatTypes)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchFormatTypes', { root: true })

                reject(error)
              })
          })
        },
        fetchCreativeTagTypes: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchCreativeTagTypes', { root: true })

          return new Promise((resolve, reject) => {
            Api.getCreativeTagTypes()
              .then(creativeTagTypes => context.commit('SET_CREATIVE_FORMAT_TYPES', creativeTagTypes))
              .then(creativeTagTypes => {
                // app status: ready
                context.dispatch('completeTask', 'fetchCreativeTagTypes', { root: true })

                // resolve
                resolve(creativeTagTypes)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchCreativeTagTypes', { root: true })

                reject(error)
              })
          })
        },
        fetchUsersRoles: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchUsersRoles', { root: true })

          return new Promise((resolve, reject) => {
            Api.getUsersRoles()
              .then(usersRoles => {
                context.commit('SET_USERS_ROLES', usersRoles)
              })
              .then(usersRoles => {
                // app status: ready
                context.dispatch('completeTask', 'fetchUsersRoles', { root: true })

                // resolve
                resolve(usersRoles)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchUsersRoles', { root: true })

                reject(error)
              })
          })
        },
        fetchAllLists: context => {
          // app status: loading
          context.dispatch('registerTask', 'fetchAllLists', { root: true })

          return new Promise((resolve, reject) => {
            Api.getAllLists()
              .then(allLists => {
                context.commit('SET_ALL_LISTS', allLists)
              })
              .then(allLists => {
                // app status: ready
                context.dispatch('completeTask', 'fetchAllLists', { root: true })

                // resolve
                resolve(allLists)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchAllLists', { root: true })

                reject(error)
              })
          })
        },
        fetchAllTaxonomies: context => {
          context.dispatch('fetchAllLists')

          // context.dispatch('fetchVerticals');
          // context.dispatch('fetchCreativeFormats');
          // context.dispatch('fetchCreativeTypes');
          // context.dispatch('fetchDealTypes');
          // context.dispatch('fetchKPIs');
          // context.dispatch('fetchPartners');

          // request this one independant, not coming in allLists
          context.dispatch('fetchUsersRoles')

          // context.dispatch('fetchProductTypes');
          // context.dispatch('fetchFormatTypes');
          // context.dispatch('fetchCreativeTagTypes');
        }
      }
    },
    campaigns: campaignsModule,
    placements: placementsModule,
    tags: tagGeneratorModule,
    advertisers: advertiserModule,
    quickview: quickviewModule,
    analytics: analyticsModule,
    creatives: creativesModule,
    locationFeeders: locationFeederModule,
    livePolls: livePollsModule,
    showcaseCreatives: {
      state: {
        creatives: [],
        creativesPagination: {}
      },
      getters: {
        showcaseCreatives: (state, getters) => {
          const includingMappedData = state.creatives.map(creative => {
            const creatif = creative

            // map creative taxonomies into the creative
            creatif.vertical = _.get(getters.verticals, `[${creative.vertical_id}].name`, '')
            creatif.format = _.get(getters.creativeFormats, `[${creative.format_id}].name`, '')
            creatif.type = _.get(getters.creativeTypes, `[${creative.type_id}].name`, '')
            creatif.dealType = _.get(getters.dealTypes, `[${creative.deal_id}].name`, '')
            creatif.mainKPI = _.get(getters.KPIs, `[${creative.mainKPI_id}].name`, '')
            creatif.touched_on = creatif.updated_on || creatif.created_on

            return creatif
          })

          return includingMappedData
        },
        showcaseCreativeById: (state, getters) => id => {
          return getters.showcaseCreatives.find(item => item.id == id)
        },
        creativesPagination: (state, getters) => {
          return state.creativesPagination
        }
      },
      mutations: {
        SET_SHOWCASE_CREATIVES: (state, showcaseCreatives) => {
          state.creatives = showcaseCreatives
        },
        UPDATE_SHOWCASE_CREATIVE: (state, showcaseCreative) => {
          const creatifIndex = _.findIndex(state.creatives, { id: showcaseCreative.id })

          state.creatives.splice(creatifIndex, 1, showcaseCreative)
        },
        CREATE_SHOWCASE_CREATIVE: (state, showcaseCreative) => {
          state.creatives.push(showcaseCreative)
        },
        DELETE_SHOWCASE_CREATIVE: (state, showcaseCreativeId) => {
          const creatifIndex = _.findIndex(state.creatives, { id: showcaseCreativeId })

          state.creatives.splice(creatifIndex, 1)
        },
        SET_ASSETS_SHOWCASE_CREATIVE: (state, assetsInfo) => {
          const creatifIndex = _.findIndex(state.creatives, { id: assetsInfo.showcaseCreativeId })

          // let's filter assets by two main categories: Design, and everything else.
          const designImages = _.filter(assetsInfo.assets, asset => { return asset.asset_type == 'design' })
          const assetFiles = _.filter(assetsInfo.assets, asset => { return asset.asset_type != 'design' })

          // add design files and asset files to the creative in the state.
          state.creatives[creatifIndex].designImages = _.sortBy(designImages, ['order']) // sort by order
          state.creatives[creatifIndex].assetFiles = assetFiles
        },
        SET_SHOWCASE_CREATIVES_PAGINATION: (state, pagination) => {
          state.creativesPagination = pagination || {}
        }
      },
      actions: {
        modelShowcaseCreativeData: (context, showcaseCreativeData) => {
          return new Promise((resolve, reject) => {
            try {
              // there's some manipulation to do of the data since the api fails if we send more data than it is expecting, so let's do some picking of the properties
              // if campaignStartDate is not empty
              if (!_.isEmpty(showcaseCreativeData.campaignStartDate)) {
                // format it so the db accepts it
                showcaseCreativeData.campaignStartDate = Vue.moment(showcaseCreativeData.campaignStartDate).format('YYYY-MM-DD')
              } else {
                // if it's empty, let's just make it null
                showcaseCreativeData.campaignStartDate = null
              }

              // if campaignEndDate is not empty
              if (!_.isEmpty(showcaseCreativeData.campaignEndDate)) {
                // format it so the db accepts it. Adding a day to account date discrepancy
                showcaseCreativeData.campaignEndDate = Vue.moment(showcaseCreativeData.campaignEndDate).format('YYYY-MM-DD')
              } else {
                // if it's empty, let's just make it null
                showcaseCreativeData.campaignEndDate = null
              }

              // let's add the .order property to the design images elements
              _.map(showcaseCreativeData.designImages, (designImage, key) => {
                return _.merge(designImage, {
                  order: _.parseInt(key + 1)
                })
              })

              // let's add the .order property to the assetFiles elements
              _.map(showcaseCreativeData.assetFiles, (assetFile, key) => {
                return _.merge(assetFile, {
                  order: _.parseInt(key + 1)
                })
              })

              // let's add the .order property to the metrics elements
              _.map(showcaseCreativeData.performanceMetrics, (metric, key) => {
                return _.merge(metric, {
                  order: _.parseInt(key + 1)
                })
              })

              // let's trim open values, so we avoid duplicates in the db due to spaces at the end.
              showcaseCreativeData.creativeName = _.trim(showcaseCreativeData.creativeName)
              showcaseCreativeData.name = _.trim(showcaseCreativeData.name)
              showcaseCreativeData.advertiser = _.trim(showcaseCreativeData.advertiser)
              showcaseCreativeData.campaign = _.trim(showcaseCreativeData.campaign)

              // let's prepare the values we want to pick
              const showcaseCreativeDataModel = [
                'advertiser',
                'advertiser_id',
                'campaign',
                'campaignEndDate',
                'campaignStartDate',
                'demoTag',
                'description',
                'format_id',
                'keywords',
                'mainKPI_id',
                'name',
                'vertical_id',
                'deal_id',
                'designImages',
                'assetFiles',
                'performanceMetrics',
                'type_id',
                'supports_mobile',
                'supports_desktop',
                'expandable',
                'iframe_vidstream',
                'vastTag',
                'crossScreen'
              ]

              // if showcaseCreativeData contains id came in then let's add it
              if (showcaseCreativeData.id) showcaseCreativeDataModel.push('id')

              // pick the values that from our showcaseCreativeDataModel
              const showcaseCreativeDataModeled = _.pick(showcaseCreativeData, showcaseCreativeDataModel)

              resolve(showcaseCreativeDataModeled)
            } catch (error) {
              reject(error)
            }
          })
        },
        updateShowcaseCreative: (context, showcaseCreative) => {
          // app status: loading
          context.dispatch('registerTask', 'updateShowcaseCreative', { root: true })

          return new Promise((resolve, reject) => {
            context.dispatch('modelShowcaseCreativeData', showcaseCreative)
              .then(showcaseCreativeModeled => Api.updateCreative(showcaseCreativeModeled))
              .then(showcaseCreativeFromServer => {
                // before commiting the data, let's update the updated_on value of the showcaseCreativeData
                showcaseCreative.updated_on = Vue.moment().toISOString()

                // commit the non modeled data because it is more complete.
                context.commit('UPDATE_SHOWCASE_CREATIVE', showcaseCreative)
              })
              .then(() => {
                // app status: ready
                context.dispatch('completeTask', 'updateShowcaseCreative', { root: true })

                resolve(showcaseCreative)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'updateShowcaseCreative', { root: true })

                reject(error)
              })
          })
        },
        createShowcaseCreative: (context, showcaseCreative) => {
          // app status: loading
          context.dispatch('registerTask', 'createShowcaseCreative', { root: true })

          return new Promise((resolve, reject) => {
            context.dispatch('modelShowcaseCreativeData', showcaseCreative)
              .then(showcaseCreativeModeled => Api.createCreative(showcaseCreativeModeled))
              .then(showcaseCreativeFromServer => {
                showcaseCreative.id = showcaseCreativeFromServer.id
                // before commiting the data, let's update the updated_on value of the showcaseCreativeData
                showcaseCreative.updated_on = Vue.moment().toISOString()

                // commit the non modeled data because it is more complete.
                context.commit('CREATE_SHOWCASE_CREATIVE', showcaseCreative)
              })
              .then(() => {
                // app status: ready
                context.dispatch('completeTask', 'createShowcaseCreative', { root: true })

                resolve(showcaseCreative)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'createShowcaseCreative', { root: true })

                reject(error)
              })
          })
        },
        deleteShowcaseCreative: (context, showcaseCreative) => {
          // app status: loading
          context.dispatch('registerTask', 'deleteShowcaseCreative', { root: true })

          return new Promise((resolve, reject) => {
            Api.deleteCreative(showcaseCreative)
              .then(() => context.commit('DELETE_SHOWCASE_CREATIVE', showcaseCreative.id))
              .then(() => {
                // app status: ready
                context.dispatch('completeTask', 'deleteShowcaseCreative', { root: true })

                resolve(showcaseCreative)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'deleteShowcaseCreative', { root: true })

                reject(error)
              })
          })
        },
        fetchShowcaseCreatives: (context, queryString) => {
          // app status: loading
          context.dispatch('registerTask', 'fetchShowcaseCreatives', { root: true })

          return new Promise((resolve, reject) => {
            Api.getCreativesList(queryString)
              .then(showcaseCreatives => {
                context.commit('SET_SHOWCASE_CREATIVES', showcaseCreatives.creatives)
                context.commit('SET_SHOWCASE_CREATIVES_PAGINATION', showcaseCreatives.pagination)
              })
              .then(showcaseCreatives => {
                // app status: ready
                context.dispatch('completeTask', 'fetchShowcaseCreatives', { root: true })

                resolve(showcaseCreatives)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchShowcaseCreatives', { root: true })

                reject(error)
              })
          })
        },
        fetchShowcaseCreativeAssets: (context, showcaseCreativeId) => {
          // app status: loading
          context.dispatch('registerTask', 'fetchShowcaseCreativeAssets', { root: true })

          return new Promise((resolve, reject) => {
            Api.getCreativeAssets(showcaseCreativeId)
              .then(showcaseCreativeAssets => context.commit('SET_ASSETS_SHOWCASE_CREATIVE', { showcaseCreativeId, assets: showcaseCreativeAssets }))
              .then(showcaseCreativeAssets => {
                // app status: ready
                context.dispatch('completeTask', 'fetchShowcaseCreativeAssets', { root: true })

                // resolve
                resolve(showcaseCreativeAssets)
              })
              .catch(error => {
                // app status: ready
                context.dispatch('completeTask', 'fetchShowcaseCreativeAssets', { root: true })

                reject(error)
              })
          })
        }
      }
    }
  }
})
