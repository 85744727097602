<script setup>
import { ref } from 'vue'

const emit = defineEmits(['close', 'save'])
const props = defineProps(['statusType'])

const selectedStatus = ref(props.statusType || 'Development')

const statusOptions = ref([
  'Development',
  'Client Revision',
  'Internal Revision',
  'Test',
  'QA Revision',
  'Creative Optimization'
])

const closeModal = () => {
  emit('close')
}

const saveLogStatus = () => {
  emit('save', selectedStatus.value)
  emit('close')
}
</script>

<template>
  <div
    class="modal"
    @click.self="closeModal"
  >
    <div class="modal-content d-flex">
      <div class="d-flex w-100">
        <label class="label-style mb-4">Please select a status for this asset's log.
        </label>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-4">
        <select
          class="input-style"
          v-model="selectedStatus"
        >
          <option
            v-for="(option, index) in statusOptions"
            :key="index"
          >
            {{ option }}
          </option>
        </select>
        <button
          class="save-button"
          @click="saveLogStatus"
        >
          Save Log
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add your modal styles here */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  width: 30%;
  padding: 1%;
  border-radius: 8px;
}

.label-style {
  color: #141138;
  font-size: 18px;
  padding-bottom: 3px;
}

.input-style {
  height: 4%;
  padding: 1.5%;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;
  font-size: 12px;
  line-height: 1.4;
  border: solid 1px #dbdbdb;
}

.save-button {
  color: white;
  background-color: #8ad622;
  border: none;
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1.5rem;
  width: 25%;
  cursor: pointer;
}
</style>
