<script setup>
import { ref, computed, onMounted } from 'vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Navbar from '@/components/Navbar.vue'
import PencilBar from '@/components/PencilBar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import WebLinks from '@/components/WebLinks.vue'
import DspReportingTable from '@/components/DspReporting/DspReportingTable.vue'
import moment from 'moment'
import CreatePlacementListModal from '@/views/modals/XandrPlacementList.vue'

const { useActions } = createNamespacedHelpers('analytics')

const { fetchXandrPlacementLogs, createXandrPlacementLog } = useActions([
  'fetchXandrPlacementLogs',
  'createXandrPlacementLog'
])

onMounted(async () => {
  const placementList = await fetchXandrPlacementLogs(
    'orderBy=created_at&order=DESC&pageSize=10'
  )
  // changes backend 0 to false 1 to true
  placementList.placement_logs.forEach((obj) => {
    obj.included === 1 ? (obj.included = 'include') : (obj.included = 'exclude')
  })
  // changes backend date to readable format
  placementList.placement_logs.forEach((obj) => {
    obj.created_at = moment(obj.created_at)
      .utc()
      .format('MMMM Do YYYY, h:mm:ss a')
  })
  tableData.value.push(...placementList.placement_logs)
  paginationData.value = placementList.pagination
})

const searchByValue = ref('Select an option.')
const searchInputValue = ref('')
const paginationData = ref({})
const modalData = ref({
  currentComponent: null,
  userData: null
})
const moduleModal = ref(null)
const tableData = ref([])

// Model for placementLogData

const placementLogData = ref({
  deal_id: null,
  user_created: null,
  member_id: null,
  advertiser_id: null,
  included: null,
  uploaded_file_url: null,
  log_status: null,
  log_message: null
})

// To Add Fields to the Table.

const fields = ref([
  { key: 'deal_id', label: 'Deal ID' },
  { key: 'member_id', label: 'Member ID' },
  { key: 'advertiser_id', label: 'Advertiser ID' },
  { key: 'included', label: 'Include' },
  { key: 'uploaded_file_url', label: 'File Asset Url' },
  { key: 'log_message', label: 'Error Message' },
  { key: 'user_created', label: 'User' },
  { key: 'placement_count', label: 'Placement Count' },
  { key: 'created_at', label: 'Date Created' },
  { key: 'log_status', label: 'Status' }
])

// Gets current user
const currentUser = computed(() => {
  const currentUser = JSON.parse(localStorage._sUser)
  return currentUser
})
// Filter Module Data Options
const searchByIdOptions = ref({
  default: {
    label: 'Select an option.',
    value: 'default'
  },
  member_id: {
    value: 'member_id',
    label: 'Member ID'
  },
  deal_id: {
    value: 'deal_id',
    label: 'Deal ID'
  },
  advertiser_id: {
    value: 'advertiser_id',
    label: 'Advertiser ID'
  }
})

// Assigns the modal the component.
const openPlacementListModal = () => {
  modalData.value.currentComponent = CreatePlacementListModal
  moduleModal.value.show()
}

const handleBackendValues = (value) => {
  if (Array.isArray(value)) {
    return value.map((obj) => ({
      ...obj,
      included:
        obj.included === 1 || obj.included === true ? 'include' : 'exclude',
      created_at: moment(obj.created_at).utc().format('MMMM Do YYYY, h:mm:ss a')
    }))
  } else {
    return {
      ...value,
      included:
        value.included === 1 || value.included === true ? 'include' : 'exclude',
      created_at: moment(value.created_at)
        .utc()
        .format('MMMM Do YYYY, h:mm:ss a')
    }
  }
}

const handlePagination = async (event) => {
  const result = await fetchXandrPlacementLogs(
    `orderBy=created_at&order=DESC&page=${event}&pageSize=10`
  )

  tableData.value = []
  tableData.value.push(handleBackendValues(result.placement_logs))
  paginationData.value = result.pagination
}

const hideModal = () => {
  moduleModal.value.hide()
}

// Gets data emitted from Modal, assigns OBJ data.
const handleReceivedData = async (event) => {
  placementLogData.value = { ...event }

  try {
    const result = await createXandrPlacementLog(placementLogData.value)
    tableData.value.unshift(handleBackendValues(result))
    hideModal()
  } catch (error) {
    console.error('Error:', error)
    hideModal()
  }
}

const handleSearchInput = async () => {
  let searchParam

  switch (searchByValue.value) {
    case 'Deal ID':
      searchParam = `deal_id=${searchInputValue.value}`
      break
    case 'Member ID':
      searchParam = `member_id=${searchInputValue.value}`
      break
    case 'Advertiser ID':
      searchParam = `advertiser_id=${searchInputValue.value}`
      break
    default:
      return
  }

  const searchResult = await fetchXandrPlacementLogs(
    `'orderBy=created_at&order=DESC&${searchParam}`
  )

  tableData.value = []

  tableData.value.push(handleBackendValues(searchResult.placement_logs))
}

const debounce = (func, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const debouncedHandleSearchInput = debounce(handleSearchInput, 500)

const handleIdSelectClear = async () => {
  searchByValue.value = searchByIdOptions.value.default.label
  searchInputValue.value = ''

  const placementList = await fetchXandrPlacementLogs(
    'orderBy=created_at&order=DESC&pageSize=10'
  )
  // changes backend 0 to false 1 to true
  placementList.placement_logs.forEach((obj) => {
    obj.included === 1 ? (obj.included = 'include') : (obj.included = 'exclude')
  })
  // changes backend date to readable format
  placementList.placement_logs.forEach((obj) => {
    obj.created_at = moment(obj.created_at)
      .utc()
      .format('MMMM Do YYYY, h:mm:ss a')
  })
  tableData.value = []
  tableData.value.push(...placementList.placement_logs)
  paginationData.value = placementList.pagination
}
</script>

<template>
  <div class="appFrame">
    <Navbar
      :current-module-path="'tools'"
      :current-module-name="'Simpli Tools'"
    />
    <!-- Main body component page includes all content -->
    <main class="moduleFrame moduleFrame--tagManagerRepository">
      <PencilBar />
      <!-- Header for navigation/importing -->
      <ModuleHeader>
        <template #tabs>
          <ul>
            <li>
              <router-link to="/tools/location-feeder/feeds/"
                >Location Feeder</router-link
              >
            </li>
            <li selected>
              <router-link to="/tools/xandr-placements"
                >Xandr Placements</router-link
              >
            </li>
          </ul>
        </template>

        <template #title>
          <div>Xandr Placements</div>
        </template>

        <template #actions>
          <div>
            <button
              class="moduleHeader__createButton"
              @click="openPlacementListModal()"
            >
              <font-awesome-icon
                icon="table"
                class="i"
              />Upload Placement List
            </button>
          </div>
        </template>
      </ModuleHeader>
      <ModuleFilter>
        <template #searchField>
          <FormField
            label="Search Logs"
            for-id="filter-search"
            type="search"
            class="moduleFilter__filter"
          >
            <input
              id="filter-search"
              v-model="searchInputValue"
              type="text"
              required
              @input="debouncedHandleSearchInput"
            />
          </FormField>
        </template>

        <template #collapsableFilters>
          <FormField
            label="Search By ID"
            for-id="filter-status"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-status"
              v-model="searchByValue"
            >
              <option
                v-for="(options, index) in searchByIdOptions"
                :key="index"
              >
                {{ options.label }}
              </option>
            </select>
            <template
              v-if="searchByValue !== searchByIdOptions.default.label"
              #custom-content
            >
              <div
                class="moduleFilter__filter__clear"
                @click="handleIdSelectClear"
              >
                [X] CLEAR
              </div>
            </template>
          </FormField>
        </template>
      </ModuleFilter>

      <!-- Table Container -->
      <div>
        <!-- Logs Table -->
        <DspReportingTable
          :table-data="tableData"
          :fields="fields"
          :pagination-data="paginationData"
          @get-pagination-event="handlePagination"
        />
      </div>
      <!-- Placement List Modal -->
      <b-modal
        ref="moduleModal"
        title="Bootstrap-Vue"
        hide-footer
        hide-header
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
      >
        <component
          :is="modalData.currentComponent"
          @save-update-success="handleReceivedData"
          @cancel="hideModal"
        />
      </b-modal>
      <!-- Page Footer  -->
      <ModuleFooter class="mt-auto">
        <template #left>
          <div>© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
        </template>
        <template #right>
          <WebLinks />
        </template>
      </ModuleFooter>
    </main>
  </div>
</template>

<style scoped>
.style-btn {
  margin-top: 1rem;
  border: none;
  width: auto;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  cursor: pointer;
}
</style>
