<template>
  <section class="sModal">
    <div class="loader" :class="{'done': (!loading)}">
      <font-awesome-icon icon="spinner" class="spinner"/>
    </div>

    <header v-if="user.id" class="sModal__header">
      {{user.name}} {{user.last_name}}
      <button v-if="isCurrentUserProfile" class="sModal__header__logOutButton" @click="logOutUser"><font-awesome-icon icon="sign-out-alt" class="i" />logout</button>
    </header>
    <header v-else class="sModal__header">New User</header>

    <form @submit.prevent="saveData()">
      <div class="sModal__body">
          <FormField label="Name *" forId="user-name">
            <input v-model="user.name" id="user-name" type="text" required />
          </FormField>

          <FormField label="Last Name *" forId="user-last_name">
            <input v-model="user.last_name" id="user-last_name" type="text" required />
          </FormField>

          <FormField label="Email *" forId="user-email">
            <input v-model="user.email" id="user-email" type="email" required />
          </FormField>

          <FormField label="Phone Number *" forId="phone-number"  fade>
              <input v-model="user.phone_number" id="phone-number" type="text" required/>
          </FormField>

          <FormField v-if="currentUserIsAdmin && itemId != null" label="Account *" forId="user-accountName" type="select">
              <select v-model="user.account_name" id="user-accountName" @change="accountTypeChange($event)" required :disabled="!currentUserIsAdmin || isCurrentUserProfile">
                  <option v-if="!isCurrentUserProfile && itemId == null" disabled>Select one</option>
                  <option v-for="(account, index) in accounts" :key="index">{{account.text}}</option>
              </select>
              <small slot="footer" v-if="!currentUserIsAdmin || isCurrentUserProfile">* Only another admin can update your account</small>
          </FormField>

          <FormField v-if="currentUserIsAdmin" label="Role *" forId="user-roleName" type="select">
              <select v-model="user.role_name" id="user-roleName" @change="roleTypeChange($event)" required :disabled="!currentUserIsAdmin || isCurrentUserProfile">
                  <option v-if="!isCurrentUserProfile && itemId == null" disabled>Select one</option>
                  <option v-for="(role, index) in roles" :key="index">{{role.text}}</option>
              </select>
              <small slot="footer" v-if="!currentUserIsAdmin || isCurrentUserProfile">* Only another admin can update your role</small>
          </FormField>
<!--
          <FormField label="Role *" forId="user-role" type="select">
            <select v-model="user.role_name" id="user-role" required :disabled="!currentUserIsAdmin || isCurrentUserProfile">
              <option v-for="(role, index) in roles" :key="index">{{role}}</option>
            </select>
            <small slot="footer" v-if="!currentUserIsAdmin || isCurrentUserProfile">* Only another admin can update your role</small>
          </FormField>

          <FormField v-if="currentUserIsAdmin" label="Account *" forId="user-account_name" type="select">
            <select v-model="user.account_name" id="user-account_name" required :disabled="!currentUserIsAdmin || isCurrentUserProfile">
              <option v-for="(account, index) in accounts" :key="index">{{account}}</option>
            </select>
            <small slot="footer" v-if="!currentUserIsAdmin || isCurrentUserProfile">* Only another admin can switch your account</small>
          </FormField> -->

          <FormField label="Subscribe to Weekly Creative Showcase newsletter" forId="user-creativeShowcase" type="select">
            <select v-model="user.creative_show_case" id="user-creativeShowcase" required>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </FormField>

          <!-- show status only if this is not a new user -->
          <FormField v-if="itemId" label="Status *" forId="user-status" type="input">
            <input v-model="user.status" id="user-status" type="text" :disabled="true" />
          </FormField>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" /> Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'save user' if this not a new user -->
          <button v-if="itemId" v-bind:disabled="!isUserModified" class="saveButton"><font-awesome-icon icon="check" class="i" /> Save User</button>

          <!-- show 'create user' if this a new user -->
          <button v-if="!itemId" :disabled="!isUserModified" class="saveButton"><font-awesome-icon icon="check" class="i" /> Create User</button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>

import FormField from '@/components/FormField.vue'
import Api from '@/helpers/ApiUser.js'
import Utils from '@/helpers/Utils.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserProfileModal',
  props: {
    /* if this prop comes empty, the template considers this as a new user */
    itemId: [String, Number],
    userData: [Object]
  },
  components: {
    FormField
  },
  data () {
    return {
      loading: true,
      userDefaults: {
        id: null,
        name: '',
        last_name: '',
        email: '',
        phone_number: '',
        account_name: 'PadSquad',
        account_type: null,
        role_type: null,
        role_name: 'Select one',
        creative_show_case: '1'
      },
      user: {},
      userEdit: {},
      userFetchedData: {},
      roles: [],
      accounts: [],
      status: []
    }
  },
  methods: {
    ...mapActions([
      'signOut',
      // 'signUpInvite',
      'signUpByInvitation'
    ]),
    getOnlyDigits (value) {
      // returns only numbers
      return value.replace(new RegExp(/\D+/g), '')
    },
    accountTypeChange: function (event) {
      // this.user.account_name = event.target.value;
    },
    roleTypeChange: function (event) {
      // this.user.role_name = event.target.value;
    },
    saveData: function () {
      const self = this

      // turn on loading spinner
      self.loading = true

      // add the id to the object
      self.user.account_type_id = self.user.account_id = self.user.account_type = self.accountByName(self.user.account_name).id
      self.user.role_type_id = self.user.role_id = self.user.role_type = self.roleByName(self.user.role_name).id

      // convert to only numbers
      self.user.phone_number = self.getOnlyDigits(self.user.phone_number)

      // if this is not a new user...
      if (self.itemId) {
        new Promise((resolve, reject) => {
          // update user role
          Api.updateUserRole({ role_id: self.user.role_id, user_id: self.user.id })
            .then(response => {
              if (response.error) {
                reject(response.data)
              } else {
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        })
          .then(response => Api.updateUser(self.user))
          .then(response => {
            // turn off loading spinner
            self.loading = false

            // if data was successfully saved emit save-new-success event and pass the reponse
            self.$emit('save-update-success', response)
          })
          .catch(error => {
            // turn off loading spinner
            self.loading = false

            self.$log.error(error)

            // if there was an issue
            // alert('There was an issue updating this user. Please try again.')
            // self.$emit('save-update-error', error);
            this.$swal({
              position: 'top',
              title: 'There was an issue updating this user. </br>Please try again.',
              html: '',
              confirmButtonText: 'Ok',
              icon: 'error',
              toast: false
            })
          })
      } else {
        // user db internal id creating this new user, could be org/account id
        self.user.created_by = self.userInSession.id
        // add the account type of the user creating this user
        self.user.account_type_id = self.user.account_type = self.userInSession.account_type_id

        // first sign up the user to AWS, before create in the DB
        new Promise((resolve, reject) => {
          self.signUpByInvitation(self.user)
            .then(response => {
              // console.log('new', response);
              if (response.error) {
                reject(response.data)
              } else {
                resolve(self.user)
              }
            })
            .catch(error => reject(error))
        })
          // otherwise this is a new user, so let's create it
          .then(response => {
            // alert(`You have invited ${self.user.email}.`);

            this.$swal({
              position: 'top',
              title: `You have invited ${self.user.email}.`,
              html: '',
              confirmButtonText: 'Ok',
              icon: 'success',
              toast: false
            })
            // turn off loading spinner
            self.loading = false

            // if data was successfully saved emit save-new-success event and pass the reponse
            self.$emit('save-new-success', response)
          })
          .catch(error => {
            // turn off loading spinner
            self.loading = false

            if (error == 'Request failed with status code 500' || error == 'An account with the given email already exists.') {
              // alert(`An user with email: ${self.user.email} already exists`);

              this.$swal({
                position: 'top',
                title: `An user with email: ${self.user.email} already exists`,
                html: '',
                confirmButtonText: 'Ok',
                icon: 'error',
                toast: false
              })
            } else {
              // if there was an issue
              // alert(`${error}: There was an issue creating the user. Please try again.`)
              this.$swal({
                position: 'top',
                title: `${error}: There was an issue creating the user. </br>Please try again.`,
                html: '',
                confirmButtonText: 'Ok',
                icon: 'error',
                toast: false
              })
            }
          })
      }
    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('cancel')
    },
    populateForm: function () {
      const self = this

      // if an itemId was passed...
      if (self.itemId) {
        // ... then it means that we are trying to get an user's profile...

        // so, load user data from API
        Api.getUser(self.itemId)
          .then(user => {
            // store fetched data.
            self.userFetchedData = _.cloneDeep(user.data[0])

            // console.log('user', user.data[0])
            // populate form with user's data
            self.user = _.cloneDeep(user.data[0])

            // turn off loading spinner
            self.loading = false

            return user.data[0]
          })
          .then(user => {
            // console.log(self.isCurrentUserProfile, user, self.currentUser.aws_user_id, self.user.aws_user_id)
            // if it's not current userRol...
            if (self.isCurrentUserProfile) {
              // populate the accounts, roles and status lists with just the that comes with the user info
              self.accounts = _.filter(this.accounts_type, { id: self.user.account_type_id }) // [self.user.account_name];
              self.roles = _.filter(this.roles_type, { text: self.user.role_name })// [self.user.role_name];
              self.status = [self.user.status]

              // if we only have the id in the data
              self.user.account_name = self.accounts[0].text
            } else {
              // let's fetch accounts, roles and status options from the API.
              // get list of accounts
              self.accounts = _.filter(this.accounts_type, { id: self.user.account_type_id }) // [self.user.account_name];
              self.roles = this.roles_type
              self.status = [self.user.status]

              // if we only have the id in the data
              self.user.account_name = self.accounts[0].text
            }

            // convert to only numbers
            self.user.phone_number = self.getOnlyDigits(self.user.phone_number)
          })
          .catch(error => {
            self.$log.error(error)
          })
      } else {
        // otherwise we are just trying to create a new user. So set defaults
        self.user = _.cloneDeep(self.userDefaults)
        self.userFetchedData = _.cloneDeep(self.userDefaults)

        // turn off loading spinner
        self.loading = false

        // let's fetch accounts, roles and status options from the API.
        // // get list of accounts
        self.accounts = this.accounts_type
        self.roles = this.roles_type
      }
    },
    logOutUser: function () {
      const self = this

      this.signOut()
        .then(response => {
          localStorage._sUser = null
          self.$router.push('/sign-in')
        })
        .catch(error => {

        }
        )
    }
  },
  created () {
    const self = this

    // populate form
    self.populateForm()
  },
  computed: {
    ...mapGetters([
      'userInSession',
      'roles_type',
      'accounts_type',
      'accountByName',
      'roleByName',
      'userInSession'
    ]),
    isUserModified: function () {
      const self = this

      return !_.isEqual(self.user, self.userFetchedData)
    },
    currentUser: function () {
      const self = this

      const currentUser = self.userInSession

      return currentUser
    },
    currentUserIsAdmin: function () {
      const self = this

      return (self.currentUser.role_name == 'Admin')
    },
    isCurrentUserProfile: function () {
      const self = this

      return (self.currentUser.aws_user_id == self.user.aws_user_id)
    },
    accountType: function (value) {
      return this.accounts_type.filter(account => account.value == this.user.account_type_id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/common.scss";
</style>
