<template>
  <div class="appFrame">
    <Navbar
      :current-module-path="'creative-production-creatives'"
      :current-module-name="'Creative Production Creatives'"
    />

    <main
      class="moduleFrame moduleFrame--tagManagerRepository moduleFrame--tagManagerRepositoryPlacements moduleFrame--tagManagerRepositoryCampaignChild"
    >
      <PencilBar />

      <div v-if="campaign != null" class="heading">
        {{ campaign.name }}
      </div>
      <!-- <button class="button" @click="openModalSettings(campaign.id, null, 'Campaign')"><font-awesome-icon icon="cog" class="i" /></button> -->

      <ModuleHeader>
        <ul slot="tabs">
          <li>
            <router-link
              :to="{
                path: '/tag-manager/placements/campaign/' + campaign.id,
                params: { id: campaign.id, data: campaign }
              }"
              >Placements</router-link
            >
          </li>
          <li selected>Creatives</li>
          <li>
            <router-link
              :to="{ path: '/tag-manager/live-polls/campaign/' + campaign.id }"
              >Live Polls</router-link
            >
          </li>
          <!-- <li v-if="currentUser.role_name == 'Admin' || environment !== 'production'"><router-link :to="{path:'/analytics/performance/campaign/' + campaign.id}">Monitoring</router-link></li> -->
          <!-- <li v-if="currentUser.role_name == 'Admin' || environment !== 'production'"><router-link :to="{path:'/analytics/live/campaign/' + campaign.id}">Live</router-link></li> -->
        </ul>

        <div slot="title">Creatives</div>

        <div
          v-if="campaign.campaign_source === 'Airtory Sync' || 'PadSquad'"
          slot="actions"
        >
          <button
            v-if="campaign.campaign_source === 'Airtory Sync' && currentUser.email === 'connor.lacour@padsquad.com'"
            class="moduleHeader__createButton mr-4"
            @click="downloadAllCreativeAssets"
          >
            <font-awesome-icon icon="exchange-alt" class="i" />
            Download All Assets
          </button>
          <button
            class="moduleHeader__createButton"
            :disabled="campaign.status_source == 'Updating'"
            @click.prevent="openNewCreative"
          >
            <font-awesome-icon icon="plus-circle" class="i" />
            New Creative
          </button>
        </div>
      </ModuleHeader>

      <ModuleFilter>
        <div slot="searchField">
          <FormField
            label="Search Creatives"
            for-id="filter-search"
            type="search"
            class="moduleFilter__filter"
          >
            <input
              id="filter-search"
              v-model="filterModuleData.search"
              type="text"
              required
            />
          </FormField>
        </div>

        <div slot="collapsableFilters">
          <FormField
            label="Status"
            for-id="filter-status"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-format"
              v-model="filterModuleData.filterValues.status"
            >
              <option
                v-for="status in filterModuleData.filterOptions.status"
                :key="status"
              >
                {{ status }}
              </option>
            </select>
            <div
              v-if="
                filterModuleData.filterValues.status !==
                filterModuleData.filterDefaults.status
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="
                filterModuleData.filterValues.status =
                  filterModuleData.filterDefaults.status
              "
            >
              [X] CLEAR
            </div>
          </FormField>

          <FormField
            label="Format"
            for-id="filter-format"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-format"
              v-model="filterModuleData.filterValues.format"
            >
              <option
                v-for="format in filterModuleData.filterOptions.format"
                :key="format"
              >
                {{ format }}
              </option>
            </select>
            <div
              v-if="
                filterModuleData.filterValues.format !==
                filterModuleData.filterDefaults.format
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="
                filterModuleData.filterValues.format =
                  filterModuleData.filterDefaults.format
              "
            >
              [X] CLEAR
            </div>
          </FormField>

          <FormField
            label="Main KPI"
            for-id="filter-meanKPI"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-meanKPI"
              v-model="filterModuleData.filterValues.mainKPI"
            >
              <option
                v-for="mainKPI in filterModuleData.filterOptions.mainKPI"
                :key="mainKPI"
              >
                {{ mainKPI }}
              </option>
            </select>
            <div
              v-if="
                filterModuleData.filterValues.mainKPI !==
                filterModuleData.filterDefaults.mainKPI
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="
                filterModuleData.filterValues.mainKPI =
                  filterModuleData.filterDefaults.mainKPI
              "
            >
              [X] CLEAR
            </div>
          </FormField>

          <FormField
            label="VIDEO TAG TYPE"
            for-id="filter-tagType"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-meanKPI"
              v-model="filterModuleData.filterValues.tagType"
            >
              <option
                v-for="tagType in filterModuleData.filterOptions.tagType"
                :key="tagType"
              >
                {{ tagType }}
              </option>
            </select>
            <div
              v-if="
                filterModuleData.filterValues.tagType !==
                filterModuleData.filterDefaults.tagType
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="
                filterModuleData.filterValues.tagType =
                  filterModuleData.filterDefaults.tagType
              "
            >
              [X] CLEAR
            </div>
          </FormField>
        </div>
      </ModuleFilter>

      <section class="moduleContent">
        <div
          v-if="campaign != null && campaign.status_source == 'Updating'"
          class="alert-heading"
        >
          <font-awesome-icon icon="exclamation-circle" class="i" />This campaign
          is updating creatives by traffic plan, Unable to update any creative
          until the process have been completed.
        </div>
        <div class="contentTableFrame">
          <ContentTableTools>
            <FormField
              slot="left"
              for-id="tools-orderby"
              type="select"
              tag-left="Order By:"
            >
              <select id="tools-orderby" v-model="tableDataOptions.orderBy">
                <option
                  v-for="(orderByOption, index) in tableDataOptions.options"
                  :key="index"
                  :value="orderByOption.value"
                >
                  {{ orderByOption.label }}
                </option>
              </select>
            </FormField>

            <FormField
              slot="left"
              for-id="tools-order"
              type="select"
              tag-left="Order:"
            >
              <select id="tools-order" v-model="tableDataOptions.order">
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </FormField>

            <FormField
              slot="left"
              for-id="tools-actions"
              type="select"
              tag-left="Actions:"
            >
              <select id="tools-actions" @change="actionsHandler">
                <option value="none">Select an Action</option>
                <option
                  value="Clone Creatives"
                  :disabled="!validCreativesSelected.length"
                >
                  Clone Creatives
                </option>
              </select>
            </FormField>

            <div slot="right" class="contentTableFrame__total">
              Total Creatives: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>

          <div
            class="contentTableWrapper"
            :class="{ contextualMenuExpanded: contextualMenuExpanded }"
          >
            <table class="contentTable">
              <thead>
                <tr>
                  <th class="contentTable__dimension">
                    <input type="checkbox" @change="selectAllToggle($event)" />
                    Creative Name
                  </th>
                  <th>Creative ID</th>
                  <th>Status</th>
                  <th>Format</th>
                  <th>Last Updated</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in paginatedItems"
                  :key="item.id"
                  :class="{ 'highlight-row': creativeToHighlight === item.id }"
                >
                  <td
                    :class="{
                      defaultCreative:
                        item.name === 'Placeholder_Creative_300x250' ||
                        item.name === 'Placeholder_Creative_320x50' ||
                        item.name === 'Placeholder_Campaign_Creative' ||
                        campaign.campaign_source === 'Celtra Sync'
                    }"
                    class="contentTable__dimension"
                  >
                    <input
                      v-model="creativesSelected[item.id]"
                      :disabled="
                        item.name === 'Placeholder_Creative_300x250' ||
                        item.name === 'Placeholder_Creative_320x50' ||
                        item.name === 'Placeholder_Campaign_Creative' ||
                        campaign.campaign_source === 'Celtra Sync'
                      "
                      type="checkbox"
                      :name="'creative_' + item.name"
                      @change="toggleCreativeSelection(item.id)"
                    /><a
                      :title="item.name"
                      href="#"
                      @click.prevent="openCreativeSettings(item.id)"
                      >{{ item.name | truncateTitle(70) }}</a
                    >
                  </td>
                  <td
                    :class="{
                      defaultCreative:
                        item.name === 'Placeholder_Creative_300x250' ||
                        item.name === 'Placeholder_Creative_320x50' ||
                        item.name === 'Placeholder_Campaign_Creative' ||
                        campaign.campaign_source === 'Celtra Sync'
                    }"
                    column-name="Creative ID"
                  >
                    <a
                      href="#"
                      @click.prevent="
                        filterModuleData.filterValues.id = item.id
                      "
                      >{{ item.id }}</a
                    >
                  </td>
                  <td
                    :class="{
                      defaultCreative:
                        item.name === 'Placeholder_Creative_300x250' ||
                        item.name === 'Placeholder_Creative_320x50' ||
                        item.name === 'Placeholder_Campaign_Creative' ||
                        campaign.campaign_source === 'Celtra Sync'
                    }"
                    column-name="Status"
                  >
                    <a
                      href="#"
                      @click.prevent="
                        filterModuleData.filterValues.status = item.status_id
                      "
                      >{{ getStatusName(item.status_id) }}</a
                    >
                  </td>
                  <td
                    :class="{
                      defaultCreative:
                        item.name === 'Placeholder_Creative_300x250' ||
                        item.name === 'Placeholder_Creative_320x50' ||
                        item.name === 'Placeholder_Campaign_Creative' ||
                        campaign.campaign_source === 'Celtra Sync'
                    }"
                    column-name="Format"
                  >
                    <a
                      href="#"
                      @click.prevent="
                        filterModuleData.filterValues.format = item.format_id
                      "
                      >{{ getFormatName(item.format_id) }}</a
                    >
                  </td>
                  <td
                    :class="{
                      defaultCreative:
                        item.name === 'Placeholder_Creative_300x250' ||
                        item.name === 'Placeholder_Creative_320x50' ||
                        item.name === 'Placeholder_Campaign_Creative' ||
                        campaign.campaign_source === 'Celtra Sync'
                    }"
                    column-name="UpdatedDate"
                  >
                    <a href="#" @click.prevent="''">{{
                      getFormattedDate(item.touched_on)
                    }}</a>
                  </td>
                  <td
                    class="contentTable__actions"
                    :class="{
                      defaultCreative:
                        item.name === 'Placeholder_Creative_300x250' ||
                        item.name === 'Placeholder_Creative_320x50' ||
                        item.name === 'Placeholder_Campaign_Creative' ||
                        campaign.campaign_source === 'Celtra Sync'
                    }"
                  >
                    <button @click="openCreativePreview(item)">
                      <font-awesome-icon icon="eye" class="i" />
                    </button>
                    <button @click="openCreativeSettings(item.id)">
                      <font-awesome-icon icon="cog" class="i" />
                    </button>
                    <ActionsDropdown
                      :dropup="
                        paginatedItems.length > 10 &&
                        !(index < paginatedItems.length - 2)
                      "
                      :item-id="item.id"
                      :actions="
                        campaign.campaign_source !== 'PadSquad'
                          ? tableActions
                          : padSquadTableActions
                      "
                      @show="contextualMenuExpanded = true"
                      @hide="contextualMenuExpanded = false"
                    />
                  </td>
                </tr>

                <tr v-if="paginatedItems.length < 1 && campaign.creatives">
                  <td colspan="9">No creative matches your criteria</td>
                </tr>
                <tr
                  v-if="!campaign.creatives || campaign.creatives.length === 0"
                >
                  <td colspan="9">There are no creatives on this campaign.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ContentTableTools>
            <div slot="right" class="contentTableFrame__total">
              Total Creatives: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>
        </div>
      </section>

      <ModuleFooter>
        <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
        <WebLinks slot="right" />
      </ModuleFooter>
    </main>

    <!-- Modal Component -->
    <b-modal
      ref="moduleModal"
      :static="staticBModal"
      :class="[
        { modal990Width: !buildingCreativeInModal },
        { modalClassForBuild: buildingCreativeInModal }
      ]"
      title=""
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hidden="modalData.currentComponent = null"
    >
      <component
        :is="modalData.currentComponent"
        :item-id="modalData.itemId"
        :items-id="modalData.itemsId"
        :advertiser-id="modalData.advertiserId"
        :advertiser-name="modalData.advertiserName"
        :campaign="modalData.campaign"
        :creative-prop="modalData.creative"
        @done="hideModal()"
        @addModalClassForBuild="buildingCreativeInModal = true"
        @removeModalClassForBuild="buildingCreativeInModal = false"
      />
    </b-modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import CreativeProfileModal from '@/views/creative-production/modals/RepoProductionCreativeProfile.vue'
import CloneCreativesModal from '@/views/modals/CloneCreatives.vue'
import WebLinks from '@/components/WebLinks.vue'
import axios from 'axios'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'

export default {
  name: 'CreativeProdcutionRepositoryCampaignCreatives',
  components: {
    Navbar,
    FormField,
    ModuleHeader,
    ModuleFilter,
    PencilBar,
    ModuleFooter,
    ActionsDropdown,
    ContentTableTools,
    WebLinks,
    CreativeProfileModal,
    CloneCreativesModal
  },
  data() {
    return {
      staticBModal: true,
      environment: process.env.VUE_APP_ENV,
      mobileFiltersExpanded: false,
      filterModuleData: {
        search: '',
        filterValues: {
          status: 'All statuses',
          format: 'All formats',
          mainKPI: 'All KPI',
          tagType: 'All Video Tags'
        },
        filterDefaults: {
          status: 'All statuses',
          format: 'All formats',
          mainKPI: 'All KPI',
          tagType: 'All Video Tags'
        },
        filterOptions: {
          status: [],
          format: [],
          mainKPI: [],
          tagType: []
        }
      },
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Format',
            value: 'format'
          }
        ]
      },
      breadcrumsData: [
        {
          text: 'Creative Production',
          href: '/creative-production'
        },
        {
          text: 'Creatives',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        airtoryCampaignId: null,
        airtoryCampaignName: null,
        campaign: null,
        creative: null,
        itemsId: []
      },
      data: [],
      creativesSelected: {},
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'Creative Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openCreativeSettings
        },
        {
          ico: 'trash-alt',
          text: 'Delete Creative',
          roles: ['Admin', 'Marketing'],
          clickAction: this.deleteCreative
        },
        {
          ico: 'chart-line',
          text: 'Airtory Analytics',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openAnalytics
        }
      ],
      padSquadTableActions: [
        {
          ico: 'cog',
          text: 'Creative Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openCreativeSettings
        },
        {
          ico: 'trash-alt',
          text: 'Delete Creative',
          roles: ['Admin', 'Marketing'],
          clickAction: this.deleteCreative
        }
      ],
      contextualMenuExpanded: false,
      selectAll: false,
      campaign: { campaign_source: { id: null } },
      buildingCreativeInModal: false,
      // if we select an assigned creative name from the placements list on Tag Manager placements, it comes in the url query ?creative=id
      creativeToHighlight: null
    }
  },
  updated() {
    if (
      this.$store.state.creatives.openPadSquadModal === true
      && this.$store.state.creatives.creativeLogs.length !== 0
    ) {
      this.openPadSquadCreativeSettings(this.$store.state.creatives.creative)
    } else if (
      this.$store.state.creatives.creativeLogs.length === 0
      && this.$store.state.creatives.isPadSquadTemporaryCreative === true
    ) {
      this.openIncompletePadSquadCreative()
    }
  },
  methods: {
    ...mapActions(['registerModalOpened', 'registerModalClosed']),
    ...mapActions('creatives', {
      fetchProductionCreatives: 'fetch',
      deleteProductionCreative: 'delete',
      fetchCreativesLogs: 'fetchCreativesLogs'
    }),
    ...mapActions('campaigns', {
      replaceCampaign: 'replace',
      fetchCampaignById: 'fetchById',
      deleteCreativeFromCampaign: 'deleteCreativeFromCampaign',
      getCampaign: 'fetchByIdNoPlacements'
    }),
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':
          // self.addMultipleCreativesToList();
          break

        case 'Clone Creatives':
          this.openCloneCreatives(this.validItemsSelectedToObjects)
          break

        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    passFormatIdOfTempCreative: function (data) {
      this.formatIdToPassToModal = data
    },
    openCloneCreatives: function (itemsId) {
      const creativesId = itemsId
        .map((item) => {
          return item.id
        })
        .join('-')

      const self = this
      // set the modal item id to the  we want to update
      // self.modalData.itemId = creativeId;

      // send Airtory Campaign
      self.modalData.campaign = self.campaign

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CloneCreativesModal

      self.modalData.itemsId = itemsId
      // show modal
      self.$refs.moduleModal.show()
    },
    openCreativePreview: function (creative) {
      if (this.campaign.campaign_source === 'PadSquad') {
        window.open(`/creative-preview/${creative.creative_ps_id}`, '_blank')
      } else {
        const _id = creative.external_creative_id
        window.open(
          `https://padsquad.adtag.org/ad-preview/#${_id}/mobile_portrait`,
          '_blank'
        )
      }
    },
    openAnalytics(id) {
      const p = this.campaign.creatives.find((item) => item.id === id)
      if (p)
        window.open(
          `https://studio.airtory.com/#/analytics/creative/${p.external_creative_id}`,
          '_blank'
        )
    },
    openCreativeSettings: function (creativeId) {
      const self = this
      // set the modal item id to the  we want to update
      self.modalData.itemId = creativeId
      // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCreative
      // let _creative = self.productionCreativesByCampaignId(creativeId);
      const _creative = self.campaign.creatives.find(
        (item) => item.id === creativeId
      )
      const _advertiser
        = _creative.advertiser_id === null
          ? self.advertiserByName(_creative.advertiser)
          : self.advertiserById(_creative.advertiser_id)

      // self.$log.info('CREATIVE TO MODAL',  creativeId, _creative);

      // self.$log.info(_advertiser, _creative.advertiser)

      if (_advertiser !== undefined) {
        self.modalData.advertiserId = _advertiser.id
        self.modalData.advertiserName = _advertiser.name
      } else {
        self.modalData.advertiserId = _creative.advertiser_id
        self.modalData.advertiserName = _creative.advertiser
      }

      // send Airtory Campaign
      self.modalData.campaign = self.campaign
      self.modalData.creative = _creative
      // self.modalData.airtoryCampaignId = self.campaign.external_campaign_id || null;
      // self.modalData.airtoryCampaignName = self.campaign.name || null;

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },

    openPadSquadCreativeSettings: function (creativeData) {
      if (this.$store.state.creatives.isPadSquadTemporaryCreative === true) {
        this.modalData.itemId = creativeData.creative_ps_id
      } else this.modalData.itemId = creativeData.id

      this.modalData.campaign = this.campaign
      this.modalData.creative = creativeData

      // self.registerModalOpened()

      this.modalData.currentComponent = CreativeProfileModal

      this.$refs.moduleModal.show()
    },
    deleteCreative: function (creativeId) {
      const self = this

      if (self.campaign.status_source != 'Updating') {
        // fet user from users.
        // var creative = _.find(self.productionCreatives, {id: creativeId});
        const creative = _.find(self.campaign.creatives, { id: creativeId })

        // if the user we are trying to delete is not the logged in user...
        if (
          confirm(
            `Are you sure you want to delete this creative: ${creative.name}?`
          )
        ) {
          // delete creative
          self
            .deleteProductionCreative(creative.id)
            .then((response) => {
              self.deleteCreativeFromCampaign({
                campaignId: self.campaign.id,
                creativeId: creative.id
              })
            })
            .catch((error) => {
              self.$log.error(error)
              alert(
                `There was an issue deleting this creative. Please try again. Error Code:${error}`
              )
            })
        }
      } else {
        self.$swal({
          position: 'top',
          title: 'Info!',
          html: '<h3>This campaign is updating placements by traffic plan, Unable to update any placement until the process have been completed.</h3>',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          allowOutsideClick: false,
          icon: 'info',
          timer: 5000,
          toast: false
        })
      }
    },
    openNewCreative: function () {
      const self = this

      // set Advertiser to null
      self.modalData.advertiserId = null
      self.modalData.advertiserName = null

      // set the modal item id to null to create new user
      self.modalData.itemId = null

      // send Airtory Campaign
      self.modalData.campaign = self.campaign

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    openIncompletePadSquadCreative: function () {
      const self = this

      // set Advertiser to null
      self.modalData.advertiserId = null
      self.modalData.advertiserName = null

      // set the modal item id to null to create new user
      self.modalData.itemId = null

      // send Airtory Campaign
      self.modalData.campaign = self.campaign

      self.modalData.currentSection = 'summary'

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    toggleCreativeSelection: function (creativeId) {
      const self = this

      if (self.creativesSelected[creativeId]) {
        self.$set(self.creativesSelected, String(creativeId), true)
      } else {
        self.$delete(self.creativesSelected, String(creativeId))
      }
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('MMM DD, YYYY')

      return formattedDate !== 'Invalid date' ? formattedDate : ''
    },
    getItemTouchedOn(item) {
      return item.updated_date || item.created_date
    },
    getFormatName: function (id) {
      const item = _.find(this.creativeFormats, { id })
      return item ? item.name : ''
    },
    getStatusName: function (id) {
      const item = this.creativeStatus.find((item) => item.id === id)
      return item ? item.name : ''
    },
    mapItemsFilters: function () {
      const self = this

      self.campaign.creatives.map((item) => {
        item.format = _.find(self.creativeFormats, { id: item.format_id })
        item.status = _.find(self.creativeStatus, { id: item.status_id })
        item.mainKPI = _.find(self.KPIs, { id: item.main_kpi_id })
        if (item.format == undefined) return item
        item.format = item.format.name
        item.status = item.status.name
        item.mainKPI
          = item.mainKPI !== null && item.mainKPI !== undefined
            ? item.mainKPI.name
            : null
        item.touched_on = item.updated_date || item.created_date

        return item
      })
    },
    populateFilters: function () {
      const self = this

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      // var filterOptions = Utils.pickFilterOptions(self.productionCreatives, paramsToFilterBy);
      const filterOptions = Utils.pickFilterOptions(
        self.campaign.creatives,
        paramsToFilterBy
      )

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, (param) => {
        filterOptions[param].unshift(
          self.filterModuleData.filterDefaults[param]
        )
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.paginatedItems.map((item, key) => {
          // add reactivity
          // Vue.set(object, propertyName, value)
          if (
            !(
              item.name === 'Placeholder_Creative_300x250'
              || item.name === 'Placeholder_Creative_320x50'
              || item.name === 'Placeholder_Campaign_Creative'
              || this.campaign.campaign_source === 'Celtra Sync'
            )
          ) {
            this.$set(this.creativesSelected, String(item.id), true)
          }
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.creativesSelected, String(item.id), false)
        })
      }
    },
    async downloadAllCreativeAssets () {
      if (!this.campaign.creatives || this.campaign.creatives.length === 0) return
      const creatives = this.campaign.creatives

      const assetsMap = {}

      for (const creative of creatives) {
        const assetLog = await this.getCreativeLog(creative)
        if (assetLog && assetLog.zip_url) {
          const fileName = await this.downloadAsset(assetLog)
          assetsMap[creative.id] = fileName
        }
      }

      this.downloadAssetsMap(assetsMap)
    },
    async getCreativeLog (creative) {
      const logs = await this.fetchCreativesLogs(creative.id)
      if (!logs || logs.length === 0) return
      let newestLog
      for (const log of logs) {
        if (!newestLog || newestLog.id < log.id) {
          newestLog = log
        }
      }
      return newestLog
    },
    async downloadAsset (asset) {
      const a = document.createElement('a')
      a.href = asset.zip_url
      a.target = '_blank'
      
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      
      return `${asset.zip_url.substring(asset.zip_url.lastIndexOf('/') + 1)}.zip`
    },
    async downloadAssetsMap (assetsMap) {
      const a = document.createElement('a')

      a.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(assetsMap));
      a.download = "assets.json"

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
  computed: {
    ...mapGetters(['creativeFormats', 'creativeStatus', 'KPIs']),
    ...mapGetters('creatives', {
      productionCreatives: 'list',
      productionCreativesByCampaignId: 'listByCampaignId',
      creativeById: 'itemById'
    }),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById',
      advertiserByName: 'itemByName'
    }),
    ...mapGetters('campaigns', {
      // listCampaign: 'list',
      campaignById: 'itemById'
    }),
    currentUser: function () {
      const self = this
      const currentUser = JSON.parse(localStorage._sUser)

      return currentUser
    },
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validCreativesSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.creativesSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    validItemsSelectedToObjects: function () {
      const data = []
      this.validCreativesSelected.map((item) => {
        data.push({ id: parseInt(item) })
      })

      return data
    },
    filteredItems: function () {
      const self = this

      // get items that match the text on the search box
      // var searchedItems = Utils.searchItemsByText(self.productionCreatives, self.filterModuleData.search);
      const searchedItems = Utils.searchItemsByText(
        self.campaign.creatives,
        self.filterModuleData.search
      )

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(
        self.filterModuleData.filterValues,
        (filter, key) => {
          // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
          const filterOption = _.get(
            self.filterModuleData.filterOptions,
            '[' + key + '][0]',
            false
          )

          // if filterOption is not false and the filter option is not the first option
          return filterOption && filter != filterOption
        }
      )

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(
        filteredItems,
        [self.tableDataOptions.orderBy],
        [self.tableDataOptions.order]
      )

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // this.$log.info(self.filteredItems, self.pagination.currentPage, self.pagination.perPage);

      // return paginated data
      return Utils.getPaginatedItems(
        self.filteredItems,
        self.pagination.currentPage,
        self.pagination.perPage
      )
    }
  },
  watch: {
    productionCreatives: function () {
      const self = this

      self.populateFilters()
    },
    list: function () {
      const self = this

      self.populateFilters()
    },
    listCampaign: function () {
      // const self = this;
      // this.campaign = this.campaignById(this.$route.params.id);

      // For Filters
      this.mapItemsFilters()
      this.populateFilters()
    }
  },
  created() {
    const self = this

    if (this.$route.query.creative) {
      this.creativeToHighlight = parseInt(this.$route.query.creative)
      // this.$log.info('Creative to highlight', this.$route.query.creative);
    }
    // idlc
    self.getCampaign(this.$route.params.id).then((response) => {
      // self.fetch(this.$route.params.id);
      // placements come inside the campaingById data
      self.campaign = response

      // for filters
      self.campaign.creatives.map((item) => {
        // add format type {id, name}
        item.format = _.find(this.creativeFormats, { id: item.format_id })
        item.mainKPI = _.find(this.KPIs, { id: item.main_kpi_id })
        item.status = _.find(this.creativeStatus, { id: item.status_id })
        item.tagType = item.vast_tag === 1 ? 'VAST' : 'Non VAST'
        if (item.format == undefined) return item
        item.format = item.format.name
        item.mainKPI = item.mainKPI !== undefined ? item.mainKPI.name : ''
        item.status = item.status.name
        item.touched_on = item.updated_date || item.created_date
        return item
      })
      // inform if the campaign is been updated by TP
      if (self.campaign.status_source == 'Updating') {
        this.$swal({
          position: 'top',
          title: 'Info!',
          html: '<h3>This campaign is updating creatives by traffic plan, Unable to update any creative until the process have been completed.</h3>',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          allowOutsideClick: false,
          icon: 'info',
          timer: 10000,
          toast: false
        })
      }

      // when data changes, populate the filter
      // BUG delete this, testing purpose
      // if(!self.campaign.external_campaign_id) self.campaign.external_campaign_id = "";
      // self.$log.info('CAMPAIGN', self.campaign);
    })
  }
}
</script>

<style lang="scss">
@import '@/scss/common.scss';

.icon-name {
  margin: 0 5px;
}

.defaultCreative {
  // background: darkgrey !important;
  pointer-events: none;
  opacity: 0.5;
}

.highlight-row,
.highlight-row td,
.highlight-row:hover {
  background: #ededed !important;
}

.highlight-row td:first-child {
  border-left: 3px solid #3bbca1;
}
</style>
