import axios from "axios"

var ApiLocationFeeder = function() {

    /**
     * returns location feeder list
     */
    this.getLocationFeederList = function() {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_LIST}`)
                .then((response) => {
                    // console.log('GET LOCATION FEED LIST',response.data.data)
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
    * get a Location ApiLoFeeder by id
    * @param {number} id - feed ID
    */
    this.getLocationFeederById  = function(id) {
        return new Promise((resolve, reject) =>{
            axios.get(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_BY_ID}${id}`)
                .then((response) => {
                    // console.log('GET LOCATION FEED BY ID',response.data.data)
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
    * get a Location Feeder by id
    * @param {number} id - feed ID
    */
    this.getDetailByFeederId = function(id) {
        return new Promise((resolve, reject) =>{
            axios.get(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_BY_ID}${id}`)
                .then((response) => {
                    // console.log('GET LOCATION DETAIL FEED BY ID',response.data.data)
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * create a location feeder
     * @param {object} data - Location Feeder data to be inserted
     */
    this.createLocationFeeder = function(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_CREATE}`, data)
                .then((response) => {
                    // console.log('CREATE LOCATION FEED',response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    /**
     * delete a Location Feeder by id
     * @param {number} id - Location Feeder ID
     */
    this.deleteLocationFeeder = function(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_DELETE}${id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

     /**
     * update a Location Feeder
     * @param {number} data - Location Feeder Data to be inserted
     */
    this.updateLocationFeeder = function(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_UPDATE}`, data)
                .then((response) => {
                    // console.log('UPDATE LOCATION FEED',response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    /**
     * returns location feeder type list
     */
    this.getFeederTypeList = function() {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_TYPE_LIST}`)
                .then((response) => {
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

     /**
     * returns location feeder input list
     */
     this.getFeederInputList = function() {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_INPUT_LIST}`)
                .then((response) => {
                    // console.log('GET LOCATION FEED INPUT LIST',response.data.data)
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

     /**
     * returns location feeder content list
     */
     this.getFeederContentTypeList = function() {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_ENDPOINT_LOCATION_FEEDER_CONTENT_TYPE_LIST}`)
                .then((response) => {
                    // console.log('GET LOCATION FEED CONTENT LIST',response.data.data)
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

}

export default new ApiLocationFeeder()
