<template>
  <section class="sModal">
    <div class="loader" :class="{ done: !loading }">
      <font-awesome-icon icon="spinner" class="spinner" />
    </div>

    <header class="sModal__header">New Job</header>

    <form @submit.prevent="saveData()">
      <nav class="sModal__tabs">
        <ul>
          <li selected>

            <button readonly @click.prevent="">Details</button>
          </li>

        </ul>
      </nav>
      <div class="sModal__body">
        <div class="formFieldGroup">
          <div class="formFieldGroup__fields">
            <FormField label="Data source" forId="datasource" type="select">
              <select id="datasource" v-model="newJob.source" disabled>
                <option value="airtory">Airtory Sync</option>
              </select>
            </FormField>
            <FormField label="Data type" forId="data-type" class="">
              <input
                id="data-type"
                type="text"
                v-model="newJob.type"
                disabled
              />
            </FormField>
          </div>
        </div>

<div class="formFieldSelect">
    <small class="label">Data campaigns:</small>
          <div class="py-3">
          <multiselect
            v-model="selectedCampaigns"
            :multiple="true"
            :maxHeight="240"
            placeholder="Select one or more campaign"
            @input="onSelectCampaign($event)"
            :options="listCampaignsFiltered.map((type) => type.id)"
            :custom-label="
              (opt) => {
                return (
                  listCampaignsFiltered.find((x) => x.id == opt).id +
                  ' - ' +
                  listCampaignsFiltered.find((x) => x.id == opt).name
                );
              }
            "
          ></multiselect>
          </div>
</div>

        <div class="formFieldGroupv2">
          <div class="formFieldGroup__fields">
            <FormField class="formFieldv2"
              label="Data Date From"
              forId="data-date-from"
              type="date"
            >
              <input
                id="data-date-from"
                type="text"
                v-model="newJob.from"
                required
                readonly
                @click="showDate = !showDate"
                class="mb-0"
              />
              <div v-if="showDate" class="date">
            <date-range-picker
              v-model="dateRangeData"
              ref="picker"
              opens="inline"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="true"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="true"
              :showDropdowns="false"
              :ranges="false"
              :autoApply="true"
              :date-format="dateFormat"
              @select="updateValues"
            >
            </date-range-picker>
          </div>
            </FormField>

            <FormField label="Data Hour" forId="data-date-hour" type="number" class="formFieldv2">
              <input
                class="hour mb-0"
                min="0"
                v-model="newJob.hour"
                id="data-date-hour"
                type="number"
                max="23"
              />
              <small class="mb-0">hour number </small>
            </FormField>
          </div>

        </div>
      </div>
      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel">
            <font-awesome-icon icon="ban" class="i" />Cancel
          </button>
        </div>

        <div class="sModal__footer__right">
          <button :disabled="validateForm" class="saveButton" type="submit">
            <font-awesome-icon icon="check" class="i" />CREATE JOB
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormField from '@/components/FormField.vue'
import Multiselect from 'vue-multiselect'
import DateRangePicker from 'vue2-daterange-picker'
export default {
  name: 'RepoAnalyticsDataLoadingCreate',
  components: {
    FormField,
    Multiselect,
    DateRangePicker
  },
  data () {
    return {
      newJob: {
        from: '',
        to: '',
        hour: '0',
        source: 'airtory',
        /*      id: null, */
        type: 'campaign',
        /*    external_campaign_id: null, */
        campaigns: []
      },
      loading: true,
      showDate: false,
      dateRangeData: {
        startDate: new Date(),
        endDate: new Date()
      },
      selectedCampaigns: []
    }
  },
  methods: {
    ...mapActions('campaigns', ['fetch']),
    ...mapActions('analytics', ['createJob']),

    saveData () {
      const self = this
      if (!self.validateForm) {
        self.loading = true
        self.newJob.to = self.newJob.from

        // map external campaigns
        self.newJob.campaigns = this.selectedCampaigns.map((camp) => {
          return {
            id: camp,
            external_campaign_id: this.listCampaignsFiltered.find((x) => x.id == camp)
              .external_campaign_id
          }
        })

        self
          .createJob(self.newJob)
          .then((response) => self.$emit('done', response))
          .catch((err) => (self.loading = false))
      }
    },
    cancel () {
      // emit event
      this.$emit('done')
    },
    updateValues: function (dates) {
      this.$log.info(dates, this.dateRangeData)
      this.newJob.from = new Date(dates.startDate).toDateString('mm/dd/yyyy')
      this.showDate = false
    },
    onSelectCampaign (event) {
      /*    console.log('campaigns event',event);
      console.log('campaigns',this.selectedCampaigns); */
    },
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() > new Date()
      }
      return classes
    }
  },
  created () {
    this.fetch()
    this.loading = false
    this.newJob.from = new Date().toDateString('mm/dd/yyyy')
  },
  computed: {
    ...mapGetters('campaigns', {
      campaignsList: 'listAirtory'
    }),
    validateForm () {
      const validate = false
      if (this.newJob.from == '' || this.selectedCampaigns.length == 0) {
        return true
      }

      const today = new Date().toDateString('mm/dd/yyyy')

      if (new Date(this.newJob.from) > new Date()) {
        return true
      }

      if (
        this.newJob.hour < 0 ||
        this.newJob.hour > 23 ||
        this.newJob.hour == ''
      ) {
        return true
      }
      return validate
    },
    listCampaignsFiltered: function () {
      // descending sort

      return this.campaignsList.sort((a, b) => (a.id < b.id ? 1 : -1))
    }
  },
  watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/scss/common.scss";
.hour {
  -webkit-appearance: none;
  display: block;
  height: auto;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: black;
  border: 1px solid #cbcbcb;
  border: 1px solid #d9d9d9;
  border-radius: 0px;
}

.date{
  position: absolute;
  bottom: 32px
}

.formFieldGroupv2 small {
    display: block;
    margin-bottom: 0rem;
    margin-top: 1px;

}

.formFieldv2 {
   margin-bottom: 0rem;
}

.formFieldSelect {
  margin-bottom: 2rem;
}
::v-deep .multiselect__content-wrapper  {
  height: 150px;
  max-height: 200px;
}

</style>
