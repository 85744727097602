<template>
  <section v-if="itemId != null" class="sModal">

    <Preloader :inModal="true"></Preloader>

    <header class="sModal__header">Traffic Plan</header>

    <form @submit.prevent="formSubmit">

      <div class="sModal__body" v-if="currentSection=='details'">
        <div class="buttons">
          <button class="button button--green mr1" @click.prevent="downloadFilledTemplateCSV"><font-awesome-icon icon="plus-circle" class="i" /> Download Traffic Plan</button>
          <button class="button" @click.prevent="downloadBlankTemplateCSV"><font-awesome-icon icon="download" class="i" /> Download Blank Template</button>
        </div>
        <small>The trafficking plan is the excel template file with existing placement information that you can modify or add new placements.</small>
        <div class="mb2"></div>
        <FormField label="Upload Trafficking Plan"  description="" forId="creative-delivered-impressions">
          <b-form-file class="button-file" v-model="assetUploader.file" @change="uploadAssets" accept=".csv, application/vnd.sealed.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :state="Boolean(assetUploader.file)" placeholder="Choose a file..."></b-form-file>
        </FormField>

        <section v-if="listUploaded.length > 0">
        <!-- <section v-if="assetFiles.length > 0"> -->
              <div class="contentTableWrapper" :class="{'contextualMenuExpanded': contextualMenuExpanded}">
                <table class="contentTable">
                  <thead>
                    <tr>
                      <th class="contentTable__dimension"><input type="checkbox" @change="selectAllToggle($event)"/>Trafficking Plan Updates {{ getStatusCount }}</th>
                      <!-- <th class="contentTable__dimension">Placement Name</th> -->
                      <th>Placement Id</th>
                      <th>Format</th>
                      <th>3P ID</th>
                      <th>Tag Source</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in listUploaded" :key="index">
                    <!-- <tr v-for="(item, index) in assetFiles" :key="item.id"> -->
                      <td class="contentTable__dimension"><input type="checkbox" :name="'placement_' + item.name" v-model="itemsSelected[item.name]"/><span :class="getStatus(item.id)" v-text="getStatus(item.id)"></span><a href="#" @click="">{{item.name}}</a></td>
                      <td column-name="Placement Id">{{ item.id }}</td>
                      <td column-name="Format">{{ getFormatName(item.format_id) }}</a></td>
                      <td column-name="3P ID">{{ item.third_party_id }}</td>
                      <td column-name="Tag Source">{{ item.placement_source }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </section>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" />Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <!-- <button :disabled="itemId != null && listUploaded.length == 0" class="saveButton"> -->
          <button :disabled="itemId != null && !validItemsSelected.length" class="saveButton">
            <font-awesome-icon icon="check" class="i" />Update Placements List
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import draggable from 'vuedraggable'
import Api from '@/helpers/ApiTagManager.js'
import Utils from '@/helpers/Utils.js'
import Preloader from '@/components/Preloader.vue'

import Papa from 'papaparse'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TrafficPlanProfile',
  components: {
    FormField, Preloader, draggable
  },
  props: {
    itemId: null,
    campaignProp: null
  },
  data () {
    return {
      loading: true,
      listMatched: [],
      listUploaded: [],
      contextualMenuExpanded: false,
      itemsSelected: {},
      assetUploader: {
        file: null
      },
      sections: [
        'details'
      ],
      assetFiles: [],
      blank: false,
      currentSection: 'details',
      synced: false,
      selectAll: false,
      campaign: null
    }
  },
  methods: {
    ...mapActions('campaigns', {
      fetchCampaignById: 'fetchById'
    }),
    ...mapActions('placements', [
      'create',
      'update',
      'updateBulk',
      'createBulk',
      'syncTrafficPlanToList'
    ]),
    ...mapActions([
      'registerTask',
      'completeTask'
    ]),
    formSubmit: function (e) {
      const self = this

      self.createUpdatePlacements()
    },
    saveData: function () {

    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('done')
    },
    getStatus: function (id) {
      const item = _.find(this.listMatched, { id })// {id:id}
      if (!item) return ''

      return item.status
      // return 'new';
    },
    matchItems: function () {
      this.listMatched = []
      // match items to see if are new or updated
      if (this.campaign.placements.length < 1) {
        this.listUploaded.map((item, index) => {
          this.listMatched.push({ id: item.id, status: 'new' })
        })
      } else {
        this.listUploaded.map((item, index) => {
          const itemOld = this.campaign.placements.find(oldItem => oldItem.id == item.id)
          if (itemOld) {
            if (_.isEqual(item, itemOld)) {
              this.listMatched.push({ id: item.id, status: '' })
            } else {
              this.listMatched.push({ id: item.id, status: 'update' })
            }
          } else {
            this.listMatched.push({ id: item.id, status: 'new' })
          }
        })
      }
    },
    uploadAssets: function (event) {
      const self = this
      self.assetFiles = []

      // turn oon loading spinner
      self.loading = true

      // Files to be uploaded
      const files = event.target.files

      // amount of pending files.
      const filesQueue = files.length

      const errors = 0

      Papa.parse(files[0], {
        error: function (err, file, inputElem, reason) {
          alert('It appears that there are some files we could not upload. Please try again.')

          // turn off loading spinner
          self.loading = false
        },
        complete: function (results) {
          // generate json placements data to modify/create on save
          if (results.data.length == 1) {
            alert('It appears that the file is empty. Please try again.')

            // turn off loading spinner
            self.loading = false

            return
          }

          const fileNameSplit = files[0].name.split('.')
          self.assetFiles.push({ fileName: `${files[0].name.replace('.' + fileNameSplit[fileNameSplit.length - 1], '')}` })

          const headers = results.data[0]

          // pull out the first row that is the headers data
          results.data.shift()

          const placementsResult = []

          // let's create the placements from the received data
          results.data.map((row, i) => {
            const placementObj = {}

            headers.map((header, j) => {
              placementObj[header] = row[j]
            })

            placementsResult.push(placementObj)
          })

          self.mapPlacementsResult(placementsResult)

          // self.$log.info('Mapped Placements', self.listUploaded);

          // turn off loading spinner
          self.loading = false
        }
      })
    },
    getFormatName: function (formatId) {
      return _.get(_.find(this.creativeFormats, { id: formatId }), 'name', '')
    },
    getKPIName: function (kpiID) {
      return _.get(_.find(this.KPIs, { id: kpiID }), 'name', '')
    },
    getKPIID: function (KPIName) {
      return _.get(_.find(this.KPIs, { name: KPIName }), 'id', '')
    },
    mapPlacementsResult: function (placements) {
      // if we don't have placments passed
      if (placements && placements.length == 0) {
        // this.$log.info.log('No placements to map');
      }

      this.listUploaded = []

      placements.map((placement, index) => {
        const _placement = {}
        const format_id = _.get(_.find(this.creativeFormats, { name: placement.Format }), 'id', '')
        const main_kpi = this.getKPIID(placement['Main KPI'])

        // make sure that every placement has this campaign id
        _placement.id = placement.ID
        _placement.name = placement.Name
        _placement.campaign_id = this.campaign.id
        _placement.external_placement_id = placement['External Placement ID']
        _placement.placement_source = placement['Tag Source']
        _placement.format_id = format_id || ''
        _placement.third_party_id = placement['Measurement 3P ID'] || ''
        _placement.verification_third_party_id = placement['Verification 3P ID'] || ''
        _placement.main_kpi = main_kpi || ''
        _placement.creative_tag_id = 1
        _placement.creative_tag = placement['Tag Code']
        _placement.append_script = placement['Append Script']
        _placement.creative_id = placement['Creative ID']
        _placement.external_creative_id = placement['External Creative ID']

        if (_placement.name != '' && _placement.name != null) this.listUploaded.push(_placement)
      })
    },
    createUpdatePlacements: function () {
      const self = this

      // self.loading = true;

      if (this.validItemsSelectedToObjects.length == 0) return

      // CHECK STATUS TO SEE WE NEED TO CREATE OR UPDATE PLACEMENT
      const itemsWithStatus = this.validItemsSelectedToObjects.map(item => {
        item.status = this.getStatus(item.id, item.name)
        return item
      })

      const promises = []
      const toUpdatePlacements = []
      const toCreatePlacements = []

      // self.listUploaded.map((placement, index) => {
      itemsWithStatus.map((placement, index) => {
        // add external_campaign_id for pass validation on backend
        placement.external_campaign_id = 'N/A'
        placement.campaign_name = 'N/A'
        placement.start_date = ''
        placement.end_date = ''
        placement.goal = 0
        placement.publisher_name = 'N/A'
        placement.trackers = []

        if (placement.main_kpi === '' || placement.main_kpi === undefined) placement.main_kpi = 0
        placement.creative_id = parseInt(placement.creative_id)

        // if(item.status == "new") {
        if (placement.id === '') {
          toCreatePlacements.push(placement)

          // promises.push(self.create(placement));
        } else {
          placement.id = parseInt(placement.id)

          toUpdatePlacements.push(placement)

          // promises.push(self.update(placement));
        }
      })

      self.$log.info(toUpdatePlacements, toCreatePlacements)

      // bulk create
      if (toCreatePlacements.length > 0) {
        promises.push(self.createBulk({ campaign_id: self.campaign.id, placements: toCreatePlacements }))
      }
      // bulk update
      if (toUpdatePlacements.length > 0) {
        promises.push(self.updateBulk({ campaign_id: self.campaign.id, placements: toUpdatePlacements }))
      }

      Promise.all(promises)
        .then(response => {
          self.loading = false

          self.$emit('done', response)
        })
        .catch(err => {
          alert('It appears that there was an error updating the placements. Please try again.')

          self.loading = false
        })

      // self.$log.error('savingPlacementsFromTrafficPlan', err);
    },
    downloadAsset: function (assetFile) {
      const link = document.createElement('a')
      link.download = assetFile.name
      link.target = '_blank'
      link.href = assetFile.path
      link.click()
    },
    populateForm: function () {
      const self = this

      self.$log.info('TRAFFIC PLAN', self.campaign.placements, self.campaign)
    },
    downloadBlankTemplateCSV () {
      this.blank = true
      this.downloadTrafficTemplateCSV(true)
    },
    downloadFilledTemplateCSV () {
      this.blank = false
      this.downloadTrafficTemplateCSV(false)
    },
    downloadTrafficTemplateCSV (isBlank) {
      const self = this

      const tableData = []

      if (isBlank) {
        tableData.push({
          id: '',
          name: '',
          // campaign_id: "",
          external_placement_id: '',
          placement_source: '',
          format_name: '',
          third_party_id: '',
          verification_third_party_id: '',
          main_kpi: '',
          // creative_tag_id: "",
          creative_tag: '',
          append_script: '',
          creative_id: '',
          external_creative_id: ''
        })
      } else {
        self.campaign.placements.map(placement => {
          const format_name = _.get(_.find(this.creativeFormats, { id: placement.format_id }), 'name', '')
          const main_kpi_name = this.getKPIName(placement.main_kpi)

          const tag = (placement.creative_tag) ? '"' + placement.creative_tag.replace(/"/g, '""') + '"' : ''
          const script = (placement.append_script && placement.append_script !== ' ') ? '"' + placement.append_script.replace(/"/g, '""') + '"' : ''

          tableData.push({
            id: placement.id,
            name: placement.name,
            // campaign_id: placement.campaign_id,
            external_placement_id: placement.external_placement_id,
            placement_source: placement.placement_source || self.campaign.campaign_source,
            format_name,
            third_party_id: placement.third_party_id || '',
            verification_third_party_id: placement.verification_third_party_id || '',
            main_kpi: main_kpi_name,
            // creative_tag_id: placement.creative_tag_id,
            creative_tag: tag,
            append_script: script,
            creative_id: placement.creative_id || '',
            external_creative_id: placement.external_creative_id || ''
          })
        })
      }

      // if there are any placements
      if (tableData.length > 0) {
        // format the output before starting downloading this
        const formatedDataOutput = this.formatOutputCSV(tableData)

        // download
        Utils.downloadCSV('TrafficPlan-' + self.campaign.name + '', formatedDataOutput)
      } else {
        alert('There was an issue downloading tags. Please try again. Alternatively you could copy the tags one by one.')
      }
    },
    formatOutputCSV (data) {
      let formatedData = ''
      // formatedData += 'Name,ID,Campaign ID,External Placement ID,3P ID,Format,Tag Source,Creative Tag ID,Tag Code,Append Script';
      formatedData += 'Name,ID,External Placement ID,Measurement 3P ID,Verification 3P ID,Main KPI,Format,Tag Source,Tag Code,Append Script,Creative ID,External Creative ID'

      if (!this.blank) {
        data.map(element => {
          formatedData += '\n'
          // formatedData += element.name + "," + element.id + "," + element.campaign_id + "," + element.external_placement_id + "," + element.third_party_id + "," + element.format_name + "," + element.placement_source + "," + element.creative_tag_id + "," + element.creative_tag + "," + element.append_script;
          formatedData += element.name + ',' + element.id + ',' + element.external_placement_id + ',' + element.third_party_id + ',' + element.verification_third_party_id + ',' + element.main_kpi + ',' + element.format_name + ',' + element.placement_source + ',' + element.creative_tag + ',' + element.append_script + ',' + element.creative_id + ',' + element.external_creative_id
        })
      }

      return formatedData
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.listUploaded.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.itemsSelected, String(item.name), true)
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.listUploaded.map((item, key) => {
          this.$set(this.itemsSelected, String(item.name), false)
        })
      }
    }
  },
  created () {
    const self = this

    self.campaign = self.campaignProp
    self.$log.info('CAMPAIGN PROP', self.campaign), self.campaignProp

    if (!self.campaign || !self.campaign.placements) {
      self.fetchCampaignById(this.$route.params.id)
        .then(response => {
          self.campaign = response
          self.populateForm()

          self.$log.info('CAMPAIGN', self.campaign)
        })
    } else {
      // populate form
      self.populateForm()
    }
  },
  beforeDestroy () {
    const self = this
  },
  computed: {
    ...mapGetters('campaigns', [
      'itemById'
    ]),
    ...mapGetters([
      'creativeFormats',
      'KPIs'
    ]),
    getStatusCount: function () {
      const updateCount = _.filter(this.listMatched, ['status', 'update']).length
      const newCount = _.filter(this.listMatched, ['status', 'new']).length
      // let newCount = this.assetFiles.length;

      const s = (updateCount > 1) ? 's' : ''

      // if(!updateCount && !newCount) return '';

      return `(${updateCount} Update${s}, ${newCount} New)`
    },
    /**
       * Returns an array with the list of id for selected creatives.
       */
    validItemsSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    validItemsSelectedToObjects: function () {
      const data = []

      this.validItemsSelected.map(item => {
        const _item = _.find(this.listUploaded, { name: String(item) })
        data.push(_item)
      })

      return data
    }
  },
  watch: {
    /* when the section is changed */
    currentSection: function (value) {
      const self = this
    },
    listUploaded: function () {
      const self = this
      self.matchItems()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/common.scss";

  .new{
    color:white;
    background-color:$green1;
    margin-right: $spacing-unit/2;
    padding: .4rem $spacing-unit/2;
    border-radius: $roundness;
    text-transform: uppercase;
    font-size:0.8rem;
  }

  .update{
    color:white;
    background-color: $green9;
    margin-right: $spacing-unit/2;
    padding: .4rem $spacing-unit/2;
    border-radius: $roundness;
    text-transform: uppercase;
    font-size:0.8rem;
  }

  .buttons{
    margin-bottom: $spacing-unit;
  }

</style>
