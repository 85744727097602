<script>
import FormField from '../../components/FormField.vue'
import ConfirmationModal from './ConfirmationModal.vue'
import DspReportingFileUpload from '../../components/DspReporting/DspReportingFileUpload.vue'

export default {
  name: 'CreatePlacementListModal',
  components: {
    FormField,
    DspReportingFileUpload,
    ConfirmationModal
  },

  data() {
    return {
      formData: {
        deal_id: null,
        member_id: 'Select an option',
        advertiser_id: null,
        included: false,
        uploaded_file_url: null
      },
      errors: {},
      confirmationModalOptions: {
        affirmative: {
          display: 'confirm',
          val: true
        },
        negative: {
          display: 'cancel',
          val: false
        }
      },
      isConfirmationModal: false
    }
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0
    }
  },
  methods: {
    handleUploadResults(event) {
      this.formData.uploaded_file_url = event.url
      if (this.formData.uploaded_file_url) {
        delete this.errors.uploaded_file_url
      }
    },
    validateField(field, value) {
      const numbersOnly = /^[0-9]*$/

      if (value && value.target && value.target.value) {
        value = value.target.value
      } else if (!value) {
        return null
      }

      switch (field) {
        case 'deal_id':
        case 'member_id':
        case 'advertiser_id':
          if (!numbersOnly.test(value)) {
            this.errors[field]
              = `Please enter only numbers for ${field.replace('_', ' ')}.`
          } else {
            this.$delete(this.errors, field)
          }
          break
        case 'included':
          if (value === 'default') {
            this.errors[field] = 'Please select an option.'
          } else {
            this.$delete(this.errors, field)
          }
          break
        case 'uploaded_file_url':
          if (!value) {
            this.errors[field] = 'A file is needed to upload.'
          } else {
            this.$delete(this.errors, field)
          }
          break
        default:
          break
      }
    },
    validateForm() {
      this.errors = {}
      for (const field in this.formData) {
        this.validateField(field, this.formData[field])
      }

      return Object.keys(this.errors).length === 0
    },

    sendData() {
      if (this.validateForm()) {
        this.isConfirmationModal = true
      }
    },
    selectConfirmationModalOption(isConfirm) {
      if (!isConfirm) {
        this.isConfirmationModal = false
        return
      }
      this.$emit('save-update-success', this.formData)
      this.isConfirmationModal = false
    },
    cancel() {
      // emit event
      this.$emit('cancel')
    }
  }
}
</script>
<template>
  <div class="sModal">
    <header class="sModal__header">Upload Placement List</header>
    <div class="sModal__body">
      <form @submit.prevent="sendData()">
        <FormField
          v-for="field in ['deal_id', 'advertiser_id']"
          :key="field"
          :label="field.replace('_', ' ').toUpperCase() + ' *'"
          :for-id="field"
        >
          <input
            :id="field"
            v-model="formData[field]"
            type="text"
            required
            @input="validateField(field, formData[field])"
          />
          <p
            v-if="errors[field]"
            style="color: red"
          >
            {{ errors[field] }}
          </p>
        </FormField>
        <FormField
          label="Member Id *"
          for-id="member_id"
          fade
        >
          <select
            id="member_id"
            v-model="formData.member_id"
            required
            @input="validateField('member_id', $event)"
          >
            <option disabled>Select an option</option>
            <option :value="15333">15333</option>
            <option :value="13775">13775</option>
          </select>
          <p
            v-if="errors.included"
            style="color: red"
          >
            {{ errors.member_id }}
          </p>
        </FormField>
        <FormField
          label="Include *"
          for-id="include-toggle"
          fade
        >
          <select
            id="include-toggle"
            v-model="formData.included"
            required
            @input="validateField('included', $event)"
          >
            <option :value="true">Include</option>
            <option :value="false">Exclude</option>
          </select>
          <p
            v-if="errors.included"
            style="color: red"
          >
            {{ errors.included }}
          </p>
        </FormField>
        <div>
          <DspReportingFileUpload @send-upload-results="handleUploadResults" />
          <p
            v-if="errors.uploaded_file_url"
            style="color: red"
          ></p>
        </div>
        <footer class="sModal__footer">
          <div class="sModal__footer__left">
            <button
              class="cancelButton"
              @click.prevent="cancel"
            >
              <font-awesome-icon
                icon="ban"
                class="i"
              />
              Cancel
            </button>
          </div>
          <div class="sModal__footer__right">
            <button
              class="saveButton"
              :disabled="hasErrors"
            >
              <font-awesome-icon
                icon="check"
                class="i"
              />
              Send to Xandr
            </button>
          </div>
        </footer>
      </form>
      <!-- Confirmation Modal for back button -->
      <div>
        <ConfirmationModal
          v-if="isConfirmationModal"
          class="confirmation-modal"
          :prompt="'Are you sure you want to send to Xandr?'"
          :affirmative-option="confirmationModalOptions.affirmative"
          :negative-option="confirmationModalOptions.negative"
          @select-option="selectConfirmationModalOption"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/scss/common.scss';
.confirmation-modal .confirmation-modal-content {
  width: 50% !important;
}
</style>
