<template>
  <section class="sModal">
    <div class="loader" :class="{ done: !loading }">
      <font-awesome-icon icon="spinner" class="spinner" />
    </div>
    <header class="sModal__header">Request Settings</header>

    <form @submit.prevent="saveData()">
      <nav class="sModal__tabs">
        <ul>
          <li selected>
            <button @click.prevent="">Details</button>
          </li>
        </ul>
      </nav>
      <div class="sModal__body">
        <div class="formFieldGroup">
          <FormField label="Job Id" forId="job-id">
            <input disabled id="cjob-id" type="text" :value="selectedJob.JobId" />
          </FormField>
          <FormField
            label="Job Status"
            forId="jobstatus"
            type="select"
            disabled
          >
            <select
              id="jobstatus"
              v-model="selectedJob.JobStatus"
              :disabled="!currentUserIsAdmin"
            >
              <option
                v-for="(orderByOption, index) in getfilteredStatus"
                :key="index"
                :value="orderByOption.label"
                :disabled="
                  orderByOption.label === 'Pending' ||
                  orderByOption.label == 'Paused'
                    ? false
                    : true
                "
              >
                {{ orderByOption.label }}
              </option>
            </select>
          </FormField>

          <div class="formFieldGroup__fields">
            <FormField label="Data source" forId="datasource" type="select">
              <select id="datasource" v-model="selectedJob.Source" disabled>
                <option value="airtory">Airtory Sync</option>
                 <option value="dcm">DCM</option>
                  <option value="moat">MOAT</option>
                  <option value="dv">DV</option>
                  <option value="ias">IAS</option>
                   <option value="celtra">CELTRA</option>
              </select>
            </FormField>
            <FormField label="Data type" forId="celtra-id" class="">
              <input id="celtra-id" type="text" disabled :value="selectedJob.Type" />
            </FormField>
          </div>
          <FormField label="External Request Id" forId="external-request-id">
            <input
              id="external-request-id"
              type="text"
              :value="selectedJob.JobId"
              disabled
            />
          </FormField>

          <div class="formFieldGroup__fields">
            <FormField label="Job Created" forId="job-created">
              <input
                id="job-created"
                type="text"
                :value="selectedJob.CreatedAt"
                disabled
              />
            </FormField>
            <FormField label="Job Last Updated" forId="job-last-updated">
              <input
                id="job-last-updated"
                :value="selectedJob.UpdatedAt"
                type="text"
                disabled
              />
            </FormField>
            <FormField
              label="Job Status Check tries"
              forId="job-status-check-tries"
            >
              <input
                id="job-status-check-tries"
                :value="selectedJob.RecivedCount"
                type="text"
                disabled
              />
            </FormField>
          </div>
          <FormField label="Job Message" type="textarea" forId="job-message">
            <textarea
              id="job-message"
              cols="30"
              rows="3"
              minlength="3333"
              :value="selectedJob.JobMessage"
              disabled
            ></textarea>
          </FormField>
          <FormField
            label="Data Deposit Location"
            forId="data-deposit-location"
          >
            <input
              id="data-deposit-location"
              :value="selectedJob.OutputPath"
              type="text"
              disabled
            />
          </FormField>
          <div class="formFieldGroup__fields">
            <FormField
              label="Campaign External Id"
              forId="campaign-external-id"
            >
              <input
                id="campaign-external-id"
                :value="selectedJob.ExternalCampaignId"
                type="text"
                disabled
              />
            </FormField>
            <FormField label="Data Campaign Id" forId="data-campaign-id">
              <input
                disabled
                id="jdata-campaign-id"
                :value="selectedJob.InternalCampaignId"
                type="text"
              />
            </FormField>
          </div>
          <div class="formFieldGroup__fields">
            <FormField label="Data Date From" forId="data-date-from">
              <input
                id="data-date-from"
                :value="selectedJob.From"
                type="text"
                disabled
              />
            </FormField>
            <FormField label="Data Date To" forId="data-date-to">
              <input id="data-date-to" :value="selectedJob.To" type="text" disabled />
            </FormField>
            <FormField label="Data Hour" forId="data-hour">
              <input id="data-hour" :value="selectedJob.Hour" type="text" disabled />
            </FormField>
          </div>
        </div>
      </div>
      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel">
            <font-awesome-icon icon="ban" class="i" />Cancel
          </button>
        </div>

        <div class="sModal__footer__right">
          <button class="saveButton" :disabled="!currentUserIsAdmin">
            <font-awesome-icon icon="check" class="i" />Save Request
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  name: 'RepoAnalyticsDataLoadingSettings',
  components: {
    FormField
  },
  data () {
    return {
      data: {},
      loading: true
    }
  },
  methods: {
    ...mapActions('analytics', ['updateJob', 'fetchJobById']),
    saveData () {
      const self = this
      self.loading = true
      self
        .updateJob(self.selectedJob)
        .then((response) => self.$emit('done', response))
        .catch((err) => (self.loading = false))
    },
    cancel () {
      // emit event
      this.$emit('done')
    }
  },
  computed: {
    ...mapGetters('analytics', ['getDashBoardData']),
    ...mapState('analytics', ['jobStatus', 'selectedJob']),
    ...mapGetters(['userInSession']),
    currentUserIsAdmin: function () {
      return this.userInSession.role_name == 'Admin'
    },
    getfilteredStatus () {
      switch (this.selectedJob.JobStatus) {
        case 'Completed':
          return this.jobStatus.filter(x => x.value == 'Completed')
          break
        case 'Failed':
          return this.jobStatus.filter(x => x.value == 'Failed')
          break
        case 'Paused':
          return this.jobStatus.filter(x => x.value == 'Paused' || x.value == 'Pending')
          break
        case 'Pending':
          return this.jobStatus.filter(x => x.value == 'Pending' || x.value == 'Paused')
          break
        case 'Requested':
          return this.jobStatus.filter(x => x.value == 'Requested' || x.value == 'Paused')
          break
        default:
          return this.jobStatus
          break
      }
    }

  },
  created () {
    const self = this
    self.loading = true

    if (self.JobId) {
      self
        .fetchJobById(self.JobId)
        .then((payload) => {
        /*  self.getfilteredStatus(); */
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })

      /* this.data = this.getDashBoardselectedJob.find((x) => x.JobId == this.JobId);
      this.selectedStatus = this.selectedJob.JobStatus;
      this.loading = false; */
    }
  },
  props: {
    JobId: null
  },
  directives: {
    'job-status': {
      bind (el, binding, vnode) {
        if (binding.value == 'Completed') {
          el.style.color = 'green'
        } else {
          if (binding.value == 'Pending') {
            el.style.color = 'blue'
          } else if (binding.value == 'Failed') {
            el.style.color = 'red'
          }
        }
      }
    }
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}
</style>
