<template>

    <div class="appFrame">

        <Navbar :currentModulePath="'tag-manager'" :currentModuleName="'Tag Manager'" />

        <main class="moduleFrame moduleFrame--tagManagerRepository moduleFrame--tagManagerRepositoryPlacements moduleFrame--tagManagerRepositoryCampaignChild">

          <PencilBar />

          <div v-if="campaign != null" class="heading">{{ campaign.name }}</div>

          <ModuleHeader>
            <ul slot="tabs">
                <li><router-link :to="{path:'/tag-manager/placements/campaign/' + campaign.id, params:{ id:campaign.id, data:campaign}}">Placements</router-link></li>
                <!-- <li><router-link :to="{path:'/creative-production/creatives/campaign/' + campaign.id}">Creatives</router-link></li> -->
                <li selected>Monitoring</li>
                <li><router-link :to="{path:'/analytics/live/campaign/' + campaign.id}">Live</router-link></li>
            </ul>

            <div slot="actions">
              <button class='moduleHeader__createButton mr-3' @click.prevent="openGenerateCSV"><font-awesome-icon icon="table" class="i" />Generate CSV</button>
              <button class='moduleHeader__createButton' @click.prevent="openModalSettings(campaign.id, null, '3PData')"><font-awesome-icon icon="table" class="i" /> Manual Data Upload</button>
            </div>
          </ModuleHeader>
          <div class="container-fluid" style="background-color: #EDEDED;">
              <h5 v-if="topBannerIsLoading" class="loading" ref="topBannerIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
              <div class="row">
                  <MetaData v-for="(item, index) in topBannerData" :key="index" v-bind:title="item.title" v-bind:value="item.value" v-bind:subValue="item.subValue" v-bind:change="item.change" v-bind:color="bannerBackgroundColor"/>
              </div>
          </div>

            <div class="container-fluid pb-0">
                <div class="row">
                    <div class="d-inline">
                        <datepicker v-model="startDate" ref="startDateOpen" placeholder="Start Date"></datepicker>
                    </div>
                    <div class="d-inline px-2 pt-2">
                        <font-awesome-icon @click="openStartDate" class="calendar-button-icon" icon="calendar-alt"/>
                    </div>
                    <div class="d-inline">
                        <datepicker v-model="endDate" ref="endDateOpen" placeholder="End Date"></datepicker>
                    </div>
                    <div class="d-inline px-2 pt-2">
                        <font-awesome-icon @click="openEndDate" class="calendar-button-icon" icon="calendar-alt"/>
                    </div>
                    <div class="d-inline px-2">
                        <select class="custom-select" v-model="placementId">
                          <option value="">Placement</option>
                          <!-- <option v-for="(placement, index) in viewFilterData.placements" :key="index" v-bind:value="placement.placementId">{{placement.placementName}}</option> -->
                        </select>
                    </div>
                    <!--<div class="d-inline px-2" v-if="!showAwareness">-->
                        <!--<select class="custom-select" v-model="productId">-->
                          <!--<option value="">Product Name</option>-->
                          <!--<option v-for="product in viewFilterData.products" v-bind:value="product.productId">{{product.productName}}</option>-->
                        <!--</select>-->
                    <!--</div>-->
                    <div class="d-inline px-2">
                        <select class="custom-select" v-model="creativeId">
                          <option value="">Creative</option>
                          <!-- <option v-for="(creative, index) in viewFilterData.creatives" :key="index" v-bind:value="creative.creativeId">{{creative.creativeName}}</option> -->
                        </select>
                    </div>
                    <div class="d-inline px-2">
                        <button type="button" class="apply-button" v-on:click="initialLoad = true; showAwareness ? getCpmAwareness() : getEngagementsData()">Apply</button>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <button type="button" v-bind:class="showAwareness ? 'nav-button-selected' : 'nav-button'" @click="showAwareness = true">Awareness</button>
                    <button type="button" v-bind:class="showAwareness ? 'nav-button' : 'nav-button-selected'" @click="showAwareness = false; getEngagementsData();">Engagements</button>
                </div>
                <div class="row card-border" v-if="showAwareness">
                        <h5 v-if="impressionsIsLoading" class="loading" ref="impressionsIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <MetaData style="display: inline-block; padding-top: 50px;" v-if="impressions.metaData" v-bind:title="impressions.metaData.title" v-bind:value="impressions.metaData.value" v-bind:subValue="impressions.metaData.subValue" v-bind:change="impressions.metaData.change" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="impressionsInfo"/>
                        <div class="col-3"></div>
                        <GraphDataSourceSelector :cols="'col-7'" style="display: inline-block; padding-top: 50px; float: right;" v-bind:dataSources="[dataSources[0], dataSources[1], dataSources[3], dataSources[2], dataSources[4]]" v-on:sourceSelected="getImpressionsData"/>
                    <div class="col-12" id="impressionsChart">
                        <GChart
                            ref="impressions"
                            type="LineChart"
                            :data="impressions.chartData"
                            :options="impressionsChartOptions"
                            :events="impressionsChartEvents"
                            @ready="onChartReady"
                          />
                    </div>

                    <div class="col-12 pt-5">
                        <div class="border-bottom"></div>
                    </div>

                    <h5 v-if="top5IsLoading" class="loading" ref="top5IsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                    <MetaData style="display: inline-block; padding-top: 50px;" v-bind:title="'Top 5 By KPI Performance'" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="topFiveInfo"/>
                    <div class="col-4"></div>
                    <div class="col-3" style="padding-top: 50px;">
                        <div>
                            <select v-model="topFiveFilter" @change="getTopFivePlacements">
                              <option value="performanceByPlacement">Performance By Placement</option>
                              <option value="performanceByCreative">Performance By Creative</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 px-5">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                  <th scope="col" v-for="(name, index) in topFivePlacementAds.columnNames" :key="index" v-bind:class="index == 0 ? 'first-column' : 'other-column'">{{name}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(value, index) in topFivePlacementAds.tableData" :key="index">
                                  <th scope="row" class="first-column" v-if="(viewTopTwenty == false && index < 5) || (viewTopTwenty == true && index >= 0)">{{value.Rank}}</th>
                                  <td v-for="(item, name) in value" :key="index+item+name" v-if="name != 'Rank' && ((viewTopTwenty == false && index < 5) || (viewTopTwenty == true && index >= 0))">{{item}}</td>
                                </tr>
                              </tbody>
                        </table>
                        <div style="color: #2F9B8C; cursor: pointer;" @click="viewTopTwenty = !viewTopTwenty">{{viewTopTwenty ? 'View Top 5 Ranks' : 'View Top 20 Ranks'}} <font-awesome-icon style="color: #2F9B8C;" class="calendar-button-icon" v-bind:icon="viewTopTwenty ? 'caret-up' : 'caret-down'"/></div>
                    </div>
                    <div class="col-12 pt-5">
                        <div class="border-bottom"></div>
                    </div>

                    <h5 v-if="performanceIsLoading" class="loading" ref="performanceIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                    <MetaData style="display: inline-block; padding-top: 50px;" v-bind:title="'Performance Data'" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="performanceDataInfo"/>
                    <div class="col-5"></div>
                    <div class="col-2" style="padding-top: 50px;">
                        <div>
                            <select v-model="performanceDataFilter" @change="getPerformanceData">
                              <option value="byDay">By Day</option>
                              <option value="byDayOfWeek">By Day of the Week</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12">
                        <GChart
                            type="ColumnChart"
                            :data="performanceDataByDay"
                            :options="performanceDataByDayChartOptions"
                         />
                    </div>
                    <div class="col-12 pt-5">
                        <div class="border-bottom"></div>
                    </div>

                    <h5 v-if="viewabilityIsLoading" class="loading" ref="viewabilityIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                    <MetaData style="display: inline-block; padding-top: 50px;" v-bind:title="'Viewability'" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="viewabilityInfo"/>
                    <div class="col-3"></div>
                    <GraphDataSourceSelector :cols="'col-6'" style="display: inline-block; padding-top: 50px; float: right;" v-on:sourceSelected="getViewabilityData" v-bind:dataSources="[dataSources[3], dataSources[0]]"/>
                    <div class="col-12 pb-5">
                        <GChart
                            type="LineChart"
                            :data="viewability.chartData"
                            :options="viewabilityChartOptions"
                          />
                    </div>

                    <div class="col-3 py-5" v-for="(item, index) in bottomBannerData" :key="index">
                        <h5 v-if="bottomBannerIsLoading" class="loading" :ref="'bottomBannerIsLoading' + index" :id="'bottomBannerIsLoading' + index"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <MetaData v-if="item" v-bind:title="item.title" v-bind:value="item.value" v-bind:subValue="item.subValue" v-bind:change="item.change" v-bind:color="chartBackgroundColor" v-bind:info="bottomBannerInfo[index]" v-bind:onGraph="true"/>
                    </div>
                    <div class="col-3 py-5">
                        <h5 v-if="browserIsLoading" class="loading" ref="browserIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <GChart
                            type="PieChart"
                            :data="browserUsage"
                            :options="browserUsageChartOptions"
                          />
                    </div>
                </div>
                <div class="row card-border" v-if="!showAwareness">
                    <h5 v-if="unitsIsLoading" class="loading" ref="unitsIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                    <MetaData style="display: inline-block; padding-top: 50px;" v-if="unitEngagements.metaData" v-bind:title="unitEngagements.metaData.title" v-bind:value="unitEngagements.metaData.value" v-bind:subValue="unitEngagements.metaData.subValue" v-bind:change="unitEngagements.metaData.change" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="unitEngagementsInfo"/>
                    <div class="col-3"></div>
                    <GraphDataSourceSelector :cols="'col-6'" style="display: inline-block; padding-top: 50px; float: right;" v-on:sourceSelected="getUnitEngagementsData" v-bind:dataSources="[dataSources[0], dataSources[2]]"/>
                    <div class="col-12">
                        <GChart
                            ref="unitEngagements"
                            type="ComboChart"
                            :data="unitEngagements.chartData"
                            :events="unitEngagementsChartEvents"
                            :options="unitEngagementsChartOptions"
                          />
                    </div>
                    <div class="col-12 pt-5">
                        <div class="border-bottom"></div>
                    </div>
                    <div class="col-6">
                        <h5 v-if="effectivenessIsLoading" class="loading" ref="effectivenessIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <div class="row">
                            <MetaData style="display: inline-block; padding-top: 50px;" v-bind:title="'Placement Effectiveness'" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="placementEffectivenessInfo"/>
                            <div class="col-2"></div>
                            <GraphDataSourceSelector :cols="'col-3'" style="display: inline-block; padding-top: 50px; float: right;" v-on:sourceSelected="getPlacementEffectivenessData" v-bind:dataSources="[dataSources[0], dataSources[2]]"/>
                        </div>
                        <GChart
                            type="BubbleChart"
                            :data="placementEffectiveness.chartData"
                            :options="placementEffectivenessChartOptions"
                          />
                    </div>
                    <div class="col-6">
                        <h5 v-if="top5EngamentIsLoading" class="loading" ref="top5EngamentIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <div class="row">
                            <MetaData style="display: inline-block; padding-top: 50px;" v-bind:title="'Top 5'" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="topFiveByInfo"/>
                            <div class="col-3"></div>
                            <div class="col-3" style="padding-top: 50px;">
                                <div>
                                   <select v-model="topFivePlacementsFilter" @change="getTopFivePlacementsData">
                                      <option value="placementsByEngagement">Placements by Engagement</option>
                                      <option value="creativesByEngagement">Creatives by Engagement</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <GChart
                            type="BarChart"
                            :data="topFivePlacements.chartData"
                            :options="topFivePlacementsChartOptions"
                          />
                    </div>
                    <div class="col-12 pt-5">
                        <div class="border-bottom"></div>
                    </div>
                    <div class="col-6">
                        <h5 v-if="top10IsLoading" class="loading" ref="top10IsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <div class="row pt-5">
                            <MetaData style="display: inline-block; padding-top: 50px;" v-bind:title="'Top 10 Custom Event Occurrences'" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="topTenInfo"/>
                            <!-- <div class="col-2"></div> -->
                            <GraphDataSourceSelector :cols="'col-4'" style="display: inline-block; padding-top: 50px; float: right;" v-on:sourceSelected="getCustomEventOccurrencesData" v-bind:dataSources="[dataSources[0], dataSources[2]]"/>
                        </div>
                        <div class="row">
                            <div class="col-12">
                              <GChart
                                type="PieChart"
                                :data="customEventOccurrences.chartData"
                                :options="customEventOccurrencesChartOptions"
                              />
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6 mx-auto py-5" v-for="(item, index) in customEventOccurrencesMetaData" :key="index">
                                <h5 v-if="tEngagementsIsLoading" class="loading" :id="'tEngagementsIsLoading' + index" ref="tEngagementsIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                                <MetaData style="padding-top: 50px;" v-if="item" v-bind:title="item.title" v-bind:value="item.value" v-bind:subValue="item.subValue" v-bind:change="item.change" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="topTenMetaDataInfo[index]"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row py-5" v-if="!showAwareness">
                    <div class="col-3">
                        <h3 class="video-performance-heading">
                            Video Performance
                        </h3>
                    </div>
                    <div class="col-4">
                        <select style="float: right;" class="custom-select" @change="getVideoPerformanceData">
                          <option disabled selected value="">File Name</option>
                          <option v-for="(file, index) in viewFilterData.fileNames" :key="index" v-bind:value="file.fileId">{{file.fileName}}</option>
                        </select>
                    </div>
                    <GraphDataSourceSelector :cols="'col-5'" style="display: inline-block; float: right;" v-on:sourceSelected="getVideoPerformanceData" v-bind:dataSources="[dataSources[0], dataSources[2]]"/>
                </div>
                <div class="row card-border" v-if="!showAwareness">
                    <div class="col-6">
                        <h5 v-if="quartilePerformanceIsLoading" class="loading" ref="quartilePerformanceIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <div class="row">
                            <MetaData style="display: inline-block; padding-top: 50px;" v-if="quartilePerformance.metaData" v-bind:title="quartilePerformance.metaData.title" v-bind:value="quartilePerformance.metaData.value" v-bind:subValue="quartilePerformance.metaData.subvalue" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="quartileInfo"/>
                        </div>
                        <GChart
                            type="LineChart"
                            :data="quartilePerformance.chartData"
                            :options="quartilePerformanceChartOptions"
                          />
                    </div>
                    <div class="col-6">
                        <h5 v-if="completionRateIsLoading" class="loading" ref="completionRateIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                        <div class="row">
                            <!-- <MetaData style="display: inline-block; padding-top: 50px;" :key="item.index" v-bind:title="'Inline Video Completion Rate'" v-if="inlineVideoCompletionRate.metaData" v-bind:title="inlineVideoCompletionRate.metaData.title" v-bind:value="inlineVideoCompletionRate.metaData.value" v-bind:subValue="inlineVideoCompletionRate.metaData.subValue" v-bind:change="inlineVideoCompletionRate.metaData.change" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="inlineVideoCompletionRateInfo"/> -->
                            <MetaData style="display: inline-block; padding-top: 50px;" v-if="inlineVideoCompletionRate.metaData" v-bind:title="inlineVideoCompletionRate.metaData.title" v-bind:value="inlineVideoCompletionRate.metaData.value" v-bind:subValue="inlineVideoCompletionRate.metaData.subValue" v-bind:change="inlineVideoCompletionRate.metaData.change" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="inlineVideoCompletionRateInfo"/>
                        </div>
                        <GChart
                            type="LineChart"
                            :data="inlineVideoCompletionRate.chartData"
                            :options="viewabilityChartOptions"
                          />
                    </div>
                        <div class="col-3 py-5" v-for="(item, index) in inlineVideoCompletionRateMetaData" :key="index">
                            <h5 v-if="completionRateIsLoading" class="loading" :id="'completionRateIsLoading' + index" :ref="completionRateIsLoading"><font-awesome-icon icon="spinner" class="spinner"/>loading...</h5>
                            <MetaData style="padding-top: 50px;" v-if="item" v-bind:title="item.title" v-bind:value="item.value" v-bind:subValue="item.subValue" v-bind:change="item.change" v-bind:color="chartBackgroundColor" v-bind:onGraph="true" v-bind:info="videoPerformanceMetaDataInfo[index]"/>
                        </div>
                </div>
            </div>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          :class="{ modalFullWidth: isModalFullWidth }"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            :campaignId="modalData.campaignId"
            :campaignName="modalData.campaignName"
            :campaignCeltraId="modalData.campaignCeltraId"
            :campaignIsCeltraSync="modalData.campaignIsCeltraSync"
            :advertiserId="modalData.advertiserId"
            :advertiserName="modalData.advertiserName"
            :campaign="modalData.campaign"
            :currentSectionProp="modalData.currentSectionProp"
            @done="hideModal()"
            @fallbackChanged="onFallbackChanged"
           />
        </b-modal>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import ProfileModalCampaign from '@/views/modals/RepoCampaignProfile.vue'
import ProfileModal3PData from '@/views/modals/RepoManual3PDataUploadProfile.vue'
import WebLinks from '@/components/WebLinks.vue'
import MetaData from '@/components/MetaData.vue'
import { mapGetters, mapActions } from 'vuex'
import Utils from '@/helpers/Utils.js'

// import Google charts modules here
import { GChart } from 'vue-google-charts'
import GraphDataSourceSelector from '@/components/GraphDataSourceSelector.vue'
import Datepicker from 'vuejs-datepicker'
import GenerateCSV from '@/views/modals/GenerateCSV.vue'

export default {
  name: 'TagManagerRepositoryPerformance',
  components: {
    Navbar, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks, ProfileModalCampaign, ProfileModal3PData, GChart, MetaData, GraphDataSourceSelector, Datepicker, GenerateCSV
  },
  data () {
    return {
      staticBModal: true,
      // Awareness loading
      topBannerIsLoading: true,
      impressionsIsLoading: true,
      top5IsLoading: true,
      performanceIsLoading: true,
      viewabilityIsLoading: true,
      bottomBannerIsLoading: true,
      browserIsLoading: true,
      // Engagement loading
      unitsIsLoading: true,
      effectivenessIsLoading: true,
      top5EngamentIsLoading: true,
      top10IsLoading: true,
      tEngagementsIsLoading: true,
      quartilePerformanceIsLoading: true,
      completionRateIsLoading: true,
      impressionsInfo: "<p>Impressions: Requested Impressions: The number of times an ad tag is fired and creative is fetched from it's source.</p>\n" +
          '                            <p>Loaded Impressions: The number of times where the creative was loaded to the point where it could be displayed.</p>\n' +
          '                            <p>Rendered Impressions: The number of Loaded impressions in which the creative content appeared.</p>\n' +
          '                            <p>Friendly Iframe Impressions: Number of impressions where creative tag is delivered into a Friendly Iframe.</p>\n' +
          "                            <p>IAB Viewable Impressions: Impressions across desktop, mobile web and mobile in-app delivery that were measured as viewable according to the IAB Viewability Guidelines: at least 50% of the creative was in the viewable portion of a user's device for 1 consecutive second or more.</p>\n" +
          '                            <p>Effective Render Rate : Ratio of Rendered Impression to Requested Impressions; Formerly named Jenny Rate.</p>\n' +
          '                            <p>Valid Impressions: The unique count of impressions free from bot fraud, site fraud, nonhuman data center traffic, hijacked devices and injected ads</p>',
      topFiveInfo: '<p>Viewability: IAB Viewability: Ratio of IAB Viewable Impressions to Analyzed Impressions</p>\n' +
            '                            <p>Clicks: The number of impressions where a click occurred</p>\n' +
            '                            <p>Click Through Rate (CTR): The ratio of Clicks to Analyzed Impressions</p>\n' +
            '                            <p>Placement: Represents an ad traffic strategy</p>\n' +
            '                            <p>Creative: The interactive piece delivered to the end user</p>\n' +
            '                            <p>Product Name: PadSquad name for the format served</p>\n' +
            "                            <p>Impressions: The number of times an ad tag is fired and creative is fetched from it's source</p>",
      performanceDataInfo: "<p>Impressions: Requested Impressions: The number of times a creative tag is fired and creative is fetched from it's source.</p>\n" +
            '                            <p>Click Through rate (CTR): The ratio of Clicks to Analyzed Impressions</p>',
      viewabilityInfo: '<p>Viewability: IAB Viewability: Ratio of IAB Viewable Impressions to Analyzed Impressions</p>',
      bottomBannerInfo: [
        '<p>Viewability: IAB Viewability: Ratio of IAB Viewable Impressions to Analyzed Impressions</p>',
        '<p>3P Verification Viewability: The ratio of 3P viewable impressions to 3P Viewability Measured Impressions</p>',
        '<p>IAB Friendly Iframe Impressions: Number of impressions where creative tag is delivered into an IAB Friendly Iframe</p>'
      ],
      unitEngagementsInfo: '<p>Unit Engagements: The number of unit views in which the user interacted with the unit.</p>',
      placementEffectivenessInfo: '<p>Click Through Rate (CTR): The ratio of Clicks to Analyzed Impressions</p>\n' +
            '<p>Avg. Exposure Time: The average amount of time the Main Creative was in view per user session</p>',
      topFiveByInfo: '<p>Top performing placements based on primary and secondary KPIs</p>',
      topTenInfo: '<p>Custom Event Occurrences: The number of times a custom event has been registered</p>',
      topTenMetaDataInfo: [
        '<p>Total Engagements: The number of times a user interacted with the ad content</p>',
        '<p>Total Time Spent: The aggregate time users have spent with the ad content</p>\n' +
            '<p>Average Time Spent: The ratio Total Time Spent to Friendly Iframe Impressions</p>',
        '<p>Total Exposure Time: The total amount of time the Main Creative was in view across all user sessions</p>'
      ],
      quartileInfo: '<p>The number of times a video has been played to a specific percentage of its length</p>',
      inlineVideoCompletionRateInfo: '<p>Inline Video completion Rate: The ratio of Inline video completions to Inline video starts.</p>',
      videoPerformanceMetaDataInfo: [
        '<p>Video First Quartile View: The number of video starts in which the inline video played through the first quarter marker of the video.</p>',
        '<p>Video Second Quartile View:  The number of video starts in which the video played through the second quarter marker of the video.</p>',
        '<p>Video Third Quartile View: The number of video starts in which the inline video played through the third quarter marker of the video.</p>',
        '<p>Video Fourth Quartile View: The number of video starts in which the video played through the fourth quarter marker of the video.</p>',
        '<p>Total Video Play Time: The total amount of time video was played across all user sessions</p>',
        '<p>Avg. Video Play Time: The average amount of time video was played across per user session</p>'
      ],
      impressionsColumns: [],
      impressionsData: null,
      impressionsSeries: {},
      unitEngagementsColumns: [],
      unitEngagementsData: null,
      unitEngagementsSeries: {},
      chartsLib: null,
      initialLoad: true,
      showAwareness: true,
      impressions: { chartData: [['date', 'Impressions', 'Loaded Impressions', 'Rendered Impressions', 'Friendly Iframe Impressions', 'IAB Viewable Impressions', 'Effective Render Rate (%)'], ['None', 0, 0, 0, 0, 0, 0.0]], metaData: { title: 'Impressions', value: '-', subValue: 'Average --', change: 'negative' } },
      unitEngagements: {},
      deselected: [],
      dataSources: [{ name: 'Simpli', selected: true }, { name: '3P Measurements', selected: false }, { name: 'Celtra', selected: false }, { name: '3P Verification', selected: false }, { name: 'All', selected: false }],
      impressionsChartOptions: {
        title: '',
        width: '100%',
        height: 500,
        hAxis: { showTextEvery: 1, fontName: 'Montserrat', color: '#646464' },
        vAxes: {
          0: {
            viewWindowMode: 'explicit',
            gridlines: { color: 'transparent' }
          },
          1: {
            gridlines: { color: 'transparent' },
            format: '#%'
          }
        },
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 0 }
          // 2:{targetAxisIndex:1},
        },
        colors: ['#1B5F55', '#3BBCA1', '#A0B469', '#F2BF2D', '#3E6990', '#6457A6', '#FF715B',
          '#2F9B8C', '#6DABE0', '#7E5FFF', '#FF789B', '#70C278', '#87CCE5', '#AE61FF', '#EAA15E',
          '#8AD622', '#66E2FF', '#F466FF', '#AD7AB4'],
        legend: { position: 'bottom' },
        chartArea: { width: '90%' }
      },
      impressionsChartEvents: {
        select: () => {
          const chart = this.$refs.impressions.chartObject
          const sel = chart.getSelection()
          // if selection length is 0, we deselected an element
          if (sel.length > 0) {
            // if row is undefined, we clicked on the legend
            if (sel[0].row === null) {
              const col = sel[0].column
              if (this.impressionsColumns[col] == col) {
                // hide the data series
                this.impressionsColumns[col] = {
                  label: this.impressionsData.getColumnLabel(col),
                  type: this.impressionsData.getColumnType(col),
                  calc: function () {
                    return null
                  }
                }

                // grey out the legend entry
                this.impressionsSeries[col - 1].color = '#CCCCCC'
              } else {
                // show the data series
                this.impressionsColumns[col] = col
                this.impressionsSeries[col - 1].color = null
              }
              this.impressionsChartOptions.series = this.impressionsSeries
              this.impressionsChartOptions.series[1].targetAxisIndex = 0
              if (this.impressionsDataSource == '3P Verification' || this.impressionsDataSource == '3P Measurements') {
                this.impressionsChartOptions.series[this.impressions.chartData[0].length - 2].targetAxisIndex = {}
              } else if (this.impressionsDataSource == 'All') {
                this.impressionsChartOptions.series[this.impressions.chartData[0].length - 2].targetAxisIndex = 1
                this.impressionsChartOptions.series[this.impressions.chartData[0].length - 3].targetAxisIndex = 1
              } else {
                this.impressionsChartOptions.series[this.impressions.chartData[0].length - 2].targetAxisIndex = 1
              }
              const view = new this.chartsLib.visualization.DataView(this.impressionsData)
              view.setColumns(this.impressionsColumns)
              chart.draw(view, this.impressionsChartOptions)
            }
          }
        }
      },
      performanceDataByDay: [['Day', 'Impression', 'Third Party CTR', 'Simpli Engagement Rate'], ['None', 0, 0, 0.0]],
      performanceDataByDayChartOptions: {
        chartArea: { width: '90%' },
        colors: ['#70C278', '#339B8C', '#F1C12D'],
        legend: { position: 'bottom' },
        vAxis: {
          gridlines: { color: 'transparent' }
        }
      },
      viewability: { chartData: [['Date', 'Viewability'], ['None', 0]] },
      viewabilityChartOptions: {
        chartArea: { width: '90%' },
        colors: ['#339B8C'],
        legend: { position: 'none' }
      },
      browserUsage: [['Browser', 'Usage'], ['Safari', 1], ['Others', 1], ['Chrome', 1], ['Mobile Safari', 1]],
      browserUsageChartOptions: {
        pieHole: 0.5,
        colors: ['#1B5F55', '#8AD622', '#F2BF2D', '#3BBCA1', 'grey'],
        legend: {
          position: 'left',
          textStyle: { color: 'grey', fontSize: 14, fontName: 'Montserrat' }
        },
        chartArea: { width: '100%', height: '100%' }
      },
      unitEngagements: { chartData: [['Day', 'Unit Engagements', 'Engagement Rate'], ['None', 0, 0.0]], metaData: { title: 'Unit Engagements', value: '-', subValue: 'Average --', change: 'negative' } },
      unitEngagementsChartOptions: {
        // seriesType: 'bars',
        vAxes: {
          0: {
            viewWindowMode: 'explicit',
            gridlines: { color: 'transparent' }
          },
          1: {
            gridlines: { color: 'transparent' },
            format: '#%'
          },
          2: {
            gridlines: { color: 'transparent' },
            format: '#%'
          }
        },
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 1, type: 'line' },
          2: { targetAxisIndex: 2, type: 'bars' }
        },
        legend: { position: 'bottom' },
        colors: ['#2F9B8C', '#9f9f9f'],
        chartArea: { width: '90%' }
      },
      unitEngagementsChartEvents: {
        select: () => {
          const chart = this.$refs.unitEngagements.chartObject
          const sel = chart.getSelection()
          // if selection length is 0, we deselected an element
          if (sel.length > 0) {
            // if row is undefined, we clicked on the legend
            if (sel[0].row === null) {
              const col = sel[0].column
              if (this.unitEngagementsColumns[col] == col) {
                // hide the data series
                this.unitEngagementsColumns[col] = {
                  label: this.unitEngagementsData.getColumnLabel(col),
                  type: this.unitEngagementsData.getColumnType(col),
                  calc: function () {
                    return null
                  }
                }

                // grey out the legend entry
                this.unitEngagementsSeries[col - 1].color = '#CCCCCC'
              } else {
                // show the data series
                this.unitEngagementsColumns[col] = col
                this.unitEngagementsSeries[col - 1].color = null
              }
              this.unitEngagementsChartOptions.series = this.unitEngagementsSeries
              this.unitEngagementsChartOptions.series[1].targetAxisIndex = 0
              if (this.unitEngagementsDataSource == '3P Verification' || this.unitEngagementsDataSource == '3P Measurements') {
                this.unitEngagementsChartOptions.series[this.unitEngagements.chartData[0].length - 2].targetAxisIndex = {}
              } else if (this.unitEngagementsDataSource == 'All') {
                this.unitEngagementsChartOptions.series[this.unitEngagements.chartData[0].length - 2].targetAxisIndex = 1
                this.unitEngagementsChartOptions.series[this.unitEngagements.chartData[0].length - 3].targetAxisIndex = 1
              } else {
                this.unitEngagementsChartOptions.series[this.unitEngagements.chartData[0].length - 2].targetAxisIndex = 1
              }
              const view = new this.chartsLib.visualization.DataView(this.unitEngagementsData)
              view.setColumns(this.unitEngagementsColumns)
              chart.draw(view, this.unitEngagementsChartOptions)
            }
          }
        }
      },
      placementEffectiveness: { chartData: [['Placement ID', 'Avg Exposure Time', 'CTR', 'Placement Name', 'Engagement Count'], ['0000', 10000.0, 0.0, null, 0]] },
      placementEffectivenessChartOptions: {
        height: 600,
        colors: ['#2F9B8C'],
        legend: { position: 'none' },
        chartArea: { width: '80%', height: '80%' },
        vAxis: { format: 'percent', title: 'CTR', titleTextStyle: { color: '#5C5C5C', fontName: 'Montserrat', fontSize: 12, bold: true } },
        hAxis: { title: 'Average Exposure Time (hrs)', titleTextStyle: { color: '#5C5C5C', fontName: 'Montserrat', fontSize: 12, bold: true } }
      },
      topFivePlacementsFilter: 'placementsByEngagement',
      topFivePlacements: { chartData: [['Creatives', 'AM', 'PM'], ['None', 0.0, 0.0], ['THD_BlackFriday_Enhanced_Q4_Desktop_Tile', 0.0, 0.00]] },
      topFivePlacementsChartOptions: {
        height: 600,
        legend: { position: 'bottom' },
        chartArea: { width: '70%', height: '80%' },
        colors: ['#2F9B8C', '#F2BF2D'],
        vAxis: { textStyle: { color: '#5C5C5C', fontName: 'Montserrat', fontSize: 14, bold: true } },
        hAxis: { format: 'percent', titleTextStyle: { color: '#5C5C5C', fontName: 'Montserrat', fontSize: 12, bold: true } }
      },
      customEventOccurrences: { chartData: [['Custom Event Occurences', 'Number of Occurences'], ['none', 1]] },
      customEventOccurrencesChartOptions: {
        pieHole: 0.5,
        colors: ['#1B5F55', '#8AD622', '#F2BF2D', '#3BBCA1', 'grey'],
        legend: {
          position: 'left',
          textStyle: { color: 'grey', fontSize: 14, fontName: 'Montserrat' }
        },
        chartArea: { width: '90%', height: '100%' }
      },
      customEventOccurrencesMetaData: [{ title: 'Total Engagements', value: '-', subValue: 'Campaign Average 00000 ', change: 'negative' }, { title: 'Total Time Spent', value: '-', subValue: 'Campaign Average - min -- ', change: 'negative' }, { title: 'Total Exposure Time', value: '-', subValue: 'Campaign Average - hr -- min -- ', change: 'negative' }],
      quartilePerformance: { chartData: [['Quartile', 'Drop Point'], ['Starts', 0.0], ['1st', 0.0], ['2nd', 0.0], ['3rd', 0.0], ['4th', 0.0]], metaData: { title: 'Quartile Performance', value: '-', subvalue: 'Video Starts' } },
      quartilePerformanceChartOptions: {
        chartArea: { width: '90%', height: '50%' },
        colors: ['#339B8C'],
        legend: { position: 'none' }
      },
      inlineVideoCompletionRate: { chartData: [['Date', 'Rate'], ['None', 0.0]], metaData: { title: 'Inline Video Completion Rate', value: '--', subValue: 'Campaign Average --', change: 'negative' } },
      inlineVideoCompletionRateMetaData: [{ title: 'Inline First Quartile Views', value: '-', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Inline Second Quartile Views', value: '-', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Inline Third Quartile Views', value: '-', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Inline Fourth Quartile Views', value: '-', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Total Video Playtime', value: '-', subValue: 'Campaign Average - %', change: 'positive' }, { title: 'Average Video Playtime', value: '-', subValue: 'Campaign Average - %', change: 'positive' }],
      videoPerformanceFileName: '',
      chartBackgroundColor: '#FFFFFF',
      bannerBackgroundColor: '#EDEDED',
      mobileFiltersExpanded: false,
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)).getTime(),
      endDate: new Date().getTime(),
      placementId: '',
      productId: '',
      creativeId: '',
      impressionsDataSource: 'Simpli',
      viewabilityDataSource: 'Simpli',
      unitEngagementsDataSource: 'Simpli',
      placementEffectivenessDataSource: 'Simpli',
      videoPerformanceDataSource: this.$store.state.dataSource,
      customEventOccurrencesDataSource: 'Simpli',
      // dataSource: 'Source One',
      topBannerData: [{ title: 'Third Party CTR', value: '--', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Simpli CTR', value: '--', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Simpli Engagement Rate', value: '--', subValue: 'Campaign Average --', change: 'positive' }, { title: 'Celtra Engagement Rate', value: '--', subValue: 'Campaign Average --', change: 'negative' }],
      viewFilterData: { placements: [{ placementName: null, placementId: '0000' }, { placementName: 'None', placementId: '0000' }], fileNames: [{ fileName: '' }], creatives: [{ creativeName: 'None', creativeId: '000' }, { creativeName: 'None', creativeId: '000' }] },
      topFivePlacementAds: { tableData: [{ Rank: '-', Placement: 'None', 'Format Name': 'None', 'Third Party CTR': '--', 'Simpli CTR': '--', 'Simpli Engagement_Rate': '--', 'Celtra Engagement_Rate': '--' }], columnNames: ['Rank', 'Placement', 'Format Name', 'Third Party CTR', 'Simpli CTR', 'Simpli Engagement_Rate', 'Celtra Engagement_Rate'] },
      bottomBannerData: [{ title: 'Overall Simpli Viewability', value: '-', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Overall 3P Verification Viewability', value: '-', subValue: 'Campaign Average --', change: 'negative' }, { title: 'Simpli IAB Friendly Iframe', value: '-', subValue: 'Campaign Average --', change: 'negative' }],
      viewTopTwenty: false,
      topFiveFilter: 'performanceByPlacement',
      performanceDataFilter: 'byDay',
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Format Type',
            value: 'formatType'
          },
          {
            label: 'Created Date',
            value: 'placementCreatedDate'
          }
        ]
      },
      breadcrumsData: [
        {
          text: 'Tag Manager',
          href: '/tag-manager'
        },
        {
          text: 'Campaigns',
          href: '/tag-manager/campaigns'
        },
        {
          text: 'Campaign',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        campaignId: null,
        campaignName: null,
        campaignCeltraId: null,
        campaignIsCeltraSync: null,
        advertiserId: null,
        advertiserName: null,
        campaign: null,
        currentSectionProp: null
      },
      contextualMenuExpanded: false,
      campaign: { campaign_source: { id: null } },
      fallbackImages: [],
      isModalFullWidth: false,
      selectAll: false
    }
  },
  methods: {
    onChartReady (chart, google) {
      // now we have google lib loaded. Let's create data table based using it.
      this.chartsLib = google
    },
    ...mapActions('campaigns', {
      replaceCampaign: 'replace',
      fetchCampaignById: 'fetchById'
    }),
    ...mapActions('analytics', {
      fetchFilterData: 'fetchFilterData',
      fetchCpmAwarenessTopBannerData: 'fetchCpmAwarenessTopBannerData',
      fetchCpmAwarenessImpressionsData: 'fetchCpmAwarenessImpressionsData',
      fetchCpmAwarenessTopFivePlacementAdsData: 'fetchCpmAwarenessTopFivePlacementAdsData',
      fetchCpmAwarenessPerformanceDataByDayData: 'fetchCpmAwarenessPerformanceDataByDayData',
      fetchCpmAwarenessViewableImpressionsData: 'fetchCpmAwarenessViewableImpressionsData',
      fetchCpmAwarenessBottomBannerData: 'fetchCpmAwarenessBottomBannerData',
      fetchCpmAwarenessBrowserUsageData: 'fetchCpmAwarenessBrowserUsageData',
      fetchCpmEngagementsUnitEngagementsData: 'fetchCpmEngagementsUnitEngagementsData',
      fetchCpmEngagementsPlacementEffectivenessData: 'fetchCpmEngagementsPlacementEffectivenessData',
      fetchCpmEngagementsTopFivePlacementsData: 'fetchCpmEngagementsTopFivePlacementsData',
      fetchCpmEngagementsCustomEventOccurrencesData: 'fetchCpmEngagementsCustomEventOccurrencesData',
      fetchCpmEngagementsCustomEventOccurrencesMetaData: 'fetchCpmEngagementsCustomEventOccurrencesMetaData',
      fetchCpmEngagementsQuartilePerformance: 'fetchCpmEngagementsQuartilePerformance',
      fetchCpmEngagementsInlineVideoCompletionRate: 'fetchCpmEngagementsInlineVideoCompletionRate',
      fetchCpmEngagementsInlineVideoCompletionRateMetaData: 'fetchCpmEngagementsInlineVideoCompletionRateMetaData'
    }),
    ...mapActions([
      'fetchCreativeFormats',
      'registerModalOpened',
      'registerModalClosed'
    ]),
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':

          break
        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    openModalSettings: function (itemId, currentSection, modal) {
      const self = this
      // set the modal item id to the userId we want to update
      self.modalData.itemId = itemId

      // for opening fallbacks directly
      self.modalData.currentSectionProp = currentSection

      // self.$log.info('Campaign Id', itemId, this.listCampaign);

      // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCampaign
      const _advertiser = this.advertiserById(this.campaign.advertiser_id)

      self.modalData.advertiserId = _advertiser.id
      self.modalData.advertiserName = _advertiser.name

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = (modal === 'Campaign') ? ProfileModalCampaign : ProfileModal3PData
      this.isModalFullWidth = false

      // show modal
      self.$refs.moduleModal.show()
    },
    openGenerateCSV: function () {
      const self = this
      // set the modal item id to the userId we want to update
      // self.modalData.itemId = itemId;

      // for opening fallbacks directly
      // self.modalData.currentSectionProp = currentSection;

      // self.$log.info('Campaign Id', itemId, this.listCampaign);

      // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCampaign
      // let _advertiser = this.advertiserById(this.campaign.advertiser_id);

      // self.modalData.advertiserId = _advertiser.id;
      // self.modalData.advertiserName = _advertiser.name;

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.campaignId = this.$route.params.id
      self.modalData.campaignName = this.campaign.name
      self.modalData.currentComponent = GenerateCSV
      this.isModalFullWidth = false

      // show modal
      self.$refs.moduleModal.show()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()
    },
    onFallbackChanged: function (fallbacks) {
      this.fallbackImages = fallbacks.filter(fallback => {
        return fallback.image_url != null
      })
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('M/DD/YY h:mma')

      return (formattedDate != 'Invalid date') ? formattedDate : ''
    },
    populateFilters: function () {
      const self = this
    },
    getAwarenessImpressionViewabilityPlacementsPerformance: function () {
      const self = this
      this.$store.dispatch('analytics/fetchCpmAwarenessImpressionsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, dataSource: this.impressionsDataSource, creativeId: this.creativeId })
        .then(() => {
          self.getAwarenessViewabilityPlacementsPerformance()
        }
        )
        .catch(() => {
          self.getAwarenessViewabilityPlacementsPerformance()
        }
        )
    },

    getAwarenessViewabilityPlacementsPerformance: function () {
      const self = this
      this.$store.dispatch('analytics/fetchCpmAwarenessViewableImpressionsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, dataSource: this.viewabilityDataSource, creativeId: this.creativeId })
        .then(() => {
          self.getAwarenessPlacementsPerformance()
        }
        )
        .catch(() => {
          self.getAwarenessPlacementsPerformance()
        }
        )
    },

    getAwarenessPlacementsPerformance: function () {
      const self = this
      this.$store.dispatch('analytics/fetchCpmAwarenessTopFivePlacementAdsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, sortBy: this.topFiveFilter, creativeId: this.creativeId })
        .then(() => {
          self.getAwarenessPerformance()
        }
        )
        .catch(() => {
          self.getAwarenessPerformance()
        }
        )
    },

    getAwarenessPerformance: function () {
      const self = this
      this.$store.dispatch('analytics/fetchCpmAwarenessPerformanceDataByDayData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, sortBy: this.performanceDataFilter, creativeId: this.creativeId })
        .then(() => {
          self.getBottomBrowser()
        }
        )
        .catch(() => {
          self.getBottomBrowser()
        }
        )
    },

    getBottomBrowser: function () {
      this.$store.dispatch('analytics/fetchCpmAwarenessBottomBannerData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, creativeId: this.creativeId })
      this.$store.dispatch('analytics/fetchCpmAwarenessBrowserUsageData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, creativeId: this.creativeId })
    },

    getImpressionsData: function (source) {
      this.impressionsDataSource = source
      this.$store.dispatch('analytics/fetchCpmAwarenessImpressionsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, dataSource: this.impressionsDataSource, creativeId: this.creativeId })
    },
    getViewabilityData: function (source) {
      this.viewabilityDataSource = source
      this.$store.dispatch('analytics/fetchCpmAwarenessViewableImpressionsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, dataSource: this.viewabilityDataSource, creativeId: this.creativeId })
    },
    getTopFivePlacements: function () {
      // Get top five by kpi performance fetchCpmAwarenessTopFivePlacementAdsData
      this.$store.dispatch('analytics/fetchCpmAwarenessTopFivePlacementAdsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, sortBy: this.topFiveFilter, creativeId: this.creativeId })
    },
    getPerformanceData: function () {
      // Get performance data by data
      this.$store.dispatch('analytics/fetchCpmAwarenessPerformanceDataByDayData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, sortBy: this.performanceDataFilter, creativeId: this.creativeId })
    },
    getUnitEngagementsData: function (source, initial) {
      this.unitEngagementsDataSource = source
      const self = this
      this.$store.dispatch('analytics/fetchCpmEngagementsUnitEngagementsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, dataSource: this.unitEngagementsDataSource, creativeId: this.creativeId })
        .then(() => {
          if (initial) {
            self.unitsIsLoading = false
            self.getPlacementEffectivenessData(self.placementEffectivenessDataSource, initial)
          }
        })
        .catch(() => {
          if (initial) {
            self.$refs.unitsIsLoading.innerHTML = 'Error'
            self.getPlacementEffectivenessData(self.placementEffectivenessDataSource, initial)
          }
        })
    },
    getPlacementEffectivenessData: function (source, initial) {
      const self = this
      this.placementEffectivenessDataSource = source
      this.$store.dispatch('analytics/fetchCpmEngagementsPlacementEffectivenessData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, dataSource: this.placementEffectivenessDataSource, creativeId: this.creativeId })
        .then(() => {
          if (initial) {
            self.effectivenessIsLoading = false
            self.getTopFivePlacementsData(initial)
          }
        })
        .catch(() => {
          if (initial) {
            self.getTopFivePlacementsData(initial)
            self.$refs.effectivenessIsLoading.innerHTML = 'Error'
          }
        })
    },
    getTopFivePlacementsData: function (initial) {
      const self = this
      this.$store.dispatch('analytics/fetchCpmEngagementsTopFivePlacementsData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, sortBy: this.topFivePlacementsFilter, creativeId: this.creativeId })
        .then(() => {
          if (initial) {
            self.top5EngamentIsLoading = false
            self.getCustomEventOccurrencesData(self.customEventOccurrencesDataSource, initial)
          }
        })
        .catch(() => {
          if (initial) {
            self.getCustomEventOccurrencesData(self.customEventOccurrencesDataSource, initial)
            self.$refs.top5EngamentIsLoading.innerHTML = 'Error'
          }
        })
    },
    getCustomEventOccurrencesData: function (source, initial) {
      this.customEventOccurrencesDataSource = source
      const self = this
      this.$store.dispatch('analytics/fetchCpmEngagementsCustomEventOccurrencesData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, dataSource: this.customEventOccurrencesDataSource, creativeId: this.creativeId })
        .then(() => {
          if (initial) {
            self.top10IsLoading = false
            self.getCustomEventOccurrencesMetaData(initial)
          }
        })
        .catch(() => {
          if (initial) {
            self.getCustomEventOccurrencesMetaData(initial)
            self.$refs.top10IsLoading.innerHTML = 'Error'
          }
        })
    },

    getCustomEventOccurrencesMetaData: function (initial) {
      const self = this
      this.$store.dispatch('analytics/fetchCpmEngagementsCustomEventOccurrencesMetaData', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, creativeId: this.creativeId })
        .then(() => {
          if (initial) {
            self.tEngagementsIsLoading = false
            self.getVideoPerformanceData('Simpli', initial)
          }
        })
        .catch(() => {
          if (initial) {
            self.getVideoPerformanceData('Simpli', initial)
            // self.$refs.tEngagementsIsLoading.innerHTML = 'Error';
            document.querySelector('#tEngagementsIsLoading0').innerHTML = 'Error'
            document.querySelector('#tEngagementsIsLoading1').innerHTML = 'Error'
            document.querySelector('#tEngagementsIsLoading2').innerHTML = 'Error'
          }
        })
    },
    openStartDate: function () {
      this.$refs.startDateOpen.showCalendar()
    },
    openEndDate: function () {
      this.$refs.endDateOpen.showCalendar()
    },
    getVideoPerformanceData: function (event, initial) {
      const self = this
      try {
        this.videoPerformanceFileName = event.target.selectedOptions[0].text
      } catch (err) {
      }
      this.$store.dispatch('analytics/fetchCpmEngagementsQuartilePerformance', { id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId, creativeId: this.creativeId, dataSource: self.$store.state.dataSource, fileName: this.videoPerformanceFileName })
        .then(() => {
          self.quartilePerformanceIsLoading = false
          self.$store.dispatch('analytics/fetchCpmEngagementsInlineVideoCompletionRate', { id: self.$route.params.id, startDate: new Date(self.startDate).getTime(), endDate: new Date(self.endDate).getTime(), placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, dataSource: self.$store.state.dataSource, fileName: self.videoPerformanceFileName })
            .then(() => {
              self.completionRateIsLoading = false
              self.$store.dispatch('analytics/fetchCpmEngagementsInlineVideoCompletionRateMetaData', { id: self.$route.params.id, startDate: new Date(self.startDate).getTime(), endDate: new Date(self.endDate).getTime(), placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, dataSource: self.$store.state.dataSource, fileName: self.videoPerformanceFileName })
            })
            .catch(() => {
              self.$store.dispatch('analytics/fetchCpmEngagementsInlineVideoCompletionRateMetaData', { id: self.$route.params.id, startDate: new Date(self.startDate).getTime(), endDate: new Date(self.endDate).getTime(), placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, dataSource: self.$store.state.dataSource, fileName: self.videoPerformanceFileName })
              self.$refs.completionRateIsLoading.innerHTML = 'Error'

              document.querySelector('#completionRateIsLoading0').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading1').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading2').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading3').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading4').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading5').innerHTML = 'Error'
            })
        })
        .catch(() => {
          self.$refs.quartilePerformanceIsLoading.innerHTML = 'Error'

          self.$store.dispatch('analytics/fetchCpmEngagementsInlineVideoCompletionRate', { id: self.$route.params.id, startDate: new Date(self.startDate).getTime(), endDate: new Date(self.endDate).getTime(), placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, dataSource: self.$store.state.dataSource, fileName: self.videoPerformanceFileName })
            .then(() => {
              self.completionRateIsLoading = false
              self.$store.dispatch('analytics/fetchCpmEngagementsInlineVideoCompletionRateMetaData', { id: self.$route.params.id, startDate: new Date(self.startDate).getTime(), endDate: new Date(self.endDate).getTime(), placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, dataSource: self.$store.state.dataSource, fileName: self.videoPerformanceFileName })
            })
            .catch(() => {
              self.$store.dispatch('analytics/fetchCpmEngagementsInlineVideoCompletionRateMetaData', { id: self.$route.params.id, startDate: new Date(self.startDate).getTime(), endDate: new Date(self.endDate).getTime(), placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, dataSource: self.$store.state.dataSource, fileName: self.videoPerformanceFileName })
              self.$refs.completionRateIsLoading.innerHTML = 'Error'

              document.querySelector('#completionRateIsLoading0').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading1').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading2').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading3').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading4').innerHTML = 'Error'
              document.querySelector('#completionRateIsLoading5').innerHTML = 'Error'
            })
        })
        .finally(() => {
          // set to show preloader
          self.$store.commit('UNSET_DO_NOT_USE_LOADER')
        })
    },
    getCpmAwareness: function (event) {
      this.getAwarenessImpressionViewabilityPlacementsPerformance()

      // this.getImpressionsData(this.impressionsDataSource);
      // this.getTopFivePlacements();
      // this.getPerformanceData();
      // // Get Viewability
      // this.getViewabilityData(this.viewabilityDataSource);
      // // Get bottom banner data fetchCpmAwarenessBottomBannerData
      // this.$store.dispatch('analytics/fetchCpmAwarenessBottomBannerData', {id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId});
      // // Get browser data fetchCpmAwarenessBrowserUsageData
      // this.$store.dispatch('analytics/fetchCpmAwarenessBrowserUsageData', {id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId});

      // this.getUnitEngagementsData(this.unitEngagementsDataSource);
      // this.getPlacementEffectivenessData();
      // this.getTopFivePlacementsData();
      // this.getCustomEventOccurrencesData(this.customEventOccurrencesDataSource);
      // this.$store.dispatch('analytics/fetchCpmEngagementsCustomEventOccurrencesMetaData', {id: this.$route.params.id, startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), placementId: this.placementId, productId: this.productId});
      // this.getVideoPerformanceData(this.videoPerformanceDataSource);
    },
    getEngagementsData: function () {
      const self = this
      if (this.initialLoad) {
        // set not to show preloader
        self.$store.commit('SET_DO_NOT_USE_LOADER')

        this.getUnitEngagementsData(this.unitEngagementsDataSource, this.initialLoad)
        // this.getPlacementEffectivenessData(this.placementEffectivenessDataSource);
        // this.getTopFivePlacementsData();
        // this.getCustomEventOccurrencesData(this.customEventOccurrencesDataSource);
        // this.getVideoPerformanceData();
      }
      this.initialLoad = false
    }
  },
  computed: {
    ...mapGetters('campaigns', {
      listCampaign: 'list',
      campaignById: 'itemById'
    }),
    ...mapGetters('analytics', {
      filterData: 'filterData',
      cpmAwarenessTopBannerData: 'cpmAwarenessTopBannerData',
      cpmAwarenessImpressionsData: 'cpmAwarenessImpressionsData',
      cpmAwarenessTopFivePlacementAds: 'cpmAwarenessTopFivePlacementAds',
      cpmAwarenessPerformanceDataByDay: 'cpmAwarenessPerformanceDataByDay',
      cpmAwarenessViewableImpressionsData: 'cpmAwarenessViewableImpressionsData',
      cpmAwarenessBottomBannerData: 'cpmAwarenessBottomBannerData',
      cpmAwarenessBrowserUsageData: 'cpmAwarenessBrowserUsageData',
      cpmEngagementsUnitEngagementsData: 'cpmEngagementsUnitEngagementsData',
      cpmEngagementsPlacementEffectivenessData: 'cpmEngagementsPlacementEffectivenessData',
      cpmEngagementsTopFivePlacementsData: 'cpmEngagementsTopFivePlacementsData',
      cpmEngagementsCustomEventOccurrencesData: 'cpmEngagementsCustomEventOccurrencesData',
      cpmEngagementsCustomEventOccurrencesMetaData: 'cpmEngagementsCustomEventOccurrencesMetaData',
      cpmEngagementsQuartilePerformance: 'cpmEngagementsQuartilePerformance',
      cpmEngagementsInlineVideoCompletionRate: 'cpmEngagementsInlineVideoCompletionRate',
      cpmEngagementsInlineVideoCompletionRateMetaData: 'cpmEngagementsInlineVideoCompletionRateMetaData'
    }),
    ...mapGetters([
      'creativeFormats'
    ]),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById'
    })
  },
  destroyed () {
    // set to show preloader
    this.$store.commit('UNSET_DO_NOT_USE_LOADER')
  },
  created () {
    const self = this

    self.fetchCampaignById(self.$route.params.id)
      .then(response => {
        self.campaign = response
        self.fallbackImages = self.campaign.fallbackImages
        self.$log.info(self.campaign)

        fetchFilterTopBottomImpressionPlacementPerformanceViewableBrowser()
      })
      .catch(err => {
        self.$log.error(err)
        fetchFilterTopBottomImpressionPlacementPerformanceViewableBrowser()
      })

    function fetchFilterTopBottomImpressionPlacementPerformanceViewableBrowser () {
      // set not to show preloader
      self.$store.commit('SET_DO_NOT_USE_LOADER')

      self.fetchFilterData({ id: self.$route.params.id })
        .then(response2 => {
          self.$log.info(response2)
          self.viewFilterData = response2
          fetchTopBottomImpressionPlacementPerformanceViewableBrowser()
        })
        .catch(err => {
          self.$log.error(err)
          fetchTopBottomImpressionPlacementPerformanceViewableBrowser()
        })
    }

    function fetchTopBottomImpressionPlacementPerformanceViewableBrowser () {
      self.fetchCpmAwarenessTopBannerData({ id: self.$route.params.id })
        .then(response3 => {
          self.$log.info(response3)
          self.topBannerData = response3
          self.topBannerIsLoading = false

          fetchImpressionPlacementPerformanceViewableBrowser()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.topBannerIsLoading.innerHTML = 'Error'
          fetchImpressionPlacementPerformanceViewableBrowser()
        })
    }

    function fetchImpressionPlacementPerformanceViewableBrowser () {
      self.fetchCpmAwarenessImpressionsData({ id: self.$route.params.id, startDate: self.startDate, endDate: self.endDate, placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, dataSource: self.impressionsDataSource })
        .then(response5 => {
          self.$log.info(response5)
          self.impressions = response5

          self.impressionsIsLoading = false

          fetchPlacementPerformanceViewableBrowser()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.impressionsIsLoading.innerHTML = 'Error'
          fetchPlacementPerformanceViewableBrowser()
        })
    }

    function fetchPlacementPerformanceViewableBrowser () {
      self.fetchCpmAwarenessTopFivePlacementAdsData({ id: self.$route.params.id, startDate: self.startDate, endDate: self.endDate, placementId: self.placementId, creativeId: self.creativeId, productId: self.productId, sortBy: self.topFiveFilter })
        .then(response6 => {
          self.$log.info(response6)
          self.topFivePlacementAds = response6

          self.top5IsLoading = false

          fetchPerformanceViewableBrowser()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.top5IsLoading.innerHTML = 'Error'
          fetchPerformanceViewableBrowser()
        })
    }

    function fetchPerformanceViewableBrowser () {
      self.fetchCpmAwarenessPerformanceDataByDayData({ id: self.$route.params.id, startDate: self.startDate, endDate: self.endDate, placementId: self.placementId, productId: self.productId, creativeId: self.creativeId, sortBy: self.performanceDataFilter })
        .then(response7 => {
          self.$log.info(response7)
          self.performanceDataByDay = response7

          self.performanceIsLoading = false

          fetchViewableBrowser()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.performanceIsLoading.innerHTML = 'Error'
          fetchViewableBrowser()
        })
    }

    function fetchViewableBrowser () {
      self.fetchCpmAwarenessViewableImpressionsData({ id: self.$route.params.id, startDate: self.startDate, endDate: self.endDate, placementId: self.placementId, productId: self.productId, dataSource: self.viewabilityDataSource, creativeId: self.creativeId })
        .then(response8 => {
          self.$log.info(response8)
          self.viewability = response8

          self.viewabilityIsLoading = false

          fetchBrowser()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.viewabilityIsLoading.innerHTML = 'Error'
          fetchBrowser()
        })
    }

    function fetchBrowser () {
      self.fetchCpmAwarenessBrowserUsageData({ id: self.$route.params.id, startDate: self.startDate, endDate: self.endDate, placementId: self.placementId, productId: self.productId, creativeId: self.creativeId })
        .then(response9 => {
          self.$log.info(response9)
          self.browserUsage = response9

          self.browserIsLoading = false

          fetchBottomImpressionPlacementPerformanceViewableBrowser()
        })
        .catch(err => {
          self.$log.error(err)
          self.$refs.browserIsLoading.innerHTML = 'Error'
          fetchBottomImpressionPlacementPerformanceViewableBrowser()
        })
    }

    function fetchBottomImpressionPlacementPerformanceViewableBrowser () {
      self.fetchCpmAwarenessBottomBannerData({ id: self.$route.params.id, startDate: self.startDate, endDate: self.endDate, placementId: self.placementId, productId: self.productId, creativeId: self.creativeId })
        .then(response4 => {
          self.$log.info(response4)
          self.bottomBannerData = response4

          self.bottomBannerIsLoading = false
        })
        .catch(err => {
          self.$log.error(err)
          // self.$refs.bottomBannerIsLoading0.innerHTML = 'Error';
          // self.$refs.bottomBannerIsLoading1.innerHTML = 'Error';
          // self.$refs.bottomBannerIsLoading2.innerHTML = 'Error';

          document.querySelector('#bottomBannerIsLoading0').innerHTML = 'Error'
          document.querySelector('#bottomBannerIsLoading1').innerHTML = 'Error'
          document.querySelector('#bottomBannerIsLoading2').innerHTML = 'Error'
        })
        .finally(() => {
          // set to show preloader
          self.$store.commit('UNSET_DO_NOT_USE_LOADER')
        })
    }

    // self.fetchCpmAwarenessImpressionsData(this.$route.params.id, this.startDate, this.endDate, this.placement, this.productName, this.dataSource)

    // self.fetchCpmEngagementsUnitEngagementsData({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId, dataSource: this.unitEngagementsDataSource})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.unitEngagements = response;
    //     });
    //
    // self.fetchCpmEngagementsPlacementEffectivenessData({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId,  sortBy: this.placmentEffectivenessFilter, dataSource: this.placementEffectivenessDataSource})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.placementEffectiveness = response;
    //     });
    //
    // self.fetchCpmEngagementsTopFivePlacementsData({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId, sortBy: this.topFivePlacementsFilter})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.topFivePlacements = response;
    //     });
    //
    // self.fetchCpmEngagementsCustomEventOccurrencesData({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId, dataSource: this.customEventOccurrencesDataSource})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.customEventOccurrences = response;
    //     });
    //
    // self.fetchCpmEngagementsCustomEventOccurrencesMetaData({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.customEventOccurrencesMetaData = response;
    //     });
    //
    // self.fetchCpmEngagementsQuartilePerformance({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId, dataSource: this.videoPerformanceDataSource})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.quartilePerformance = response;
    //     });
    //
    // self.fetchCpmEngagementsInlineVideoCompletionRate({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId, dataSource: this.videoPerformanceDataSource})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.inlineVideoCompletionRate = response;
    //     });
    //
    // self.fetchCpmEngagementsInlineVideoCompletionRateMetaData({id: this.$route.params.id, startDate: this.startDate, endDate: this.endDate, placementId: this.placementId, productId: this.productId, dataSource: this.videoPerformanceDataSource})
    //     .then(response => {
    //         self.$log.info(response);
    //         self.inlineVideoCompletionRateMetaData = response;
    //     });
    // self.fetchPerformanceAwareness(this.$route.params.id)
    // .then(response => {
    //   self.$log.info(response);
    // });
    //
    // self.fetchPerformanceEngagements(this.$route.params.id)
    // .then(response => {
    //   self.$log.info(response);
    // });
  },
  watch: {
    listCampaign: function () {
      this.campaign = this.campaignById(this.$route.params.id)
    },
    cpmAwarenessImpressionsData: function (val) {
      this.impressions = val
      this.impressionsData = this.chartsLib.visualization.arrayToDataTable(this.impressions.chartData)
      this.impressionsColumns = []
      this.impressionsSeries = {}
      for (let i = 0; i < this.impressionsData.getNumberOfColumns(); i++) {
        this.impressionsColumns.push(i)
        if (i > 0) {
          this.impressionsSeries[i - 1] = {}
        }
      }
      this.impressionsChartOptions.series = this.impressionsSeries
      this.impressionsChartOptions.series[1].targetAxisIndex = 0
      if (this.impressionsDataSource == '3P Verification' || this.impressionsDataSource == '3P Measurements') {
        this.impressionsChartOptions.vAxes[1] = null
        this.impressionsChartOptions.series = {}
      } else if (this.impressionsDataSource == 'All') {
        this.impressionsChartOptions.series[this.impressions.chartData[0].length - 2] = { targetAxisIndex: 1 }
        this.impressionsChartOptions.series[this.impressions.chartData[0].length - 3] = { targetAxisIndex: 1 }
      } else {
        this.impressionsChartOptions.vAxes[1] = {
          gridlines: { color: 'transparent' },
          format: '#%'
        }
        this.impressionsChartOptions.series[this.impressions.chartData[0].length - 2] = { targetAxisIndex: 1 }
      }
    },
    cpmAwarenessTopFivePlacementAds: function (val) {
      this.topFivePlacementAds = val
    },
    cpmAwarenessPerformanceDataByDay: function (val) {
      this.performanceDataByDay = val
    },
    cpmAwarenessViewableImpressionsData: function (val) {
      this.viewability = val
    },
    cpmAwarenessBottomBannerData: function (val) {
      this.bottomBannerData = val
    },
    cpmAwarenessBrowserUsageData: function (val) {
      this.browserUsage = val
    },
    cpmEngagementsUnitEngagementsData: function (val) {
      this.unitEngagements = val
      this.unitEngagementsData = this.chartsLib.visualization.arrayToDataTable(this.unitEngagements.chartData)
      this.unitEngagementsColumns = []
      this.unitEngagementsSeries = {}
      for (let i = 0; i < this.unitEngagementsData.getNumberOfColumns(); i++) {
        this.unitEngagementsColumns.push(i)
        if (i > 0) {
          this.unitEngagementsSeries[i - 1] = {}
        }
      }
      this.unitEngagementsChartOptions.series[this.unitEngagements.chartData[0].length - 2] = { targetAxisIndex: 1 }
    },
    cpmEngagementsPlacementEffectivenessData: function (val) {
      this.placementEffectiveness = val
    },
    cpmEngagementsTopFivePlacementsData: function (val) {
      this.topFivePlacements = val
    },
    cpmEngagementsCustomEventOccurrencesData: function (val) {
      this.customEventOccurrences = val
    },
    cpmEngagementsCustomEventOccurrencesMetaData: function (val) {
      this.customEventOccurrencesMetaData = val
    },
    cpmEngagementsQuartilePerformance: function (val) {
      this.quartilePerformance = val
    },
    cpmEngagementsInlineVideoCompletionRate: function (val) {
      this.inlineVideoCompletionRate = val
    },
    cpmEngagementsInlineVideoCompletionRateMetaData: function (val) {
      this.inlineVideoCompletionRateMetaData = val
    },
    performance_awareness: function () {
      // do something with this.performance_awareness
    },
    performance_engagements: function () {
      // do something with this.performance_engagements
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/common.scss";

  .card-border {
      box-shadow: -5px 5px 20px #0000002B;
      border-radius: 10px;
  }

  .nav-button-selected {
      height: 42px;
      width: 192px;
      background-color: #2F9B8C;
      color: #ffffff;
      text-transform: uppercase;
      font: Bold 14px/22px Montserrat;
      border: none;
      cursor: pointer;
  }

  .nav-button {
      height: 42px;
      width: 192px;
      background-color: #EFEFEF;
      color: #5C5C5C;
      text-transform: uppercase;
      font: Bold 14px/22px Montserrat;
      border: none;
      cursor: pointer;
  }

  .apply-button {
      height: 31px;
      width: 150px;
      background-color: $green4;
      color: #ffffff;
      text-transform: capitalize;
      font: Bold 18px/22px Montserrat;
      border: none;
      cursor: pointer;

      border-radius: 4px;
      border:0px;
      font-size: 1.2rem;
      background: $green4;
      text-transform: uppercase;
  }

  .backgroundDarker{
    background: $gray2 !important;
  }

    .custom-select {
        width: 175px;
        height: 31px;
    }

    .vdp-datepicker {
        width: 206px;
        height: 31px;
    }

    .vdp-datepicker input {
        width: 206px;
        height: 31px;
        padding-left: 10px;
    }

    .first-column {
        background-color: #2F9B8C;
        color: #ffffff;
    }

    .other-column {
        background-color: #ebebeb;
    }

    th.first-column {
        // border: none !important;
        border-color: $green3 !important;
        width: 62px !important;
    }

    .table th, .table td {
        padding: 1.4rem 0.75rem !important;
    }

    tbody tr:nth-child(2n) td{
        background: $gray1;
    }

    .calendar-button-icon {
        cursor: pointer;
        color: #888888;
        font-size: 13px;
    }

    .video-performance-heading {
        text-align: left;
        font: Bold 16px/19px Montserrat;
        letter-spacing: 0;
        color: #5C5C5C;
        text-transform: uppercase;
    }

    .title[data-v-7a60cfff] {
        font-size: 11px;
        color: $gray6;
        margin-left: 0;
    }

    .subValue[data-v-7a60cfff] {
        font-size: 11px;
        color: #8a8a8a;
        font-weight: normal;
    }

    .value[data-v-7a60cfff]{
        display: block !important;
        font-size: 40px;
    }

    .data-labels[data-v-8882235c]{
        // text-align: right;
        // width: 100%;
        color: $green4;
        // padding-right: 0;
        text-transform: uppercase;
        font-size: 11px !important;
    }

    .col-3 select, .col-2 select{
        height: 30px;
    }

    .card-border *{
        font-family: Montserrat !important;
    }

    .tooltip .tooltiptext{
        font-size: 12px !important;
        font-weight: normal !important;
    }

</style>
