<template>
  <div
    class="appFrame"
  >
    <Navbar
      :currentModulePath="'creative-studio'"
      :currentModuleName="'Creative Studio'"
    />

    <main class="moduleFrame moduleFrame--creativeRepository">
      <PencilBar />

      <ModuleHeader>
        <ul slot="tabs">
          <li selected>
            <router-link to="/creative-studio/creatives">Creatives</router-link>
          </li>
          <li><router-link to="/creative-studio/lists">Lists</router-link></li>
        </ul>

        <div slot="title">Creatives</div>

        <div slot="actions">
          <button
            class="moduleHeader__createButton"
            @click.prevent="openNewCreative"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            /> New Creative
          </button>
        </div>
      </ModuleHeader>

      <ModuleFilter>
        <div slot="searchField">
          <FormField
            label="Search Creatives"
            forId="filter-search"
            type="search"
            class="moduleFilter__filter"
          >
            <input
              v-model="searchInput"
              id="filter-search"
              type="text"
              required
              @input="debounceSearch"
            />
          </FormField>
        </div>

        <div slot="collapsableFilters">
          <FormField
            v-for="[fieldRef, field] in Object.entries(filterOptions)"
            :key="fieldRef"
            :label="field.label"
            :forId="`filter-${fieldRef}`"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              v-model="filterValues[fieldRef]"
              :id="`filter-${fieldRef}`"
              @change="fetchShowcaseCreatives(fetchShowcaseCreativesQueryString)"
            >
              <option
                v-for="option in filterOptions[fieldRef].options"
                :key="option.id"
                :value="option"
              >{{ option.name }}</option>
            </select>
            <div
              v-if="filterValues[fieldRef].id !==
                filterDefaults[fieldRef].id
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="() => {
                filterValues[fieldRef] = filterDefaults[fieldRef]
                fetchShowcaseCreatives(fetchShowcaseCreativesQueryString)
              }"
            >
              [X] CLEAR
            </div>
          </FormField>
        </div>
      </ModuleFilter>

      <section class="moduleContent">
        <div class="contentTableFrame">
          <ContentTableTools>
            <FormField
              slot="left"
              forId="tools-orderby"
              type="select"
              tagLeft="Order By:"
            >
              <select
                v-model="tableDataOptions.orderBy"
                id="tools-orderby"
                @change="fetchShowcaseCreatives(fetchShowcaseCreativesQueryString)"
              >
                <option
                  v-for="(orderByOption, index) in tableDataOptions.options"
                  :key="index"
                  :value="orderByOption.value"
                >{{ orderByOption.label }}</option>
              </select>
            </FormField>

            <FormField
              slot="left"
              forId="tools-order"
              type="select"
              tagLeft="Order:"
            >
              <select
                v-model="tableDataOptions.order"
                id="tools-order"
                @change="fetchShowcaseCreatives(fetchShowcaseCreativesQueryString)"
              >
                <option value="ASC">Ascending</option>
                <option value="DESC">Descending</option>
              </select>
            </FormField>

            <FormField
              slot="left"
              forId="tools-actions"
              type="select"
              tagLeft="Actions:"
            >
              <select
                id="tools-actions"
                v-on:change="actionsHandler"
              >
                <option value="none">Select an Action</option>
                <option
                  value="AddToList"
                  :disabled="!validCreativesSelected.length"
                >Add Creatives To List</option>
              </select>
            </FormField>

            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Creatives: {{ creativesPagination.total }}
            </div>
            <b-pagination
              slot="right"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="creativesPagination.total"
              v-model="page"
              :per-page="pageSize"
              @input="fetchShowcaseCreatives(fetchShowcaseCreativesQueryString)"
            ></b-pagination>
          </ContentTableTools>

          <div
            class="contentTableWrapper"
            :class="{ contextualMenuExpanded: contextualMenuExpanded }"
          >
            <table class="contentTable">
              <thead>
                <tr>
                  <th class="contentTable__dimension">
                    <input
                      type="checkbox"
                      @change="selectAllToggle($event)"
                    />
                    Creative Name
                  </th>
                  <th>Creative Type</th>
                  <th>Format</th>
                  <th>Campaign Start</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in showcaseCreatives"
                  v-bind:key="item.id"
                >
                  <td class="contentTable__dimension">
                    <input
                      type="checkbox"
                      :name="'creative_' + item.name"
                      v-model="creativesSelected[item.id]"
                    />
                    <a
                    class="overflow-ellipsis"
                    :title="item.name"
                    href="#"
                    @click.prevent="viewCreative(item)"
                  >
                    {{ item.name }}
                    <span
                      v-if="checkCreativeIsLive(item)"
                      class="live-badge"
                    ></span>
                    <font-awesome-icon
                      v-if="item.supports_mobile === 1"
                      icon="mobile-alt"
                      class="i icon-name"
                    />
                    <font-awesome-icon
                      v-if="item.supports_desktop === 1"
                      icon="desktop"
                      class="i icon-name"
                    /><font-awesome-icon
                      v-if="item.demoTag"
                      icon="ad"
                      class="i icon-name"
                    /></a>
                  </td>
                  <td column-name="Type">
                    <a
                      href="#"
                      v-on:click.prevent="
                        filterValues.creativeType = item.type
                      "
                    >{{ item.type }}</a>
                  </td>
                  <td column-name="Format">
                    <a
                      href="#"
                      v-on:click.prevent="
                        filterValues.format = item.format
                      "
                    >{{ item.format }}</a>
                  </td>
                  <td column-name="CampaignStart">
                    <a
                      href="#"
                      v-on:click.prevent="
                        '';

                      "
                    >{{ getFormattedDate(item.campaignStartDate) }}</a>
                  </td>
                  <td class="contentTable__actions">
                    <ActionsDropdown
                      :dropup="showcaseCreatives.length > 10 &&
                        !(index < showcaseCreatives.length - 2)
                        "
                      v-bind:itemId="item.id"
                      v-bind:actions="tableActions"
                      @show="contextualMenuExpanded = true"
                      @hide="contextualMenuExpanded = false"
                    />
                  </td>
                </tr>

                <tr v-if="showcaseCreatives.length < 1">
                  <td colspan="9">No creative matches your criteria</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ContentTableTools>
            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Creatives: {{ creativesPagination.total }}
            </div>
            <b-pagination
              slot="right"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="creativesPagination.total"
              v-model="page"
              :per-page="pageSize"
              @input="fetchShowcaseCreatives(fetchShowcaseCreativesQueryString)"
            ></b-pagination>
          </ContentTableTools>
        </div>
      </section>

      <ModuleFooter>
        <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
        <WebLinks slot="right" />
      </ModuleFooter>
    </main>

    <!-- Modal Component -->
    <b-modal
      :static="staticBModal"
      ref="moduleModal"
      title=""
      @hidden="modalData.currentComponent = null"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <component
        :is="modalData.currentComponent"
        :itemId="modalData.itemId"
        :advertiserId="modalData.advertiserId"
        :advertiserName="modalData.advertiserName"
        @done="hideModal(true)"
        @cancel="hideModal(false)"
      />
    </b-modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import CreativeProfileModal from '@/views/modals/RepoCreativeProfile.vue'
import CreativeAddToListModal from '@/views/modals/RepoCreativeAddToList.vue'
import CreativesAddToListMultipleModal from '@/views/modals/RepoCreativeAddToListMultiple.vue'
import WebLinks from '@/components/WebLinks.vue'
import Api from '@/helpers/Api.js'
import { debounce, sortBy } from 'lodash'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'

export default {
  name: 'CreativeRepository',
  components: {
    Navbar,
    FormField,
    ModuleHeader,
    ModuleFilter,
    PencilBar,
    ModuleFooter,
    ActionsDropdown,
    ContentTableTools,
    WebLinks,
    CreativeProfileModal,
    CreativeAddToListModal,
    CreativesAddToListMultipleModal
  },
  data () {
    return {
      staticBModal: true,
      mobileFiltersExpanded: false,
      searchInput: '',
      isAllShowcases: true,
      filterValues: {},
      filterDefaults: {
        creativeType: { 'id': -1, 'name': 'All creatives types' },
        advertiser: { 'id': -1, 'name': 'All advertisers' },
        vertical: { 'id': -1, 'name': 'All verticals' },
        format: { 'id': -1, 'name': 'All formats' },
        dealType: { 'id': -1, 'name': 'All Deal types' },
        mainKPI: { 'id': -1, 'name': 'All KPI' },
        showcaseType: { 'id': -1, 'name': 'All Showcase Types' },
        supportedDevice: { 'id': -1, 'name': 'All Devices' },
        liveCreatives: { 'id': -1, 'name': 'All Creatives' },
        tagType: { 'id': -1, 'name': 'All Video Tags' }
      },
      tableDataOptions: {
        orderBy: 'updated_on',
        order: 'DESC',
        options: [
          { label: 'Last Updated', value: 'updated_on' },
          { label: 'Name', value: 'name' },
          { label: 'Creative Type', value: 'type_id' },
          { label: 'Advertiser', value: 'advertiser' },
          { label: 'Vertical', value: 'vertical_id' },
          { label: 'Format', value: 'format_id' },
          { label: 'Deal Type', value: 'deal_id' },
          { label: 'Campaign Start', value: 'campaignStartDate' },
          { label: 'Campaign End', value: 'campaignEndDate' }
        ]
      },
      breadcrumbsData: [
        {
          text: 'Creative Studio',
          href: '/creative-studio'
        },
        {
          text: 'Creatives',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null
      },
      data: [],
      creativesSelected: {},
      pageSize: 30,
      page: 1,
      tableActions: [
        {
          ico: 'cog',
          text: 'Creative Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openCreativeSettings
        },
        {
          ico: 'link',
          text: 'Copy Preview Link',
          clickAction: this.copyCreativePreviewUrlToClipboard
        },
        {
          ico: 'link',
          text: 'Copy Preview Link (Extended)',
          clickAction: this.copyCreativePreviewExtendedUrlToClipboard
        },
        {
          ico: 'list',
          text: 'Add to List',
          clickAction: this.addCreativeToList
        },
        {
          ico: 'trash-alt',
          text: 'Delete Creative',
          clickAction: this.deleteCreative
        }
      ],
      contextualMenuExpanded: false,
      selectAll: false
    }
  },
  methods: {
    ...mapActions([
      'fetchShowcaseCreatives',
      'deleteShowcaseCreative',
      'registerModalOpened',
      'registerModalClosed'
    ]),
    checkCreativeIsLive: function (creative) {
      const start = this.$moment.utc(creative.campaignStartDate)
      const end = this.$moment.utc(creative.campaignEndDate).endOf('day')
      const now = this.$moment.utc()
      // console.log('Original startDate:', startDate);
      // console.log('Original endDate:', endDate);
      // console.log('Moment start:', start.format('YYYY-MM-DD HH:mm:ss'));
      // console.log('Moment end:', end.format('YYYY-MM-DD HH:mm:ss'));
      // console.log('Moment now:', now.format('YYYY-MM-DD HH:mm:ss'));
      return now.isSameOrAfter(start) && now.isBefore(end) ? 1 : 0
    },
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':
          self.addMultipleCreativesToList()
          break

        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    openCreativeSettings: function (creativeId) {
      const self = this

      // set the modal item id to the userId we want to update
      self.modalData.itemId = creativeId

      // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCreative
      const _creative = self.showcaseCreativeById(creativeId)
      const _advertiser =
        _creative.advertiser_id === null
          ? self.advertiserByName(_creative.advertiser)
          : self.advertiserById(_creative.advertiser_id)

      // self.$log.info(_advertiser, _creative.advertiser)

      if (_advertiser !== undefined) {
        self.modalData.advertiserId = _advertiser.id
        self.modalData.advertiserName = _advertiser.name
      } else {
        self.modalData.advertiserId = _creative.advertiser_id
        self.modalData.advertiserName = _creative.advertiser
      }

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },
    copyCreativePreviewUrlToClipboard: function (creativeId) {
      const self = this
      Utils.copyToClipboard('/c/' + creativeId)
    },
    copyCreativePreviewExtendedUrlToClipboard: function (creativeId) {
      const self = this
      Utils.copyToClipboard('/ce/' + creativeId)
    },
    addCreativeToList: function (creativeId) {
      const self = this

      // set the modal item id to the userId we want to update
      self.modalData.itemId = creativeId

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeAddToListModal

      // show modal
      self.$refs.moduleModal.show()
    },
    addMultipleCreativesToList: function (creatives) {
      const self = this

      // set the modal item id to the userId we want to update
      self.modalData.itemId = self.validCreativesSelected

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativesAddToListMultipleModal

      // show modal
      self.$refs.moduleModal.show()
    },
    deleteCreative: function (creativeId) {
      const self = this

      // fet user from users.
      const creative = _.find(self.showcaseCreatives, { id: creativeId })

      // if the user we are trying to delete is not the logged in user...
      if (
        confirm(
          `Are you sure you want to delete this creative: ${creative.name}?`
        )
      ) {
        // delete creative
        self.deleteShowcaseCreative(creative).catch(error => {
          self.$log.error(error)
          alert(
            `There was an issue deleting this creative. Please try again. Error Code:${error}`
          )
        })
      }
    },
    openNewCreative: function () {
      const self = this

      // set Advertiser to null
      self.modalData.advertiserId = null
      self.modalData.advertiserName = null

      // set the modal item id to null to create new user
      self.modalData.itemId = null

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    hideModal: function (wasSuccessful) {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()

      if (wasSuccessful) self.fetchShowcaseCreatives(this.fetchShowcaseCreativesQueryString)
    },
    viewCreative: function (creative) {
      // open file url in a new window
      window.open('/c/' + creative.id, '_blank')
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self
        .$moment(date)
        .utc()
        .format('MMM DD, YYYY')

      return formattedDate != 'Invalid date' ? formattedDate : ''
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.showcaseCreatives.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.creativesSelected, String(item.id), true)
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.showcaseCreatives.map((item, key) => {
          this.$set(this.creativesSelected, String(item.id), false)
        })
      }
    },
    debounceSearch: debounce(function () {
      if (!this.isAllShowcases || this.searchInput.length >= 3) {
        this.isAllShowcases = this.searchInput.length < 3
        this.page = 1
        this.fetchShowcaseCreatives(this.fetchShowcaseCreativesQueryString)
      }
    }, 500)
  },
  computed: {
    ...mapGetters([
      'showcaseCreatives',
      'showcaseCreativeById',
      'creativesPagination',
      'verticals',
      'KPIs',
      'dealTypes',
      'creativeTypes',
      'creativeFormats',
      'Status'
    ]),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById',
      advertiserByName: 'itemByName',
      advertisersList: 'list'
    }),
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validCreativesSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.creativesSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    validItemsSelectedToObjects: function () {
      const data = []
      this.validCreativesSelected.map(item => {
        data.push({ id: parseInt(item) })
      })

      return data
    },
    filterOptions () {
      const options = {
        creativeType: {
          label: 'Creative Type',
          options: [...sortBy(Object.values(this.creativeTypes), ['name'])]
        },
        advertiser: {
          label: 'Advertiser',
          options: [...sortBy(Object.values(this.advertisersList), ['name'])]
        },
        vertical: {
          label: 'Vertical',
          options: [...sortBy(Object.values(this.verticals), ['name'])]
        },
        format: {
          label: 'Format',
          options: [...sortBy(Object.values(this.creativeFormats), ['name'])]
        },
        dealType: {
          label: 'Deal Type',
          options: [...sortBy(Object.values(this.dealTypes), ['name'])]
        },
        mainKPI: {
          label: 'Main KPI',
          options: [...sortBy(Object.values(this.KPIs), ['name'])]
        },
        showcaseType:{
          label: 'Showcase',
          options: [{ id: 0, name: 'Has Design' }, { id: 1, name: 'Has Demo' }]
        },
        supportedDevice: {
          label: 'Supported Device',
          options: [{ id: 0, name: 'Mobile Only' }, { id: 1, name: 'Desktop & Mobile' }]
        },
        liveCreatives: {
          label: 'Status',
          options: [...sortBy(Object.values(this.Status), ['name'])]
        },
        tagType: {
          label: 'Tag Type',
          options: [{ id: 0, name: 'Non-VAST'}, { id: 1, name: 'VAST' }]
        }
      }

      for (const [key, val] of Object.entries(options)) {
        options[key].options.unshift(this.filterDefaults[key])
      }
      return options
    },
    fetchShowcaseCreativesQueryString () {
      return Utils.serialize({
        search: this.searchInput.length >= 3 ? this.searchInput : '',
        page: this.page,
        pageSize: this.pageSize,
        creativeType: this.filterValues.creativeType.id < 0 ? '' : this.filterValues.creativeType.id,
        advertiser: this.filterValues.advertiser.id < 0 ? '' : this.filterValues.advertiser.name,
        vertical: this.filterValues.vertical.id < 0 ? '' : this.filterValues.vertical.id,
        format: this.filterValues.format.id < 0 ? '' : this.filterValues.format.id,
        dealType: this.filterValues.dealType.id < 0 ? '' : this.filterValues.dealType.id,
        mainKPI: this.filterValues.mainKPI.id < 0 ? '' : this.filterValues.mainKPI.id,
        showcaseType: this.filterValues.showcaseType.id < 0 ? '' : this.filterValues.showcaseType.id,
        supportedDevice: this.filterValues.supportedDevice.id < 0 ? '' : this.filterValues.supportedDevice.id,
        creativeStatus: this.filterValues.liveCreatives.id < 0 ? '' : this.filterValues.liveCreatives.id,
        videoTagType: this.filterValues.tagType.id < 0 ? '' : this.filterValues.tagType.id,
        orderBy: this.tableDataOptions.orderBy,
        order: this.tableDataOptions.order
      })
    }
  },
  created () {
    const self = this
    this.filterValues = {...this.filterDefaults} // init with default values
    self.fetchShowcaseCreatives(this.fetchShowcaseCreativesQueryString)
  }
}
</script>

<style>
.icon-name {
  margin: 0 5px;
}

.live-badge {
  display: inline-block;
  width: 1.5em;
  height: 0.82em;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='10' viewBox='0 0 100 40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='0' width='70' height='40' rx='10' fill='%2304AA6D'/%3E%3Ctext x='35' y='20' fill='%23fff' font-size='23' font-family='Arial, sans-serif' letter-spacing='0.7px' font-weight='500' text-anchor='middle' alignment-baseline='central'%3ELIVE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@keyframes blink {
  50% {
    opacity: 0.3;
  }
}
</style>
