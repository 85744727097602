<template>
  <div class="appFrame">
    <Navbar
      v-if="$store.getters.authenticated"
      :current-module-path="$route.path"
      :current-module-name="$route.name"
    />

    <main class="moduleFrame moduleFrame--accessManagement">
      <div
        class="loader"
        :class="{ done: !loading }"
      >
        <font-awesome-icon
          icon="spinner"
          class="spinner"
        />
      </div>

      <PencilBar />

      <ModuleHeader>
        <ul slot="tabs">
          <li selected>
            <router-link to="/access-management/">Users</router-link>
          </li>
          <!-- <li><router-link to="/access-management/accounts/">Accounts</router-link></li> -->
        </ul>

        <div slot="title">Users</div>

        <div slot="actions">
          <button
            class="moduleHeader__createButton"
            @click.prevent="openNewUser"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            />
            New User
          </button>
        </div>
      </ModuleHeader>

      <ModuleFilter>
        <div slot="searchField">
          <FormField
            label="Search Users"
            for-id="filter-search"
            type="search"
          >
            <input
              id="filter-search"
              v-model="filterModuleData.search"
              type="text"
              required
            />
          </FormField>
        </div>

        <div slot="collapsableFilters">
          <FormField
            label="Role"
            for-id="filter-role"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              id="filter-role"
              v-model="filterModuleData.filterValues.role_name"
            >
              <option
                v-for="(role_name, index) in filterModuleData.filterOptions
                  .role_name"
                :key="index"
              >
                {{ role_name }}
              </option>
            </select>
            <div
              v-if="
                filterModuleData.filterValues.role_name !==
                filterModuleData.filterDefaults.role_name
              "
              slot="custom-content"
              class="moduleFilter__filter__clear"
              @click="
                filterModuleData.filterValues.role_name =
                  filterModuleData.filterDefaults.role_name
              "
            >
              [X] CLEAR
            </div>
          </FormField>
        </div>
      </ModuleFilter>

      <section class="moduleContent">
        <div class="contentTableFrame">
          <ContentTableTools>
            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Users: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>

          <div
            class="contentTableWrapper"
            :class="{ contextualMenuExpanded: contextualMenuExpanded }"
          >
            <table class="contentTable">
              <thead>
                <tr>
                  <th class="contentTable__dimension">Full Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(user, index) in paginatedItems"
                  v-if="paginatedItems.length > 0"
                  :key="index"
                >
                  <td class="contentTable__dimension">
                    {{ user.name }} {{ user.last_name }}
                  </td>
                  <td column-name="Email">{{ user.email }}</td>
                  <td column-name="Role">{{ user.role_name }}</td>
                  <td class="contentTable__actions">
                    <button
                      @click.prevent="openUserSettings(user.aws_user_id, user)"
                    >
                      <font-awesome-icon
                        icon="cog"
                        class="i"
                      />
                    </button>
                    <ActionsDropdown
                      :item-id="user.aws_user_id"
                      :actions="tableActions"
                      @show="contextualMenuExpanded = true"
                      @hide="contextualMenuExpanded = false"
                    />
                  </td>
                </tr>

                <tr v-if="paginatedItems.length < 1">
                  <td colspan="5">No creative matches your criteria</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ContentTableTools>
            <div
              slot="right"
              class="contentTableFrame__total"
            >
              Total Users: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              v-model="pagination.currentPage"
              class="contentTableFrame__pagination"
              :total-rows="filteredItems.length"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>
        </div>
      </section>

      <ModuleFooter>
        <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
        <WebLinks slot="right" />
      </ModuleFooter>
    </main>

    <!-- Modal Component -->
    <b-modal
      ref="moduleModal"
      :static="staticBModal"
      title="Bootstrap-Vue"
      hide-footer
      hide-header
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hidden="modalData.currentComponent = null"
    >
      <component
        :is="modalData.currentComponent"
        :item-id="modalData.itemId"
        :user-data="modalData.userData"
        :logged-user="modalData.loggedUser"
        @save-new-success="modalSaveNew"
        @save-update-success="modalSaveUpdate"
        @cancel="hideModal"
      />
    </b-modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import WebLinks from '@/components/WebLinks.vue'
import UserProfileModal from '@/views/modals/UserProfile.vue'

import Api from '@/helpers/ApiUser.js'
import Utils from '@/helpers/Utils.js'

export default {
  name: 'AccessManagement',
  components: {
    Navbar,
    FormField,
    ModuleHeader,
    ModuleFilter,
    PencilBar,
    ModuleFooter,
    ActionsDropdown,
    WebLinks,
    ContentTableTools,
    UserProfileModal
  },
  data() {
    return {
      staticBModal: true,
      loading: true,
      filterModuleData: {
        search: '',
        filterValues: {
          role_name: 'All roles'
        },
        filterDefaults: {
          role_name: 'All roles'
        },
        filterOptions: {
          role_name: []
        }
      },
      breadcrumsData: [
        {
          text: 'Access Management',
          href: '/access-management'
        },
        {
          text: 'Users',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        userData: null,
        loggedUser: null
      },
      data: [],
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'User Settings',
          clickAction: this.openUserSettings
        },
        {
          ico: 'trash-alt',
          text: 'Delete User',
          clickAction: this.deleteUser
        }
      ],
      contextualMenuExpanded: false
    }
  },
  computed: {
    filteredItems: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        self.data,
        self.filterModuleData.search
      )

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(
        self.filterModuleData.filterValues,
        (filter, key) => {
          // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
          const filterOption = _.get(
            self.filterModuleData.filterOptions,
            '[' + key + '][0]',
            false
          )

          // if filterOption is not false and the filter option is not the first option
          return filterOption && filter != filterOption
        }
      )

      // filter data
      const filteredItems = Utils.getFilteredItems(
        searchedItems,
        propsToFilterBy
      )

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(
        self.filteredItems,
        self.pagination.currentPage,
        self.pagination.perPage
      )
    }
  },
  watch: {
    data: function () {
      const self = this

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.data, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, (param) => {
        filterOptions[param].unshift(
          self.filterModuleData.filterDefaults[param]
        )
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    }
  },
  created() {
    const self = this

    self.populate()
  },
  methods: {
    openUserSettings: function (userId, userData) {
      const self = this

      // set the modal item id to the userId we want to update
      self.modalData.itemId = userId

      // set the modal item id to the userData we want to update
      self.modalData.userData = userData

      // set the modal logged user to the loggedUser
      self.modalData.loggedUser = JSON.parse(localStorage._sUser)

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = UserProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },
    deleteUser: function (userId) {
      const self = this

      // fet user from users.
      const user = _.find(self.data, { aws_user_id: userId })

      // if the user we are trying to delete is not the logged in user...
      if (user.id != JSON.parse(localStorage._sUser).id) {
        this.$swal({
          position: 'top',
          title: `Are you sure you want to delete this user: ${user.name} ${user.last_name}?`,
          html: '',
          showDenyButton: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'YES',
          cancelButtonText: 'No',
          allowOutsideClick: false,
          icon: 'warning',
          toast: false
        }).then(async (result) => {
          if (result.isConfirmed) {
            Api.deleteUser(user.id)
              .then(self.populate)
              .catch((error) => {
                this.$log.error(error)
                this.$swal({
                  position: 'top',
                  title: `There was an issue deleting this user. Please try again. Error Code:${error}.`,
                  html: `Error Code:${error}`,
                  showDenyButton: false,
                  showConfirmButton: false,
                  showCancelButton: true,
                  confirmButtonText: 'YES',
                  cancelButtonText: 'Close',
                  allowOutsideClick: false,
                  icon: 'error',
                  toast: false
                })

              })
          }
        })
      } else {
        alert('You cannot delete your own user :)')
      }
    },
    openNewUser: function () {
      const self = this

      // set the modal item id to null to create new user
      self.modalData.itemId = null

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = UserProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },
    reInviteUser: function (data) {
      // turn on loading spinner
      self.loading = true

      Api.reInviteUser({ email: data })
        .then((response) => {
          console.log('response', response)
        })
        .catch((error) => {
          self.$log.error(error)
          alert(
            `There was an issue re inviting this user. Please try again. Error Code:${error}`
          )

          // turn off loading spinner
          self.loading = false
        })
    },
    modalSaveNew: function (userData) {
      const self = this

      // turn on loading spinner
      self.loading = true

      self.populate()

      // hide modal
      self.hideModal()
    },
    modalSaveUpdate: function (userData) {
      const self = this

      // turn on loading spinner
      self.loading = true

      self.populate()

      // hide modal
      self.hideModal()
    },
    hideModal: function () {
      const self = this

      // show modal
      self.$refs.moduleModal.hide()
    },
    populate: function () {
      const self = this

      Api.getUsersList() // pass true for dummy data
        .then((data) => {
          self.$log.info('users:', data)

          // store the data in userData
          self.data = data

          // turn off loading spinner
          self.loading = false

          return data
        })
        .catch((error) => {
          self.$log.error(error)
        })

      Api.gethealthcheck() // pass true for dummy data
        .then((data) => {
          console.log('data check', data)
        })
        .catch((error) => {
          self.$log.error(error)
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/common.scss';
</style>
