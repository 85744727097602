import _ from 'lodash'
import Api from '@/helpers/Api'
import ApiTagManager from '@/helpers/ApiTagManager'
import ApiCreativeProduction from '@/helpers/ApiCreativeProduction'

/** VUEX module for campaigns in Tag Manager section **/

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    campaigns: [],
    campaign: null
  },
  // -----------------------------------------------------------------
  getters: {
    list: (state, getters, rootState, rootGetters) => {
      const includingMappedData = state.campaigns.map((item) => {
        const itemRef = _.cloneDeep(item)

        // map advertiser into the campaign
        itemRef.advertiser = _.get(
          rootGetters['advertisers/list'],
          `[${itemRef.advertiser_id}].name`,
          ''
        )
        itemRef.kpi = _.get(rootGetters.KPIs, `[${itemRef.kpi_id}].name`, {})
        itemRef.deal_type = _.get(
          rootGetters.dealTypes,
          `[${itemRef.deal_type_id}].name`,
          {}
        )
        itemRef.touched_on =
          itemRef.modified_date != '' &&
          itemRef.modified_date != '0000-00-00 00:00:00' &&
          itemRef.modified_date != null
            ? itemRef.modified_date
            : itemRef.created_date

        return itemRef
      })

      return includingMappedData
    },
    listAirtory: (state, getters) => {
      let _list = _.cloneDeep(getters.list)
      _list = _list.filter((item) => item.campaign_source === 'Airtory Sync')
      return _list
    },
    listPadSquad: (state, getters) => {
      let _list = _.cloneDeep(getters.list)
      _list = _list.filter((item) => item.campaign_source === 'PadSquad')
      return _list
    },
    listAirtoryMeasuremet: (state, getters) => {
      // list campaign report builder

      const includingMappedData = state.campaigns.map((itemRef) => {
        // map created date, modified date into the campaign
        itemRef.touched_on =
          itemRef.modified_date != '' &&
          itemRef.modified_date != '0000-00-00 00:00:00' &&
          itemRef.modified_date != null
            ? itemRef.modified_date
            : itemRef.created_date
        return itemRef
      })

      // filtering campaign list by source, then picking the keys we need only
      const _list = _.map(
        _.clone(
          includingMappedData.filter(
            (item) =>
              item.campaign_source === 'Airtory Sync' ||
              item.campaign_source === 'Celtra Sync' ||
              item.campaign_source === 'Manual' ||
              item.measurement_partner_id === 1
          )
        ),
        _.partialRight(_.pick, [
          'id',
          'name',
          'campaign_source',
          'measurement_partner_id',
          'created_date',
          'start_date',
          'touched_on',
          'modified_date'
        ])
      )

      return _list
    },
    listPadSquadMeasuremet: (state, getters) => {
      // list campaign report builder

      const includingMappedData = state.campaigns.map((itemRef) => {
        // map created date, modified date into the campaign
        itemRef.touched_on =
          itemRef.modified_date != '' &&
          itemRef.modified_date != '0000-00-00 00:00:00' &&
          itemRef.modified_date != null
            ? itemRef.modified_date
            : itemRef.created_date
        return itemRef
      })

      // filtering campaign list by source, then picking the keys we need only
      const _list = _.map(
        _.clone(
          includingMappedData.filter(
            (item) =>
              item.campaign_source === 'PadSquad'
          )
        ),
        _.partialRight(_.pick, [
          'id',
          'name',
          'campaign_source',
          'measurement_partner_id',
          'created_date',
          'start_date',
          'touched_on',
          'modified_date'
        ])
      )

      return _list
    },
    itemById: (state, getters) => (id) => {
      const item = getters.list.find((item) => item.id == id)

      return item
    },
    selectedCampaign: (state) => {
      return state.campaign
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET: (state, payload) => {
      state.campaigns = payload
      return payload
    },
    SET_BY_ID: (state, payload) => {
      state.campaign = payload
    },
    CREATE: (state, payload) => {
      // payload.active = true;
      state.campaigns.push(payload)
    },
    UPDATE: (state, payload) => {
      const item = state.campaigns.find((item) => item.id === payload.id)
      if (item) Object.assign(item, payload)
      else state.campaigns.push(payload)
    },
    ADD_CREATIVE_BY_CAMPAIGN: (state, payload) => {
      const campaign = state.campaigns.find(
        (item) => item.external_campaign_id === payload.external_campaign_id
      )
      campaign.creatives.push(payload.creative)
    },
    UPDATE_CREATIVE_BY_CAMPAIGN: (state, payload) => {
      const campaign = state.campaigns.find(
        (item) => item.external_campaign_id === payload.external_campaign_id
      )

      if (!campaign || !campaign.creatives) {
        return
      }

      const creative = _.find(campaign.creatives, { id: payload.creative.id })

      if (!creative) {
        return
      }

      _.assign(creative, payload.creative)
    },
    ADD_PLACEMENT_BY_CAMPAIGN: (state, payload) => {
      const campaign = state.campaigns.find(
        (item) => item.id === payload.campaign_id
      )
      campaign.placements.push(payload.placement)
    },
    DELETE: (state, id) => {
      const index = _.findIndex(state.campaigns, {
        id
      })

      state.campaigns.splice(index, 1)
    },
    REPLACE: (state, payload) => {
      if (state.campaigns.length == 0) {
        state.campaigns.push(payload)
      } else {
        const index = _.findIndex(state.campaigns, {
          id: payload.id
        })

        state.campaigns.splice(index, 1, payload)
      }
    },
    DELETE_CREATIVE_FROM_CAMPAIGN: (state, payload) => {
      const campaign = state.campaigns.find(
        (item) => item.id === payload.campaignId
      )

      const index = _.findIndex(campaign.creatives, {
        id: payload.creativeId
      })

      campaign.creatives.splice(index, 1)
    }
  },
  // -----------------------------------------------------------------
  actions: {
    fetch: (context) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCampaigns', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignsList()
          .then((data) => context.commit('SET', data))
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaigns', { root: true })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaigns', { root: true })

            reject(error)
          })
      })
    },
    fetchInactive: (context) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCampaignsInactive', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignsInactiveList()
          .then((data) => context.commit('SET', data))
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaignsInactive', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaignsInactive', {
              root: true
            })

            reject(error)
          })
      })
    },
    // delete a creative in state
    deleteCreativeFromCampaign: (context, data) => {
      context.commit('DELETE_CREATIVE_FROM_CAMPAIGN', data)
    },
    fetchById: (context, campaignId) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCampaign', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignById(campaignId)
          .then((data) => {
            context.commit('UPDATE', data)
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaign', { root: true })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaign', { root: true })

            reject(error)
          })
      })
    },
    fetchByIdNoPlacements: (context, campaignId) => {
      // app status: loading

      context.dispatch('registerTask', 'fetchNoPlacements', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignNoPlacements(campaignId)
          .then((data) => {
            context.commit('UPDATE', data)
            // app status: ready
            context.dispatch('completeTask', 'fetchNoPlacements', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchNoPlacements', {
              root: true
            })

            reject(error)
          })
      })
    },
    fetchByIdIncludeTrackers: (context, campaignId) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchByIdIncludeTrackers', {
        root: true
      })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignByIdIncludeTrackers(campaignId)
          .then((data) => {
            context.commit('UPDATE', data)
            // app status: ready
            context.dispatch('completeTask', 'fetchByIdIncludeTrackers', {
              root: true
            })

            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchByIdIncludeTrackers', {
              root: true
            })

            reject(error)
          })
      })
    },
    model: (context, campaignData) => {
      return new Promise((resolve, reject) => {
        try {
          const campaignDataModel = [
            'deal_type_id',
            'name',
            // "advertiser",
            'advertiser_id',
            'campaign_source',
            'kpi_id',
            // "kpi_goal",
            // "kpi2_id",
            // "kpi2_goal",
            'start_date',
            'end_date',
            // "verification_partner_id",
            // "measurement_partner_id",
            'account_id',
            'use_click_from_adserver',
            'active',
            'salesforce_id',
            'test_campaign'
          ]

          if (campaignData.kpi2_id) campaignDataModel.push('kpi2_id')
          if (campaignData.kpi_goal) campaignDataModel.push('kpi_goal')
          if (campaignData.kpi2_goal) campaignDataModel.push('kpi2_goal')
          if (campaignData.measurement_partner_id)
            campaignDataModel.push('measurement_partner_id')
          if (campaignData.verification_partner_id)
            campaignDataModel.push('verification_partner_id')

          // if this is an existent campaign...
          if (campaignData.id) {
            // ... add the id to the model
            campaignDataModel.push('id')
            campaignDataModel.push('external_campaign_id')
            /* if (campaignData.campaign_url)  */
            campaignDataModel.push('campaign_url')
            if (campaignData.use_click_from_adserver)
              campaignDataModel.push('use_click_from_adserver')
          } else {
            campaignDataModel.push('created_by')
          }

          if (
            campaignData.id === null &&
            campaignData.campaign_source === 'Celtra Sync'
          ) {
            campaignDataModel.push('external_campaign_id')
          }

          // if(campaignData.id !== null && campaignData.campaign_source === 'Airtory Sync'){
          if (campaignData.campaign_source === 'Airtory Sync') {
            campaignDataModel.push('advertiser')
          }

          // pick the values in the model from our campaignDataModel
          const campaignDataModeled = _.pick(campaignData, campaignDataModel)
          resolve(campaignDataModeled)
        } catch (error) {
          reject(error)
        }
      })
    },
    create: (context, campaignData) => {
      // app status: loading
      context.dispatch('registerTask', 'createCampaign', { root: true })

      return new Promise((resolve, reject) => {
        context
          .dispatch('model', campaignData)
          .then((campaignDataModeled) => {
            return campaignDataModeled
          })
          .then((campaignDataModeled) =>
            ApiTagManager.createCampaign(campaignDataModeled)
          )
          .then((CampaignDataFromServer) => {
            // app status: ready
            context.commit('CREATE', CampaignDataFromServer)
            // context.commit('CREATE', CampaignDataFromServer.data);
            context.dispatch('completeTask', 'createCampaign', { root: true })
            resolve(campaignData)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'createCampaign', { root: true })

            reject(error)
          })
      })
    },
    update: (context, campaignData) => {
      // app status: loading
      context.dispatch('registerTask', 'updateCampaign', { root: true })

      return new Promise((resolve, reject) => {
        context
          .dispatch('model', campaignData)
          .then((campaignDataModeled) => {
            return campaignDataModeled
          })
          .then((campaignDataModeled) =>
            ApiTagManager.updateCampaign(campaignDataModeled)
          )
          .then((CampaignDataFromServer) => {
            // app status: ready
            context.commit('UPDATE', CampaignDataFromServer)
            context.dispatch('completeTask', 'updateCampaign', { root: true })
            resolve(CampaignDataFromServer)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'updateCampaign', { root: true })

            reject(error)
          })
      })
    },
    read: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'readCampaign', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignById(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'readCampaign', { root: true })
            context.commit('SET_BY_ID', data)
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'readCampaign', { root: true })

            reject(error)
          })
      })
    },
    delete: (context, id) => {
      context.dispatch('registerTask', 'deleteCampaign', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.deleteCampaign(id)
          .then((data) => {
            // Determine if we need to update the campaign here, because we are prompting the user to SAVE CAMPAIGN after syncing
            context.commit('DELETE', id)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'deleteCampaign', { root: true })
          })
      })
    },
    replace: (context, campaignData) => {
      context.commit('REPLACE', campaignData)
    },
    syncCeltra: (context, id) => {
      context.dispatch('registerTask', 'syncCampaignCeltra', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignByIdCeltra(id)
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'syncCampaignCeltra', {
              root: true
            })
          })
      })
    },

    fetchAirtoryCampaign: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchAirtoryCampaign', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.fetchAirtoryCampaign(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryCampaign', {
              root: true
            })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryCampaign', {
              root: true
            })

            reject(error)
          })
      })
    },
    fetchCampaignById: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchCampaignById', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.getCampaignById(id)
          .then((data) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaignById', {
              root: true
            })
            resolve(data)
          })
          .catch((error) => {
            // app status: ready
            context.dispatch('completeTask', 'fetchCampaignById', {
              root: true
            })

            reject(error)
          })
      })
    },

    create3PData: (context, data) => {
      context.dispatch('registerTask', 'create3PData', { root: true })

      return new Promise((resolve, reject) => {
        ApiTagManager.create3PData(data)
          .then((response) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'create3PData', { root: true })
          })
      })
    },

    addCreativeCampaign: (context, data) => {
      // context.dispatch('registerTask', 'updateCreativeCampaign', { root: true });

      return new Promise((resolve, reject) => {
        context.commit('ADD_CREATIVE_BY_CAMPAIGN', data)
        resolve(data)
        // context.dispatch('completeTask', 'updateCreativeCampaign', { root: true });
      })
    },

    updateCreativeCampaign: (context, data) => {
      // context.dispatch('registerTask', 'updateCreativeCampaign', { root: true });
      return new Promise((resolve, reject) => {
        context.commit('UPDATE_CREATIVE_BY_CAMPAIGN', data)
        resolve(data)
        // context.dispatch('completeTask', 'updateCreativeCampaign', { root: true });
      })
    },

    addPlacementCampaign: (context, data) => {
      // context.dispatch('registerTask', 'updateCreativeCampaign', { root: true });
      return new Promise((resolve, reject) => {
        context.commit('ADD_PLACEMENT_BY_CAMPAIGN', data)
        resolve(data)
        // context.dispatch('completeTask', 'updateCreativeCampaign', { root: true });
      })
    }
  }
}
