<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
  tableData: {
    type: Array,
    default: () => []
  },
  fields: {
    type: Array,
    default: () => []
  },
  paginationData: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['get-pagination-event'])
const placementData = computed(() => {
  return props.tableData.flat()
})

watch(
  () => props.paginationData,
  (newValue) => {
    paginationObject.value = newValue
  }
)

const toolTipVisible = ref({})
const paginationObject = ref({})

const handlePaginationChange = (event) => {
  emit('get-pagination-event', event)
}

const debounce = (func, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const handleShowToolTip = (id) => {
  toolTipVisible.value = Object.keys(toolTipVisible.value).reduce(
    (acc, key) => {
      acc[key] = false
      return acc
    },
    {}
  )

  toolTipVisible.value = { ...toolTipVisible.value, [id]: true }
}
const handleHideToolTip = (id) => {
  toolTipVisible.value = { ...toolTipVisible.value, [id]: false }
}
const debouncedShowToolTip = debounce(handleShowToolTip, 500)
const debouncedHideToolTip = debounce(handleHideToolTip, 500)

const showToolTip = (id) => {
  debouncedShowToolTip(id)
}

const hideToolTip = (id) => {
  debouncedHideToolTip(id)
}
</script>

<template>
  <div class="moduleContent">
    <div class="contentTableFrame">
      <div class="contentTableTools d-flex justify-content-end">
        <b-pagination
          v-model="paginationObject.page"
          :per-page="paginationObject.pageSize"
          :total-rows="paginationObject.total"
          :limit="3"
          class="contentTableFrame__pagination"
          aria-controls="my-table"
          @change="handlePaginationChange"
        ></b-pagination>
      </div>
      <div>
        <b-table
          id="my-table"
          style="text-align: left"
          class="contentTable"
          :items="placementData"
          :fields="
            props.fields.map((field) => {
              if (field.key === 'uploaded_file_url') {
                return { ...field, class: 'limited-width-uploaded-file-url' }
              }
              if (field.key === 'log_message') {
                return { ...field, class: 'limited-width-log-message' }
              }
              return field
            })
          "
        >
          <template #cell(uploaded_file_url)="data">
            <span
              @mouseover="showToolTip(`assetUrl-${data.index}`)"
              @mouseleave="hideToolTip(`assetUrl-${data.index}`)"
            >
              <div
                v-if="toolTipVisible[`assetUrl-${data.index}`]"
                class="tooltip"
              >
                {{ data.value }}
              </div>
              <div>
                {{ data.value }}
              </div>
            </span>
          </template>
          <template #cell(log_message)="data">
            <span
              @mouseover="showToolTip(`errorMessage-${data.index}`)"
              @mouseleave="hideToolTip(`errorMessage-${data.index}`)"
            >
              <div
                v-if="toolTipVisible[`errorMessage-${data.index}`]"
                class="tooltip"
              >
                {{ data.value }}
              </div>
              <div>
                {{ data.value }}
              </div>
            </span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/scss/common.scss';

.contentTable th {
  border: none !important;
}

.limited-width-uploaded-file-url,
.limited-width-log-message {
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  display: inline-block;
  position: absolute;
  background-color: rgb(125, 125, 125);
  color: #fff;
  padding: 7px;
  border-radius: 5px;
  z-index: 1000;
  white-space: nowrap;
}
</style>
