import _ from 'lodash'
import Utils from '@/helpers/Utils'
import axios from 'axios'

// var ax = axios.create({
//     baseURL: process.env.VUE_APP_BACKEND_ENDPOINT,
//     headers: {
//         'x-api-key': process.env.VUE_APP_X_API_KEY
//     }
// });

const ApiTagManager = function () {
  // CAMPAIGNS
  /**
     * returns list of campaigns
     */
  this.getCampaignsList = () => {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults);
      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // INACTIVE CAMPAIGNS
  /**
     * returns list of inactive campaigns
     */
  this.getCampaignsInactiveList = () => {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults);
      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_INACTIVE_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // AIRTORY CAMPAIGNS
  /**
     * returns list of Airtory campaigns
     */
  this.getCampaignsListAirtory = () => {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults);
      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_LIST_AIRTORY}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns data from a campaign
     * @param {number} campaignId - Campaign ID
     */
  this.getCampaignById = (campaignId) => {
    const placementsn = []
    const top = campaignId == 10590 ? 60 : 0
    const placementid = 0
    return new Promise((resolve, reject) => {
      // axiosRetry(axios, { retries: 3 ,shouldResetTimeout:true});

      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_BY_ID}${campaignId}?top=${top}&placementid=${placementid}`)
        .then(response => {
          /* resolve(response.data.data); */
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })

      // axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay});
    })
  }

  /**
     * returns data from a campaign
     * @param {number} campaignId - Campaign ID
     */
  this.getCampaignNoPlacements = (campaignId) => {
    return new Promise((resolve, reject) => {
      // axiosRetry(axios, { retries: 3 ,shouldResetTimeout:true});

      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_BY_ID_NO_PLACEMENTS}${campaignId}`)
        .then(response => {
          /* resolve(response.data.data); */
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })

      // axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay});
    })
  }

  /**
     * returns data from a campaign
     * @param {number} campaignId - Campaign ID
     */
  this.getCampaignByIdIncludeTrackers = (campaignId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_BY_ID}${campaignId}?includeTrakers=1`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * create a campaign
     * @param {number} campaignData - Campaign Data to be inserted
     */
  this.createCampaign = (campaignData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_CREATE}`, campaignData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * update a campaign
     * @param {number} campaignData - Campaign Data to be inserted
     */
  this.updateCampaign = (campaignData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_UPDATE}`, campaignData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * delete a campaign by id
     * @param {number} campaignId - Campaign ID
     */
  this.deleteCampaign = (campaignId) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_DELETE}${campaignId}`)
        .then(response => {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * get a campaign by id
     * @param {number} id - Campaign ID
     */
  this.getPlacementCampaignById = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_CAMPAIGN_BY_ID}${id}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  this.getCreativesByCampaignId = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_CREATIVES_BY_CAMPAIGN_ID}${id}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  this.getFallbacksByCampaignId = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_FALLBACKS_BY_CAMPAIGN_ID}${id}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  this.getTrackersByPlacementId = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_TRACKERS_BY_PLACEMENT_ID}${id}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // CELTRA
  /**
     * returns list of campaigns from Celtra
     */
  this.getCampaignsListCeltra = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_LIST_CELTRA}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * Celtra Sync Campaign a campaign by id
     * @param {number} campaignId - Campaign ID
     */
  this.getCampaignByIdCeltra = (campaignId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_CAMPAIGN_BY_ID_CELTRA}${campaignId}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // FALLBACKS
  /**
     * returns list of fallbacks
     */
  this.getFallbacksList = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_FALLBACK_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns data from a fallback
     * @param {number} fallbackId - Fallback ID
     */
  this.getFallbackById = (fallbackId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_FALLBACK_BY_ID}${fallbackId}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * create a fallback
     * @param {number} fallbackData - Fallback Data to be inserted
     */
  this.createFallback = (fallbackData) => {
    // console.log(fallbackData)
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_FALLBACK_CREATE}`, fallbackData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * update a fallback
     * @param {number} fallbackData - Fallback Data to be inserted
     */
  this.updateFallback = (fallbackData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_FALLBACK_UPDATE}`, fallbackData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * delete a fallback by id
     * @param {number} fallbackId - Campaign ID
     */
  this.deleteFallback = (fallbackId) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_FALLBACK_DELETE}${fallbackId}`)
        .then(response => {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
          } else {
            reject(response.data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // PLACEMENTS
  /**
     * returns list of placements
     */
  this.getAllPlacementsList = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /**
     * returns list of placements
     */
  this.getPlacementsList = (campaignId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_LIST_BY_CAMPAIGN}${campaignId}?adserver=DBM`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns list of placements from celtra
     */
  this.getPlacementsListCeltra = (campaignCeltraId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_LIST_BY_CAMPAIGN_CELTRA}${campaignCeltraId}?adserver=DBM`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns data from a placement
     * @param {number} placementId - Placement ID
     */
  this.getPlacementById = (placementId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_BY_ID}${placementId}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns data from a placement
     * @param {number} placementId - Placement ID
     */
  this.getTrackersListByPlacement = (selectedPlacementsId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_TRACKERS_LIST_BY_PLACEMENT_ID}?selectedPlacementsId=${selectedPlacementsId}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns data from a placement
     * @param {number} placementId - Placement ID
     */
  this.duplicatePlacementById = (placementId) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_DUPLICATE}${placementId}/duplicate`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * create a placement
     * @param {number} placementData - Placement Data to be inserted
     */
  this.createPlacement = (placementData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_CREATE}`, placementData)
        .then(response => {
          // resolve(placementData);
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
     * delete a placement by id
     * @param {number} placementId - Placement ID
     */
  this.deletePlacement = (placementId) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_DELETE}${placementId}`)
        .then(response => {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * updates a placement info
     * @param {object} placementData - placement updated data to be stored.
     */
  this.updatePlacement = placementData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_UPDATE}`, placementData)
        .then(response => {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
          } else {
            reject(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * updates a placement info
     * @param {array} of {object} placementData - placement updated data to be stored.
     */
  this.createPlacementBulk = placementData => {
    // add a space avoiding the ad to render undefined
    placementData.placements.map(item => {
      if (item.append_script === '') item.append_script = ' '
      return item
    })

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_CREATE_BULK}`, placementData)
        .then(response => {
          // if (response.data.success == true) {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * updates a placement info
     * @param {array} of {object} placementData - placement updated data to be stored.
     */
  this.updatePlacementBulk = placementData => {
    // add a space avoiding the ad to render undefined
    placementData.placements.map(item => {
      if (item.append_script === '') item.append_script = ' '
      return item
    })

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_UPDATE_BULK}`, placementData)
        .then(response => {
          // if (response.data.success == true) {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch(error => {
          console.log()
          reject(error)
        })
    })
  }

  this.placementTrackersBulk = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ENDPOINT_PLACEMENT_TRACKERS_BULK, data)
        .then(response => {
          // console.log(response)
          resolve(response.data.data)
        })
        .catch(error => {
          // console.log(error)
          reject(error)
        })
    })
  }
  /**
      * Celtra Sync Placement a placement by id
      * @param {number} placementId - Placement ID
      */
  this.syncPlacement = (placementId) => {
    return new Promise((resolve, reject) => {
      setTimeout(resolve({ message: 'success' }, 1000))
    })
  }

  /**
     * returns list of tags by advertiser and placements
     * @param {Object} data - {campaignId:0, placementsId:[{id:0}]}
     */
  this.getAdvertisersList = (data) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_ADVERTISER_LIST}`, data)
        .then(response => {
          // console.log('Data Tags', response.data);
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns list of tags by advertiser and placements
     * @param {Object} advertiserId - Id of the placement
     */
  this.getAdvertiserById = (advertiserId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_ADVERTISER_BY_ID}${advertiserId}`, data)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * creates a new advertiser
     * @param {object} advertiserData - advertiser data to be inserted.
     */
  this.createAdvertiser = advertiserData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_ADVERTISER_CREATE}`, advertiserData)
        .then(response => {
          if (response.data.message == 'The request has succeeded.') {
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * updates placement data
     * @param {object} advertiserData - advertiser data to be inserted.
     */
  this.editAdvertiser = advertiserData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_ADVERTISER_UPDATE}`, advertiserData)
        .then(response => {
          if (response.data.message == 'The request has succeeded.') {
            resolve(response.data.data)
          } else {
            reject(response.data.data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * deletes an advertiser
     * @param {Object} advertiserId - Id of the placement to be deleted
     */
  this.deleteAdvertiserById = (advertiserId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_ADVERTISER_DELETE}${advertiserId}`, data)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * get tags
     * @param {Object} tagsData - campaign Id and placements Ids
     */
  this.getTags = (tagsData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_TAG_GENERATOR}`, tagsData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * uploads a 3P Data file
     * @param {String} base64File - Base 64 string of the file to be uploaded
     */
  this.upload3PDataFile = (fileData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_3P_ASSET_CREATE}`, fileData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * creates a new 3P Data
     * @param {object} data - 3p Data data to be inserted.
     */
  this.create3PData = data => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_3P_DATA_CREATE}`, data)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
    })
  }

  /**
     * create a placement tracker/s
     * @param {number} id - Placement Id to be inserted
     * @param {object or array} trackers - Placement Tracker/s events to be inserted
     */
  this.addPlacementTrackers = (id, trackers) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_ADD_TRACKERS}${id}/trackers`, trackers)
        .then(response => {
          // console.log('TRACKERS API', response.data.data);
          // resolve(trackers);
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
     * remove a placement tracker/s
     * @param {number} id - Placement Id to be inserted
     * @param {object or array} trackers - Placement Tracker/s events to be inserted
     */
  this.removePlacementTrackers = (id, trackers) => {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_DELETE_TRACKERS}${id}/trackers`, { data: { trackers } })
        .then(response => {
          // resolve(trackers);
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
     * create a placement tracker/s
     * @param {number} id - Placement Id to be inserted
     * @param {object or array} trackers - Placement Tracker/s events to be inserted
     */
  this.updatePlacementTrackers = (id, trackers) => {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_UPDATE_TRACKERS}${id}/trackers`, trackers)
        .then(response => {
          // resolve(trackers);
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
     * create a placement options tracker/s
     * @param {number} id - Placement Id to be inserted
     * @param {object or array} trackers - Placement Option Tracker/s events to be inserted
     */
  this.addPlacementOptionTrackers = (id, trackers) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_ADD_OPTION_TRACKERS}${id}/trackers/options`, trackers)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
     * update a placement options tracker/s
     * @param {number} id - Placement Id to be updated
     * @param {object or array} trackers - Placement Option Tracker/s events to be updated
     */
  this.updatePlacementOptionTrackers = (id, trackers) => {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_ENDPOINT_PLACEMENT_ADD_OPTION_TRACKERS}${id}/trackers/options`, trackers)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
     * parse the objects to and convert to xlsx with custom data validation
     * @param {array or object} payloadXLS - Placements array
     */
  this.trafficPlanFileDownload = (payloadXLS) => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ENDPOINT_TRAFFIC_PLAN_XLSX_DOWNLOAD, payloadXLS, {
        responseType: 'arraybuffer'
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
     * parse the xlsx to and convert to array of objects
     * @param { object buffer data } { buffer: data } - XLSX buffer
     */
  this.trafficPlanFileUpload = (bufferObj) => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ENDPOINT_TRAFFIC_PLAN_XLSX_UPLOAD, bufferObj)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export default new ApiTagManager()
