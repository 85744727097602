<template>
  <section class="sModal">
    <Preloader :in-modal="true"></Preloader>

    <header
      v-if="itemId"
      class="sModal__header"
    >
      <span
        v-if="creative.liveCreatives === 'Live'"
        class="live-badge"
      ></span
      >{{ creative.name }}
    </header>
    <header
      v-else
      class="sModal__header"
    >
      New Creative
    </header>

    <form @submit.prevent="formSubmit">
      <nav class="sModal__tabs">
        <ul>
          <!--
            Workaround:
            Each tab requires validations. I wanted to use the native validation. So...
            I am using buttons for the tabs so it triggers the form submittion. This also changes the toSection var.
            The idea is that when the formSubmit method is called it checks against the toSection var, if it has value then
            it will change currentSection to toSection value, so the tab changes. If the form is submitted with
            no toSection value (e.g. when the save creative button is hit), then it will just call saveData.
          -->
          <li :selected="currentSection == 'details'">
            <button @click="gotoSection('details')">Details</button>
          </li>
          <li :selected="currentSection == 'design'">
            <button @click="gotoSection('design')">Design</button>
          </li>
          <li :selected="currentSection == 'demo'">
            <button @click="gotoSection('demo')">Demo</button>
          </li>
          <li :selected="currentSection == 'performance-metrics'">
            <button @click="gotoSection('performance-metrics')">
              Performance Metrics
            </button>
          </li>
          <li :selected="currentSection == 'assets'">
            <button @click="gotoSection('assets')">Assets</button>
          </li>
        </ul>
      </nav>

      <div
        v-show="currentSection == 'details'"
        class="sModal__body"
      >
        <FormField
          label="Creative Name  *"
          for-id="creative-name"
        >
          <input
            id="creative-name"
            v-model="creative.name"
            type="text"
            maxlength="50"
            required
          />
        </FormField>

        <FormField
          label="Campaign Name"
          for-id="campaign-name"
        >
          <input
            id="campaign-name"
            v-model="creative.campaign"
            type="text"
          />
        </FormField>

        <FormField
          label="Advertiser  *"
          for-id="creative-advertiser"
        >
          <!-- <input v-model="creative.advertiser" id="campaign-advertiser" type="text" required /> -->
          <autocomplete
            id="creative-advertiser"
            ref="autocomplete"
            :initial-value="advertiserId"
            :initial-display="advertiserName"
            :show-no-results="false"
            :source="listAdvertisersArray"
            results-property="name"
            placeholder=""
            :show-search-icon="false"
            :show-close-icon="false"
            :allow-out-of-list="true"
            :required="true"
            @selected="onAutocompleteSelected"
          >
          </autocomplete>
        </FormField>

        <FormField
          label="Vertical *"
          for-id="campaign-vertical"
          type="select"
        >
          <select
            id="campaign-vertical"
            v-model="creative.vertical_id"
            required
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Vertical
            </option>
            <option
              v-for="(vertical, index) in verticals"
              :key="index"
              :value="vertical.id"
            >
              {{ vertical.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Creative Type *"
          for-id="creative-type"
          type="select"
        >
          <select
            id="creative-type"
            v-model="creative.type_id"
            required
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Creative Type
            </option>
            <option
              v-for="(creativeType, index) in creativeTypes"
              :key="index"
              :value="creativeType.id"
            >
              {{ creativeType.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Deal Type"
          for-id="deal-type"
          type="select"
        >
          <select
            id="deal-type"
            v-model="creative.deal_id"
          >
            <option
              value="null"
              selected
              disabled
            >
              Select a Deal Type
            </option>
            <option
              v-for="(dealType, index) in dealTypes"
              :key="index"
              :value="dealType.id"
            >
              {{ dealType.name }}
            </option>
          </select>
        </FormField>

        <div class="formFieldGroup">
          <div
            v-if="showDate"
            class="date-range-picker-common"
          >
            <date-range-picker
              ref="picker"
              v-model="dateRangeData"
              opens="inline"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
              :min-date="null"
              :max-date="null"
              :single-date-picker="false"
              :time-picker="false"
              :time-picker24-hour="false"
              :show-week-numbers="true"
              :show-dropdowns="false"
              :ranges="false"
              :auto-apply="true"
              @select="updateValues"
            >
            </date-range-picker>
          </div>

          <label>Flight Dates *</label>
          <div class="formFieldGroup__fields">
            <FormField
              type="date"
              tag-left="Start"
              required
            >
              <input
                id="campaign-flight-start"
                v-model="campaignStartDateInput"
                aria-required="true"
                type="text"
                required
                @click="showDate = !showDate"
              />
            </FormField>

            <FormField
              type="date"
              tag-left="End"
              required
            >
              <input
                id="campaign-flight-end"
                v-model="campaignEndDateInput"
                aria-required="true"
                type="text"
                required
                @click="showDate = !showDate"
              />
            </FormField>
          </div>
        </div>

        <FormField
          label="Format *"
          for-id="creative-format"
          type="select"
        >
          <select
            id="creative-format"
            v-model="creative.format_id"
            required
            @change="onChangeFormat($event)"
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Format
            </option>
            <option
              v-for="(format, index) in _.sortBy(creativeFormatsNoDeprecated, [
                'name'
              ])"
              :key="index"
              :value="format.id"
            >
              {{ format.name }}
            </option>
          </select>
        </FormField>
        <FormField>
          <div
            v-show="isVidStream"
            class="mb1"
          >
            <input
              id="iframe_vidstream"
              v-model="creative.iframe_vidstream"
              type="checkbox"
            />
            Iframe VidStream
          </div>
          <div
            v-show="isVidStream"
            class="mb1"
          >
            <input
              id="iframe_vidstream"
              v-model="creative.crossScreen"
              type="checkbox"
            />
            Is Cross-screen
          </div>
        </FormField>

        <FormField
          label="Main KPI *"
          for-id="creative-kpi"
          type="select"
        >
          <select
            id="creative-kpi"
            v-model="creative.mainKPI_id"
            required
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Kpi
            </option>
            <option
              v-for="(kpi, index) in KPIs"
              :key="index"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          v-show="!isCTV && !isVidStream"
          label="SUPPORTED DEVICES"
        >
          <div class="mb10">
            <input
              id="supports_mobile"
              v-model="creative.supports_mobile"
              type="checkbox"
            />
            Mobile
          </div>
          <div class="mb0">
            <input
              id="supports_desktop"
              v-model="creative.supports_desktop"
              type="checkbox"
            />
            Desktop
          </div>
        </FormField>

        <FormField
          v-show="!isCTV && !isVidStream"
          label="IS AN EXPANDABLE OR FLOATING CREATIVE?"
        >
          <div class="mb10">
            <input
              id="is_expandable"
              v-model="creative.expandable"
              type="checkbox"
              @change="expandableChange"
            />
            Yes
          </div>
        </FormField>

        <FormField
          label="Keywords / Tags *"
          for-id="creative-keywords"
        >
          <input
            id="creative-keywords"
            v-model="creative.keywords"
            type="text"
            required
          />
        </FormField>

        <FormField
          v-if="currentSection === 'details'"
          label="Description"
          for-id="creative-description"
        >
          <div class="editor">
            <editor-menu-bar :editor="editor">
              <div
                slot-scope="{ commands, isActive }"
                class="editor__menu"
              >
                <button
                  class="editor__menu__button"
                  :class="{ 'is-active': isActive.bold() }"
                  @click.prevent="commands.bold"
                >
                  <font-awesome-icon
                    icon="bold"
                    class="i"
                  />
                </button>

                <button
                  class="editor__menu__button"
                  :class="{ 'is-active': isActive.italic() }"
                  @click.prevent="commands.italic"
                >
                  <font-awesome-icon
                    icon="italic"
                    class="i"
                  />
                </button>

                <button
                  class="editor__menu__button"
                  :class="{ 'is-active': isActive.paragraph() }"
                  @click.prevent="commands.paragraph"
                >
                  <font-awesome-icon
                    icon="paragraph"
                    class="i"
                  />
                </button>

                <button
                  class="editor__menu__button"
                  :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                  @click.prevent="commands.heading({ level: 3 })"
                >
                  <font-awesome-icon
                    icon="heading"
                    class="i"
                  />
                </button>
              </div>
            </editor-menu-bar>

            <editor-content
              id="creative-description"
              class="editor__content formField__field"
              :editor="editor"
            />
          </div>
        </FormField>
      </div>

      <div
        v-if="currentSection == 'design'"
        class="sModal__body"
      >
        <FormField label="Design Images">
          <div class="tileBox">
            <ul>
              <draggable v-model="creative.designImages">
                <li
                  v-for="(designImage, index) in activeDesignFiles"
                  :key="index"
                >
                  <div
                    class="tileBox__image"
                    :style="{
                      'background-image': 'url(' + designImage.path + ')'
                    }"
                  >
                    <button
                      v-if="designImage.status != 'removed'"
                      @click.prevent="removeDesignImage(designImage)"
                    >
                      <font-awesome-icon
                        icon="trash-alt"
                        class="i"
                      />
                    </button>
                  </div>

                  <div class="tileBox__label">
                    <div
                      v-if="designImage.status == 'new'"
                      class="tileBox__label__tag tileBox__label__tag--new"
                    >
                      new
                    </div>
                    <div
                      v-if="designImage.status == 'removed'"
                      class="tileBox__label__tag tileBox__label__tag--removed"
                    >
                      removed
                    </div>
                    Slide {{ index + 1 }}
                  </div>
                </li>
              </draggable>
              <li
                v-if="creative.designImages.length == 0"
                class="empty"
              >
                <div>There are no designs attached to this creative.</div>
              </li>
            </ul>
          </div>
          <small v-if="creative.designImages.length != 0"
            >* You must save creative in order for New and Removed Design Images
            to be saved.</small
          >
        </FormField>

        <FormField
          label="Upload Design Images"
          description="Supported Formats: JPEG, PNG."
          for-id="creative-delivered-impressions"
        >
          <b-form-file
            v-model="assetUploader.file"
            class="button-file"
            multiple
            accept="image/jpeg, image/png"
            :state="Boolean(assetUploader.file)"
            placeholder="Choose a file..."
            @change="uploadDesignImages"
          ></b-form-file>
        </FormField>
      </div>

      <div
        v-if="currentSection == 'demo'"
        class="sModal__body"
      >
        <FormField
          label="Creative Tag *"
          for-id="creative-demo-tag"
        >
          <div v-show="(isCTV || isVidStream) && !creative.iframe_vidstream">
            Add a VAST Tag
          </div>
          <!-- make the demo field required if there are no 'active' design files (creative.designFiles.status != removed). Don't make it required if we are trying to go to the previous section-->
          <textarea
            id="creative-demo-tag"
            v-model="creative.demoTag"
            rows="20"
            :required="activeDesignFiles == 0"
          ></textarea>
        </FormField>
      </div>

      <div
        v-if="currentSection == 'performance-metrics'"
        class="sModal__body"
      >
        <FormField label="Performance Metrics">
          <div class="listBox">
            <ul>
              <draggable v-model="creative.performanceMetrics">
                <li
                  v-for="(
                    performanceMetric, index
                  ) in creative.performanceMetrics"
                  :key="index"
                >
                  <div class="formFieldGroup listBox__label">
                    <div class="formFieldGroup__fields">
                      <FormField
                        :for-id="'performanceMetric__name' + index"
                        tag-left="Name"
                      >
                        <input
                          :id="'performanceMetric__name' + index"
                          v-model="performanceMetric.name"
                          :max="creative.campaignEndDate"
                          type="text"
                          required
                        />
                      </FormField>

                      <FormField
                        :for-id="'performanceMetric__value' + index"
                        tag-left="Value"
                      >
                        <input
                          :id="'performanceMetric__value' + index"
                          v-model="performanceMetric.value"
                          :min="creative.campaignStartDate"
                          type="text"
                          required
                        />
                      </FormField>
                    </div>
                  </div>
                  <div>
                    <button @click.prevent="removeMetric(index)">
                      <font-awesome-icon
                        icon="trash-alt"
                        class="i"
                      />
                    </button>
                  </div>
                </li>
              </draggable>
              <li
                v-if="creative.performanceMetrics.length == 0"
                class="empty"
              >
                <div>There are no metrics attached to this creative.</div>
              </li>
            </ul>
          </div>
        </FormField>

        <FormField>
          <button
            class="button button--positive"
            @click.prevent="addMetric()"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            />add metric
          </button>
        </FormField>
      </div>

      <div
        v-if="currentSection == 'assets'"
        class="sModal__body"
      >
        <FormField label="Assets Files">
          <div class="listBox">
            <ul>
              <li
                v-for="(assetFile, index) in creative.assetFiles"
                v-if="assetFile.status != 'removed'"
                :key="index"
              >
                <div class="listBox__label">
                  <div
                    v-if="assetFile.status == 'new'"
                    class="listBox__label__tag listBox__label__tag--new"
                  >
                    new
                  </div>
                  <div
                    v-if="assetFile.status == 'removed'"
                    class="listBox__label__tag listBox__label__tag--removed"
                  >
                    removed
                  </div>
                  {{ assetFile.name }}
                </div>
                <div>
                  <button
                    v-if="
                      assetFile.type == 'image' || assetFile.extension == 'pdf'
                    "
                    @click.prevent="viewAsset(assetFile)"
                  >
                    <font-awesome-icon
                      icon="eye"
                      class="i"
                    />
                  </button>
                  <button @click.prevent="downloadAsset(assetFile)">
                    <font-awesome-icon
                      icon="download"
                      class="i"
                    />
                  </button>
                  <button
                    v-if="assetFile.status != 'removed'"
                    @click.prevent="removeAsset(assetFile)"
                  >
                    <font-awesome-icon
                      icon="trash-alt"
                      class="i"
                    />
                  </button>
                  <button
                    v-if="assetFile.status == 'removed'"
                    @click.prevent="undoRemoveAsset(assetFile)"
                  >
                    <font-awesome-icon
                      icon="undo-alt"
                      class="i"
                    />
                  </button>
                </div>
              </li>
              <li
                v-if="creative.assetFiles.length == 0"
                class="empty"
              >
                <div>There are no assets attached to this creative.</div>
              </li>
            </ul>
          </div>
          <small v-if="creative.assetFiles.length != 0"
            >* You must save creative in order for New and Removed Files to be
            saved.</small
          >
        </FormField>

        <FormField
          label="Upload Files"
          for-id="creative-delivered-impressions"
        >
          <b-form-file
            v-model="assetUploader.file"
            class="button-file"
            multiple
            accept="application/vnd.sealed.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/jpeg, image/png"
            :state="Boolean(assetUploader.file)"
            placeholder="Choose a file..."
            @change="uploadAssets"
          ></b-form-file>
        </FormField>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button
            class="cancelButton"
            @click.prevent="cancel"
          >
            <font-awesome-icon
              icon="ban"
              class="i"
            />Cancel
          </button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Next' if this is new creative and we are not on the last screen -->
          <button
            v-if="!itemId && !isFirstSection"
            class="saveButton"
            @click="gotoSection(prevSection)"
          >
            <font-awesome-icon
              icon="angle-left"
              class="i"
            />Previous
          </button>

          <!-- show 'Next' if this is new creative and we are not on the last screen -->
          <button
            v-if="!itemId && !isLastSection"
            class="saveButton"
            @click="gotoSection(nextSection)"
          >
            Next<font-awesome-icon
              icon="angle-right"
              class="i last"
            />
          </button>

          <!-- show 'Save Creative' if this not a new creative -->
          <button
            v-if="itemId"
            :disabled="!isDataModified"
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Save Creative
          </button>

          <!-- show 'Save New Creative' if this is new creative and we are on the last screen-->
          <button
            v-if="!itemId && isLastSection"
            :disabled="!isDataModified"
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Save New Creative
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import draggable from 'vuedraggable'
import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'
import Autocomplete from '@/components/Autocomplete.vue'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'
import Preloader from '@/components/Preloader.vue'
import DateRangePicker from 'vue2-daterange-picker'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreativeProfile',
  components: {
    FormField,
    draggable,
    EditorMenuBar,
    EditorContent,
    Preloader,
    Autocomplete,
    DateRangePicker
  },
  props: {
    /* if itemId prop comes empty, the template considers this as a new user */
    itemId: null,
    advertiserId: null,
    advertiserName: null
  },
  data() {
    return {
      loading: true,
      sections: ['details', 'design', 'demo', 'performance-metrics', 'assets'],
      campaignStartDateInput: '',
      campaignEndDateInput: '',
      currentSection: 'details',
      assetUploader: {
        file: null
      },
      creative: {
        id: null,
        type_id: '',
        advertiser: '',
        advertiser_id: '',
        creativeName: '',
        campaignName: '',
        flightDateStart: '',
        flightDateEnd: '',
        format_id: '',
        mainKPI_id: '',
        vertical_id: '',
        deal_id: null,
        keywords: '',
        description: '',
        designImages: [],
        supports_mobile: false,
        supports_desktop: false,
        demoTag: '',
        deliveredImpressions: null,
        deliveredImpressionsShow: false,
        engagements: null,
        engagementsShow: false,
        avgTimeSpent: null,
        avgTimeSpentShow: false,
        videoCompletions: null,
        videoCompletionsShow: false,
        viewableImpressions: null,
        viewableImpressionsShow: false,
        assetFiles: [],
        performanceMetrics: [],
        expandable: false,
        iframe_vidstream: null,
        crossScreen: false
      },
      undoBucket: [],
      editor: null,
      showDate: false,
      dateRangeData: {
        startDate: new Date(),
        endDate: new Date()
      },
      isCTV: false,
      isVidStream: false
    }
  },
  computed: {
    ...mapGetters([
      'showcaseCreativeById',
      'verticals',
      'creativeFormats',
      'creativeFormatsNoDeprecated',
      'creativeTypes',
      'dealTypes',
      'KPIs'
    ]),
    ...mapGetters('advertisers', {
      listAdvertisers: 'list',
      advertiserById: 'itemById',
      advertiserByName: 'itemByName',
      listAdvertisersArray: 'listAdvertisersArray'
    }),
    activeDesignFiles: function () {
      const self = this
      // a list of design files that are not status = removed.
      return _.filter(self.creative.designImages, (di) => {
        return di.status != 'removed'
      })
    },
    isFirstSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return self.sections.indexOf(self.currentSection) + 1 == 1
    },
    isLastSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return (
        self.sections.indexOf(self.currentSection) + 1 == self.sections.length
      )
    },
    prevSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex = self.sections.indexOf(self.currentSection) - 1

      // return the section name on the next position in the sections array;
      return self.sections[currentSectionIndex]
    },
    nextSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex = self.sections.indexOf(self.currentSection) + 1

      // return the section name on the next position in the sections array;
      return self.sections[currentSectionIndex]
    },
    isDataModified: function () {
      const self = this

      return !_.isEqual(self.creative, self.showcaseCreativeById(self.itemId))
    }
  },
  watch: {
    /* when the section is changed */
    currentSection: function (value) {
      const self = this

      // reset the editor
      self.setEditor()
    },
    campaignStartDateInput: function (value) {
      const self = this

      // update the campaign flight dates of the creative
      self.creative.campaignStartDate = self
        .$moment(self.campaignStartDateInput)
        .utc()
        .toISOString()
    },
    campaignEndDateInput: function (value) {
      const self = this

      // update the campaign flight dates of the creative
      self.creative.campaignEndDate = self
        .$moment(self.campaignEndDateInput)
        .utc()
        .toISOString()
    }
  },
  created() {
    const self = this

    // fetch advertisers
    self.fetchAdvertisers()

    // populate form
    self.populateForm()
  },
  beforeUnmount() {
    const self = this
    self.editor.destroy()
  },
  methods: {
    ...mapActions([
      'fetchShowcaseCreativeAssets',
      'updateShowcaseCreative',
      'createShowcaseCreative',
      'registerTask',
      'completeTask'
    ]),
    ...mapActions('advertisers', {
      createAdvertiser: 'create',
      fetchAdvertisers: 'fetch',
      setAdvertiser: 'setAdvertiser'
    }),
    onChangeFormat: function (evt) {
      const self = this

      evt.target.value === '62' ? (self.isCTV = true) : (self.isCTV = false)
      evt.target.value === '63'
        ? (self.isVidStream = true)
        : (self.isVidStream = false)
      self.creative.iframe_vidstream = 0
      self.creative.expandable = 0
    },
    validateVastTag: function (xmlString) {
      try {
        // Check if the XML string contains an <script> tag
        if (xmlString.includes('<script>')) {
          return false
        }
        const parser = new DOMParser()
        const doc = parser.parseFromString(xmlString, 'application/xml')

        // if parsing the XML string to the documment is successful
        return doc.getElementsByTagName('parsererror').length === 0
      } catch (e) {
        // if parsing the XML string to the documment is not successful
        return false
      }
    },
    onAutocompleteSelected: function (data) {
      this.creative.advertiser_id = data.value
      this.creative.advertiser = data.display
    },
    expandableChange: function () {
      const self = this
      // console.log('expandable', self.creative.expandable, Number(self.creative.expandable))
    },
    addMetric: function () {
      const self = this

      self.creative.performanceMetrics.push({
        name: '',
        value: ''
      })
    },
    removeMetric: function (index) {
      const self = this

      // if the metric has any data
      if (
        self.creative.performanceMetrics[index].name != ''
        && self.creative.performanceMetrics[index].value != ''
      ) {
        // ask before removing
        if (confirm('Are you sure you want to remove this metric?')) {
          // if yes, then remove the metric
          self.creative.performanceMetrics.splice(index, 1)
        }
      } else {
        // if the metric is empty, just remove it.
        self.creative.performanceMetrics.splice(index, 1)
      }
    },
    gotoSection: function (section) {
      const self = this

      // if this a new creative and if the section we are trying to go to is previous to the currentSection (More to the left)
      if (!self.itemId && self.isPrevSection(section)) {
        // set toSection so the formSubmit knows we are navigation to another section.
        self.toSection = section

        // just change currentSection so we move and bypass the validation
        self.currentSection = section
      } else {
        // otherwise let's keep the validation and
        // change toSection, which is use by the formSubmit.
        self.toSection = section
      }
    },
    formSubmit: function (e) {
      const self = this

      // if toSection has any value it means that we are trying to go to a section. so...
      if (self.toSection != null) {
        // do not submit to action
        e.preventDefault()
        // then change the current section to the toSectino
        self.currentSection = self.toSection

        self.toSection = null

        // if we selected Brandscape or FT (Adhesion) format we'll replace web.js for preview.js in the TAG
        if (this.currentSection == 'performance-metrics') {
          const id = this.creative.format_id
          // Brandscape id:2
          // FT (Adhesion) id:36
          if (parseInt(id) == 2 || parseInt(id) == 36) {
            const tag = this.creative.demoTag
            this.creative.demoTag = tag.replace('web.js', 'preview.js')
          }
        }
      } else {
        // otherwise just save data
        self.saveData()
      }
    },
    saveData: function () {
      const self = this

      // convert to int
      self.creative.supports_mobile = self.creative.supports_mobile ? 1 : 0
      self.creative.supports_desktop = self.creative.supports_desktop ? 1 : 0
      self.creative.iframe_vidstream = self.creative.iframe_vidstream ? 1 : 0

      // convert to int 0 or 1, another way
      // by default will be expandable

      if (self.creative.demoTag.includes('celtra')) {
        self.creative.expandable = Number(1)
      } else {
        self.creative.expandable = Number(self.creative.expandable)
      }

      // check if the advertiser does not exist
      let advertiserObject = _.get(
        _.keyBy(self.listAdvertisersArray, 'name'),
        `${self.creative.advertiser}`,
        ''
      )

      if (advertiserObject) {
        self.creative.advertiser_id = advertiserObject.id
        self.creative.advertiser = advertiserObject.name
        self.setAdvertiser(advertiserObject)

        self.saveCreativeData()
      } else {
        // create advertiser
        advertiserObject = {
          id: self.creative.advertiser_id,
          name: self.creative.advertiser
        }
        self.setAdvertiser(advertiserObject)

        self
          .createAdvertiser({ name: self.creative.advertiser })
          .then((data) => {
            // self.$log.info('advertiser', data)
            advertiserObject.id = self.creative.advertiser_id = data.id
            self.creative.advertiser = data.name

            self.saveCreativeData()
          })
      }
    },
    saveCreativeData: function () {
      const self = this

      const tag = self.creative.demoTag
      // check if the creative is valid XML
      if (
        (self.isCTV || self.creative.format_id === 63)
        && !self.creative.iframe_vidstream
        && tag.length > 1
      ) {
        if (!self.validateVastTag(tag)) {
          // show an alert and return if it's not valid
          this.$swal({
            position: 'top',
            title: 'Unable to Save Creative',
            html: '<h3>Verify that the VAST TAG is correctly formatted and contains all of the required elements and attributes.</h3>',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            allowOutsideClick: false,
            icon: 'info',
            timer: 15000,
            toast: false
          })
          return
        }
      }

      // if this is not a new creative...
      if (self.itemId) {
        // update the creatives (clone deep so in case it fails none of the data gets messed up)
        self
          .updateShowcaseCreative(_.cloneDeep(self.creative))
          // emit done event
          .then((response) => self.$emit('done', response))
          .catch((error) => {
            // notify the user
            alert(
              'There was a problem updating this creative. Please try again.'
            )
          })
      } else {
        // create the creative (clone deep so in case it fails none of the data gets messed up)
        self
          .createShowcaseCreative(_.cloneDeep(self.creative))
          // emit done event
          .then((response) => self.$emit('done', response))
          .catch((error) => {
            // notify the user
            alert(
              'There was a problem creating this creative. Please try again.'
            )
          })
      }
    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('cancel')
    },
    populateForm: function () {
      const self = this

      self.$log.info(
        'advertiser',
        self.itemId,
        self.advertiserId,
        self.advertiserName
      )

      // if an itemId came in it means that we are trying to load settings for an existing creative... so if there's an itemId
      if (self.itemId) {
        // let's fetch the assets for tat creative
        self
          .fetchShowcaseCreativeAssets(self.itemId)
          .then((assets) => {
            // let's clode te
            const creatif = _.cloneDeep(self.showcaseCreativeById(self.itemId))

            // since the input[type=date] requires YYY-MM-DD format (we are using ISO date format), let's use a local property to manage it and use a watch to update the dates in the object
            self.campaignStartDateInput = creatif.campaignStartDate
              ? self
                  .$moment(creatif.campaignStartDate)
                  .utc()
                  .format('YYYY-MM-DD')
              : ''
            self.campaignEndDateInput = creatif.campaignEndDate
              ? self.$moment(creatif.campaignEndDate).utc().format('YYYY-MM-DD')
              : ''

            self.creative = creatif

            // Validate if creative is Advance CTV or VidStream+
            self.creative.format_id === 62
              ? (self.isCTV = true)
              : (self.isCTV = false)
            self.creative.format_id === 63
              ? (self.isVidStream = true)
              : (self.isVidStream = false)

            // render the editor for the description
            self.setEditor()
          })
          .catch((error) => {
            alert(
              'There was a problem loading this creative. Please report this issue to simpli@padsquad.com. Creative Id:'
                + self.itemId
            )

            // emit event to close the modal.
            self.$emit('cancel')
          })
      } else {
        // render the editor for the description
        self.setEditor()
      }
    },
    setEditor: function () {
      const self = this

      // set the editor content as description
      self.editor = new Editor({
        extensions: [
          new HardBreak(),
          new Heading({ levels: [3] }),
          new Bold(),
          new Italic()
        ],
        content: self.creative.description,
        onUpdate: ({ getJSON, getHTML }) => {
          self.creative.description = getHTML()
        }
      })
    },
    uploadDesignImages: function (event) {
      const self = this

      // turn on loading spinner
      self.loading = true

      // Files to be uploaded
      const files = event.target.files

      let errors = 0

      // amount of pending files.
      let filesQueue = files.length

      // app status: loading
      self.registerTask('uploadDesignImages')

      // iterate over the list of files to be uploaded.
      _.forOwn(files, (file) => {
        const fileNameSplit = file.name.split('.')

        Utils.toBase64(file)
          .then((fileString) => {
            return {
              type: 'design',
              extension: `.${fileNameSplit[fileNameSplit.length - 1]}`,
              fileData: fileString,
              fileName: `${file.name.replace('.' + fileNameSplit[fileNameSplit.length - 1], '')}`
            }
          })
          .then(Api.uploadFile)
          .then((newFile) => {
            // add the new file data to the creative.assetFiles array
            self.creative.designImages.push(newFile)

            // one less from the Queue
            filesQueue--

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // app status: ready
              self.completeTask('uploadDesignImages')

              // turn off loading spinner
              self.loading = false

              if (errors) {
                alert(
                  'It appears that there are some files we could not upload. Please try again.'
                )
              }
            }
          })
          .catch((error) => {
            // one less from the Queue
            filesQueue--

            errors++

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // app status: ready
              self.completeTask('uploadDesignImages')

              // turn off loading spinner
              self.loading = false

              if (errors) {
                alert(
                  'It appears that there are some files we could not upload. Please try again.'
                )
              }
            }

            self.$log.error('uploadAssets', error)
          })
      })
    },
    removeDesignImage: function (designImage) {
      const self = this

      // turn on loading spinner
      self.loading = true

      if (
        confirm(
          'This will remove the image permanently. Are you sure you want to remove this image?'
        )
      ) {
        Api.deleteFile(designImage.id)
          .then(() => {
            // now, let's stage file to be removed on save.
            // find index of the asset file to be deleted, in the creatives.assetFiles
            const index = _.findIndex(self.creative.designImages, {
              id: designImage.id
            })

            // create an assetFile with status removed so it is removed when saved
            const removedImage = designImage
            removedImage.status = 'removed'

            // replace the assetFile in the creative.assetFiles with the removedFile element
            self.creative.designImages.splice(index, 1, designImage)

            // turn off loading spinner
            self.loading = false
          })
          .catch((error) => {
            self.$log.error(error)

            // turn off loading spinner
            self.loading = false

            alert('There was an issue deleting the image. Please try again')
          })
      }
    },
    uploadAssets: function (event) {
      const self = this

      // turn oon loading spinner
      self.loading = true

      // Files to be uploaded
      const files = event.target.files

      // amount of pending files.
      let filesQueue = files.length

      let errors = 0

      // iterate over the list of files to be uploaded.
      _.forOwn(files, (file) => {
        const fileNameSplit = file.name.split('.')

        Utils.toBase64(file)
          .then((fileString) => {
            return {
              type: 'asset',
              extension: `.${fileNameSplit[fileNameSplit.length - 1]}`,
              fileData: fileString,
              fileName: `${file.name.replace('.' + fileNameSplit[fileNameSplit.length - 1], '')}`
            }
          })
          .then(Api.uploadFile)
          .then((newFile) => {
            // add the new file data to the creative.assetFiles array
            self.creative.assetFiles.push(newFile)

            // one less from the Queue
            filesQueue--

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // turn off loading spinner
              self.loading = false

              if (errors) {
                alert(
                  'It appears that there are some files we could not upload. Please try again.'
                )
              }
            }
          })
          .catch((error) => {
            // one less from the Queue
            filesQueue--

            errors++

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // turn off loading spinner
              self.loading = false

              if (errors) {
                alert(
                  'It appears that there are some files we could not upload. Please try again.'
                )
              }
            }

            self.$log.error('uploadAssets', error)
          })
      })
    },
    viewAsset: function (assetFile) {
      // open file url in a new window
      window.open(assetFile.path, '_blank')
    },
    downloadAsset: function (assetFile) {
      const link = document.createElement('a')
      link.download = assetFile.name
      link.target = '_blank'
      link.href = assetFile.path
      link.click()
    },
    removeAsset: function (assetFile) {
      const self = this

      if (
        confirm(
          'This will remove the file permanently. Are you sure you want to remove this file?'
        )
      ) {
        Api.deleteFile(assetFile.id)
          .then(() => {
            // before anything, let's store the current state of the assetFile in case we want to undo.. so...
            // let's looks for the asset in the undoBucket.
            const wasUndone = _.findIndex(self.undoBucket, { id: assetFile.id })

            // if we had already stored a previous state...
            if (wasUndone != -1) {
              // ...let's store the current state instead.
              self.undoBucket.splice(wasUndone, 1, assetFile)
            } else {
              // otherwise let's just store it's current state (clone deep so we are not creating a reference)
              self.undoBucket.push(_.cloneDeep(assetFile))
            }

            // now, let's stage file to be removed on save.
            // find index of the asset file to be deleted, in the creatives.assetFiles
            const index = _.findIndex(self.creative.assetFiles, {
              id: assetFile.id
            })

            // create an assetFile with status removed so it is removed when saved
            const removedFile = assetFile
            removedFile.status = 'removed'

            // replace the assetFile in the creative.assetFiles with the removedFile element
            self.creative.assetFiles.splice(index, 1, removedFile)
          })
          .catch((error) => {
            self.$log.error(error)
            alert('There was an issue deleting the image. Please try again')
          })
      }
    },
    undoRemoveAsset: function (assetFile) {
      const self = this

      // retrieve the previous state's index in the undoBucket. This to do a splice later.
      const indexOnUndoBucket = _.findIndex(self.undoBucket, {
        id: assetFile.id
      })

      // retrieve the actual previous state. This to replace the current state with this one.
      const onUndoBucket = _.find(self.undoBucket, { id: assetFile.id })

      // find index of the asset file to delete in the creatives.assetFiles
      const index = _.findIndex(self.creative.assetFiles, { id: assetFile.id })

      // replace the current state of the assetFile in the creative.assetFiles with the state in the undoBucket
      self.creative.assetFiles.splice(index, 1, onUndoBucket)

      // remove the previous state from the undo bucket
      self.undoBucket.splice(indexOnUndoBucket, 1)
    },
    isPrevSection: function (toSection) {
      const self = this

      // if we are attempting to go to a section
      if (toSection) {
        // get currentSection index
        const currentSectionIndex
          = self.sections.indexOf(self.currentSection) + 1

        // get toSection index
        const toSectionIndex = self.sections.indexOf(toSection) + 1

        // if the toSection index is < than the currentSection,
        // then the validation is omited (all required fields will not be required on this case)
        // so return the comparison result;
        return toSectionIndex < currentSectionIndex
      } else {
        // otherwise just make it false so validation stays in place
        return false
      }
    },
    updateValues: function (dates) {
      this.campaignStartDateInput = this.$moment(new Date(dates.startDate))
        .utc()
        .format('YYYY-MM-DD')
      this.campaignEndDateInput = this.$moment(new Date(dates.endDate))
        .utc()
        .format('YYYY-MM-DD')
      this.showDate = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';

.live-badge {
  position: absolute;
  margin-top: -16px;
  margin-left: 0;
  width: 1.8em;
  height: 2em;
  background-image: url("data:image/svg+xml,%3Csvg width='29' height='18' viewBox='0 0 100 40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='0' width='100' height='50' rx='10' fill='%2304AA6D'/%3E%3Ctext x='50.5' y='27' fill='%23fff' font-size='28' font-family='Arial, sans-serif' font-weight='500' letter-spacing='2px' text-anchor='middle' alignment-baseline='central'%3ELIVE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  animation: blink 1.5s linear infinite;
}
@keyframes blink {
  50% {
    opacity: 0.3;
  }
}
</style>
