<script setup>
import { computed } from 'vue'
import { useRouter } from '@/router/index.js'

const props = defineProps({
  creativeData: {
    type: Object,
    required: true
  }
})

const router = useRouter()

const infoFields = computed(() => {
  return [
    {
      key: 'Creative Id',
      value: props.creativeData.id
    },
    {
      key: 'Creative Name',
      value: props.creativeData.name
    },
    {
      key: 'Campaign Id',
      value: props.creativeData.campaign_id,
      clickable: true,
      onClick: () => goToCampaign()
    },
    {
      key: 'Campaign Name',
      value: props.creativeData.campaign_name
    },
    {
      key: 'Format',
      value: props.creativeData.formatName
    }
  ]
})

const goToCampaign = () => {
  router.push(`/creative-production/creatives/campaign/${props.creativeData.campaign_id}`)
}

const copyUrl = () => {
  navigator.clipboard.writeText(window.location.href)
}
</script>

<template>
  <div class="h-100 d-flex flex-column">
    <!-- info section -->
    <div class="info-section p-4 text-white">
      <div
        v-for="(field, idx) in infoFields"
        :key="idx"
        class="w-100 d-flex my-4"
      >
        <div class="w-50">{{ field.key }}</div>
        <div
          v-if="field.clickable"
          @click="() => field.onClick()"
          class="w-50"
        >
          <span class="clickable text-truncate">
            {{ field.value }}
          </span>
        </div>
        <div
          v-else
          class="w-50 text-truncate"
        >
          {{ field.value }}
        </div>
      </div>
    </div>

    <!-- QR section -->
    <div class="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
      <div
        v-if="creativeData.qr_code"
        class="real-qr mb-3"
      >
        <img
          :src="creativeData.qr_code"
          class="h-100 w-100"
        />
      </div>
      <div class="text-center text-white">Scan QR code to<br/>preview on mobile</div>
      <div
        @click="copyUrl"
        class="copy-url-button my-4 d-flex justify-content-center align-items-center"
      >
        Copy URL
      </div>
    </div>
  </div>
</template>

<style scoped>
.info-section {
  border-bottom: 1px solid #464646;
  min-height: 25%;
}
.real-qr {
  width: 50%;
  aspect-ratio: 1;
  border-radius: 10px;
  overflow: hidden;
}
.clickable:hover {
  cursor: pointer;
  color: #ddd;
  text-decoration: underline;
}
.copy-url-button {
  width: 30%;
  color: #464646;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.copy-url-button:hover {
  background-color: #ddd;
}
</style>
