<template>
    <div class="product-info">
        <div class="product-header">
            <div class="product-title">{{ title }}</div>
            <div class="product-circle">
                <transition name="bounce">
                    <font-awesome-icon v-show="selected" icon="check" class="i" />
                </transition>
            </div>
        </div>
        <div class="product-body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
import Utils from '@/helpers/Utils.js'

export default {
  name: 'ProductInfo',
  props: {
    title: null,
    selected: false
  }
}
</script>

<style lang="scss">
    @import "@/scss/common.scss";

    .isSelected{
        // border: 2px solid $primary1;
        pointer-events: none;

        .product-header{
            .product-circle{
                background: $green9;
            }
        }
    }

    .product-info{

    }

    .product-header{
        display: flex;
        justify-content: space-between;
        background: $primary1;
        padding:$spacing-unit;

        .product-title{
            color:white;
            font-size: 1.7rem;
        }

        .product-circle{
            width: 28px;
            height: 28px;
            min-width: 28px;
            min-height: 28px;
            border-radius: 50%;
            position: relative;
            float: right;
            border: 3px solid $green9;
            background: white;

            .i{
                width: 24px;
                height: 24px;
                color: white;
                position: absolute;
                top:0;
                bottom: 6px;
                left:3px;
                right:0;
                margin: auto;
            }
        }
    }

    .product-body{
        color: $gray8;
        padding: $spacing-unit;

        ul{
            padding-left: $spacing-unit;
        }
    }

    .question{
        pointer-events: auto;
    }

    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        // animation: bounce-in .5s reverse;
        animation: bounce-out .5s;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes bounce-out {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

</style>
