<template>

    <div class="appFrame">

        <Navbar :currentModulePath="'analytics'" :currentModuleName="'Analytics'" />

        <main class="moduleFrame moduleFrame--tagManagerRepository">

          <PencilBar />

          <ModuleHeader>
            <ul slot="tabs">
              <li><router-link to="/analytics/campaigns">Campaigns</router-link></li>
              <li selected><router-link to="/analytics/productPerformance">Product Performance</router-link></li>
              <!-- <li><router-link to="/tag-manager/campaigns/inactive">Inactive Campaigns</router-link></li> -->
            </ul>
            <div slot="actions">
              <button class='moduleHeader__createButton mr-3' @click.prevent="openGenerateCSV"><font-awesome-icon icon="table" class="i" />Generate CSV</button>
            </div>
          </ModuleHeader>
          <section class="moduleContent">
              <div class="container-fluid p-0">
                  <div class="col-sm-12">
                      <div class="row pb-5">
                            <div class="d-inline">
                                <datepicker v-model="startDate" ref="startDateOpen" placeholder="Start Date"></datepicker>
                            </div>
                            <div class="d-inline px-2 pt-2">
                                <font-awesome-icon @click="openStartDate" class="calendar-button-icon" icon="calendar-alt"/>
                            </div>
                            <div class="d-inline">
                                <datepicker v-model="endDate" ref="endDateOpen" placeholder="End Date"></datepicker>
                            </div>
                            <div class="d-inline px-2 pt-2">
                                <font-awesome-icon @click="openEndDate" class="calendar-button-icon" icon="calendar-alt"/>
                            </div>
                            <div class="d-inline px-2">
                                <select class="custom-select" v-model="primaryKpi">
                                  <option value="">Primary KPI</option>
                                  <option v-for="(kpi, index) in kpiValues" :key="index" v-bind:value="kpi">{{kpi}}</option>
                                </select>
                            </div>
                            <div class="d-inline px-2">
                                <select class="custom-select" v-model="secondaryKpi">
                                  <option value="">Secondary KPI</option>
                                  <option v-for="(kpi, index) in kpiValues" :key="index" v-bind:value="kpi">{{kpi}}</option>
                                </select>
                            </div>
                          <!-- DROPDOWN PRIMARY AND SECONDARY KPI ARE THE SELECT TITLES CONTAINING OWN OPTIONS SAME FOR BOTH -->
                          <!-- Simpli and 3P  -->
                            <!--<div class="d-inline px-2" v-if="!showAwareness">-->
                                <!--<select class="custom-select" v-model="productId">-->
                                  <!--<option value="">Product Name</option>-->
                                  <!--<option v-for="product in viewFilterData.products" v-bind:value="product.productId">{{product.productName}}</option>-->
                                <!--</select>-->
                            <!--</div>-->
                            <!-- <div class="d-inline px-2">
                                <select class="custom-select" v-model="creativeId">
                                  <option value="">Creative Name</option>
                                  <option v-for="(creative, index) in viewFilterData.creatives" :key="index" v-bind:value="creative.creativeId">{{creative.creativeName}}</option>
                                </select>
                            </div> -->
                            <div class="d-inline px-2">
                                <button type="button" class="apply-button" @click="getAdProductDatabase">Apply</button>
                            </div>
                        </div>
                      <div class="row overflow-x-scroll">
                          <table class="table table-bordered">
                            <thead>
                                <tr>
                                  <th scope="col" @click="sortTable(name)" v-for="(name, index) in adProductDatabaseValue.columnNames" :key="index" v-bind:class="index == 0 ? 'first-column' : 'other-column'">{{name}} <span><font-awesome-icon v-if="sortBy == name && direction == 'asc'" class="calendar-button-icon" icon="angle-down"/><font-awesome-icon v-if="sortBy == name && direction == 'desc'" class="calendar-button-icon" icon="angle-up"/></span></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(value, index) in adProductDatabaseValue.tableData" :key="index">
                                  <td v-for="(item, name, index) in value" v-bind:class="index == 0 ? 'first-column' : ''" :key="index">{{item}}</td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </section>
          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            :advertiserId="modalData.advertiserId"
            :advertiserName="modalData.advertiserName"
            @done="hideModal()"
           />
        </b-modal>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import ProfileModal from '@/views/modals/RepoCampaignProfile.vue'
import WebLinks from '@/components/WebLinks.vue'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'
import MetaData from '@/components/MetaData'
import Datepicker from 'vuejs-datepicker'
import GenerateCSV from '@/views/modals/GenerateCSV'
import GenerateAdProductCSV from '@/views/modals/GenerateAdProductCSV'

export default {
  name: 'AnalyticsRepositoryCampaigns',
  components: {
    Navbar, MetaData, Datepicker, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks, ProfileModal, GenerateAdProductCSV
  },
  data () {
    return {
      staticBModal: true,
      primaryKpi: '',
      secondaryKpi: '',
      sortBy: null,
      direction: 'asc',
      viewFilterData: {},
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)).getTime(),
      endDate: new Date().getTime(),
      adProductDatabaseValue: {},
      adProductDatabaseFiltersValue: { 1: 'Drive Store / Locator ER', 3: 'Site Visits / CTR', 5: 'Viewability', 2: 'Engagement / Awareness', 4: 'Video / VCR' },
      mobileFiltersExpanded: false,
      kpiValues: [],
      // kpiValues: [
      //     'Simpli Engagement Rate',
      //     'Simpli CTR',
      //     'Simpli Video Completion Rate',
      //     '3P Viewability',
      //     'Simpli Viewability'
      // ],
      filterModuleData: {
        search: '',
        filterValues: {
          advertiser: 'All Advertisers',
          deal_type: 'All Deal types',
          kpi: 'All KPI',
          status: 'All Status'
        },
        filterDefaults: {
          advertiser: 'All Advertisers',
          deal_type: 'All Deal types',
          kpi: 'All KPI',
          status: 'All Status'
        },
        filterOptions: {
          advertiser: [],
          deal_type: [],
          kpi: [],
          status: []
        }
      },
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          }
        ]
      },
      modalData: {
        currentComponent: null,
        itemId: null,
        advertiserId: null,
        advertiserName: null
      },
      itemsSelected: {},
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'Campaign Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openCampaignSettings
        },
        {
          ico: 'trash-alt',
          text: 'Delete Campaign',
          clickAction: this.deleteCampaign
        }
      ],
      contextualMenuExpanded: false,
      selectAll: false
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'fetch',
      'delete'
    ]),
    ...mapActions('analytics', {
      fetchAdProductDatabase: 'fetchAdProductDatabase',
      fetchAdProductDatabaseFilters: 'fetchAdProductDatabaseFilters'
    }),
    ...mapActions([
      'registerModalOpened',
      'registerModalClosed'
    ]),
    sortTable: function (name) {
      this.sortBy = name
      if (this.direction == 'asc') {
        this.direction = 'desc'
      } else {
        this.direction = 'asc'
      }
      this.getAdProductDatabase()
    },
    openStartDate: function () {
      this.$refs.startDateOpen.showCalendar()
    },
    openEndDate: function () {
      this.$refs.endDateOpen.showCalendar()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()

      self.modalData.advertiserId = null
      self.modalData.advertiserName = null
    },
    populateFilters: function () {
      const self = this

      // set status based on active
      self.list.map(item => {
        item.status = (item.active === 1) ? 'Active' : 'Inactive'
        return item
      })

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.list, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, param => {
        filterOptions[param].unshift(self.filterModuleData.filterDefaults[param])
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    },
    openGenerateCSV: function () {
      const self = this
      // set the modal item id to the userId we want to update
      // self.modalData.itemId = itemId;

      // for opening fallbacks directly
      // self.modalData.currentSectionProp = currentSection;

      // self.$log.info('Campaign Id', itemId, this.listCampaign);

      // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCampaign
      // let _advertiser = this.advertiserById(this.campaign.advertiser_id);

      // self.modalData.advertiserId = _advertiser.id;
      // self.modalData.advertiserName = _advertiser.name;

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      // self.modalData.campaignId = this.$route.params.id;
      self.modalData.currentComponent = GenerateAdProductCSV
      this.isModalFullWidth = false

      // show modal
      self.$refs.moduleModal.show()
    },
    getAdProductDatabase: function () {
      this.$store.dispatch('analytics/fetchAdProductDatabase', { startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), primaryKPI: this.primaryKpi, secondaryKPI: this.secondaryKpi, sortBy: this.sortBy, direction: this.direction })
    }
  },
  computed: {
    ...mapGetters('campaigns', [
      'list',
      'itemById'
    ]),
    ...mapGetters('analytics', {
      adProductDatabase: 'adProductDatabase',
      adProductDatabaseFilters: 'adProductDatabaseFilters'
    }),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById'
    }),
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validItemsSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.itemsSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    filteredItems: function () {
      const self = this
      const filteredList = self.list.filter(item => item.active === 1)

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(filteredList, self.filterModuleData.search)

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(self.filterModuleData.filterValues, (filter, key) => {
        // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
        const filterOption = _.get(self.filterModuleData.filterOptions, '[' + key + '][0]', false)

        // if filterOption is not false and the filter option is not the first option
        return filterOption && filter != filterOption
      })

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(filteredItems, [self.tableDataOptions.orderBy], [self.tableDataOptions.order])

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(self.filteredItems, self.pagination.currentPage, self.pagination.perPage)
    }
  },
  created () {
    const self = this

    self.fetch()
  },
  watch: {
    list: function () {
      const self = this
      self.populateFilters()
    },
    adProductDatabase: function (val) {
      this.adProductDatabaseValue = val
    },
    adProductDatabaseFilters: function (val) {
      this.adProductDatabaseFiltersValue = val
      this.kpiValues = []
      for (const key in val) {
        this.kpiValues.push(val[key])
      }
    }
  },
  mounted () {
    const self = this

    self.fetchAdProductDatabase({ startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), primaryKPI: this.primaryKpi, secondaryKPI: this.secondaryKpi })
      .then(response => {
        self.$log.info(response)
        self.adProductDatabaseValue = response
      })

    self.fetchAdProductDatabaseFilters({ startDate: new Date(this.startDate).getTime(), endDate: new Date(this.endDate).getTime(), primaryKPI: this.primaryKpi, secondaryKPI: this.secondaryKpi })
      .then(response => {
        self.$log.info(response)
        self.adProductDatabaseFiltersValue = response
        console.log(response)
      })
  }
}
</script>
<style lang="scss">
  @import "@/scss/common.scss";

  .card-border {
      box-shadow: -5px 5px 20px #0000002B;
      border-radius: 10px;
  }

  .nav-button-selected {
      height: 42px;
      width: 192px;
      background-color: #2F9B8C;
      color: #ffffff;
      text-transform: uppercase;
      font: Bold 14px/22px Montserrat;
      border: none;
      cursor: pointer;
  }

  .nav-button {
      height: 42px;
      width: 192px;
      background-color: #EFEFEF;
      color: #5C5C5C;
      text-transform: uppercase;
      font: Bold 14px/22px Montserrat;
      border: none;
      cursor: pointer;
  }

  .apply-button {
      height: 31px;
      width: 150px;
      background-color: $green4;
      color: #ffffff;
      text-transform: capitalize;
      font: Bold 18px/22px Montserrat;
      border: none;
      cursor: pointer;

      border-radius: 4px;
      border:0px;
      font-size: 1.2rem;
      background: $green4;
      text-transform: uppercase;
  }

  .backgroundDarker{
    background: $gray2 !important;
  }

    .custom-select {
        width: 175px;
        height: 31px;
    }

    .vdp-datepicker {
        width: 206px;
        height: 31px;
    }

    .vdp-datepicker input {
        width: 206px;
        height: 31px;
        padding-left: 10px;
    }

    .first-column {
        background-color: #2F9B8C !important;
        color: #ffffff;
    }

    .other-column {
        background-color: #ebebeb;
    }

    th.first-column {
        // border: none !important;
        border-color: $green3 !important;
        width: 92px !important;
    }

    .table th, .table td {
        padding: 1.4rem 0.75rem !important;
    }

    tbody tr:nth-child(2n) td{
        background: $gray1;
    }

    .calendar-button-icon {
        cursor: pointer;
        color: #888888;
        font-size: 13px;
    }

    .video-performance-heading {
        text-align: left;
        font: Bold 16px/19px Montserrat;
        letter-spacing: 0;
        color: #5C5C5C;
        text-transform: uppercase;
    }

    .title[data-v-7a60cfff] {
        font-size: 11px;
        color: $gray6;
        margin-left: 0;
    }

    .subValue[data-v-7a60cfff] {
        font-size: 11px;
        color: #8a8a8a;
        font-weight: normal;
    }

    .value[data-v-7a60cfff]{
        display: block !important;
        font-size: 40px;
    }

    .data-labels[data-v-8882235c]{
        // text-align: right;
        // width: 100%;
        color: $green4;
        // padding-right: 0;
        text-transform: uppercase;
        font-size: 11px !important;
    }

    .col-3 select, .col-2 select{
        height: 30px;
    }

    .card-border *{
        font-family: Montserrat !important;
    }

    .overflow-x-scroll{
      overflow-x: scroll;
    }

</style>
