<template>
  <section class="sModal">
    <Preloader :in-modal="true"></Preloader>

    <header
      v-if="itemId != null"
      class="sModal__header"
    >
      Placement Settings
    </header>
    <header
      v-else
      class="sModal__header"
    >
      New Placement
    </header>

    <form @submit.prevent="formSubmit">
      <nav class="sModal__tabs">
        <ul>
          <!--
            Workaround:
            Each tab requires validations. I wanted to use the native validation. So...
            I am using buttons for the tabs so it triggers the form submittion. This also changes the toSection var.
            The idea is that when the formSubmit method is called it checks against the toSection var, if it has value then
            it will change currentSection to toSection value, so the tab changes. If the form is submitted with
            no toSection value (e.g. when the save campaign button is hit), then it will just call saveData.
          -->
          <li :selected="currentSection == 'details'">
            <button @click="gotoSection('details')">Details</button>
          </li>
          <li
            v-if="isAirtorySync || (isPadSquadSync && itemId !== null)"
            :selected="currentSection == 'trackers'"
          >
            <button @click="gotoSection('trackers')">Trackers</button>
          </li>
        </ul>
      </nav>

      <div
        v-if="currentSection == 'details'"
        class="sModal__body"
      >
        <FormField
          v-if="campaignIsCeltraSync != false"
          label="Creative Tag Source *"
          for-id="campaign-source"
          type="select"
        >
          <select
            id="campaign-source"
            v-model="placement.placement_source"
            :disabled="itemId !== null"
          >
            <option
              value="null"
              selected
              disabled
            >
              Select a Source
            </option>
            <option
              v-for="(campaignSource, index) in campaignSources"
              :key="index"
              :value="campaignSource.name"
            >
              {{ campaignSource.name }}
            </option>
          </select>
        </FormField>
        <FormField
          v-else
          label="Creative Tag Source *"
          for-id="campaign-source"
          class=""
        >
          <input
            id="campaign-source"
            v-model="placement.placement_source"
            value="Manual"
            type="text"
            maxlength="50"
            disabled
          />
        </FormField>

        <div
          v-if="placement.placement_source == 'Celtra Sync'"
          class="formFieldGroup"
        >
          <div
            class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
          >
            <FormField
              label="Celtra Placement Id  *"
              for-id="celtra-id"
              class=""
            >
              <input
                id="celtra-id"
                v-model="placement.external_placement_id"
                type="text"
                maxlength="50"
                required
                :disabled="itemId !== null"
              />
            </FormField>

            <FormField>
              <button
                id="celtra-sync-button"
                :disabled="
                  placement.external_placement_id !== undefined &&
                  placement.external_placement_id < 4
                "
                @click.prevent="syncPlacementDetails"
              >
                <span
                  ><font-awesome-icon
                    icon="sync-alt"
                    class="i" /></span
                >FETCH PLACEMENT DETAILS
              </button>
            </FormField>
          </div>
          <small
            >You have to hit the ’Save Placement’ button in order for synced
            details to be saved.</small
          >
        </div>

        <FormField
          v-if="isAirtorySync"
          label="Airtory Placement Id  *"
          for-id="airtory-id"
          class=""
        >
          <div class="ratioInputs">
            <div class="mb1 mr1">
              <input
                id="new_placement"
                v-model="typeOfData"
                type="radio"
                name="placementNew"
                value="newPlacement"
                :disabled="itemId !== null"
              />
              New Placement
            </div>
            <div class="mb1">
              <input
                id="not_new_placement"
                v-model="typeOfData"
                type="radio"
                name="placementNew"
                value="syncPlacement"
                :disabled="itemId !== null"
              />
              Placement Id:
            </div>
          </div>

          <div class="formFieldGroup">
            <div
              class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
            >
              <FormField
                label=""
                for-id="airtory-id"
              >
                <input
                  id="airtory-id"
                  v-model="placement.external_placement_id"
                  type="text"
                  maxlength="50"
                  :disabled="
                    (isAirtorySync && !isSyncPlacement) || itemId !== null
                  "
                  required
                />
              </FormField>
              <!-- <FormField>
                    <button @click.prevent="syncPlacementDetails" id="airtory-sync-button" :disabled="placement.external_placement_id !== null && placement.external_placement_id.length < 4 && isSyncPlacement || isAirtorySync && !isSyncPlacement"><span><font-awesome-icon icon="sync-alt" class="i" /></span>FETCH PLACEMENT DETAILS</button>
                  </FormField> -->
            </div>
            <!-- <small>You have to hit the ’Save Placement’ button in order for synced details to be saved.</small> -->
            <small>Id of the placement on Airtory.</small>
          </div>
        </FormField>

        <FormField
          label="Placement Name *"
          for-id="placment-name"
          :is-celtra="isCeltraSync"
          :is-airtory="isAirtorySync && isSyncPlacement"
        >
          <input
            id="placment-name"
            v-model="placement.name"
            type="text"
            required
          />
          <p
            v-if="placementLength <= 1"
            class="required-message"
          >
            <small
              >The NAME field must be at least 2 characters in length.
            </small>
          </p>
        </FormField>

        <FormField
          label="Xandr Curated Deal ID"
          for-id="curated-deal-id"
        >
          <input
            id="curated-deal-id"
            v-model="placement.xandr_curated_deal_id"
            type="text"
            maxlength="50"
          />
        </FormField>

        <FormField
          v-if="isAirtorySync"
          label="Assigned Creative *"
          for-id="assigned-creative"
          type="select"
        >
          <select
            id="assigned-creative"
            v-model="placement.external_creative_id"
            required
            @change="changeCreative($event)"
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Creative
            </option>
            <option
              v-for="(creative, index) in creatives"
              :key="index"
              :selected="
                creative.external_creative_id === placement.external_creative_id
              "
              :value="creative.external_creative_id"
            >
              {{ creative.name }}
            </option>
          </select>
        </FormField>

        <FormField
          v-if="isPadSquadSync"
          label="Assigned Creative *"
          for-id="assigned-creative"
          type="select"
        >
          <select
            id="assigned-creative"
            v-model="placement.creative_id"
            required
            @change="changeCreative($event)"
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Creative
            </option>
            <option
              v-for="(creative, index) in creatives"
              :key="index"
              :selected="creative.id === placement.creative_id"
              :value="creative.id"
            >
              {{ creative.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Format *"
          for-id="format-type"
          type="select"
          :is-celtra="isCeltraSync"
          :is-airtory="isAirtorySync && isSyncPlacement"
        >
          <select
            id="format-type"
            v-model="placement.format_id"
            :disabled="
              isAirtorySync &&
              creative !== null &&
              (creative.name !== 'Placeholder_Creative_300x250' ||
                creative.name !== 'Placeholder_Creative_320x50' ||
                item.name === 'Placeholder_Campaign_Creative')
            "
            required
          >
            <!-- <select v-model="placement.format_id" id="format-type" required> -->
            <option
              value=""
              selected
              disabled
            >
              Select a Format
            </option>
            <option
              v-for="(product, index) in creativeFormats"
              :key="index"
              :value="product.id"
            >
              {{ product.name }}
            </option>
          </select>
        </FormField>

        <div id="sf-packages">
          <small class="label">ASSIGNED PACKAGES *</small>
          <div class="py-3">
            <multiselect
              v-if="campaign.salesforcePackages.length > 0"
              v-model="selectedPackages"
              :multiple="true"
              placeholder="Select a package"
              :show-labels="false"
              :class="
                showErrorMessage && selectedPackages.length === 0
                  ? 'multiselect-error'
                  : ''
              "
              :options="
                campaign.salesforcePackages.map((item) => item.package_id)
              "
              :custom-label="
                (opt) => {
                  return (
                    campaign.salesforcePackages.find((x) => x.package_id == opt)
                      .package_name +
                    ' - ' +
                    new Date(
                      campaign.salesforcePackages.find(
                        (x) => x.package_id == opt
                      ).package_start_date
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }) +
                    '-' +
                    new Date(
                      campaign.salesforcePackages.find(
                        (x) => x.package_id == opt
                      ).package_end_date
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })
                  )
                }
              "
              @input="onSelectPackage($event)"
            ></multiselect>
            <multiselect
              v-else
              v-model="selectedPackages"
              :disabled="true"
              :multiple="false"
              placeholder="No package available"
              :show-labels="false"
              :options="
                campaign.salesforcePackages.map((item) => item.package_id)
              "
              :custom-label="
                (opt) => {
                  return (
                    campaign.salesforcePackages.find((x) => x.package_id == opt)
                      .package_id +
                    ' - ' +
                    campaign.salesforcePackages.find((x) => x.package_id == opt)
                      .package_name
                  )
                }
              "
              @input="onSelectPackage($event)"
            ></multiselect>
            <small
              v-show="showErrorMessage && selectedPackages.length === 0"
              class="error-message"
              >* Please select a pacakage for this placement</small
            >
            <small
              v-if="
                !campaign.salesforce_id && campaign.test_campaign !== 'test'
              "
              class="l-red"
              >* Please add an Opportunity Id for this Campaign.</small
            >
          </div>
        </div>

        <FormField
          label="Measurement 3P ID"
          for-id="placement-3pId"
        >
          <input
            id="placement-3pId"
            v-model="placement.third_party_id"
            type="text"
          />
        </FormField>

        <FormField
          label="Verification 3P ID"
          for-id="campaign-verification-third-party-id"
        >
          <input
            id="campaign-verification-third-party-id"
            v-model="placement.verification_third_party_id"
            type="text"
          />
        </FormField>

        <FormField
          label="Primary KPI"
          for-id="placement-kpi"
          type="select"
        >
          <select
            id="placement-kpi"
            v-model="placement.main_kpi"
            @change="changeKPI($event)"
          >
            <option
              value="0"
              selected
            >
              No Primary KPI
            </option>
            <option
              v-for="(kpi, index) in KPIs"
              :key="index"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Creative Tag Type  *"
          for-id="creative-tag-type"
          type="select"
          :is-celtra="isCeltraSync"
          :is-airtory="isAirtorySync && isSyncPlacement"
        >
          <select
            id="creative-tag-type"
            v-model="placement.creative_tag_id"
            disabled
            required
          >
            <option
              v-for="(creativeTagType, index) in creativeTagTypes"
              :key="index"
              :value="creativeTagType.id"
            >
              {{ creativeTagType.name }}
            </option>
          </select>
        </FormField>

        <!-- PLACEMENT FALLBACK CLICK URL -->
        <FormField
          label="CLICKTHROUGH URL"
          for-id="url-id"
        >
          <div class="mb0">
            <input
              id="url-fallback"
              v-model="placement.use_fallback_clicktrough_url"
              type="checkbox"
              @change="toggleShowInputUrl"
            />
            Use Placement Fallback URL
          </div>
          <input
            v-if="showInputUrl"
            id="url-fallback-id"
            v-model="placement.fallback_clicktrough_url"
            v-validate-url
            class="mt1"
            type="text"
            placeholder="Placement Fallback URL here"
            required
          />
        </FormField>

        <FormField
          :label="isPadSquadSync ? 'Creative Tag *' : 'Placement Tag *'"
          for-id="creative-demo-tag"
          :is-celtra="isCeltraSync"
          :is-airtory="isAirtorySync && isSyncPlacement"
        >
          <button
            v-if="placement.creative_tag != ''"
            class="button button-over-div"
            @click.prevent="copyTag(placement.creative_tag)"
          >
            <font-awesome-icon
              icon="copy"
              class="i"
            />COPY TAG
          </button>
          <textarea
            id="creative-demo-tag"
            v-model="placement.creative_tag"
            rows="12"
            :disabled="isAirtorySync || isPadSquadSync"
            required
          ></textarea>
        </FormField>

        <FormField
          label="Append Scripts"
          for-id="placement-append-scripts"
        >
          <textarea
            id="placement-append-scripts"
            v-model="placement.append_script"
            rows="5"
          ></textarea>
        </FormField>
      </div>

      <!-- TRACKERS -->
      <!-- { event_id: 1, label_name: "Impression", creative_id: 23, creative_event_id: 87} -->
      <!-- { event_id: 1, label_name: "Click", creative_id: 23, creative_event_id: 88} -->
      <!-- // {
                //   tracker_id: 1, // if the placement already has trackers
                //   event_id: 1,
                //   label_name: 'Impressions',
                //   options: [{
                      creative_events_option_id: 10
                      name: "use_as_destination"
                      option_id: 1
                      option_value: "0"
                   }]

                //   creative_event_id: 87,
                //   placement_id:  49,
                //   tracker_value: "www.google.com"
                // } -->
      <div
        v-if="currentSection == 'trackers'"
        id="bodyTrackers"
        class="sModal__body"
      >
        <!-- Toggle button for trackers input size -->
        <div class="toggleContainer mb1">
          <button
            class="toggleButton"
            @click.prevent="toggleResize()"
          >
            <font-awesome-icon
              v-show="!isResize"
              icon="toggle-on"
              class="i"
            /><font-awesome-icon
              v-show="isResize"
              icon="toggle-off"
              class="i"
            />Expand Trackers on/off
          </button>
          <button
            class="toggleButton ml1"
            @click.prevent="downloadTrackers"
          >
            <font-awesome-icon
              icon="download"
              class="i"
            />
            Download Trackers
          </button>
        </div>
        <div>
          <FormField
            v-if="trackersRemovedEvents.length > 0"
            label="Trackers assigned to removed events"
            class="mt5 removed-events"
          >
            <div class="listBox">
              <ul>
                <div
                  v-for="(event, index) in trackersRemovedEvents"
                  :key="index"
                >
                  <li class="fullWidth">
                    <div class="formFieldGroup listBoxOneField noBorderBottom">
                      <div class="formFieldGroup listBox__label">
                        <FormField
                          :for-id="
                            '_name' +
                            event.label_name +
                            event.creative_event_id +
                            index
                          "
                          :description="event.label_name"
                          :tag-left="'Pixel URL'"
                        >
                          <input
                            :id="event.label_name + '__name' + index"
                            v-model="event.tracker_value"
                            type="text"
                            readonly
                            :class="{ notSaved: true }"
                            @click.prevent="copyTag(event.tracker_value)"
                          />
                          <div class="copy-event">
                            <font-awesome-icon
                              icon="copy"
                              class="copy-icon i"
                              @click.prevent="copyTag(event.tracker_value)"
                            />
                          </div>
                        </FormField>
                      </div>
                      <div class="remove-event-trash">
                        <button
                          @click.prevent="deleteRemovedEvent(index, event)"
                        >
                          <font-awesome-icon
                            icon="trash-alt"
                            class="i"
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </FormField>
        </div>

        <div
          v-for="(id, j) in typeOfEventsCreativeHas"
          :key="j"
        >
          <FormField
            :label="getEventsName(id, true)"
            class="mt5"
          >
            <div class="listBox">
              <ul>
                <span style="display: none">{{ (h = 0) }}</span>
                <!-- <draggable v-model="placement.events.standards"> -->
                <div
                  v-for="(event, index) in creative.events"
                  :key="index"
                >
                  <li
                    v-if="event.event_id === id"
                    class="fullWidth"
                  >
                    <!-- iterate over the trackers and show if there are values -->
                    <div
                      v-if="
                        lengthEventsOfThisTypeAndCreativeEventId(
                          event.event_id,
                          event.creative_event_id
                        ) > 0
                      "
                    >
                      <div
                        v-for="(item, i) in placement.trackers"
                        :key="'tracker_' + index + i + item.creative_event_id"
                      >
                        <div
                          v-if="
                            item.creative_event_id === event.creative_event_id
                          "
                          :class="[
                            isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                              i,
                              event.creative_event_id
                            )
                              ? 'listBoxOneField'
                              : 'listBoxOneField_labelArrowUp',
                            {
                              noBorderBottom:
                                lengthEventsOfThisTypeAndCreativeEventId(
                                  event.event_id,
                                  item.creative_event_id
                                ) > 1
                            }
                          ]"
                        >
                          <div
                            class="formFieldGroup listBox__label noBorderBottom"
                          >
                            <div class="formFieldGroup__fields">
                              <span style="display: none">{{ h++ }}</span>
                              {{ updateItemOrder(item, h) }}
                              <input
                                v-model="item.order"
                                type="hidden"
                              />
                              <FormField
                                :for-id="
                                  getEventsName(id, false) +
                                  '_name' +
                                  event.label_name +
                                  item.creative_event_id +
                                  index +
                                  i
                                "
                                :description="
                                  isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                    i,
                                    event.creative_event_id
                                  )
                                    ? event.label_name
                                    : ''
                                "
                                :tag-left="
                                  isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                    i,
                                    event.creative_event_id
                                  )
                                    ? 'Pixel URL'
                                    : '.'
                                "
                                :class="{
                                  labelArrowUP:
                                    !isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                      i,
                                      event.creative_event_id
                                    )
                                }"
                              >
                                <span
                                  v-if="
                                    isThisLastIndexEventsOfThisTypeAndCreativeEventId(
                                      i,
                                      item.creative_event_id
                                    ) &&
                                    lengthEventsOfThisTypeAndCreativeEventId(
                                      event.event_id,
                                      event.creative_event_id
                                    ) > 1 &&
                                    (getLowercase(item.label_name) ===
                                      'click' ||
                                      item.event_id === 2)
                                  "
                                  class="defaultLabel"
                                  >Default Tracker:</span
                                >
                                <span
                                  v-if="
                                    !isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                      i,
                                      event.creative_event_id
                                    )
                                  "
                                  class="arrowUp flipX"
                                  ><font-awesome-icon
                                    icon="level-up-alt"
                                    class="i"
                                /></span>
                                <input
                                  v-if="!isResize"
                                  :id="
                                    getEventsName(id, false) +
                                    '__name' +
                                    index +
                                    i
                                  "
                                  v-model="item.tracker_value"
                                  type="text"
                                  :required="
                                    !isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                      i,
                                      event.creative_event_id
                                    ) ||
                                    (item.tracker_value !== '' &&
                                      !passedValidation(item.tracker_value))
                                  "
                                  :class="{ notSaved: !item.saved }"
                                  :disabled="item.saved && !item.editing"
                                  rows="1"
                                  @input="resize($event)"
                                />
                                <textarea
                                  v-else
                                  :id="
                                    getEventsName(id, false) +
                                    '__name' +
                                    index +
                                    i
                                  "
                                  v-model="item.tracker_value"
                                  type="text"
                                  :required="
                                    !isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                      i,
                                      event.creative_event_id
                                    ) ||
                                    (item.tracker_value !== '' &&
                                      !passedValidation(item.tracker_value))
                                  "
                                  :class="{ notSaved: !item.saved }"
                                  :disabled="item.saved && !item.editing"
                                  rows="1"
                                  @input="resize($event)"
                                />
                              </FormField>
                            </div>
                            <div
                              v-if="
                                (item.saved &&
                                  !item.editing &&
                                  getLowercase(item.label_name) === 'click') ||
                                (item.saved &&
                                  !item.editing &&
                                  item.event_id === 2)
                              "
                              class="check_option mt-2"
                            >
                              <input
                                :id="
                                  getEventsName(id, false) +
                                  '__name' +
                                  index +
                                  i +
                                  'option'
                                "
                                :checked="getChecked(item)"
                                type="checkbox"
                                @change="optionChanged($event, item)"
                              />
                              <label
                                :for="
                                  getEventsName(id, false) +
                                  '__name' +
                                  index +
                                  i +
                                  'option'
                                "
                                >USE AS DESTINATION</label
                              >
                            </div>
                          </div>

                          <div
                            class="edit-button"
                            :class="{
                              isFirstWithDescription:
                                isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                  i,
                                  event.creative_event_id
                                )
                            }"
                            tabindex="-1"
                          >
                            <button
                              tabindex="-1"
                              @click.prevent=""
                            >
                              <font-awesome-icon
                                icon="ellipsis-h"
                                class="i"
                                tabindex="-1"
                              />
                            </button>
                          </div>

                          <div
                            class="buttons-row"
                            :class="{
                              isFirstWithDescription:
                                isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                  i,
                                  event.creative_event_id
                                )
                            }"
                            tabindex="-1"
                          >
                            <button
                              v-if="
                                (isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                  i,
                                  event.creative_event_id
                                ) &&
                                  item.saved &&
                                  !item.editing) ||
                                !isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                  i,
                                  event.creative_event_id
                                )
                              "
                              tabindex="-1"
                              @click.prevent="
                                removeEvent(
                                  i,
                                  placement.trackers,
                                  event.event_id,
                                  item.creative_event_id
                                )
                              "
                            >
                              <font-awesome-icon
                                icon="trash-alt"
                                class="i"
                              />
                            </button>
                            <button
                              v-if="item.saved && !item.editing"
                              class="button--positive"
                              @click.prevent="editTracker(item)"
                            >
                              <font-awesome-icon
                                icon="pen"
                                class="i"
                              />
                            </button>
                            <button
                              v-if="
                                isThisLastIndexEventsOfThisTypeAndCreativeEventId(
                                  i,
                                  item.creative_event_id
                                )
                              "
                              class="button--positive"
                              tabindex="-1"
                              @click.prevent="
                                addEvent(
                                  placement.trackers,
                                  event.event_id,
                                  event.label_name,
                                  event.creative_event_id,
                                  false,
                                  null,
                                  '',
                                  event.options
                                )
                              "
                            >
                              <font-awesome-icon
                                icon="plus-circle"
                                class="i"
                              />
                            </button>
                            <button
                              v-if="
                                (!item.saved &&
                                  passedValidation(item.tracker_value)) ||
                                (item.editing === true &&
                                  passedValidation(item.tracker_value))
                              "
                              :class="{
                                isFirstWithDescriptionu:
                                  isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                    i,
                                    event.creative_event_id
                                  )
                              }"
                              class="button--positive"
                              :disabled="
                                lengthEventsOfThisTypeAndCreativeEventId(
                                  event.event_id,
                                  item.creative_event_id
                                ) && !passedValidation(item.tracker_value)
                              "
                              tabindex="-1"
                              @click.prevent="saveTracker(item)"
                            >
                              <font-awesome-icon
                                icon="check"
                                class="i"
                              />
                            </button>
                          </div>

                          <div
                            v-if="
                              (!item.saved &&
                                passedValidation(item.tracker_value)) ||
                              (item.editing === true &&
                                passedValidation(item.tracker_value))
                            "
                            :class="{
                              isFirstWithDescription:
                                isThisFirstIndexEventsOfThisTypeAndCreativeEventId(
                                  i,
                                  event.creative_event_id
                                )
                            }"
                          ></div>
                          <!-- <div v-if="item.saved && isThisLastIndexEventsOfThisTypeAndCreativeEventId(i, item.creative_event_id) && passedValidation(item.tracker_value)" >
                                  <button class="button--positive" :disabled="lengthEventsOfThisTypeAndCreativeEventId(event.event_id, item.creative_event_id) && !passedValidation(item.tracker_value)" @click.prevent="addEvent(placement.trackers, event.event_id, event.label_name, event.creative_event_id)"><font-awesome-icon icon="plus-circle" class="i" /></button>
                                </div> -->
                        </div>
                      </div>
                      <!-- end of v-for tracker -->
                    </div>
                  </li>
                </div>
                <!-- </draggable> -->
                <!-- we don't showthis message if there are standards events -->
                <!-- <li v-if="!hasEventsOfThisType(1)" class="empty">
                    <div>There are no standards events attached to this placement.</div>
                  </li> -->
                <!-- </div>   -->
              </ul>
            </div>
            <small v-if="id !== 1"
              >{{ getEventsName(id, true) }} defined at creative level</small
            >
          </FormField>
        </div>
        <!-- end of v-for typeOfEventsCreativeHas -->
        <!-- Check all use as destination last item-->
        <label class="form-label">OTHER TRACKERS OPTIONS:</label>
        <div class="checkAll_container">
          <button
            class="checkAllButton"
            :disabled="
              !hasTrackerNotSaved().length > 0 ||
              campaign.status_source == 'Updating'
            "
            @click.prevent="saveAll()"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Save All New Trackers
          </button>
          <button
            class="checkAllButton"
            :disabled="
              hasUseDestination().length == 0 || hasTrackerNotSaved().length > 0
            "
            @click.prevent="toggleChecks()"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />All Clicks Use as Destination
          </button>
        </div>
        <FormField>
          <small
            ><b>Save all New Trackers:</b> Will save all unsaved
            trackers.</small
          >
          <small
            ><b>All Clicks Use as Destination:</b> Set all default trackers to
            “Use as Destination”.</small
          >
        </FormField>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button
            class="cancelButton"
            @click.prevent="cancel"
          >
            <font-awesome-icon
              icon="ban"
              class="i"
            />Cancel
          </button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Save Placement' if this not a new placement -->
          <button
            v-if="itemId != null"
            :disabled="!isDataModified || campaign.status_source == 'Updating'"
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Save Placement
          </button>

          <!-- show 'Save New Placement' if this is new placement and we are on the last screen-->
          <button
            v-if="itemId == null"
            :disabled="!isDataModified"
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Create Placement
          </button>

          <!-- <b-button size="sm" @click="showToast = !showToast">
            {{ showToast ? 'Hide' : 'Show' }} Fixed top Alert
          </b-button> -->
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/ApiTagManager.js'
import Utils from '@/helpers/Utils.js'
import Preloader from '@/components/Preloader.vue'
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PlacementProfile',
  components: {
    FormField,
    draggable,
    Preloader,
    Multiselect
  },
  props: {
    /* if this prop comes empty, the template considers this as a new item */
    itemId: null,
    campaign: null
  },
  data() {
    return {
      showToast: false,
      loading: true,
      sections: ['details', 'trackers'],
      selectedPackages: [],
      showErrorMessage: false,
      currentSection: 'details',
      placement: {
        placement_id: null,
        name: '',
        placement_source: null,
        tag_source: null,
        xandr_curated_deal_id: null,
        format_id: '',
        external_placement_id: '',
        third_party_id: '',
        main_kpi: 0,
        append_script: '',
        third_party_id: '',
        verification_third_party_id: '',
        // from campaign
        campaign_id: null,
        external_campaign_id: '',
        campaign_name: '',
        start_date: '',
        end_date: '',
        goal: '',
        publisher_name: '',
        // from creative
        creative_id: '',
        external_creative_id: '',
        creative_tag: '',
        creative_tag_id: 1,

        padsquad_product: null,
        campaign_source: null,
        modified_date: '',
        created_date: '',
        status_source: '',
        distribution: {
          mobile: { b320x50: false, b300x250: false },
          desktop: {
            b300x250: false,
            b728x90: false,
            b728x250: false,
            b970x250: false
          }
        },
        // WE CREATE THE MAIN FIELDS FROM THE EVENTS OF THE CREATIVE,
        // BUT WE ITERATE OVER THE TRACKERS (THAT WILL STORE THE VALUES) TO SEE IF THERE ARE ANY EVENT_ID VALUE

        // if the placement is new placement, trackers will be pull,
        // and we need to add trackers from the assigned creative from Creative Production
        trackers: [
          // {
          //   tracker_id: 1, // if the placement already has trackers
          //   event_id: 1,
          //   label_name: 'Impression',
          //   placement_id:  49,
          //   creative_event_id: 87,
          //   tracker_value: "www.google.com",
          //   options:[ {
          //     creative_events_option_id: 10
          //     name: "use_as_destination"
          //     option_id: 1
          //     option_value: "0"
          //  } ]
          // }
        ],
        // {
        //     event_id: 1,
        //     creative_id: 2,
        //     label_name: 34,
        //     options:[ {
        //     creative_events_option_id: 10
        //     name: "use_as_destination"
        //     option_id: 1
        //     option_value: "0"
        //  } ]
        // }

        // if Impression event id is 87, we'll need to repeat 87 for each url added  [ {87}, {87}, {34} ]
        // [{
        //   "placement_id":  49,
        //   "creative_event_id": 87, // id of the created event,
        //   "tracker_value": "testclaudio" // url
        // }]
        assigned_packages: [],
        use_fallback_clicktrough_url: false,
        fallback_clicktrough_url: ''
      },
      // Assigned creative to placement
      // placements does not have events,
      // these are pull from the assigned creative, only the first time
      // events does not have value
      //
      creative: null,
      // creative: {
      //   events: [
      //     // events standards 1
      //     { id:1, event_id: 1, label_name: "impression", creative_id: 23, creative_event_id: 87},
      //     { id:1, event_id: 1, label_name: "click", creative_id: 23, creative_event_id: 88},
      //     // clicks 2
      //     { event_id: 2, label_name: "exit_url1", creative_id: 23, creative_event_id: 96},
      //     { event_id: 2, label_name: "exit_url2", creative_id:2, creative_event_id: 89},
      //     // events customs 3
      //     { event_id: 3, label_name: "CustomEvent1", creative_id: 23, creative_event_id: 78},
      //     { event_id: 3, label_name: "CustomEvent2", creative_id: 23, creative_event_id: 65}
      //   ]
      // },
      showInputUrl: false,
      creatives: [],
      synced: false,
      typeOfData: 'newPlacement',
      previewSelectedCreativeId: '',
      trackersRemovedEvents: [],
      isResize: false,
      salesforcePackages: [],
      trackers_count: 0
    }
  },
  methods: {
    ...mapActions('placements', [
      'read', // placement by id
      'update',
      'create',
      'sync',
      'fetchCeltra',
      'fetchAirtoryPlacement',
      'addPlacementTrackers',
      'removePlacementTrackers',
      'addPlacementOptionTrackers',
      'updatePlacementOptionTrackers'
    ]),
    ...mapActions(['registerTask', 'completeTask', 'fetchKPIs']),
    ...mapActions('creatives', ['fetchById']),
    ...mapActions('campaigns', {
      fetchCampaignById: 'fetchById',
      updateCreativeCampaign: 'updateCreativeCampaign',
      getCampaign: 'fetchByIdNoPlacements'
    }),
    setTrackerCount(item) {
      if (item.tracker_value?.length > 0) {
        this.trackers_count = this.trackers_count + 1
        return this.trackers_count
      }
    },
    updateItemOrder(item, index) {
      this.$set(item, 'order', index)
      this.$log.info('updateItemOrder', item, index)
    },
    resize(e) {
      if (e.target) {
        // this.$log.info('RESIZE', e.target.scrollHeight);
        e.target.style.height = '37px'
        e.target.style.height = `${e.target.scrollHeight}px`
      }
    },
    validatePackage() {
      if (this.isRequired) {
        this.showErrorMessage = true
        return false
      }
      this.showErrorMessage = false
      return true
    },
    getLowercase(str) {
      return String(str).toLowerCase()
    },
    getChecked(item) {
      if (item.options && item.options[0]) {
        return item.options[0].option_value === '1'
      } else {
        return false
      }
    },
    onSelectPackage: function (selected) {
      this.$log.info(this.placement.assigned_packages, this.selectedPackages)
    },
    optionChanged: async function (e, item, i) {
      try {
        if (item.id && item.value !== '') {
          this.$log.info('t', item)
          // if(item.editing === true) {
          // await this.removePlacementOptionTrackers({id: this.placement.id, trackers:[item]});
          // item.id = null;
          // }
          const asyncRes = await Promise.all(
            this.placement.trackers.map(async (el) => {
              // for addPlacementOptionTrackers data
              // await (el.options[0].tracker_id = el.id)
              // await (el.options[0].id = el.options[0].creative_events_option_id)
              if (
                el.event_id === item.event_id
                && el.id !== item.id
                && el.creative_event_id === item.creative_event_id
              ) {
                if (el.options[0].option_value === '1') {
                  this.$log.info('update to 0', el.options[0])
                  await (el.options[0].option_value = '0')
                  await (el.options[0].active = 0)

                  const response = await this.updatePlacementOptionTrackers({
                    id: this.placement.id,
                    options: [el.options[0]]
                  })
                  // option tracker added
                  // this.$log.info('OPTION TRACKER', response);
                }
              }
            })
          )

          const saved_trackers_with_option_click = _.filter(
            this.getSavedTrackers(),
            function (o) {
              return o.label_name == 'click' || o.event_id == 2
            }
          )
          const saved_item = saved_trackers_with_option_click[i]
            ? saved_trackers_with_option_click[i]
            : item

          // select / deselect the item clicked
          if (item.saved == true && item.options[0]) {
            if (item.options[0].option_value === '0') {
              this.$log.info('save to 1', item.options[0])
              // Save Option Tracker on DB
              await (item.options[0].option_value = '1')
              await (item.options[0].active = 1)
              const response = await this.addPlacementOptionTrackers({
                id: this.placement.id,
                options: [item.options[0]]
              })
              this.$log.info('saved option', response)
              // assign new id
              item.options[0].id = response.id
            } else {
              // Update Option Tracker on DB
              await (item.options[0].option_value = '0')
              await (item.options[0].active = 0)
              const response = await this.updatePlacementOptionTrackers({
                id: this.placement.id,
                options: [item.options[0]]
              })
            }
          }
          // force component update for the checkboxes
          this.$forceUpdate()
        }
      } catch (error) {
        console.log('error t', error)
        // alert('There was an error adding the option tracker');
        this.notificationAlert(
          'There was an error adding the option tracker',
          '',
          'error'
        )
      }
    },
    fetchCreative: async function (creativeId, preserveTrackers = false) {
      const self = this

      try {
        let creative
        // Determine how to find the creative based on isPadSquadSync
        if (this.isPadSquadSync) {
          // Find by internal ID if isPadSquadSync is true
          creative = this.creatives.find((item) => item.id == creativeId)
        } else {
          // Find by external ID otherwise
          creative = this.creatives.find(
            (item) => item.external_creative_id === creativeId
          )
        }
        self.$log.info('CREATIVE FOUND', creative)

        if (!creative) {
          throw new Error('Creative not found')
        }

        const data = await self.fetchById(creative.id)
        self.$log.info('AWAIT', data)
        self.creative = data

        // Set the creative details on the placement
        this.placement.format_id = this.creative.format_id
        this.placement.main_kpi = this.creative.main_kpi_id
        this.placement.creative_id = this.creative.id
        this.placement.creative_tag = this.creative.creative_tag

        if (this.campaign.campaign_source === 'PadSquad') {
          this.updatePlacementDimensionsByCreativeData(self.creative)
        }

        return self.creative
      } catch (error) {
        self.$log.info(error)
        // Handle the error appropriately
      }
    },
    updatePlacementDimensionsByCreativeData: function (creative) {
      const adSize
        = creative.adSize !== 'Custom'
          ? creative.adSize
          : creative.customDimensions
      const dimens = this.parseAdSize(adSize)
      this.placement.width = dimens.width
      this.placement.height = dimens.height
    },
    parseAdSize: function (adSize) {
      const splitAdSize = adSize.split(' ')
      return {
        width: parseInt(splitAdSize[0]) || '',
        height: parseInt(splitAdSize[2]) || ''
      }
    },
    // alert the user if click on save placement that there's trackers not saved
    hasTrackerNotSaved: function (eventId) {
      const trackers = []

      if (this.placement.trackers) {
        this.placement.trackers.map((item) => {
          if (
            (item.saved === false
              && this.passedValidation(item.tracker_value))
            || (item.editing && this.passedValidation(item.tracker_value))
          ) {
            trackers.push(item)
          }
        })
      }

      return trackers
    },
    hasUseDestination: function (eventId) {
      const trackers = []

      if (this.placement.trackers) {
        this.placement.trackers.map((item) => {
          if (
            (this.getLowercase(item.label_name) === 'click'
              || item.event_id === 2)
            && item.saved
          ) {
            trackers.push(item)
          }
        })
      }
      return trackers
    },
    toggleResize: function () {
      this.isResize = !this.isResize
    },
    toggleChecks: function () {
      const self = this
      const checkeds = []
      const uncheckeds = []
      const itemclicks = 0

      this.placement.trackers.map(async (item, i) => {
        if (
          (this.getLowercase(item.label_name) === 'click'
            || item.event_id === 2)
          && this.isThisLastIndexEventsOfThisTypeAndCreativeEventId(
            i,
            item.creative_event_id
          )
          && item.saved == true
        ) {
          if (!this.getChecked(item)) {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            await self.optionChanged(null, item, i)
          }
        }
      })
    },
    changeKPI: async function (e) {
      // parse to int e.target.value
      this.placement.main_kpi = parseInt(e.target.value)
    },
    changeCreative: async function (e) {
      const self = this
      const creativeName = this.creatives.find((x) =>
        this.isPadSquadSync
          ? x.id == this.placement.creative_id
          : x.external_creative_id == this.placement.external_creative_id
      ).name

      if (
        creativeName === 'Placeholder_Creative_300x250'
        || creativeName === 'Placeholder_Creative_320x50'
        || creativeName === 'Placeholder_Campaign_Creative'
      ) {
        this.previewSelectedCreativeId = ''
      }

      try {
        if (
          this.previewSelectedCreativeId !== ''
          && this.getSavedTrackers().length > 0
        ) {
          // if the upcoming creative has the same events as the current selected
          this.$swal({
            position: 'top',
            title:
              "If you change the assigned creative you'll lose the current placement trackers.",
            html: 'Do you want to preserve the trackers? </br>This action will apply any unsaved changes.',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Preserve',
            denyButtonText: "Don't Preserve",
            allowOutsideClick: false,
            icon: 'warning',
            toast: false
          }).then(async (result) => {
            if (result.isConfirmed) {
              // this.$swal('Saved!', '', 'success')
              this.previewSelectedCreativeId = e.target.value

              // this.$log.info(this.creative.events)
              const creative = await this.fetchCreative(e.target.value, true)
              this.$log.info(this.creative.events, creative)

              // const creative = this.creatives.find(item => item.external_creative_id === e.target.value);
              const trackersToSave = []

              this.getSavedTrackers().filter((item) => {
                const eventFound = _.find(creative.events, {
                  event_id: item.event_id,
                  label_name: item.label_name
                })
                if (eventFound) {
                  // this.$log.info('found', eventFound, item.creative_event_id)
                  const _item = {
                    placement_id: this.placement.id,
                    creative_event_id: eventFound.creative_event_id,
                    tracker_value: item.tracker_value,
                    options: []
                  }

                  // this.$log.info('options', eventFound.options, item.options);
                  if (
                    item.options
                    && item.options.length > 0
                    && item.options[0] !== undefined
                    && item.options[0].option_value
                    && item.options[0].option_value === '1'
                  ) {
                    this.$log.info(
                      'eventFound',
                      eventFound,
                      item.options[0],
                      item.options[0].creative_events_option_id
                    )
                    // _item.options = _.cloneDeep(item.options[item.options.length - 1]) || [item.options]
                    _item.options = [{}]
                    _item.options[0].creative_event_id
                      = eventFound.creative_event_id
                    _item.options[0].option_value = '1'
                    _item.options[0].option_id = 1 // item.options[0].option_id
                    _item.options[0].tracker_id = item.id
                    // _item.options[0].active = 1
                  }

                  trackersToSave.push(_item)
                }
              })

              const trackersNotInTheNewCreative
                = this.getSavedTrackers().filter((item) => {
                  if (
                    !_.find(creative.events, {
                      creative_event_id: item.creative_event_id
                    })
                  ) {
                    // this.$log.info(
                    //   'found',
                    //   _.find(creative.events, {
                    //     creative_event_id: item.creative_event_id
                    //   })
                    // )
                    return item
                  }
                })

              if (trackersNotInTheNewCreative)
                await this.removePlacementTrackers({
                  id: this.placement.id,
                  trackers: trackersNotInTheNewCreative
                })
              // ******************

              // save placement
              this.placement.trackers = trackersToSave
              this.$log.info('to save', trackersToSave)
              this.saveData(false)
            } else if (result.isDenied) {
              // this.$swal('Changes are not saved', '', 'info')
              this.previewSelectedCreativeId = e.target.value

              await this.removePlacementTrackers({
                id: this.placement.id,
                trackers: this.getSavedTrackers()
              })
              // set to 0 length
              await this.fetchCreative(e.target.value, false)
              //set trackers to []
              this.placement.trackers = []
            } else {
              // // if not change creative, preserve id and creative
              this.placement.external_creative_id
                = this.previewSelectedCreativeId
              this.placement.creative_id = this.creative.id
            }
          })
        } else {
          // assign id
          this.previewSelectedCreativeId = e.target.value

          if (this.itemId !== null) {
            await this.removePlacementTrackers({
              id: this.placement.id,
              trackers: this.getSavedTrackers()
            })
          }
          // set to 0 length
          await this.fetchCreative(e.target.value, false)

          this.placement.trackers = []

          if (this.itemId !== null) {
            // load events and generate trackers
            self.loadEventsCreativeAssigned()
          }
        }
      } catch (error) {
        self.$log.info(error)
      }
    },
    getEventsName: function (eventId = 1, separated = false) {
      switch (eventId) {
        case 1:
          return separated ? 'Standard Events' : 'standards_events'
          break
        case 2:
          return separated ? 'Custom Clicks' : 'custom_clicks'
          break
        case 3:
          return separated ? 'Custom Events' : 'custom_events'
          break
      }
    },
    hasEventsOfThisType: function (eventId) {
      const findEvent = this.placement.trackers.find(
        (item) => item.event_id === eventId
      )
      return Boolean(findEvent)
    },
    lengthEventsOfThisType: function (eventId) {
      const foundEvents = this.placement.trackers.filter(
        (item) => item.event_id === eventId
      )
      return foundEvents.length
    },
    lengthEventsOfThisTypeAndCreativeEventId: function (
      eventId,
      creativeEventId
    ) {
      const foundEvents = this.placement.trackers.filter((item) => {
        if (
          item.event_id === eventId
          && item.creative_event_id === creativeEventId
        ) {
          return item
        }
      })
      return foundEvents.length
    },
    lengthEventsOfThisTypeAndLabelEventSaved: function (eventId, eventLabel) {
      const foundEvents = this.placement.trackers.filter((item) => {
        if (
          item.event_id === eventId
          && item.label_name === eventLabel
          && item.saved === true
        ) {
          return item
        }
      })

      return foundEvents.length
    },
    isThisFirstIndexEventsOfThisTypeAndCreativeEventId: function (
      index,
      creativeEventId
    ) {
      let _index = false

      const itemFirst = this.placement.trackers.find((item, i) => {
        if (item.creative_event_id === creativeEventId) {
          _index = i
          return item
        }
      })

      return index === _index
    },
    isThisLastIndexEventsOfThisTypeAndCreativeEventId: function (
      index,
      creativeEventId
    ) {
      const lastIndexEvent = _.findLastIndex(
        this.placement.trackers,
        (item) => item.creative_event_id === creativeEventId
      )
      return index === lastIndexEvent
    },
    syncPlacementDetails: function () {
      const self = this

      const celtraId = self.campaign.external_campaign_id
      const placementCeltraId
        = self.placement.placement_id || self.placement.external_placement_id

      // this.$log.info('Sync Placement Details ID: ' + placementCeltraId, celtraId, this.placement.placement_source);

      // first fetch celtra placements
      // then fetch placements by filtering from the celtra campaign

      if (this.placement.placement_source === 'Airtory Sync') {
        this.$log.info('FETCH AIRTORY PLACEMENT')

        this.fetchAirtoryPlacement(self.placement.external_placement_id)
          .then((data) => {
            // self.$log.info('Sync placement response', data);
            self.loading = false
            self.synced = true
          })
          .catch((error) => {
            // notify the user
            // alert('There was a problem syncing this placement. Please try again.');
            this.notificationAlert(
              'There was a problem syncing this placement. Please try again.',
              '',
              'error'
            )

            self.loading = false
          })
      } else {
        this.fetchCeltra(celtraId)
          .then((data) => {
            const placementCeltra = this.listCeltra.find((item) => {
              return item.placement_id === placementCeltraId
            })

            // this.$log.info('placement celtra', placementCeltra, this.listCeltra);

            const itemRef = placementCeltra
            itemRef.id = self.placement.id

            itemRef.campaign_id = parseInt(self.campaign.id)
            itemRef.celtra_placement_id = itemRef.external_placement_id
              = placementCeltraId
            itemRef.tagSource = itemRef.placement_source = 'Celtra Sync'
            itemRef.creative_tag_id = 1
            itemRef.touched_on
              = self.placement.modified_date || self.placement.created_date
            itemRef.append_script = self.placement.append_script

            const foundCreativeFormat = _.find(this.creativeFormats, {
              name: itemRef.padsquad_product
            })
            itemRef.format_id = _.get(foundCreativeFormat, 'id', '')
            if (itemRef.format_id == '')
              itemRef.format_id = self.placement.format_id
            self.placement = itemRef

            self.synced = true
          })
          .catch((error) => {
            // notify the user
            // alert('There was a problem syncing this placement. Please try again.');
            this.notificationAlert(
              'There was a problem syncing this placement. Please try again.',
              '',
              'error'
            )
            self.loading = false
          })
      }
    },
    passedValidation: function (value) {
      // if(!value) return false;
      // return value.length >= 10;
      return Utils.validateURL(value)
    },
    editTracker: function (item) {
      item.creative_id = this.creative.id

      item.editing = true
      // make propery reactive to value true
      // Vue.set(object, propertyName, value)
      this.$set(item, String(item.editing), true)
    },
    saveTracker: async function (item) {
      item.creative_id = this.creative.id

      try {
        if (item.editing === true) {
          await this.removePlacementTrackers({
            id: this.placement.id,
            trackers: [item]
          })
          item.id = null
        }
        // Save Tracker on DB
        const response = await this.addPlacementTrackers({
          id: this.placement.id,
          trackers: [item]
        })
        // tracker added
        // show + add more
        item.id = response[0].id
        item.saved = true
        item.editing = false
        // add the options
        const event = this.creative.events.find(
          (item) => item.creative_event_id === response[0].creative_event_id
        )
        item.options = [
          _.cloneDeep(event.options[event.options.length - 1])
        ] || [{}]
        if (item.options[0]) {
          item.options[0].option_value = '0'
          item.options[0].creative_event_id = item.creative_event_id
          item.options[0].tracker_id = item.id
          item.options[0].active = 0
        }

        // make property reactive to value false
        this.$set(item, String(item.editing), false)
      } catch (error) {
        // alert('There was an error adding the tracker');
        this.notificationAlert(
          'There was an error adding the tracker',
          '',
          'error'
        )
      }
    },
    addEvent: function (
      items,
      eventId,
      labelName,
      creativeEventId,
      saved = false,
      id = null,
      tracker_value = '',
      options = []
    ) {
      const self = this

      items.push({
        id,
        event_id: eventId,
        placement_id: this.placement.id,
        creative_id: this.creative.id,
        external_campaign_id: this.placement.external_campaign_id,
        creative_event_id: creativeEventId,
        label_name: labelName,
        tracker_value,
        saved,
        editing: false,
        options: _.cloneDeep(options)
      })

      // self.$log.info('Add', items, this.lengthEventsOfThisTypeAndCreativeEventId(eventId, creativeEventId), this.lengthEventsOfThisType(eventId));
    },
    removeEvent: function (
      index,
      items,
      eventId = null,
      creativeEventId = null
    ) {
      const self = this

      // if the value has any data
      // if(items[index].tracker_value !== "" && items[index].saved ) {
      if (items[index].id !== null && items[index].saved) {
        // ask before removing
        this.$swal({
          position: 'top',
          title: 'Are you sure you want to remove this item?',
          html: '',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
          allowOutsideClick: false,
          icon: 'warning',
          toast: false
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Remove Tracker on DB
            this.removePlacementTrackers({
              id: this.placement.id,
              trackers: [items[index]]
            })
              .then((response) => {
                // tracker added
                // show + add more
                if (
                  this.lengthEventsOfThisTypeAndCreativeEventId(
                    eventId,
                    creativeEventId
                  ) > 1
                ) {
                  items.splice(index, 1)
                } else {
                  items[index].tracker_value = ''
                  items[index].saved = false
                  items[index].editing = false
                }
              })
              .catch((error) => {
                // alert('There was an error removing the tracker');
                this.notificationAlert(
                  'There was an error removing the tracker',
                  '',
                  'error'
                )
              })
          }
        })
      } else {
        items.splice(index, 1)
      }

      this.$log.info(items)
    },
    deleteRemovedEvent: function (index, item) {
      const self = this
      // ask before removing
      const trackerIndex = self.placement.trackers.indexOf(item)
      this.$swal({
        position: 'top',
        title: 'Are you sure you want to remove this item?',
        html: '',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        icon: 'warning',
        toast: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Remove Tracker on DB
          self
            .removePlacementTrackers({
              id: this.placement.id,
              trackers: self.placement.trackers.filter((x) => x.id == item.id)
            })
            .then((response) => {
              // tracker added
              // show + add more

              if (self.trackersRemovedEvents.length > 0) {
                self.trackersRemovedEvents.splice(index, 1)
                self.placement.trackers.splice(trackerIndex, 1)
              }
            })
            .catch((error) => {
              // alert('There was an error removing the tracker');
              this.notificationAlert(
                'There was an error removing the tracker',
                '',
                'error'
              )
            })
        }
      })
    },
    getSavedTrackers: function () {
      // Delete empty trackers
      const saved = []

      if (this.placement.trackers.length > 0) {
        this.placement.trackers.map((event, index) => {
          if (event.tracker_value !== '' && event.saved && event.id !== null) {
            // add tracker
            saved.push(event)
          }
        })

        this.$log.info('Trackers', saved)
      }
      return saved
    },
    gotoSection: function (section) {
      const self = this
      // if this a new creative and if the section we are trying to go to is previous to the currentSection (More to the left)
      if (!self.itemId && self.isPrevSection(section)) {
        // set toSection so the formSubmit knows we are navigation to another section.
        self.toSection = section

        // just change currentSection so we move and bypass the validation
        self.currentSection = section
      } else {
        // otherwise let's keep the validation and
        // change toSection, which is use by the formSubmit.
        self.toSection = section
      }
    },
    copyTag: function (tag) {
      const self = this
      Utils.copyToClipboard(tag, false)
    },
    downloadTrackers: function () {
      const self = this
      const tableData = []

      self.placement.trackers.map((item) => {
        tableData.push({
          id: item.id || '',
          placement_id: item.placement_id,
          creative_event_id: item.creative_event_id,
          label_name: item.label_name,
          tracker_value: item.tracker_value,
          use_as_destination:
            item.options[0]?.option_value == '1' ? 'Yes' : 'No'
        })
      })
      // if there are any placements
      if (tableData.length > 0) {
        // format the output before starting downloading this
        const formatedDataOutput = this.formatOutputCSV(tableData)

        // download
        Utils.downloadCSV(
          'Trackers-' + self.placement.name + '',
          formatedDataOutput
        )
      } else {
        alert(
          'There was an issue downloading tracker list. Please try again. Alternatively you could download a traffic plan.'
        )
      }
    },
    toggleShowInputUrl: function () {
      this.showInputUrl = this.placement.use_fallback_clicktrough_url

      if (!this.showInputUrl) {
        this.placement.clickthrough_url
          = this.placement.fallback_clicktrough_url = ''
      }
    },
    formatOutputCSV(data) {
      let formatedData = ''
      formatedData
        += 'ID,Placement ID,Creative Event ID,Label Name,Tracker Value, Use as Destination'
      if (!this.blank) {
        data.map((element) => {
          formatedData += '\n'
          formatedData
            += element.id
            + ','
            + element.placement_id
            + ','
            + element.creative_event_id
            + ','
            + element.label_name
            + ','
            + element.tracker_value
            + ','
            + element.use_as_destination
        })
      }

      return formatedData
    },
    formSubmit: function (e) {
      if (this.placement.name.trim().length <= 1) {
        return
      }

      const self = this

      // if toSection has any value it means that we are trying to go to a section. so...
      if (self.toSection != null) {
        // do not submit to action
        e.preventDefault()
        // then change the current section to the toSectino

        self.currentSection = self.toSection

        self.toSection = null
      } else {
        const trackersNotSaved = this.hasTrackerNotSaved().length
        const message
          = trackersNotSaved > 1
            ? `You have ${trackersNotSaved} trackers not saved.`
            : `You have ${trackersNotSaved} tracker not saved.`

        if (trackersNotSaved > 0) {
          // alert(message);
          this.notificationAlert(
            message,
            `${trackersNotSaved > 1 ? 'Please save or delete them.' : 'Please save or delete it.'}`
          )
        } else {
          if (!self.validatePackage()) return
          // otherwise just save data
          self.saveData()

          self.synced = false
        }
      }
    },
    saveData: async function (closeAfterSave = true) {
      const self = this

      // if this is not a new campaign...
      this.placement.campaign_source = this.placement.placement_source
      this.placement.external_placement_id
        = this.placement.external_placement_id == ''
          ? this.placement.placement_id
          : this.placement.external_placement_id

      // add a space avoiding the ad to render undefined
      this.placement.append_script
        = this.placement.append_script == ''
        || this.placement.append_script == null
          ? ' '
          : this.placement.append_script
      // We assign this only in Airtory creation
      this.placement.external_creative_id
        = this.placement.external_creative_id === ''
          ? 1
          : this.placement.external_creative_id

      // delete not saved trackers
      // const cleanTrackersToSend = this.getSavedTrackers();

      // CHECK TRACKERS AND OPTIONS BEFORE SEND
      // _placement.trackers.push({ creative_event_id: creative_event_1.creative_event_id, tracker_value: placement.tracker_1_pixel, option: { option_id: 1, option_value: this.getOptionValueFromYesNo(placement.tracker_1_use_as_destination) } });

      this.$log.info('Placement', this.placement)
      // this.$log.info('>>>Trackers', this.placement.trackers);
      const _package = this.selectedPackages.join(',')
      this.placement.assigned_packages = _package
      // this.placement.assigned_packages = this.selectedPackages.toString();

      if (self.itemId !== null) {
        if (self.placement.modified_date == null)
          self.placement.modified_date = self.placement.created_date

        // simple update without changing creative, we need not to send the trackers
        if (closeAfterSave) {
          self.placement.trackers = []
        }
        // update the placement (clone deep so in case it fails none of the data gets messed up)
        self
          .update(_.cloneDeep(self.placement))
          // emit done event
          .then((response) => {
            self.$log.info('Updated placement', response)

            _.assign(self.placement, _.cloneDeep(response))

            // WE NEED TO FECHT THE CREATIVE AGAIN TO HAVE THE UPDATED EVENT OPTIONS?

            self.placement.trackers.map((item) => {
              item.saved = true
              item.editing = false
              if (item.options && item.options.length === 0) item.options = []

              this.$log.info(
                'typeof',
                item,
                item.options.length === 0,
                self.getLowercase(item.label_name) === 'click'
                  || item.event_id === 2
              )

              // we add empty option if is a click tracker and has no options from DB
              if (
                item.options.length === 0
                && (self.getLowercase(item.label_name) === 'click'
                  || item.event_id === 2)
              ) {
                this.$log.info('we need to add options')
                // add the options
                // item.options = [_.cloneDeep(event.options[event.options.length - 1])] || [{}]
                item.options = [{}]
                item.options[0].option_value = '0'
                item.options[0].creative_event_id = item.creative_event_id
                item.options[0].tracker_id = item.id
                item.options[0].active = 0

                // this.$log.info(item);

                // make property reactive to value false
                this.$set(item, String(item.editing), false)
              } else {
                if (
                  self.getLowercase(item.label_name) === 'click'
                  || item.event_id === 2
                )
                  this.$log.info('item has options!', item.options)
              }
            })

            if (closeAfterSave) {
              self.$emit('done', response)
            } else {
              this.loadEventsCreativeAssigned()
            }
          })
          .catch((error) => {
            // notify the user
            // alert('There was a problem updating this placement. Please try again.');
            // this.notificationAlert('There was a problem updating this placement. Please try again.', '', 'error');
            this.$swal({
              position: 'top',
              title:
                'There was a problem updating this placement. Please Try again.',
              html: 'You can also try by clicking on the SAVE PLACEMENT button.',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: 'TRY AGAIN',
              cancelButtonText: "I'll click on SAVE",
              allowOutsideClick: false,
              icon: 'error',
              toast: false
            })
          })
      } else {
        // create the placement (clone deep so in case it fails none of the data gets messed up)
        self
          .create(_.cloneDeep(self.placement))
          // emit done event
          .then((response) => {
            self.$log.info('New placement', response)
            if (closeAfterSave) self.$emit('done', response)
            // reload page after modal close to get placement external id
            self.navigateToPlacementsCampaign(this.campaign.id)
          })
          .catch((error) => {
            // notify the user
            // alert('There was a problem creating this placement. Please try again.');
            this.notificationAlert(
              'There was a problem creating this placement. Please try again.',
              '',
              'error'
            )
          })
      }
    },
    saveAll: function () {
      this.hasTrackerNotSaved().map((item) => {
        this.saveTracker(item)
      })
    },
    cancel: function () {
      const self = this

      if (self.synced) {
        // show message that should to save the synced placement
      }

      // emit event
      self.$emit('done')
    },
    loadEventsCreativeAssigned: async function (
      preserveTrackers = false,
      updatePlacement = false
    ) {
      try {
        // idlc
        this.creative.events.map((event) => {
          if (
            this.lengthEventsOfThisTypeAndCreativeEventId(
              event.event_id,
              event.creative_event_id
            ) === 0
          ) {
            // add tracker empty
            this.addEvent(
              this.placement.trackers,
              event.event_id,
              event.label_name,
              event.creative_event_id
            )
          }
        })

        this.$log.info('trackers', this.placement.trackers)
        this.$log.info('creative events', this.creative.events)

        if (this.itemId !== null) {
        }
        // ADD EVENT OPTION FROM CREATIVE TO ALL EVENTS THAT HAVE options: []
        this.placement.trackers = _.orderBy(
          this.placement.trackers,
          ['order'],
          ['asc']
        )
        this.placement.trackers.map((tracker) => {
          const event = this.creative.events.find(
            (item) => item.creative_event_id === tracker.creative_event_id
          )
          this.$log.info(tracker.options.length === 0, event)
          if (tracker.options.length === 0 && event) {
            // this.$log.info(tracker.id, tracker.options.length, tracker.creative_event_id, this.creative.events.find(item => item.creative_event_id === tracker.creative_event_id))
            if (event !== undefined) {
              tracker.options
                = event.options && event.options.length > 1
                  ? [_.cloneDeep(event.options[event.options.length - 1])]
                  : _.cloneDeep(event.options)
              this.$log.info(tracker.options)
              if (
                tracker.options
                && tracker.options[0]
                && tracker.id !== tracker.options[0].tracker_id
              ) {
                // we need to set this to '0' because the tracker option value is from the event
                // and if we set the value to '1' via tracker
                tracker.options[0].option_value = '0'
                tracker.options[0].creative_event_id = event.creative_event_id
                tracker.options[0].id
                  = tracker.options[0].creative_events_option_id
                tracker.options[0].option_id = 1 // tracker.options[0].option_id
                tracker.options[0].tracker_id = tracker.id
                this.$log.info(tracker.id, tracker.options[0], event)
              }
            }
          } else {
            if (tracker.options.length > 0 && event) {
              // if the tracker options come with multiple objects we select the last one
              tracker.options = [
                _.cloneDeep(tracker.options[tracker.options.length - 1])
              ]
              tracker.options[tracker.options.length - 1].id
                = tracker.options[
                  tracker.options.length - 1
                ].creative_events_option_id
              tracker.options[tracker.options.length - 1].option_id = 1 // tracker.options[0].option_id
              tracker.options[tracker.options.length - 1].creative_event_id
                = event.creative_event_id
            }
            // this.$log.info(tracker)
          }
        })
      } catch (error) {
        this.$log.info('error', error)
        if (preserveTrackers) {
          // alert('There was an error preserving the trackers');
          const message = preserveTrackers
            ? 'There was an error preserving the trackers.'
            : 'There was an error loading the trackers'
          this.notificationAlert(message)
        } else {
          // alert('There was an error loading the trackers');
        }
      }
    },
    populateForm: async function () {
      const self = this

      try {
        // if the campaign passed as prop does not have creatives we'll need to fetch the campaign again
        // because campaigns must have at leat one placeholder creative
        if (!this.campaign.creatives || this.campaign.creatives.length === 0) {
          self.$log.info('No creatives')
          const _campaign = await this.getCampaign(this.campaign.id)
          this.creatives = _.cloneDeep(_campaign.creatives)
        } else {
          this.creatives = _.cloneDeep(this.campaign.creatives)
        }

        // if an itemId came in it means that we are trying to load settings for an existing item... so if there's an itemId
        if (self.itemId !== null) {
          const itemRef = _.cloneDeep(self.itemById(self.itemId))
          self.placement = _.assign(self.placement, itemRef)

          this.typeOfData = 'syncPlacement'

          // get placement by id
          const _placement = await self.read(self.itemId)

          _.assign(self.placement, _.cloneDeep(_placement[0]))
          // add and set saved to true

          self.placement.creative_tag_id = self.placement.creative_tag_id || 1

          if (this.isAirtorySync || this.isPadSquadSync) {
            self.$log.info('PLACEMENT', _placement, _placement[0].trackers)
            self.placement.trackers.map((item) => {
              item.saved = true
              item.editing = false
            })

            self.previewSelectedCreativeId = self.placement.external_creative_id

            const targetCreativeId = this.isPadSquadSync
              ? this.placement.creative_id
              : this.placement.external_creative_id

            this.creative = this.creatives.find((item) =>
              this.isPadSquadSync
                ? item.id === targetCreativeId
                : item.external_creative_id === targetCreativeId
            )

            self.placement.creative_id = self.creative.id

            self.toggleShowInputUrl()
            // let's fetch the creative
            // we need the creative.events
            const data = await self.fetchById(self.creative.id)
            self.$log.info('CREATIVE', data)

            _.assign(self.creative, data)

            // load events and generate trackers
            self.loadEventsCreativeAssigned()

            // set the trackers of removed events
            this.placement.trackers.map((item) => {
              if (
                this.creative.events.filter(
                  (dt) =>
                    dt.event_id === item.event_id
                    && dt.creative_event_id === item.creative_event_id
                ).length === 0
              ) {
                this.trackersRemovedEvents.push(item)
              }
            })
          }

          // set assigned packaged to the placement and validate
          if (
            self.placement.assigned_packages === null
            || self.placement.assigned_packages === ''
          ) {
            self.placement.assigned_packages = []
          } else {
            self.selectedPackages = self.placement.assigned_packages.split(',')
          }
        }

        self.placement.placement_source = self.campaign.campaign_source

        // set campaign id
        self.placement.campaign_id = self.campaign.id
        self.placement.external_campaign_id = self.campaign.external_campaign_id
        self.placement.campaign_name = self.campaign.name
        self.placement.start_date = self.campaign.start_date
        self.placement.end_date = self.campaign.end_date
        self.placement.goal = self.campaign.kpi_goal || 100000 // 100000 number for airtory
        self.placement.publisher_name = 'PadSquad' // self.campaign.publisher_name;
        self.salesforcePackages = Object.values(
          self.campaign.salesforcePackages
        )

        // set campaign source to placement, to prevent select Celtra Sync Placement source
        // if(!this.campaignIsCeltraSync) self.placement.placement_source = 'Manual';
      } catch (error) {
        self.$log.info(error)
      }
    },
    isPrevSection: function (toSection) {
      const self = this

      // if we are attempting to go to a section
      if (toSection) {
        // get currentSection index
        const currentSectionIndex
          = self.sections.indexOf(self.currentSection) + 1

        // get toSection index
        const toSectionIndex = self.sections.indexOf(toSection) + 1

        // if the toSection index is < than the currentSection,
        // then the validation is omited (all required fields will not be required on this case)
        // so return the comparison result;
        return toSectionIndex < currentSectionIndex
      } else {
        // otherwise just make it false so validation stays in place
        return false
      }
    },
    navigateToPlacementsCampaign: function (itemData) {
      const self = this

      const routeData = self.$router.resolve({
        name: 'Tag Manager Campaign',
        params: { id: itemData }
      })
      window.open(routeData.href, '_self')
    }
  },
  created() {
    const self = this

    // populate form
    self.populateForm()
  },
  updated() {
    // set height of the trackers textarea when loaded
    if (this.currentSection === 'trackers') {
      const textareas = this.$el.querySelectorAll('textarea')
      for (const el of textareas) {
        el.style.height = '37px'
        el.style.height = el.scrollHeight + 'px'
      }
    }
  },
  directives: {
    'validate-url': {
      update: function (el, binding, vnode) {
        const isValid = vnode.context.passedValidation(el.value)
        el.setCustomValidity(isValid ? '' : 'Invalid URL')
      }
    }
  },
  computed: {
    ...mapGetters('placements', ['itemById', 'listCeltra']),
    ...mapGetters([
      'campaignSources',
      'productTypes',
      'creativeTagTypes',
      'creativeFormats',
      'KPIs'
    ]),
    isRequired() {
      return (
        this.campaign.salesforcePackages.length > 0
        && this.selectedPackages.length === 0
      )
    },
    campaignIsCeltraSync: function () {
      return this.campaign.campaign_source === 'Celtra Sync'
    },
    campaignIsAirtorySync: function () {
      return this.campaign.campaign_source === 'Airtory Sync'
    },
    getPatternUrlValidation: function () {
      // add pattern validation to input
      return Utils.getRegExUrlValidation()
    },
    typeOfEventsCreativeHas: function () {
      let lastId = null
      const ids = []
      const sorted = _.sortBy(this.creative.events, 'event_id')
      sorted.map((item) => {
        if (lastId !== item.event_id) {
          ids.push(item.event_id)
          lastId = item.event_id
        }
      })

      this.$log.info('IDS', ids)

      return ids
    },
    getEventsOfTypeClicks: function () {
      const foundEvents = this.creative.events.filter(
        (item) => item.event_id === 2
      )
      return foundEvents
    },
    getEventsOfTypeEventCustoms: function () {
      const foundEvents = this.creative.events.filter(
        (item) => item.event_id === 3
      )
      return foundEvents
    },
    isAirtorySync: function () {
      return this.placement.placement_source === 'Airtory Sync'
    },
    isPadSquadSync: function () {
      return this.placement.placement_source === 'PadSquad'
    },
    isSyncPlacement: function () {
      return this.typeOfData === 'syncPlacement'
    },
    isCeltraSync: function () {
      return this.placement.placement_source === 'Celtra Sync'
    },
    isFirstSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return self.sections.indexOf(self.currentSection) + 1 == 1
    },
    isLastSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return (
        self.sections.indexOf(self.currentSection) + 1 == self.sections.length
      )
    },
    prevSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex = self.sections.indexOf(self.currentSection) - 1

      // return the section name on the next position in the sections array;
      return self.sections[currentSectionIndex]
    },
    nextSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex = self.sections.indexOf(self.currentSection) + 1

      // return the section name on the next position in the sections array;
      return self.sections[currentSectionIndex]
    },
    isDataModified: function () {
      const self = this

      return !_.isEqual(self.placement, self.itemById(self.itemId))
    },
    placementLength: function () {
      return this.placement.name == null ? 0 : this.placement.name.trim().length
    }
  },
  watch: {
    /* when the section is changed */
    currentSection: function (value) {
      const self = this
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import '@/scss/common.scss';

.input-wrapper {
  display: flex;

  .input-group {
    margin-right: $spacing-unit * 2;
    min-width: 140px;

    .pair {
      width: 100%;
      margin-right: 0;
      float: left;
      color: $gray6;
      display: flex;
      flex-direction: column;

      input {
        margin-right: $spacing-unit/2;
        margin-bottom: 1rem;
      }
    }

    .pair50 {
      width: 50%;
      margin-right: 0;
    }
  }

  .input-group-wide {
    min-width: 230px;
  }
}

.ratioInputs {
  display: flex;
}

.ratioInputs div input[type='radio'][data-v-610bc537] {
  margin-right: 5px !important;
}

.fullWidth {
  display: grid !important;
}

.buttons-row {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  transition: all 0.3s ease-out;
  opacity: 0;
  // pointer-events: none;
}

.isFirstWithDescription {
  margin-top: 25px;
}

.buttons-row button {
  float: left;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  margin-left: 0 !important;
}

.edit-button {
  display: block;
}

.buttons-row:hover {
  opacity: 1;
  z-index: 200;
  // pointer-events: auto;
}

.buttons-row:hover button {
  margin-left: $spacing-unit !important;
}

.edit-button button {
  pointer-events: none;
}

#bodyTrackers .formField__field > input[type='text']:disabled,
textarea:disabled {
  background: #fff !important;
  cursor: not-allowed;
}

.check_option {
  clear: both;
  margin-left: 120px;
  display: flex;
}

.check_option label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
.copy-event {
  text-align: left;
  color: #646464;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
  border-radius: 4px;

  padding: 1rem 1.5rem;
  cursor: pointer;
  visibility: hidden;
  margin-left: -4rem;

  width: 3px;
  pointer-events: none;
}
.show :hover + .copy-event {
  visibility: visible;
}
.copy-event :hover {
  visibility: visible;
}
.copy-icon {
  pointer-events: auto;
}
.removed-events {
  color: #3bbca1;
}
.remove-event-trash {
  margin-top: 2.5rem;
}
.checkAll_container {
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  padding: 20px 20px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkAllButton {
  @extend .button;
  @extend .button--positive;
  text-decoration: none;
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.toggleContainer {
  display: flex;
  align-items: right;
  justify-content: right;
}
.toggleButton {
  @extend .button;
  text-decoration: none;
  text-align: center;
}
.defaultLabel {
  position: absolute;
  font-size: 1rem;
}
.form-label {
  text-transform: uppercase;
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
  min-height: 1.5rem;
}
/* arrow */
.multiselect__select {
  z-index: 9;
}

.multiselect__input,
.multiselect__single {
  font-size: 1.2rem;
  font-weight: 400;
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 0px;
  background: #fff;
  padding: 0 0 0 5px;
  padding: 0;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}

.multiselect__select:before {
  color: #646464;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #14123a;
}

.multiselect__placeholder {
  padding-left: 7px;
  color: #adadad;
  color: #14123a;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
  padding: 0;
  font-weight: 400;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 1rem 1.5rem 0;
  border-radius: 0px;
  border: 1px solid #d9d9d9;
  background: #fff;
  font-size: 1.2rem;
}

.multiselect__tags:hover {
  border-color: #979797;
}

.multiselect__tag {
  position: relative;
  display: block;
  border-radius: 0px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #3bbca1;
  background: #8ad622;
  margin-bottom: 5px;
  white-space: normal;
  word-break: break-all;
}

.multiselect__option {
  font-size: 1.2rem;
  font-weight: 400;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #14123a;
  font-weight: 400;
}

.multiselect__option--highlight {
  background: #3bbca1;
  outline: none;
  color: #fff;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #3bbca1;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__tag-icon:after {
  content: '\D7';
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__option--highlight {
  background: #3bbca1;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #3bbca1;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #a8a8a8;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #a8a8a8;
  content: attr(data-deselect);
  color: #fff;
}

.l-red {
  color: #f00 !important;
}

.error-message {
  color: red;
  // font-size: 14px;
  margin-top: 5px;
  display: inline-block;
}

.multiselect-error {
  border: 1px solid red;
}
</style>
