<template>
    <div class="progress-bar-container">
        <div class="progress-bar" :style="styleBar"></div>
  </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        value: {
            type: Number,
            default: 0,
            required: true
        },
        max: {
            type: Number,
            default: 100,
        },
    },
    computed: {
        styleBar() {
            const barWidth = this.value / this.max * 100;
            return {
                width: `${barWidth}%`,
                backgroundColor: '#3BBCA1',
            };
        }
    }
};
</script>

<style scoped>
.progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #E6E6E6;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
}
.progress-bar {
  height: 20px;
  border-radius: 5px;
}
</style>
