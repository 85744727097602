<template>
    <router-view/>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AnalyticsRepository',
  methods: {
    ...mapActions([
      'fetchAllTaxonomies'
    ]),
    ...mapActions('advertisers', {
      fetchAdvertisers: 'fetch'
    })
  },
  created () {
    const self = this

    // load taxonomies
    self.fetchAllTaxonomies()

    // load advertisers
    self.fetchAdvertisers()
  }
}
</script>
