import { reactive, shallowRef, computed } from 'vue'
/**
 * @typedef { import("vue-router").default } Router
 * @typedef { import("vue-router").Route } Route
 */
/**
 * vue-router composables
 * @param {Router} router - router instance
 */
export function useRouterApi (router) {
  const currentRoute = shallowRef({
    path: '/',
    name: undefined,
    params: {},
    query: {},
    hash: '',
    fullPath: '/',
    matched: [],
    meta: {},
    redirectedFrom: undefined
  })
  /** @type {Route} */
  const reactiveRoute = {}
  for (const key in currentRoute.value) {
    reactiveRoute[key] = computed(() => currentRoute.value[key])
  }
  router.afterEach((to) => {
    currentRoute.value = to
  })
  /**
   * get router instance
   */
  function useRouter () {
    return router
  }
  /**
   * get current route object
   */
  function useRoute () {
    return reactive(reactiveRoute)
  }
  return {
    useRouter,
    useRoute
  }
}
