<template>
    <section class="sModal">
        <Preloader :inModal="true"></Preloader>
        <header class="sModal__header">Export CSV</header>
        <div class="sModal__body">
            <div class="container-fluid py-3">
                <div class="row">
                    <div class="d-inline">
                        <label>Start Date</label>
                        <datepicker v-model="startDate" ref="startDateOpen" placeholder="Start Date"></datepicker>
                    </div>
                    <div class="d-inline px-2 pt-5">
                        <font-awesome-icon @click="openStartDate" class="calendar-button-icon" icon="calendar-alt"/>
                    </div>
                    <div class="d-inline">
                        <label>End Date</label>
                        <datepicker v-model="endDate" ref="endDateOpen" placeholder="End Date"></datepicker>
                    </div>
                    <div class="d-inline px-2 pt-5">
                        <font-awesome-icon @click="openEndDate" class="calendar-button-icon" icon="calendar-alt"/>
                    </div>
                </div>
            </div>
            <div class="py-3">
                <multiselect v-model="selectedPrimaryKpi" :options="primaryKpi" :multiple="true" placeholder="Select a Primary KPI"></multiselect>
            </div>
            <div class="py-3">
                <multiselect v-model="selectedSecondaryKpi" :options="secondaryKpi" :multiple="true" placeholder="Select a Secondary KPI"></multiselect>
            </div>
            <div class="py-3">
                <multiselect v-model="selectedFormat" :options="format" :multiple="true" placeholder="Select a format"></multiselect>
            </div>
        </div>
        <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" />Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Save New Campaign' if this is new campaign and we are on the last screen-->
          <button class="saveButton" @click="download" >
            <font-awesome-icon icon="check" class="i" />Download
          </button>
        </div>
      </footer>
    </section>
</template>

<script>
import Preloader from '@/components/Preloader.vue'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
  name: 'GenerateAdProductCSV',
  components: {
    Preloader, Multiselect, Datepicker
  },
  props: {
    campaignId: null
  },
  data () {
    return {
      selectedPrimaryKpi: [],
      primaryKpi: [
        'Simpli Engagement Rate',
        'Simpli CTR',
        'Simpli Video Completion Rate',
        '3P Viewability',
        'Simpli Viewability'
      ],
      selectedSecondaryKpi: [],
      secondaryKpi: [
        'Simpli Engagement Rate',
        'Simpli CTR',
        'Simpli Video Completion Rate',
        '3P Viewability',
        'Simpli Viewability'
      ],
      selectedFormat: [],
      format: [
        'Popup Banner',
        'Interscroller',
        'Standard 728x90',
        'Viz Quiz',
        'Flip Tile',
        'Brandscape',
        'Hover',
        'VidScroll',
        'Swipe Right',
        'Cart Injection',
        'Mosaic',
        'Standard 320x50',
        'Chat Bot',
        'VidTile',
        'Squad Stories',
        'Smile Meter',
        'Swell',
        'Look Book',
        '3D Art',
        'Duo',
        'Standard 300x250',
        'Shopper Marketing',
        'Vertical Video',
        'TaptoMap',
        "Shop'spiration Cards",
        'Emoji Slider',
        'Reactive Tile',
        'Gamification',
        'Augmented Reality',
        'Reactive Hover',
        'PS Shop',
        'Accordion',
        'VVital',
        '360 Video',
        'FT Adhesion',
        'Press  Hold Scroller',
        'Slide to Reveal'
      ],
      startDate: new Date(),
      endDate: new Date()
    }
  },
  methods: {
    ...mapActions([
      'registerDownloadingStarted',
      'registerDownloadingCompleted'
    ]),
    openStartDate: function () {
      this.$refs.startDateOpen.showCalendar()
    },
    openEndDate: function () {
      this.$refs.endDateOpen.showCalendar()
    },
    cancel: function () {
      const self = this

      if (self.synced) {
        // show message that should to save the synced campaign
      }

      // emit event
      self.$emit('done')
    },
    forceFileDownload (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const date = new Date()
      const filename = `${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}_productperformance.csv`
      link.setAttribute('download', filename) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    download: function () {
      this.registerDownloadingStarted()
      const ax = axios.create({
        // baseURL: process.env.VUE_ANALYTICS_BACKEND_ENDPOINT,
        baseURL: '',
        headers: {
          'x-api-key': process.env.VUE_ANALYTICS_X_API_KEY,
          Authorization: `Bearer ${this.$store.getters.token}`
        }
      })
      this.cancel()
      ax.get(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_AD_PRODUCT_DATABASE_CSV_DOWNLOAD}`, {
        params: {
          startDate: this.startDate.getTime(),
          endDate: this.endDate.getTime(),
          selectedPrimaryKpi: this.selectedPrimaryKpi.join(),
          selectedSecondaryKpi: this.selectedSecondaryKpi.join(),
          selectedFormat: this.selectedFormat.join()
        }
      })
        .then(response => {
          this.forceFileDownload(response)
          this.registerDownloadingCompleted()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
