<script setup>
import { computed, onMounted } from 'vue'
import CreativePreviewMobileShell from '@/components/creative-preview/device-shells/CreativePreviewMobileShell.vue'
import CreativePreviewTabletShell from '@/components/creative-preview/device-shells/CreativePreviewTabletShell.vue'
import CreativePreviewDesktopShell from '@/components/creative-preview/device-shells/CreativePreviewDesktopShell.vue'
import RenderIFrameContent from '@/components/creative-preview/RenderIFrameContent.vue'

const emit = defineEmits(['refresh-creative', 'modify-url-params', 'ps-tag-ready', 'has-dco'])
const props = defineProps({
  creativeData: {
    type: Object,
    required: true
  },
  mockSiteIframeKey: {
    type: Number,
    required: true
  },
  previewSettings: {
    type: Object,
    required: true
  },
  isDragging: {
    type: Boolean,
    required: true
  }
})

const sizeIcons = [
  { name: 'xsmall', val: 'XS' },
  { name: 'small', val: 'S' },
  { name: 'medium', val: 'M' },
  { name: 'large', val: 'L' }
]

const shellComponent = computed(() => {
  if (props.previewSettings.isDesktopOnly) {
    return CreativePreviewDesktopShell
  }
  switch (props.previewSettings.device) {
    case 'mobile':
      return CreativePreviewMobileShell
    case 'tablet':
      return CreativePreviewTabletShell
    default:
      return CreativePreviewDesktopShell
  }
})
const isRotatable = computed(() => {
  return !props.previewSettings.isHorizontalOnly && !props.previewSettings.isPortraitOnly && !props.previewSettings.isDesktopOnly
})
const selectedMobileSize = computed(() => {
  return sizeIcons.find(x => x.name === props.previewSettings.mobileSize)
})

const rotate = () => {
  emit('modify-url-params', 'set', 'landscape', !props.previewSettings.landscape)
  emit('refresh-creative')
}
const selectMobileSize = (size) => {
  emit('modify-url-params', 'set', 'mobileSize', size.name)
  emit('refresh-creative')
}
onMounted(() => {
  if (props.previewSettings.isHorizontalOnly) {
    emit('modify-url-params', 'set', 'landscape', true)
  }
})
</script>

<template>
  <div
    class="w-100 h-100 p-4 d-flex justify-content-center align-items-center"
    :class="{'desktop-bg': previewSettings.device === 'desktop' || previewSettings.isDesktopOnly}"
  >
    <div class="scroll-container d-flex justify-content-center align-items-center">
      <div
        class="scroll-contents"
        :class="[previewSettings.device !== 'desktop' && !previewSettings.isDesktopOnly ? 'centered-flex-column' : 'h-100' ]"
      >
        <!-- shell -->
        <shellComponent :preview-settings="previewSettings">
          <template v-slot:iFrameContent>
            <RenderIFrameContent
              :key="mockSiteIframeKey"
              :creative-data="creativeData"
              :is-dragging="isDragging"
              @ps-tag-ready="emit('ps-tag-ready')"
              @has-dco="(val) => emit('has-dco', val)"
            />
          </template>
        </shellComponent>
      </div>
    </div>

    <!-- size icons -->
    <div class="icons-menu d-flex flex-column h-100 justify-content-center m-4">
      <div v-if="previewSettings.device === 'mobile' && !previewSettings.isDesktopOnly">
        <div
          v-for="(icon, idx) in sizeIcons"
          :key="idx"
        >
          <div
            class="icons-menu-icon mobile-icon my-3 d-flex justify-content-center align-items-center cursor-pointer"
            :style="`background-color: ${selectedMobileSize.val === icon.val ? '#6D6D6D' : '#CACACA'}`"
            @click="selectMobileSize(icon)"
          >
            {{ icon.val }}
          </div>
        </div>
      </div>

      <!-- rotate icon -->
      <img
        v-if="previewSettings.device !== 'desktop' && isRotatable"
        src="@/assets/rotate-icon.svg"
        class="icons-menu-icon my-3 cursor-pointer"
        style="color: #6D6D6D;"
        @click="rotate()"
      />
    </div>
  </div>
</template>

<style scoped>
.icons-menu {
  position: absolute;
  right: 0;
}
.icons-menu-icon {
  height: 30px;
  width: 30px;
}
.mobile-icon {
  border-radius: 5px;
  color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
.desktop-bg {
  background-color: #F0F0F0;
}

.scroll-container {
  position: absolute;
  inset: 0;
  overflow-y: auto;
  flex-grow: 1;
}
.scroll-contents {
  top: 0px;
  position: absolute;
  min-height: 100%;
  width: 100%;
}
.centered-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0px;
}
</style>
