import Vue from 'vue'
// load font-awesome (fa)
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './registerServiceWorker'
import router from '@/router'
import BootstrapVue from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueLogger from 'vuejs-logger'
import Vuex from 'vuex'
import store from '@/store'
import moment from 'vue-moment'
import VueTextareaAutosize from 'vue-textarea-autosize'

// AWS AMPLIFY
import Amplify from '@aws-amplify/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import Auth from '@aws-amplify/auth'
import { AmplifyPlugin } from 'aws-amplify-vue'
// Vue Hotjar
import Hotjar from 'vue-hotjar'

// load script for Airtory iframe cdn
import LoadScript from 'vue-plugin-load-script'

import VueExcelEditor from 'vue-excel-editor'
// Vue Alert and Toast
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';

// load fa icons
import {
  faSignInAlt,
  faUserLock,
  faHdd,
  faUsersCog,
  faUserCircle,
  faPlusCircle,
  faFilter,
  faEye,
  faEllipsisH,
  faCaretDown,
  faSearch,
  faTimes,
  faCog,
  faTools,
  faTrashAlt,
  faShareAlt,
  faList,
  faCheck,
  faBan,
  faCalendarAlt,
  faDownload,
  faUndoAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faSignOutAlt,
  faLock,
  faLockOpen,
  faMobileAlt,
  faSyncAlt,
  faExternalLinkAlt,
  faLink,
  faSpinner,
  faBold,
  faItalic,
  faHeading,
  faParagraph,
  faDesktop,
  faCopy,
  faFire,
  faShapes,
  faCode,
  faTable,
  faInfo,
  faInfoCircle,
  faAd,
  faExclamationCircle,
  faQuestion,
  faCaretUp,
  faChartPie,
  faPencilRuler,
  faLayerGroup,
  faArrowLeft,
  faLevelUpAlt,
  faPen,
  faClone,
  faChartLine,
  faChartBar,
  faCircle,
  faPause,
  faPlayCircle,
  faHeadset,
  faToggleOn,
  faToggleOff,
  faExpandAlt,
  faCompressAlt,
  faUpload,
  faMap,
  faEdit,
  faRedo,
  faTabletAlt,
  faTerminal,
  faSlidersH,
  faUser,
  faChevronDown,
  faChevronUp,
  faExchangeAlt
} from '@fortawesome/free-solid-svg-icons'
// Axios interceptor
import setAxiosInterceptor from './helpers/interceptors'
import App from './App.vue'

// add fa icons
library.add(faCaretUp)
library.add(faSignInAlt)
library.add(faSignOutAlt)
library.add(faUserLock)
library.add(faHdd)
library.add(faUsersCog)
library.add(faUserCircle)
library.add(faPlusCircle)
library.add(faFilter)
library.add(faEye)
library.add(faEllipsisH)
library.add(faCaretDown)
library.add(faSearch)
library.add(faTimes)
library.add(faCog)
library.add(faTrashAlt)
library.add(faShareAlt)
library.add(faList)
library.add(faCheck)
library.add(faBan)
library.add(faCalendarAlt)
library.add(faDownload)
library.add(faUndoAlt)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faAngleUp)
library.add(faAngleDown)
library.add(faLock)
library.add(faLockOpen)
library.add(faMobileAlt)
library.add(faSyncAlt)
library.add(faLink)
library.add(faExternalLinkAlt)
library.add(faSpinner)
library.add(faBold)
library.add(faItalic)
library.add(faHeading)
library.add(faParagraph)
library.add(faDesktop)
library.add(faCopy)
library.add(faFire)
library.add(faShapes)
library.add(faCode)
library.add(faTable)
library.add(faInfo)
library.add(faAd)
library.add(faExclamationCircle)
library.add(faQuestion)
library.add(faInfoCircle)
library.add(faChartPie)
library.add(faPencilRuler)
library.add(faLayerGroup)
library.add(faArrowLeft)
library.add(faLevelUpAlt)
library.add(faPen)
library.add(faClone)
library.add(faChartLine)
library.add(faChartBar)
library.add(faCircle)
library.add(faPause)
library.add(faPlayCircle)
library.add(faHeadset)
library.add(faToggleOn)
library.add(faToggleOff)
library.add(faExpandAlt)
library.add(faCompressAlt)
library.add(faUpload)
library.add(faMap)
library.add(faEdit)
library.add(faRedo)
library.add(faTabletAlt)
library.add(faTerminal)
library.add(faSlidersH)
library.add(faUser)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faExchangeAlt)
library.add(faTools)

// activate components
Vue.use(BootstrapVue)

// Vue.use(VueLodash);
Vue.use(VueLodash, {
  name: 'custom',
  lodash
})
Vue.use(VueLogger, {
  isEnabled: process.env.VUE_APP_LOGGING === 'true',
  logLevel: process.env.VUE_APP_LOGGING_LEVEL,
  showMethodName: true,
  showConsoleColors: true
})
Vue.use(Vuex)
Vue.use(moment)
Vue.use(VueTextareaAutosize)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(VueExcelEditor)

Vue.use(LoadScript)

if (localStorage.current_region === undefined) {
  localStorage.current_region = 'us-east-1'
}
// DR config
if (localStorage.current_region === 'us-west-2') {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID,
      identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID_WEST,
      // REQUIRED - Amazon Cognito Region
      region: process.env.VUE_APP_AUTH_REGION_WEST,
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: process.env.VUE_APP_AUTH_IDENTITY_POOL_REGION_WEST,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID_WEST,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID_WEST
    }
  })
} else if (localStorage.current_region === 'us-east-1') {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID,
      identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.VUE_APP_AUTH_REGION,
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: process.env.VUE_APP_AUTH_IDENTITY_POOL_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID
    }
  })
}

Vue.use(AmplifyPlugin, Auth)

const alertOptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
}

Vue.use(VueSweetalert2, alertOptions)

Vue.use(Hotjar, {
  id: '2522867',
  isProduction: true,
  snippetVersion: 6
})

// Jira Bug Submission
const updateFieldValues = () => {
  const { fullname, userInSession: { email } } = store.getters
  Object.assign(window.ATL_JQ_PAGE_PROPS.fieldValues, { fullname, email })
}
window.ATL_JQ_PAGE_PROPS = {
  triggerFunction: (showCollectorDialog) => {
    Vue.prototype.$showCollectorDialog = () => {
      updateFieldValues()
      showCollectorDialog()
    }
  },
  fieldValues: {
    summary: '',
    description: '',
    screenshot: '',
    name: '',
    email:''
  }
}

// Run axios interceptor for token
setAxiosInterceptor()

// Numbers formatter
Vue.filter('numberWithCommas', (value) => {
  if (!value) return ''

  // if is percentage % value
  if (value.toString().indexOf('%') !== -1) return value

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('truncateTitle', (value, max) => {
  const maxChars = max || 70

  if (value.length <= maxChars) return value
  return `${String(value).slice(0, maxChars - 3)}...`
})

Vue.mixin({
  methods: {
    notificationAlert(title, description = '', icon = 'warning') {
      this.$swal({
        position: 'top',
        title,
        html: description,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        icon,
        toast: false
      })
    }
  }
})

Vue.config.productionTip = false

// global settings for the app
Vue.prototype.$defaultModule = {
  name: 'Creative Studio'
}

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
