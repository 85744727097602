<template>
  <div class="app_bar">
    <div
      class="app_bar__brand"
      :class="{ app_bar__brand__expand: hoverOnLogo }"
      @mouseover="hoverOnLogo = true"
      @mouseleave="hoverOnLogo = false"
    >
      <router-link :to="$defaultModule">
        <img
          v-if="!hoverOnLogo"
          src="../assets/simpli-icon.svg"
        />
        <img
          v-if="hoverOnLogo"
          src="../assets/simpli-logo-white.svg"
        />
      </router-link>
    </div>

    <div class="app_bar__module_name">{{ currentModuleName }}</div>

    <a
      class="app_bar__navigation__toggle"
      @click="mobileExpanded = !mobileExpanded"
    >
      <div class="app_bar__navigation__toggle__bar"></div>
      <div class="app_bar__navigation__toggle__bar"></div>
      <div class="app_bar__navigation__toggle__bar"></div>
    </a>

    <nav
      class="app_bar__navigation"
      :expanded="mobileExpanded"
    >
      <ul class="app_bar__navigation__primary">
        <!-- non admin routes -->
        <li
          v-for="(route, index) in primaryRoutes"
          :key="index"
          class="app_bar__navigation__item app_bar__navigation__item--link"
          :selected="route.name == $route.matched[0].name"
        >
          <!-- <router-link :to="{name: route.name}" :style="{ background: route.meta.navbarColorDefault }"> -->
          <router-link :to="{ name: route.name }">
            <font-awesome-icon
              :icon="route.meta.navbarIcon"
              class="app_bar__navigation__item__icon"
            />
            <span class="app_bar__navigation__item__label">{{
              route.name
            }}</span>
          </router-link>
        </li>
      </ul>

      <ul class="app_bar__navigation__secondary">
        <!-- admin heading -->
        <li
          v-if="currentUser.role_name == 'Admin'"
          class="app_bar__navigation__item app_bar__navigation__item--heading"
        >
          <span>ADMIN</span>
        </li>
        <!-- hepl -->
        <li class="app_bar__navigation__item app_bar__navigation__item--link">
          <a
            href="https://forms.monday.com/forms/3daf416480fc7c55d1a4b94932c1937d?r=use1"
            target="_blank"
          >
            <font-awesome-icon
              icon="headset"
              class="app_bar__navigation__item__icon"
            />
            <span class="app_bar__navigation__item__label">Support Desk </span>
          </a>
        </li>
        <!-- admin routes -->
        <div v-if="currentUser.role_name == 'Admin'">
          <li
            v-for="(route, index) in adminRoutes"
            :key="index"
            class="app_bar__navigation__item app_bar__navigation__item--link"
            :selected="route.name == $route.matched[0].name"
          >
            <router-link :to="{ name: route.name }">
              <font-awesome-icon
                :icon="route.meta.navbarIcon"
                class="app_bar__navigation__item__icon"
              />
              <span class="app_bar__navigation__item__label">{{
                route.name
              }}</span>
            </router-link>
          </li>
        </div>

        <!-- current user profile -->
        <li
          class="app_bar__navigation__item app_bar__navigation__item--link-modal"
          highlighted="true"
        >
          <a @click="openCurrentUserModal">
            <font-awesome-icon
              icon="user-circle"
              class="app_bar__navigation__item__icon"
            />
            <span class="app_bar__navigation__item__label">User Profile</span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- Modal Component -->
    <b-modal
      ref="moduleModal"
      title="Bootstrap-Vue"
      hide-footer
      hide-header
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <component
        :is="modalData.currentComponent"
        :item-id="modalData.itemId"
        :user-data="modalData.userData"
        :logged-user="modalData.loggedUser"
        @save-update-success="hideModal"
        @cancel="hideModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserProfileModal from '@/views/modals/UserProfile.vue'

/**
 * Navigation Bar. On mobile is displayed at the top. On Desktop is displayed on the side
 */
export default {
  name: 'Navbar',
  components: {
    UserProfileModal
  },
  filters: {
    firstLettersOfName: function (value) {
      const letters = value.split(' ')
      return `${letters[0].charAt(0).toUpperCase()}  ${letters[1].charAt(0).toUpperCase()}`
    }
  },
  props: {
    currentModuleName: String,
    currentModulePath: String
  },
  data() {
    return {
      modalData: {
        currentComponent: null,
        itemId: null,
        userData: null,
        loggedUser: null
      },
      user: {
        isAdmin: true
      },
      mobileExpanded: false,
      primaryRoutes: [],
      adminRoutes: [],
      hoverOnLogo: false,
      logo: {
        normal: '../assets/simpli-icon.svg',
        expanded: '../assets/simpli-logo-white.svg'
      }
    }
  },
  computed: {
    logoImage: function () {
      return !this.hoverOnLogo ? this.logo.normal : this.logo.expanded
    },
    currentUser: function () {
      const self = this

      // var currentUser = JSON.parse(self.$store.state.cognito.authenticated.storage._sUser);
      // self.$store.getters['userInSession']
      // self.$log.info(self.$store.getters['userInSession'], localStorage._sUser)
      const currentUser = JSON.parse(localStorage._sUser)

      return currentUser
    },
    currentUserIsAdmin: function () {
      const self = this

      return self.currentUser.role_name == 'Admin'
    },
    isCurrentUserProfile: function () {
      const self = this

      // return (self.currentUser.id == self.user.id);
      return self.currentUser.aws_user_id == self.user.aws_user_id
    }
  },
  mounted() {
    const self = this

    const allRoutes = self.$router.options.routes

    const routesForNavBar = _.filter(allRoutes, (route) => {
      return _.get(route, 'meta.showInNavbar', false)
    })

    // get the routes that requireAdmin
    self.adminRoutes = _.filter(routesForNavBar, (route) => {
      return _.get(route, 'meta.requiresAdmin', false)
    })

    // get the routes that do not require admin
    self.primaryRoutes = _.filter(routesForNavBar, (route) => {
      if (this.currentUser.role_name == 'Reporting Only') {
        return _.get(route, 'meta.requiresReportingOnly', true)
      }
      return !_.get(route, 'meta.requiresAdmin', false)
    })
  },
  methods: {
    openCurrentUserModal: function () {
      const self = this

      // set and get the data from the logged user
      self.modalData.loggedUser = JSON.parse(localStorage._sUser)

      // set the modal item id to the userId of the logged user
      // self.modalData.itemId = self.modalData.loggedUser.id;
      self.modalData.itemId = self.modalData.loggedUser.aws_user_id

      // set the modal item id to the userData of the logged user
      self.modalData.userData = self.modalData.loggedUser

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = UserProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },
    modalSaveUpdate: function (userData) {
      const self = this

      // find user's index in the data
      const userIndexInData = _.findIndex(self.data, { id: userData.id })

      // replace the user info in the data list
      self.data.splice(userIndexInData, 1, userData)

      // hide modal
      self.hideModal()
    },
    hideModal: function () {
      const self = this

      // show modal
      self.$refs.moduleModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/common.scss';

.first-letters {
  position: absolute;
  z-index: 999;
  font-size: 9px;
  left: 0;
  bottom: -30%;
  width: 100%;
  display: inline-block;
  pointer-events: none;
}

.app_bar {
  width: 100%;
  height: $spacing-unit * 4;
  background: $navbar-background;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 1002;

  @include box-shadow(3px 0px 9px rgba(0, 0, 0, 0.25));

  &__brand {
    width: $spacing-unit * 4;
    height: $spacing-unit * 4;
    line-height: $spacing-unit * 4;
    background: $navbar-brand-background;
    overflow: hidden;
    -webkit-transition: all 0.25s;
    /* Safari */
    transition: all 0.25s;

    &:hover {
      width: $spacing-unit * 11;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;

      &:hover {
        background-color: $navbar-brand-background--hover;
      }
    }

    img {
      height: 50%;
    }
  }

  &__module_name {
    height: $spacing-unit * 4;
    line-height: 6rem;
    padding: 0 $spacing-unit;
    color: $navbar-text;
    font-weight: 600;
    flex-grow: 1;
  }

  &__navigation__toggle {
    width: $spacing-unit * 4;
    height: $spacing-unit * 4;
    padding: 19px;
    cursor: pointer;
    -webkit-transition: background-color 0.25s;
    /* Safari */
    transition: background-color 0.25s;

    &:hover {
      background-color: $navbar-navigation-toggle-background--hover;
    }

    a {
      width: $spacing-unit * 4;
      height: $spacing-unit * 4;
    }

    &__bar {
      width: 100%;
      height: 4px;
      margin-bottom: 4px;
      background: $navbar-navigation-toggle-bars;
      margin-right: auto;
      margin-left: auto;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__navigation {
    position: absolute;
    background: $navbar-navigation-background;
    top: 100%;
    width: 100%;
    max-height: 0px;
    -webkit-transition: max-height 1s ease-in-out;
    transition: max-height 1s ease-in-out;
    overflow: hidden;

    &[expanded='true'] {
      max-height: 1000px;
    }

    &__item {
      padding: $spacing-unit;
      font-weight: 600;

      border: 4px solid $primary1 !important;
      border-radius: 10px !important;
      background: $primary1;

      &__label {
        color: white !important;
        text-decoration: none;
        font-weight: bold;
      }

      &__icon {
        display: none;
      }

      &--heading {
        display: none;
        @extend .no-select;
      }
    }
  }

  a {
    color: white !important;
    -webkit-transition: all 0.25s;
    /* Safari */
    transition: all 0.25s;
    cursor: pointer;
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: column;
    width: $spacing-unit * 4;
    height: 100vh;

    &__module_name,
    &__navigation__toggle {
      display: none;
    }

    &__navigation {
      position: relative;
      top: initial;
      flex-grow: 1;
      background: none;
      max-height: 2000px;
      overflow: initial;
      -webkit-transition: none;
      transition: none;

      &__secondary {
        position: absolute;
        width: 100%;
        bottom: 0px;
      }

      &__item {
        width: $spacing-unit * 4;
        text-align: center;
        color: $navbar-navigation-item-text;
        position: relative;
        padding: 0;
        margin-bottom: 1px;

        border: 4px solid $primary1 !important;
        border-radius: 10px !important;
        background: $primary1;
        // overflow: hidden;

        &[selected] {
          background: $navbar-navigation-item--selected-background;
          // margin-right: 5px;
          // width: calc(#{$spacing-unit*4} + 4px);

          border: 4px solid $primary1 !important;
          border-radius: 10px !important;

          a {
            border-radius: 6px !important;
            // @include box-shadow(0px 3px 6px rgba(black, 0.16));
            // @include box-shadow(-4px 5px 4px rgba(0, 0, 0, 0.18));
          }
        }

        &:before {
          content: '';
          width: 30%;
          height: 1px;
          background: $navbar-navigation-divisor-background;
          position: absolute;
          z-index: 0;
          top: -6px;
          left: 50%;
          margin-left: -15%;
        }

        &:first-child:before {
          display: none;
        }

        &[highlighted]:before {
          display: none;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;

          .app_bar__navigation__item__label {
            display: block;
            position: absolute;
            top: 50%;
            left: calc(100% + 1rem);
            left: -200px;
            z-index: 255;
            z-index: -1 !important;
            background: $navbar-navigation-item-label-background;
            font-size: 1.2rem;
            line-height: 1.2rem;
            border-radius: 5px;
            padding: 1.2rem;
            margin-top: -1.8rem;
            white-space: nowrap;
            opacity: 1;
            pointer-events: none;
            -webkit-transition: all 0.3s;
            /* Safari */
            transition: all 0.3s;
            /*      text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
                                text-rendering: optimizeLegibility !important; */
          }
        }

        &[highlighted='true'] {
          background: $navbar-navigation-item--highlighted-background;
        }

        &__icon {
          display: initial;
        }

        &__label {
          display: none;
        }

        &--heading {
          width: 100%;
          line-height: 3rem;
          font-size: 0.6rem;
          text-transform: uppercase;
          font-weight: 600;
          display: block;
        }

        &--link {
          width: 100%;
          height: $spacing-unit * 4;
          line-height: $spacing-unit * 4;
          font-size: 1.5rem;

          border: 4px solid $primary1 !important;
          border-radius: 10px !important;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px !important;

            &:hover {
              background: $navbar-navigation-item--hover-background;
              // width: calc(6rem + 4px);

              .app_bar__navigation__item__label {
                opacity: 1;
                left: calc(100% + 1rem);
                font-size: 1.3rem !important;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
                text-rendering: optimizeLegibility !important;
                -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
              }
            }
          }
        }

        // user
        &--link-modal {
          width: 100%;
          height: $spacing-unit * 4;
          line-height: $spacing-unit * 4;
          font-size: 1.5rem;
          background: $navbar-navigation-item--modal-background;

          border: 0 !important;
          border-radius: 0 !important;

          a {
            border: 0 !important;

            &:hover {
              background: $navbar-navigation-item--modal--hover-background;

              .app_bar__navigation__item__label {
                opacity: 1;
                left: calc(100% + 1rem);
              }
            }
          }
        }
      }
    }
  }
}
</style>
