<template>
  <section class="sModal">
    <Preloader :in-modal="true"></Preloader>

    <header
      v-if="itemId != null"
      class="sModal__header"
    >
      Campaign Settings
    </header>
    <header
      v-else
      class="sModal__header"
    >
      New Campaign
    </header>

    <form @submit.prevent="formSubmit">
      <nav class="sModal__tabs">
        <ul>
          <!--
            Workaround:
            Each tab requires validations. I wanted to use the native validation. So...
            I am using buttons for the tabs so it triggers the form submittion. This also changes the toSection var.
            The idea is that when the formSubmit method is called it checks against the toSection var, if it has value then
            it will change currentSection to toSection value, so the tab changes. If the form is submitted with
            no toSection value (e.g. when the save campaign button is hit), then it will just call saveData.
          -->
          <li :selected="currentSection == 'details'">
            <button @click="gotoSection('details')">Details</button>
          </li>
          <li
            v-if="itemId != null"
            :selected="currentSection == 'fallbacks'"
          >
            <button @click="gotoSection('fallbacks')">
              Default Creative Fallbacks
            </button>
          </li>
          <li
            v-if="itemId != null"
            :selected="currentSection == 'datasources'"
          >
            <button @click="gotoSection('datasources')">Data Sources</button>
          </li>
        </ul>
      </nav>

      <div
        v-if="currentSection == 'details'"
        class="sModal__body"
        :class="campTestBackground"
      >
        <FormField
          label="Campaign Source *"
          for-id="campaign-source"
          type="select"
        >
          <select
            v-if="itemId"
            id="campaign-source"
            v-model="campaign.campaign_source"
            :disabled="
              campaignSourceProp === 'Airtory Sync' ||
              (itemId !== null && campaignSourceProp === 'Celtra Sync')
            "
            required
          >
            -->
            <option
              value=""
              selected
              disabled
            >
              Select a Source
            </option>
            <option
              v-for="(campaignSource, index) in filteredCampaignSources"
              :key="index"
              :value="campaignSource.name"
            >
              {{ campaignSource.name }}
            </option>
          </select>
          <select
            v-else
            id="campaign-source"
            v-model="campaign.campaign_source"
            :disabled="
              (campaign.campaign_source === 'Airtory Sync' && itemId) ||
              (itemId !== null && campaign.campaign_source === 'Celtra Sync')
            "
            required
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Source
            </option>
            <option
              v-for="(campaignSource, index) in filteredCampaignSources"
              :key="index"
              :value="campaignSource.name"
            >
              {{ campaignSource.name }}
            </option>
          </select>
        </FormField>

        <div
          v-if="isCeltraSync"
          class="formFieldGroup"
        >
          <div
            class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
          >
            <FormField
              label="Celtra Campaign Id  *"
              for-id="celtra-id"
              class=""
            >
              <input
                id="celtra-id"
                v-model="campaign.external_campaign_id"
                type="text"
                maxlength="50"
                required
              />
            </FormField>
            <FormField>
              <button
                id="celtra-sync-button"
                :disabled="
                  campaign.external_campaign_id !== null &&
                  campaign.external_campaign_id.length < 4
                "
                @click.prevent="syncCampaignDetails"
              >
                <span
                  ><font-awesome-icon
                    icon="sync-alt"
                    class="i" /></span
                >FETCH CAMPAIGN DETAILS
              </button>
            </FormField>
          </div>
          <small
            >You have to hit the ’Save Campaign’ button in order for synced
            details to be saved.</small
          >
        </div>

        <FormField
          v-if="isAirtorySync"
          label="Airtory Campaign Id  *"
          for-id="airtory-id"
        >
          <div class="ratioInputs">
            <div class="mb1 mr1">
              <input
                id="new_campaign"
                v-model="typeOfData"
                type="radio"
                name="campaignNew"
                :disabled="isAirtorySync && itemId !== null"
                value="newCampaign"
              />
              New Campaign
            </div>
            <div class="mb1">
              <input
                id="not_new_campaign"
                v-model="typeOfData"
                type="radio"
                name="campaignNew"
                :disabled="isAirtorySync && itemId !== null"
                value="syncCampaign"
              />
              Campaign Id:
            </div>
          </div>

          <div class="formFieldGroup">
            <div
              class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
            >
              <FormField
                label=""
                for-id="airtory-id"
              >
                <input
                  id="airtory-id"
                  v-model="campaign.external_campaign_id"
                  type="text"
                  maxlength="50"
                  :disabled="
                    (isAirtorySync && !isSyncCampaign) ||
                    (isAirtorySync && itemId !== null)
                  "
                  required
                />
              </FormField>
              <FormField>
                <button
                  id="airtory-sync-button"
                  :disabled="
                    (campaign.external_campaign_id !== null &&
                      campaign.external_campaign_id.length < 4 &&
                      isSyncCampaign) ||
                    (isAirtorySync && !isSyncCampaign)
                  "
                  @click.prevent="syncCampaignDetails"
                >
                  <span
                    ><font-awesome-icon
                      icon="sync-alt"
                      class="i" /></span
                  >FETCH CAMPAIGN DETAILS
                </button>
              </FormField>
            </div>
            <small
              >You have to hit the ’Save Campaign’ button in order for synced
              details to be saved.</small
            >
          </div>
        </FormField>
        <FormField
          v-if="isPadSquadSync"
          label="PadSquad Campaign Id  *"
          for-id="padsquad-id"
        >
          <div class="ratioInputs">
            <div class="mb1 mr1">
              <input
                id="new_campaign"
                v-model="typeOfData"
                type="radio"
                name="campaignNew"
                :disabled="isPadSquadSync && itemId !== null"
                value="newCampaign"
              />
              New Campaign
            </div>
            <div class="mb1">
              <input
                id="not_new_campaign"
                v-model="typeOfData"
                type="radio"
                name="campaignNew"
                :disabled="isPadSquadSync && itemId !== null"
                value="syncCampaign"
              />
              Campaign Id:
            </div>
          </div>

          <div class="formFieldGroup">
            <div
              class="formFieldGroup__fields formFieldGroup__fields__bottom_align formFieldGroup__footer__text"
            >
              <FormField
                label=""
                for-id="padsquad-id"
              >
                <input
                  id="padsquad-id"
                  v-model="campaign.id"
                  type="text"
                  maxlength="50"
                  :disabled="
                    (isPadSquadSync && !isSyncCampaign) ||
                    (isPadSquadSync && itemId !== null)
                  "
                  required
                />
              </FormField>
              <FormField>
                <button
                  id="padsquad-sync-button"
                  :disabled="
                    (campaign.id !== null &&
                      campaign.id.length < 4 &&
                      isSyncCampaign) ||
                    (isPadSquadSync && !isSyncCampaign)
                  "
                  @click.prevent="syncCampaignDetails"
                >
                  <span
                    ><font-awesome-icon
                      icon="sync-alt"
                      class="i" /></span
                  >FETCH CAMPAIGN DETAILS
                </button>
              </FormField>
            </div>
            <small
              >You have to hit the ’Save Campaign’ button in order for synced
              details to be saved.</small
            >
          </div>
        </FormField>

        <FormField>
          <div class="toggleContainer">
            <button
              class="toggleButton"
              @click.prevent="checkTestCampaign($event)"
            >
              <font-awesome-icon
                v-show="!testCampaignChecked"
                icon="toggle-on"
                class="i"
              /><font-awesome-icon
                v-show="testCampaignChecked"
                icon="toggle-off"
                class="i"
              />Test Campaign on/off
            </button>
          </div>
        </FormField>

        <FormField
          label="Campaign Name *"
          for-id="campaign-name"
          :is-celtra="isCeltraSync"
          :is-airtory="isAirtorySync && isSyncCampaign"
        >
          <input
            id="campaign-name"
            v-model="campaign.name"
            type="text"
            required
          />
        </FormField>

        <FormField
          label="Salesforce Opportunity ID *"
          for-id="salesforce-id"
        >
          <input
            id="salesforce-id"
            v-model="campaign.salesforce_id"
            type="text"
            :disabled="campaign.test_campaign === 'test'"
            :required="campaign.test_campaign !== 'test'"
          />
        </FormField>

        <FormField
          v-model="campaign.advertiser"
          label="Advertiser  *"
          for-id="campaign-advertiser"
          :is-celtra="isCeltraSync"
          :is-airtory="isAirtorySync && isSyncCampaign"
        >
          <!-- <input v-model="campaign.advertiser" id="campaign-advertiser" type="text" required /> -->
          <autocomplete
            id="campaign-advertiser"
            ref="autocomplete"
            :initial-value="advertiserId"
            :initial-display="advertiserName"
            :show-no-results="false"
            :source="listAdvertisersArray"
            results-property="name"
            placeholder=""
            :show-search-icon="false"
            :show-close-icon="false"
            :allow-out-of-list="true"
            :required="true"
            @selected="onAutocompleteSelected"
          >
          </autocomplete>
          <p
            v-if="advertiserLength <= 1"
            class="required-message"
          >
            <small
              >The ADVERTISER field must be at least 2 characters in length.
            </small>
          </p>
        </FormField>

        <FormField
          label="Deal Type *"
          for-id="deal-type"
          type="select"
          :is-celtra="isCeltraSync"
          :is-airtory="isAirtorySync && isSyncCampaign"
        >
          <select
            id="deal-type"
            v-model="campaign.deal_type_id"
            required
          >
            <option
              value=""
              selected
              disabled
            >
              Select a Deal Type
            </option>
            <option
              v-for="(dealType, index) in dealTypes"
              :key="index"
              :value="dealType.id"
            >
              {{ dealType.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Primary KPI *"
          for-id="creative-kpi"
          type="select"
        >
          <select
            id="creative-kpi"
            v-model="campaign.kpi_id"
            required
            @change="onChangeKPI($event, 'kpi1')"
          >
            <option
              value="0"
              selected
            >
              No Primary KPI
            </option>
            <option
              v-for="(kpi, index) in KPIs"
              :key="index"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Primary KPI Goal (Client Benchmark 1)"
          for-id="campaign-kpi-goal"
        >
          <input
            id="campaign-kpi-goal"
            v-model="campaign.kpi_goal"
            :disabled="campaign.kpi_id == 0"
            type="text"
            @keyup="inputKPI($event)"
          />
          <small v-if="kpiDescription">{{ kpiDescription }}</small>
        </FormField>

        <FormField
          label="Secondary KPI"
          for-id="creative-kpi2"
          type="select"
        >
          <select
            id="creative-kpi2"
            v-model="campaign.kpi2_id"
            @change="onChangeKPI($event, 'kpi2')"
          >
            <option value="0">No Secondary KPI</option>
            <option
              v-for="(kpi, index) in KPIs"
              :key="index"
              :value="kpi.id"
            >
              {{ kpi.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Secondary KPI Goal (Client Benchmark 2)"
          for-id="campaign-kpi2-goal"
        >
          <input
            id="campaign-kpi2-goal"
            v-model="campaign.kpi2_goal"
            :disabled="campaign.kpi2_id == 0"
            type="text"
            @keyup="inputKPI($event)"
          />
          <small v-if="kpiDescription2">{{ kpiDescription2 }}</small>
        </FormField>

        <div class="formFieldGroup">
          <div
            v-if="showDate"
            class="date-range-picker-common"
          >
            <date-range-picker
              ref="picker"
              v-model="dateRangeData"
              opens="inline"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
              :min-date="null"
              :max-date="null"
              :single-date-picker="false"
              :time-picker="false"
              :time-picker24-hour="false"
              :show-week-numbers="true"
              :show-dropdowns="false"
              :ranges="false"
              :auto-apply="true"
              @select="updateValues"
            >
            </date-range-picker>
          </div>

          <label>Flight Dates *</label>
          <div class="formFieldGroup__fields">
            <FormField
              type="date"
              tag-left="Start"
              required
            >
              <input
                id="campaign-flight-start"
                v-model="campaignStartDateInput"
                :max="campaign.end_date"
                aria-required="true"
                type="text"
                required
                @click="showDate = !showDate"
              />
            </FormField>

            <FormField
              type="date"
              tag-left="End"
              required
            >
              <input
                id="campaign-flight-end"
                v-model="campaignEndDateInput"
                :min="campaign.start_date"
                aria-required="true"
                type="text"
                required
                @click="showDate = !showDate"
              />
            </FormField>
          </div>
        </div>

        <FormField
          label="Measurement Partner"
          for-id="campaign-measurement-partner"
          type="select"
        >
          <select
            id="campaign-measurement-partner"
            v-model="campaign.measurement_partner_id"
          >
            <option value="null">No Measurement Partner</option>
            <option
              v-for="(partner, index) in partnersMeasurement"
              :key="index"
              :value="partner.id"
            >
              {{ partner.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Verification Partner"
          for-id="campaign-verification-partner"
          type="select"
        >
          <select
            id="campaign-verification-partner"
            v-model="campaign.verification_partner_id"
          >
            <option value="null">No Verification Partner</option>
            <option
              v-for="(partner, index) in partnersVerification"
              :key="index"
              :value="partner.id"
            >
              {{ partner.name }}
            </option>
          </select>
        </FormField>

        <FormField
          label="Status *"
          for-id="campaign-status"
          type="select"
        >
          <select
            id="campaign-status"
            v-model="campaign.active"
            required
          >
            <option
              v-for="(status, index) in Status"
              :key="index"
              :value="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </FormField>
      </div>

      <!-- FALLBACKS -->
      <div
        v-if="currentSection == 'fallbacks'"
        class="sModal__body"
      >
        <!-- FALLBACKS URL -->
        <FormField
          label="CLICKTHROUGH URL"
          for-id="url-id"
        >
          <div class="tileBoxBack">
            <div class="mb0">
              <input
                id="url-adserver"
                v-model="campaign.use_click_from_adserver"
                type="checkbox"
                @change="toggleShowInputUrl"
              />
              Use URL from adserver
            </div>
            <input
              v-if="showInputUrl"
              id="url-id"
              v-model="campaign.campaign_url"
              class="mt1"
              type="text"
              placeholder="Custom URL here"
            />
          </div>
        </FormField>

        <div
          v-for="(fallback, index) in campaign.fallbackImages"
          :key="index"
        >
          <FormField :label="`Fallback ${fallback.format_size}`">
            <div class="tileBoxBack">
              <div
                v-if="fallback.image_url != null"
                class="tileBox"
              >
                <label>IMAGE</label>
                <ul>
                  <li>
                    <div
                      class="tileBox__image"
                      :style="{
                        'background-image':
                          'url(' +
                          fallback.image_url +
                          '?date=' +
                          new Date().toISOString() +
                          ')'
                      }"
                    >
                      <button
                        style="right: 27px"
                        @click.prevent="removeFallbackImage(fallback, index)"
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                          class="i"
                        />
                      </button>
                      <button
                        @click.prevent="
                          downloadAsset({
                            name:
                              fallback.campaign_id + '_' + fallback.format_size,
                            path: fallback.image_url
                          })
                        "
                      >
                        <font-awesome-icon
                          icon="download"
                          class="i"
                        />
                      </button>
                    </div>
                  </li>
                </ul>
                <!-- <small v-if="fallback.image_status=='new'">* You must save campaign in order for New and Removed Design Fallback Image to be saved.</small> -->
              </div>

              <FormField
                class="mb0"
                label="Upload Image"
                description=""
                for-id="campaign-delivered-impressions"
              >
                <b-form-file
                  ref="file-input"
                  :key="index"
                  v-model="assetUploader.file"
                  class="button-file"
                  accept="image/jpeg, image/png"
                  :state="Boolean(assetUploader.file)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="uploadFallbackImages($event, index)"
                >
                </b-form-file>
              </FormField>
            </div>
          </FormField>
        </div>
      </div>

      <!-- Data Sources -->
      <div
        v-if="currentSection == 'datasources'"
        class="sModal__body"
      >
        <div class="contentTableWrapper">
          <table class="contentTable">
            <thead>
              <tr>
                <th class="contentTable__dimension tdrow">Campaign Source</th>
                <th class="column-text-align-center">Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="contentTable__dimension">Airtory</td>
                <td
                  column-name="Status"
                  class="report-status column-text-align-center"
                >
                  <font-awesome-icon
                    v-report-status="avdata.Airtory"
                    icon="circle"
                  />
                </td>
                <td
                  v-description-status="avdata.Airtory"
                  column-name="Description"
                ></td>
              </tr>
              <tr>
                <td>Celtra</td>
                <td
                  column-name="Status"
                  class="report-status column-text-align-center"
                >
                  <font-awesome-icon
                    v-report-status="avdata.Celtra"
                    icon="circle"
                  />
                </td>
                <td
                  v-description-status="avdata.Celtra"
                  column-name="Description"
                ></td>
              </tr>
              <tr>
                <td>Moat</td>
                <td
                  column-name="Status"
                  class="report-status column-text-align-center"
                >
                  <font-awesome-icon
                    v-report-status="avdata.Moat"
                    icon="circle"
                  />
                </td>
                <td
                  v-description-status="avdata.Moat"
                  column-name="Description"
                ></td>
              </tr>
              <tr>
                <td>DV</td>
                <td
                  column-name="Status"
                  class="report-status column-text-align-center"
                >
                  <font-awesome-icon
                    v-report-status="avdata.DV"
                    icon="circle"
                  />
                </td>
                <td
                  v-description-status="avdata.DV"
                  column-name="Description"
                ></td>
              </tr>
              <tr>
                <td>DCM</td>
                <td
                  column-name="Status"
                  class="report-status column-text-align-center"
                >
                  <font-awesome-icon
                    v-report-status="avdata.DCM"
                    icon="circle"
                  />
                </td>
                <td
                  v-description-status="avdata.DCM"
                  column-name="Description"
                ></td>
              </tr>
              <tr>
                <td>IAS</td>
                <td
                  column-name="Status"
                  class="report-status column-text-align-center"
                >
                  <font-awesome-icon
                    v-report-status="avdata.IAS"
                    icon="circle"
                  />
                </td>
                <td
                  v-description-status="avdata.IAS"
                  column-name="Description"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="ds-legend-wrap">
          <ul>
            <li>
              <font-awesome-icon
                style="color: green"
                icon="circle"
              /><span>Processed</span>
            </li>
            <li>
              <font-awesome-icon
                style="color: orange"
                icon="circle"
              /><span>Warning</span>
            </li>
            <li>
              <font-awesome-icon
                style="color: orangered"
                icon="circle"
              /><span>Warning and Error</span>
            </li>
            <li>
              <font-awesome-icon
                style="color: red"
                icon="circle"
              /><span>Error</span>
            </li>
            <li>
              <font-awesome-icon
                style="color: gray"
                icon="circle"
              /><span>No data received | N/A</span>
            </li>
            <li>
              <font-awesome-icon
                style="color: black"
                icon="circle"
              /><span>No data received today</span>
            </li>
          </ul>
        </div>
      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button
            class="cancelButton"
            @click.prevent="cancel"
          >
            <font-awesome-icon
              icon="ban"
              class="i"
            />Cancel
          </button>
        </div>

        <div class="sModal__footer__right">
          <!-- show 'Save Campaign' if this not a new campaign -->
          <button
            v-if="itemId != null"
            :disabled="!isDataModified"
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Save Campaign
          </button>

          <!-- show 'Save New Campaign' if this is new campaign and we are on the last screen-->
          <button
            v-if="itemId == null"
            :disabled="!isDataModified"
            class="saveButton"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />Create Campaign
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/ApiTagManager.js'
import Utils from '@/helpers/Utils.js'
import Preloader from '@/components/Preloader.vue'
import Autocomplete from '@/components/Autocomplete.vue'
import DateRangePicker from 'vue2-daterange-picker'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CampaignProfile',
  components: {
    FormField,
    Preloader,
    Autocomplete,
    DateRangePicker
  },
  directives: {
    'report-status': {
      bind(el, binding, vnode) {
        if (binding.value == 'Processed') {
          el.style.color = 'green'
        } else {
          if (binding.value == 'No data received' || binding.value == 'N/A') {
            el.style.color = 'gray'
          } else if (binding.value == 'Warning') {
            el.style.color = 'orange'
          } else if (binding.value == 'Warning and Error') {
            el.style.color = 'orangered'
          } else if (binding.value == 'Error') {
            el.style.color = 'red'
          } else {
            el.style.color = 'black'
          }
        }
      },
      update: function (el, binding, vnode) {
        if (binding.value == 'Processed' || binding.value == 'N/A') {
          el.style.color = 'green'
        } else {
          if (binding.value == 'No data received') {
            el.style.color = 'gray'
          } else if (binding.value == 'Warning') {
            el.style.color = 'orange'
          } else if (binding.value == 'Warning and Error') {
            el.color = 'orangered'
          } else if (binding.value == 'Error') {
            el.style.color = 'red'
          } else {
            el.style.color = 'black'
          }
        }
      }
    },
    'description-status': {
      bind(el, binding, vnode) {
        if (binding.value == 'Processed') {
          el.innerHTML = 'All required columns are present'
        } else {
          if (binding.value == 'No data received') {
            el.innerHTML = 'No data received'
          } else if (binding.value == 'Warning') {
            el.innerHTML = 'Non-critical column missing'
          } else if (binding.value == 'Warning and Error') {
            el.innerHTML = "Critical and Non-critical column's missing"
          } else if (binding.value == 'Error') {
            el.innerHTML = 'Critical column missing'
          } else if (binding.value == 'N/A') {
            el.innerHTML = 'Vendor not associated with this campaign'
          } else {
            el.innerHTML = 'No data received today'
          }
        }
      },
      update: function (el, binding, vnode) {
        if (binding.value == 'Processed') {
          el.innerHTML = 'All required columns are present'
        } else {
          if (binding.value == 'No data received') {
            el.innerHTML = 'No data received'
          } else if (binding.value == 'Warning') {
            el.innerHTML = 'Non-critical column missing'
          } else if (binding.value == 'Warning and Error') {
            el.innerHTML = "Critical and Non-critical column's missing"
          } else if (binding.value == 'Error') {
            el.innerHTML = 'Critical column missing'
          } else if (binding.value == 'N/A') {
            el.innerHTML = 'Vendor not associated with this campaign'
          } else {
            el.innerHTML = 'No data received today'
          }
        }
      }
    }
  },
  props: {
    /* if this prop comes empty, the template considers this as a new user */
    itemId: null,
    advertiserId: null,
    advertiserName: null,
    currentSectionProp: null,
    campaignSourceProp: null
  },
  data() {
    return {
      loading: true,
      sections: ['details', 'fallbacks', 'datasources'],
      currentSection: 'details',
      assetUploader: {
        file: null
      },
      campaignStartDateInput: '',
      campaignEndDateInput: '',
      typeOfData: 'newCampaign',
      // {
      //   "deal_type_id": 1,
      //   "name": "Simpli Campaign Q1-Q2",
      //   "campaign_url": "http://simpli.com",
      //   "campaign_source": "Airtory Sync",
      //   "advertiser_id": 1,
      //   "advertiser": "asdfadsf",
      //   "kpi_id": 2,
      //   "account_id": 1,
      //   "use_click_from_adserver": 0,
      //   "created_by": "skdjfk-asdf-asdfasdfp2-asdf",
      //   "active": 0,
      //   "start_date": "2020-06-29T18:30:00.000Z",
      //   "end_date": "2020-05-31T18:30:00.000Z"
      // }
      setKpiOption: null,
      setKpiOption2: null,
      kpiDescription: null,
      kpiDescription2: null,
      avdata: null,
      campaignId: '',
      campaign: {
        id: null,
        name: '',
        campaign_source: '',
        external_campaign_id: '',
        advertiser: '',
        advertiser_id: '',
        kpi: null,
        kpi_id: 0,
        kpi_goal: null,
        kpi2_id: 0,
        kpi2_goal: null,
        start_date: null,
        end_date: null,
        verification_partner_id: null,
        measurement_partner_id: null,
        deal_type: {},
        mainKPI_id: null,
        deal_id: null,
        deal_type_id: '',
        active: 1,
        campaign_url: '',
        created_by: '',
        modified_date: '',
        created_date: '',
        account: {},
        placements: [],
        fallbackImages: [],
        creatives: [],
        salesforce_id: null,
        test_campaign: ''
      },
      synced: false,
      showInputUrl: true,
      fallbackImagesDefault: [
        {
          image_url: null,
          format_size: '300x250'
        },
        {
          image_url: null,
          format_size: '320x50'
        },
        {
          image_url: null,
          format_size: '728x90'
        },
        {
          image_url: null,
          format_size: '728x250'
        },
        {
          image_url: null,
          format_size: '970x250'
        }
      ],
      showDate: false,
      dateRangeData: {
        startDate: new Date(),
        endDate: new Date()
      },
      testCampaignChecked: false,
      campTestBackground: 'white_bg'
    }
  },
  computed: {
    ...mapGetters('campaigns', ['itemById']),
    ...mapGetters('advertisers', {
      listAdvertisers: 'list',
      advertiserById: 'itemById',
      listAdvertisersArray: 'listAdvertisersArray'
    }),
    ...mapGetters([
      'campaignSources',
      'Status',
      'dealTypes',
      'KPIs',
      'partners',
      'partnersMeasurement',
      'partnersVerification',
      'userInSession'
    ]),
    filteredCampaignSources: function () {
      const sources = _.cloneDeep(this.campaignSources)

      if (this.campaignSources === 'Celtra Sync') {
        return sources.filter((item) => item.name !== 'Airtory Sync')
      } else if (this.campaignSources === 'Airtory Sync') {
        return sources.filter((item) => item.name === 'Airtory Sync')
      } else {
        return sources
      }
    },
    isAirtorySync: function () {
      return this.campaign.campaign_source === 'Airtory Sync'
    },
    isPadSquadSync: function () {
      return this.campaign.campaign_source === 'PadSquad'
    },
    isSyncCampaign: function () {
      return this.typeOfData === 'syncCampaign'
    },
    isCeltraSync: function () {
      return this.campaign.campaign_source === 'Celtra Sync'
    },
    activeDesignFiles: function (index) {
      const self = this
      // a list of design files that are not status = removed.
      return _.filter(self.campaign.fallbackImages, (di) => {
        return di.status != 'removed'
      })
    },
    isFirstSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return self.sections.indexOf(self.currentSection) + 1 == 1
    },
    isLastSection: function () {
      const self = this
      // return true if currentSection is the last position in the sections array.
      return (
        self.sections.indexOf(self.currentSection) + 1 == self.sections.length
      )
    },
    prevSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex = self.sections.indexOf(self.currentSection) - 1

      // return the section name on the next position in the sections array;
      return self.sections[currentSectionIndex]
    },
    nextSection: function () {
      const self = this
      // get the current inde of the currentSection in the sections array.
      const currentSectionIndex = self.sections.indexOf(self.currentSection) + 1

      // return the section name on the next position in the sections array;
      return self.sections[currentSectionIndex]
    },
    isDataModified: function () {
      const self = this

      return !_.isEqual(self.campaign, self.itemById(self.itemId))
    },
    advertiserLength: function () {
      return this.campaign.advertiser == null
        ? 0
        : this.campaign.advertiser.trim().length
    }
  },
  watch: {
    /* when the section is changed */
    currentSection: function (value) {
      const self = this
    },
    itemId: function () {
      self.campaign.fallbackImages = _.map(
        self.fallbackImagesDefault,
        function (item) {
          return _.merge(
            item,
            _.find(self.campaign.fallbackImages, {
              format_size: item.format_size
            })
          )
        }
      )
    },
    // campaign.advertiser: function(){

    // },
    listAdvertisers: function () {
      // this.$log.info.log('====================================');
      // this.$log.info.log('ADVERTISERS', this.listAdvertisers);
      // this.$log.info.log('====================================');
    },
    campaignStartDateInput: function (value) {
      const self = this

      // update the campaign flight dates
      self.campaign.start_date = self
        .$moment(self.campaignStartDateInput)
        .utc()
        .toISOString()
    },
    campaignEndDateInput: function (value) {
      const self = this

      // update the campaign flight dates
      self.campaign.end_date = self
        .$moment(self.campaignEndDateInput)
        .utc()
        .toISOString()
    }
  },
  created() {
    const self = this

    // fetch advertisers
    self.fetchAdvertisers()

    // if we are updating an existing campaign, let's fetch the meta data.
    self.$log.info('itemId', self.itemId)
    if (self.itemId) {
      self.fetchById(self.itemId).then((response) => {
        self.campaign = _.cloneDeep(response)
        self.$log.info(
          'SOURCE, ITEM_ID',
          self.campaign.campaign_source,
          self.campaignSourceProp,
          self.itemId
        )
        self.campaign.test_campaign === 'test'
          ? ((self.testCampaignChecked = true),
            (this.campTestBackground = 'gray_bg'))
          : (self.testCampaignChecked = false)
        // populate form
        self.populateForm()
      })
    } else {
      // IF IS AIRTORY FROM NEW CAMPAIGN
      self.campaign.campaign_source = ''
      // self.$log.info('SOURCE', self.campaign.campaign_source, self.campaignSourceProp,);

      // populate form
      self.populateForm()
    }
    // GET DATASOURCE FROM CAMPAING ID
    self.fetchAvailabilityReports(self.itemId).then((response) => {
      self.avdata = response.data
    })
  },
  beforeUnmount() {
    const self = this
  },
  methods: {
    ...mapActions('campaigns', [
      'read',
      'update',
      'create',
      'syncCeltra',
      'fetchAirtoryCampaign',
      'fetchCampaignById',
      'fetchById'
    ]),
    ...mapActions('analytics', {
      fetchAvailabilityReports: 'fetchAvailabilityReports'
    }),
    ...mapActions('advertisers', {
      createAdvertiser: 'create',
      fetchAdvertisers: 'fetch',
      setAdvertiser: 'setAdvertiser'
    }),
    ...mapActions(['registerTask', 'completeTask']),
    onChangeKPI($event, kpiselected) {
      if (kpiselected == 'kpi1') {
        if (this.campaign.kpi_id !== 0) {
          const kpiId = this.campaign.kpi_id
          const kpiname
            = kpiId > 0 ? this.KPIs.find((kpi) => kpi.id == kpiId).name : ''

          this.selectKpiGoal(kpiselected, kpiname)
        }
      } else {
        if (this.campaign.kpi2_id === 0) {
          return
        } else {
          const kpiId = this.campaign.kpi2_id
          const kpiname
            = kpiId > 0 ? this.KPIs.find((kpi) => kpi.id == kpiId).name : ''

          this.selectKpiGoal(kpiselected, kpiname)
        }
      }
    },
    selectKpiGoal(kpiselected, kpiname) {
      const self = this
      // kpi's object base on type of value
      const kpiList = [
        {
          name: 'integers',
          values: [
            'Impressions - 3rd Party',
            'Engagements',
            'Clicks',
            'Video Views - internal data',
            'Conversions',
            'Brand Study'
          ]
        },
        {
          name: 'percents',
          values: [
            'Engagement Rate (ER)',
            'Video Completion',
            'Video Completion Rate (VCR)',
            'CTR - 3rd Party',
            'Viewability',
            'Brand Lift',
            'Click Through Rate (CTR)',
            'Other'
          ]
        },
        { name: 'times', values: ['Time Spent'] }
      ]

      function findParent(kpiList, value) {
        for (let i = 0; i < kpiList.length; i++) {
          if (kpiList[i].values.indexOf(value) > -1) {
            return kpiList[i].name
          }
        }
      }
      // test findParent function and then parse kpi input value if needed
      if (kpiselected == 'kpi1') {
        self.setKpiOption = findParent(kpiList, kpiname)
        switch (self.setKpiOption) {
          case 'integers':
            self.kpiDescription = 'Amount in whole numbers'
            if (self.campaign.kpi_goal === '') {
              self.campaign.kpi_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.campaign.kpi_goal !== null) {
              self.campaign.kpi_goal = Math.round(
                parseFloat(self.campaign.kpi_goal)
              )
            }
            break
          case 'percents':
            self.kpiDescription = 'Amount in numbers %'
            if (self.campaign.kpi_goal === '') {
              self.campaign.kpi_goal = null
            } // this is to avoid NaN% on input if user delete the goal
            if (self.campaign.kpi_goal !== null) {
              self.campaign.kpi_goal = parseFloat(self.campaign.kpi_goal) + '%'
            }
            break
          case 'times':
            self.kpiDescription = 'Amount in seconds'
            if (self.campaign.kpi_goal !== null) {
              if (self.campaign.kpi_goal === '') {
                self.campaign.kpi_goal = null
              } // this is to avoid NaN on input if user delete the goal
              if (self.campaign.kpi_goal !== null) {
                self.campaign.kpi_goal = Math.round(
                  parseFloat(self.campaign.kpi_goal)
                )
              }
            }
            break
          case undefined:
            self.kpiDescription = null
            self.campaign.kpi_goal = null
            break
        }
        console.log(self.setKpiOption)
      } else {
        self.setKpiOption2 = findParent(kpiList, kpiname)
        switch (self.setKpiOption2) {
          case 'integers':
            self.kpiDescription2 = 'Amount in whole numbers'
            if (self.campaign.kpi2_goal === '') {
              self.campaign.kpi2_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.campaign.kpi2_goal !== null) {
              self.campaign.kpi2_goal = Math.round(
                parseFloat(self.campaign.kpi2_goal)
              )
            }
            break
          case 'percents':
            self.kpiDescription2 = 'Amount in numbers %'
            if (self.campaign.kpi2_goal === '') {
              self.campaign.kpi2_goal = null
            } // this is to avoid NaN% on input if user delete the goal
            if (self.campaign.kpi2_goal !== null) {
              self.campaign.kpi2_goal
                = parseFloat(self.campaign.kpi2_goal) + '%'
            }
            break
          case 'times':
            self.kpiDescription2 = 'Amount in seconds'
            if (self.campaign.kpi2_goal === '') {
              self.campaign.kpi2_goal = null
            } // this is to avoid NaN on input if user delete the goal
            if (self.campaign.kpi2_goal !== null) {
              self.campaign.kpi2_goal = Math.round(
                parseFloat(self.campaign.kpi2_goal)
              )
            }
            break
          case undefined:
            self.kpiDescription2 = null
            self.campaign.kpi2_goal = null
            break
        }
        console.log(self.setKpiOption2)
      }
    },
    inputKPI: function (e) {
      let kpi_goal,
        characterReg = /([0-9.])$/
      if (e.target.id == 'campaign-kpi-goal') {
        kpi_goal = this.campaign.kpi_goal
        if (this.setKpiOption == 'percents') {
          characterReg = /^[0-9.]+%?$/
        } else if (this.setKpiOption == 'times') {
          characterReg = /^[0-9]+$/
        } else {
          characterReg = /([0-9])$/
        }
        if (!characterReg.test(kpi_goal)) {
          if (this.campaign.kpi_goal != null) {
            this.campaign.kpi_goal = this.campaign.kpi_goal.slice(0, -1)
          }
          e.preventDefault()
        }
      } else {
        kpi_goal = this.campaign.kpi2_goal
        if (this.setKpiOption2 == 'percents') {
          characterReg = /^[0-9.]+%?$/
        } else if (this.setKpiOption2 == 'times') {
          characterReg = /^[0-9]+$/
        } else {
          characterReg = /([0-9])$/
        }
        if (!characterReg.test(kpi_goal)) {
          if (this.campaign.kpi2_goal != null) {
            this.campaign.kpi2_goal = this.campaign.kpi2_goal.slice(0, -1)
          }
          e.preventDefault()
        }
      }
    },
    checkTestCampaign: function (e) {
      const self = this
      self.testCampaignChecked = !self.testCampaignChecked
      self.campaign.salesforce_id = ''
      self.testCampaignChecked
        ? ((self.campaign.test_campaign = 'test'),
          (self.campTestBackground = 'gray_bg'))
        : ((self.campaign.test_campaign = 'non-test'),
          (self.campTestBackground = 'white_bg'))
    },
    informUser: function (e) {
      this.$swal({
        position: 'top',
        title: e,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'info',
        timer: 15000,
        toast: false
      })
    },
    toDecimalValue: function (e) {
      console.log('Value', Number(e.target.value))
      return Number(e.target.value).toFixed(2)
    },
    onAutocompleteSelected: function (data) {
      this.campaign.advertiser_id = data.value
      this.campaign.advertiser = data.display
    },
    syncCampaignDetails: function () {
      const self = this

      // self.$log.info("Sync campaign", self.campaign);

      // turn on loading spinner
      self.loading = true

      if (this.isAirtorySync) {
        self.$log.info('fetch Airtory Sync', self.campaign.external_campaign_id)
        self
          .fetchAirtoryCampaign(self.campaign.external_campaign_id)
          .then((response) => {
            _.assign(self.campaign, response)

            self.campaign.name = response.cname

            self.campaignStartDateInput = response.start
              ? self.$moment(response.start).utc().format('YYYY-MM-DD')
              : ''
            self.campaignEndDateInput = response.end
              ? self.$moment(response.end).utc().format('YYYY-MM-DD')
              : ''

            const dealTypeObject = _.get(
              _.keyBy(this.dealTypes, 'name'),
              `${response.deal_type}`,
              ''
            )
            if (dealTypeObject) self.campaign.deal_type_id = dealTypeObject.id

            let advertiserObject = _.get(
              _.keyBy(this.listAdvertisersArray, 'name'),
              `${response.advertiser}`,
              ''
            )
            if (advertiserObject) {
              self.campaign.advertiser_id = advertiserObject.id
              this.setAdvertiser(advertiserObject)
            } else {
              // create advertiser
              advertiserObject = {
                id: response.advertiser_id,
                name: response.advertiser
              }
              this.setAdvertiser(advertiserObject)

              this.createAdvertiser({ name: response.advertiser }).then(
                (data) => {
                  this.$log.info('advertiser', data)
                  advertiserObject.id = self.campaign.advertiser_id = data.id
                }
              )
            }

            self.$log.info('Sync campaign response', response)

            self.loading = false
            self.synced = true
          })
          .catch((error) => {
            // notify the user
            alert(
              'There was a problem syncing this campaign. Please try again.'
            )
            self.synced = false
          })
          .finally(() => {
            // turn off loading spinner
            self.loading = false

            // emit event
            // self.$emit('done');
          })
      } else if (!this.isPadSquadSync && !this.isAirtorySync) {
        self
          .syncCeltra(self.campaign.external_campaign_id)
          .then((response) => {
            _.assign(self.campaign, response)

            self.campaign.external_campaign_id = self.campaign.campaign_id
            // self.$log.info('Celtra Camapign', response, self.campaign);

            const dealTypeObject = _.get(
              _.keyBy(this.dealTypes, 'name'),
              `${response.deal_type}`,
              ''
            )
            if (dealTypeObject) self.campaign.deal_type_id = dealTypeObject.id

            let advertiserObject = _.get(
              _.keyBy(this.listAdvertisersArray, 'name'),
              `${response.advertiser}`,
              ''
            )
            if (advertiserObject) {
              self.campaign.advertiser_id = advertiserObject.id
              this.setAdvertiser(advertiserObject)
            } else {
              // create advertiser
              advertiserObject = {
                id: response.advertiser_id,
                name: response.advertiser
              }
              this.setAdvertiser(advertiserObject)

              this.createAdvertiser({ name: response.advertiser }).then(
                (data) => {
                  // this.$log.info('advertiser', data)
                  advertiserObject.id = self.campaign.advertiser_id = data.id
                }
              )
            }

            // this.$log.info("Sync campaign ", self.campaign, this.listAdvertisersArray, advertiserObject, response.advertiser);

            self.synced = true
          })
          .catch((error) => {
            // notify the user
            alert(
              'There was a problem syncing this campaign. Please try again.'
            )
            self.synced = false
          })
          .finally(() => {
            // turn off loading spinner
            self.loading = false

            // emit event
            // self.$emit('done');
          })
      } else if (this.isPadSquadSync) {
        self.$log.info('fetch PadSquad Sync', self.campaign.id)
        self
          .fetchCampaignById(self.campaign.id)
          .then((response) => {

            if (response.campaign_source !== 'PadSquad') {
              return alert('Invalid PadSquad campaign ID provided.')
            }
            // Since Airtory uses a different API request the reponse needs to be filtered
            // below keys are fields that get removed from response to not interupt campaign sync
            const keysToRemove = [
              'campaign_source',
              'salesforce_id',
              'deal_type_id',
              'kpi_goal',
              'kpi2_goal',
              'kpi_id',
              'kpi2_id',
              'measurement_partner_id',
              'verification_partner_id'
            ]
            // new variable that will hold the correct fields
            const dataToAssignFromResponse = {}
            // logic to remove fields
            for (const key in response) {
              if (response.hasOwnProperty(key) && !keysToRemove.includes(key)) {
                dataToAssignFromResponse[key] = response[key]
              }
            }

            _.assign(self.campaign, dataToAssignFromResponse)

            self.campaign.name = dataToAssignFromResponse.name

            self.campaignStartDateInput = dataToAssignFromResponse.start_date
              ? self
                  .$moment(dataToAssignFromResponse.start)
                  .utc()
                  .format('YYYY-MM-DD')
              : ''
            self.campaignEndDateInput = dataToAssignFromResponse.end_date
              ? self
                  .$moment(dataToAssignFromResponse.end)
                  .utc()
                  .format('YYYY-MM-DD')
              : ''

            const dealTypeObject = _.get(
              _.keyBy(this.dealTypes, 'name'),
              `${dataToAssignFromResponse.deal_type}`,
              ''
            )
            if (dealTypeObject) self.campaign.deal_type_id = dealTypeObject.id

            let advertiserObject = _.get(
              _.keyBy(this.listAdvertisersArray, 'name'),
              `${dataToAssignFromResponse.advertiser}`,
              ''
            )
            if (advertiserObject) {
              self.campaign.advertiser_id = advertiserObject.id
              this.setAdvertiser(advertiserObject)
            } else {
              // create advertiser
              advertiserObject = {
                id: dataToAssignFromResponse.advertiser_id,
                name: dataToAssignFromResponse.advertiser
              }
              this.setAdvertiser(advertiserObject)

              this.createAdvertiser({
                name: dataToAssignFromResponse.advertiser
              }).then((data) => {
                this.$log.info('advertiser', data)
                advertiserObject.id = self.campaign.advertiser_id = data.id
              })
            }

            self.$log.info('Sync campaign response', dataToAssignFromResponse)

            self.loading = false
            self.synced = true
          })
          .catch((error) => {
            // notify the user
            alert(
              'There was a problem syncing this campaign. Please try again.'
            )
            self.synced = false
          })
          .finally(() => {
            // turn off loading spinner
            self.loading = false

            // emit event
            // self.$emit('done');
          })
      }
    },
    gotoSection: function (section) {
      const self = this

      // if this a new campaign and if the section we are trying to go to is previous to the currentSection (More to the left)
      if (!self.itemId && self.isPrevSection(section)) {
        // set toSection so the formSubmit knows we are navigation to another section.
        self.toSection = section

        // just change currentSection so we move and bypass the validation
        self.currentSection = section
      } else {
        // otherwise let's keep the validation and
        // change toSection, which is use by the formSubmit.
        self.toSection = section
      }
    },
    formSubmit: function (e) {
      if (this.campaign.advertiser.trim().length <= 1) {
      } else {
        const self = this
        // if toSection has any value it means that we are trying to go to a section. so...
        if (self.toSection != null) {
          // do not submit to action
          e.preventDefault()
          // then change the current section to the toSectino
          self.currentSection = self.toSection

          self.toSection = null
        } else {
          // otherwise just save data
          // self.saveData();
          self.checkAdvertiser()

          self.synced = false
        }
      }
    },
    checkAdvertiser: function () {
      const self = this

      /// /SAVE ADVERTISER IF NOT FOUND
      const advertiserFound = _.find(self.listAdvertisers, (item) => {
        return item.name === self.campaign.advertiser
      })

      if (advertiserFound) {
        self.campaign.advertiser_id = advertiserFound.id
        self.saveData()
      } else {
        self
          .createAdvertiser({ name: self.campaign.advertiser })
          .then((response) => {
            // can not read property id of undefined error :
            self.campaign.advertiser_id
              = response.data === undefined ? response.id : response.data.id
            self.saveData()
          })
      }
    },
    saveData: function () {
      const self = this
      let e

      const campaignData = _.cloneDeep(self.campaign)

      // send only filled fallbacks
      campaignData.fallbackImages = _.filter(
        campaignData.fallbackImages,
        function (item) {
          return item.image_url != null
        }
      )

      // validate if campaignData.salesforce_id is empty and if it is set it to null
      if (campaignData.salesforce_id == '') {
        campaignData.salesforce_id = null
      }
      // this.$log.info('save data', campaignData);

      // if this is not a new campaign...
      if (self.itemId != null) {
        // update the campaign (clone deep so in case it fails none of the data gets messed up)
        self
          .update(campaignData)
          // emit done event
          .then((response) => self.$emit('done', response))
          .catch((error) => {
            // notify the user
            console.log('saved error', error?.response)
            if (
              error.response?.data?.data?.errors[0].reason.sqlMessage.includes(
                'Duplicate entry'
              )
            ) {
              e
                = 'There was a problem updating this campaign. Please make sure the opportunity id is unique and try again.'
              // alert('There was a problem updating this campaign. Please make sure the opportunity id is unique.');
            } else {
              e
                = 'There was a problem updating this campaign. Please try again again.'
              // alert('There was a problem updating this campaign. Please try again again.');
            }
            self.informUser(e)
          })
      } else {
        // this.$log.info.log('new');
        // create the campaign (clone deep so in case it fails none of the data gets messed up)

        // removes properties that get added when user syncs existing campaign details to one that doesn't exist
        delete campaignData.id
        delete campaignData.account_id
        delete campaignData.use_click_from_adserver

        self
          .create(campaignData)
          // emit done event
          .then((response) => self.$emit('done', response))
          .catch((error) => {
            // notify the user
            // console.log('errorsito', error?.response)
            if (
              error.response?.data?.data?.errors[0].reason.sqlMessage.includes(
                'Duplicate entry'
              )
            ) {
              e
                = 'There was a problem creating this campaign. Please make sure the opportunity id is unique.'
              // alert('There was a problem creating this campaign. Please make sure the opportunity id is unique.');
            } else {
              // alert('There was a problem creating this campaign. Please try again.');
              e
                = 'There was a problem creating this campaign. Please make sure the opportunity id is unique and try again.'
              // alert('There was a problem creating this campaign. Please make sure the opportunity id is unique and try again.');
            }
            self.informUser(e)
          })
      }
    },
    cancel: function () {
      const self = this

      if (self.synced) {
        // show message that should to save the synced campaign
      }

      // emit event
      self.$emit('done')
    },
    populateForm: function () {
      const self = this

      // if an itemId came in it means that we are trying to load settings for an existing campaign... so if there's an itemId
      if (self.itemId !== null) {
        // self.campaign = _.cloneDeep(self.itemById(self.itemId));

        this.$log.info('CAMPAIGN', self.campaign, self.itemId)

        // IS A SYNCED CAMPAIGN
        if (
          self.campaign.external_campaign_id
          || self.campaign.campaign_source === 'PadSquad'
        )
          self.typeOfData = 'syncCampaign'

        // open fallbacks if passed on props
        self.currentSection
          = self.currentSectionProp != undefined
          && self.currentSectionProp != null
            ? self.currentSectionProp
            : 'details'
        // console.log(self.currentSection);

        // since the input[type=date] requires YYY-MM-DD format (we are using ISO date format), let's use a local property to manage it and use a watch to update the dates in the object
        self.campaignStartDateInput = self.campaign.start_date
          ? self.$moment(self.campaign.start_date).utc().format('YYYY-MM-DD')
          : ''
        self.campaignEndDateInput = self.campaign.end_date
          ? self.$moment(self.campaign.end_date).utc().format('YYYY-MM-DD')
          : ''

        self.campaign.modified_by = self.userInSession.id
        self.toggleShowInputUrl()
        self.campaign.fallbackImages = _.map(
          self.fallbackImagesDefault,
          function (item) {
            return _.merge(
              item,
              _.find(_.sortBy(self.campaign.fallbackImages, 'id').reverse(), {
                format_size: item.format_size
              })
            )
          }
        )

        // set advertiser name to input
        self.campaign.advertiser = this.advertiserById(
          self.campaign.advertiser_id
        ).name

        // validate actual kpi and set the value
        self.onChangeKPI(self, 'kpi1')
        self.onChangeKPI(self, 'kpi2')
      } else {
        self.campaign.created_by = String(self.userInSession.id)
        self.campaign.fallbackImages = self.fallbackImagesDefault
      }
    },
    uploadFallbackImages: function (e, index) {
      const self = this
      // Files to be uploaded
      const files = (this.assetUploader.file = e.target.files)

      let errors = 0

      // amount of pending files.
      let filesQueue = files.length

      if (files.length == 0) return

      // turn on loading spinner
      self.loading = true

      // app status: loading
      self.registerTask('uploadFallbackImages')

      // iterate over the list of files to be uploaded.
      _.forOwn(files, (file) => {
        const fileNameSplit = file.name.split('.')

        // image url DOMString, using after upload, because is not refreshing the image
        window.URL = window.URL || window.webkitURL
        const _imagURL = window.URL.createObjectURL(file)

        new Promise(function (resolve, reject) {
          // check the image dimensions
          const img = new Image()
          const formatSize = self.campaign.fallbackImages[index].format_size
          const _width = formatSize.split('x')[0]
          const _height = formatSize.split('x')[1]
          let canUpload = false

          img.onload = function () {
            // self.$log.info(this.width + " " + this.height);
            // self.$log.info(_width + " " + _height);

            if (this.width == _width && this.height == _height) {
              // return file
              canUpload = true
              resolve(canUpload)
            } else {
              canUpload = false
              alert(
                `The uploaded image dimensions do not match with the fallback ${formatSize}. Please try again.`
              )
              self.$refs['file-input'][index].reset()
              self.loading = false
              self.completeTask('uploadFallbackImages')

              reject(canUpload)
            }
          }

          img.src = _imagURL
        })
          .then((canUpload) => {
            // self.$log.info(canUpload);
            if (!canUpload) return
            return Utils.toBase64(file)
          })
          .then((fileString) => {
            // self.$log.info(fileString);

            return {
              type: 'imgage/png', // ${fileNameSplit[fileNameSplit.length-1]}`,
              tag_html: '<>',
              fileData: fileString,
              extension: '.png', // `.${fileNameSplit[fileNameSplit.length-1]}`,
              campaign_id: self.campaign.id,
              format_size: self.campaign.fallbackImages[index].format_size,
              use_click_from_adserver:
                self.campaign.use_click_from_adserver === null ? 0 : 1
              // fileName: `${file.name.replace('.'+fileNameSplit[fileNameSplit.length-1], '')}`
            }
          })
          // .then(Api.uploadFile)
          .then((newFile) => {
            if (self.campaign.fallbackImages[index].image_url != null) {
              newFile.id = self.campaign.fallbackImages[index].id

              Api.updateFallback(newFile)
                .then((response) => {
                  // assign the new image to the fallback
                  self.campaign.fallbackImages[index].image_url = _imagURL
                  // self.campaign.fallbackImages[index].image_url = response.image_url;//caching issue

                  // emit fallbacks to placements section
                  this.checkFallbacksAndEmit()

                  self.loading = false
                  // app status: ready
                  self.completeTask('uploadFallbackImages')
                })
                .catch((error) => {
                  alert(
                    'It appears that there are some files we could not upload. Please try again.'
                  )
                  self.loading = false
                  // app status: ready
                  self.completeTask('uploadFallbackImages')
                })

              self.$refs['file-input'][index].reset()
              this.assetUploader.file = null

              // one less from the Queue
              // filesQueue--;

              // if the queue is empty, let the user know.
              if (filesQueue == 0) {
                // self.loading = false;

                // app status: ready
                self.completeTask('uploadFallbackImages')
              }
            } else {
              Api.createFallback(newFile)
                .then((response) => {
                  _.merge(self.campaign.fallbackImages[index], response)

                  // emit fallbacks to placements section
                  this.checkFallbacksAndEmit()

                  self.loading = false
                  // app status: ready
                  self.completeTask('uploadFallbackImages')
                })
                .catch((error) => {
                  alert(
                    'It appears that there are some files we could not upload. Please try again.'
                  )

                  self.loading = false
                  // app status: ready
                  self.completeTask('uploadFallbackImages')
                })

              self.$refs['file-input'][index].reset()
              this.assetUploader.file = null

              // one less from the Queue
              // filesQueue--;

              // if the queue is empty, let the user know.
              if (filesQueue == 0) {
                // self.loading = false;

                // app status: ready
                self.completeTask('uploadFallbackImages')
              }
            }
          })
          .catch((error) => {
            // one less from the Queue
            filesQueue--

            errors++

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // turn off loading spinner
              self.loading = false
              self.$refs['file-input'][index].reset()
              this.assetUploader.file = null
              // app status: ready
              self.completeTask('uploadFallbackImages')

              if (errors) {
                alert(
                  'It appears that there are some files we could not upload. Please try again.'
                )
              }
            }

            self.$log.error('uploadFallback', error)
          })
      })
    },
    downloadAsset: function (assetFile) {
      const link = document.createElement('a')
      link.download = assetFile.name
      link.target = '_blank'
      link.href = assetFile.path
      link.click()
    },
    removeFallbackImage: function (fallback, index) {
      const self = this

      // turn on loading spinner
      self.loading = true

      if (
        confirm(
          'This will remove the image permanently. Are you sure you want to remove this image?'
        )
      ) {
        // Api.deleteFile(fallbackImage.image_id)
        // WE'LL DELETE THE FALLBACK INSTEAD OF THE ASSET
        Api.deleteFallback(self.campaign.fallbackImages[index].id)
          .then(() => {
            // now, let's stage file to be removed on save.
            // find index of the asset file to be deleted, in the campaigns.assetFiles
            // var index = _.findIndex( self.campaign.fallbackImages, {id: fallbackImage.id});

            // self.campaign.fallbackImages.splice(index, 1, fallbackImage);
            self.campaign.fallbackImages[index].image_url = null

            // emit fallbacks to placements section
            this.checkFallbacksAndEmit()

            // turn off loading spinner
            self.loading = false
          })
          .catch((error) => {
            self.$log.error(error)

            // turn off loading spinner
            self.loading = false

            alert('There was an issue deleting the image. Please try again')
          })
      }
    },
    checkFallbacksAndEmit: function () {
      this.$emit('fallbackChanged', this.campaign.fallbackImages)
    },
    isPrevSection: function (toSection) {
      const self = this

      // if we are attempting to go to a section
      if (toSection) {
        // get currentSection index
        const currentSectionIndex
          = self.sections.indexOf(self.currentSection) + 1

        // get toSection index
        const toSectionIndex = self.sections.indexOf(toSection) + 1

        // if the toSection index is < than the currentSection,
        // then the validation is omited (all required fields will not be required on this case)
        // so return the comparison result;
        return toSectionIndex < currentSectionIndex
      } else {
        // otherwise just make it false so validation stays in place
        return false
      }
    },
    toggleShowInputUrl: function () {
      this.showInputUrl = !this.campaign.use_click_from_adserver
    },
    updateValues: function (dates) {
      this.campaignStartDateInput = this.$moment(new Date(dates.startDate))
        .utc()
        .format('YYYY-MM-DD')
      this.campaignEndDateInput = this.$moment(new Date(dates.endDate))
        .utc()
        .format('YYYY-MM-DD')
      this.showDate = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';

#url-id,
input[type='number'] {
  min-width: 100%;
  height: 36px;
  padding-left: 1.3rem;
}

.ratioInputs {
  display: flex;
}

.toggleContainer {
  display: flex;
  width: 240px;
  align-items: left;
  justify-content: left;
}

.toggleButton {
  @extend .button;
  text-decoration: none;
  text-align: center;
}

.column-text-align-center {
  text-align: center !important;
}

.contentTable tbody tr[data-v-2649aa10] {
  border: 1px solid #d9d9d9 !important;
}

.report-status .svg-inline--fa.fa-w-16 {
  width: 1em;
}

.ds-legend-wrap {
  margin-top: 0.5rem;
  padding: 10px 5px;
  border: 1px solid #d9d9d9;
  font-size: 1rem;
}

.ds-legend-wrap > ul {
  margin-bottom: 0;
}

.ds-legend-wrap li {
  display: inline-flex;
  padding-right: 1rem;
  align-items: center;
  margin-left: 0.2rem;
}

.ds-legend-wrap li svg.svg-inline--fa.fa-circle.fa-w-16 {
  margin-right: 0.3rem;
}

.gray_bg {
  background: #f6f6f682;
}
.white_bg {
  background: #fff;
}
</style>
