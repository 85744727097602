<template>
    <div :class="cols">
        <div class="data-labels" style="float: right;" v-on:click="selectDataSource(source.name)" v-for="(source, index) in dataSources" v-bind:class="source.selected ? 'selected-source' : ''" :key="index">{{source.name}}</div>
    </div>
</template>

<script>
export default {
  name: 'GraphDataSourceSelector',
  props: {
    dataSources: Array,
    cols: {
      type: String,
      default: 'col-4'
    }
  },
  methods: {
    selectDataSource: function (event) {
      for (let i = 0; i < this.dataSources.length; i++) {
        if (event == this.dataSources[i].name) {
          this.dataSources[i].selected = true
          continue
        }
        this.dataSources[i].selected = false
      }
      this.$store.state.dataSource = event
      this.$emit('sourceSelected', event)
    }
  }
}
</script>

<style scoped>
.data-labels{
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    font: Normal 14px Montserrat;
    color: #5C5C5C;
    cursor: pointer;
}

.selected-source{
    text-decoration: underline;
    font: Bold 14px Montserrat;
    color: #5C5C5C;
    cursor: pointer;
}

</style>
