export const SIGNIN = 'signIn'
export const SIGNOUT = 'signOut'
export const SIGNUP = 'signUp'
export const CONFIRM_SIGNUP = 'confirmSignUp'
// forgot password
export const FORGOT_PASSWORD = 'forgotPassword'
export const FORGOT_PASSWORD_SUBMIT = 'forgotPasswordSubmit'
// change password
export const CHANGE_PASSWORD = 'changePassword'
export const COMPLETE_NEW_PASSWORD = 'completeNewPassword'
// error
export const SET_MESSAGE = 'setMessage'
// user
export const SET_USER = 'setUser'
export const SET_USER_DATA = 'setUserData'
export const SET_USER_IN_SESSION = 'setUserInSession'// data from DB
// db
export const SET_SEND_DATA_TO_DB = 'setSendDataToDB'
// token
export const SET_JWT_TOKEN = 'setJWT'
// form type
export const SET_FORM_TO_SHOW = 'setFormToShow'

export const SET_LOADING = 'setLoading'

export const SET_AUTHENTICATED = 'setAuthenticaded'
export const SET_STATUS = 'setStatus'
