<template>
  <div class="appFrame">
    <div class="loader" :class="{ done: !loading }">
      <font-awesome-icon icon="spinner" class="spinner" />
    </div>
    <Navbar :currentModulePath="'analytics'" :currentModuleName="'Analytics'" />

    <main class="moduleFrame moduleFrame--tagManagerRepository">
      <PencilBar />
      <ModuleHeader>
        <ul slot="tabs">
          <!-- <li>
            <router-link to="/analytics/campaigns">Campaigns</router-link>
          </li> -->
          <li><router-link to="/analytics/saved-reports">Saved Reports</router-link></li>
          <li selected>
            <router-link to="/analytics/dataloading/">Data Loading</router-link>
          </li>
          <li><router-link to="/analytics/pulse/">Pulse</router-link></li>
          <li><router-link to="/analytics/campaign-pacing/">Campaign Pacing</router-link></li>
          <li><router-link to="/analytics/vidstream-performance/">VidStream P1</router-link></li>
          <li><router-link to="/analytics/vidstream-performance2/">VidStream P2</router-link></li>
        </ul>

        <div slot="title"></div>

        <div slot="actions">
          <button class="moduleHeader__createButton" @click.prevent="addNewJob">
            <font-awesome-icon icon="plus-circle" class="i" />CREATE NEW JOB
          </button>
        </div>
      </ModuleHeader>
      <ModuleFilter>
        <div slot="searchField">
          <FormField
            label="Search Jobs"
            forId="filter-search"
            type="search"
            class="moduleFilter__filter"
          >
            <input
              id="filter-search"
              type="text"
              v-model="filterModuleData.search"
              required
            />
          </FormField>
        </div>
        <div slot="collapsableFilters">
          <FormField
            label="Status"
            forId="filter-status"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              v-model="filterModuleData.filterValues.JobStatus"
              id="filter-status"
            >
              <option
                v-for="status in filterModuleData.filterOptions.JobStatus"
                :key="status"
              >
                {{ status }}
              </option>
            </select>
            <div
              slot="custom-content"
              class="moduleFilter__filter__clear"
              v-if="
                filterModuleData.filterValues.JobStatus !==
                filterModuleData.filterDefaults.JobStatus
              "
              v-on:click="
                filterModuleData.filterValues.JobStatus =
                  filterModuleData.filterDefaults.JobStatus
              "
            >
              [X] CLEAR
            </div>
          </FormField>
             <FormField
            label="Vendor"
            forId="filter-vendor"
            type="select"
            class="moduleFilter__filter"
          >
            <select
              v-model="filterModuleData.filterValues.Source"
              id="filter-vendor"
            >
              <option
                v-for="vendor in filterModuleData.filterOptions.Source"
                :key="vendor"
              >
                {{ vendor }}
              </option>
            </select>
            <div
              slot="custom-content"
              class="moduleFilter__filter__clear"
              v-if="
                filterModuleData.filterValues.Source !==
                filterModuleData.filterDefaults.Source
              "
              v-on:click="
                filterModuleData.filterValues.Source =
                  filterModuleData.filterDefaults.Source
              "
            >
              [X] CLEAR
            </div>
          </FormField>
        </div>
      </ModuleFilter>

      <section class="moduleContent">
        <div class="contentTableFrame">
          <ContentTableTools>
            <FormField
              slot="left"
              forId="tools-orderby"
              type="select"
              tagLeft="Order By:"
            >
              <select v-model="tableDataOptions.orderBy" id="tools-orderby">
                <option
                  v-for="(orderByOption, index) in tableDataOptions.options"
                  :key="index"
                  :value="orderByOption.value"
                >
                  {{ orderByOption.label }}
                </option>
              </select>
            </FormField>

            <FormField
              slot="left"
              forId="tools-order"
              type="select"
              tagLeft="Order:"
            >
              <select v-model="tableDataOptions.order" id="tools-order">
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </FormField>
            <FormField
              slot="left"
              forId="tools-actions"
              type="select"
              tagLeft="Actions:"
            >
              <select id="tools-actions" v-on:change="actionsHandler">
                <option value="none">Select an Action</option>
                <option
                  value="Clear Jobs"
                  :disabled="!validItemsSelected.length"
                >
                  Clear Jobs
                </option>
              </select>
            </FormField>

            <div slot="right" class="contentTableFrame__total">
              Total Jobs: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>

          <div
            class="contentTableWrapper"
            :class="{ contextualMenuExpanded: contextualMenuExpanded }"
          >
            <table class="contentTable">
              <thead>
                <tr>
                  <th class="contentTable__dimension">
                    <input type="checkbox" @change="selectAllToggle($event)" />
                    Job Id
                  </th>
                  <th class="tdrow">Job Created</th>
                  <th>Job Last Update</th>
                  <th>Job Status</th>
                  <th>Job Message</th>
                  <th>Campaign Id</th>
                  <th>Data Date From</th>
                  <th>Data Hour</th>
                   <th>Vendor</th>

                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in paginatedItems" :key="item.JobId">
                  <td class="contentTable__dimension tdrow">
                    <input
                      type="checkbox"
                      :name="'job_' + item.JobId"
                      v-model="itemsSelected[item.JobId]"
                      :disabled="
                        item.JobStatus == 'Completed' ||
                        item.JobStatus == 'Failed'
                          ? false
                          : true
                      "

                       :title="item.JobStatus == 'Completed' ||
                        item.JobStatus == 'Failed'
                          ? ''
                          : 'Actions are disabled for pending items'"

                    />
                    <a
                      :title="item.JobId"
                      @click="jobSetting(item.JobId)"
                      href="#"
                      >{{ item.JobId }}</a
                    >
                  </td>
                  <td column-name="Job Created">
                    {{ getFormattedDate(item.CreatedAt) }}
                  </td>
                  <td column-name="Job Last Update">
                    {{ getFormattedDate(item.UpdatedAt) }}
                  </td>
                  <td column-name="Job Status" class="job-status">
                    <a
                      href="#"
                      v-on:click.prevent="
                        filterModuleData.filterValues.JobStatus = item.JobStatus
                      "
                    >
                      <font-awesome-icon
                        :id="item.JobId"
                        v-job-status="item.JobStatus"
                        icon="circle"
                      />
                      {{ item.JobStatus }}</a
                    >
                  </td>

                  <td column-name="Job Message">
                    {{ item.JobMessage }}
                  </td>
                  <td column-name="Campaign Id">
                    {{ item.InternalCampaignId }}
                  </td>
                  <td column-name="Data Date From">
                    {{ item.From }}
                  </td>
                  <td column-name="Data Hour">
                    {{ item.Hour }}
                  </td>
                  <td column-name="Data Source">
                    {{ item.Source }}
                  </td>
                  <td class="contentTable__actions">
                    <button @click="jobSetting(item.JobId)">
                      <font-awesome-icon icon="cog" class="i" />
                    </button>
                    <ActionsDropdown
                      :dropup="
                        paginatedItems.length > 10 &&
                        !(index < paginatedItems.length - 2)
                      "
                      :itemId="item.JobId"
                      :actions="getMenuActions(item.JobStatus)"
                      @show="contextualMenuExpanded = true"
                      @hide="contextualMenuExpanded = false"
                    />
                  </td>
                </tr>

                <tr v-if="paginatedItems.length < 1">
                  <td colspan="9">No job matches your criteria</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ContentTableTools>
            <div slot="right" class="contentTableFrame__total">
              Total Jobs: {{ filteredItems.length }}
            </div>
            <b-pagination
              slot="right"
              class="contentTableFrame__pagination"
              :limit="3"
              :total-rows="filteredItems.length"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
            ></b-pagination>
          </ContentTableTools>
        </div>
      </section>
      <ModuleFooter>
        <div slot="left">© {{ new Date() | moment("YYYY") }} PADSQUAD LLC</div>
        <WebLinks slot="right" />
      </ModuleFooter>
    </main>

    <!-- Modal Component -->
    <b-modal
      :static="staticBModal"
      ref="moduleModal"
      title=""
      @hidden="modalData.currentComponent = null"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <component
        :is="modalData.currentComponent"
        :itemId="modalData.itemId"
        :JobId="modalData.JobId"
        @done="hideModal()"
      />
    </b-modal>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import PencilBar from '@/components/PencilBar.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import FormField from '@/components/FormField.vue'
import RepoAnalyticsDataLoadingCreate from '@/views/modals/RepoAnalyticsDataLoadingCreate.vue'
import WebLinks from '@/components/WebLinks.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import RepoAnalyticsDataLoadingSettings from '@/views/modals/RepoAnalyticsDataLoadingSettings.vue'
import Utils from '@/helpers/Utils.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AnalyticsRepositoryDataLoading',
  components: {
    Navbar,
    PencilBar,
    ModuleHeader,
    ModuleFilter,
    ModuleFooter,
    FormField,
    WebLinks,
    ContentTableTools,
    ActionsDropdown
  },
  data () {
    return {
      loading: false,
      staticBModal: true,
      modalData: {
        currentComponent: null,
        itemId: null,
        userData: null,
        loggedUser: null,
        JobId: null
      },
      itemsSelected: {},
      pagination: {
        perPage: 300,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'pause',
          text: 'Pause Job',
          roles: ['Admin'],
          clickAction: this.pauseJob
        },
        {
          ico: 'info-circle',
          text: 'Re-process Item',
          roles: ['Admin'],
          clickAction: this.reProcessJob
        },
        {
          ico: 'list',
          text: 'Re-load Item',
          roles: ['Admin'],
          clickAction: this.reLoadJob
        },
        {
          ico: 'trash-alt',
          text: 'Clear Job',
          roles: ['Admin'],
          clickAction: this.clearJob,
          visible: false
        }
      ],
      selectAll: false,
      contextualMenuExpanded: false,
      filterModuleData: {
        search: '',
        filterValues: {
          JobStatus: 'All Status',
          Source: 'All Vendors'
        },
        filterDefaults: {
          JobStatus: 'All Status',
          Source: 'All Vendors'
        },
        filterOptions: {
          JobStatus: [],
          Source: []
        }
      },
      tableDataOptions: {
        orderBy: 'CreatedAt',
        order: 'desc',
        options: [
          {
            label: 'Status',
            value: 'JobStatus'
          },
          {
            label: 'Created',
            value: 'CreatedAt'
          },
          {
            label: 'Last Update',
            value: 'UpdatedAt'
          },
          {
            label: 'Campaign Id',
            value: 'InternalCampaignId'
          }
        ]
      }
    }
  },
  methods: {
    addNewJob () {
      this.modalData.currentComponent = RepoAnalyticsDataLoadingCreate
      // register that we have just opened a modal
      this.registerModalOpened()

      // show modal
      this.$refs.moduleModal.show()
    },
    hideModal: function () {
      // register that we have just closed a modal
      this.registerModalClosed()

      // show modal
      this.$refs.moduleModal.hide()

      this.modalData.JobId = null
      this.modalData.currentComponent = null
    },
    ...mapActions(['registerModalOpened', 'registerModalClosed']),
    ...mapActions('analytics', [
      'fetchDashBoardData',
      'updateJob',
      'createJob'
    ]),

    getFormattedDate: function (date) {
      const formattedDate = this.$moment(date == undefined ? '' : date).utc().format('M/DD/YY h:mma')

      return formattedDate != 'Invalid date' ? formattedDate : ''
    },
    jobSetting (itemId) {
      this.modalData.currentComponent = RepoAnalyticsDataLoadingSettings

      this.modalData.JobId = itemId
      // register that we have just opened a modal
      this.registerModalOpened()

      // show modal
      this.$refs.moduleModal.show()
    },
    populateFilters () {
      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(this.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(
        this.getDashBoardData,
        paramsToFilterBy
      )

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, (param) => {
        filterOptions[param].unshift(
          this.filterModuleData.filterDefaults[param]
        )
      })

      // Store filter options.
      this.filterModuleData.filterOptions = filterOptions
    },
    pauseJob (itemId) {
      // idlc
      const self = this
      if (itemId) {
        self.loading = true
        self
          .updateJob({ JobId: itemId, JobStatus: 'Paused' })
          .then((dt) => {
            self.loading = false
          })
          .catch((err) => (self.loading = false))
      }
    },
    reProcessJob (itemId) {
      const self = this
      if (itemId) {
        self.loading = true
        self
          .updateJob({ JobId: itemId, JobStatus: 'Pending' })
          .then((dt) => {
            self.loading = false
          })
          .catch((err) => (self.loading = false))
      }
    },
    reLoadJob (itemId) {
      const self = this
      if (itemId) {
        const newItem = self.getDashBoardData.find((x) => x.JobId == itemId)
        self.loading = true

        self
          .createJob({
            from: newItem.From,
            to: newItem.To,
            hour: newItem.Hour,
            source: 'airtory',
            id: newItem.InternalCampaignId,
            type: 'campaign',
            external_campaign_id: newItem.ExternalCampaignId,
            campaigns: [
              {
                id: newItem.InternalCampaignId,
                external_campaign_id: newItem.ExternalCampaignId
              }
            ]
          })
          .then((dt) => (self.loading = false))
          .catch((err) => (self.loading = false))
      }
    },
    clearJob (itemId) {
      // idlc
      const self = this
      if (itemId) {
        self.loading = true
        self
          .updateJob({ JobId: itemId, JobStatus: 'Stoped' })
          .then((dt) => {
            self.loading = false
          })
          .catch((err) => (self.loading = false))
      }
    },
    actionsHandler: function (event) {
      // Stop all selected jobs.
      const self = this
      self.loading = true
      self
        .updateJob({
          JobId: 0,
          JobStatus: 'Stoped',
          Jobs: self.validItemsSelected
        })
        .then((dt) => {
          self.loading = false
        })
        .catch((err) => (self.loading = false))

      // reset the dropdown
      event.target.value = 'none'
    },

    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.paginatedItems.map((item, key) => {
          if (item.JobStatus == 'Completed' || item.JobStatus == 'Failed') {
            this.$set(this.itemsSelected, String(item.JobId), true)
          }
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.itemsSelected, String(item.JobId), false)
        })
      }
    },
    getMenuActions (jobStatus) {
      switch (jobStatus) {
        case 'Completed':
          return this.tableActions.filter(x => x.text == 'Re-load Item' || x.text == 'Clear Job')
          break
        case 'Failed':
          return this.tableActions.filter(x => x.text == 'Re-load Item' || x.text == 'Clear Job')
          break
        case 'Paused':
          return this.tableActions.filter(x => x.text == 'Re-load Item' || x.text == 'Re-process Item')
          break
        case 'Pending':
          return this.tableActions.filter(x => x.text == 'Re-load Item' || x.text == 'Pause Job')
          break
        case 'Requested':
          return this.tableActions.filter(x => x.text == 'Re-load Item' || x.text == 'Pause Job')
          break

        default:
          return this.tableActions.filter(x => x.text == 'Re-load Item')
          break
      }
    }
  },
  directives: {
    'job-status': {
      bind (el, binding, vnode) {
        if (binding.value == 'Completed') {
          el.style.color = 'green'
        } else {
          if (binding.value == 'Pending') {
            el.style.color = 'blue'
          } else if (binding.value == 'Failed') {
            el.style.color = 'red'
          } else if (binding.value == 'Paused') {
            el.style.color = 'orange'
          }
        }
      },
      update: function (el, binding, vnode) {
        if (binding.value == 'Completed') {
          el.style.color = 'green'
        } else {
          if (binding.value == 'Pending') {
            el.style.color = 'blue'
          } else if (binding.value == 'Failed') {
            el.style.color = 'red'
          } else if (binding.value == 'Paused') {
            el.style.color = 'orange'
          }
        }
      }
    }
  },
  created () {
    this.fetchDashBoardData()
  },
  watch: {
    getDashBoardData: function () {
      this.populateFilters()
    }
  },
  computed: {
    paginatedItems: function () {
      return Utils.getPaginatedItems(
        this.filteredItems,
        this.pagination.currentPage,
        this.pagination.perPage
      )
    },
    filteredItems: function () {
      /*     console.log("paginatedItems funtions"); */
      const filteredList = this.getDashBoardData

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(
        filteredList,
        this.filterModuleData.search
      )

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(
        this.filterModuleData.filterValues,
        (filter, key) => {
          // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
          const filterOption = _.get(
            this.filterModuleData.filterOptions,
            '[' + key + '][0]',
            false
          )

          // if filterOption is not false and the filter option is not the first option
          return filterOption && filter != filterOption
        }
      )

      // filter data
      let filteredItems = Utils.getFilteredItems(
        searchedItems,
        propsToFilterBy
      )

      console.log('this.tableDataOptions.orderBy', this.tableDataOptions.orderBy)
      let exludedCreateAt = []
      let excludeUpdateAt = []

      // remove empty dates to sort
      if (this.tableDataOptions.orderBy == 'CreatedAt') {
        exludedCreateAt = filteredItems.filter(x => x.CreatedAt == null)
      }
      if (this.tableDataOptions.orderBy == 'UpdateAt') {
        excludeUpdateAt = filteredItems.filter(x => x.UpdateAt == null)
      }
      // filteredItems =  this.tableDataOptions.orderBy == 'CreatedAt'  ? filteredItems.filter(x=> x.CreateAt != null) : this.tableDataOptions.orderBy == 'UpdatedAt'? filteredItems.filter(x=> x.UpdateAt != null) :filteredItems ;
      // order data asc or desc
      filteredItems = _.orderBy(
        this.tableDataOptions.orderBy == 'CreatedAt' ? filteredItems.filter(x => x.CreatedAt != null) : this.tableDataOptions.orderBy == 'UpdatedAt' ? filteredItems.filter(x => x.UpdatedAt != null) : filteredItems,
        [this.tableDataOptions.orderBy],
        [this.tableDataOptions.order]
      )

      // add againg empty date removed
      if (this.tableDataOptions.orderBy == 'CreatedAt') {
        filteredItems.push(...exludedCreateAt)
      }
      if (this.tableDataOptions.orderBy == 'UpdateAt') {
        filteredItems.push(...excludeUpdateAt)
      }

      return filteredItems
    },
    validItemsSelected: function () {
      const validEntries = []

      // iterate over jobs
      _.forOwn(this.itemsSelected, (item, key) => {
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    ...mapGetters('analytics', ['getDashBoardData'])
  }
}
</script>
<style  scoped>
  .job-status {
    text-align: left;
  }
  .job-status .svg-inline--fa.fa-w-16 {
    width: 0.7em;
  }
</style>
