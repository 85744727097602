<template>

    <div class="appFrame">

        <Navbar :currentModulePath="'tag-manager'" :currentModuleName="'Tag Manager Creatives'" />

        <main class="moduleFrame moduleFrame--creativeRepository">

          <PencilBar />

          <ModuleHeader>
            <ul slot="tabs">
              <li><router-link to="/tag-manager/campaigns">Active Campaigns</router-link></li>
              <li><router-link to="/tag-manager/campaigns/inactive">Inactive Campaigns</router-link></li>
              <li selected><router-link to="/tag-manager/creatives">Creatives</router-link></li>
            </ul>

            <div slot="title">Creatives</div>

            <div slot="actions">
            </div>
          </ModuleHeader>

          <ModuleFilter>
            <div slot="searchField">
              <FormField label="Search Creatives" forId="filter-search" type="search" class="moduleFilter__filter">
                <input v-model="filterModuleData.search" id="filter-search" type="text" required />
              </FormField>
            </div>

            <div slot="collapsableFilters">

              <FormField label="Format" forId="filter-format" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.format" id="filter-format">
                  <option v-for="format in filterModuleData.filterOptions.format" :key="format">{{format}}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.format !== filterModuleData.filterDefaults.format"
                  v-on:click="filterModuleData.filterValues.format = filterModuleData.filterDefaults.format">[X] CLEAR
                </div>
              </FormField>

              <FormField label="Main KPI" forId="filter-meanKPI" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.mainKPI" id="filter-meanKPI">
                  <option v-for="mainKPI in filterModuleData.filterOptions.mainKPI" :key="mainKPI">{{mainKPI}}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.mainKPI !== filterModuleData.filterDefaults.mainKPI"
                  v-on:click="filterModuleData.filterValues.mainKPI = filterModuleData.filterDefaults.mainKPI">[X] CLEAR
                </div>
              </FormField>

            </div>
          </ModuleFilter>

          <section class="moduleContent">

            <div class="contentTableFrame">

              <ContentTableTools>
                <FormField slot="left" forId="tools-orderby" type="select" tagLeft="Order By:">
                  <select v-model="tableDataOptions.orderBy" id="tools-orderby">
                    <option v-for="(orderByOption, index) in tableDataOptions.options" :key="index" :value="orderByOption.value">{{orderByOption.label}}</option>
                  </select>
                </FormField>

                <FormField slot="left" forId="tools-order" type="select" tagLeft="Order:">
                  <select v-model="tableDataOptions.order" id="tools-order">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </FormField>

                <FormField slot="left" forId="tools-actions" type="select" tagLeft="Actions:">
                  <select id="tools-actions" v-on:change="actionsHandler">
                    <option value="none">Select an Action</option>
                    <!-- <option value="AddToList" :disabled="!validCreativesSelected.length">Add Creatives To List</option> -->
                  </select>
                </FormField>

                <div slot="right" class="contentTableFrame__total">Total Creatives: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

              <div class="contentTableWrapper" :class="{'contextualMenuExpanded': contextualMenuExpanded}">
                <table class="contentTable">
                  <thead>
                    <tr>
                       <th class="contentTable__dimension"><input type="checkbox" @change="selectAllToggle($event)"/> Creative Name</th>
                      <th>Creative ID</th>
                      <th>Status</th>
                      <th>Format</th>
                      <th>Campaign Start</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in paginatedItems" v-bind:key="item.id">
                      <td class="contentTable__dimension"><input type="checkbox" :name="'creative_' + item.name" v-model="creativesSelected[item.id]" /> {{item.name}}</td>
                      <td column-name="Creative ID"><a href="#" v-on:click.prevent="filterModuleData.filterValues.id = item.id">{{ item.id }}</a></td>
                      <td column-name="Status"><a href="#" v-on:click.prevent="filterModuleData.filterValues.status = item.status_id">{{getStatusName(item.status_id)}}</a></td>
                      <td column-name="Format"><a href="#" v-on:click.prevent="filterModuleData.filterValues.format = item.format_id">{{getFormatName(item.format_id)}}</a></td>
                      <td column-name="CampaignStart"><a href="#" v-on:click.prevent="''">{{getFormattedDate(item.created_date)}}</a></td>
                      <td class="contentTable__actions">
                        <button @click="openCreativePreview(item)"><font-awesome-icon icon="eye" class="i" /></button>
                        <!-- <button @click="openCreativeSettings(item)"><font-awesome-icon icon="cog" class="i" /></button> -->
                        <!-- <ActionsDropdown :dropup="paginatedItems.length>10&&!(index<(paginatedItems.length-2))" v-bind:itemId="item.id" v-bind:actions="tableActions" @show="contextualMenuExpanded=true"  @hide="contextualMenuExpanded=false"/> -->
                      </td>
                    </tr>

                    <tr v-if="paginatedItems.length < 1 && this.creativesReadyForTraffic.length > 0">
                      <td colspan="9">No creative matches your criteria</td>
                    </tr>
                    <tr v-if="!this.creativesReadyForTraffic || this.creativesReadyForTraffic.length === 0">
                      <td colspan="9">There are no creatives available.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ContentTableTools>
                <div slot="right" class="contentTableFrame__total">Total Creatives: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

            </div>

          </section>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            :advertiserId="modalData.advertiserId"
            :advertiserName="modalData.advertiserName"
            :campaign="modalData.campaign"
            :creative="modalData.creative"
            @done="hideModal()"
           />
        </b-modal>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import CreativeProfileModal from '@/views/creative-production/modals/RepoProductionCreativeProfile.vue'
import WebLinks from '@/components/WebLinks.vue'

import { mapGetters, mapActions } from 'vuex'

import Utils from '@/helpers/Utils.js'

export default {
  name: 'TagManagerRepository',
  components: {
    Navbar, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks, CreativeProfileModal
  },
  data () {
    return {
      staticBModal: true,
      mobileFiltersExpanded: false,
      filterModuleData: {
        search: '',
        filterValues: {
          // advertiser: "All advertisers",
          format: 'All formats',
          mainKPI: 'All KPI'
        },
        filterDefaults: {
          // advertiser: "All advertisers",
          format: 'All formats',
          mainKPI: 'All KPI'
        },
        filterOptions: {
          // advertiser: [],
          format: [],
          mainKPI: []
        }
      },
      tableDataOptions: {
        orderBy: 'touched_on',
        order: 'desc',
        options: [
          {
            label: 'Last Updated',
            value: 'touched_on'
          },
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Advertiser',
            value: 'advertiser'
          },
          {
            label: 'Format',
            value: 'format'
          }
        ]
      },
      breadcrumsData: [
        {
          text: 'Creative Production',
          href: '/creative-production'
        },
        {
          text: 'Creatives',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null,
        campaign: null,
        creative: null
      },
      data: [],
      creativesSelected: {},
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'Creative Settings',
          roles: ['Admin', 'Marketing']
          // clickAction: this.openCreativeSettings
        },
        {
          ico: 'trash-alt',
          text: 'Delete Creative',
          roles: ['Admin', 'Marketing']
          // clickAction: this.deleteCreative
        }
      ],
      contextualMenuExpanded: false,
      selectAll: false
    }
  },
  methods: {
    ...mapActions([
      'registerModalOpened',
      'registerModalClosed'
    ]),
    ...mapActions('creatives', {
      fetchReadyForTraffic: 'fetchReadyForTraffic',
      deleteProductionCreative: 'delete'
    }),
    ...mapActions('campaigns', {
      fetchCampaignById: 'fetchById'
    }),
    actionsHandler: function (event) {
      const self = this
      const value = event.target.value

      switch (value) {
        case 'AddToList':
          // self.addMultipleCreativesToList();
          break

        default:
          break
      }

      // reset the dropdown
      event.target.value = 'none'
    },
    openCreativePreview: function (creative) {
      const _id = creative.external_creative_id
      window.open(`https://padsquad.adtag.org/ad-preview/#${_id}/mobile_portrait`, '_blank')
    },
    openCreativeSettings: async function (creative) {
      const self = this
      try {
        if (!this.campaignById(creative.campaign_id)) {
          await self.fetchCampaignById(creative.campaign_id)
        }
        // set the modal item id to the userId we want to update
        self.modalData.itemId = creative.id

        self.$log.info('CREATIVE TO MODAL', creative, _creative, this.list)

        // for advertiser autocomplete initial values, because are not loaded on mounted in RepoCreative
        // let _creative = self.productionCreativesByCampaignId(creativeId);
        const _campaign = this.campaignById(creative.campaign_id)
        const _creative = _campaign.creatives.find(item => item.id === creative.id)
        const _advertiser = (_creative.advertiser_id === null) ? self.advertiserByName(_creative.advertiser) : self.advertiserById(_creative.advertiser_id)

        // self.$log.info(_advertiser, _creative.advertiser)

        if (_advertiser !== undefined) {
          self.modalData.advertiserId = _advertiser.id
          self.modalData.advertiserName = _advertiser.name
        } else {
          self.modalData.advertiserId = _creative.advertiser_id
          self.modalData.advertiserName = _creative.advertiser
        }

        // send Airtory Campaign
        self.modalData.campaign = _campaign
        self.modalData.creative = _creative

        // register that we have just opened a modal
        self.registerModalOpened()

        // set the content for the modal to the user profile component
        self.modalData.currentComponent = CreativeProfileModal

        // show modal
        self.$refs.moduleModal.show()
      } catch (error) {
        self.$log.info(error)
      }
    },
    deleteCreative: function (creativeId) {
      const self = this

      // fet user from users.
      const creative = _.find(self.creativesReadyForTraffic, { id: creativeId })

      // if the user we are trying to delete is not the logged in user...
      if (confirm(`Are you sure you want to delete this creative: ${creative.name}?`)) {
        // delete creative
        self.deleteProductionCreative(creative)
          .catch(error => {
            self.$log.error(error)
            alert(`There was an issue deleting this creative. Please try again. Error Code:${error}`)
          })
      }
    },
    openNewCreative: function () {
      const self = this

      // set Advertiser to null
      self.modalData.advertiserId = null
      self.modalData.advertiserName = null

      // set the modal item id to null to create new user
      self.modalData.itemId = null

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()
    },
    viewCreative: function (creative) {
      // open file url in a new window
      window.open('/c/' + creative.id, '_blank')
    },
    getFormattedDate: function (date) {
      const self = this
      const formattedDate = self.$moment(date).utc().format('MMM DD, YYYY')

      return (formattedDate != 'Invalid date') ? formattedDate : ''
    },
    getFormatName: function (id) {
      const item = _.find(this.creativeFormats, { id })
      return (item) ? item.name : ''
    },
    getStatusName: function (id) {
      const item = this.creativeStatus.find(item => item.id === id)
      return (item) ? item.name : ''
    },
    mapItemsFilters: function () {
      const self = this

      self.creativesReadyForTraffic.map(item => {
        item.format = _.find(self.creativeFormats, { id: item.format_id })
        item.status = _.find(self.creativeStatus, { id: item.status_id })
        item.mainKPI = _.find(self.KPIs, { id: item.main_kpi_id })
        if (item.format == undefined) return item
        item.format = item.format.name
        item.status = item.status.name
        item.mainKPI = item.mainKPI.name
        return item
      })
    },
    populateFilters: function () {
      const self = this

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // map creatives to see if there's demo and/or design
      self.creativesReadyForTraffic.map(item => {
        // if(item.designImages && item.designImages.length>0) item.showcaseType = 'Has design'
        item.showcaseType = (item.demoTag) ? 'Has demo' : 'Has design'
        item.supportedDevice = (item.supports_desktop === 1) ? 'Desktop & Mobile' : 'Mobile only'
        return item
      })

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.creativesReadyForTraffic, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, param => {
        filterOptions[param].unshift(self.filterModuleData.filterDefaults[param])
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    },
    selectAllToggle: function (e) {
      this.selectAll = !this.selectAll

      // select all
      if (this.selectAll == true) {
        this.paginatedItems.map((item, key) => {
          // Vue.set(object, propertyName, value)
          this.$set(this.creativesSelected, String(item.id), true)
        })
      }

      // deselect all
      if (this.selectAll == false) {
        this.paginatedItems.map((item, key) => {
          this.$set(this.creativesSelected, String(item.id), false)
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'creativeFormats',
      'creativeStatus',
      'KPIs'
    ]),
    ...mapGetters('advertisers', {
      advertiserById: 'itemById',
      advertiserByName: 'itemByName'
    }),
    ...mapGetters('creatives', {
      creativeById: 'itemById',
      creativesReadyForTraffic: 'listReadyForTraffic'
    }),
    ...mapGetters('campaigns', {
      campaignById: 'itemById',
      list: 'list'
    }),
    /**
     * Returns an array with the list of id for selected creatives.
     */
    validCreativesSelected: function () {
      const self = this

      const validEntries = []

      // iterate over creativesSelects
      _.forOwn(self.creativesSelected, (item, key) => {
        // push the ones that are true into validEntries
        if (item === true) validEntries.push(key)
      })

      return validEntries
    },
    validItemsSelectedToObjects: function () {
      const data = []
      this.validCreativesSelected.map(item => {
        data.push({ id: parseInt(item) })
      })

      return data
    },
    filteredItems: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(self.creativesReadyForTraffic, self.filterModuleData.search)

      this.$log.info(searchedItems)

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(self.filterModuleData.filterValues, (filter, key) => {
        // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
        const filterOption = _.get(self.filterModuleData.filterOptions, '[' + key + '][0]', false)

        // if filterOption is not false and the filter option is not the first option
        return filterOption && filter != filterOption
      })

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(filteredItems, [self.tableDataOptions.orderBy], [self.tableDataOptions.order])

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(self.filteredItems, self.pagination.currentPage, self.pagination.perPage)
    }
  },
  created () {
    const self = this

    self.fetchReadyForTraffic()
  },
  watch: {
    creativesReadyForTraffic: function (value) {
      const self = this

      self.$log.info('CREATIVES READY FOR TRAFFIC', this.creativesReadyForTraffic)

      this.mapItemsFilters()

      self.populateFilters()
    },
    list: function (value) {
      this.mapItemsFilters()
      this.populateFilters()
    }
  }
}
</script>

<style>
  .icon-name{
    margin:0 5px;
  }
</style>
