<template>

    <div class="appFrame">

        <!-- <Navbar
          v-if="$store.state.cognito.authenticated"
          :currentModulePath="$route.path"
          :currentModuleName="$route.name"
        /> -->

        <Navbar
          v-if="$store.getters.authenticated"
          :currentModulePath="$route.path"
          :currentModuleName="$route.name"
        />

        <main class="moduleFrame moduleFrame--creativeRepository">

          <div class="loader" :class="{'done': (!loading)}">
            <font-awesome-icon icon="spinner" class="spinner"/>
          </div>

          <PencilBar />

          <ModuleHeader>
            <ul slot="tabs">
              <li><router-link to="/creative-studio/creatives">Creatives</router-link></li>
              <li selected><router-link to="/creative-studio/lists">Lists</router-link></li>
            </ul>

            <div slot="title">Lists</div>

            <div slot="actions">
              <button class="moduleHeader__createButton" @click.prevent="openNewList"><font-awesome-icon icon="plus-circle" class="i" /> New List</button>
            </div>
          </ModuleHeader>

          <ModuleFilter>
            <div slot="searchField">
              <FormField label="Search Lists" forId="filter-search" type="search">
                <input v-model="filterModuleData.search" id="filter-search" type="text" required />
              </FormField>
            </div>

            <div slot="collapsableFilters">
              <FormField label="Visibility" forId="filter-visibility" type="select" class="moduleFilter__filter">
                <select v-model="filterModuleData.filterValues.visibility" id="filter-visibility">
                  <option v-for="visibility in filterModuleData.filterOptions.visibility" :key="visibility">{{visibility}}</option>
                </select>
                <div slot="custom-content" class="moduleFilter__filter__clear"
                  v-if="filterModuleData.filterValues.visibility !== filterModuleData.filterDefaults.visibility"
                  v-on:click="filterModuleData.filterValues.visibility = filterModuleData.filterDefaults.visibility">[X] CLEAR
                </div>
              </FormField>
            </div>
          </ModuleFilter>

          <section class="moduleContent">
            <div class="contentTableFrame">
              <ContentTableTools>
                <FormField slot="left" forId="tools-orderby" type="select" tagLeft="Order By:">
                  <select v-model="tableDataOptions.orderBy" id="tools-orderby">
                    <option v-for="(orderByOption, index) in tableDataOptions.options" :key="index" :value="orderByOption.value">{{orderByOption.label}}</option>
                  </select>
                </FormField>

                <FormField slot="left" forId="tools-order" type="select" tagLeft="Order:">
                  <select v-model="tableDataOptions.order" id="tools-order">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </FormField>

                <div slot="right" class="contentTableFrame__total">Total Lists: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

              <div class="contentTableWrapper" :class="{'contextualMenuExpanded': contextualMenuExpanded}">
                <table class="contentTable">
                  <thead>
                    <tr>
                      <th class="contentTable__dimension">List Name</th>
                      <th>Owner</th>
                      <th>Visibility</th>
                      <th>Access</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody v-if="paginatedItems.length > 0">
                    <tr v-for="(item, index) in paginatedItems" v-bind:key="item.id">
                      <td class="contentTable__dimension"><a
                    class="overflow-ellipsis"
                    :title="item.name"
                    href="#"
                    @click.prevent="viewList(item)"
                  >{{item.name}}</a></td>
                      <td column-name="Owner">{{item.owner}}</td>
                      <td column-name="Visibility">
                        <font-awesome-icon v-if="item.visibility=='Public'" icon="lock-open" class="i td_i" />
                        <font-awesome-icon v-if="item.visibility!='Public'" icon="lock" class="i td_i" />
                        <a href="#" v-on:click.prevent="filterModuleData.filterValues.visibility = item.visibility">{{item.visibility}}</a>
                      </td>
                      <td column-name="Access"><a href="#" v-on:click.prevent="''">{{item.private ? "Private" : "Shared"}}</a></td>
                      <td class="contentTable__actions">
                        <ActionsDropdown :dropup="!(index<(paginatedItems.length-2))" v-bind:itemId="item.id" v-bind:actions="tableActions" @show="contextualMenuExpanded=true"  @hide="contextualMenuExpanded=false"/>
                      </td>
                    </tr>

                    <tr v-if="paginatedItems.length < 1">
                      <td colspan="5">No creative matches your criteria</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ContentTableTools>
                <div slot="right" class="contentTableFrame__total">Total Lists: {{filteredItems.length}} </div>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

            </div>

          </section>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />>
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            @save-new-success="modalSaveNew"
            @save-update-success="modalSaveUpdate"
            @cancel="hideModal"
           />
        </b-modal>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import WebLinks from '@/components/WebLinks.vue'
import ListProfileModal from '@/views/modals/RepoListProfile.vue'

import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'

export default {
  loading: true,
  name: 'CreativeRepository',
  components: {
    Navbar, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks, ListProfileModal
  },
  data () {
    return {
      staticBModal: true,
      loading: true,
      mobileFiltersExpanded: false,
      filterModuleData: {
        search: '',
        filterValues: {
          visibility: 'All visibilities'
        },
        filterDefaults: {
          visibility: 'All visibilities'
        },
        filterOptions: {
          visibility: []
        }
      },
      tableDataOptions: {
        orderBy: 'name',
        order: 'asc',
        options: [
          {
            label: 'Name',
            value: 'name'
          },
          {
            label: 'Visibility',
            value: 'visibility'
          },
          {
            label: 'Access',
            value: 'private'
          },
          {
            label: 'Owner',
            value: 'owner'
          }
        ]
      },
      breadcrumsData: [
        {
          text: 'Creative Studio',
          href: '/creative-studio'
        },
        {
          text: 'Lists',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null
      },
      data: [],
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'List Settings',
          clickAction: this.openListSettings
        },
        {
          ico: 'link',
          text: 'Copy Url to Clipboard',
          clickAction: this.copyListUrlToClipboard
        },
        {
          ico: 'trash-alt',
          text: 'Delete List',
          clickAction: this.deleteList
        }
      ],
      contextualMenuExpanded: false
    }
  },
  methods: {
    openListSettings: function (ListId) {
      const self = this

      // set the modal item id to the userId we want to update
      self.modalData.itemId = ListId

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = ListProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },
    copyListUrlToClipboard: function (listId) {
      const domain = window.location.origin
      const urlToClipboard = origin + '/l/' + listId

      const el = document.createElement('textarea')
      el.value = urlToClipboard
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    viewList: function (list) {
      // open file url in a new window
      window.open('/l/' + list.id, '_blank')
    },
    deleteList: function (ListId) {
      const self = this

      // the the spinner on
      self.loading = true

      // fet user from users.
      const list = _.find(self.data, { id: ListId })

      if (confirm(`Are you sure you want delete to this list: ${list.name}?`)) {
        Api.getList(ListId)
          .then(Api.deleteList)
          .then(response => {
            const listIndex = _.findIndex(self.data, { id: ListId })

            self.data.splice(listIndex, 1)

            // turne the spinner off
            self.loading = false
          })
          .catch(error => {
            self.$log.error(error)

            // turne the spinner off
            self.loading = false
            alert(`There was an issue deleting this list. Please try again. Error Code:${error}`)
          })
      } else {
        // turn the spinner off
        self.loading = false
      }
    },
    openNewList: function () {
      const self = this

      // set the modal item id to null to create new user
      self.modalData.itemId = null

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = ListProfileModal // this resets everytime the modal is hidden. this happens on the @hidden of b-modal.

      // show modal
      self.$refs.moduleModal.show()
    },
    modalSaveNew: function (listData) {
      const self = this

      // turn on loading spinner
      self.loading = true

      self.populate()

      // hide modal
      self.hideModal()
    },
    modalSaveUpdate: function (listData) {
      const self = this

      // turn on loading spinner
      self.loading = true

      self.populate()

      // hide modal
      self.hideModal()
    },
    hideModal: function () {
      const self = this

      // show modal
      self.$refs.moduleModal.hide()
    },
    populate: function () {
      const self = this

      // get data from API
      Api.getListsList()
        .then(data => {
        // pick the lists that can be seen. This should actually be filtered at API level....
          data = data.filter(list => {
            if (list.private == '1') {
              return list.user_id == self.userInSession.id || self.userInSession.role == 'Admin'
            } else {
              return true
            }
          })

          self.$log.info('lists:', data)

          // turn off loading spinner
          self.loading = false

          // store the data in userData
          self.data = data

          return data
        })
        .catch(error => {
          self.$log.error(error)
        })
    }
  },
  computed: {
    ...mapGetters([
      'userInSession'
    ]),
    filteredItems: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(self.data, self.filterModuleData.search)

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(self.filterModuleData.filterValues, (filter, key) => {
        // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
        const filterOption = _.get(self.filterModuleData.filterOptions, '[' + key + '][0]', false)

        // if filterOption is not false and the filter option is not the first option
        return filterOption && filter != filterOption
      })

      // filter data
      let filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      // order data asc or desc
      filteredItems = _.orderBy(filteredItems, [self.tableDataOptions.orderBy], [self.tableDataOptions.order])

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(self.filteredItems, self.pagination.currentPage, self.pagination.perPage)
    }
  },
  created () {
    const self = this

    self.populate()
  },
  watch: {
    data: function () {
      const self = this

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.data, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, param => {
        filterOptions[param].unshift(self.filterModuleData.filterDefaults[param])
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/common.scss";

.contentTableFrame {
  display: block;

  &__pagination {

    @extend .no-select;

    $pagination-li-w: 3.5rem;
    $pagination-li-h: 3.5rem;

    text-align: left;
    display: inline-block;
    padding: 0;
    vertical-align: top;
    border-left: 1px solid $gray5;
    border-right: 1px solid $gray5;
    margin: 0px;

    li {
      width: $pagination-li-w;
      height: $pagination-li-h;
      line-height: $pagination-li-h;
      padding: 0;
      text-align: center;
      display: inline-block;
      border-top: 1px solid $gray5;
      border-bottom: 1px solid $gray5;

      & + li {
        border-left: 1px solid $gray5;
      }

      &.active {
        border-color: $pink1;
        background-color: $pink1;

         & + li {
           border-left-color: $pink1;
         }

         a {
          color: white;
          text-decoration: none;

          &:hover {
            background-color: $pink3;
          }
        }
      }

      & a,
      & span {
        color: $gray6;
        text-decoration: none;
        border: none;
        width: 100%;
        height: 100%;
        line-height: $pagination-li-h;
        display: inline-block;
        background: none;
      }

      a {
        &:hover {
          background: $gray5;
        }
      }
    }

  }
}
</style>
