<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { createNamespacedHelpers, useState } from 'vuex-composition-helpers'
import { useRoute, useRouter } from '@/router/index.js'
import { createToastInterface } from 'vue-toastification'
import { debounce } from 'lodash'
import LogsModal from '@/views/modals/CreativeUploadLogsModal.vue'
import 'vue-toastification/dist/index.css'
import checkmark from '@/assets/checkmark.svg'
import emptyCheckmark from '@/assets/emptyCheckmark.svg'
import CreativeFileUpload from '@/components/PadSquadCreativeUpload/CreativeFileUpload.vue'
import Navbar from '@/components/Navbar.vue'
import CreativeUploadDraftPreview from './CreativeUploadDraftPreview.vue'
import ApiCreativeProduction from '@/helpers/ApiCreativeProduction'
import ConfirmationModal from '../modals/ConfirmationModal.vue'

const route = useRoute()
const router = useRouter()

const {
  useActions,
  useState: useCreativeState,
  useMutations,
  useGetters
} = createNamespacedHelpers('creatives')

const { users: userStore } = useState(['users'])
const creativesState = useCreativeState(['creatives'])
const creativeState = useCreativeState(['creative'])
const temporaryCreativeState = useCreativeState(['temporaryCreative'])
const { SET_OPEN_MODAL, SET_IS_TEMP, SET_BY_ID } = useMutations([
  'SET_OPEN_MODAL',
  'SET_IS_TEMP',
  'SET_BY_ID'
])

const { mostRecentLogStatus } = useGetters(['mostRecentLogStatus'])

const {
  updatePadSquadTemporaryCreative,
  fetchTemporaryCreativeById,
  createPadSquadCreative,
  updatePadSquadCreative,
  fetchByPsId,
  fetchPadSquadCreatives,
  createPadSquadCreativesLogs,
  updateCreativePlacementsOnCreativeChange
} = useActions([
  'updatePadSquadTemporaryCreative',
  'fetchTemporaryCreativeById',
  'createPadSquadCreative',
  'updatePadSquadCreative',
  'fetchByPsId',
  'fetchPadSquadCreatives',
  'createPadSquadCreativesLogs',
  'updateCreativePlacementsOnCreativeChange'
])

const temporaryCreativeId = computed(() => {
  return route.params.id
})
const userEmail = computed(() => userStore.value.userInfo.attributes.email)

const temporaryCreativeData = ref({
  name: '',
  campaign_name: '',
  campaign_id: null,
  adType: '',
  adSize: '',
  orientationType: '',
  asset_url: '',
  zip_url: '',
  displayOptions: [],
  customDimensions: '',
  sponsoredTop: 'ADVERTISEMENT',
  sponsoredBottom: 'ENTER THE SPONSORED AD BOTTOM TEXT',
  creativeId: '',
  fitToScreen: null,
  ad_weight: 0,
  kpi2_id: 0,
  main_kpi_id: 0
})
const isEdit = ref(false)
const isLogModalOpen = ref(false)
const demoDisplayView = ref('mobile')
const customWidth = ref('')
const customHeight = ref('')
const fitToScreen = ref(false)
const statusType = ref('')
const liveCreative = ref({})
const disableExport = ref(true)
const isConfirmationModal = ref(false)
const isNewAssetsUploaded = ref(false)
const draftPreviewKey = ref(1)
const deleteQueue = ref([])
const displayButtons = [
  {
    val: 'mobile',
    icon: 'mobile-alt'
  },
  {
    val: 'desktop',
    icon: 'desktop'
  }
]
const adTypeOptions = ref(['Generic Ad', 'Scroller Ad'])
const adSizeOptions = ref(['320 x 50', '300 x 250', 'Custom'])
const orientationOptions = ref(['Portrait', 'Landscape', 'ANY'])
const isLoading = ref(false)

const closeLogModal = () => {
  isLogModalOpen.value = false
}

const saveLogModal = async (event) => {
  statusType.value = event

  try {
    closeLogModal()
    await onSubmit()
  } catch (error) {
    toast.error('Failed to save log.')
    console.error('Failed to save log modal:', error)
  } finally {
  }
}

const handleLogName = computed(() => {
  return `${temporaryCreativeData.value.name}_${temporaryCreativeData.value.id}`
})

const checkBoxItems = ref([
  { label: 'Mobile Portrait', isChecked: false },
  { label: 'Mobile Landscape', isChecked: false },
  { label: 'Desktop', isChecked: false },
  { label: 'Tablet Portrait', isChecked: false },
  { label: 'Tablet Landscape', isChecked: false }
])

const confirmationModalOptions = {
  affirmative: {
    display: 'confirm',
    val: true
  },
  negative: {
    display: 'cancel',
    val: false
  }
}

onBeforeMount(async () => {
  // ensure creatives state is populated before checking if creative exists
  if (creativesState.creatives.value.length === 0)
    await fetchPadSquadCreatives()
  const creativesList = creativesState.creatives.value

  // gets the temporary creative
  await fetchTemporaryCreativeById(temporaryCreativeId.value)

  // Checks if the creative exists
  const doesArrayContainId = (creativesList, temporaryCreativeId) => {
    return creativesList.find((obj) => {
      const hasCreativePsId = obj.hasOwnProperty('creative_ps_id')
      const isMatchingId = obj.creative_ps_id === temporaryCreativeId

      return hasCreativePsId && isMatchingId
    })
  }

  // Reads the displayOptions values, checks for isChecked if there is match it applies the values to checkBoxItems
  const updateCheckBoxItems = (temporaryCreativeData, checkedBoxes) => {
    const displayOptions = temporaryCreativeData.displayOptions

    // Check if 'displayOptions' is an array before attempting to iterate
    if (Array.isArray(displayOptions)) {
      for (const displayOption of displayOptions) {
        const matchedIndex = checkedBoxes.findIndex(
          (item) => item.label === displayOption
        )

        if (matchedIndex !== -1) {
          checkedBoxes[matchedIndex].isChecked = true
        }
      }
    } else {
      // Handle the case where 'displayOptions' is not an array (e.g., displayOptions is not iterable)
      console.error("Warning: 'displayOptions' is not an array.")
    }

    return checkedBoxes
  }

  const result = await doesArrayContainId(
    creativesList,
    temporaryCreativeId.value
  )

  temporaryCreativeData.value = temporaryCreativeState.temporaryCreative.value

  temporaryCreativeData.value.fitToScreen
    = temporaryCreativeState.temporaryCreative.value.fitToScreen === 1
  fitToScreen.value = temporaryCreativeData.value.fitToScreen

  if (result) {
    // If creative exists, it will pull the live creative, than merge the data with the temp.
    isEdit.value = true
    await updateCheckBoxItems(temporaryCreativeData.value, checkBoxItems.value)
    parseDimens()
    liveCreative.value = await fetchByPsId(temporaryCreativeId.value)
  } else {
  }
})
// Toastification settings
const pluginOptions = {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
}
const toast = createToastInterface(pluginOptions)

const deviceButtonStyling = (deviceType) => {
  const isActive = demoDisplayView.value === deviceType

  return {
    color: isActive ? '#646464' : '#C3C3C3',
    backgroundColor: isActive ? '#E1E1E1' : '#F2F2F2',
    border: '3px solid #E1E1E1'
  }
}

const handleCustomDimensions = () => {
  if (temporaryCreativeData.value.adSize === 'Custom') {
    temporaryCreativeData.value.customDimensions = `${customWidth.value} X ${customHeight.value}`
    liveTemporaryUpdate()
    return temporaryCreativeData.value
  } else return (temporaryCreativeData.value.customDimensions = '')
}

const handleExistingUrlPath = computed(() => {
  if (temporaryCreativeData.value.asset_url === '0') {
    return null
  } else {
    return temporaryCreativeData.value.asset_url
  }
})

const creativeAssetLogs = computed(() => {
  return {
    asset_url: temporaryCreativeData.value.asset_url,
    zip_url: temporaryCreativeData.value.zip_url,
    email: userEmail.value,
    status: statusType.value,
    creative_id: liveCreative.value.id,
    name: handleLogName.value
  }
})

const updateDisplayOptions = () => {
  const checkedOptions = checkBoxItems.value
    .filter((item) => item.isChecked)
    .map((item) => item.label)

  temporaryCreativeData.value.displayOptions = Array.isArray(checkedOptions)
    ? checkedOptions
    : [checkedOptions]

  liveTemporaryUpdate()
}
// Will check boxes based on the orientation input.
const handleOrientationDisplay = () => {
  const isPortrait = temporaryCreativeData.value.orientationType === 'Portrait'
  const isLandscape
    = temporaryCreativeData.value.orientationType === 'Landscape'

  checkBoxItems.value[0].isChecked = isPortrait
  checkBoxItems.value[3].isChecked = isPortrait
  checkBoxItems.value[1].isChecked = isLandscape
  checkBoxItems.value[4].isChecked = isLandscape
  updateDisplayOptions()
}
// handles input models based on what adtype is selected
const handleAdTypeChange = (event) => {
  if (event.target.value === 'Scroller Ad') {
    temporaryCreativeData.value.adSize = '300 x 250'
    temporaryCreativeData.value.orientationType = 'Portrait'
    temporaryCreativeData.value.sponsoredBottom
      = 'SCROLL TO CONTINUE WITH CONTENT'
    temporaryCreativeData.value.sponsoredTop = 'ADVERTISEMENT'
  } else if (event.target.value === 'Generic Ad') {
    temporaryCreativeData.value.orientationType = 'ANY'
    temporaryCreativeData.value.sponsoredBottom = ''
    temporaryCreativeData.value.sponsoredTop = ''
  }
  temporaryCreativeData.value.adType = event.target.value
  handleOrientationDisplay()
}

const getAssetUrl = (assetUrl) => {
  temporaryCreativeData.value.asset_url = assetUrl
}

const checkForAssetInComponent = () => {
  console.log('disableExport', disableExport.value)
  disableExport.value
    ? toast.error('No file asset in component.')
    : (isLogModalOpen.value = true)
}

const getZipUrl = (zipUrl) => {
  temporaryCreativeData.value.zip_url = zipUrl
}

const handleFitToScreen = () => {
  temporaryCreativeData.value.fitToScreen = fitToScreen
  console.log('temporaryCreativeData', temporaryCreativeData.value)
  liveTemporaryUpdate()
}

const refreshCreative = () => {
  draftPreviewKey.value += 1
}

const getCreativeTag = async () => {
  try {
    const res = await ApiCreativeProduction.generateCreativeScript(
      temporaryCreativeData.value
    )
    temporaryCreativeData.value.creative_tag = res.body.creative_tag
    refreshCreative()
  } catch (err) {
    console.error('Error: ', err)
  }
}

const creativeDataModel = [
  'creative_ps_id',
  'campaign_id',
  'format_id',
  'creative_type_id',
  'main_kpi_id',
  'name',
  'creative_tag',
  'status_id',
  'production_build_type_id',
  'kpi_goal',
  'kpi2_id',
  'kpi2_goal',
  'creative_tag',
  'adType',
  'adSize',
  'orientationType',
  'asset_url',
  'zip_url',
  'displayOptions',
  'customDimensions',
  'sponsoredTop',
  'sponsoredBottom',
  'fitToScreen',
  'ad_weight'
]

const matchKeysWithValues = (data, keys) =>
  keys.reduce((result, key) => {
    result[key]
      = key === 'main_kpi_id' || key === 'kpi2_id' ? data[key] : data[key] || null
    return result
  }, {})

const liveTemporaryUpdate = debounce(async () => {
  try {
    await getCreativeTag()
    const finalCreativeData = matchKeysWithValues(
      temporaryCreativeData.value,
      creativeDataModel
    )
    await updatePadSquadTemporaryCreative(finalCreativeData)
  } catch (error) {
    console.error('Error:', error)
  }
}, 500)

const pushToDeleteQueue = (zip) => {
  if (isEdit.value && liveCreative.value.asset_url?.includes(zip)) return
  deleteQueue.value.push(zip)
}

const deleteAllZipInQueue = () => {
  deleteQueue.value.forEach((zip) => {
    ApiCreativeProduction.deleteZip({ key: zip })
  })
}

const selectConfirmationModalOption = (isConfirm) => {
  if (!isConfirm) {
    isConfirmationModal.value = false
    return
  }

  const currentZip = extractPath(temporaryCreativeData.value.asset_url)
  if (isNewAssetsUploaded.value && !deleteQueue.value.includes(currentZip)) {
    deleteQueue.value.push(currentZip)
  }

  deleteAllZipInQueue()
  isConfirmationModal.value = false

  isEdit.value ? SET_IS_TEMP(false) : SET_IS_TEMP(true)

  SET_OPEN_MODAL(true)

  router.push({
    path: `/creative-production/creatives/campaign/${temporaryCreativeData.value.campaign_id}`
  })
}

const extractPath = (url) => {
  if (!url) return ''
  const pathSegments = url.split('/')
  const key = pathSegments.slice(1, 3).join('/')
  return `${key}/`
}

const parseDimens = () => {
  if (
    !temporaryCreativeData.value.adSize
    || temporaryCreativeData.value.adSize !== 'Custom'
  )
    return
  const splitArr = temporaryCreativeData.value.customDimensions.split(' ') || []
  customWidth.value = splitArr[0] || ''
  customHeight.value = splitArr[2] || ''
}

const logDataApiCall = async (data) => {
  if (disableExport.value === true) {
    toast.error('No asset availible to export.')
  } else {
    try {
      await createPadSquadCreativesLogs(data)
    } catch (e) {
      console.error('Error with log creation:', e)
    }
  }
}

const onSubmit = async () => {
  try {
    await getCreativeTag()
    const finalCreativeData = matchKeysWithValues(
      temporaryCreativeData.value,
      creativeDataModel
    )
    deleteAllZipInQueue()

    if (!isEdit.value) {
      const { id: temporaryCreativeId, ...finalCreativeData }
        = matchKeysWithValues(temporaryCreativeData.value, creativeDataModel)

      const createRes = await createPadSquadCreative(finalCreativeData)

      liveCreative.value = createRes

      await updatePadSquadTemporaryCreative(finalCreativeData)
      await logDataApiCall(creativeAssetLogs.value)

      if (createRes) {
        toast.success('Successful Export!')
        isLogModalOpen.value = true
      } else {
        toast.error('Failed to export.')
      }
    } else {
      const updateRes = await updatePadSquadTemporaryCreative(finalCreativeData)
      const updateLiveCreative = await updatePadSquadCreative(finalCreativeData)
      const updatePlacementsRes = await updateCreativePlacementsOnCreativeChange({...finalCreativeData, id: liveCreative.value.id})

      await logDataApiCall(creativeAssetLogs.value)

      if (updateRes && updateLiveCreative) {
        toast.success('Successful Update!')
        isLogModalOpen.value = true
      } else {
        toast.error('Failed to update temporary creative.')
      }
    }
    router.push({
      path: `/creative-production/creatives/campaign/${temporaryCreativeData.value.campaign_id}`
    })
  } catch (error) {
    console.error('An error occurred:', error)
  }

  SET_IS_TEMP(false)
  SET_OPEN_MODAL(false)
}
</script>

<template>
  <div class="app-container">
    <Navbar
      :current-module-path="'creative-production-creatives'"
      :current-module-name="'Creative Production Creatives'"
    />
    <div class="content-container">
      <header
        class="d-flex justify-content-between pb-4"
        style="border-bottom: 2px solid lightgrey"
      >
        <div class="d-flex align-items-center">
          <button
            class="back-button d-flex"
            @click="isConfirmationModal = true"
          >
            <font-awesome-icon
              icon="arrow-left"
              style="width: 11px; height: 14px; margin: auto"
            />
          </button>
          <div class="ml-4 custom-header">Creative Upload</div>
        </div>
        <div class="d-flex">
          <button
            class="export-button ml-2"
            @click="checkForAssetInComponent()"
          >
            EXPORT
          </button>
        </div>
      </header>

      <!-- Page Content -->
      <div
        class="d-flex flex-nowrap row"
        style="margin-left: 2px; background-color: #f2f2f2"
      >
        <!-- Form -->
        <div
          style="
            border-right: solid lightgrey 1px;
            width: 29%;
            padding-top: 45px;
          "
          class="pr-5 d-flex flex-column"
        >
          <form
            id="mainForm"
            @submit="onSubmit"
          >
            <div class="form-group">
              <label class="label-style">CREATIVE NAME</label>
              <input
                v-model="temporaryCreativeData.name"
                type="text"
                style="
                  background-color: white;
                  color: #131212 !important;
                  -webkit-text-stroke: 0.25px #000000 !important;
                "
                class="form-control input-style col-12"
                @change="liveTemporaryUpdate()"
              />
            </div>
            <div class="form-group">
              <label class="label-style">CAMPAIGN NAME</label>
              <input
                type="text"
                disabled
                style="
                  background-color: white;
                  color: #131212 !important;
                  -webkit-text-stroke: 0.25px #000000 !important;
                "
                class="form-control input-style col-12"
                :placeholder="temporaryCreativeData.campaign_name"
              />
            </div>
            <!-- File Selection Component -->
            <label style="color: #9b9b9b; padding-bottom: 5px"
              >Zip File Upload</label
            >
            <div>
              <CreativeFileUpload
                :creative-id="temporaryCreativeId"
                :existing-zip-url="handleExistingUrlPath"
                @get-asset-url="(data) => getAssetUrl(data)"
                @get-zip-url="(data) => getZipUrl(data)"
                @disable-export="(value) => (disableExport = value)"
                @update-ad-weight="
                  (value) => (temporaryCreativeData.ad_weight = value)
                "
                @update-temporary-creative="liveTemporaryUpdate()"
                @push-to-delete-queue="pushToDeleteQueue"
                @new-assets-uploaded="() => (isNewAssetsUploaded = true)"
              />
            </div>

            <!--Ad Type Drop Down -->
            <div>
              <label
                for="adType"
                class="label-style"
                >AD TYPE</label
              >
              <div>
                <select
                  v-model="temporaryCreativeData.adType"
                  class="col-12 mb-5 input-style"
                  style="color: #131212; font-weight: 600"
                  @input="(val) => handleAdTypeChange(val)"
                >
                  <option
                    v-for="(option, index) in adTypeOptions"
                    :key="index"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Top & Bottom Text Fields -->
            <div v-if="temporaryCreativeData.adType === 'Scroller Ad'">
              <label
                for="sponsoredTop"
                class="label-style"
                >ENTER THE SPONSORED AD TOP TEXT</label
              >
              <div>
                <input
                  v-model="temporaryCreativeData.sponsoredTop"
                  type="text"
                  style="
                    background-color: white;
                    color: #131212 !important;
                    -webkit-text-stroke: 0.25px #000000 !important;
                  "
                  class="form-control input-style col-12"
                  @input="liveTemporaryUpdate()"
                />
              </div>
              <label
                for="sponsoredBottom"
                class="label-style"
                >ENTER THE SPONSORED AD BOTTOM TEXT</label
              >
              <div>
                <input
                  v-model="temporaryCreativeData.sponsoredBottom"
                  type="text"
                  style="
                    background-color: white;
                    color: #131212 !important;
                    -webkit-text-stroke: 0.25px #000000 !important;
                  "
                  class="form-control input-style col-12"
                  @input="liveTemporaryUpdate()"
                />
              </div>
            </div>
            <!-- Ad Size DropDown -->
            <div>
              <label
                for="adSize"
                class="label-style"
                >AD SIZE</label
              >
              <div>
                <select
                  v-model="temporaryCreativeData.adSize"
                  class="col-12 mb-5 input-style"
                  :disabled="temporaryCreativeData.adType === 'Scroller Ad'"
                  style="color: #131212; font-weight: 600"
                  @change="liveTemporaryUpdate()"
                >
                  <option
                    v-for="(option, index) in adSizeOptions"
                    :key="index"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Custom Ad Size Inputs -->
            <div
              v-if="temporaryCreativeData.adSize === 'Custom'"
              class="d-flex"
            >
              <label class="label-style ml-3">WIDTH</label>
              <div>
                <input
                  v-model="customWidth"
                  class="mb-4 input-style py-3 ml-2 col-12"
                  @change="handleCustomDimensions"
                />
              </div>
              <label class="label-style mr-2 ml-3">HEIGHT</label>
              <div>
                <input
                  v-model="customHeight"
                  class="mb-4 input-style py-3 mr-4 col-12"
                  @change="handleCustomDimensions"
                />
              </div>
            </div>
            <!-- Orientation Drop Down -->
            <div>
              <label
                for="orientation"
                class="label-style"
                >ORIENTATION</label
              >
              <div>
                <select
                  v-model="temporaryCreativeData.orientationType"
                  class="col-12 mb-5 input-style"
                  style="color: #131212; font-weight: 600"
                  @change="handleOrientationDisplay()"
                >
                  <option
                    v-for="(option, index) in orientationOptions"
                    :key="index"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="temporaryCreativeData.adType === 'Scroller Ad'"
              class="ml-0"
            >
              <label
                for="fitToScreenToggle"
                class="label-style"
              >
                <input
                  id="fitToScreenToggle"
                  v-model="fitToScreen"
                  type="checkbox"
                  style="margin-left: -15%"
                  @input="handleFitToScreen()"
                />
                <img
                  class="mr-1"
                  style="
                    position: relative;
                    z-index: 2;
                    height: 25px;
                    width: 25px;
                    vertical-align: middle; /* Adjust the vertical alignment */
                  "
                  :src="fitToScreen ? checkmark : emptyCheckmark"
                />
                <span style="vertical-align: middle">Fit To Screen</span>
              </label>
            </div>
            <!-- Preview Page Options -->
            <label
              for="orientation"
              class="label-style mt-3"
              >PREVIEW PAGE OPTIONS</label
            >
            <div
              style="
                border: 1px solid lightgray;
                border-radius: 10px;
                display: flex;
              "
            >
              <div
                class="row pt-2"
                style="margin-left: 5%; flex: 1"
              >
                <div class="col-md-6">
                  <ul style="list-style-type: none; padding: 0; margin: 0">
                    <li
                      v-for="(item, index) in checkBoxItems.slice(0, 3)"
                      :key="index"
                      :class="
                        item.label === 'Desktop'
                          ? 'list-group-item mt-3 row'
                          : 'list-group-item my-3 row'
                      "
                      style="
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <label style="display: flex; align-items: center">
                        <input
                          v-model="item.isChecked"
                          type="checkbox"
                          class="text-center mr-2"
                          style="
                            position: absolute;
                            left: 0;
                            opacity: 0;
                            z-index: 1;
                            cursor: pointer;
                            width: 16px;
                            height: 16px;
                          "
                          @change="updateDisplayOptions()"
                        />
                        <img
                          class="mr-1"
                          style="
                            position: relative;
                            z-index: 2;
                            height: 25px;
                            width: 25px;
                          "
                          :src="item.isChecked ? checkmark : emptyCheckmark"
                        />
                        {{ item.label }}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="row pt-2"
                style="flex: 1"
              >
                <div class="col-md-12">
                  <ul style="list-style-type: none; padding: 0; margin: 0">
                    <li
                      v-for="(item, index) in checkBoxItems.slice(3, 6)"
                      :key="index"
                      class="mt-3"
                      style="
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <label style="display: flex; align-items: center">
                        <input
                          v-model="item.isChecked"
                          type="checkbox"
                          class="text-center mr-2"
                          style="
                            position: relative;
                            left: 0;
                            opacity: 0;
                            z-index: 1;
                            cursor: pointer;
                            width: 16px;
                            height: 16px;
                          "
                          @change="updateDisplayOptions()"
                        />
                        <img
                          class="mr-1"
                          style="
                            position: relative;
                            z-index: 2;
                            height: 25px;
                            width: 25px;
                          "
                          :src="item.isChecked ? checkmark : emptyCheckmark"
                        />
                        {{ item.label }}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- Creative Preview -->
        <div
          class="d-flex justify-content-center align-items-center flex-grow-1"
        >
          <CreativeUploadDraftPreview
            :key="draftPreviewKey"
            :creative="temporaryCreativeData"
            :view="demoDisplayView"
          />
        </div>
        <!-- Display Buttons -->
        <div
          class="d-flex flex-column justify-content-center align-items-center ml-2"
        >
          <div style="width: 60px; height: 119px">
            <!-- Mobile Preview Button -->
            <button
              v-for="(button, idx) in displayButtons"
              :key="idx"
              :style="deviceButtonStyling(button.val)"
              @click="demoDisplayView = button.val"
            >
              <font-awesome-icon
                :icon="button.icon"
                class="icon-button"
              />
            </button>
          </div>
        </div>
        <!-- Creative Logs Modal -->
        <div v-if="isLogModalOpen">
          <LogsModal
            :status-type="mostRecentLogStatus"
            @save="saveLogModal"
            @close="closeLogModal"
          />
        </div>
      </div>
    </div>
    <!-- Confirmation Modal for back button -->
    <ConfirmationModal
      v-if="isConfirmationModal"
      :prompt="'Any changes you made will not be saved. Are you sure you want to navigate away?'"
      :affirmative-option="confirmationModalOptions.affirmative"
      :negative-option="confirmationModalOptions.negative"
      @select-option="selectConfirmationModalOption"
    />
  </div>
</template>

<style scoped>
.app-container {
  padding-left: calc(3% + 11px);
  background-color: #f2f2f2;
  height: 100%;
  width: 100%;
  /* Adjust the 11px value based on your needs */
}

.content-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  background-color: #f2f2f2;
  /* Add padding to separate content from border */
}

.custom-header {
  font-size: 22px;
  font-weight: bold;
}

.back-button {
  border-radius: 50%;
  border: none !important;
  background-color: #dfdfdf;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.back-button:active {
  background-color: grey;
  transform: translateY(2px);
}

.preview-button {
  color: #8ad622;
  background-color: white;
  border: none !important;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  height: 31px;
  width: 83px;
  cursor: pointer;
}

.export-button {
  color: white;
  background-color: #8ad622;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  height: 31px;
  width: 83px;
  cursor: pointer;
}

.label-style {
  color: #141138;
  font-size: 10px;
  padding-bottom: 3px;
}

.form-group {
  margin-bottom: 2rem;
}

.input-style {
  height: 4%;
  padding: 1.5%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 1.4;
  border: solid 1px #dbdbdb;
}

.list-group-item {
  list-style: none;
  padding: none;
}

.icon-button {
  height: 55px;
  width: 50px;
  padding: 20% 0% 20% 0%;
}

.icon-button:active {
  background-color: lightgray;
}

input[type='checkbox'] {
  opacity: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../assets/dropArrow.png') no-repeat right center;
  background-position: right 10px center;
  font-size: 14px;
  border: solid 1px #dbdbdb;
  background-color: white;
}
</style>
