<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRoute } from '@/router/index.js'
import { fields, initDate } from './fields.js'
import { debounce } from 'lodash'
import axios from 'axios'

const route = useRoute()

const emit = defineEmits(['modify-url-params', 'refresh-creative'])

onMounted(() => {
  initCustomValues()
})

const rowHeight = 30
const optionsFieldRowHeight = 90
const fieldOptions = ['Auto', 'Custom']
const fieldRefs = ref({})
const locationPredictionResults = ref({
  'city': [],
  'country': []
})
const locationSelected = ref({
  'city': false,
  'country': false
})

const expandedField = computed(() => {
  return fields.value.find(x => x.isExpanded)
})

const urlParams = computed(() => {
  const params = route.query
  for (const key in params) {
    if (params[key] !== 'true' && params[key] !== 'false') continue
    params[key] = params[key] === 'true'
  }
  return params
})

const collapse = (field) => {
  setHeightToChildrenHeight(field)
  setHeightToZero(field)
  field.isExpanded = !field.isExpanded
}

const expand = (field) => {
  setHeightToChildrenHeight(field)

  // if another field is open, close it
  if (expandedField.value) collapse(expandedField.value)

  field.isExpanded = !field.isExpanded
}

const toggleExpand = (field) => {
  if (field.isExpanded) collapse(field)
  else expand(field)
}

const getChildrenHeight = (field) => {
  let height = 0
  getEl(field).children.forEach(x => (height += x.offsetHeight))
  return height
}

const onOptionSelect = (field, option) => {
  const action = option.target.value === 'Auto' ? 'delete' : 'set'
  emit('modify-url-params', action, field.name, field.value)
  emit('refresh-creative')

  setTimeout(() => {
    setHeightToChildrenHeight(field)
  }, 10)
}

const onInput = (input, field, option) => {
  switch (field.name) {
    case 'city':
      if (input.length < 2) locationPredictionResults.value.city = []
      debounceCityPrediction(input, field)
      field.value = input
      break
    case 'country':
      if (input.length < 2) locationPredictionResults.value.country = []
      debounceCountryPrediction(input, field)
      field.value = input
      break
    case 'zipCode':
      debounceGetLocationByZip(input, field)
      field.value = input
      break
    case 'date':
      handleDateChange(input)
      break
    default:
      handleCustomInput(input, field)
      break
  }
}

const handleCustomInput = (input, field) => {
  field.value = input
  emit('modify-url-params', 'set', field.name, field.value)
  emit('refresh-creative')
}

const onFocus = (input, field) => {
  if (field.name !== 'date') return
  handleEmptyDateTime(input, field)
}

const onFocusOut = (input, field) => {
  if (field.name !== 'city' && field.name !== 'country') return
  setTimeout(() => {
    field.value = locationSelected.value[field.name] ? field.value : ''
    locationPredictionResults.value[field.name] = []
    setTimeout(() => {
      setHeightToChildrenHeight(field)
    }, 10)
  }, 500)
}

const handleEmptyDateTime = (input, field) => {
  if (!input) handleCustomInput(initDate(), field)
}

const initCustomValues = () => {
  for (const param in urlParams.value) {
    const field = fields.value.find(x => x.name === param)
    if (field) {
      field.selectedOption = 'Custom'
        field.value = urlParams.value[param]
    }
  }
}

const setHeightToChildrenHeight = (field) => {
  getEl(field).style.height = `${getChildrenHeight(field)}px`
}
const setHeightToZero = (field) => {
  getEl(field).style.height = '0px'
}

const getIdx = (field) => {
  return fields.value.findIndex(x => x.name === field.name)
}
const getEl = (field) => {
  return fieldRefs.value[getIdx(field)]
}

const locationCustomStyle = (field, option) => {
  if (field.name !== 'city' || field.name !== 'country') return ''
  if (option !== 'Custom' || field.selectedOption !== 'Custom') return
  return `height: ${optionsFieldRowHeight.value}px`;
}

const predictLocation = async (query, field) => {
  if (query.length < 2) {
    setHeightToChildrenHeight(field)
    return
  }
  const url = "https://www.mapquestapi.com/search/v3/prediction";
  const params = {
    q: query,
    key: process.env.VUE_APP_MAPQUEST_API_KEY,
    collection: "adminArea",
    limit: 15
  };
  try {
    await axios.get(url, {
      params,
    }).then(res => {
      if (res) {
        locationPredictionResults.value[field.name] = res.data.results.filter(
          (r) => r['recordType'] === field.name
        )
      }
    }).finally(() => {
      setHeightToChildrenHeight(field)
    })
  } catch (err) {
    console.log(err);
  }
};

const getLocationByZip = async (zip, field) => {
  if (zip.length < 5 || zip.length > 7) return
  const url = "https://www.mapquestapi.com/geocoding/v1/address"
  const params = {
    location: zip,
    key: process.env.VUE_APP_MAPQUEST_API_KEY
  };
  try {
    await axios.get(url, {
      params
    }).then(res => {
      if (res && res.data.results) {
        const data = res.data.results[0].locations[0] || {}
        handleLocationByZip(data)
      }
    })
  } catch (error) {
    console.log(error)
  }
}

const handleDateChange = (input) => {
  const dateField = fields.value.find(x => x.name === 'date')
  const dayField = fields.value.find(x => x.name === 'day')

  dayField.selectedOption = 'Custom'

  dateField.value = input
  dayField.value = new Date(input).toLocaleString('en-us', {  weekday: 'long' })

  emit('modify-url-params', 'set', dateField.name, dateField.value)
  emit('modify-url-params', 'set', dayField.name, dayField.value)
  emit('refresh-creative')
}

const handleLocationByZip = (location) => {
  const countryField = fields.value.find(x => x.name === 'country')
  const stateField = fields.value.find(x => x.name === 'state')
  const cityField = fields.value.find(x => x.name === 'city')
  const zipCodeField = fields.value.find(x => x.name === 'zipCode')

  countryField.selectedOption = 'Custom'
  stateField.selectedOption = 'Custom'
  cityField.selectedOption = 'Custom'
  zipCodeField.selectedOption = 'Custom'
  
  countryField.value = location.adminArea1
  stateField.value = location.adminArea3
  cityField.value = location.adminArea5
  zipCodeField.value = location.postalCode

  emit('modify-url-params', 'set', countryField.name, countryField.value)
  emit('modify-url-params', 'set', cityField.name, cityField.value)
  emit('modify-url-params', 'set', stateField.name, stateField.value)
  emit('modify-url-params', 'set', zipCodeField.name, zipCodeField.value)
  emit('refresh-creative')
}

const selectLocation = (location, locationType) => {
  const countryField = fields.value.find(x => x.name === 'country')
  const stateField = fields.value.find(x => x.name === 'state')
  const cityField = fields.value.find(x => x.name === 'city')
  const zipCodeField = fields.value.find(x => x.name === 'zipCode')

  stateField.selectedOption = 'Auto'
  zipCodeField.selectedOption = 'Auto'
  stateField.value = ''
  zipCodeField.value = ''
  countryField.value = location.place.properties.country

  locationSelected.value[locationType] = true

  switch (locationType) {
    case 'city':
      countryField.selectedOption = 'Custom'
      cityField.value = location.place.properties.city

      emit('modify-url-params', 'set', countryField.name, countryField.value)
      emit('modify-url-params', 'set', cityField.name, cityField.value)
      break;
    case 'country':
      cityField.selectedOption = 'Auto'
      cityField.value = ''

      emit('modify-url-params', 'set', countryField.name, countryField.value)
      emit('modify-url-params', 'delete', cityField.name, cityField.value)
      break;
    default:
      break;
  }
  
  emit('modify-url-params', 'delete', stateField.name, stateField.value)
  emit('modify-url-params', 'delete', zipCodeField.name, zipCodeField.value)

  emit('refresh-creative')
}

const debounceCityPrediction = debounce(predictLocation, 300)
const debounceCountryPrediction = debounce(predictLocation, 300)
const debounceGetLocationByZip = debounce(getLocationByZip, 300)
</script>

<template>
  <div class="h-100 d-flex flex-column p-3">
    <!-- title -->
    <div class="my-3 text-white pl-4">CONTEXT SIMULATOR</div>
    <!-- context simulator -->
    <div class="context-simulator-container w-100 d-flex flex-column">
      <div
        v-for="(field, idx) in fields"
        :key="idx"
        :style="`
          border-bottom: ${idx !== fields.length - 1 ? '1px solid #CACACA' : ''};
        `"
      >
        <div
          class="cursor-pointer outer-row d-flex justify-content-between align-items-center px-4"
          :style="`height: ${rowHeight}px;`"
          @click="toggleExpand(field)"
        >
          <div>{{ field.display }}</div>
          <font-awesome-icon
            :icon="field.isExpanded ? 'chevron-up' : 'chevron-down'"
            style="height: 10px; width: 10px;"
          />
        </div>

        <!-- expandable field options -->
        <div
          :ref="(el) => (fieldRefs[`${idx}`] = el)"
          class="collapsible overflow-hidden"
          style="background-color: #F0F0F0; height: 0px;"
        >
          <div
            v-for="(option, optIdx) in fieldOptions"
            :key="optIdx"
            class="inner-row cursor-pointer d-flex align-items-center px-4"
            :style="locationCustomStyle(field, option)"
          >
            <input
              :id="`${idx}${optIdx}`"
              v-model="field.selectedOption"
              class="mr-4 cursor-pointer"
              :style="`height: ${rowHeight}px;`"
              :value="option"
              type="radio"
              @input="(option) => onOptionSelect(field, option)"
            />
            <label
              :for="`${idx}${optIdx}`"
              style="margin: 0 !important;"
              class="cursor-pointer"
            >
              {{ option }}
            </label>
          </div>

          <!-- Custom Options -->
          <div
            v-if="field.selectedOption === 'Custom'"
            class="cursor-pointer px-4 pb-3 d-flex"
          >
            <div
              v-for="(customOption, cidx) in field.customOptions"
              :key="cidx"
              class="mr-3"
              style="flex: 1;"
            >
              <select
                v-if="customOption.type === 'select'"
                :value="customOption.value || field.value"
                @input="e => onInput(e.target.value, field, customOption)"
              >
                <option
                  v-for="(opt, oidx) in customOption.options"
                  :key="oidx"
                  :value="opt"
                >
                  {{ opt }}
                </option>
              </select>
              <input
                v-else
                :value="customOption.value || field.value"
                :type="customOption.type"
                :placeholder="customOption.placeholder || ''"
                style="width: 100%"
                @input="e => onInput(e.target.value, field, customOption)"
                @focus="e => onFocus(e.target.value, field, customOption)"
                @focusout="e => onFocusOut(e.target.value, field)"
              />
              <!-- Location Predictions -->
              <div
                v-if="locationPredictionResults[field.name] && locationPredictionResults[field.name].length > 0 && field.isExpanded"
                id="location-predictions-container"
                class="d-flex flex-column justify-content-between align-items-center"
              >
                <div
                  v-for="(location, index) in locationPredictionResults[field.name]"
                  :key="index"
                  class="location-prediction d-flex flex-column w-100 text-center"
                  @click.stop="selectLocation(location, field.name)"
                >
                  {{ location.displayString }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.context-simulator-container {
  border-radius: 5px;
  background-color: #FAFAFA;
  color: #6D6D6D;
  overflow: hidden;
}
#location-predictions-container {
  z-index: 100;
  max-height: 100px;
  width: 100%;
  background-color: #FAFAFA;
  overflow-y: auto;
}
.location-prediction {
  background-color: #FAFAFA;
  height: 35px;
  border-bottom: 1px solid #f0f0f0;
}
.location-prediction:hover {
  background-color: #E1E1E1;
  transition: 0.3s;
}
.outer-row {
  z-index: 2;
}
.inner-row {
  background-color: #F0F0F0;
  border-top: 1px solid #CACACA;
}
.collapsible {
  transition: height 300ms ease-in-out;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-hidden {
  overflow: hidden;
}
</style>
