<template>

    <div class="appFrame">

        <Navbar
          v-if="$store.getters.authenticated"
          :currentModulePath="$route.path"
          :currentModuleName="$route.name"
        />

        <main class="moduleFrame moduleFrame--creativeRepository moduleFrame--creativeRepositoryList" :class="{'moduleFrame--noBar': !$store.getters.authenticated}">
          <div class="loader" :class="{'done': (!loading)}">
            <font-awesome-icon icon="spinner" class="spinner"/>
          </div>

          <PencilBar />

          <ModuleHeader>
            <div slot="title">
              <div class="moduleHeader__title__brand"><img src="@/assets/simpli-logo.svg" /></div> List: {{list.name}}
            </div>
          </ModuleHeader>

          <ModuleFilter>
            <div slot="searchField">
              <FormField label="Search Creatives" forId="filter-search" type="search">
                <input v-model="filterModuleData.search" id="filter-search" type="text" required />
              </FormField>
            </div>

            <div slot="collapsableFilters">
              <FormField label="Advertisers" forId="filter-advertiser" type="select">
                <select v-model="filterModuleData.filterValues.advertiser" id="filter-advertiser">
                  <option v-for="advertiser in filterModuleData.filterOptions.advertiser" :key="advertiser">{{advertiser}}</option>
                </select>
              </FormField>

              <FormField label="Vertical" forId="filter-vertical" type="select">
                <select v-model="filterModuleData.filterValues.vertical" id="filter-vertical">
                  <option v-for="vertical in filterModuleData.filterOptions.vertical" :key="vertical">{{vertical}}</option>
                </select>
              </FormField>

              <FormField label="Format" forId="filter-format" type="select">
                <select v-model="filterModuleData.filterValues.format" id="filter-format">
                  <option v-for="format in filterModuleData.filterOptions.format" :key="format">{{format}}</option>
                </select>
              </FormField>
            </div>
          </ModuleFilter>

          <section class="moduleContent">

            <div class="contentTableFrame">

            <div class="alert-heading-var" :key="list.id" >List Owner: {{ list.owner }}</div>
              <ContentTableTools>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

              <div class="contentTableWrapper" :class="{'contextualMenuExpanded': contextualMenuExpanded}">
                <table class="contentTable">
                  <thead>
                    <tr>
                      <th class="contentTable__dimension"><!-- <input type="checkbox" />--> Creative Name</th>
                      <th>Advertiser</th>
                      <th>Vertical</th>
                      <th>Format</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in paginatedItems" v-bind:key="item.id">
                      <td class="contentTable__dimension"><!--<input type="checkbox" :name="'creative_' + item.id" />--> <a
                    class="overflow-ellipsis"
                    :title="item.name"
                    href="#"
                    @click.prevent="viewCreative(item)"
                  >{{item.name}}</a></td>
                      <td column-name="Advertiser"><a href="#" v-on:click.prevent="filterModuleData.filterValues.advertiser = item.advertiser">{{item.advertiser}}</a></td>
                      <td column-name="Vertical"><a href="#" v-on:click.prevent="filterModuleData.filterValues.vertical = item.vertical">{{item.vertical}}</a></td>
                      <td column-name="Format"><a href="#" v-on:click.prevent="filterModuleData.filterValues.format = item.format">{{item.format}}</a></td>
                      <td class="contentTable__actions">
                        <button @click="viewCreative(item)"><font-awesome-icon icon="eye" class="i" /></button>
                        <ActionsDropdown :dropup="paginatedItems.length>10 && !(index<(paginatedItems.length-2))" v-bind:itemId="item.id" v-bind:actions="tableActions" @show="contextualMenuExpanded=true"  @hide="contextualMenuExpanded=false"/>
                      </td>
                    </tr>

                    <tr v-if="paginatedItems.length < 1">
                      <td colspan="5">No creative matches your criteria</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ContentTableTools>
                <b-pagination slot="right" class="contentTableFrame__pagination" :limit="3" :total-rows="filteredItems.length" v-model="pagination.currentPage" :per-page="pagination.perPage"></b-pagination>
              </ContentTableTools>

            </div>

          </section>

          <ModuleFooter>
            <div slot="left">© {{ new Date() | moment('YYYY') }} PADSQUAD LLC</div>
            <WebLinks slot="right" />
          </ModuleFooter>

        </main>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          ref="moduleModal"
          title=""
          @hidden="modalData.currentComponent=null"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus>
          <component
            :is="modalData.currentComponent"
            :itemId="modalData.itemId"
            @save-new-success="modalSaveNew"
            @save-update-success="modalSaveUpdate"
            @done="hideModal()"
           />
        </b-modal>

        <!-- Modal Component -->
        <b-modal
          :static="staticBModal"
          ref="lockdownModal"
          title=""
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus
          centered>
          <section class="sModal">
            <header class="sModal__header">
              List: {{list.name}}
            </header>

            <form>
              <div class="sModal__body">

                <FormField label="Passkey" description="This is a private list and you need a passkey. If you were not provided with a passkey, place contact your Simpli representative." forId="list-passkey" :class="{'wrong':lockdown.fail}">
                  <input v-model="lockdown.password" id="list-passkey" type="text" />
                  <small v-if="lockdown.fail">*Incorrect Passkey</small>
                </FormField>
              </div>
              <footer class="sModal__footer">
                <div class="sModal__footer__left">
                  <router-link class="saveButton" :to="{name:'Sign In'}"><font-awesome-icon icon="lock" class="i" />Take me to login</router-link>
                </div>
                <div class="sModal__footer__right">
                  <!-- show 'Save New Creative' if this is new creative and we are on the last screen-->
                  <button @click.prevent="validatePassword" class="saveButton"><font-awesome-icon icon="lock-open" class="i" />Let me in</button>
                </div>
              </footer>
            </form>

          </section>
        </b-modal>

    </div>

</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import ModuleFilter from '@/components/ModuleFilter.vue'
import PencilBar from '@/components/PencilBar.vue'
import ActionsDropdown from '@/components/ActionsDropdown.ContentTable.vue'
import ContentTableTools from '@/components/ContentTableTools.ContentTable.vue'
import CreativeProfileModal from '@/views/modals/RepoCreativeProfile.vue'
import WebLinks from '@/components/WebLinks.vue'
import store from '@/store'

import { mapActions, mapGetters } from 'vuex'

import Api from '@/helpers/Api.js'
import Utils from '@/helpers/Utils.js'

export default {
  name: 'CreativeRepository',
  components: {
    Navbar, FormField, ModuleHeader, ModuleFilter, PencilBar, ModuleFooter, ActionsDropdown, ContentTableTools, WebLinks
  },
  data () {
    return {
      staticBModal: true,
      loading: true,
      mobileFiltersExpanded: false,
      filterModuleData: {
        search: '',
        filterValues: {
          advertiser: 'All advertisers',
          vertical: 'All verticals',
          format: 'All formats'
        },
        filterDefaults: {
          advertiser: 'All advertisers',
          vertical: 'All verticals',
          format: 'All formats'
        },
        filterOptions: {
          advertiser: [],
          vertical: [],
          format: []
        }
      },
      passwordEntered: false,
      breadcrumsData: [
        {
          text: 'Creative Studio',
          href: '/creative-studio'
        },
        {
          text: 'Creatives',
          active: true
        }
      ],
      modalData: {
        currentComponent: null,
        itemId: null
      },
      list: {},
      lockdown: {
        password: '',
        fail: false
      },
      data: [],
      verticalsData: [],
      formatsData: [],
      ...mapGetters([
        'verticals',
        'creativeFormats'
      ]),
      pagination: {
        perPage: 30,
        currentPage: 1
      },
      tableActions: [
        {
          ico: 'cog',
          text: 'Creative Settings',
          roles: ['Admin', 'Marketing'],
          clickAction: this.openCreativeSettings
        },
        {
          ico: 'link',
          text: 'Copy Preview Link',
          clickAction: this.copyCreativePreviewUrlToClipboard
        },
        {
          ico: 'link',
          text: 'Copy Preview Link (Extended)',
          roles: ['Admin', 'Marketing', 'Sales'],
          clickAction: this.copyCreativePreviewExtendedUrlToClipboard
        },
        {
          ico: 'trash-alt',
          text: 'Remove Creative from List',
          roles: ['Admin', 'Marketing', 'Sales'],
          clickAction: this.removeCreativeFromList
        }
      ],
      contextualMenuExpanded: false
    }
  },
  methods: {
    ...mapActions([
      'fetchShowcaseCreatives',
      'registerModalOpened',
      'registerModalClosed'
    ]),
    copyCreativePreviewUrlToClipboard: function (creativeId) {
      const self = this
      Utils.copyToClipboard(window.origin + '/c/' + creativeId)
    },
    copyCreativePreviewExtendedUrlToClipboard: function (creativeId) {
      const self = this
      Utils.copyToClipboard(window.origin + '/ce/' + creativeId)
    },
    removeCreativeFromList: function (creativeId) {
      const self = this

      // turn the spinner on
      self.loading = true

      // fet creative data.
      const creative = _.find(self.data, { id: creativeId })
      const listId = creative.list_id

      if (confirm(`Are you sure you want remove this creative from this list: ${creative.name}?`)) {
        const creativeIndex = _.findIndex(self.data, { id: creative.id })
        self.data.splice(creativeIndex, 1)

        // create a creative with status removed so it is removed when saved
        const removedCreative = creative
        removedCreative.status = 'removed'

        // let's only send the creatives that are not removed
        self.list.creatives = _.filter(self.list.creatives, creative => {
          return creative.status != 'removed'
        })

        Api.updateList(self.list)
          .then(response => {
          // console.log('save-update-success', response);

            // turn the spinner off
            self.loading = false
          })
          .catch(error => {
            self.$log.error(error)

            // turne the spinner off
            self.loading = false
            alert(`There was an issue removing the creative from this list. Please try again. Error Code:${error}`)
          })
      } else {
        // turn the spinner off
        self.loading = false
      }
    },
    openCreativeSettings: function (creativeId) {
      const self = this

      // set the modal item id to the userId we want to update
      self.modalData.itemId = creativeId

      // register that we have just opened a modal
      self.registerModalOpened()

      // set the content for the modal to the user profile component
      self.modalData.currentComponent = CreativeProfileModal

      // show modal
      self.$refs.moduleModal.show()
    },
    modalSaveNew: function (creativeData) {
      const self = this

      // turn on loading spinner
      self.loading = true

      self.populate()

      // hide modal
      self.hideModal()
    },
    modalSaveUpdate: function (creativeData) {
      const self = this

      // turn on loading spinner
      self.loading = true

      self.populate()

      // hide modal
      self.hideModal()
    },
    hideModal: function () {
      const self = this

      // register that we have just closed a modal
      self.registerModalClosed()

      // show modal
      self.$refs.moduleModal.hide()
    },
    viewCreative: function (creative) {
      // open file url in a new window
      window.open('/c/' + creative.id, '_blank')
    },
    validatePassword: function () {
      const self = this

      // if the password typed in is correct
      if (self.lockdown.password === self.list.password) {
        self.passwordEntered = true

        // show modal
        self.$refs.lockdownModal.hide()
      } else {
        self.lockdown.fail = true
      }
    },
    populate: function () {
      const self = this

      // get data from API
      Api.getList(self.$route.params.id, true)
        .then(data => {
        // turn off loading spinner
          self.loading = false

          // if this list requires passkey
          if (data.visibility == 'Passkey' && self.passwordEntered === false) {
          // show lockdown modal
            self.$refs.lockdownModal.show()
          }

          self.list = data
          self.$log.info('list creatives:', data.creatives)

          // store the data in userData
          self.data = data.creatives

          // turn off loading spinner
          // self.loading = false;

          // add data to $store.state.showcaseCreatives.creatives because is empty, we're calling this route with window.open
          // self.$store.state.showcaseCreatives.creatives = data.creatives;
          // <-- if we have all the creatives features in the Api.getList we won't need to calle the Api.getCreativesList that load all the creatives and takes long

          Api.getCreativesList()
            .then(showcaseCreatives => {
              self.$store.state.showcaseCreatives.creatives = showcaseCreatives

              // turn off loading spinner
              self.loading = false
            })
        })
        .catch(error => {
          self.$log.error(error)
        })
    }
  },
  computed: {
    data2: function () {
      const self = this

      self.verticalsData = _.keyBy(self.verticals(), 'id')
      self.formatsData = _.keyBy(self.creativeFormats(), 'id')

      return _.forOwn(self.data, (creative, index) => {
        self.data[index].vertical = self.verticalsData[creative.vertical_id] ? self.verticalsData[creative.vertical_id].name : ''
        self.data[index].format = self.formatsData[creative.format_id] ? self.formatsData[creative.format_id].name : null
      })
    },
    filteredItems: function () {
      const self = this

      // get items that match the text on the search box
      const searchedItems = Utils.searchItemsByText(self.data2, self.filterModuleData.search)

      // Pick properties to filter by. the ones that are not using the default values
      const propsToFilterBy = _.pickBy(self.filterModuleData.filterValues, (filter, key) => {
        // get the filter. If filterModuleData.filterOptions is not yet populated, then, default to false
        const filterOption = _.get(self.filterModuleData.filterOptions, '[' + key + '][0]', false)

        // if filterOption is not false and the filter option is not the first option
        return filterOption && filter != filterOption
      })

      // filter data
      const filteredItems = Utils.getFilteredItems(searchedItems, propsToFilterBy)

      return filteredItems
    },
    paginatedItems: function () {
      const self = this

      // return paginated data
      return Utils.getPaginatedItems(self.filteredItems, self.pagination.currentPage, self.pagination.perPage)
    }
  },
  created () {
    const self = this

    self.populate()
  },
  watch: {
    data2: function () {
      const self = this

      // when data changes, populate the filter

      // Get keys Use keys from filterModuleData.filterValues to be used as the params to filter by.
      const paramsToFilterBy = _.keys(self.filterModuleData.filterValues)

      // Get filter options from the data.
      const filterOptions = Utils.pickFilterOptions(self.data, paramsToFilterBy)

      // Add filterModuleData.filterValue's default values as the first option for each filter
      _.forOwn(paramsToFilterBy, param => {
        filterOptions[param].unshift(self.filterModuleData.filterDefaults[param])
      })

      // Store filter options.
      self.filterModuleData.filterOptions = filterOptions
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/common.scss";

   .alert-heading-var{
    font-size: 12.5px;
    color: $secondary !important;
    border: 1px solid #d9d9d9 !important;
    padding: $spacing-unit;
    margin: 0 0 $spacing-unit;
  }

.formField {

  &.wrong &__field {

    input {
      border-color: $red1;
    }

  }

}

.contentTableFrame {
  display: block;

  &__pagination {

    @extend .no-select;

    $pagination-li-w: 3.5rem;
    $pagination-li-h: 3.5rem;

    text-align: left;
    display: inline-block;
    padding: 0;
    vertical-align: top;
    border-left: 1px solid $gray5;
    border-right: 1px solid $gray5;
    margin: 0px;

    li {
      width: $pagination-li-w;
      height: $pagination-li-h;
      line-height: $pagination-li-h;
      padding: 0;
      text-align: center;
      display: inline-block;
      border-top: 1px solid $gray5;
      border-bottom: 1px solid $gray5;

      & + li {
        border-left: 1px solid $gray5;
      }

      &.active {
        border-color: $pink1;
        background-color: $pink1;

         & + li {
           border-left-color: $pink1;
         }

         a {
          color: white;
          text-decoration: none;

          &:hover {
            background-color: $pink3;
          }
        }
      }

      & a,
      & span {
        color: $gray6;
        text-decoration: none;
        border: none;
        width: 100%;
        height: 100%;
        line-height: $pagination-li-h;
        display: inline-block;
        background: none;
      }

      a {
        &:hover {
          background: $gray5;
        }
      }
    }

  }
}
</style>
