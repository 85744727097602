import _ from 'lodash'
import axios from 'axios'

// var ax = axios.create({
//     // baseURL: process.env.VUE_ANALYTICS_BACKEND_ENDPOINT,
//     baseURL: '',
//     headers: {
//         'x-api-key': process.env.VUE_ANALYTICS_X_API_KEY
//     }
// });

const ApiAnalytics = function () {
  // Filters

  // Get filter data
  this.getFilterData = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_FILTER_DATA}`, {
          // axios.get(`/assets/filterData.json`,{
          // axios.get(`https://simpli-cpm-engagements-filterdata.default-tenant.app.iguazio.padsquad.com`,{
          params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Campaign Performance Monitoring - Awareness

  // Get top banner data
  this.getTopBanner = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_AWARENESS_TOP_BANNER}`,
          {
            // axios.get(`/assets/topBanner.json`,{
            // axios.get(`https://simpli-cpm-engagements-topbanner.default-tenant.app.iguazio.padsquad.com`,{
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get impressions
  this.getImpressions = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_AWARENESS_IMPRESSIONS}`,
          {
            // http://3.87.145.179:32230/assets/impressions.json?id=10111&startDate=1581455208065&endDate=1584043608065&placementId=&productId=&dataSource=Celtra&fileName=
            // axios.get(`/assets/impressions.json`, {
            // axios.get(`https://simpli-cpm-engagements-impressions.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get top five placement ads
  this.getTopFivePlacementAds = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_AWARENESS_TOP_FIVE_PLACEMENT_ADS}`,
          {
            // axios.get(`/assets/topFivePlacementAds.json`, {
            // axios.get(`https://simpli-cpm-awareness-topfivead.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get performance data by day
  this.getPerformanceDataByDay = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_AWARENESS_PERFORMANCE_DATA_BY_DAY}`,
          {
            // axios.get(`/assets/performanceDataByDay.json`, {
            // axios.get(`https://simpli-cpm-awareness-performance.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get viewable impression
  this.getViewableImpressions = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_AWARENESS_VIEWABLE_IMPRESSIONS}`,
          {
            // http://3.87.145.179:30008
            // axios.get(`/assets/viewability.json`, {
            // axios.get(`https://simpli-cpm-awareness-viewability.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get bottom banner data
  this.getBottomBanner = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_AWARENESS_BOTTOM_BANNER}`,
          {
            // http://3.87.145.179:31355
            // axios.get(`/assets/bottomBanner.json`, {
            // axios.get(`https://simpli-cpm-awareness-bottombanner.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get browser usage
  this.getBrowserUsage = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_AWARENESS_BROWSER_USAGE}`,
          {
            // axios.get(`/assets/browserUsage.json`, {
            // axios.get(`https://simpli-cpm-awareness-browserusage.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Campaign Performance Monitoring - Engagements

  // Get unit engagements
  this.getUnitEngagements = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_UNIT_ENGAGEMENTS}`,
          {
            // http://3.87.145.179:32377
            // axios.get(`/assets/unitEngagements.json`, {
            // axios.get(`https://simpli-cpm-engagements-unitengagements.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get placement effectiveness
  this.getPlacementEffectiveness = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_PLACEMENT_EFFECTIVENESS}`,
          {
            // axios.get(`/assets/placementEffectiveness.json`, {
            // axios.get(`https://simpli-cpm-engagements-placmnteffectiveness.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get top five placements
  this.getTopFivePlacements = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_TOP_FIVE_PLACEMENTS}`,
          {
            // axios.get(`/assets/topFivePlacements.json`, {
            // axios.get(`https://simpli-cpm-engagements-top5placementcreative.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get custom event occurrences
  this.getCustomEventOccurrences = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_CUSTOM_EVENT_OCCURRENCES}`,
          {
            // axios.get(`/assets/customEventOccurrences.json`, {
            // axios.get(`https://simpli-cpm-engagements-topoccurence.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get custom event occurrences meta data
  this.getCustomEventOccurrencesMetaData = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_CUSTOM_EVENT_OCCURRENCES_META_DATA}`,
          {
            // http://3.87.145.179:31579
            // axios.get(`/assets/customEventOccurrencesMetaData.json`, {
            // axios.get(`https://simpli-cpm-engagements-occurenceemetadata.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Campaign Performance Monitoring - Engagements - Inline Video Performance

  // Get inline video starts completes
  this.getQuartilePerformance = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_INLINE_VIDEO_STARTS_COMPLETES}`,
          {
            // http://3.87.145.179:30617/?id=10111&startDate=1581455208065&endDate=1584043608065&placementId=&productId=&dataSource=Source+One&fileName=
            // axios.get(`/assets/quartilePerformance.json`, {
            // axios.get(`https://simpli-cpm-engagements-quartileperform.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get inline video completion rate
  this.getInlineVideoCompletionRate = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_INLINE_VIDEO_COMPLETION_RATE}`,
          {
            // axios.get(`/assets/inlineVideoCompletionRate.json`, {
            // axios.get(`https://simpli-cpm-engagements-videocompletion.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get inline video performance meta data
  this.getInlineVideoPerformanceMetaData = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CPM_ENGAGEMENTS_INLINE_VIDEO_PERFORMANCE_META_DATA}`,
          {
            // axios.get(`/assets/inlineVideoPerformanceMetaData.json`, {
            // axios.get('https://simpli-cpm-engagements-videoperformance.default-tenant.app.iguazio.padsquad.com', {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Live Analytics

  // Get live analytics top banner
  this.getLiveAnalyticsTopBanner = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_TOP_BANNER}`,
          {
            // axios.get(`/assets/liveAnalyticsTopBanner.json`, {
            // axios.get(`https://simpli-liveanalytics-topbanner.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get live analytics top banner
  this.getLiveAnalyticsCampaignData = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA}`,
          {
            // axios.get(`/assets/liveAnalyticsCampaignData.json`, {
            // axios.get(`https://simpli-liveanalytics-campaigndata.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get live analytics top domains data
  this.getLiveAnalyticsTopDomains = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_TOP_DOMAINS}`,
          {
            // axios.get(`/assets/topDomains.json`, {
            // axios.get(`https://simpli-liveanalytics-topdomains.default-tenant.app.iguazio.padsquad.com`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get active users data
  this.getActiveUsers = (campaignId, startDate, endDate, placementId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA_ACTIVE_USERS}`,
          {
            params: {
              startDate,
              endDate,
              placementId
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get analyzed impressions data
  this.getAnalyzedImpressionsData = (
    campaignId,
    startDate,
    endDate,
    placementId
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA_ANALYZED_IMPRESSIONS}`,
          {
            params: {
              startDate,
              endDate,
              placementId
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get friendly iframe impressions data
  this.getFriendlyIframeImpressions = (
    campaignId,
    startDate,
    endDate,
    placementId
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA_FRIENDLY_IFRAME_IMPRESSIONS}`,
          {
            params: {
              startDate,
              endDate,
              placementId
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get iab friendly iframe impressions data
  this.getIabFriendlyIframeImpressions = (
    campaignId,
    startDate,
    endDate,
    placementId
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA_IAB_FRIENDLY_IFRAME_IMPRESSIONS}`,
          {
            params: {
              startDate,
              endDate,
              placementId
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get iab viewable impressions data
  this.getIabViewableImpressions = (
    campaignId,
    startDate,
    endDate,
    placementId
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA_IAB_VIEWABLE_IMPRESSIONS}`,
          {
            params: {
              startDate,
              endDate,
              placementId
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get mobile friendly impressions data
  this.getMobileFriendlyImpressions = (
    campaignId,
    startDate,
    endDate,
    placementId
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA_MOBILE_FRIENDLY_IMPRESSIONS}`,
          {
            params: {
              startDate,
              endDate,
              placementId
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get secure impressions data
  this.getSecureImpressions = (campaignId, startDate, endDate, placementId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_CAMPAIGN_DATA_SECURE_IMPRESSIONS}`,
          {
            params: {
              startDate,
              endDate,
              placementId
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get secure impressions data
  this.getAdProductDatabase = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_AD_PRODUCT_DATABASE}`, {
          params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get secure impressions data
  this.getBlacklistWhitelist = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_LIVE_ANALYTICS_BLACKLIST_WHITELIST}`,
          {
            // axios.get(`http://34.228.56.79:30570`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  this.getPerformanceFilters = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_AD_PRODUCT_DATABASE_FILTERS} `,
          {
            // axios.get(`http://34.228.56.79:30570`, {
            params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get dash board data: get anaytics last 3 weeks job
  this.getDashBoardData = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_JOB_DATA_LOADING_DASHBOARD_DATA_BY_STATUS}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * new Job data adding
   * @param {object} data - Job Data to be inserted
   */
  this.createJob = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env
            .VUE_APP_ENDPOINT_ANALYTICS_JOB_DATA_LOADING_DASHBOARD_DATA_ADD,
          data
        )
        .then((response) => {
          // console.log(response)
          resolve(response.data.data)
        })
        .catch((error) => {
          // console.log(error)
          reject(error)
        })
    })
  }

  /**
   * new Job data adding
   * @param {object} data - Job Data to be updated
   */
  this.updateJob = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env
            .VUE_APP_ENDPOINT_ANALYTICS_JOB_DATA_LOADING_DASHBOARD_DATA_EDIT,
          data
        )
        .then((response) => {
          // console.log(response)
          resolve(response.data.data)
        })
        .catch((error) => {
          // console.log(error)
          reject(error)
        })
    })
  }

  // Get Metrics and Dimensions
  this.getMetricsAndDimensions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_METRICS_AND_DIMENSIONS}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get Metrics
  this.getMetrics = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_METRICS}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get Dimensions
  this.getDimensions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_DIMENSIONS}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get Templates and Saved Reports
  this.getTemplatesAndSavedReports = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_SAVED_REPORTS}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get Xandr Placement Logs
  this.getXandrPlacementLogs = (queryString = '') => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_GET_XANDR_PLACEMENT_LOGS}?${queryString}`
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Create Report
  this.createSavedReport = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_REPORT_CREATE}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Create Xandr Placement Log
  this.createXandrPlacementLog = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(
        `${process.env.VUE_APP_ENDPOINT_ANALYTICS_CREATE_XANDR_PLACEMENT_LOGS}`, data)
        .then ((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Edit Report
  this.editSavedReport = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_REPORT_UPDATE}`, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Delete Report
  this.deleteSavedReport = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_ENDPOINT_REPORT_DELETE}${id}`)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // Get Data Availability
  this.getDataAvailabilityReport = (campaignId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_DATA_AVAILABILITY_GET}?selectedCampaignId=${campaignId}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  /**
   * returns job data by id
   * @param {string} jobId -
   */
  this.getJobById = (jobId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_ENDPOINT_ANALYTICS_JOB_DATA_LOADING_DASHBOARD_DATA_GET_BY_ID}${jobId}`
        )
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  /**
   * uploads zip file for creative
   * returns the url at which zip file was saved
   */
  this.uploadDspReportingCSV = (data) => {
    return new Promise((resolve, reject) => {
      const headers = { 'content-type': 'text/csv' }
      axios
        .post(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_DSP_REPORTING}`, data, {
          headers
        })
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export default new ApiAnalytics()
