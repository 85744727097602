<template>
  <div
    v-if="!doNotUsePreloader && appStatus !== 'ready' && !fileDownloading && !inModal"
    class="loader"
  >
    <font-awesome-icon
      icon="spinner"
      class="spinner"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Preloader',
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'appStatus',
      'appModalOpen',
      'fileDownloading',
      'doNotUsePreloader'
    ])
  },
  created() {
    this.$log.info(process.env.VUE_APP_ENV)
  }
}
</script>
