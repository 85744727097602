import actions from './actions'
import * as CONSTANTS from './constants'

export default {
  // namespaced:true,
  state: {
    user: null, // AWS user object
    userInfo: { // This is the data distribution of the userInfo from AWS
      id: null,
      attributes: {
        email: null,
        email_verified: false
      }
    },
    userInSession: null,
    userData: { aws_user_id: '' }, // data to be send to DB Post Confirmation
    accounts_type: [{ id: 1, text: 'PadSquad' }],
    roles_type: [
      { id: 1, text: 'Admin' },
      { id: 2, text: 'Marketing' },
      { id: 3, text: 'Sales' },
      { id: 4, text: 'Reporting Only' }],
    message: '',
    wasSent: false,
    gotError: false,
    confirm: false,
    forgotPasswordSubmit: false,
    sendDataToDB: false,
    loading: false,
    token: null,
    tokenExpires: null,
    formToShow: 'SignIn',
    authenticated: false
  },
  getters: {
    loading: state => state.loading,
    wasSent: state => state.wasSent,
    gotError: state => state.gotError,
    user: state => state.user,
    userInSession: getters => getters.userInSession,
    fullname: (state) => {
      return `${state.userInSession.name} ${state.userInSession.last_name}`
    },
    userInfo: state => state.userInfo,
    userData: state => state.userData,
    confirm: state => state.confirm,
    message: state => state.message,
    authenticated: state => state.authenticated,
    forgotPasswordSubmit: state => state.forgotPasswordSubmit,
    sendDataToDB: state => state.sendDataToDB,
    accounts_type: state => state.accounts_type,
    roles_type: state => state.roles_type,
    accountByName: getters => name => {
      return getters.accounts_type.find(item => item.text === name)
    },
    roleByName: getters => name => {
      return getters.roles_type.find(item => item.text === name)
    },
    token: state => state.token,
    tokenExpires: state => state.tokenExpires,
    formToShow: state => state.formToShow
  },
  mutations: {
    [CONSTANTS.SET_MESSAGE] (state, payload) {
      state.message = payload.text
      state.wasSent = payload.wasSent
      state.gotError = payload.gotError
    },
    [CONSTANTS.SET_LOADING] (state, payload) {
      state.loading = payload
    },
    [CONSTANTS.FORGOT_PASSWORD_SUBMIT] (state, payload) {
      state.forgotPasswordSubmit = payload
    },
    [CONSTANTS.SET_USER] (state, payload) {
      state.user = payload.user || null
      state.userInfo = payload.userInfo || null
      state.userData.aws_user_id = (state.userInfo != null) ? state.userInfo.id : null
    },
    [CONSTANTS.SET_USER_IN_SESSION] (state, payload) {
      state.userInSession = payload || null
    },
    [CONSTANTS.CONFIRM_SIGNUP] (state, payload) {
      state.confirm = payload
    },
    [CONSTANTS.SET_USER_DATA] (state, payload) {
      const account = state.accounts_type.filter(item => item.text == payload.account_type)
      const role = state.roles_type.filter(item => item.text == payload.role_type)
      // console.log('payload id', account, account.id, account[0].id)

      const userDataComplete = {
        aws_user_id: payload.aws_user_id || '',
        email: payload.email,
        name: payload.name,
        last_name: payload.last_name,
        phone_number: payload.phone_number,
        account_type_id: account[0].id,
        role_id: role[0].id
      }

      state.userData = userDataComplete
    },
    [CONSTANTS.SET_SEND_DATA_TO_DB] (state, payload) {
      state.sendDataToDB = payload
    },
    [CONSTANTS.SET_JWT_TOKEN] (state, payload) {
      state.token = payload.token
      state.tokenExpires = payload.expires
    },
    [CONSTANTS.SET_FORM_TO_SHOW] (state, payload) {
      state.formToShow = payload
    },
    [CONSTANTS.SET_AUTHENTICATED] (state, payload) {
      state.authenticated = payload
    }
  },
  actions,
  modules: {

  }
}
