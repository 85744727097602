<template v-bind:style="color">
    <header v-bind:class="onGraph ? '' : 'col'">
        <div class="title" v-bind:style="onGraph ? 'margin-left: 20px; text-transform: capitalize; font-size: 18px;' : ''">{{title}} <div class="tooltip"><font-awesome-icon style="margin-left: 15px; color: #888888; font-size: 13px;" v-if="onGraph" icon="info-circle"/><span class="tooltiptext" v-html="info">{{info}}</span></div></div>
        <div class="value" v-bind:style="onGraph ? 'display: inline-block; padding-right: 15px; margin-left: 20px;' : ''">{{value}}</div>
        <div class="subValue" v-bind:style="onGraph ? 'display: inline-block; margin-left: 20px;' : ''">{{subValue}} <font-awesome-icon v-if="change != undefined" v-bind:class="change == 'negative' ? 'negative' : 'positive'" v-bind:icon="change == 'negative' ? 'caret-down' : 'caret-up'"/></div>
    </header>
</template>

<script>
export default {
  name: 'MetaData',
  props: {
    title: String,
    value: String,
    subValue: String,
    change: String,
    color: String,
    onGraph: Boolean,
    info: String
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/common.scss";

* {
    font-family: 'Montserrat';
    text-align: left;
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: #5C5C5C;
    text-transform: uppercase;
}

.value {
    font-size: 40px;
    font-weight: bold;
    color: #11433C;
}

.subValue {
    font-size: 14px;
    font-weight: 600;
    color: #646464;
}

.positive {
    color: #3DAD39;
}

.negative {
    color: #D56161;
}

.tooltip {
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  /*width: 100%;*/
  background-color: #5C5C5C;
  color: #fff;
  text-align: left;
  border-radius: 6px;
    font-size: 14px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  padding: 5px 10px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
