<script setup>
import { computed } from 'vue'

const emit = defineEmits(['refresh-creative', 'open-drawer', 'select-device'])
const props = defineProps({
  creativeData: {
    type: Object,
    required: true
  },
  isDrawerOpen: {
    type: Boolean,
    required: true
  },
  previewSettings: {
    type: Object,
    required: true
  }
})

const inDisplayOptions = (val) => {
  if (!props.creativeData.displayOptions) return false
  return props.creativeData.displayOptions.includes(val)
}

const deviceIcons = computed(() => {
  let icons = []

  if (inDisplayOptions('Desktop')) icons.push({ name: 'desktop', val: 'desktop' })
  if (inDisplayOptions('Tablet Portrait') || inDisplayOptions('Tablet Landscape')) {
    icons.push({ name: 'tablet', val: 'tablet-alt' })
  }
  if (inDisplayOptions('Mobile Portrait') || inDisplayOptions('Mobile Landscape')) {
    icons.push({ name: 'mobile', val: 'mobile-alt' })
  }
  
  return icons
})

const otherIcons = computed(() => {
  return [
    {
      name: 'undo-alt',
      onClick: () => emit('refresh-creative'),
      style: 'transform: rotateY(180deg);'
    },
    {
      name: props.isDrawerOpen ? 'times' : 'ellipsis-h',
      onClick: () => emit('open-drawer'),
      style: 'color: #3BBBA0;'
    }
  ]
})
</script>

<template>
  <header class="preview-header d-flex w-100">
    <!-- ps logo -->
    <router-link to="/">
      <img class="px-5 cursor-pointer" src="@/assets/ps_logo_pink.svg" />
    </router-link>

    <div class="separator"/>

    <!-- creative name -->
    <div class="mx-4 text-white flex-grow-1 d-flex align-items-center">
      {{ props.creativeData.name }}
    </div>

    <!-- device icons -->
    <div class="d-flex justify-content-center" style="margin: 0 3rem;">
      <button
        v-for="(icon, idx) in deviceIcons"
        :key="idx"
        class="icon-button mx-4 cursor-pointer"
        @click="emit('select-device', icon.name)"
        :style="`color: ${icon.name === previewSettings.device ? 'white' : '#5B5A75'}`"
      >
        <font-awesome-icon
          :icon="icon.val"
          style="height: 16px; width: 16px;"
        />
      </button>
    </div>

    <div class="separator" />

    <!-- other icons -->
    <div class="d-flex justify-content-center" style="margin: 0 3rem;">
      <button
        v-for="(icon, idx) in otherIcons"
        :key="idx"
        class="icon-button mx-4 cursor-pointer"
        @click="icon.onClick"
        :style="icon.style"
      >
        <font-awesome-icon
          :icon="icon.name"
          style="height: 16px; width: 16px;"
        />
      </button>
    </div>
  </header>
</template>

<style scoped lang="scss">

@import "../../scss/common.scss";
.preview-header {
  @extend .container-fluid;
  text-align: left;
  color: white;
  padding: $spacing-unit 0;
  border-bottom: 5px solid #3BBBA0;
  background: #14123A;
  overflow-x: hidden;
}
.separator {
  border-left: 2px solid #5B5A75;
}

.icon-button {
  border: none !important;
  background-color: transparent;
  color: white;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
