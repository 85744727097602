<template>
  <section class="sModal">
    <div
      class="close-button"
      @click="cancel()"
    >
      <font-awesome-icon icon="times" />
    </div>
    <Preloader :in-modal="true"></Preloader>
    <header class="sModal__header sModal__header--blue">
      Generate Analytics Report
    </header>
    <div
      v-if="hasIncompatibleMetrics"
      class="alert-box"
    >
      <span
        >{{ incompatibleDimensionsMetrics[0].metric }},
        {{ incompatibleDimensionsMetrics[1].metric }}</span
      >
      metrics and
      <span
        >{{ incompatibleDimensionsMetrics[0].dimension }},
        {{ incompatibleDimensionsMetrics[1].dimension }}</span
      >
      dimensions are not compatible.
    </div>

    <div class="sModal__body">
      <div class="link-box">
        <button
          class="button"
          @click="openMetDimDocument"
        >
          <font-awesome-icon
            icon="info"
            class="i"
          />See Metrics & Dimensions documentation
        </button>
      </div>
      <FormField
        label="Report Type"
        for-id="airtory-id"
      >
        <div class="ratioInputs">
          <div class="df mr1">
            <input
              id="new_report"
              v-model="isSavedReport"
              type="radio"
              name="newReport"
              :value="false"
            />
            New Report
          </div>
          <div class="df">
            <input
              id="saved_report"
              v-model="isSavedReport"
              type="radio"
              name="newReport"
              :value="true"
            />
            Saved Report
          </div>
        </div>
      </FormField>

      <small
        v-if="isSavedReport"
        class="label"
        >Templates & Saved Reports</small
      >
      <div
        v-if="isSavedReport"
        class="py-3"
      >
        <multiselect
          v-model="selectedTemplateReport"
          :multiple="false"
          placeholder="Select a template or saved report"
          :show-labels="false"
          :allow-empty="false"
          :options="listReportsFiltered.map((item) => item.id)"
          :custom-label="
            (opt) => {
              return (
                listReportsFiltered.find((x) => x.id == opt).id +
                ' - ' +
                listReportsFiltered.find((x) => x.id == opt).name
              )
            }
          "
          @input="onSelectTemplate($event)"
        ></multiselect>
      </div>

      <FormField
        v-if="
          !isSavedReport || (isSavedReport && selectedTemplateReport !== null)
        "
        label="Report Name"
        type="text"
        class="moduleFilter__filter"
      >
        <input
          id="report-name"
          v-model="newReportName"
          autocomplete="off"
          type="text"
        />
        <small v-if="isSavedReport && selectedTemplateReport !== null"
          >You can modify the name for this saved report.</small
        >
      </FormField>

      <small class="label">Date Range</small>
      <div class="py-3">
        <multiselect
          v-model="selectedDateRange"
          :options="dateRange"
          :allow-empty="false"
          :multiple="false"
          :close-on-select="true"
          placeholder="Select a date range"
          :show-labels="false"
          :searchable="false"
          @select="onSelectDateRange($event)"
        >
        </multiselect>
      </div>

      <div
        v-if="selectedDateRange === 'Custom Range'"
        class="py-3 tac"
      >
        <date-range-picker
          ref="picker"
          v-model="dateRangeData"
          opens="inline"
          :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
          :min-date="null"
          :max-date="null"
          :single-date-picker="false"
          :time-picker="false"
          :time-picker24-hour="false"
          :show-week-numbers="true"
          :show-dropdowns="true"
          :ranges="false"
          :auto-apply="true"
          @select="updateValues"
        >
        </date-range-picker>

        <small class="dates"
          >{{ dateRangeData.startDate | date }} <span>to</span>
          {{ dateRangeData.endDate | date }}</small
        >
      </div>
      <small class="label">Campaign Source</small>
      <div class="py-3">
        <multiselect
          v-model="selectedCampaignSource"
          :multiple="false"
          placeholder="Select a campaign source"
          :show-labels="false"
          :close-on-select="true"
          :options="selectCampaignSourceOptions"
          @input="selectedCampaignsIds = []"
        ></multiselect>
      </div>

      <small class="label">Campaigns</small>
      <div class="py-3">
        <multiselect
          v-model="selectedCampaignsIds"
          :multiple="true"
          placeholder="Select a campaign"
          :show-labels="false"
          :close-on-select="false"
          :options-limit="50"
          :options="updatedOptionsList.map((type) => type.id)"
          :internal-search="false"
          :custom-label="
            (opt) => {
              const option = listCampaignsFiltered.find((x) => x.id == opt)
              return option ? option.id + ' - ' + option.name : ''
            }
          "
          @input="onSelectCampaignChange($event)"
          @search-change="updateSearch($event)"
          @open="onSelectCampaignOpen($event)"
          @close="onSelectCampaignClose($event)"
        ></multiselect>
      </div>
      <small
        v-if="selectedCampaignsIds.length > 0"
        class="label"
        >Placements</small
      >
      <div
        v-if="
          selectedCampaignsIds.length > 0 &&
          selectedCampaignsPlacements.length !== 0
        "
        class="py-3"
      >
        <multiselect
          v-model="selectedPlacements"
          :multiple="true"
          placeholder="Select one or more placement"
          :show-labels="false"
          :close-on-select="false"
          :options="selectedCampaignsPlacements.map((type) => type.id)"
          :custom-label="
            (opt) => {
              return (
                selectedCampaignsPlacements.find((x) => x.id == opt).id +
                ' - ' +
                selectedCampaignsPlacements.find((x) => x.id == opt).name
              )
            }
          "
          @input="onSelectPlacements($event)"
        ></multiselect>
      </div>
      <div
        v-if="
          selectedCampaignsIds.length > 0 &&
          selectedCampaignsPlacements.length === 0
        "
        class="py-3"
      >
        <small class="error">This campaign has no placement.</small>
      </div>

      <small
        v-if="selectedCampaignsIds.length > 0"
        class="label"
        >Creatives</small
      >
      <div
        v-if="
          selectedCampaignsIds.length > 0 &&
          selectedCampaignsCreatives.length !== 0
        "
        class="py-3"
      >
        <multiselect
          v-model="selectedCreatives"
          :multiple="true"
          placeholder="Select one or more creative"
          :show-labels="false"
          :close-on-select="false"
          :options="selectedCampaignsCreatives.map((type) => type.id)"
          :custom-label="
            (opt) => {
              return (
                selectedCampaignsCreatives.find((x) => x.id == opt).id +
                ' - ' +
                selectedCampaignsCreatives.find((x) => x.id == opt).name
              )
            }
          "
          @input="onSelectCreatives($event)"
        ></multiselect>
      </div>
      <div
        v-if="
          selectedCampaignsIds.length > 0 &&
          selectedCampaignsCreatives.length === 0
        "
        class="py-3"
      >
        <small class="error">This campaign has no creative.</small>
      </div>

      <small class="label">Metrics</small>
      <div class="py-3">
        <multiselect
          id="metrics"
          v-model="selectedGroupMetValues"
          :options="groupMetrics"
          :multiple="true"
          :close-on-select="false"
          group-values="data"
          group-label="vendor"
          :group-select="true"
          :show-labels="false"
          placeholder="Select a metric"
          track-by="name"
          label="name"
          @input="onSelectedMetrics($event)"
        >
          <template
            id="metrics"
            #option="props"
          >
            <div>
              <button class="option__title">
                {{ vendorLabel(props.option) }}
              </button>
              <span>
                {{ vendorLabelDisplay(props.option, 'All metrics') }}</span
              >
            </div>
          </template>
        </multiselect>
        <div class="checkbox-flex">
          <input
            id="rate"
            v-model="showRatesAsDecimals"
            type="checkbox"
          />
          <label for="rate">Show rates as decimals.</label>
        </div>
      </div>
      <div id="dimensions">
        <small class="label">Dimensions</small>
        <div class="py-3">
          <multiselect
            id="dimensions"
            v-model="selectedGroupDimValues"
            :options="groupDimensions"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select a dimension"
            track-by="name"
            :show-labels="false"
            group-values="data"
            group-label="vendor"
            :group-select="true"
            label="name"
            @input="onSelectedDimensions($event)"
          >
            <template #option="props">
              <div>
                <button class="option__title">
                  {{ vendorLabel(props.option) }}
                </button>
                <span>
                  {{ vendorLabelDisplay(props.option, 'All dimensions') }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <!--      todo: update sorting -->
      <!-- <div id="sortdimensions">
        <small class="label">Sort by</small>
               <div class="py-3">
                <multiselect id="sortdimensions"
                v-model="selectedGroupSortValues"
                :options="sortvalues"
                :multiple="false"
                :closeOnSelect="true"
                @select="onSelectedSortDimensions($event)"
                placeholder="Select a dimension to sort by"
                track-by="name"
                :show-labels="false"
                group-values="data"
                 group-label="vendor"
                 :group-select="false"
                 label="name"
                >
                    <template slot="option" slot-scope="props">
                  <div> <button  class="option__title">{{vendorLabel(props.option,false)}}</button> <span>  {{ vendorLabelDisplay(props.option,'Dimensions')}}</span></div>
                  </template>
                </multiselect>
                 <small>Sorted by Date ascending by default (Oldest to Newest).</small>
            </div>
            </div> -->
      <!--     <div id="orderby">
        <small class="label">ORDER BY.</small>
            <div class="py-3">
                <multiselect
                    v-model="selectedOrderBy"
                    :options="SortByList"
                    :allow-empty="false"
                    :multiple="false"
                    :close-on-select="true"
                    placeholder="Select"
                    :show-labels="false"
                    :searchable = 'false'
                    >
                 </multiselect>
            </div>
             </div> -->
      <FormField
        label="SEND TO"
        class="mt5"
      >
        <div class="listBox listBoxReport">
          <ul>
            <li class="fullWidth">
              <div v-if="emails.length > 0">
                <div
                  v-for="(item, i) in emails"
                  :key="'email_' + i"
                >
                  <div
                    :class="[
                      isThisFirstIndexEmail(i)
                        ? 'listBoxOneField'
                        : 'listBoxOneField_labelArrowUp',
                      { noBorderBottom: emails.length > 1 }
                    ]"
                  >
                    <div class="formFieldGroup listBox__label noBorderBottom">
                      <div class="formFieldGroup__fields">
                        <FormField
                          :for-id="'email_' + i"
                          :tag-left="isThisFirstIndexEmail(i) ? 'Email' : '.'"
                          :class="{ labelArrowUP: !isThisFirstIndexEmail(i) }"
                        >
                          <span
                            v-if="!isThisFirstIndexEmail(i)"
                            class="arrowUp flipX"
                            ><font-awesome-icon
                              icon="level-up-alt"
                              class="i"
                          /></span>
                          <input
                            :id="'email_' + i"
                            v-model="item.value"
                            autocomplete="off"
                            type="text"
                            :required="
                              !isThisFirstIndexEmail(i) ||
                              (item.value !== '' &&
                                !passedValidation(item.value))
                            "
                            class="input-email"
                            :class="{
                              notPassedValidation:
                                item.value !== '' &&
                                !passedValidation(item.value)
                            }"
                            :disabled="isThisFirstIndexEmail(i)"
                          />
                        </FormField>
                      </div>
                    </div>

                    <div
                      class="edit-button"
                      :class="{
                        isFirstWithDescription: isThisFirstIndexEmail(i)
                      }"
                    >
                      <button @click.prevent="">
                        <font-awesome-icon
                          icon="ellipsis-h"
                          class="i"
                        />
                      </button>
                    </div>

                    <div
                      class="buttons-row"
                      :class="{
                        isFirstWithDescription: isThisFirstIndexEmail(i)
                      }"
                    >
                      <button
                        v-if="i > 0"
                        @click.prevent="removeEmail(i)"
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                          class="i"
                        />
                      </button>
                      <button
                        v-if="
                          passedValidation(item.value) &&
                          isThisLastIndexEmail(i)
                        "
                        class="button--positive"
                        @click.prevent="addEmail(i + 1)"
                      >
                        <font-awesome-icon
                          icon="plus-circle"
                          class="i"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <small
          >Emails on this list will receive this report every time it is
          executed.</small
        >
      </FormField>

      <small class="label small-block">EMAIL OPTIONS</small>
      <div class="only-email">
        <input
          id="onlySend"
          v-model="onlyEmailReport"
          type="checkbox"
        />
        <label for="onlySend">Only email this report. Do not download.</label>
      </div>

      <small class="label small-block">SCHEDULE</small>
      <div
        v-if="
          isSavedReport &&
          selectedTemplateReport !== null &&
          selectedDateRange !== 'Custom Range'
        "
        class="schedule-select"
        :class="{ 'justify-start': schedule.frequencySelected === 'Every Day' }"
      >
        <div class="pair">
          <span>Run report</span>
          <div>
            <multiselect
              v-model="schedule.frequencySelected"
              :options="frequencies"
              :multiple="false"
              placeholder=""
              :allow-empty="false"
              :show-labels="false"
              @input="onSelectedScheduleItem($event)"
            ></multiselect>
          </div>
        </div>
        <div
          v-if="schedule.frequencySelected !== 'Every Day'"
          class="pair"
        >
          <span>On</span>
          <div>
            <multiselect
              v-model="schedule.daySelected"
              :options="days"
              :multiple="false"
              placeholder="Day"
              :show-labels="false"
              @input="onSelectedScheduleItem($event)"
            ></multiselect>
          </div>
        </div>
        <div class="pair">
          <span>Around</span>
          <div>
            <multiselect
              v-model="schedule.hourSelected"
              :options="hours.map((item) => item.hour)"
              :multiple="false"
              placeholder="Hour"
              :show-labels="false"
              @input="onSelectedScheduleItem($event)"
            ></multiselect>
          </div>
        </div>
      </div>

      <small
        v-if="
          selectedTemplateReport !== null &&
          isSavedReport &&
          selectedDateRange === 'Custom Range'
        "
        class="error"
        >Reports with fixed dates do not support schedules.</small
      >
      <div
        v-if="
          !isSavedReport || (isSavedReport && selectedTemplateReport === null)
        "
      >
        <small class="error"
          >Unsaved reports do not support schedules. Save this report to enable
          this feature.</small
        >
      </div>
      <div style="margin-top: 15px">
        <small class="label small-block">Legend</small>
        <div class="only-email">
          <ul>
            <li>SHRD: Shared</li>
            <li>AIR: Airtory</li>
            <li>CELT: Celtra</li>
            <li>DCM: DoubleClick Campaign Manager</li>
          </ul>
          <ul>
            <li>DV: DoubleVerify</li>
            <li>IAS: IntegralAds</li>
            <li>MOAT: MOAT</li>
          </ul>
        </div>
      </div>
    </div>
    <footer class="sModal__footer">
      <div class="sModal__footer__left">
        <button
          class="cancelButton"
          @click.prevent="cancel"
        >
          <font-awesome-icon
            icon="ban"
            class="i"
          />Cancel
        </button>
      </div>

      <div class="sModal__footer__right">
        <button
          v-if="!isSavedReport"
          class="saveButton"
          :disabled="!isRequiredDataFilledOutForSavedReport"
          @click="saveNewReport"
        >
          <font-awesome-icon
            icon="check"
            class="i"
          />Save Report
        </button>
        <button
          v-if="isSavedReport && selectedTemplateReport !== null"
          class="saveButton"
          :disabled="!isRequiredDataFilledOutForSavedReport"
          @click="updateReport"
        >
          <font-awesome-icon
            icon="check"
            class="i"
          />Update Report
        </button>
        <button
          class="saveButton"
          :disabled="!isRequiredDataFilledOut"
          @click="download"
        >
          <font-awesome-icon
            icon="play-circle"
            class="i"
          />Run
        </button>
      </div>
    </footer>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Utils from '@/helpers/Utils.js'
import Preloader from '@/components/Preloader.vue'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import DateRangePicker from 'vue2-daterange-picker'
// you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'GenerateCSV',
  components: {
    Preloader,
    Multiselect,
    DateRangePicker,
    FormField
  },
  props: {
    campaignId: null,
    campaignName: null,
    savedReportId: null
  },
  data() {
    return {
      campaignSearchValue: null,
      groupMetrics: [],
      selectedGroupMetValues: [],
      groupDimensions: [],
      groupSortDimensions: [],
      selectedGroupDimValues: [],
      selectedGroupSortValues: [],
      selectednewSortValues: '',
      dateRange: [
        'Today',
        'Yesterday',
        'Last 7 Days',
        'Last 14 Days',
        'Last 30 Days',
        'Campaign start date to today',
        'Custom Range'
      ],
      selectedDateRange: 'Today',
      SortByList: ['ASC', 'DESC'],
      selectedOrderBy: 'ASC',
      selectedCampaignsIds: [], // []
      selectedCampaignSource: null,
      selectCampaignSourceOptions: ['PadSquad', 'Airtory + 3P'],
      selectedCampaignsData: null, // [] for one campaign
      selectedCampaignsPlacements: [],
      selectedCampaignsCreatives: [],
      selectedPlacements: [],
      selectedCreatives: [],
      selectedMetrics: [],
      metrics: [],
      hasMetricsOcurrences: false,
      hasMetricsVideos: false,
      showRatesAsDecimals: false,
      selectedDimensions: [],
      selectedSortsDimensions: [],
      incompatibleDimensionsMetrics: [
        {
          metric: 'AIR Engagement Rate',
          dimension: 'AIR Engagement Reporting Label'
        },
        {
          metric: 'CELT Unit engagement rate',
          dimension: 'CELT Engagement Reporting label'
        }
      ],
      hasIncompatibleMetrics: false,
      dimensions: [],
      selectedSystems: ['Airtory'],
      systems: [
        'Simpli',
        'Airtory',
        'Celtra',
        '3P Verification',
        '3P Measurement'
      ],
      dateRangeData: {
        startDate: new Date(), // today
        endDate: new Date()
      },
      isSavedReport: false,
      newReportName: null,
      // Templates and Saved Reports
      listTemplatesReports: [],
      selectedTemplateReport: null,
      selectedTemplateReportData: null,
      // one or multiple emails
      // creator of the report will always receive the email
      emails: [],
      // prevent the csv to be downloaded
      onlyEmailReport: false,
      showRatesAsDecimals: false,
      // Schedule
      // Reports with fixed date do not support this feature.
      // Unsaved Reports do not support this feature.
      frequencies: [
        'No Schedule',
        'Every Day',
        'Every Week',
        'Every 2 Weeks',
        'Every Month'
      ],
      days: [
        'Mondays',
        'Tuesdays',
        'Wednesdays',
        'Thursdays',
        'Fridays',
        'Saturdays',
        'Sundays'
      ],
      hours: [
        {
          hour: '6AM ET',
          utc: '10AM UTC',
          utc0to23: 10
        },
        {
          hour: '7AM ET',
          utc: '11AM UTC',
          utc0to23: 11
        },
        {
          hour: '8AM ET',
          utc: '12PM UTC',
          utc0to23: 12
        },
        {
          hour: '9AM ET', //
          utc: '1PM UTC',
          utc0to23: 13
        },
        {
          hour: '10AM ET',
          utc: '2PM UTC',
          utc0to23: 14
        },
        {
          hour: '11AM ET',
          utc: '3PM UTC',
          utc0to23: 15
        },
        {
          hour: '12PM ET', //
          utc: '4PM UTC',
          utc0to23: 16
        },
        {
          hour: '1PM ET',
          utc: '5PM UTC',
          utc0to23: 17
        },
        {
          hour: '2PM ET',
          utc: '6PM UTC',
          utc0to23: 18
        },
        {
          hour: '3PM ET', //
          utc: '7PM UTC',
          utc0to23: 19
        },
        {
          hour: '4PM ET',
          utc: '8PM UTC',
          utc0to23: 20
        },
        {
          hour: '5PM ET',
          utc: '9PM UTC',
          utc0to23: 21
        },
        {
          hour: '6PM ET', //
          utc: '10PM UTC',
          utc0to23: 22
        },
        {
          hour: '7PM ET',
          utc: '11PM UTC',
          utc0to23: 23
        },
        {
          hour: '8PM ET',
          utc: '12AM UTC',
          utc0to23: 0
        },
        {
          hour: '9PM ET',
          utc: '1AM UTC',
          utc0to23: 1
        },
        {
          hour: '10PM ET',
          utc: '2AM UTC',
          utc0to23: 2
        },
        {
          hour: '11PM ET',
          utc: '3AM UTC',
          utc0to23: 3
        }
      ],
      schedule: {
        frequencySelected: 'No Schedule',
        daySelected: null,
        hourSelected: null
      },
      created_by: null,
      last_executed: null,
      status: null,
      isCampaignDropdownOpen: false,
      debounce: null
    }
  },
  computed: {
    ...mapGetters('campaigns', {
      campaignsListAirtory: 'listAirtoryMeasuremet', // listAirtory
      campaignsListPadSquad: 'listPadSquadMeasuremet',
      campaignById: 'itemById'
    }),
    ...mapGetters('placements', {
      placementsList: 'list',
      placementsByCampaign: 'listByCampaignId'
    }),
    ...mapGetters('creatives', {
      creativesList: 'list',
      creativesByCampaign: 'listByCampaignId'
    }),
    ...mapGetters('analytics', {
      getMetrics: 'getMetrics',
      getDimensions: 'getDimensions',
      getSavedReports: 'getSavedReports',
      getSavedReportById: 'getSavedReportById'
    }),
    updatedOptionsList: function () {
      if (this.campaignSearchValue) {
        return this.filterCampaignsSearchList(this.listCampaignsFiltered)
      }
      return this.listCampaignsFiltered.slice(0, 49)
    },
    listCampaignsFiltered: function () {
      if (!this.selectedCampaignSource) return []

      const filteredCampaignBySource
        = this.selectedCampaignSource === 'PadSquad'
          ? this.campaignsListPadSquad
          : this.campaignsListAirtory

      const campaigns
        = this.selectedCampaignSource === 'PadSquad'
          ? [{ id: 0, name: 'PadSquad Campaigns' }]
          : [{ id: 0, name: 'All Campaigns' }]

      return [...campaigns, ...filteredCampaignBySource]
    },
    listReportsFiltered: function () {
      // descending sort
      return [
        ...this.listTemplatesReports.sort((a, b) => (a.id < b.id ? 1 : -1))
      ]
    },
    isRequiredDataFilledOut: function () {
      return (
        this.selectedCampaignsIds.length > 0
        && this.selectedPlacements.length > 0
        && this.selectedCreatives.length > 0
        && this.selectedMetrics.length > 0
        && this.selectedDimensions.length > 0
        && !this.hasIncompatibleMetrics
        && this.selectedCampaignSource !== null
      )
    },
    isRequiredDataFilledOutForSavedReport: function () {
      // this.$log.info(this.isRequiredDataFilledOut, (this.newReportName !== null && this.newReportName.length > 4), this.emails.length > 0, this.schedule.frequencySelected !== 'No Schedule', this.schedule.daySelected !== 'Day', this.schedule.hourSelected !== 'Hour');
      if (!this.isSavedReport) {
        return (
          this.isRequiredDataFilledOut
          && this.newReportName !== null
          && this.newReportName.length > 4
          && this.emails.length > 0
        )
      } else {
        if (
          this.isRequiredDataFilledOut
          && this.newReportName !== null
          && this.newReportName.length > 4
          && this.emails.length > 0
        ) {
          // if the user selects a schedule frequency and not selects day or hour
          if (this.schedule.frequencySelected !== 'No Schedule') {
            // console.log('Is not No Schedule', this.schedule.frequencySelected !== 'No Schedule');
            if (this.schedule.frequencySelected !== 'Every Day') {
              if (!this.schedule.daySelected || !this.schedule.hourSelected)
                return false
            }
            if (!this.schedule.frequencySelected && !this.schedule.hourSelected)
              return false
            return true
          }
          return true
        } else {
          return false
        }
      }
    },
    filteredDimensions: function () {
      let filtered = [...this.dimensions]
      if (!this.hasMetricsOcurrences) {
        filtered = filtered.filter(
          (item) => item !== 'AIR Engagement Reporting Label'
        )
      }
      if (!this.hasMetricsVideos) {
        filtered = filtered.filter((item) => item !== 'AIR Video Name')
      }
      return filtered
    },
    filteredDMetrics: function () {
      const filtered = [...this.metrics]

      return filtered
    },
    getEmailsValue: function () {
      const _emails = new Set()
      this.emails.map((email) => {
        if (email.value.length > 0 && this.passedValidation(email.value))
          return _emails.add(email.value)
      })
      return Array.from(_emails)
    },
    areAllCampaignSelected: function () {
      return this.selectedCampaignsIds.find((item) => item === 0) !== undefined
    },
    sortvalues: function () {
      const datasort = []
      this.selectedGroupDimValues.map((dt) => {
        this.groupDimensions.every((item) => {
          if (item.data.find((x) => x.id == dt.id)) {
            if (datasort.length == 0) {
              datasort.push({
                vendor: item.data.find((x) => x.id == dt.id).name.split(' ')[0],
                data: [item.data.find((x) => x.id == dt.id)]
              })
            } else if (
              datasort.find(
                (x) =>
                  x.vendor
                  == item.data.find((x) => x.id == dt.id).name.split(' ')[0]
              )
            ) {
              datasort
                .find(
                  (x) =>
                    x.vendor
                    == item.data.find((x) => x.id == dt.id).name.split(' ')[0]
                )
                .data.push(item.data.find((x) => x.id == dt.id))
            } else {
              datasort.push({
                vendor: item.data.find((x) => x.id == dt.id).name.split(' ')[0],
                data: [item.data.find((x) => x.id == dt.id)]
              })
            }

            return false
          }
          return true
        })
      })
      this.groupSortDimensions = datasort
      return datasort
    }
  },
  watch: {
    selectedMetrics: function () {
      this.hasIncompatibleMetrics
        = (this.selectedMetrics.includes(
          this.incompatibleDimensionsMetrics[0].metric
        )
          && this.selectedDimensions.includes(
            this.incompatibleDimensionsMetrics[0].dimension
          ))
        || (this.selectedMetrics.includes(
          this.incompatibleDimensionsMetrics[1].metric
        )
          && this.selectedDimensions.includes(
            this.incompatibleDimensionsMetrics[1].dimension
          ))
    },
    selectedDimensions: function () {
      this.hasIncompatibleMetrics
        = (this.selectedMetrics.includes(
          this.incompatibleDimensionsMetrics[0].metric
        )
          && this.selectedDimensions.includes(
            this.incompatibleDimensionsMetrics[0].dimension
          ))
        || (this.selectedMetrics.includes(
          this.incompatibleDimensionsMetrics[1].metric
        )
          && this.selectedDimensions.includes(
            this.incompatibleDimensionsMetrics[1].dimension
          ))
    },
    getSavedReports: function () {
      this.listTemplatesReports = [...this.getSavedReports]
    },
    isSavedReport: function () {
      // empty
      if (!this.isSavedReport) {
        this.newReportName = ''
      } else {
        if (this.selectedTemplateReport !== null)
          this.newReportName = this.selectedTemplateReportData.name
      }
    }
  },
  async created() {
    // if no campaigns
    if (!this.campaignsListAirtory || this.campaignsListAirtory.length === 0) {
      await this.fetchCampaigns()
    }
    // set the id passed on props
    if (this.campaignId) {
      this.selectedCampaignsIds = [this.campaignId]
      await this.loadCampaignData()
    }

    await this.fetchMetrictsAndDimensions()

    this.getMetrics.forEach((element) => {
      if (this.groupMetrics.length == 0) {
        this.groupMetrics.push({
          vendor: element.name.split(' ')[0],
          data: [{ name: element.name, id: element.id }]
        })
      } else if (
        this.groupMetrics.find((x) => x.vendor == element.name.split(' ')[0])
      ) {
        this.groupMetrics
          .find((x) => x.vendor == element.name.split(' ')[0])
          .data.push({ name: element.name, id: element.id })
      } else {
        this.groupMetrics.push({
          vendor: element.name.split(' ')[0],
          data: [{ name: element.name, id: element.id }]
        })
      }
    })
    this.getDimensions.forEach((element) => {
      if (this.groupDimensions.length == 0) {
        this.groupDimensions.push({
          vendor: element.name.split(' ')[0],
          data: [{ name: element.name, id: element.id }]
        })
      } else if (
        this.groupDimensions.find((x) => x.vendor == element.name.split(' ')[0])
      ) {
        this.groupDimensions
          .find((x) => x.vendor == element.name.split(' ')[0])
          .data.push({ name: element.name, id: element.id })
      } else {
        this.groupDimensions.push({
          vendor: element.name.split(' ')[0],
          data: [{ name: element.name, id: element.id }]
        })
      }
    })

    if (!this.getSavedReports || this.getSavedReports.length === 0)
      await this.fetchSavedReports()
    this.listTemplatesReports = [...this.getSavedReports]
    if (this.savedReportId) {
      this.selectedTemplateReport = this.savedReportId
      this.isSavedReport = true
      await this.loadSavedReportData()
    }

    /*      this.metrics = this.getMetrics.map(item => item.name);
            this.dimensions = this.getDimensions.map(item => item.name); */

    if (!this.savedReportId)
      this.emails = [
        { index: 0, value: `${this.$store.getters.userInSession.email}` }
      ]
  },
  methods: {
    ...mapActions(['registerTask', 'completeTask']),
    ...mapActions('campaigns', {
      getCampaignById: 'fetchById',
      fetchCampaigns: 'fetch'
    }),
    ...mapActions('analytics', {
      fetchMetrictsAndDimensions: 'fetchMetrictsAndDimensions',
      fetchMetricts: 'fetchMetricts',
      fetchDimensions: 'fetchDimensions',
      fetchSavedReports: 'fetchSavedReports',
      createSavedReport: 'createSavedReport',
      editSavedReport: 'editSavedReport'
    }),
    filterCampaignsSearchList(arrayOfCampaigns) {
      if (!this.campaignSearchValue){
        return arrayOfCampaigns
      }
      const resultOfSearch = arrayOfCampaigns.filter(
        (x) =>
          x.id.toString().includes(this.campaignSearchValue)
          || x.name.toLowerCase().includes(this.campaignSearchValue.toLowerCase())
      )

      return resultOfSearch
    },
    updateValues: function (dates) {
      this.$log.info(dates, this.dateRangeData)
    },
    onSelectDateRange: function (e) {
      this.selectedDateRange = e

      switch (this.selectedDateRange) {
        case 'Today':
          this.dateRangeData.startDate = new Date()
          this.dateRangeData.endDate = new Date()
          break
        case 'Yesterday':
          this.dateRangeData.startDate = this.getDateFromDays(1)
          this.dateRangeData.endDate = this.getDateFromDays(1)
          break
        case 'Last 7 Days':
          this.dateRangeData.startDate = this.getDateFromDays(7)
          this.dateRangeData.endDate = new Date()
          break
        case 'Last 14 Days':
          this.dateRangeData.startDate = this.getDateFromDays(14)
          this.dateRangeData.endDate = new Date()
          break
        case 'Last 30 Days':
          this.dateRangeData.startDate = this.getDateFromDays(30)
          this.dateRangeData.endDate = new Date()
          break
        case 'Campaign start date to today':
          this.dateRangeData.startDate = new Date()
          this.dateRangeData.endDate = new Date()
          this.campaingnToDate(this.selectedCampaignsIds)
          break
        default:
          if (!this.saveNewReport) {
            // today
            this.dateRangeData.startDate = new Date()
            this.dateRangeData.endDate = new Date()
          }
          break
      }
    },
    debouncedTypeSearchCampaigns: _.debounce(function (event) {
      this.campaignSearchValue = event
    }, 300),
    updateSearch: function (event) {
      this.debouncedTypeSearchCampaigns(event)
    },
    onSelectCampaignChange: function (selected) {
      // if Campaign start date to today is selected
      this.campaingnToDate(selected)

      if (
        selected.includes(0)
        && selected.length > 1
        && selected.indexOf(0) !== 0
      ) {
        // "All Campaigns" is selected along with any previous campaings (remove all previous campaigns)
        this.selectedCampaignsIds = [0]
      } else if (
        selected.includes(0)
        && selected.length > 1
        && selected.indexOf(0) === 0
      ) {
        // A campaing is seleted and "All Campaigns" is already selected (remove "All Campaigns")
        this.selectedCampaignsIds = selected.filter((id) => id !== 0)
      }

      if (!this.isCampaignDropdownOpen) this.checkIsOnlyOneCampaign(selected)
    },
    campaingnToDate: function (selected) {
      // if Campaign start date to today is selected, validate if all campaign are selected
      if (this.selectedDateRange === 'Campaign start date to today') {
        if (selected.filter((x) => x === 0).length > 0) {
          this.dateRangeData.startDate = new Date(
            Math.min(
              ...this.listCampaignsFiltered
                .filter(
                  (x) => x.start_date != null && x.start_date != undefined
                )
                .map((x) => new Date(x.start_date))
            )
          )
        } else if (selected.length > 0) {
          this.dateRangeData.startDate = new Date(
            Math.min(
              ...this.listCampaignsFiltered
                .filter((x) => selected.includes(x.id))
                .map((x) => new Date(x.start_date))
            )
          )
        }
      }
    },
    onSelectCampaignOpen: function (selected) {
      this.isCampaignDropdownOpen = true
    },
    onSelectCampaignClose: function (selected) {
      // this.$log.info(selected, this.selectedCampaignsIds);
      this.isCampaignDropdownOpen = false
      this.checkIsOnlyOneCampaign(selected)
    },
    checkIsOnlyOneCampaign: function (selected) {
      if (selected === null) {
        this.selectedCampaignsData = null
        this.selectedCampaignsPlacements = []
        this.selectedCampaignsCreatives = []

        this.selectedPlacements = []
        this.selectedCreatives = []
      } else {
        if (
          this.areAllCampaignSelected
          || this.selectedCampaignsIds.length > 1
        ) {
          // pre select the 0 - All ..
          this.selectedCampaignsPlacements = [{ id: 0, name: 'All Placements' }]
          this.selectedCampaignsCreatives = [{ id: 0, name: 'All Creatives' }]
          this.selectedPlacements = [0]
          this.selectedCreatives = [0]

          // add campaign name as dimension
          //  if(this.selectedDimensions.length === 0 || this.selectedDimensions.length > 0 && this.selectedDimensions.find(item => item === 'AIR Campaign Name') === undefined) this.selectedDimensions.push('AIR Campaign Name');
        } else {
          // get campaign placements and creatives
          if (this.selectedCampaignsIds.length === 1) this.loadCampaignData()
        }
      }
    },
    getArrayFromString: function (string, parse = false) {
      if (parse) return string.split(',').map((item) => parseInt(item)) || [0]
      return string.split(',').map((item) => item)
    },
    loadSavedReportData: async function () {
      const data = this.getSavedReportById(this.selectedTemplateReport)
      this.$log.info(data)

      const campaignsArray = this.getArrayFromString(data.campaigns, true)
      const placementsArray = this.getArrayFromString(data.placements, true)
      const creativesArray = this.getArrayFromString(data.creatives, true)

      this.newReportName = data.name

      data.campaign_source === 'PadSquad'
        ? (this.selectedCampaignSource = data.campaign_source)
        : (this.selectedCampaignSource = 'Airtory Sync')

      this.selectedTemplateReportData = { ...data }

      try {
        this.selectedPlacements = placementsArray.find((item) => item === 0)
          ? [0]
          : placementsArray
        this.selectedCreatives = creativesArray.find((item) => item === 0)
          ? [0]
          : creativesArray

        // Load load the campaign data if the campaign is only one ID,
        // that way we could select placements and creatives lists from that campaign
        this.selectedCampaignsIds = data.all_campaigns_selected
          ? [0]
          : campaignsArray
        if (
          this.selectedCampaignsIds.length === 1
          && this.selectedCampaignsIds[0] !== 0
        ) {
          await this.loadCampaignData(false)
        } else {
          this.selectedCampaignsPlacements
            = this.selectedPlacements[0] === 0
              ? [{ id: 0, name: 'All Placements' }]
              : this.selectedPlacements
          this.selectedCampaignsCreatives
            = this.selectedCreatives[0] === 0
              ? [{ id: 0, name: 'All Creatives' }]
              : this.selectedCreatives
        }

        data.metrics.split(',').forEach((dt) => {
          this.groupMetrics.every((item) => {
            if (item.data.find((x) => x.id == dt)) {
              this.selectedMetrics.push(item.data.find((x) => x.id == dt).name)
              this.selectedGroupMetValues.push(
                item.data.find((x) => x.id == dt)
              )
              return false
            }
            return true
          })
        })

        // this.selectedDimensions = data.dimensions.split(',');

        const datasort = []
        data.dimensions.split(',').forEach((dt) => {
          this.groupDimensions.every((item) => {
            if (item.data.find((x) => x.id == dt)) {
              this.selectedDimensions.push(
                item.data.find((x) => x.id == dt).name
              )
              // idlc
              // add sort items based on selected dimensions

              if (datasort.length == 0) {
                datasort.push({
                  vendor: item.data.find((x) => x.id == dt).name.split(' ')[0],
                  data: [item.data.find((x) => x.id == dt)]
                })
              } else if (
                datasort.find(
                  (x) =>
                    x.vendor
                    == item.data.find((x) => x.id == dt).name.split(' ')[0]
                )
              ) {
                datasort
                  .find(
                    (x) =>
                      x.vendor
                      == item.data.find((x) => x.id == dt).name.split(' ')[0]
                  )
                  .data.push(item.data.find((x) => x.id == dt))
              } else {
                datasort.push({
                  vendor: item.data.find((x) => x.id == dt).name.split(' ')[0],
                  data: [item.data.find((x) => x.id == dt)]
                })
              }

              // this.groupSortDimensions.push(item.data.find(x=> x.id==dt));
              this.selectedGroupDimValues.push(
                item.data.find((x) => x.id == dt)
              )
              return false
            }
            return true
          })
        })

        this.groupSortDimensions = datasort
        // console.log('data.sort_dimension',data.sort_dimension)
        if (
          data.sort_dimension != null
          && this.groupSortDimensions.length > 0
        ) {
          data.sort_dimension.split(',').forEach((dt) => {
            this.groupSortDimensions.every((item) => {
              if (item.data.find((x) => x.id == dt)) {
                // this.groupSortDimensions.push(item.data.find(x=> x.id==dt));
                this.selectedGroupSortValues = item.data.find((x) => x.id == dt)
                return false
              }
              return true
            })
          })
        }

        this.showRatesAsDecimals = data.rates_as_decimals

        this.emails = data.emails.split(',').map((email, index) => {
          const item = { index, value: email.replace(' ', '') }
          return item
        })
        this.selectedDateRange = data.date_range
        ;(this.dateRangeData.startDate = moment
          .utc(data.startDate)
          .format('YYYY-MM-D')),
          (this.dateRangeData.endDate = moment
            .utc(data.endDate)
            .format('YYYY-MM-D')),
          (this.schedule.frequencySelected = data.frequency || 'No Schedule')
        this.schedule.daySelected = data.day || null
        this.schedule.hourSelected = data.hour
          ? this.hours.find((item) => item.utc0to23 === parseInt(data.hour))
              .hour || null
          : null

        this.onlyEmailReport = data.only_email || false
        this.selectedOrderBy = data.sort_by
      } catch (error) {
        console.log(error)
      }
    },
    loadCampaignData: function (override = true) {
      return new Promise((resolve, reject) => {
        this.getCampaignById(this.selectedCampaignsIds)
          .then((campaignData) => {
            this.$log.info(campaignData)

            this.selectedCampaignsData = { ...campaignData }
            this.selectedCampaignsPlacements
              = campaignData.placements.length > 0
                ? [
                    { id: 0, name: 'All Placements' },
                    ...campaignData.placements
                  ]
                : []
            this.selectedCampaignsCreatives
              = campaignData.creatives.length > 0
                ? [{ id: 0, name: 'All Creatives' }, ...campaignData.creatives]
                : []

            if (override) {
              this.selectedPlacements = []
              this.selectedCreatives = []
              // pre select the 0 - All ..
              if (campaignData.placements.length > 0)
                this.selectedPlacements = [0]
              if (campaignData.creatives.length > 0)
                this.selectedCreatives = [0]
            }

            resolve(campaignData)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    onSelectPlacements: function (selected) {
      this.$log.info(this.selectedPlacements)
    },
    onSelectCreatives: function (selected) {
      this.$log.info(this.selectedCreatives)
    },
    onSelectedMetrics: function (selected) {
      this.selectedMetrics = this.selectedGroupMetValues.map(
        (item) => item.name
      )
      const ocurrencesM = [
        'AIR Event Occurrences',
        'AIR User Initiated Event Occurrences'
      ]
      const videoM = [
        'AIR Video Starts',
        'AIR Video Completions',
        'AIR Video Plays',
        'AIR Video Play Time',
        'AIR Video First Quartile View',
        'AIR Video Second Quartile View',
        'AIR Video Third Quartile View',
        'AIR Video Fourth Quartile View'
      ]

      this.hasMetricsOcurrences = this.selectedMetrics.some((m) =>
        ocurrencesM.includes(m)
      )
      this.hasMetricsVideos = this.selectedMetrics.some((m) =>
        videoM.includes(m)
      )
      // this.$log.info(this.selectedMetrics, this.hasMetricsOcurrences, this.hasMetricsVideos);
    },
    onSelectedDimensions: function (selected) {
      if (
        this.selectedGroupSortValues != null
        && this.selectedGroupSortValues != undefined
      ) {
        if (this.selectedGroupSortValues.id > 0) {
          if (
            selected.filter((x) => x.id == this.selectedGroupSortValues.id)
              .length == 0
          ) {
            this.selectedGroupSortValues = []
          }
        }
      }
      this.selectedDimensions = this.selectedGroupDimValues.map(
        (item) => item.name
      )
    },
    onSelectedSortDimensions: function (selected) {
      // this.selectedSortsDimensions =  this.selectedGroupSortValues.map(item=> item.name);
      //   this.selectedDimensions = this.selectedGroupDimValues.map(item=> item.name);
      // this.$log.info(this.selectedDimensions);
    },
    onSelectTemplate: function (selected) {
      this.$log.info(this.selectedTemplateReport)
      this.loadSavedReportData()
    },
    onSelectedScheduleItem: function (selected) {
      this.$log.info(this.schedule)
    },
    getDateFromDays: function (days) {
      const d = new Date()
      d.setDate(d.getDate() - days)
      return d
    },
    getCampaignName: function (id) {
      if (
        this.selectedCampaignsIds.length === 1
        && this.selectedCampaignsIds[0] !== 0
      ) {
        // return this.campaignsListAirtory.find(x => x.id == id).name;
        return this.listCampaignsFiltered.find((x) => x.id == id).name
      }

      if (
        this.selectedCampaignsIds.length >= 1
        && this.areAllCampaignSelected
      ) {
        return 'All'
      }

      if (
        this.selectedCampaignsIds.length > 1
        && this.selectedCampaignsIds.find((item) => item === 0) === undefined
      ) {
        return 'Multiple'
      }
    },
    passedValidation: function (value) {
      return Utils.validateEmail(value)
    },
    addEmail: function (index) {
      this.emails.push({
        index,
        value: ''
      })
    },
    removeEmail: function (index) {
      if (index === 0) return // this.emails[0].value = '';
      this.emails = this.emails.filter((item, i) => i !== index)
    },
    isThisFirstIndexEmail: function (index) {
      return index === 0
    },
    isThisLastIndexEmail: function (index) {
      return index === this.emails.length - 1
    },
    cancel: function () {
      // emit event
      this.$emit('done')
    },
    getReportData: function (isRun = true) {
      let data
      // This conditional is to seperate Airtory + 3P function from padsquad explicitly
      if (this.selectedCampaignSource !== 'PadSquad') {
        if (isRun) {
          data = {
            startDate: moment
              .utc(this.dateRangeData.startDate)
              .format('YYYY-MM-D'),
            endDate: moment.utc(this.dateRangeData.endDate).format('YYYY-MM-D'),
            // selectedCampaigns: (this.selectedCampaignsIds.find(item => item === 0) === undefined) ? this.selectedCampaignsIds.join() : this.campaignsListAirtory.map(item => item.id).join(), // 0,
            selectedCampaigns:
              this.selectedCampaignsIds.find((item) => item === 0) === undefined
                ? this.selectedCampaignsIds.join()
                : 0, // 0,
            selectedPlacements:
              this.selectedPlacements.find((item) => item === 0) === undefined
                ? this.selectedPlacements.join()
                : 0, // this.selectedCampaignsData.placements.map(item => item.id).join(),
            selectedCreatives:
              this.selectedCreatives.find((item) => item === 0) === undefined
                ? this.selectedCreatives.join()
                : 0, // this.selectedCampaignsData.creatives.map(item => item.id).join(),
            selectedMetrics: this.selectedMetrics.join(),
            selectedDimensions: this.selectedDimensions.join(),
            ratesAsDecimals: this.showRatesAsDecimals,
            onlyEmailReport: this.onlyEmailReport,
            emails: this.getEmailsValue.join(),
            reportName: this.newReportName || '',
            reportId: this.selectedTemplateReport || '',
            sort_dimension:
              this.selectedGroupSortValues !== null
                ? this.selectedGroupSortValues.id
                : '',
            sort_by: this.selectedOrderBy
            // selectedSystems: this.selectedSystems.join(),
          }
        } else {
          data = {
            name: this.newReportName,
            startDate: moment
              .utc(this.dateRangeData.startDate)
              .format('YYYY-MM-D'),
            endDate: moment.utc(this.dateRangeData.endDate).format('YYYY-MM-D'),
            campaigns:
              this.selectedCampaignsIds.find((item) => item === 0) === undefined
                ? this.selectedCampaignsIds.join()
                : '0', // this.campaignsListAirtory.map(item => item.id).join(), // 0,
            placements:
              this.selectedPlacements.find((item) => item === 0) === undefined
                ? this.selectedPlacements.join()
                : '0', // this.selectedCampaignsData.placements.map(item => item.id).join(),
            creatives:
              this.selectedCreatives.find((item) => item === 0) === undefined
                ? this.selectedCreatives.join()
                : '0', // this.selectedCampaignsData.creatives.map(item => item.id).join(),
            metrics: this.selectedGroupMetValues.map((x) => x.id).join(), // this.selectedMetrics.join(),
            dimensions: this.selectedGroupDimValues.map((x) => x.id).join(), // this.selectedDimensions.join(),
            emails: this.getEmailsValue.join(),
            frequency:
              this.selectedDateRange === 'Custom Range'
                ? 'No Schedule'
                : this.schedule.frequencySelected,
            day:
              this.schedule.frequencySelected === 'Every Day'
                ? null
                : this.schedule.daySelected,
            hour: this.schedule.hourSelected
              ? this.hours.find(
                  (item) => item.hour === this.schedule.hourSelected
                ).utc0to23
              : null,
            rates_as_decimals: this.showRatesAsDecimals,
            all_campaigns_selected: !!this.areAllCampaignSelected,
            created_by: `${this.$store.getters.userInSession.name} ${this.$store.getters.userInSession.last_name}`,
            date_range: this.selectedDateRange,
            only_email: this.onlyEmailReport,
            sort_dimension:
              this.selectedGroupSortValues !== null
                ? this.selectedGroupSortValues.id
                : '',
            sort_by: this.selectedOrderBy
          }
          if (this.isSavedReport)
            data = { ...data, id: this.selectedTemplateReport }
        }
        // Below is PadSquad Campaign Source Code
      } else {
        if (isRun) {
          data = {
            startDate: moment
              .utc(this.dateRangeData.startDate)
              .format('YYYY-MM-D'),
            endDate: moment.utc(this.dateRangeData.endDate).format('YYYY-MM-D'),
            // selectedCampaigns: (this.selectedCampaignsIds.find(item => item === 0) === undefined) ? this.selectedCampaignsIds.join() : this.campaignsListAirtory.map(item => item.id).join(), // 0,
            selectedCampaigns:
              this.selectedCampaignsIds.find((item) => item === 0) === undefined
                ? this.selectedCampaignsIds.join()
                : 0, // 0,
            selectedPlacements:
              this.selectedPlacements.find((item) => item === 0) === undefined
                ? this.selectedPlacements.join()
                : 0, // this.selectedCampaignsData.placements.map(item => item.id).join(),
            selectedCreatives:
              this.selectedCreatives.find((item) => item === 0) === undefined
                ? this.selectedCreatives.join()
                : 0, // this.selectedCampaignsData.creatives.map(item => item.id).join(),
            selectedMetrics: this.selectedMetrics.join(),
            selectedDimensions: this.selectedDimensions.join(),
            ratesAsDecimals: this.showRatesAsDecimals,
            onlyEmailReport: this.onlyEmailReport,
            emails: this.getEmailsValue.join(),
            reportName: this.newReportName || '',
            reportId: this.selectedTemplateReport || '',
            sort_dimension:
              this.selectedGroupSortValues !== null
                ? this.selectedGroupSortValues.id
                : '',
            sort_by: this.selectedOrderBy,
            // This key is the only property added when the campaign source is PadSquad
            campaign_source: this.selectedCampaignSource
            // selectedSystems: this.selectedSystems.join(),
          }
        } else {
          data = {
            name: this.newReportName,
            startDate: moment
              .utc(this.dateRangeData.startDate)
              .format('YYYY-MM-D'),
            endDate: moment.utc(this.dateRangeData.endDate).format('YYYY-MM-D'),
            campaigns:
              this.selectedCampaignsIds.find((item) => item === 0) === undefined
                ? this.selectedCampaignsIds.join()
                : '0', // this.campaignsListAirtory.map(item => item.id).join(), // 0,
            placements:
              this.selectedPlacements.find((item) => item === 0) === undefined
                ? this.selectedPlacements.join()
                : '0', // this.selectedCampaignsData.placements.map(item => item.id).join(),
            creatives:
              this.selectedCreatives.find((item) => item === 0) === undefined
                ? this.selectedCreatives.join()
                : '0', // this.selectedCampaignsData.creatives.map(item => item.id).join(),
            metrics: this.selectedGroupMetValues.map((x) => x.id).join(), // this.selectedMetrics.join(),
            dimensions: this.selectedGroupDimValues.map((x) => x.id).join(), // this.selectedDimensions.join(),
            emails: this.getEmailsValue.join(),
            frequency:
              this.selectedDateRange === 'Custom Range'
                ? 'No Schedule'
                : this.schedule.frequencySelected,
            day:
              this.schedule.frequencySelected === 'Every Day'
                ? null
                : this.schedule.daySelected,
            hour: this.schedule.hourSelected
              ? this.hours.find(
                  (item) => item.hour === this.schedule.hourSelected
                ).utc0to23
              : null,
            rates_as_decimals: this.showRatesAsDecimals,
            all_campaigns_selected: !!this.areAllCampaignSelected,
            created_by: `${this.$store.getters.userInSession.name} ${this.$store.getters.userInSession.last_name}`,
            date_range: this.selectedDateRange,
            only_email: this.onlyEmailReport,
            sort_dimension:
              this.selectedGroupSortValues !== null
                ? this.selectedGroupSortValues.id
                : '',
            sort_by: this.selectedOrderBy,
            // This key is the only property added when the campaign source is PadSquad
            campaign_source: this.selectedCampaignSource
          }
          if (this.isSavedReport)
            data = { ...data, id: this.selectedTemplateReport }
        }
      }
      return data
    },
    informUserAndNotDownload: function () {
      this.$swal({
        position: 'top',
        title: 'Success!',
        html: '<h3>Looks like your report will take some extra time to process. We will notify you via email once your request is completed.</h3>',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'success',
        timer: 10000,
        toast: false
      })
    },
    informUserDownload: function () {
      const message = this.onlyEmailReport ? 'sent' : 'downloaded'

      this.$swal({
        position: 'top',
        title: 'Success!',
        html: `<h3>Your file is being ${message}.</h3>`,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: 'success',
        timer: 4000,
        toast: false
      })
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const date = new Date()
      const filename = `${this.selectedCampaignsIds}_${this.getCampaignName(this.selectedCampaignsIds)}_${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}_export.csv`
      link.setAttribute('download', filename) // or any other extension
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // close
      // this.$emit('done');
    },
    openMetDimDocument: function (evt) {
      window.open(
        'https://padsquad.atlassian.net/wiki/spaces/SPUD/pages/2166423555/Report+Builder',
        '_blank'
      )
    },
    saveNewReport: async function () {
      this.$log.info(this.getReportData(false))
      try {
        const data = await this.createSavedReport(this.getReportData(false))
        this.selectedTemplateReport = data.id
        this.selectedTemplateReportData = { ...data }
        this.isSavedReport = true
        this.$log.info(data)
      } catch (error) {
        console.log(error)
      }
    },
    updateReport: async function () {
      this.onSelectDateRange(this.selectedDateRange)
      this.$log.info(this.getReportData(false))
      try {
        const data = await this.editSavedReport(this.getReportData(false))
        this.$log.info(data)
      } catch (error) {
        console.log(error)
      }
    },
    download: async function () {
      await this.registerTask('downLoadingCSV', { root: true })
      this.onSelectDateRange(this.selectedDateRange)
      this.$log.info('dataaaaaa', this.getReportData(true))
      axios
        .get(`${process.env.VUE_APP_ENDPOINT_ANALYTICS_API_CSV_DOWNLOAD}`, {
          params: this.getReportData(true)
        })
        .then((response) => {
          this.completeTask('downLoadingCSV', { root: true })
          // clearTimeout(_timeout);
          if (response.data.data && response.data.data === 'RUNNING') {
            // 30s timeout for notifying user if the data takes too long to be proccessed it will be send to him by email
            this.informUserAndNotDownload()
          } else {
            this.informUserDownload()
            if (!this.onlyEmailReport) this.forceFileDownload(response)
          }
        })
        .catch((error) => {
          this.completeTask('downLoadingCSV', { root: true })
          console.log('error', error)
          if (error == 'Error: Network Error') {
            const Reportdata = this.getSavedReportById(
              this.selectedTemplateReport
            )

            const a = document.createElement('a')
            a.href = Reportdata.last_generated_csv_url
            a.download = Reportdata.last_generated_csv_url.split('/').pop()
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          } else {
            this.completeTask('downLoadingCSV', { root: true })
            this.$swal({
              position: 'top',
              title: 'Failed to download the CSV.',
              html: `${error}`,
              showDenyButton: false,
              showConfirmButton: false,
              showCancelButton: true,
              confirmButtonText: 'TRY AGAIN',
              cancelButtonText: 'Close',
              allowOutsideClick: false,
              icon: 'error',
              toast: false
            })
          }
        })
    },
    vendorLabel(option) {
      if (!option.$isLabel) {
        return option.name.split(' ')[0]
      }
      return option.$groupLabel
    },
    vendorLabelDisplay(option, all = '') {
      if (!option.$isLabel) {
        const lvalue = option.name.split(' ')[0].length
        return option.name.substring(lvalue, option.name.length)
      } else {
        return `${all}`
      }
    }
  },
  filters: {
    date: function (value) {
      return moment(value).format('MMMM Do YYYY')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.tac {
  text-align: center;
}

/* arrow */
.multiselect__select {
  z-index: 9;
}

.multiselect__input,
.multiselect__single {
  font-size: 1.2rem;
  font-weight: 400;
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 0px;
  background: #fff;
  padding: 0 0 0 5px;
  padding: 0;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}

.multiselect__select:before {
  color: #646464;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #14123a;
}

.multiselect__placeholder {
  padding-left: 7px;
  color: #adadad;
  color: #14123a;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
  padding: 0;
  font-weight: 400;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 1rem 1.5rem 0;
  border-radius: 0px;
  border: 1px solid #d9d9d9;
  background: #fff;
  font-size: 1.2rem;
}

.multiselect__tags:hover {
  border-color: #979797;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  /* padding: 4px 26px 4px 10px; */
  border-radius: 0px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #3bbca1;
  background: #8ad622;
  /* background: #11433C; */
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 97%;
  text-overflow: ellipsis;
}

.multiselect__option {
  font-size: 1.2rem;
  font-weight: 400;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #14123a;
  font-weight: 400;
}

.multiselect__option--highlight {
  background: #3bbca1;
  outline: none;
  color: #fff;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #3bbca1;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__tag-icon:after {
  content: '\D7';
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__option--highlight {
  background: #3bbca1;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #3bbca1;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #a8a8a8;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #a8a8a8;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect__content-wrapper {
  max-height: 300px;
}
/* DateRange */
.reportrange-text {
  display: none !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #3bbca1;
  border-color: transparent;
  color: #fff;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
  border-radius: 0 !important;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
  border-radius: 0 !important;
}

.daterangepicker td.in-range {
  /* background-color: #efefef; */
}

small.dates {
  display: block;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 600;
}

small.dates span {
  font-weight: 400;
}

.label {
  text-transform: uppercase;
}

.py-3 {
  margin-bottom: 10px;
}

h6.title_selected {
  font-weight: 600;
  font-weight: 400;
  margin-top: 5px;
  color: #a1a0b9;
}

h6.title_selected span {
  font-weight: 500;
  color: #3bbca1;
}

.sModal__header--blue {
  background: #14123a !important;
  color: #fafafa !important;
  /* background: #3BBCA1; */
}

.error {
  color: #9c2e06;
}

.alert-box {
  background-color: #9c2e06;
  color: #ffffff;
  text-align: center;
  padding: 10px;
}

.alert-box span {
  font-weight: bold;
}

.fullWidth {
  display: grid !important;
}

.buttons-row {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  transition: all 0.3s ease-out;
  opacity: 0;
}

.isFirstWithDescription {
  /* margin-top: 25px; */
}

.buttons-row button {
  float: left;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  margin-left: 0 !important;
  height: 37px;
  width: 44px !important;
}

.notPassedValidation {
  border-color: #9c2e06 !important;
}

.edit-button {
  display: block;
  height: 37px;
}

.buttons-row:hover {
  opacity: 1;
  z-index: 200;
  pointer-events: auto;
}

.buttons-row:hover button {
  margin-left: 10px !important;
}

.edit-button button {
  pointer-events: none;
  height: 37px;
}

.listBoxReport .listBoxOneField {
  grid-template-columns: 1fr 60px;
  -ms-grid-columns: 1fr 60px;
}

.listBoxReport .listBoxOneField_labelArrowUp {
  position: relative;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 60px;
  -ms-grid-columns: 1fr 60px;
  margin-top: 10px;
}

.listBoxReport .listBoxOneField_labelArrowUp .formField__field__tag--left {
  overflow: hidden !important;
  text-indent: -500px !important;
  background-color: transparent !important;
  border: none !important;
}

.listBoxReport .listBoxOneField_labelArrowUp .formField__field--withTag {
  /* max-width: calc(100% - 60px); */
}

.listBoxReport .formField__field__tag--left {
  width: 118px !important;
  min-width: 118px !important;
  padding: 1rem 0 !important;
  text-align: center;
}

.schedule-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pair {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pair > span {
  margin-right: 10px;
}

.schedule-select .pair:first-child > div {
  width: 150px;
}

.schedule-select.justify-start .pair:first-child > div {
  margin-right: 15px;
}

.schedule-select .pair:nth-child(2) > div {
  width: 150px;
}

.schedule-select .pair:nth-child(3) > div {
  width: 90px;
}

.pair .formField {
  margin-bottom: 0 !important;
}

.checkbox-flex {
  margin-top: 5px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-flex input {
  margin-right: 5px;
}

.checkbox-flex label {
  margin-bottom: 0;
}

.only-email {
  padding: 10px;
  margin-bottom: 25px;
  border: 1px solid #dee2e6;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.only-email input {
  margin-right: 5px;
}

.only-email label {
  margin-bottom: 0;
}

.link-box {
  margin-bottom: 25px;
}

.small-block {
  display: block;
  margin-bottom: 10px;
}

.block-mt-15 {
  display: block;
  margin-top: 15px;
}

.ratioInputs {
  display: flex;
}

.ratioInputs div.df {
  display: flex;
  align-items: center;
}

.ratioInputs div.df input {
  margin-right: 5px;
}

.ratioInputs div input[type='radio'][data-v-610bc537] {
  margin-right: 5px !important;
}

.formField__field > .input-email:disabled {
  background: #ffffff !important;
  color: #8c8c8c;
}

.option__title {
  display: inline-block;
  color: #646464;
  font-size: 1rem;
  font-weight: bold;
  line-height: 0.1rem;
  text-transform: uppercase;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  background: white;
  padding: 1rem 1.5rem;
  cursor: pointer;
  width: 8rem;
  text-align: center;
  margin-right: 5px;
}
.multiselect__option--group {
  background: darkgray !important;
  color: #35495e !important;
  font-weight: bold !important;
}
</style>
