var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"drawer h-100 d-flex flex-column",style:(`
    width: ${_setup.drawerWidth}px;
    transition: width 0;
    position: relative;
  `)},[_c('div',{staticClass:"drawer-resizer",class:{'drawer-resizer__resizing': _vm.isDragging},on:{"mousedown":_setup.onResizerMouseDown}}),_c('div',{staticClass:"drawer-icons-container d-flex"},_vm._l((_setup.tabs),function(tab,idx){return _c('button',{key:idx,staticClass:"icon-button d-flex justify-content-center align-items-center flex-grow-1",style:(`
        background-color: ${tab.name === _setup.selectedTab ? '#6D6D6D' : '#575757'};
        border-bottom: ${tab.name !== _setup.selectedTab ? '1px solid #464646' : 'none'};
        border-left: ${(tab.name !== _setup.selectedTab && idx !== 0 && _setup.tabs[idx-1].name === _setup.selectedTab) ? '1px solid #464646' : 'none'};
        border-right: ${(tab.name !== _setup.selectedTab && idx !== (_setup.tabs.length - 1)) ? '1px solid #464646' : 'none'};
      `),on:{"click":function($event){return _setup.selectTab(tab)}}},[_c('font-awesome-icon',{staticStyle:{"height":"16px","width":"16px"},attrs:{"icon":tab.icon}})],1)}),0),_c('div',{staticClass:"flex-grow-1"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.selectedTab === 'info'),expression:"selectedTab === 'info'"}],staticClass:"h-100"},[_c(_setup.CreativePreviewDrawerInfo,{attrs:{"creative-data":_vm.creativeData},on:{"modify-url-params":function($event){return _setup.emit('modify-url-params', ...arguments)},"refresh-creative":function($event){return _setup.emit('refresh-creative')}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.selectedTab === 'eventConsole'),expression:"selectedTab === 'eventConsole'"}],staticClass:"h-100"},[_c(_setup.CreativePreviewEventConsole,{attrs:{"creative-data":_vm.creativeData,"is-tag-ready":_vm.isTagReady,"mock-site-iframe-key":_vm.mockSiteIframeKey},on:{"modify-url-params":function($event){return _setup.emit('modify-url-params', ...arguments)},"refresh-creative":function($event){return _setup.emit('refresh-creative')}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.selectedTab === 'contextSimulator'),expression:"selectedTab === 'contextSimulator'"}],staticClass:"h-100"},[(_vm.previewSettings.hasDCO)?_c(_setup.CreativePreviewContextSimulator,{attrs:{"creative-data":_vm.creativeData},on:{"modify-url-params":function($event){return _setup.emit('modify-url-params', ...arguments)},"refresh-creative":function($event){return _setup.emit('refresh-creative')}}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }