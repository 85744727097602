import _ from 'lodash'
import Utils from '@/helpers/Utils'
import axios from 'axios'
import { ConsoleLogger } from '@aws-amplify/core'
// axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_X_API_KEY // for all requests

// var ax = axios.create({
//     baseURL: process.env.VUE_APP_BACKEND_ENDPOINT,
//     headers: {
//         'x-api-key': process.env.VUE_APP_X_API_KEY
//     }
// })

const xhr = {
  get: (url, params) => {
    return new Promise((resolve, reject) => {
      const rq = new XMLHttpRequest()

      rq.onreadystatechange = function (vm) {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (this.status === 200) {
            resolve({
              data: JSON.parse(this.responseText)
            })
          } else {
            reject(this.status)
          }
        }
      }.bind(rq, this)

      rq.open('GET', url)
      rq.send(params)
    })
  },
  post: (url, params) => {
    return new Promise((resolve, reject) => {
      const rq = new XMLHttpRequest()

      rq.onreadystatechange = function (vm) {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (this.status === 200) {
            resolve({
              data: JSON.parse(this.responseText)
            })
          } else {
            reject(this.status)
          }
        }
      }.bind(rq, this)

      rq.open('POST', url)
      rq.send(params)
    })
  }
}

const Api = function () {
  /**
     * returns list of accounts
     */
  this.getUsersAccounts = () => {
    return new Promise((resolve, reject) => {
      resolve(['PadSquad'])
    })
  }
  /**
     * returns list of user statuses
     */
  this.getUsersStatus = () => {
    return new Promise((resolve, reject) => {
      resolve(['Active', 'Suspended'])
    })
  }
  /**
     * returns list of roles
     */
  this.getUsersRoles = () => {
    return new Promise((resolve, reject) => {
      resolve(['Admin', 'Marketing', 'Sales', 'Reporting Only'])
    })
  }
  /**
     * returns data from a user
     * @param {number} userId - User ID
     */
  this.getUser = (userId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_USER_BY_ID}${userId}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns the list of all users from incognito
     */
  this.getUsersList = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_USER_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * creates a new user
     * @param {object} userData - user data to be stored.
     */
  this.createUser = userData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_CREATE}`, userData)
        .then(response => {
          if (response.data.status == 'ok') {
            resolve(userData)
          } else {
            reject(response.data.data)
          }
        })
        .catch(reject)
    })
  }
  /**
     * creates a new user
     * @param {object} userData - user data to be stored.
     */
  this.deleteUser = userData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_DELETE}`, {
        id: userData.id
      })
        .then(response => {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
            // resolve(userData);
          } else {
            reject(response.data.data)
          }
        })
        .catch(reject)
    })
  }
  /**
     * updates a users info
     * @param {object} userData - user updated data to be stored.
     */
  this.updateUser = userData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_USER_UPDATE}`, userData)
        .then(response => {
          if (response.data.status == 'ok') {
            resolve(response.data.data)
            // resolve(userData);
          } else {
            reject(response.data.data)
          }
        })
        .catch(reject)
    })
  }
  /**
     * returns all list of filter metadata
     */
  this.getAllLists = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_ALL_LISTS}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns list of formats
     */
  this.getCreativesFormats = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_CREATIVE_FORMAT_LIST}`)
        .then(response => {
          // console.log('Creative Formats', response.data);
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * create formats
     */
  this.createCreativeFormat = data => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_CREATIVE_FORMAT_CREATE}`, data)
        .then(response => {
          // console.log('Creative Format', response.data);
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * update formats
     */
  this.updateCreativeFormat = data => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_CREATIVE_FORMAT_UPDATE}`, data)
        .then(response => {
          // console.log('Creative Formats', response.data);
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns list of kpis
     */
  this.getMainKPIs = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_KPI_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns list of partner create
     */
  this.createPartner = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_PARTNER_CREATE}`, data)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns list of partners
     */
  this.getPartners = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_PARTNER_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns data from a creative
     * @param {number} creativeId - User ID
     */
  this.getCreative = (creativeId, includeAssets, includeLists) => {
    return new Promise((resolve, reject) => {
      let requestsCompleted = 0
      let expectedRequests = 1

      // if we also want to request lists, then make it 3 expected requests
      if (includeAssets === true) expectedRequests++
      if (includeLists === true) expectedRequests++

      let creativeData = {}

      axios.get(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_BY_ID}${creativeId}`)
        .then(response => {
          creativeData = _.merge(creativeData, response.data.data)
          next()
        })
        .catch(reject)

      if (includeAssets === true) {
        axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_ASSETS}`, {
          id: creativeId
        })
          .then(response => {
            const assets = response.data.data
            const designImages = _.filter(assets, asset => { return asset.asset_type == 'design' })
            const assetFiles = _.filter(assets, asset => { return asset.asset_type != 'design' })

            creativeData.designImages = _.sortBy(designImages, ['order'])
            creativeData.assetFiles = assetFiles

            next()
          })
          .catch(reject)
      }

      // if we need the lists
      if (includeLists === true) {
        // let's fetch the
        axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_LISTS_IN}`, {
          id: creativeId
        })
          .then(response => {
            const lists = response.data.data
            // add the lists to the response
            creativeData.lists = lists.lists

            next()
          })
        // .catch(reject);
          .catch(error => {
            creativeData.lists = []

            next()
          })
      }

      var next = function () {
        requestsCompleted++
        if (requestsCompleted == expectedRequests) resolve(creativeData)
      }
    })
  }
  /**
     * returns data from a creative
     * @param {number} creativeId - User ID
     */
  this.getCreativeAssets = (creativeId) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_ASSETS}`, {
        id: creativeId
      })
        .then(response => {
          // creative data comes inside an array
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns the list of all creatives from api
     */
  this.getCreativesList = (queryString = '') => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_LIST}?${queryString}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * creates a new creative
     * @param {object} creativeData - creative data to be stored.
     */
  this.createCreative = creativeData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_CREATE}`, creativeData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * creates a new user
     * @param {object} creativeData - creative data to be deleted.
     */
  this.deleteCreative = creativeData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_DELETE}`, creativeData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * updates a creative
     * @param {object} creativeData - creative data to be stored.
     */
  this.updateCreative = creativeData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_UPDATE}`, creativeData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * updates a relationship between
     * @param {object} creativeId - creative id
     */
  this.getCreativeLists = creativeId => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_LISTS_IN}`, {
        id: creativeId
      })
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * updates a relationship between
     * @param {object} creativeLists - creative data including lists
     */
  this.updateCreativeLists = creativeLists => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_UPDATE_LISTS}`, creativeLists)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns quicksight url from api
     */
  this.getQuicksightUrl = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_SHOWCASE_GET_QUICKSIGHT}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns data from a creative
     * @param {number} listId - User ID
     * @param {boolean} includeCreatives - Also, bring creatives on this list
     */
  this.getList = (listId, includeCreatives) => {
    let listData = {}

    return new Promise((resolve, reject) => {
      let requestsCompleted = 0
      let expectedRequests = 1

      // if we also want to request lists, then make it 3 expected requests
      if (includeCreatives === true) expectedRequests++

      axios.get(`${process.env.VUE_APP_ENDPOINT_LIST_BY_ID}${listId}`)
        .then(response => {
          listData = _.merge(listData, response.data.data[0])
          next()
        })
        .catch(reject)

      if (includeCreatives === true) {
        // let's fetch the
        axios.post(`${process.env.VUE_APP_ENDPOINT_LIST_CREATIVE_IN}`, {
          id: listId
        })
          .then(response => {
            const creatives = response.data.data
            // add the lists to the response
            listData.creatives = creatives.creatives

            next()
          })
        // .catch(reject);
          .catch(error => {
            listData.creatives = []

            next()
          })
      }

      var next = function () {
        requestsCompleted++
        if (requestsCompleted == expectedRequests) resolve(listData)
      }
    })
  }
  /**
     * returns the list of all lists from api
     */
  this.getListsList = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_LIST_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * creates a new list
     * @param {object} listData - list data to be stored.
     */
  this.createList = listData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_LIST_CREATE}`, listData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * creates a new user
     * @param {object} listId - list id
     */
  this.deleteList = listData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_LIST_DELETE}${listData.id}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * updates a creative
     * @param {object} listData - list data to be stored.
     */
  this.updateList = listData => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_LIST_UPDATE}`, listData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }

  /**
     * updates a relationship between
     * @param {object} creativeLists - creative data including lists
     */
  this.updateListCreatives = listCreatives => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_LIST_UPDATE_LIST_CREATIVE}`, listCreatives)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * uploads a file
     * @param {String} base64File - Base 64 string of the file to be uploaded
     */
  this.uploadFile = (fileData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_ASSET_CREATE}`, fileData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /**
     * uploads a file
     * @param {number} assetId - id of the asset to be deleted
     */
  this.deleteFile = assetId => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_ASSET_DELETE}`, {
        id: assetId
      })
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /**
     * returns list of verticals
     */
  this.getVerticals = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_VERTICAL_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns list of deal types
     */
  this.getDealTypes = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_DEAL_TYPE_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }

  /**
     * returns list of verticals
     */
  this.getCreativesTypes = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_CREATIVE_TYPE_LIST}`)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }
  /**
     * returns lisst of list visibilities
     */
  this.getListsVisibilities = () => {
    return new Promise((resolve, reject) => {
      resolve([
        'Public',
        'Passkey'
      ])
    })
  }

  // Creative Preview Link
  /**
     * returns data from a creative
     * @param {number} creativeId - Creative ID
     */
  this.getCreativePreviewById = (creativeId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_ENDPOINT_PREVIEW_LINK}${creativeId}`)
        .then(response => {
          // console.log('Creaative Preview By ID', response.data);
          resolve(response.data.data)
        })
        .catch(error => {
          // console.error('Creaative Preview By ID', error);
          reject(error)
        })
    })
  }

  /**
     * uploads a gwd file
     * @param {String} base64File - Base 64 string of the file to be uploaded
     */
  this.uploadGwdFile = (fileData) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_GWD_UPLOADS_CREATE}`, fileData)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /**
     * uploads a gwd file
     * @param {number} gwdFileId - id of the asset to be deleted
     */
  this.deleteGwdFile = fileId => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_ENDPOINT_GWD_UPLOADS_DELETE}`, {
        id: fileId
      })
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
     * returns list of verticals
     */
}

export default new Api()
