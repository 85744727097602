export default new function () {
  /**
   * Returns an object with each column as property and each property containing a an array with the different variations of the value of each column from the data
   * @param {Array|Object} collection - data to be filtered
   * @param {Array} columns - the data to show on the filter
   */
  this.pickFilterOptions = (collection, columns) => {
    const filterData = []

    // populate filter options. This is a hack.
    // 1. Let's use _.countBy to generate an object with as many keys as different values of a property on each key of the original object.
    // 2. Make an array out of each key of the generated object.
    // 3. Repeat this for each filter key in the filterModuleData.filters object.

    // step3
    _.forOwn(columns, columnName => {
      // rule out items on the collection that have the property we are counting
      const validItemsOnCollection = _.filter(collection, item => item[columnName])

      // steps 1 and 2
      filterData[columnName] = Object.getOwnPropertyNames(_.countBy(validItemsOnCollection, item => {
        return item[columnName] // trim the value to avoid duplicates due to spaces at the end of the name.
      }))

      // order filterData[columnName] in ascending order
      filterData[columnName] = _.sortBy(filterData[columnName])
    })

    return filterData
  }

  /**
   * returns items which params match values from filterProps
   * @param {Array|Object} collection - List of items. e.g. [{itemParam1: value}, {itemParam2: value}]
   * @param {object} filterProps - An object which params represent props of each item in the collection and values will be used to filter each param. e.g. {collectionItemParam1: value, collectionItemParam2: value}
   */
  this.getFilteredItems = (collection, filterProps) => {
    const self = this

    // filter items as per the filterProps
    const filteredItems = _.filter(collection, (value, key) => {
      return _.isMatch(value, filterProps)
    })

    return filteredItems
  }

  /**
   * Returns items from a collection which any of it's params contain a String
   * @param {array|object} collection - List of items. e.g. [{itemParam1: value}, {itemParam2: value}]
   * @param {string} text - Text to match
   */
  this.searchItemsByText = (collection, text, exclude) => {
    text = _.toLower(text)
    return _.filter(collection, function (object) {
      return _(object).omit(exclude).some(function (string) {
        return _(string).toLower().includes(text)
      })
    })
  }

  /**
   * Returns paginated items
   * @param {array|object} collection - List of items. e.g. [{itemParam1: value}, {itemParam2: value}]
   * @param {number} currentPage - Page number. 0 based
   * @param {number} itemsPerPage - Number of items per page
   */
  this.getPaginatedItems = (collection, currentPage, itemsPerPage) => {
    const self = this

    // starting point of the items list
    const offset = (currentPage - 1) * itemsPerPage

    // return the items within the range of the pagination
    const paginatedItems = _.drop(collection, offset).slice(0, itemsPerPage)

    return paginatedItems
  }
  /**
   * Returns which quarter of the year a mont fall onto.
   * @param {array|object} month Zore-based month.
   */
  this.getQuarterFromMonth = month => {
    const m = Math.floor((month / 3) + 1)
    return m > 4 ? m - 4 : m
  }

  /**
   * Returns a toLocalString style number.
   * @param {array|object} month Zore-based month.
   */
  this.numberWithCommas = (x) => {
    const parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  }

  /**
   * Returns a boolean valid email
   * @param {string} value.
   */
  this.validateEmail = (value) => {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value))
  }

  /**
   * Returns a boolean valid url
   * @param {string} value.
   */
  this.validateURL = (value) => {
    // return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g.test(String(value).toLowerCase())
  }
  /**
   * Returns a reg ex validation
   * @param {string} value.
   */
  this.getRegExUrlValidation = () => {
    return '/^http:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/'
  }

  /**
   * Returns a toLocalString style number.
   * @param {File} file File to be be converte
   */
  this.toBase64 = (file, getUTF8Encoded = false) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        try {
          const binaryData = e.target.result

          // Converting Binary Data to base 64
          const base64String = btoa(binaryData)
          const base64StringFull = 'data:' + file.type + ';base64,' + base64String
          if (getUTF8Encoded) resolve(this.b64EncodeUnicode(binaryData))
          else resolve(base64StringFull)
        } catch (error) {
          reject(error)
        }
      }

      // Read in the image file as a data URL.
      reader.readAsBinaryString(file)
    })
  }

  // Encoding UTF8 ⇢ base64
  this.b64EncodeUnicode = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  // Decoding base64 ⇢ UTF8
  this.b64DecodeUnicode = (str) => {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  /**
   * Returns a toLocalString style number.
   * @param {File} file File to be be converte
   */
  this.toBinaryData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        try {
          const binaryData = e.target.result

          resolve(binaryData)
        } catch (error) {
          reject(error)
        }
      }

      // Read in the image file as a data URL.
      reader.readAsBinaryString(file)
    })
  }

  /**
   * Copies a provided string to the clipboard
   */
  this.copyToClipboard = function (string, includeDomain = true) {
    const urlToClipboard = (includeDomain == true) ? document.domain + string : string

    const el = document.createElement('textarea')
    el.value = urlToClipboard
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    if (selected) {
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(selected)
    }
  }

  this.downloadTXT = function (filename, text) {
    const today = new Date()

    filename += '-' + (today.getMonth() + 1) + today.getDate() + today.getFullYear()

    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  this.downloadCSV = function (filename, text) {
    const today = new Date()

    filename += '-' + (today.getMonth() + 1) + today.getDate() + today.getFullYear() + '.csv'

    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  /**
  * Validating current Region
  */
  this.validateRegion = function (region) {
    return region === "us-east-1";
  }

  /**
   * Serialize query params
   * @param {Object} queryParams - Object containing key/value pairs of query params (eg, {page: 3, pageSize: 10})
   * @param {Boolean} queryPrefix - Boolean allowing for prefix of "?" returned with serialized string
   */
  this.serialize = function(queryParams, queryPrefix=false) {
    try {
      const query = Object.entries(queryParams)
        .filter(([key, val]) => val || val === 0)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
      const prefix = queryPrefix ? '?' : ''
      return `${prefix}${query}`
    } catch (e) {
      return ''
    }
  }
}()
