<script setup>
import { computed } from 'vue'

const props = defineProps({
  previewSettings: {
    type: Object,
    required: true
  },
  shellSettings: {
    type: Object,
    required: false
  }
})

const sizeName = computed(() => props.previewSettings.mobileSize)
const landscape = computed(() => props.previewSettings.landscape)

const screenBgColor = computed(() => {
  return props.shellSettings?.mobileScreenBgColor || '#F0F0F0'
})
</script>

<template>
  <div
    class="mobile-shell d-flex"
    :class="[
      `${sizeName}__shell`,
      landscape ? `justify-content-end align-items-center ${sizeName}__shell__landscape` : 'justify-content-center align-items-end'
    ]"
  >
    <div
      class="mobile-screen d-flex overflow-hidden flex-column"
      :class="[
        `${sizeName}__screen`,
        landscape ? `${sizeName}__screen__landscape` : ''
      ]"
      :style="`background-color: ${screenBgColor};`"
    >
    <div
      class="mobile-top-bar"
      :class="[
        `${sizeName}__top-bar`,
        landscape ? `${sizeName}__top-bar__landscape mobile-top-bar__landscape` : ''
      ]"
    />
      <div class="flex-grow-1 d-flex overflow-hidden">
        <div
          v-if="landscape && (sizeName === 'medium' || sizeName === 'large')"
          class="left-gap"
          :class="`${sizeName}__gap`"
        />
        <slot name="iFrameContent">empty</slot>
        <div
          v-if="landscape"
          class="right-gap"
          :class="`${sizeName}__gap`"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mobile-shell {
  border-radius: 50px;
  border: 1px solid #CACACA;
  background-color: #fff;
}
.mobile-screen {
  border: 1px solid #CACACA;
}
.mobile-top-bar {
  background-color: #fff;
  border-bottom: 1px solid #CACACA;

  &__landscape {
    padding-right: 20px;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.right-gap {
  height: 100%;
  background-color: #fff;
}
.left-gap {
  height: 100%;
  background-color: #fff;
}
/* XS */
.xsmall {
  &__shell {
    height: 648px;
    width: 360px;
    padding: 20px;

    &__landscape {
      height: 360px;
      width: 648px;
    }
  }

  &__screen {
    height: 568px;
    width: 320px;
    border-radius: 0px 0px 50px 50px;

    &__landscape {
      height: 320px;
      width: 568px;
      border-radius: 0px 50px 50px 0px;
    }
  }

  &__top-bar {
    height: 20px;
    width: 100%;
  }

  &__gap {
    width: 35px;
  }
}
/* S */
.small {
  &__shell {
    height: 767px;
    width: 415px;
    padding: 20px;

    &__landscape {
      height: 415px;
      width: 767px;
    }
  }

  &__screen {
    height: 647px;
    width: 375px;
    border-radius: 0px 0px 50px 50px;

    &__landscape {
      height: 375px;
      width: 647px;
      border-radius: 0px 50px 50px 0px;
    }
  }

  &__top-bar {
    height: 20px;
    width: 100%;
  }

  &__gap {
    width: 44px;
  }
}
/* M */
.medium {
  &__shell {
    height: 876px;
    width: 422px;
    padding: 16px;

    &__landscape {
      height: 422px;
      width: 876px;
    }
  }

  &__screen {
    height: 100%;
    width: 390px;
    border-radius: 50px;
    overflow: hidden;

    &__landscape {
      height: 390px;
      width: 100%;
    }
  }

  &__top-bar {
    height: 47px;
    width: 100%;
  }

  &__gap {
    width: 55px;
  }
}
/* L */
.large {
  &__shell {
    height: 958px;
    width: 460px;
    padding: 16px;

    &__landscape {
      height: 460px;
      width: 958px;
    }
  }

  &__screen {
    height: 100%;
    width: 428px;
    border-radius: 50px;
    overflow: hidden;

    &__landscape {
      height: 428px;
      width: 100%;
    }
  }

  &__top-bar {
    height: 47px;
    width: 100%;
  }

  &__gap {
    width: 63px;
  }
}
</style>
