import _ from 'lodash'
import Api from '@/helpers/ApiTagManager'
import ApiCreativeProduction from '@/helpers/ApiCreativeProduction'

/** VUEX module for placements in Tag Manager section **/

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    placements: [],
    celtraPlacements: [],
    campaign: {},
    selectedPlacement: null
  },
  // -----------------------------------------------------------------
  getters: {
    list: (state, getters, rootState, rootGetters) => {
      if (!state.placements || state.placements.length == 0) return []
      // else {
      const includingMappedData = state.placements.map((item, index) => {
        const itemRef = item

        // itemRef.advertiser = "";//item.advertiser.name;
        // map taxonomies into the item
        // itemRef.format_id = _.get(rootGetters['creativeFormats'], `[${itemRef.format_id}].name`, {});
        itemRef.tagSource = item.tag_source
        // itemRef.campaignSource = item.campaign_source.name;
        itemRef.touched_on = (itemRef.modified_date != '' && itemRef.modified_date != '0000-00-00 00:00:00' && itemRef.modified_date != null) ? itemRef.modified_date : itemRef.created_date

        return itemRef
      })

      return includingMappedData
      // }
    },
    listByCampaignId: (state, getters, rootState, rootGetters) => (campaignId) => {
      const filteredList = _.filter(getters.list, (item) => {
        return item.campaign_id === parseInt(campaignId)
      })

      return filteredList
    },
    listCeltra: (state, getters, rootState, rootGetters) => {
      const includingMappedData = state.celtraPlacements.map(item => {
        const itemRef = item

        // itemRef.advertiser = "";//item.advertiser.name;

        // map taxonomies into the item
        itemRef.tagSource = itemRef.tag_source
        itemRef.format_id = _.get(rootGetters.creativeFormats, `[${itemRef.padsquad_product}].id`, {})
        itemRef.touched_on = itemRef.modified_date || itemRef.created_date

        // add a space avoiding the ad to render undefined
        itemRef.append_script = ' '

        return itemRef
      })

      return includingMappedData
    },
    itemById: (state, getters) => id => {
      const item = getters.list.find(item => item.id == id)
      return item
    },
    itemByName: (state, getters) => name => {
      const item = getters.list.find(item => item.name == name)
      return item
    },
    selectedCampaign: (state) => {
      return state.campaign
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET: (state, payload) => {
      state.placements = payload
    },
    SET_CELTRA: (state, payload) => {
      state.celtraPlacements = payload
    },
    SYNC_CELTRA_TO_LIST: (state, payload) => {
      state.placements = payload.placements.map(item => {
        const itemRef = item
        itemRef.tag_source = 'Celtra Sync'
        itemRef.placement_tag_source = 'Celtra Sync'
        itemRef.creative_tag_id = 1
        itemRef.external_placement_id = itemRef.celtra_placement_id = item.placement_id

        return itemRef
      })
    },
    CREATE: (state, payload) => {
      state.placements.push(payload)
    },
    UPDATE: (state, payload) => {
      const item = state.placements.find(item => item.id === payload.id)
      Object.assign(item, payload)
    },
    CREATE_BULK: (state, payloadArray) => {
      if (payloadArray.length > 0) {
        payloadArray.placements.map(payload => {
          state.placements.push(payload)
        })
      }
    },
    UPDATE_BULK: (state, payloadArray) => {
      // console.log('UPDATE BULK', payloadArray);
      if (payloadArray.length > 0) {
        payloadArray.placements.map(payload => {
          const item = state.placements.find(item => item.id === payload.id)
          Object.assign(item, payload)
        })
      }
    },
    DELETE: (state, id) => {
      const index = _.findIndex(state.placements, {
        id
      })

      state.placements.splice(index, 1)
    },
    ADD_TRACKERS: (state, payload) => {
      const index = _.findIndex(state.placements, {
        id: payload.id
      })

      state.placements[index].trackers = payload.trackers
    },
    ADD_OPTION_TRACKERS: (state, payload) => {
      const index = _.findIndex(state.placements, {
        id: payload.id
      })

      state.placements[index].trackers = payload.trackers
    },
    REMOVE_TRACKERS: (state, payload) => {
      const index = _.findIndex(state.placements, {
        id: payload.id
      })

      state.placements.trackers.splice(index, 1)
    }
  },
  // -----------------------------------------------------------------
  actions: {
    fetchAll: (context) => {
      // app status loading
      context.dispatch('registerTask', 'fetchPlacements', { root: true })

      return new Promise((resolve, reject) => {
        Api.getAllPlacementsList()
          .then(data => context.commit('SET', data))
          .then(data => {
            context.dispatch('completeTask', 'fetchPlacements', { root: true })
            resolve(data)
          })
          .catch(error => {
            context.dispatch('completeTask', 'fetchPlacements', { root: true })

            reject(error)
          })
      })
    },
    setPlacements: (context, placements) => {
      // app status loading
      context.dispatch('registerTask', 'setPlacements', { root: true })

      return new Promise((resolve, reject) => {
        context.commit('SET', placements)
        context.dispatch('completeTask', 'setPlacements', { root: true })

        resolve(placements)
      })
    },
    fetch: (context, campaignId) => {
      // app status loading
      context.dispatch('registerTask', 'fetchPlacements', { root: true })

      return new Promise((resolve, reject) => {
        Api.getPlacementsList(campaignId)
          .then(data => context.commit('SET', data))
          .then(data => {
            context.dispatch('completeTask', 'fetchPlacements', { root: true })
            resolve(data)
          })
          .catch(error => {
            context.dispatch('completeTask', 'fetchPlacements', { root: true })

            reject(error)
          })
      })
    },
    fetchWithOutTrackers: (context, campaignId) => {
      // app status loading
      context.dispatch('registerTask', 'fetchWithOutTrackers', { root: true })

      return new Promise((resolve, reject) => {
        Api.getPlacementCampaignById(campaignId)
        /* .then(data => context.commit('SET', data)) */
          .then(data => {
            context.dispatch('completeTask', 'fetchWithOutTrackers', { root: true })
            resolve(data)
          })
          .catch(error => {
            context.dispatch('completeTask', 'fetchWithOutTrackers', { root: true })

            reject(error)
          })
      })
    },
    fetchTrackersByPlacementList: (context, placementlistid) => {
      // app status loading
      context.dispatch('registerTask', 'fetchTrackersByPlacementList', { root: true })
      // console.log('placementlistid',placementlistid)
      return new Promise((resolve, reject) => {
        Api.getTrackersListByPlacement(placementlistid)
        /* .then(data => context.commit('SET', data)) */
          .then(data => {
            context.dispatch('completeTask', 'fetchTrackersByPlacementList', { root: true })
            resolve(data)
          })
          .catch(error => {
            context.dispatch('completeTask', 'fetchTrackersByPlacementList', { root: true })

            reject(error)
          })
      })
    },
    fetchCeltra: (context, campaignCeltraId) => {
      // app status loading
      context.dispatch('registerTask', 'fetchCeltraPlacements', { root: true })

      return new Promise((resolve, reject) => {
        Api.getPlacementsListCeltra(campaignCeltraId)
          .then(data => context.commit('SET_CELTRA', data))
          .then(data => {
            context.dispatch('completeTask', 'fetchceltraPlacements', { root: true })
            resolve(data)
          })
          .catch(error => {
            context.dispatch('completeTask', 'fetchceltraPlacements', { root: true })

            reject(error)
          })
      })
    },
    syncCeltraToList: ({ dispatch, commit, getters, rootGetters }, payload) => {
      const placementsUpdateBulk = []
      const placementsCreateBulk = []
      const promises = payload.placements.map(item => {
        const itemRef = item
        itemRef.campaign_id = parseInt(payload.campaignId)
        itemRef.external_placement_id = itemRef.placement_id
        itemRef.placement_tag_source = 'Celtra Sync'
        itemRef.creative_tag_id = 1
        itemRef.creative_id = item.creative_id || 1
        itemRef.celtra_placement_id = item.placement_id

        itemRef.append_script = item.append_script.replace('\n', ' ')

        const foundCreativeFormat = _.find(rootGetters.creativeFormats, { name: itemRef.padsquad_product })

        itemRef.format_id = _.get(foundCreativeFormat, 'id', '')

        if (item.status == 'new') {
          // return dispatch('create', itemRef);
          placementsCreateBulk.push(itemRef)
        }

        if (item.status == 'update') {
          const itemToUpdate = getters.itemByName(itemRef.name)
          // preserve append script
          itemRef.append_script = itemToUpdate.append_script.replace('\n', ' ')

          if (itemRef.padsquad_product == '') {
            // preserve format_id
            itemRef.format_id = itemToUpdate.format_id
          }

          // return dispatch('update', itemRef);
          placementsUpdateBulk.push(itemRef)
        }
      })

      // bulk create
      if (placementsCreateBulk.length > 0) {
        promises.push(dispatch('createBulk', { campaign_id: payload.campaignId, placements: placementsCreateBulk }))
      }

      // bulk update
      if (placementsUpdateBulk.length > 0) {
        promises.push(dispatch('createBulk', { campaign_id: payload.campaignId, placements: placementsUpdateBulk }))
      }

      Promise.all(promises)
        .then(response => {
          // commit('SYNC_CELTRA_TO_LIST', payload);

        })
        .catch(error => {
        })
    },
    model: (context, placementData) => {
      return new Promise((resolve, reject) => {
        try {
          const placementDataModel = [
            'name',
            'placement_source',
            // "tag_source",
            'format_id',
            // "external_placement_id",
            'main_kpi',
            'append_script',
            // "third_party_id",
            // "verification_third_party_id",
            // "trackers",
            // campaign
            'campaign_id',
            'external_campaign_id',
            'campaign_name',
            'start_date',
            'end_date',
            'goal',
            'publisher_name',
            // creative
            'creative_id',
            'external_creative_id',
            'creative_tag',
            'assigned_packages',
            'use_fallback_clicktrough_url',
            'fallback_clicktrough_url',
            'xandr_curated_deal_id',
            'width',
            'height'
          ]

          if (placementData.third_party_id !== '' && placementData.third_party_id !== 'null') placementDataModel.push('third_party_id')
          if (placementData.verification_third_party_id !== '' && placementData.verification_third_party_id !== 'null') placementDataModel.push('verification_third_party_id')
          if (placementData.external_placement_id !== null && placementData.external_placement_id !== '') placementDataModel.push('external_placement_id')
          if (placementData.publisher_name === '') placementDataModel.publisher_name = 'PadSquad'

          // if this is an existent placement...
          if (placementData.id) {
            // ... add the id to the model
            placementDataModel.push('id')
            // placementDataModel.push("modified_by");
          } else {
            // placementDataModel.push("created_by");
          }

          // SEND TRACKERS AND OPTIONS
          if (placementData.id && placementData.placement_source === 'Airtory Sync') placementDataModel.push('trackers')
          if (placementData.id && placementData.trackers && placementData.trackers.length > 0 && placementData.placement_source === 'PadSquad') placementDataModel.push('trackers')

          // pick the values in the model from our placementDataModel
          const placementDataModeled = _.pick(placementData, placementDataModel)

          resolve(placementDataModeled)
        } catch (error) {
          reject(error)
        }
      })
    },
    modelBulk: (context, placementDataArray) => {
      return new Promise((resolve, reject) => {
        try {
          const placementDataModeledArray = {
            campaign_id: placementDataArray.campaign_id,
            placements: []
          }

          const placementDataModel = [
            'name',
            'placement_source',
            // "tag_source",
            'format_id',
            // "external_placement_id",
            'main_kpi',
            'append_script',
            // "third_party_id",
            // "verification_third_party_id",
            // "trackers",
            // campaign
            'campaign_id',
            'external_campaign_id',
            'campaign_name',
            'start_date',
            'end_date',
            'goal',
            'publisher_name',
            // creative
            'creative_id',
            'external_creative_id',
            'creative_tag',
            'creative_name',
            'status_source',
            'updateSource',
            'assigned_packages',
            'use_fallback_clicktrough_url',
            'fallback_clicktrough_url',
            'xandr_curated_deal_id',
            'width',
            'height'
          ]

          placementDataArray.placements.map(placementData => {
            // if this is an existent placement...
            if (placementData.id) {
              // ... add the id to the model
              placementDataModel.push('id')
            }

            if (placementData.placement_source === 'Airtory Sync' || placementData.placement_source === 'PadSquad' || placementData.trackers !== undefined && placementData.placement_source === 'Celtra Sync') placementDataModel.push('trackers')

            if (placementData.third_party_id !== '' && placementData.third_party_id !== null) placementDataModel.push('third_party_id')
            if (placementData.verification_third_party_id !== '' && placementData.verification_third_party_id !== null) placementDataModel.push('verification_third_party_id')
            if (placementData.external_placement_id !== null && placementData.external_placement_id !== '') placementDataModel.push('external_placement_id')
            if (placementData.placement_source === 'PadSquad') {placementData.external_placement_id = 'null'; placementData.external_campaign_id = 'null'; placementData.external_creative_id = 'null'; placementDataModel.push('external_placement_id')}
            if (placementData.publisher_name === '') placementDataModel.publisher_name = 'PadSquad'

            // pick the values in the model from our placementDataModel
            const placementDataModeled = _.pick(placementData, placementDataModel)

            placementDataModeledArray.placements.push(placementDataModeled)
          })

          resolve(placementDataModeledArray)
        } catch (error) {
          reject(error)
        }
      })
    },
    create: (context, placementData) => {
      placementData.creative_id = placementData.creative_id || 1

      // app status loading
      context.dispatch('registerTask', 'createPlacement', { root: true })

      return new Promise((resolve, reject) => {
        context.dispatch('model', placementData)
          .then(placementDataModeled => {
            return placementDataModeled
          })
          .then(placementDataModeled => Api.createPlacement(placementDataModeled))
          .then(PlacementDataFromServer => {
            // app status: ready
            context.commit('CREATE', PlacementDataFromServer)
            context.dispatch('completeTask', 'createPlacement', { root: true })
            resolve(placementData)
          })
          .catch(error => {
            // app status: ready
            context.dispatch('completeTask', 'createPlacement', { root: true })

            reject(error)
          })
      })
    },
    read: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'readPlacement', {
        root: true
      })

      return new Promise((resolve, reject) => {
        Api.getPlacementById(id)
          .then(data => {
            // app status: ready
            context.dispatch('completeTask', 'readPlacement', { root: true })

            resolve(data)
          })
          .catch(error => {
            // app status: ready
            context.dispatch('completeTask', 'readPlacement', { root: true })

            reject(error)
          })
      })
    },
    update: (context, placementData) => {
      placementData.creative_id = placementData.creative_id || 1

      // app status: loading
      context.dispatch('registerTask', 'updatePlacement', { root: true })
      return new Promise((resolve, reject) => {
        context.dispatch('model', placementData)
          .then(placementDataModeled => {
            return placementDataModeled
          })
          .then(placementDataModeled => Api.updatePlacement(placementDataModeled))
          .then(PlacementDataFromServer => {
            // app status: ready
            context.commit('UPDATE', PlacementDataFromServer)
            context.dispatch('completeTask', 'updatePlacement', { root: true })
            resolve(PlacementDataFromServer)
          })
          .catch(error => {
            // app status: ready
            context.dispatch('completeTask', 'updatePlacement', { root: true })

            reject(error)
          })
      })
    },
    createBulk: (context, placementDataArray) => {
      // app status: loading
      context.dispatch('registerTask', 'createPlacementBulk', { root: true })

      return new Promise((resolve, reject) => {
        context.dispatch('modelBulk', placementDataArray)
          .then(placementDataModeledArray => {
            return placementDataModeledArray
          })
          .then(placementDataModeledArray => Api.createPlacementBulk(placementDataModeledArray))
          .then(PlacementDataFromServer => {
            // app status: ready
            context.commit('CREATE_BULK', PlacementDataFromServer)
            context.dispatch('completeTask', 'createPlacementBulk', { root: true })
            resolve(PlacementDataFromServer)
          })
          .catch(error => {
            // app status: ready
            context.dispatch('completeTask', 'createPlacementBulk', { root: true })

            reject(error)
          })
      })
    },
    updateBulk: (context, placementDataArray) => {
      // app status: loading
      context.dispatch('registerTask', 'updatePlacementBulk', { root: true })

      return new Promise((resolve, reject) => {
        context.dispatch('modelBulk', placementDataArray)
          .then(placementDataModeledArray => {
            return placementDataModeledArray
          })
          .then(placementDataModeledArray => Api.updatePlacementBulk(placementDataModeledArray))
          .then(PlacementDataFromServer => {
            // app status: ready
            context.commit('UPDATE_BULK', PlacementDataFromServer)
            context.dispatch('completeTask', 'updatePlacementBulk', { root: true })
            resolve(PlacementDataFromServer)
          })
          .catch(error => {
            // app status: ready
            context.dispatch('completeTask', 'updatePlacementBulk', { root: true })
            console.log(error)
            reject(error)
          })
      })
    },
    delete: (context, id) => {
      context.dispatch('registerTask', 'deletePLacement', { root: true })

      return new Promise((resolve, reject) => {
        Api.deletePlacement(id)
          .then(data => {
            context.commit('DELETE', id)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'deletePLacement', { root: true })
          })
      })
    },
    sync: (context, id) => {
      context.dispatch('registerTask', 'syncPlacement', {
        root: true
      })

      return new Promise((resolve, reject) => {
        Api.syncCampaign(id)
          .then(data => {
            // Determine if we need to update the placement here, because we are prompting the user to SAVE PLACEMENT after syncing
            // context.commit('UPDATE', data);
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'syncPlacement', { root: true })
          })
      })
    },

    /**
         * Duplicate placement
         */
    duplicate: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'duplicatePlacement', {
        root: true
      })

      return new Promise((resolve, reject) => {
        Api.duplicatePlacementById(id)
          .then(data => {
            // app status: ready
            context.dispatch('completeTask', 'duplicatePlacement', { root: true })

            resolve(data)
          })
          .catch(error => {
            // app status: ready
            context.dispatch('completeTask', 'duplicatePlacement', { root: true })

            reject(error)
          })
      })
    },

    fetchAirtoryPlacement: (context, id) => {
      // app status: loading
      context.dispatch('registerTask', 'fetchAirtoryPlacement', { root: true })

      return new Promise((resolve, reject) => {
        ApiCreativeProduction.fetchAirtoryPlacement(id)
          .then(data => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryPlacement', { root: true })
            resolve(data)
          })
          .catch(error => {
            // app status: ready
            context.dispatch('completeTask', 'fetchAirtoryPlacement', { root: true })

            reject(error)
          })
      })
    },
    addPlacementTrackers: (context, data) => {
      context.dispatch('registerTask', 'addPlacementTrackers', {
        root: true
      })

      const _trackers = data.trackers.map(item => {
        return _.pick(item, ['placement_id', 'creative_event_id', 'tracker_value', 'order'])
      })

      return new Promise((resolve, reject) => {
        Api.addPlacementTrackers(data.id, _trackers)
          .then(response => {
            // context.commit('ADD_TRACKERS', {id, trackers});
            // we need to receive all trackers
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'addPlacementTrackers', { root: true })
          })
      })
    },
    removePlacementTrackers: (context, data) => {
      context.dispatch('registerTask', 'removePlacementTrackers', {
        root: true
      })

      const _trackers = data.trackers.map(item => {
        return _.pick(item, ['id', 'placement_id', 'creative_event_id', 'tracker_value'])
      })

      return new Promise((resolve, reject) => {
        Api.removePlacementTrackers(data.id, _trackers)
          .then(response => {
            // context.commit('REMOVE_TRACKERS', {id, trackers});
            // we need to receive all trackers
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'removePlacementTrackers', { root: true })
          })
      })
    },
    updatePlacementTrackers: (context, data) => {
      context.dispatch('registerTask', 'updatePlacementTrackers', {
        root: true
      })

      const _trackers = data.trackers.map(item => {
        return _.pick(item, ['id', 'placement_id', 'creative_event_id', 'tracker_value'])
      })

      return new Promise((resolve, reject) => {
        Api.updatePlacementTrackers(data.id, _trackers)
          .then(response => {
            // we need to receive all trackers trackers
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'updatePlacementTrackers', { root: true })
          })
      })
    },
    addPlacementOptionTrackers: (context, data) => {
      context.dispatch('registerTask', 'addPlacementOptionTrackers', {
        root: true
      })

      const _trackersOptions = data.options.map(item => {
        return _.pick(item, ['tracker_id', 'active', 'creative_event_id', 'option_id', 'option_value'])
      })

      return new Promise((resolve, reject) => {
        if (!_trackersOptions[0].option_id) _trackersOptions[0].option_id = 1
        Api.addPlacementOptionTrackers(data.id, _trackersOptions[0])
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'addPlacementOptionTrackers', { root: true })
          })
      })
    },
    updatePlacementOptionTrackers: (context, data) => {
      context.dispatch('registerTask', 'updatePlacementOptionTrackers', {
        root: true
      })

      const _trackersOptions = data.options.map(item => {
        return _.pick(item, ['id', 'tracker_id', 'active', 'creative_event_id', 'option_id', 'option_value'])
      })

      return new Promise((resolve, reject) => {
        Api.updatePlacementOptionTrackers(data.id, _trackersOptions[0])
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
          .finally(() => {
            context.dispatch('completeTask', 'updatePlacementOptionTrackers', { root: true })
          })
      })
    }
  }

}
