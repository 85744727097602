<template>
  <section v-if="itemId != null" class="sModal">

    <Preloader :inModal="true"></Preloader>

    <header class="sModal__header">Manual 3P Data Upload</header>

    <form @submit.prevent="formSubmit">

      <div class="sModal__body" v-if="currentSection=='details'">

        <FormField label="Data Type" forId="data-type" type="select" >
          <select v-model="data_type" id="data-type">
            <option value="null" disabled>No Data Type</option>
            <option v-for="(type, index) in data_types" :value="type.id" :key="index">{{type.name}}</option>
          </select>
        </FormField>

        <FormField v-show="data_type !== null && data_type === 1" label="Measurement Partner" forId="campaign-measurement-partner" type="select" >
          <select v-model="measurement_partner_id" id="campaign-measurement-partner">
            <option value="null" disabled>No Measurement Partner</option>
            <option v-for="(partner, index) in partnersMeasurement" :value="partner.id" :key="index">{{partner.name}}</option>
          </select>
        </FormField>

        <FormField v-show="data_type !== null && data_type === 2" label="Verification Partner" forId="campaign-verification-partner" type="select" >
          <select v-model="verification_partner_id" id="campaign-verification-partner">
            <option value="null" disabled>No Verification Partner</option>
            <option v-for="(partner, index) in partnersVerification" :value="partner.id" :key="index">{{partner.name}}</option>
          </select>
        </FormField>

        <FormField label="Upload 3P Data"  description="" forId="manual-upload">
          <b-form-file class="button-file" v-model="assetUploader.file" @change="uploadAssets" accept=".csv, application/vnd.sealed.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :state="Boolean(assetUploader.file)" placeholder="Choose a file..."></b-form-file>
        </FormField>
        <small>The 3P Data is an excel template file with existing third party data information.</small>

      </div>

      <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" />Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <button :disabled="!canSaveData" class="saveButton">
            <font-awesome-icon icon="check" class="i" />Save 3p Data
          </button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import draggable from 'vuedraggable'
import Api from '@/helpers/ApiTagManager.js'
import Utils from '@/helpers/Utils.js'
import Preloader from '@/components/Preloader.vue'

// import Papa from 'papaparse';

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Manual3PData',
  components: {
    FormField, Preloader, draggable
  },
  props: {
    itemId: {
      type: [Number, String],
      default: null,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      listUploaded: [],
      assetUploader: {
        file: null
      },
      sections: [
        'details'
      ],
      campaign: null,
      assetFiles: [],
      base64File: null,
      currentSection: 'details',
      measurement_partner_id: null,
      verification_partner_id: null,
      data_type: null,
      data_types: [
        { id: 1, name: 'Third Party Measurement' },
        { id: 2, name: 'Third Party Verification' }
      ]
    }
  },
  methods: {
    ...mapActions([
      'registerTask',
      'completeTask'
    ]),
    ...mapActions('campaigns', [
      'fetchById',
      'create3PData'
    ]),
    formSubmit: function (e) {
      const self = this

      // turn on loading spinner
      self.loading = true

      const partner = (self.data_type === 1) ? this.partnersMeasurement.find(item => item.id === this.measurement_partner_id) : this.partnersVerification.find(item => item.id === this.verification_partner_id)

      const data = {
        campaign_id: self.campaign.id,
        data_type: self.data_type,
        partner_id: partner.id,
        asset: this.base64File // self.assetFiles[0]
      }

      self.create3PData({ csv: String(this.base64File).replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', ''), type: (process.env.VUE_APP_ENV === 'production') ? partner.folder_name : 'test_padsquad' })
        .then(response => {
          self.$log.info('3P Data created', data)
          alert('Data uploaded successfully.')

          // turn off loading spinner
          self.loading = false

          // emit event
          self.$emit('done')
        })
        .catch(error => {
          self.$log.info('Error 3P Data', error)
          alert('It appears that there was an error uploading the file. Please try again.')

          // turn off loading spinner
          self.loading = false
        })
    },
    saveData: function () {

    },
    cancel: function () {
      const self = this

      // emit event
      self.$emit('done')
    },
    uploadAssets: function (event) {
      const self = this
      self.assetFiles = []
      self.base64File = null

      // turn oon loading spinner
      // self.loading = true;

      // Files to be uploaded
      const files = event.target.files

      // amount of pending files.
      let filesQueue = files.length

      let errors = 0

      // iterate over the list of files to be uploaded.
      _.forOwn(files, file => {
        const fileNameSplit = file.name.split('.')

        // pass true to get UTF8 base64 encoded
        Utils.toBase64(file, true)
          .then(fileString => {
            // store the base 64 file for sending in the form submit data
            self.base64File = fileString

            // return {
            //   type: "asset",
            //   extension: `.${fileNameSplit[fileNameSplit.length-1]}`,
            //   fileData: fileString,
            //   fileName: `${file.name.replace('.'+fileNameSplit[fileNameSplit.length-1], '')}`
            // }
          })
          .then(base64File => {

            // store the base 64 file for sending in the form submit data
            // self.base64File = base64File;

            // send the file?
            // Api.upload3PDataFile
          })
          // .then(newFile => {
          //   // add the new file data to the assetFiles array
          //   self.assetFiles.push(newFile);

        //   // console.log('assets', self.assetFiles, file);

        //   // one less from the Queue
        //   filesQueue--;

        //   // if the queue is empty, let the user know.
        //   if( filesQueue == 0 ) {
        //     // turn off loading spinner
        //     self.loading = false;

          //     if( errors ) {
          //       alert('It appears that there are some files we could not upload. Please try again.');
          //     }
          //   }
          // })
          .catch(error => {
            // one less from the Queue
            filesQueue--

            errors++

            // if the queue is empty, let the user know.
            if (filesQueue == 0) {
              // turn off loading spinner
              self.loading = false

              if (errors) {
                alert('It appears that there are some files we could not upload. Please try again.')
              }
            }

            self.$log.error('uploadAssets', error)
          })
      })
    },
    downloadAsset: function (assetFile) {
      const link = document.createElement('a')
      link.download = assetFile.name
      link.target = '_blank'
      link.href = assetFile.path
      link.click()
    },
    populateForm: function () {
      const self = this

      self.$log.info(self.canSaveData)
    }
  },
  async created () {
    const self = this

    // assign the campaign from the list
    self.campaign = await self.itemById(this.itemId)

    // if the campaign is undefined, the list is not loaded previously
    if (!self.campaign) self.campaign = await self.fetchById(this.itemId)

    self.$log.info('MANUAL 3P DATA', self.campaign)

    // populate form
    self.populateForm()
  },
  computed: {
    ...mapGetters('campaigns', [
      'itemById'
    ]),
    ...mapGetters([
      'campaignSources',
      'Status',
      'partners',
      'partnersMeasurement',
      'partnersVerification'
    ]),
    canSaveData: function () {
      return (this.itemId != null && this.data_type !== null && (this.verification_partner_id !== null || this.measurement_partner_id !== null) && this.base64File !== null)// && this.assetFiles[0] !== null) ;
    }
  },
  watch: {
    campaign: function () {
      this.measurement_partner_id = (this.campaign) ? this.campaign.measurement_partner_id : null
      this.verification_partner_id = (this.campaign) ? this.campaign.verification_partner_id : null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/common.scss";

  .new{
    color:white;
    background-color:$green1;
    margin-right: $spacing-unit/2;
    padding: .4rem $spacing-unit/2;
    border-radius: $roundness;
    text-transform: uppercase;
    font-size:0.8rem;
  }

  .update{
    color:white;
    background-color: $green9;
    margin-right: $spacing-unit/2;
    padding: .4rem $spacing-unit/2;
    border-radius: $roundness;
    text-transform: uppercase;
    font-size:0.8rem;
  }

  .buttons{
    margin-bottom: $spacing-unit;
  }

</style>
