<template>
  <div class="appFrame">
    <Navbar
      :current-module-path="'location-feeder'"
      :current-module-name="'Location Feeder Feed Builder'"
    />

    <main class="moduleFrame moduleFrame--creativeRepository">
      <PencilBar />

      <ModuleHeader>
        <template #tabs>
          <ul>
            <li>
              <router-link to="/tools/location-feeder/feeds">Feeds</router-link>
            </li>
            <li selected>
              <router-link to="/tools/location-feeder/feed-builder"
                >Feed Builder</router-link
              >
            </li>
          </ul>
        </template>
        <template #title>
          <div>Feed Builder</div>
        </template>
      </ModuleHeader>

      <!-- New Feed modal form -->
      <b-modal
        ref="newFeedModal"
        class="sModal"
        centered
        no-close-on-backdrop
        hide-header
        hide-footer
      >
        <header class="sModal__header">New Feed</header>
        <div class="sModal__body">
          <FormField
            label="Feed Name *"
            for-id="airtory-id"
            class=""
          >
            <input
              id="airtory-id"
              v-model="feeder.feed_name"
              type="text"
              maxlength="50"
              required
            />
          </FormField>
          <FormField
            label="Feed Input"
            for-id="input-source"
            type="select"
          >
            <select
              id="location_input"
              v-model="feeder.location_feeder_input_id"
              required
            >
              <option
                v-for="(feed_input, index) in feederInputs"
                :key="index"
                :value="feed_input.id"
              >
                {{ feed_input.name }}
              </option>
            </select>
          </FormField>
          <FormField
            label="Feed Type"
            for-id="type-source"
            type="select"
          >
            <select
              id="location_type"
              v-model="feeder.location_feeder_type_id"
              required
            >
              <option
                v-for="(feed_type, index) in feederTypes"
                :key="index"
                :value="feed_type.id"
              >
                {{ feed_type.name }}
              </option>
            </select>
          </FormField>
          <FormField
            label="Upload Feed *"
            description=""
          >
            <b-form-file
              ref="fileInput"
              class="button-file"
              :class="{ disabled: !validateForm }"
              accept=".csv, application/vnd.sealed.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/json"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="onFeedUpload"
            ></b-form-file>
          </FormField>
          <button
            class="button button--positive"
            @click.prevent="downloadFeed('template')"
          >
            <font-awesome-icon
              icon="download"
              class="i"
            />
            Download template file
          </button>
        </div>
        <footer class="sModal__footer">
          <div class="sModal__footer__left">
            <button
              class="cancelButton"
              @click.prevent="cancel"
            >
              <font-awesome-icon
                icon="ban"
                class="i"
              />Cancel
            </button>
          </div>
        </footer>
      </b-modal>

      <!-- Feed Builder Editor -->
      <section
        v-if="!noDataUploaded"
        class="moduleContent"
      >
        <div class="feedBuilderModule">
          <FormField
            label="Upload Feed *"
            description=""
          >
            <b-form-file
              class="button-file"
              accept=".csv, application/vnd.sealed.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/json"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="onFeedUpload"
            ></b-form-file>
          </FormField>

          <button
            class="button mb-5 button--green"
            @click.prevent="downloadFeed('template')"
          >
            <font-awesome-icon
              icon="download"
              class="i"
            />
            Download template file
          </button>
        </div>

        <vue-excel-editor
          id="v-excel"
          ref="grid"
          v-model="jsondata"
          page-size="25"
          autocomplete
          @select="onRowSelectUnselect"
        >
          <vue-excel-column
            label="Name"
            field="name"
            type="string"
            width="410px"
          />
          <vue-excel-column
            label="Address"
            field="address"
            type="string"
            width="610px"
          />
          <vue-excel-column
            label="City"
            field="city"
            type="string"
            width="240px"
          />
          <vue-excel-column
            label="State"
            field="state"
            type="string"
            width="180px"
          />
          <vue-excel-column
            label="Postcode"
            field="postcode"
            type="number"
            width="200px"
          />
          <vue-excel-column
            label="Country"
            field="country"
            type="string"
            width="240px"
          />
          <vue-excel-column
            label="Lat"
            field="lat"
            type="number"
            width="260px"
          />
          <vue-excel-column
            label="Lng"
            field="lng"
            type="number"
            width="260px"
          />
        </vue-excel-editor>

        <div class="feedBuilderModule">
          <button
            class="button mt-3 mr-3 button-add-row"
            @click.prevent="newRecord"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            />
          </button>
          <button
            class="button mt-3 mr-3 button-add-row"
            :disabled="!showRecordButtons"
            @click.prevent="cloneRecord"
          >
            <font-awesome-icon
              icon="clone"
              class="i"
            />
          </button>
          <button
            class="button mt-3 button-add-row"
            :disabled="!showRecordButtons"
            @click.prevent="deleteRecord"
          >
            <font-awesome-icon
              icon="trash-alt"
              class="i"
            />
          </button>
        </div>

        <div class="sModal__footer__right">
          <button
            class="button mr1"
            :disabled="jsondata.length < 1"
            @click.prevent="downloadFeed('data')"
          >
            <font-awesome-icon
              icon="download"
              class="i"
            />
            Download Feed
          </button>
          <button
            class="button button--positive"
            :disabled="jsondata.length < 1"
            @click.prevent="saveFeed"
          >
            <font-awesome-icon
              icon="check"
              class="i"
            />
            Save Feed
          </button>
        </div>
      </section>

      <section
        v-if="noDataUploaded"
        class="moduleContent"
      >
        <div class="icon-container">
          <SvgIcon />
          <button
            class="button button--green mt-3"
            @click="openNewFeed"
          >
            <font-awesome-icon
              icon="plus-circle"
              class="i"
            />
            New Feed
          </button>
        </div>
      </section>

      <ModuleFooter>
        <template #left>
          <div>© {{ new Date().getFullYear() }} PADSQUAD LLC</div>
        </template>
        <template #right>
          <WebLinks />
        </template>
      </ModuleFooter>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import FormField from '@/components/FormField.vue'
import ModuleHeader from '@/components/ModuleHeader.vue'
import ModuleFooter from '@/components/ModuleFooter.vue'
import PencilBar from '@/components/PencilBar.vue'
import WebLinks from '@/components/WebLinks.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import * as XLSX from 'xlsx'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LocationFeederRepositoryFeedBuilder',
  components: {
    Navbar,
    FormField,
    ModuleHeader,
    ModuleFooter,
    PencilBar,
    WebLinks,
    SvgIcon
  },
  data() {
    return {
      selectedFeed: null,
      selectedRow: false,
      newItem: false,
      noDataUploaded: true,
      showRecordButtons: false,
      feeder: {
        id: 0,
        location_feeder_type_id: 1,
        location_feeder_input_id: 1,
        location_feeder_content_type_id: 1,
        feed_name: '',
        dynamic_url: '',
        static_url: '',
        detail: []
      },
      jsondata: []
    }
  },
  computed: {
    ...mapGetters('locationFeeders', [
      'feederTypes',
      'feederInputs',
      'feederContentTypes'
    ]),
    ...mapGetters(['userInSession']),
    validateForm: function () {
      return this.feeder.feed_name
    }
  },
  mounted() {
    const id = this.$route.params.id
    if (id) {
      //   console.log('Edit Feed', id)
      this.fetchFeederById(id)
        .then((data) => {
          this.feeder = data
          this.jsondata = data.detail
          this.newItem = false
          this.noDataUploaded = false
        })
        .catch((error) => {
          console.error('Error fetching feeder', error)
        })
    } else {
      this.newItem = true
      console.log('New Feed', this.newItem)
      this.$refs.newFeedModal.show()
    }
  },
  methods: {
    ...mapActions('locationFeeders', ['fetchFeederById', 'create', 'update']),
    openNewFeed: function (item) {
      this.feeder.feed_name = ''
      this.currentItem = item
      this.$refs.newFeedModal.show()
    },
    getNewEmptyRecord() {
      return {
        //evaluate if id exists if not then 0
        id: this.feeder.id ? this.feeder.id : 0,
        location_feeder_type_id: this.feeder.location_feeder_type_id,
        location_feeder_input_id: this.feeder.location_feeder_input_id,
        location_feeder_content_type_id:
          this.feeder.location_feeder_content_type_id,
        feed_name: this.feeder.feed_name,
        dynamic_url: '',
        static_url: '',
        created_by: `${this.userInSession.name} ${this.userInSession.last_name}`,
        detail: this.jsondata.map((data) => ({
          name: data.name,
          address: data.address,
          city: data.city,
          state: data.state,
          postcode: data.postcode,
          country: data.country,
          lat: data.lat,
          lng: data.lng
        }))
      }
    },
    newRecord() {
      this.$refs.grid.newRecord()
    },
    deleteRecord() {
      this.$refs.grid.deleteSelectedRecords()
      this.showRecordButtons = false
    },
    cloneRecord() {
      const selectedRecords = this.$refs.grid.getSelectedRecords()

      if (selectedRecords.length > 0) {
        selectedRecords.map((item) => {
          const _item = { ...item }
          delete _item.$id
          _item.name = _item.name + '_copy'
          this.jsondata.push(_item)
        })
      }
      this.$log.info(this.jsondata)
    },
    onRowSelectUnselect(selectedRows, isSelect) {
      // as the selected row length will alwas be 1 or more we use the second parameter to identify if the row is selected or not
      this.showRecordButtons = isSelect
    },
    resetFormInput: function () {
      if (this.$refs.fileInput) this.$refs.fileInput.value = ''
    },
    onFeedUpload: function (event) {
      const file = event.target.files[0]
      if (!file) return
      if (file && this.isValidFile(file)) {
        const reader = new FileReader()
        // validate if file is json or xls, xlsx and csv
        reader.onload = (e) => {
          const data = e.target.result
          if (file.name.endsWith('.json')) {
            this.jsonFileUploaded(data)
          } else {
            this.sheetFileUploaded(data)
          }
        }

        if (file.name.endsWith('.json')) {
          reader.readAsText(file)
        } else {
          reader.readAsBinaryString(file)
        }

        this.noDataUploaded = false
        this.$refs.newFeedModal.hide()
      } else {
        this.showAlerts(
          'Info!',
          'Invalid file format. Please upload a CSV, XLS, XLSX or JSON file.',
          'info'
        )
      }
    },
    jsonFileUploaded: function (data) {
      try {
        const jsonData = JSON.parse(data)
        if (jsonData.detail && Array.isArray(jsonData.detail)) {
          this.jsondata = jsonData.detail.map((item) => {
            const keys = Object.keys(item).reduce((result, key) => {
              result[key.toLowerCase()] = item[key]
              return result
            }, {})

            const { name, address, city, state, postcode, country, lng, lat }
              = keys
            return { name, address, city, state, postcode, country, lng, lat }
          })
        } else {
          this.showAlerts(
            'Error!',
            'JSON does not have the required structure.',
            'error'
          )
        }
      } catch (error) {
        this.showAlerts('Error!', 'Invalid JSON format.', 'error')
      }
    },
    sheetFileUploaded: function (data) {
      const workbook = XLSX.read(data, { type: 'binary' })
      this.jsondata = this.parseData(workbook)
    },
    isValidFile: function (file) {
      // checking if the file uploaded is a CSV XLS or XLSX or JSON file
      const validFormats = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'application/json'
      ]
      return validFormats.includes(file.type)
    },
    parseData: function (workbook) {
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) // Fetch data as array of arrays to check headers

      // Check if the first row contains required headers
      const requiredHeaders = [
        'name',
        'address',
        'city',
        'state',
        'postcode',
        'country',
        'lng',
        'lat'
      ].map((header) => header.toLowerCase())
      const actualHeaders = rawData[0].map((header) =>
        header.toString().toLowerCase()
      )
      const missingHeaders = requiredHeaders.filter(
        (header) => !actualHeaders.includes(header)
      )

      if (missingHeaders.length > 0) {
        // Handle missing headers
        this.showAlerts(
          'Error',
          `The uploaded file is missing required columns: ${missingHeaders.join(', ')}.`,
          'error'
        )
        if (this.newItem) {
          setTimeout(() => {
            this.noDataUploaded = true
            this.$refs.newFeedModal.show()
          }, 3000)
        }
        return []
      }

      // Convert data back to JSON structure if headers are present
      const data = XLSX.utils.sheet_to_json(worksheet)

      let missingDataRows = []
      // Proceed with row-based validation and collect rows with missing data
      const selectedData = data
        .map((row, index) => {
          const keys = Object.keys(row).reduce((result, key) => {
            result[key.toLowerCase()] = row[key]
            return result
          }, {})

          const { name, address, city, state, postcode, country, lng, lat }
            = keys
          if (
            !name
            || !address
            || !city
            || !state
            || !postcode
            || !country
            || !lng
            || !lat
          ) {
            // Add row number to missingDataRows
            missingDataRows.push(index + 2) // +2 to account for header row and 0-based index
            return null
          }

          return { name, address, city, state, postcode, country, lng, lat }
        })
        .filter((row) => row !== null) // Remove null entries representing rows with missing data

      // Check after processing all rows if there were any missing data issues
      if (missingDataRows.length > 0) {
        this.showAlerts(
          'Error',
          `Some required data is missing in rows: ${missingDataRows.join(', ')}.`,
          'error'
        )
        if (this.newItem) {
          setTimeout(() => {
            this.noDataUploaded = true
            this.$refs.newFeedModal.show()
          }, 3000)
        }
      }

      return selectedData
    },

    showAlerts: function (type, message, icon) {
      this.$swal({
        position: 'top',
        title: type,
        html: `<h3>${message}</h3>`,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        allowOutsideClick: false,
        icon: icon,
        timer: 3000,
        toast: false
      })
    },
    downloadFeed: function (e) {
      let dataToDownload
      let fileName

      // evaluating if parameter passed to download is a template or feed data
      if (e === 'template') {
        // create empty object for template
        dataToDownload = [
          {
            Name: '',
            Address: '',
            City: '',
            State: '',
            Postcode: '',
            Country: '',
            Lat: '',
            Lng: ''
          }
        ]
        fileName = 'tempate_feeder_data.xlsx'
      } else {
        // mapping the data from existing feed detail
        dataToDownload = this.getNewEmptyRecord().detail.map((item) => {
          const capitalizedItem = {}
          for (let key in item) {
            capitalizedItem[key.charAt(0).toUpperCase() + key.slice(1)]
              = item[key]
          }
          return capitalizedItem
        })
        fileName = this.feeder.feed_name + '_feeder_data.xlsx'
      }
      // downloading the feed data using xlsx library
      const ws = XLSX.utils.json_to_sheet(dataToDownload)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Feed Data')

      // generating the xlsx file
      XLSX.writeFile(wb, fileName)
    },
    saveFeed: function () {
      const payload = this.getNewEmptyRecord()

      if (!this.newItem) {
        this.update(payload)
          .then((response) => {
            this.showAlerts('Success!', 'Feed updated successfully.', 'success')
            setTimeout(() => {
              this.$router.push('/tools/location-feeder/feeds')
            }, 3000)
          })
          .catch((error) => {
            this.showAlerts(
              'Error!',
              'Failed updating Feed. Please try again.',
              'error'
            )
          })
      } else {
        this.create(payload)
          .then((response) => {
            this.showAlerts('Success!', 'Feed created successfully.', 'success')
            setTimeout(() => {
              this.$router.push('/tools/location-feeder/feeds')
            }, 3000)
          })
          .catch((error) => {
            this.showAlerts(
              'Error!',
              'Failed creating Feed. Please try again.',
              'error'
            )
          })
      }
    },
    cancel: function () {
      this.$refs.newFeedModal.hide()
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/common.scss';

.moduleContent {
  padding: 3em !important;
}

.icon-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 60vh;
}

.feedBuilderModule {
  text-align: left;
}

.vue-excel-editor {
  width: 100%;
  height: 100%;
}

.vue-excel-column {
  width: 100%;
}

.buttons {
  margin-bottom: $spacing-unit;
}

.button-add-row .i {
  margin-right: 0 !important;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

#v-excel .systable thead th {
  font-size: 1.3em !important;
  font-weight: 500 !important;
  padding: 1.2em 1.4em !important;
}

#v-excel .systable tbody tr td {
  font-size: 1.1em !important;
  padding: 0.8em 1.3em !important;
  text-align: left !important;
}

#v-excel .fa-sm[data-v-cf2e49d2] {
  font-size: 1.1em;
}
</style>
