<template>
    <b-dropdown class="button-dropdown" :class="{'button-dropdown--dropup': dropup}" menu-class="button-dropdown__menu" no-caret @show="$emit('show')" @hide="$emit('hide')">
        <span slot="button-content"><font-awesome-icon icon="ellipsis-h" class="i" /></span>
        <b-dropdown-item v-for="(action, index) in actionsAvailable" :key="index" @click.prevent="action.clickAction(itemId)">
            <font-awesome-icon :icon="action.ico" class="i" /> {{action.text}}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ActionsDropdown',
  props: {
    dropup: {
      type: Boolean,
      default: false
    },
    itemId: [String, Number],
    userData: Object,
    actions: Array,
    source: {
      type: String,
      default: ''
    }
  },
  computed: {
    //  when the route is public like List, the app does not always fetch user info, so we don't have userInSession
    // ...mapGetters([
    //   'userInSession'
    // ]),

    /* returns actions that are available for current user's role */
    actionsAvailable: function () {
      const self = this

      const userInSession = JSON.parse(localStorage._sUser)

      let currentUserRole = false

      // if we have a user in session, then fill out the role
      if (userInSession !== null) currentUserRole = userInSession.role_name

      return _.filter(self.actions, action => {
        if (action.sourceRequirement && action.sourceRequirement !== self.source) {
          return false
        }
        // if there are roles set for this options...
        if (action.roles && action.roles.length) {
          // allow user to see this options if the user role can see it
          return action.roles.indexOf(currentUserRole) > -1
        } else {
          // otherwise, there are no restrictions and user can see it.
          return true
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.button-dropdown {

  button {
    @extend .button;
  }

  .dropdown-toggle-no-caret {
    &:after {
      content: '';
      display: none;
    }
  }

  & &__menu {

    padding: 0px;
    border-radius: $roundness;
    transform: none !important;
    left: initial !important;
    top: -2px !important;
    right: -2px;

    & a {
      font-size: 1.2rem;
      padding: $spacing-unit $spacing-unit * 2;
      border-bottom: 1px solid $gray3;
      color: $gray6;
      text-decoration: none;

      .i {
        margin-right: 1rem;
      }
    }

  }

}
</style>
