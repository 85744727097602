<template>
    <section class="sModal">
        <div @click="cancel()" class="close-button">
            <font-awesome-icon
                icon="times"
                />
        </div>
        <Preloader :inModal="true"></Preloader>
        <header class="sModal__header sModal__header--blue">
            Clone Creatives to Campaign
        </header>
        <div class="sModal__body">
            <small class="label">Destination Campaign</small>
            <div class="py-3">
                <multiselect
                    v-model="selectedCampaignsIds"
                    :multiple="false"
                    :show-labels="false"
                    :closeOnSelect="true"
                    placeholder="Select a campaign"
                    :searchable="true"
                    @search-change="debouncedSearch"
                    @select="onSelectCampaignChange($event)"
                    :options="filteredCampaigns.map(x => x.id)"
                    :custom-label="getCustomLabel">
                </multiselect>
            </div>
        </div>
        <footer class="sModal__footer">
        <div class="sModal__footer__left">
          <button class="cancelButton" @click.prevent="cancel"><font-awesome-icon icon="ban" class="i" />Cancel</button>
        </div>

        <div class="sModal__footer__right">
          <button class="saveButton" @click="save()" :disabled="!selectedCampaingId">
            <font-awesome-icon icon="check" class="i" />Clone
          </button>
        </div>
      </footer>
    </section>
</template>

<script>
import FormField from '@/components/FormField.vue'
import Api from '@/helpers/ApiCreativeProduction.js'
import Preloader from '@/components/Preloader.vue'
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CloneCreatives',
  components: {
    Preloader, Multiselect, FormField
  },
  props: {
    campaignId: null,
    campaignName: null,
    itemsId: null
  },
  data () {
    return {
      selectedCampaignsIds: null, // []
      selectedCampaingId: null,
      selectedCampaignsData: null, // [] for one campaign
      selectedCampaignsCreatives: [],
      selectedCreatives: [],
      isCampaignDropdownOpen: false,
      searchQuery: '',
      debounceTimeout: null
    }
  },
  computed: {
    ...mapGetters('campaigns', {
      campaignsListAirtory: 'listAirtory', // listAirtory
      campaignById: 'itemById'
    }),
    ...mapGetters('creatives', {
      creativesList: 'list',
      creativesByCampaign: 'listByCampaignId'
    }),
    listCampaignsFiltered: function () {
      // descending sort
      return [...this.campaignsListAirtory.sort((a, b) => (a.id < b.id ? 1 : -1))]
    },
    isRequiredDataFilledOut: function () {
      return this.selectedCampaignsIds.length > 0
    },
    filteredCampaigns: function () {
      if (!this.searchQuery) {
        return this.listCampaignsFiltered.slice(0, 20)
      }
      return this.listCampaignsFiltered.filter(campaign => campaign.name.includes(this.searchQuery) || campaign.id.toString().includes(this.searchQuery)).slice(0, 20)
    }
  },
  methods: {
    ...mapActions('campaigns', {
      getCampaignById: 'fetchById',
      fetchCampaigns: 'fetch'
    }),
    ...mapActions('creatives', {
      cloneCreativesAction: 'cloneCreatives'
    }),
    navigateToCampaignCreatives: function (itemData) {
      const self = this

      const routeData = self.$router.resolve({ name: 'Production Creatives', params: { id: itemData } })
      window.open(routeData.href, '_self')
    },
    onSelectCampaignChange: function (selected) {
      this.selectedCampaingId = selected
    },
    getCampaignName: function (id) {
      if (this.selectedCampaignsIds.length === 1 && this.selectedCampaignsIds[0] !== 0) {
        return this.listCampaignsFiltered.find(x => x.id == id).name
      }

      if (this.selectedCampaignsIds.length >= 1 && this.areAllCampaignSelected) {
        return 'All'
      }

      if (this.selectedCampaignsIds.length > 1 && this.selectedCampaignsIds.find(item => item === 0) === undefined) {
        return 'Multiple'
      }
    },
    getExternalCampaignById: function (id) {
      const tryExternalId = this.listCampaignsFiltered.find(x => x.id == id).external_campaign_id
      return tryExternalId || null
    },
    getCustomLabel: function (option) {
      const campaign = this.listCampaignsFiltered.find(x => x.id === option)
      return `${campaign.id} - ${campaign.name}`
    },
    searchCampaigns: function (query) {
      this.searchQuery = query
    },
    debouncedSearch: function (query) {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.searchCampaigns(query)
      }, 300)
    },
    save: async function () {
      // set creatives selected
      const selectedCreatives = []
      const externalCampaignId = this.getExternalCampaignById(this.selectedCampaingId)

      this.itemsId.map(item => {
        selectedCreatives.push(item.id)
      })
      // set object from creatives selected and campaign destination
      const campCreatives = { destinationCampaignid: this.selectedCampaingId, destinationExternalId: externalCampaignId, creatives: selectedCreatives }

      try {
        await this.cloneCreativesAction(campCreatives)
        this.navigateToCampaignCreatives(this.selectedCampaingId)
        // emit event
        await this.$emit('done')
      } catch (error) {
        self.$log.info(error)
      }
    },
    cancel: function () {
      // emit event
      this.$emit('done')
    }
  },
  async created () {
    if (this.itemsId) {
      this.selectedCreatives = this.itemsId
    }
    // if(!this.campaignsListAirtory || this.campaignsListAirtory.length === 0){
    await this.fetchCampaigns()
    // }

    // set the id passed on props
    if (this.campaignId) {
      this.selectedCampaignsIds = [this.campaignId]
      await this.loadCampaignData()
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
    .tac{
        text-align: center;
    }

    /* arrow */
    .multiselect__select{
        z-index: 9;
    }

    .multiselect__input, .multiselect__single {
        font-size: 1.2rem;
        font-weight: 400;
        position: relative;
        display: inline-block;
        min-height: 20px;
        line-height: 20px;
        border: none;
        border-radius: 0px;
        background: #fff;
        padding: 0 0 0 5px;
        padding: 0;
        width: 100%;
        transition: border .1s ease;
        box-sizing: border-box;
        margin-bottom: 8px;
        vertical-align: top;
    }

    .multiselect__select:before{
        color: #646464;
    }

    .multiselect {
        box-sizing: content-box;
        display: block;
        position: relative;
        width: 100%;
        min-height: 40px;
        text-align: left;
        color: #14123A;
    }

    .multiselect__placeholder {
        padding-left: 7px;
        color: #adadad;
        color: #14123A;
        display: inline-block;
        margin-bottom: 10px;
        padding-top: 2px;
        padding: 0;
        font-weight: 400;
    }

    .multiselect__tags {
        min-height: 40px;
        display: block;
        padding: 1rem 1.5rem 0;
        border-radius: 0px;
        border: 1px solid #d9d9d9;
        background: #fff;
        font-size: 1.2rem;
    }

    .multiselect__tags:hover {
        border-color: #979797;
    }

    .multiselect__tag {
        position: relative;
        display: inline-block;
        /* padding: 4px 26px 4px 10px; */
        border-radius: 0px;
        margin-right: 10px;
        color: #fff;
        line-height: 1;
        background: #3BBCA1;
        background: #8AD622;
        /* background: #11433C; */
        margin-bottom: 5px;
        white-space: normal;
        // overflow: hidden;
         max-width: 97%;
        // text-overflow: ellipsis;
    }

    ::v-deep .multiselect__content-wrapper{
        max-height: 115px !important;
    }

    .multiselect__option {
        font-size: 1.2rem;
        font-weight: 400;
        white-space: normal;
    }

    .multiselect__option--selected {
        background: #f3f3f3;
        color: #14123A;
        font-weight: 400;
    }

     .multiselect__option--highlight {
        background: #3BBCA1;
        outline: none;
        color: #fff;
    }
    .multiselect__spinner:after,
    .multiselect__spinner:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 2px solid transparent;
        border-top-color: #3BBCA1;
        box-shadow: 0 0 0 1px transparent;
    }
    .multiselect__tag-icon:after {
        content: "\D7";
        color: #266d4d;
        font-size: 14px;
    }
    .multiselect__tag-icon:focus,
    .multiselect__tag-icon:hover {
        background: #369a6e;
    }
    .multiselect__option--highlight {
        background: #3BBCA1;
        outline: none;
        color: #fff;
    }
    .multiselect__option--highlight:after {
        content: attr(data-select);
        background: #3BBCA1;
        color: #fff;
    }

    .multiselect__option--selected.multiselect__option--highlight {
        background: #a8a8a8;
        color: #fff;
    }
    .multiselect__option--selected.multiselect__option--highlight:after {
        background: #a8a8a8;
        content: attr(data-deselect);
        color: #fff;
    }

    .label{
        text-transform: uppercase;
    }

    .py-3{
        margin-bottom: 10px;
    }

    h6.title_selected{
        font-weight: 600;
        font-weight: 400;
        margin-top: 5px;
        color: #a1a0b9;
    }

    h6.title_selected span{
        font-weight: 500;
        color: #3BBCA1;
    }

    .sModal__header--blue {
        background: #14123A !important;
        color: #FAFAFA !important;
        /* background: #3BBCA1; */
    }

    .sModal__body {
        padding-top: 4em !important;
        padding-bottom: 4.5em !important;
    }

    .error{
        color: #9c2e06;
    }

    .alert-box{
        background-color: #9c2e06;
        color: #ffffff;
        text-align: center;
        padding: 10px;
    }

    .alert-box span{
        font-weight: bold;
    }

    .fullWidth{
        display: grid !important;
    }

    .justify-start{
        -webkit-box-pack: start;
            -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .block-mt-15{
        display: block;
        margin-top: 15px;
    }

    .option__title{
        display: inline-block;
        color: #646464;
        font-size: 1rem;
        font-weight: bold;
        line-height: 0.1rem;
        text-transform: uppercase;
        border: 1px solid #CBCBCB;
        border-radius: 4px;
        background: white;
        padding: 1rem 1.5rem;
        cursor: pointer;
        width: 6rem;
        text-align: center;
        margin-right: 5px;
    }

    .multiselect__option--group {
        background:darkgray !important;
        color:#35495e !important;
        font-weight: bold !important;
    }
</style>
