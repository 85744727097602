import store from '@/store'
import axios from 'axios'

export default function setInterceptor () {
  axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ENDPOINT
  /*     console.log('region',localStorage.current_region)
    console.log('apikey',axios.defaults.headers.common['x-api-key']) */
  console.log('interseptor')
  if (localStorage.current_region == 'us-east-1') {
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_X_API_KEY
  } else if (localStorage.current_region == 'us-west-2') {
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_X_API_KEY_WEST
  }

  // console.log('setting interceptor', store.getters['token']);

  axios.interceptors.request.use(config => {
    const token = store.getters.token || null
    config.headers.Authorization = `Bearer ${token}`
    // console.log('Config', config);
    return config
  }, error => {
    return Promise.reject(error)
  })
}
