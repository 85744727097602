var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"moduleContent"},[_c('div',{staticClass:"contentTableFrame"},[_c('div',{staticClass:"contentTableTools d-flex justify-content-end"},[_c('b-pagination',{staticClass:"contentTableFrame__pagination",attrs:{"per-page":_setup.paginationObject.pageSize,"total-rows":_setup.paginationObject.total,"limit":3,"aria-controls":"my-table"},on:{"change":_setup.handlePaginationChange},model:{value:(_setup.paginationObject.page),callback:function ($$v) {_vm.$set(_setup.paginationObject, "page", $$v)},expression:"paginationObject.page"}})],1),_c('div',[_c('b-table',{staticClass:"contentTable",staticStyle:{"text-align":"left"},attrs:{"id":"my-table","items":_setup.placementData,"fields":_setup.props.fields.map((field) => {
            if (field.key === 'uploaded_file_url') {
              return { ...field, class: 'limited-width-uploaded-file-url' }
            }
            if (field.key === 'log_message') {
              return { ...field, class: 'limited-width-log-message' }
            }
            return field
          })},scopedSlots:_vm._u([{key:"cell(uploaded_file_url)",fn:function(data){return [_c('span',{on:{"mouseover":function($event){return _setup.showToolTip(`assetUrl-${data.index}`)},"mouseleave":function($event){return _setup.hideToolTip(`assetUrl-${data.index}`)}}},[(_setup.toolTipVisible[`assetUrl-${data.index}`])?_c('div',{staticClass:"tooltip"},[_vm._v(" "+_vm._s(data.value)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])])]}},{key:"cell(log_message)",fn:function(data){return [_c('span',{on:{"mouseover":function($event){return _setup.showToolTip(`errorMessage-${data.index}`)},"mouseleave":function($event){return _setup.hideToolTip(`errorMessage-${data.index}`)}}},[(_setup.toolTipVisible[`errorMessage-${data.index}`])?_c('div',{staticClass:"tooltip"},[_vm._v(" "+_vm._s(data.value)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])])]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }